<template>
  <div class="top-level-menu-status-item-wrapper">
    <div :class="[classes, { 'has-agent-timer-inside-menu': shouldDisplayTimerInsideMenu}]">
      <div
        :class="`tlmsiv-s-icon s-${statusItem.imageClass} ${isSelected && 'selected'} status-item-container`"
        role="button"
        tabindex="0"
        :aria-expanded="(canExpand && expanded) || null"
        :aria-pressed="isSelected || null"
        :lp-data-at="`status-item-` + statusItem.value"
        @click="clicked"
        @keydown.enter.space.prevent="clicked"
      ></div>
      <div class="top-level-menu-status-title">
        <span class="top-level-menu-status-text ellipsis">{{ statusItem.availabilityTitle }}</span>
      </div>
      <AgentTimer
        v-if="hasStatusTimer"
        :should-display-timer-inside-menu="shouldDisplayTimerInsideMenu"
        :status-item="statusItem"
        :selected-item="selectedItem"
        :is-first-status-after-login="isFirstStatusAfterLogin"
        @make-first-status-false="makeFirstStatusFalse"
      />
    </div>
  </div>
</template>

<script>

import AgentTimer from "./AgentTimer";

const Settings = require("codeTables/settings.json");

export default {
  name: "TopLevelMenuStatusItemView",
  components: { AgentTimer },
  props: {
    selectedTheme: {
      type: Object,
      default: () => {},
    },
    statusItem: {
      type: Object,
      default: () => {},
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
    expanded: {
      type: [Boolean, String],
      default: false
    },
    canExpand: {
      type: Boolean,
      default: false,
    },
    isFirstStatusAfterLogin: {
      type: Boolean,
      default: true,
    },
    hasConfigForCustomMaxSize: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['clicked', 'setFirstStatusToFalse'],
  data() {
    return {
      shouldDisplayTimerInsideMenu: false,
    }
  },
  computed: {
    isSelected() {
      return this.statusItem.value === this.selectedItem.value;
    },
    classes() {
      return {
        'top-level-menu-status-item': true
      };
    },
    iconClasses() {
      let imageClass;
      if (this.statusItem.imageClass === 'online') {
        imageClass = 'ico-agent-status-online online'
      } else if (this.statusItem.imageClass === 'brb') {
        imageClass = 'ico-agent-status-back-soon brb'
      } else if (this.statusItem.imageClass === 'away') {
        imageClass = 'ico-agent-status-away away'
      }

      return {
        'top-level-menu-status-icon': true,
        [imageClass]: true
      };
    },
    hasStatusTimerInsideMenu() {
      // AC FEATURE
      const agentStatusTimer = LE.sessionManager.hasAgentTimer();

      // PERMISSION
      const viewAgentStatusTimer = LE.siteSettings.privilegeSettings.hasAgentTimerPrivilege();

      // SITE SETTING
      const agentStatusTimerInsideMenu = LE.sessionManager.getAccountSettingValueByID(
        Settings.SETTING_ID.AGENT_STATUS_TIMER_INSIDE_MENU);

      return (agentStatusTimer && viewAgentStatusTimer && agentStatusTimerInsideMenu === 'true');
    },
    hasStatusTimer() {
      const agentStatusTimerUnderMenu = LE.sessionManager.getAccountSettingValueByID(
        Settings.SETTING_ID.AGENT_STATUS_TIMER_UNDER_MENU);
      if (!this.hasStatusTimerInsideMenu) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.shouldDisplayTimerInsideMenu = false;
        return agentStatusTimerUnderMenu === 'true';
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.shouldDisplayTimerInsideMenu = true;
        return true;
      }
    }
  },
  methods: {
    makeFirstStatusFalse() {
      this.$emit('setFirstStatusToFalse');
    },
    clicked() {
      // for the case the status upon login is Away and before getting the api response the user clicks on another status.
      // in this case we need to make sure isFirstStatusAfterLogin is set to false
      // it makes sense either way cause when the user clicks on a new status it is for sure not the first status
      // we also have to make sure the clicked item is not Away otherwise we wouldn't want to change anything
      if (this.isFirstStatusAfterLogin && this.statusItem.value !== 'Away') {
        this.makeFirstStatusFalse();
      }
      this.$emit('clicked', this.statusItem);
    },
  },
}
</script>

<style lang='scss' scoped>
@import "../../../../assets/scss/global";
@import "../../../../assets/scss/mixins";

.tlmsiv-s-icon {
  width: 20px;
  height: 20px;
}

$icons: s-online, s-away, s-offline;
@include add-icons-from-list($icons, 50%, 50%);

.status-item-container{
  &:focus{
    @include themify(null, 'show-a11y-focus-outline') {
      box-shadow: 0 0 0 4px getColor('user-menu-status-item-box-shadow', 'framework') !important;
    }
  }


}
.status-item-container.selected {

  &:focus{
    @include themify(null, 'show-a11y-focus-outline') {
      box-shadow: 0 0 0 4px getColor('user-menu-status-item-box-shadow-selected', 'framework') !important;
    }
  }

}

</style>
