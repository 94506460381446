define(function (require) {
    "use strict";

    var _ = require("underscore");
    var DateUtility = require("utils/DateUtility");

    var DAYS = {
        SUNDAY: 0,
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6
    };

    var EVENT_TYPES = {
        ALL_HOURS: "allHours",
        INACTIVE: "inactive",
        CUSTOM: "custom"
    };

    var DATE_AND_TIME_DELIMETER = 'T';

    var ScheduleUtilities = (function(){

        var shiftTimesArr = [];
        var shiftTimeMap = {};

        /**
         * @param event - the event we want to get the times from
         * @returns {Object} - contains startTime object and endTime object, both in the format: {hours, minutes, seconds, period}
         */
        var getShiftFromEvent = function(eventObj) {
            var startDateCombinedWithTime = eventObj.start.dateTime;
            var endDateCombinedWithTime = eventObj.end.dateTime;
            var shiftStartTime = startDateCombinedWithTime.split(DATE_AND_TIME_DELIMETER)[1];
            var shiftEndTime = endDateCombinedWithTime.split(DATE_AND_TIME_DELIMETER)[1];
            var shiftTimeObject = {};

            if (!_.isUndefined(shiftStartTime) && !_.isUndefined(shiftEndTime)) {
                shiftTimeObject.startTime = shiftStartTime;
                shiftTimeObject.endTime = shiftEndTime;
            }

            return shiftTimeObject;
        };

        var getTimezoneFromEvents = function(events) {
            return (!_.isEmpty(events) && events[0].start) ? events[0].start.timeZone : window.LE.siteSettings.timeZoneSettings.getCurrentTimeZone();
        };

        /**
         *
         * @param recurrenceRule- the recurrence rule of the event. For example: RRULE:FREQ=WEEKLY
         * @returns number- represents the day
         */
        var getScheduleEventDay = function(eventObj) {
            var dayAsNumber = _getDatesFromEvent(eventObj).start.getDay();
            return dayAsNumber;
        };

        /**
         *
         * @param event- the event we want to get his type
         * @returns String- the event type
         */
        var getEventType = function(eventObj) {
            var eventType;
            if (eventObj.meta && !eventObj.meta.working) {
                eventType = EVENT_TYPES.INACTIVE;
            }
            else {
                eventType = _.isEmpty(getShiftFromEvent(eventObj)) ? EVENT_TYPES.ALL_HOURS : EVENT_TYPES.CUSTOM;
            }
            return eventType;
        };

        /**
         *
         * @param requestedDaya- number between 0-6 that represents the request day we want to get his date (sunday-0, monday-1, ..)
         * @returns Date
         */
        var getTheClosestDateOfARequestedDay = function (requestedDay) {
            var today = new Date();
            var diff = today.getDay() - requestedDay;
            return incrementDateByDays(today, -diff);
        };

        var getParsedEvent = function(eventObj, id) {
            var currentEventType = getEventType(eventObj);

            var currentEventShift = getShiftFromEvent(eventObj);

            var currentEventDates = _getDatesFromEvent(eventObj);

            var parsedEvent = {
                id: id,
                startDate: currentEventDates.start,
                endDate: currentEventDates.end,
                shifts: !_.isEmpty(currentEventShift) ? [currentEventShift] : [],
                type: currentEventType
            };

            if (eventObj.meta && eventObj.meta.name) {
                parsedEvent.name = eventObj.meta.name;
            }

            return parsedEvent;
        };

        /**
         *
         * @returns {Array}- with all the hours options we want to display in the single select
         */
        var getShiftTimesOptionsForSingleSelect = function() {
            if (_.isEmpty(shiftTimesArr)) {
                for (var hours = 0; hours < 24; hours++) {
                    for (var minutes = 0; minutes <= 55; minutes+=5) { //We want jumps of 5 minutes (00, 05, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55)
                        var paddingHours = (hours > 9) ? hours : "0" + hours;
                        var paddingMinutes = (minutes > 9) ? minutes : "0" + minutes;

                        var hoursForValue = hours % 12;
                        hoursForValue = (hoursForValue === 0) ? 12 : hoursForValue;
                        hoursForValue = (hoursForValue > 9) ? hoursForValue : "0" + hoursForValue;

                        var minutesForValue = paddingMinutes;
                        var period = (hours < 12) ? window.LE.translator.translate("LEFramework.timeFrame.period.am") : window.LE.translator.translate("LEFramework.timeFrame.period.pm");

                        shiftTimesArr.push({
                            id: paddingHours + ":" + paddingMinutes + ":00",
                            value: hoursForValue + ":" + minutesForValue + " " + period
                        });
                    }
                }

                // add special hour to make it possible to end a shift in "23:59:59"
                shiftTimesArr.push({
                    id: "23:59:59",
                    value: "11:59" + " " + window.LE.translator.translate("LEFramework.timeFrame.period.pm")
                });
            }

            return shiftTimesArr;
        };

        var getShiftTimesOptionsForSingleSelectAsMap = function() {
            if (_.isEmpty(shiftTimeMap)) {
                var shiftTimesArr = getShiftTimesOptionsForSingleSelect();
                _.each(shiftTimesArr, function(shiftTimeOption) {
                    shiftTimeMap[shiftTimeOption.id] = shiftTimeOption;
                });
            }

            return shiftTimeMap;
        };

        /**
         *
         * @param eventsCollection - scheduleEventCollection
         * @param timezone
         * @returns {Array} - Events array we will send to the server
         */
        var createScheduleEventsApiFormat = function (eventsCollection, timezone) {
            var eventsArr = [];

            eventsCollection.each(function(eventModel) {
                var eventsArrFormParsedEvent = [];
                var type = eventModel.getType();

                if (type === EVENT_TYPES.ALL_HOURS) {
                    eventsArrFormParsedEvent = _createScheduleAllHoursEvents(eventModel, timezone);
                }
                else if (type === EVENT_TYPES.CUSTOM) {
                    eventsArrFormParsedEvent = _createSchduleCustomEvents(eventModel, timezone);
                }

                eventsArr = eventsArr.concat(eventsArrFormParsedEvent);
            });

            return eventsArr;
        };

        var createSpecialOccasionEventsApiFormat = function(eventsCollection, timezone) {
            var eventsArr = [];
            eventsCollection.each(function(parsedEventModel) {
                var eventsArrFormParsedEvent = [];
                if (parsedEventModel.isNew()) {
                    eventsArrFormParsedEvent = [{
                        meta: {
                            name: parsedEventModel.getName(),
                            working: parsedEventModel.getType()
                        },
                        start: null,
                        end: null,
                        recurrence: []
                    }];
                }
                else {
                    if (parsedEventModel.getType() === EVENT_TYPES.CUSTOM) {
                        eventsArrFormParsedEvent = _createSpecialOccasionCustomEvents(parsedEventModel, timezone);
                    }
                    else { //all hours or inactive
                        eventsArrFormParsedEvent = _createSpecialOccasionAllHoursInactiveEvents(parsedEventModel, timezone);
                    }
                }

                eventsArr = eventsArr.concat(eventsArrFormParsedEvent);
            });

            return eventsArr;
        };

        var getSpecialOccasionEventId = function(name, startDate, endDate) {
            return name + "_" + DateUtility.date2StrYYYYMMdd(startDate) + "_" + DateUtility.date2StrYYYYMMdd(endDate);
        };

        /**
         * @param event - the event we want to get the dates from
         * @returns {Object} - contains both the startDate and the endDate
         */
        var _getDatesFromEvent = function(eventObj) {
            var startDateCombinedWithTime = eventObj.start.dateTime;
            var startDate = startDateCombinedWithTime.split(DATE_AND_TIME_DELIMETER)[0];
            var countValue = !_.isEmpty(eventObj.recurrence) ? _getRecurrenceParam(eventObj.recurrence[0], "COUNT") : 0;
            var startDateObject = DateUtility.strToDateObj(startDate);

            return {
                start: startDateObject,
                end: _addDaysToStartDateFromEvent(startDate, countValue)
            };
        };

        /**
         * @param date - the date with want to add the days to
         * @param numOfDays - how many days we want to add?
         * @returns {Date} - the original date + the days we want to add *MINUS 1*.
         */
        var _addDaysToStartDateFromEvent = function(date, numOfDays) {
            var updatedDate = new Date(date + "T00:00:00");
            if (numOfDays) {
                updatedDate.setDate(updatedDate.getDate() + Number(numOfDays) - 1);
            }
            return updatedDate;
        };

        /**
         * @param recurrence- the recurrence rule of the event
         * @param requestedParam- the requested part from the recurrence.
         * @returns {*}
         */
        var _getRecurrenceParam = function (recurrence, requestedParam) {
            var recurrenceParamsObj = {};

            var splittedRRuleAndParams = recurrence.split('RRULE:');

            var paramsArray = splittedRRuleAndParams[1].split(';');
            _.each(paramsArray, function (param) {
                var splitParamToKeyAndValue = param.split('=');
                recurrenceParamsObj[splitParamToKeyAndValue[0]] = splitParamToKeyAndValue[1];
            });

            return recurrenceParamsObj[requestedParam];
        };

        /**
         *
         * @param date- date object
         * @param days - number
         * @returns {*|string}- a date string of the original date + days
         */
        var incrementDateByDays = function (date, numOfDays) {
            var dateObj = new Date(date);
            dateObj.setDate(dateObj.getDate() + numOfDays); //update the date 1 day forward
            return dateObj;
        };


        var _createSpecialOccasionAllHoursInactiveEvents = function(parsedEventModel, timezone) {
            var events = [];

            //---- event properties ----
            var meta;
            var start;
            var end;
            var recurrence = [];
            //----------------------

            var parsedEventStartDate = parsedEventModel.getStartDate();
            var parsedEventEndDate = parsedEventModel.getEndDate();

            var recurrenceCount = Math.floor(_getDaysDiff(parsedEventEndDate, parsedEventStartDate)) + 1;

            meta = {
                name: parsedEventModel.getName(),
                working: parsedEventModel.getType() === EVENT_TYPES.ALL_HOURS
            };

            start = {
                dateTime: DateUtility.date2StrYYYYMMdd(parsedEventStartDate),
                timeZone: timezone
            };

            end = {
                dateTime: DateUtility.date2StrYYYYMMdd(incrementDateByDays(parsedEventStartDate, 1)),
                timeZone: timezone
            };

            if (recurrenceCount > 1) {
                recurrence[0] = "RRULE:FREQ=DAILY;COUNT=" + recurrenceCount + ";INTERVAL=1";
            }

            events.push({
                meta: meta,
                start: start,
                end: end,
                recurrence: recurrence
            });

            return events;
        };

        var _createSpecialOccasionCustomEvents = function(parsedEventModel, timezone) {
            var events = [];

            //---- event properties ----
            var meta;
            var start;
            var end;
            var recurrence = [];
            //----------------------

            var parsedEventStartDate = parsedEventModel.getStartDate();
            var parsedEventEndDate = parsedEventModel.getEndDate();

            var recurrenceCount = Math.floor(_getDaysDiff(parsedEventEndDate, parsedEventStartDate)) + 1;

            meta = {
                name: parsedEventModel.getName(),
                working: true
            };

            if (recurrenceCount > 1) {
                recurrence[0] = "RRULE:FREQ=DAILY;COUNT=" + recurrenceCount + ";INTERVAL=1";
            }

            var shiftsCollection = parsedEventModel.getShiftsCollection();

            var dateAsString = DateUtility.date2StrYYYYMMdd(parsedEventStartDate);

            shiftsCollection.each(function(shiftModel) {
                var startTimeStr = shiftModel.getStartTime();
                var endTimeStr = shiftModel.getEndTime();

                var startDateTime = dateAsString + DATE_AND_TIME_DELIMETER + startTimeStr;
                var endDateTime = dateAsString + DATE_AND_TIME_DELIMETER + endTimeStr;

                start = {
                    dateTime: startDateTime,
                    timeZone: timezone
                };
                end = {
                    dateTime: endDateTime,
                    timeZone: timezone
                };

                events.push({
                    meta: meta,
                    start: start,
                    end: end,
                    recurrence: recurrence
                });
            });

            return events;
        };

        /**
         *
         * @param parsedEvent - an object that represent a parsed event
         * @param timezone
         * @returns {Array} - an array of events that we created from the parsedEvent
         */
        var _createScheduleAllHoursEvents = function(parsedEventModel, timezone) {
            var events = [];

            //---- event properties ----
            var start;
            var end;
            var recurrence = [];
            //----------------------

            recurrence[0] = "RRULE:FREQ=WEEKLY";

            var startDateObj = getTheClosestDateOfARequestedDay(parsedEventModel.id);
            var startDateString = DateUtility.date2StrYYYYMMdd(startDateObj);

            var endDateObj = incrementDateByDays(startDateObj, 1);
            var endDateString = DateUtility.date2StrYYYYMMdd(endDateObj);

            start = {
                dateTime: startDateString,
                timeZone: timezone
            };
            end = {
                dateTime: endDateString,
                timeZone: timezone
            };

            events.push({
                start: start,
                end: end,
                recurrence: recurrence
            });

            return events;
        };

        /**
         *
         * @param parsedEvent - an object that represent a parsed event
         * @param timezone
         * @returns {Array} - an array of events that we created from the parsedEvent
         */
        var _createSchduleCustomEvents = function (parsedEventModel, timezone) {
            var events = [];

            //---- event properties ----
            var start;
            var end;
            var recurrence = [];
            //----------------------

            recurrence[0] = "RRULE:FREQ=WEEKLY";

            var dateObj = getTheClosestDateOfARequestedDay(parsedEventModel.id);
            var startDateString = DateUtility.date2StrYYYYMMdd(dateObj);
            var endDateString = startDateString;

            var shiftsCollection = parsedEventModel.getShiftsCollection();

            shiftsCollection.each(function(shiftModel) {
                var startTimeStr = shiftModel.getStartTime();
                var endTimeStr = shiftModel.getEndTime();

                var startDateTime = startDateString + DATE_AND_TIME_DELIMETER + startTimeStr;
                var endDateTime = endDateString + DATE_AND_TIME_DELIMETER + endTimeStr;

                start = {
                    dateTime: startDateTime,
                    timeZone: timezone
                };
                end = {
                    dateTime: endDateTime,
                    timeZone: timezone
                };

                events.push({
                    start: start,
                    end: end,
                    recurrence: recurrence
                });
            });

            return events;
        };

        var _getDaysDiff = function(date1, date2) {
            var ms = date1.getTime() - date2.getTime(); // miliseconds
            var firstDateOffset = date1.getTimezoneOffset(); // minutes
            var secondDateOffset = date2.getTimezoneOffset(); // minutes
            var offsetDifference = firstDateOffset - secondDateOffset; // minutes
            ms -= offsetDifference * 60 * 1000;
            return ms / (24*60*60*1000); //days
        };

        return {
            createScheduleEventsApiFormat: createScheduleEventsApiFormat,
            createSpecialOccasionEventsApiFormat: createSpecialOccasionEventsApiFormat,
            getTimezoneFromEvents: getTimezoneFromEvents,
            getScheduleEventDay: getScheduleEventDay,
            getEventType: getEventType,
            getShiftFromEvent: getShiftFromEvent,
            getParsedEvent: getParsedEvent,
            getTheClosestDateOfARequestedDay: getTheClosestDateOfARequestedDay,
            getShiftTimesOptionsForSingleSelect: getShiftTimesOptionsForSingleSelect,
            getShiftTimesOptionsForSingleSelectAsMap: getShiftTimesOptionsForSingleSelectAsMap,
            getSpecialOccasionEventId: getSpecialOccasionEventId,
            incrementDateByDays: incrementDateByDays
        };
    })();

    ScheduleUtilities.EVENT_TYPES = EVENT_TYPES;
    ScheduleUtilities.DAYS = DAYS;

    return ScheduleUtilities;
});
