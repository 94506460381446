<template>
  <div
    :title="notification && notification.tooltip"
    :tooltip-text="notification && notification.tooltip"
    tooltip-position="right"
    :class="{'pinned-app-notifications-bubble-container': isNavBarOpen}"
  >
    <div
      v-bind="notification && notification.count ? { id: customId } : {}"
      :class="['notification-bubble', { floating: !inline }]"
    >
      <span>
        {{ notification && notification.count }}
        <span class="sr-only">conversations with unread messages</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotificationBubble',
  props: {
    isNavBarOpen: {
      type: Boolean,
      required: false,
    },
    notification: {
      type: Object,
      default: () => ({}),
    },
    inline: {
      type: Boolean,
      default: true,
    },
    customId: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
@import '../../../assets/scss/global';
@import '../../../assets/scss/mixins';

.notification-bubble {
  height: 22px;
  width: 22px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;

  @include themify {
    background-color: getColor('notification-bubble-bg', 'framework');
    color: getColor('notification-bubble-text-color', 'framework');
  }

  &.floating {
    position: absolute;
    right: -5px;
    top: -5px;
  }
}

.pinned-app-notifications-bubble-container {
  margin-right: 10px;
}
</style>
