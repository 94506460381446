/**
 * User: itziky
 * Date: 11/28/13
 * Adds test for "line clamp" (AKA multi line ellipsis) feature. we can't use here standard Modernizr test, since this feature is not w3c standard.
 */
define(function (require) {
    "use strict";
    var Modernizr = require('modernizr.src');

    Modernizr.addTest('lineclamp', function () {
        var element = document.createElement("foo");
        // TODO loop over all possible prefixes
        return typeof element.style.webkitLineClamp !== "undefined";
    });

    return Modernizr;
});
