/**
 * Created with IntelliJ IDEA.
 * User: itaik
 * Date: 4/17/13
 * Time: 10:39 AM
 * To change this template use File | Settings | File Templates.
 */

/*
 Just hold the logic for building the route names
 */
import { Logger } from "vue-infra";

const logger = Logger.getLogger('LEFramework');
const RouteNameBuilder = (() => {
  const ver = '0.1';
  // context logger
  const delimiter = '/';

  function assembleName(prefix, route) {
    let assembledName = route && route !== '' ? route : prefix;
    if (prefix && prefix !== '' && route && route !== '') {
      if ((route.indexOf(prefix + delimiter) !== 0) &&
        (route.indexOf(delimiter + prefix + delimiter) !== 0)) {
        if(route.indexOf(delimiter) === 0){
          assembledName = delimiter + prefix + route;
        } else {
          assembledName = delimiter + prefix + delimiter + route;
        }

      } else if (assembledName.indexOf(delimiter) !== 0) {
        assembledName = delimiter + assembledName;
      }
    } else if (route === '') {
      assembledName = delimiter;
    }
    return assembledName;
  }
  function parseName(name, prefix) {
    const parsedName = {
      prefix: '',
      route: '',
    };

    const ind = name.indexOf(delimiter);

    if (ind === -1) {
      if (prefix) {
        parsedName.prefix = name;
      } else {
        parsedName.route = name;
      }
    } else {
      parsedName.prefix = name.substring(0, ind);
      parsedName.route = name.substring(ind + delimiter.length);
    }

    return parsedName;
  }

  function getRouteDelimiter() {
    return delimiter;
  }

  function getRoutePrefix(name) {
    return parseName(name, true).prefix;
  }

  function getRoute(name) {
    return parseName(name).route;
  }

  return {
    assembleName,
    parseName,
    getRoutePrefix,
    getRoute,
    getRouteDelimiter,
    version: () => {
      logger.info(`routeNameBuilder.js version ${ver}`);
    },
  };
})();
export default RouteNameBuilder;
