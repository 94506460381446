<template>
  <div :data-lp-at="setRingTitle + `-select-ring-item-button`" role="button" tabindex="0" @click="showRingSelectionDialog" @keyup.space.enter="showRingSelectionDialog" class="le-ring-selector-dialog" :class="{ disabled: disabled }">

<!--    <div class="item-title ellipsis ring-title">{{ titles.menuText }}</div>-->
    <div class="selected-ring-item" :data-lp-at="setRingTitle + `-select-ring-item`">{{ setRingTitle }}</div>

    <teleport to="#fe-root-mounting-portal-target">
      <le-dialog
        v-if="isShowRingSelectionDialog"
        :header="titles.header"
        :primaryButtonText="titles.confirm"
        :cancelText="titles.cancel"
        @primaryButtonClick="onConfirmRingSelection"
        @cancelClick="onCancelRingSelection"
        class="ringer-selection-dialog"
        :zIndex="3000"
        width="450px"
      >
        <template #content>
          <div class="ring-radio-group" role="radiogroup" tabindex="0" :aria-activedescendant="`radio-${selectedRing}`">
            <label for="default-ring-options" class="ringer-selection-title">{{ titles.defaultRingOption }}</label>
            <div class="default-ring-options" id="default-ring-options">
              <le-radio
                :checked="isRingChecked(DEFAULT_RING_ID)"
                :ariaLabel="titles.defaultRing"
                ref="default"
                class="ring-radio"
                id="radio-default"
                groupName="ring-radio-group"
                @keydown.down.right="onArrowKeyDownRadio(DEFAULT_RING_ID, false)"
                @keydown.up.left="onArrowKeyDownRadio(DEFAULT_RING_ID, true)"
                @change="onRingerSelected(DEFAULT_RING_ID)">
                <custom-ring-player
                  :class="{ selected: isRingChecked(DEFAULT_RING_ID) }"
                  :id="DEFAULT_RING_ID"
                  :text="titles.defaultRing"
                  :audioUrls="[getDefaultRingUrl()]"
                  @play="onPlay(DEFAULT_RING_ID)"
                  @stop="onStop(DEFAULT_RING_ID)"
                  :canPlay="canPlay"
                ></custom-ring-player>
              </le-radio>
            </div>
            <div class="separator"></div>
            <label for="custom-ring-options" class="ringer-selection-title">
              <div class="ringer-custom-text">{{ titles.customRingsTitle }}</div>
              <le-button class="ringer-custom-info theme-light" icon="ico-round-info" :tooltip="customRingsTooltip" variant="link" disabled="true"></le-button>
            </label>
            <div class="custom-ring-options" id="custom-ring-options">
              <le-radio
                v-for="ringType in ringerTypes"
                :key="ringType.id"
                :checked="isRingChecked(ringType.id)"
                :ariaLabel="ringType.text"
                :id="`radio-${ringType.id}`"
                :ref="ringType.id"
                class="ring-radio"
                groupName="ring-radio-group"
                @keydown.down.right="onArrowKeyDownRadio(ringType.id, false)"
                @keydown.up.left="onArrowKeyDownRadio(ringType.id, true)"
                @change="onRingerSelected(ringType.id)">
                <custom-ring-player
                  :class="{ selected: isRingChecked(ringType.id) }"
                  :id="ringType.id"
                  :text="ringType.text"
                  :audioUrls="ringType.urls"
                  @play="onPlay(ringType.id)"
                  @stop="onStop(ringType.id)"
                  :canPlay="canPlay"
                ></custom-ring-player>
              </le-radio>
            </div>
          </div>
        </template>
      </le-dialog>
    </teleport>
  </div>
</template>

<script>
  import { leRadio, leDialog, leButton } from '@liveperson/lp-vue-components';
  import { CustomRingsManager, Logger } from 'vue-infra';
  import { map, defer } from 'lodash';
  import CustomRingPlayer from '../CustomRingPlayer';
  import LocaleResolver from '../../../../assets/js/i18n/localeResolver.js';

  const DEFAULT_RING_ID = 'default';
  const logger = Logger.getLogger('LiveEngage:TopLevelMenu');

  export default {
    name: 'ring-selector-dialog',
    components: {
      leRadio,
      leDialog,
      leButton,
      CustomRingPlayer,
    },
    props: {
      disabled: {
        type: Boolean,
        required: true
      },
      setRing: {
        type: String,
        required: true
      },
      supportedRings: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        selectedRing: null,
        customRings: null,
        isShowRingSelectionDialog: false,
        titles: {
          menuText: this.$translator.translate('LEFramework.userBarMenu.footer.customRings.menuText'),
          defaultRing: this.$translator.translate('LEFramework.userBarMenu.footer.customRings.defaultRing'),
          defaultRingOption: this.$translator.translate('LEFramework.userBarMenu.footer.customRings.defaultRingOption'),
          customRingsTitle: this.$translator.translate('LEFramework.userBarMenu.footer.customRings.customOptions'),
          confirm: this.$translator.translate('LEFramework.global.captions.apply'),
          cancel: this.$translator.translate('LEFramework.global.captions.cancel'),
          header: this.$translator.translate('LEFramework.userBarMenu.footer.customRings.header'),
          ringSelectionDescription: this.$translator.translate('LEFramework.userBarMenu.footer.customRings.ringSelectionDescription'),
        },
        ringerTypes: null,
        currentlyPlaying: null,
        DEFAULT_RING_ID,
      };
    },
    mounted() {
      this.selectedRing = this.setRing || DEFAULT_RING_ID;
      const urlResolver = window.LE.urlResolver;
      this.customRings = CustomRingsManager.getCustomRings({standalone: true, urlResolver});
      this.ringerTypes = this.getRingerTypes();
      this.focusOnRing(this.selectedRing);
    },
    computed: {
      setRingTitle () {
        return this.translateRingTitle(this.selectedRing || DEFAULT_RING_ID) + " " + this.$translator.translate(`LEFramework.userBarMenu.sound.ring`);
      },
      customRingsTooltip () {
        return this.$translator.translate({ key: 'LEFramework.userBarMenu.footer.customRings.customTooltip', args: [CustomRingsManager.getMaxIndex()]});
      },
      canPlay () {
        return !this.currentlyPlaying;
      },
    },
    methods: {
      getDefaultRingUrl() {
        return this.customRings.getCurrentCustomRingURL(DEFAULT_RING_ID);
      },
      isRingChecked(ringId) {
        return this.selectedRing === ringId;
      },
      translateRingTitle(ringId) {
        if (!ringId || ringId.length < 1) {
          ringId = DEFAULT_RING_ID;
        }
        return this.$translator.hasTranslationKey(LocaleResolver.getDefaultLocale(),
            `LEFramework.userBarMenu.footer.customRings.ring.${ringId}`) ? this.$translator.translate(`LEFramework.userBarMenu.footer.customRings.ring.${ringId}`) :
            ringId.charAt(0).toUpperCase() + ringId.slice(1);
      },
      getRingerTypes() {
        return map(this.supportedRings, ringId => ({ id: ringId, value: ringId, text:
                this.translateRingTitle(ringId), playing: false, urls: this.customRings.generateCustomRingURLs(ringId) }));
      },
      focusOnRing(ringId) {
          let ref = this.$refs[ringId];
          if (ref && ref[0]) {
              ref = ref[0];
          }
          if (ref && ref.$el && ref.$el.focus) {
              ref.$el.focus();
          }
      },
      onArrowKeyDownRadio(ringId, isPrevious) {
          let ring;
          if (isPrevious) {
              ring = this.getPreviousRing(ringId);
          } else {
              ring = this.getNextRing(ringId);
          }
          this.focusOnRing(ring);
      },
      getNextRing(ringId) {
          const currentRingIndex = this.supportedRings.indexOf(ringId);
          if (currentRingIndex < 0) {
              // If it's not one of the custom rings - it's the default ring.
              // So we switch to the first custom ring
              return this.supportedRings[0];
          }
          if (currentRingIndex === this.supportedRings.length - 1) {
              // If it's the last custom ring - the next ring is the default ring.
              return DEFAULT_RING_ID;
          }
          return this.supportedRings[currentRingIndex + 1];
      },
      getPreviousRing(ringId) {
          const currentRingIndex = this.supportedRings.indexOf(ringId);
          if (currentRingIndex < 0) {
              // If it's not one of the custom rings - it's the default ring.
              // So we switch to the last custom ring
              return this.supportedRings[this.supportedRings.length - 1];
          }
          if (currentRingIndex === 0) {
              // If it's the first custom ring - the previous ring is the default ring.
              return DEFAULT_RING_ID;
          }
          return this.supportedRings[currentRingIndex - 1];
      },
      onRingerSelected(ringId) {
        if (ringId) {
          this.selectedRing = ringId;
        }
      },
      showRingSelectionDialog() {
        if (!this.disabled) {
          this.isShowRingSelectionDialog = true;
          this.$emit('clicked');
          logger.bam({
              serviceName: 'CCUI-CORE',
              appName: 'CCUI',
              eventName: 'open custom rings dialog',
              widgetName: 'user-menu',
          });
        }
      },
      onCancelRingSelection() {
        this.isShowRingSelectionDialog = false;
        this.selectedRing = this.setRing || DEFAULT_RING_ID;
      },
      async onConfirmRingSelection() {
        if( this.setRing !== this.selectedRing ){
          const resetCallback = CustomRingsManager.resetIndex.bind(CustomRingsManager);
          defer(() => {
            this.isShowRingSelectionDialog = false;
          });

          this.$emit('input', {selectedRing: this.selectedRing, resetCallback });
        } else {
          defer(() => {
            this.isShowRingSelectionDialog = false;
          });
        }
      },
      onPlay(ringId) {
        this.currentlyPlaying = ringId;
      },
      onStop(ringId) {
        if (this.currentlyPlaying === ringId) {
          this.currentlyPlaying = null;
        }
      },
    }
  };
</script>


<style scoped lang="scss">
  @import "../../../../assets/scss/global";
  @import "../../../../assets/scss/mixins";

  .le-ring-selector-dialog {
    font-family: Roboto;
    font-size: 12px;
    top: -10px; // didn't exist
    .ring-title {
      //margin-left: 55px; // was 39
    }

    .selected-ring-item {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      position: absolute;
      top: 9px;
      left: 95px;
      cursor: pointer;

      @include themify {
        color: getColor('ring-link-color', 'framework');
      }
    }

    &.disabled {
      cursor: default;
      .selected-ring-item, .ring-title {
        @include themify {
          color: getColor('ring-title-disabled','framework');
        }
      }
    }
  }

  .ringer-selection-dialog {
    .ringer-selection-description {
      padding: 2px 0;
    }

    .ringer-selection-title {
      padding: 10px 20px 5px;

      .ringer-custom-text {
        display: inline-block;
      }
      .ringer-custom-info {
        display: inline-block;
      }
    }

    .default-ring-options {
      margin-top: 5px;
    }

    .separator {
      width: 93%;
      border: 1px solid #ddd;
      margin: 5px auto;
    }

    .ring-radio {
      padding: 5px 20px;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      @include themify {
        &:active, &:focus, &:focus-within {
          .indicator {
            border-color: getColor('radioButtonBorderSelected');
          }
        }
      }

      &:hover, &:focus, &:focus-within {
        background: $gray30;
      }
    }
  }

</style>
