/**
 * Created by yoavp on 7/14/2015.
 */

define(function (require) {
    "use strict";
    var SlaUnits = require("codeTables/slaUnits.json");
  var _ = require('underscore');

    var slaUnitsSettings = (function () {

        var getSlaUnitsList = function () {
            return SlaUnits.slaUnitsCodes;
        };

        var getSlaUnits = function () {
            return SlaUnits.slaUnits;
        };

        var getSlaUnitsListTranslated = function () {
            if (_.isUndefined(SlaUnits.slaUnitsTranslated)) {
                translateSlaUnitsCodes();
            }

            return SlaUnits.slaUnitsTranslated;
        };

        var translateSlaUnitsCodes = function () {
            SlaUnits.slaUnitsTranslated = [];

            _.each(SlaUnits.slaUnitsCodes, function (value) {
                SlaUnits.slaUnitsTranslated.push({
                    "key": value,
                    "value": getTranslatedSlaUnits(value)
                });
            });
            return SlaUnits.slaUnitsTranslated;
        };

        var getTranslatedSlaUnits = function(value){
            var keyToTranslate;
            keyToTranslate = "LEFramework.slaUnitsCodes." + value;
            return LE.translator.translate(keyToTranslate);
        };

        var convertSec2OtherUnit = function(sec) {
            var time = sec / 60;
            var unit = SlaUnits.slaUnits.MINUTES;

            if (time % 60 === 0) {
                time  = time / 60;
                unit = SlaUnits.slaUnits.HOURS;

                if (time % 24 === 0) {
                    time  = time / 24;
                    unit = SlaUnits.slaUnits.DAYS;
                }
            }

            return {
                time: time,
                unit: unit
            };
        };

        var convertOtherUnit2Sec = function(time, unit) {
            var sec = time;

            if (unit === SlaUnits.slaUnits.MINUTES) {
                sec = time * 60;
            }
            else if (unit === SlaUnits.slaUnits.HOURS) {
                sec = time * 60 * 60;
            }
            else if (unit === SlaUnits.slaUnits.DAYS) {
                sec = time * 60 * 60 * 24;
            }

            return sec;
        };


        return {
            getSlaUnits: getSlaUnits,
            getSlaUnitsList: getSlaUnitsList,
            getTranslatedSlaUnits: getTranslatedSlaUnits,
            getSlaUnitsListTranslated: getSlaUnitsListTranslated,
            convertSec2OtherUnit: convertSec2OtherUnit,
            convertOtherUnit2Sec: convertOtherUnit2Sec
        };

    })();
    return slaUnitsSettings;
});
