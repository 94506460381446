/**
 * Created by tomers on 5/19/2015.
 */
define(function (require) {
    "use strict";

    var { sessionManager } = require("vue-infra");
    var _ = require("underscore");
    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");
    var UserModelAC = require("models/entities/UserModelAC");
    var UserModelAC_v3 = require("models/entities/UserModelAC_v3");
    var UserModelAS = require("models/entities/UserModelAS");
    var { COMPOUND_FEATURES_IDS } = require('vue-infra');
    var CONST = require("const");

    var NAME = "UserModel";
    var MODEL_VALIDATORS = Config.validators.user;
    var USER_TYPES = UserModelAC_v3.USER_TYPES;
    var USER_TYPES_KEYS = UserModelAC_v3.USER_TYPES_KEYS;
    var UNKNOWN_USER_TYPE_KEY = UserModelAC_v3.UNKNOWN_USER_TYPE_KEY;

    var MAX_CHATS_CONST = {
        NO_CHATS: "0",
        UNLIMITED: "Unlimited"
    };
    var KEYS = {
        PID: "pid",
        USER_TYPE: "userTypeId",
        IS_API_USER: "isApiUser",
        ALLOWED_APP_KEYS: "allowedAppKeys",
        EMAIL_ADDRESS: "emailAddress",
        LOGIN_NAME: "loginName",
        NICK_NAME: "nickName",
        DISPLAY_NAME: "displayName",
        EMPLOYEE_ID: "employeeId",
        PERMISSION_GROUP: "permissionGroup",
        ENABLED: "enabled",
        RESET_MFA: "resetMfaSecret",
        MAX_NUMBER_OF_CHATS: "maxNumberOfChats",
        MAX_NUMBER_OF_CONVERSATIONS: "maxAsyncChats",
        SKILLS: "skills",
        OLD_PASSWORD: "oldPassword",
        PASSWORD: "password",
        FULL_NAME: "fullName",
        NEW_PASSWORD: "passwordSh",
        MEMBER_OF: "memberOf",
        MANAGER_OF: "managerOf",
        GROUPS: "groups",
        PICTURE_URL: "pictureUrl",
        PROFILES: "profileIds",
        CHANGE_PASSWORD_NEXT_LOGIN: "changePwdNextLogin",

        MANAGED_AGENT_GROUPS: "managedAgentGroups",
        FULL_PROFILES: "profiles",
        LOBS: "lobIds"
    };

    var ERROR_CODES = {
        PASSWORD_RESTRICTIONS: "passwordRestrictions"
    };

    var UserModel = RevisionedModel.extend({
        MODEL_VALIDATORS: MODEL_VALIDATORS,
        MAX_CHATS_CONST: MAX_CHATS_CONST,
        USER_TYPES: USER_TYPES,
        USER_TYPES_KEYS: USER_TYPES_KEYS,
        KEYS: KEYS,
        ERROR_CODES: ERROR_CODES,
        NAME: NAME,
        defaults: {
            id: null,
            pid: null,
            emailAddress: "",
            loginName: "",
            userTypeId: USER_TYPES.HUMAN,
            isApiUser: false,
            allowedAppKeys: "",
            nickName: "",
            displayName: "",
            employeeId: "",
            enabled: true,
            maxNumberOfChats: 4,
            skills: [],
            password: "",
            oldPassword: "",
            pictureURL: null,
            changePwdNextLogin: false,
            lobIds: []
        },
        model: null,
        name: "UserModel",
        useMemento: true,
        initialize: function(attributes, options) {
            options = options || {};
            this.source = options.source;
            this.lastFetchSuccess = null;
            this._isMyUser = (_.has(options, "isMyUser")) ? options.isMyUser : false;
            if(CONST.SOURCE_ENUM.LEGACY === sessionManager.getAccountSettingValueByID(sessionManager.ACCOUNT_SETTINGS_ID.USERS_SOURCE)) {
                this.model = new UserModelAS({"isMyUser": this._isMyUser});
                this.isLegacy = true;
            }
            else if (sessionManager.hasProfilesFeature()) {
                this.model = new UserModelAC_v3({ "isMyUser": this._isMyUser, source: this.source });
                this.isLegacy = false;
                this.isProfiles = true;
            }
            else {
                this.model = new UserModelAC({"isMyUser": this._isMyUser});
                this.isLegacy = false;
            }
            if(attributes) {
                this.model._setValuesInModel(attributes);
                this._buildModel();
            }

            this.setAgentStatus(false);
            this.setAgentManagerStatus(false);

            this.listenTo(this.model, "invalid", this._throwInvalidEvent);

        },

        fetch: function(options) {
            options = options ? _.clone(options) : {};
            var origSuccess = options.success;
            var origError = options.error;
            var isError = false;
            var successWrapper = _.bind(function(data, resp, opt) {
                if (!isError) {
                    this._buildModel();
                    this._setFetchState(RevisionedModel.FetchState.SUCCESS);
                    this.lastFetchSuccess = new Date().getTime();
                    if (origSuccess) {
                        origSuccess(this, resp, options);
                    }
                }
            }, this);

            var errorWrapper = _.bind(function(data, resp, opt) {
                if (!isError) {
                    isError = true;
                    this._setFetchState(RevisionedModel.FetchState.ERROR);
                    origError(this, resp, options);
                }
            }, this);
            this.model.fetch({
                freshnessTime: options.freshnessTime,
                success: successWrapper,
                error: errorWrapper
            });
        },

        _buildModel: function() {   //todo this whole thing is a mess, need to have proper seperation.
            this.unset();
            this.set(KEYS.EMAIL_ADDRESS, this.model.getEmail());
            this.set(KEYS.LOGIN_NAME, this.model.getLoginName());
            this.set(KEYS.NICK_NAME, this.model.getNickName());
            this.set(KEYS.DISPLAY_NAME, this.model.getDisplayName());
            this.set(KEYS.EMPLOYEE_ID, this.model.getEmployeeId());
            this.set(KEYS.PID, this.model.getPid());
            if (sessionManager.hasUserTypeBotFeature()){
                this.set(KEYS.USER_TYPE, this.model.getUserType());
                this.set(KEYS.IS_API_USER, this.model.getIsApiUser());
                this.set(KEYS.ALLOWED_APP_KEYS, this.model.getAllowedAppKeys());
            }
            if (sessionManager.hasProfilesFeature()) {
                this.set(KEYS.PROFILES, this.model.getProfiles());
            }
            else {
                this.set(KEYS.PERMISSION_GROUP, this.model.getPermissionGroup());
            }
            this.set(KEYS.MAX_NUMBER_OF_CONVERSATIONS, this.model.getMaxConversations());
            this.set(KEYS.ENABLED, this.model.getEnabled());
            if(!this.isLegacy && sessionManager.getFeaturePropertyState(COMPOUND_FEATURES_IDS.NLS_ENABLED)) {
                this.set(KEYS.RESET_MFA, this.model.getResetMFA());
            }
            this.set(KEYS.MAX_NUMBER_OF_CHATS, this.model.getMaxNumberOfChats());
            this.set(KEYS.SKILLS, this.model.getSkills());
            this.set(KEYS.CHANGE_PASSWORD_NEXT_LOGIN, this.model.getChangePasswordNextLogin());
            if (sessionManager.hasEntitiesTaggingByLobFeature()) {
                this.set(KEYS.LOBS, this.model.getLobs());
            }
        },

        _throwInvalidEvent: function (model, errorObject) {
            this.trigger("invalid", model, errorObject);
        },
        getPid: function(){
            return this.model.getPid();
        },
        getUserType: function(){
          return this.model.get(KEYS.USER_TYPE);
        },
        getUserTypeKey: function(userType){
            var key = UNKNOWN_USER_TYPE_KEY;
            var type = !_.isUndefined(userType) ? userType : this.get(KEYS.USER_TYPE);
            if (this.model.getUserTypeKey){
                key = this.model.getUserTypeKey(type);
            }
            return key;
        },
        setUserType: function(userType, options) {
            if (sessionManager.hasUserTypeBotFeature()) {
                if (this.model.setUserType) {
                    this.model.setUserType(userType, options);
                }
                this.set(KEYS.USER_TYPE, userType, options);
            }
        },
        getIsApiUser: function(){
            return this.model.get(KEYS.IS_API_USER);
        },
        setIsApiUser: function(isApiUser, options){
            if (sessionManager.hasUserTypeBotFeature()) {
                if (this.model.setIsApiUser) {
                    this.model.setIsApiUser(isApiUser, options);
                }
                this.set(KEYS.IS_API_USER, isApiUser, options);
            }
        },
        getAllowedAppKeys: function() {
            return this.model.get(KEYS.ALLOWED_APP_KEYS);
        },
        setAllowedAppKeys: function(appKey, options) {
            if (sessionManager.hasUserTypeBotFeature()) {
                if (_.isNull(appKey)) {
                    appKey = "";
                }
                if (this.model.setAllowedAppKeys) {
                    this.model.setAllowedAppKeys(appKey, options);
                }
                this.set(KEYS.ALLOWED_APP_KEYS, appKey, options);
            }
        },
        getLoginName: function() {
            return this.get(KEYS.LOGIN_NAME);
        },

        getIsLegacy: function () {
            return this.isLegacy;
        },

        setLoginName: function(loginName, options) {
            this.model.setLoginName(loginName, {silent:true});
            this.set(KEYS.LOGIN_NAME, loginName, options);
        },

        getEmail: function() {
            return this.get(KEYS.EMAIL_ADDRESS);
        },

        setEmail: function(email, options) {
            this.model.setEmail(email, options);
            this.set(KEYS.EMAIL_ADDRESS, email, options);
        },

        getNickName: function() {
            return this.get(KEYS.NICK_NAME);
        },
        setNickName: function(nickName, options) {
            this.model.setNickName(nickName, options);
            this.set(KEYS.NICK_NAME, nickName, options);
        },
        getDisplayName: function() {
            return this.get(KEYS.DISPLAY_NAME);
        },
        setPictureUrl: function(url) {
            this.model.setPictureUrl(url);
            this.set(KEYS.PICTURE_URL, url);
        },
        getPictureUrl: function() {
            return this.model.getPictureUrl();
        },

        setPictureUrlValidated: function(value) {
            this.model.setPictureUrlValidated(value);
        },

        getPictureUrlValidated: function() {
            return this.model.getPictureUrlValidated();
        },

        setPictureUrlErrors: function(value){
            this.model.setPictureUrlErrors(value);
        },

        getPictureUrlErrors: function(){
            return this.model.getPictureUrlErrors();
        },
        getEmployeeId: function() {
            return this.get(KEYS.EMPLOYEE_ID);
        },
        setEmployeeId: function(EmployeeId, options) {
            this.model.setEmployeeId(EmployeeId, options);
            this.set(KEYS.DISPLAY_NAME, EmployeeId, options);
        },

        getMemberOfGroup: function() {
            if (this.model.get(this.model.KEYS.MEMBER_OF) && this.model.get(this.model.KEYS.MEMBER_OF).agentGroupId){
                return this.model.get(this.model.KEYS.MEMBER_OF).agentGroupId;
            }
        },
        getManagerOfGroup: function() {
            return this.model.get(KEYS.MANAGER_OF);
        },

        setMemberOfGroup: function(groupId){
            this.model.setMemberOfGroup(groupId);
            this.set(KEYS.MEMBER_OF, groupId);
        },
        setManagerOf: function(groups){
            var managerOf = [];
            if (groups) {
                _.each(groups, function(group) {
                    if (group) {
                        managerOf.push({"agentGroupId": group});
                    }
                });
            }
            this.model.setManagerOf(managerOf);
            this.set(KEYS.MANAGER_OF, managerOf);
        },
        setDisplayName: function(displayName, options) {
            this.model.setDisplayName(displayName, options);
            this.set(KEYS.DISPLAY_NAME, displayName, options);
        },
        getEnabled: function() {
            return this.get(KEYS.ENABLED);
        },
        setEnabled: function(enabled, options) {
            this.model.setEnabled(enabled, options);
            this.set(KEYS.ENABLED, enabled, options);
        },
        getResetMFA: function() {
            return this.get(KEYS.RESET_MFA);
        },
        setResetMFA: function(resetMFA, options) {
            this.model.setResetMFA(resetMFA, options);
            this.set(KEYS.RESET_MFA, resetMFA, options);
        },
        getPermissionGroup: function() {
            return this.model.getPermissionGroup(KEYS.PERMISSION_GROUP);
        },
        setPermissionGroup: function(permissionGroup, options) {
            this.model.setPermissionGroup(permissionGroup, options);
            this.set(KEYS.PERMISSION_GROUP, permissionGroup, options);
        },
        changePermissionGroup: function(permissionGroup) {
            if(this.isLegacy) {
                this.unsetPermissionGroup();
            } else {
                this.setPermissionGroup(permissionGroup);
            }
        },
        unsetPermissionGroup: function() {
            this.unset(KEYS.PERMISSION_GROUP);
            this.model.unsetPermissionGroup();
        },
        getMaxNumberOfChats: function() {
            return this.model.getMaxNumberOfChats(KEYS.MAX_NUMBER_OF_CHATS);
        },
        setMaxNumberOfChats: function(maxNumberOfChats, options) {
            this.model.setMaxNumberOfChats(maxNumberOfChats, options);
            this.set(KEYS.MAX_NUMBER_OF_CHATS, maxNumberOfChats, options);
        },
        getMaxConversations: function() {
            return this.model.getMaxConversations(KEYS.MAX_NUMBER_OF_CONVERSATIONS);
        },
        setMaxConversations: function(maxNumberOfConversations, options) {
            this.model.setMaxConversations(maxNumberOfConversations, options);
            this.set(KEYS.MAX_NUMBER_OF_CONVERSATIONS, maxNumberOfConversations, options);
        },
        getSkills: function() {
            return this.model.getSkills();
        },
        setSkills: function(skills, options) {
            this.model.setSkills(skills, options);
            this.set(KEYS.SKILLS, skills, options);
        },
        addSkill: function(skill) {
            var skills = this.getSkills();
            if (!_.include(skills, skill)) {
                skills.push(skill);
                this.setSkills(skills);
            }
        },
        getProfiles: function() {
            return this.model.getProfiles();
        },
        setProfiles: function(profiles, options) {
            this.model.setProfiles(profiles, options);
            this.set(KEYS.PROFILES, profiles, options);
        },
        addProfile: function(profile) {
            var profiles = this.getProfiles() || [];
            if (!_.include(profiles, profile)) {
                profiles.push(profile);
                this.setProfiles(profiles);
            }
        },
        getPassword: function() {
            return this.model.getPassword();
        },
        setPassword: function(password, options) {
            this.model.setPassword(password, options);
            this.set(KEYS.PASSWORD, password, options);
        },
        getOldPassword: function() {
            return this.model.getOldPassword();
        },
        setOldPassword: function(oldPassword, options) {
            this.model.setOldPassword(oldPassword, options);
            this.set(KEYS.OLD_PASSWORD, oldPassword, options);
        },
        getChangePasswordNextLogin: function() {
            return this.model.getChangePasswordNextLogin();
        },
        setChangePasswordNextLogin: function(changePwdNextLogin) {
            this.model.setChangePasswordNextLogin(changePwdNextLogin);
            this.set(KEYS.CHANGE_PASSWORD_NEXT_LOGIN, changePwdNextLogin);
        },
        getGroups: function() {
            return this.model.getGroups();
        },
        getManagerOf: function() {
            return this.model.getManagerOf();
        },
        getMemberOf: function() {
          return this.model.getMemberOf();
        },
        getLobs: function() {
            return this.model.getLobs();
        },
        setLobs: function(lobIds) {
            this.model.setLobs(lobIds);
            this.set(KEYS.LOBS, lobIds);
        },
        isMismatchLobsAndSkills: function(skillsCollection) {
            var isMismatch = false;
            if (this.model.isMismatchLobsAndSkills) {
                isMismatch = this.model.isMismatchLobsAndSkills(skillsCollection);
            }
            return isMismatch;
        },

        /**
         * When you want to save the current logged in user (my user) you have to declare it in the options when you init the model {isMyUser:true}
         * In addition to handling the validation for this specific case (oldPassword),
         * when we want to save the current logged in user (my user) we cannot send the "enabled" attribute. so we unset it before the save and reset it after
         * TODO: remove this "hack" of unsetting the "enabled" after USERS api move to AC
         * @param key
         * @param val
         * @param options
         * @returns {Session}
         */
        save: function(attributes, options) {
            //TODO Omar: fix the getEnable hack.
            var enabled = this.getEnabled();
            if (this._isMyUser) { // when we save our user - we cannot send the enabled attribute
                this.unset(KEYS.ENABLED, {silent:true});
            }
            this.model.save(attributes, options);
            this.setEnabled(enabled, {silent:true});
        },

        validateSaveEnabled: function () {
            return this.model.validateSaveEnabled();
        },

        validate: function () {
            return this.model.validate();
        },

        setAgentStatus: function(status) {
            this.model.setAgentStatus(status);
            this.isAgent = status;
        },

        getAgentStatus: function() {
            return this.model.getAgentStatus();
        },

        setAgentManagerStatus: function(status) {
          if (this.model.setAgentManagerStatus) {
            this.model.setAgentManagerStatus(status);
            this.isAgentManager = status;
          }
        },

        getAgentManagerStatus: function() {
          if (this.model.getAgentManagerStatus) {
            return this.model.getAgentManagerStatus();
          }
          return false;
        },

        getKeys: function() {
            return this.model.KEYS;
        },

        getErrorCodes: function() {
            return this.model.getErrorCodes();
        },

        getManagedAgentGroups: function() {
            return this.model.get(KEYS.MANAGED_AGENT_GROUPS);
        },

        getFullProfiles: function() {
            return this.model.get(KEYS.FULL_PROFILES);
        },

        hasRoleType: function(profilesCollection, roleType) {
            return this.model.hasRoleType(profilesCollection, roleType);
        },

        restore: function() {
            this.model.restore();
            this.model.store();
            this._buildModel();
        }
    });

    UserModel.NAME = NAME;
    UserModel.MODEL_VALIDATORS = MODEL_VALIDATORS;
    UserModel.USER_TYPES = USER_TYPES;
    UserModel.USER_TYPES_KEYS = USER_TYPES_KEYS;

    return UserModel;
});

