<template>
  <div class="top-level-links">
    <div
      class="top-level-links-switch"
    >
      <div
        ref="dropdownButton"
        class="top-level-links-icon s-question-mark"
        role="button"
        tabindex="0"
        :aria-label="linkLabel"
        :aria-expanded="menuVisible || null"
        aria-haspopup="true"
        :class="{ clicked: menuVisible }"
        @click.exact="toggleFirstTimeReturnExperience"
        @click.shift="toggleFirstTimeWelcomeExperience"
        @keyup.enter.space="toggleFirstTimeReturnExperience"
      ></div>
    </div>
    <div
      class="top-level-links-dropdown"
      :style="{ display: menuDisplay }"
      :class="menuStateClass"
    >
      <div
        ref="dropdownContainer"
        class="top-level-links-dropdown-container"
        tabindex="-1"
      >
        <top-level-link
          v-for="(link) in links"
          :key="link.title"
          :title="link.title"
          :icon-class="link.iconClass"
          :url="link.url"
          role="button"
          tabindex="0"
          @clicked="hideMenu"
        />
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { gChannel, sessionManager, Logger } from 'vue-infra';
import TopLevelLink from './TopLevelLink';
import LEConfig from 'assets/config/le-env-conf';
import lpUtils from 'utils/lpUtils';

const topLevelLinks = LEConfig.topLevelExternalLinks || {};
import CompoundFeatureIds from 'assets/constants/compound-features-ids.json';
let outsideClickHandler;
const logger = Logger.getLogger('LiveEngage:TopBar');

export default {
  name: 'TopLevelLinks',
  components: {
    TopLevelLink
  },
  data() {
    return {
      firstTimeExperienceEnabled: true,
      linkLabel: this.$translator.translate(
        'LEFramework.topLevelLinks.linkLabel'
      ),
      menuVisible: false,
      menuStateClass: 'closed',
      links: [
        {
          title: this.$translator.translate('LEFramework.topLevelLinks.link.knowledgeCenter'),
          iconClass: '',
          url: topLevelLinks.knowledgeCenter,
        },
        {
          title: this.$translator.translate('LEFramework.topLevelLinks.link.developersCommunity'),
          iconClass: '',
          url: topLevelLinks.developersCommunity,
        },
      ],
    };
  },
  computed: {
    menuDisplay() {
      return this.menuVisible ? 'block' : 'none';
    },
  },
  created() {
    outsideClickHandler = event => this.checkForOutsideClick(event);
    let firstTimeExperienceEnabled = sessionManager.getFeaturePropertyState(
      CompoundFeatureIds.FIRST_TIME_EXPERIENCE_ENABLED
    );
    this.firstTimeExperienceEnabled = firstTimeExperienceEnabled;
  },
  methods: {
    sendMenuOpenBamEvent() {
      logger.bam({
        serviceName: 'CCUI-CORE',
        appName: 'CCUI',
        eventName: 'question opened',
        widgetName: 'header',
      });
    },
    hideMenu() {
      this.menuStateClass = 'closed';
      lpUtils.removeEventListener(document, 'keydown', this.escKeyDownHandler);
      lpUtils.removeEventListener(document, 'click', outsideClickHandler);
      setTimeout(() => this.menuVisible = false, 400);
      setTimeout(() => this.$refs.dropdownButton.focus(), 250);
    },
    escKeyDownHandler(event) {
      if(event.key === "Escape"){
      this.hideMenu();
     }
    },
    showMenu() {
      this.menuVisible = true;
      lpUtils.addEventListener(document, 'click', outsideClickHandler);
      lpUtils.addEventListener(document, 'keydown', this.escKeyDownHandler);
      setTimeout(() => this.menuStateClass = 'open', 100);
      setTimeout(() => this.$refs.dropdownContainer.focus(), 250);
      this.sendMenuOpenBamEvent();
    },
    toggleFirstTimeReturnExperience() {
      if (this.firstTimeExperienceEnabled) {
        gChannel.trigger('LiveEngageCommon', 'FirstTimeExperienceModal:open');
        this.sendMenuOpenBamEvent();
      } else {
        if (this.menuVisible) {
          this.hideMenu();
        } else {
          this.showMenu();
        }
      }
    },
    toggleFirstTimeWelcomeExperience() {
      if (this.firstTimeExperienceEnabled) {
        gChannel.trigger(
          'LiveEngageCommon',
          'FirstTimeExperienceModal:open-initial'
        );
      }
    },
    checkForOutsideClick(event) {
      if (this.menuVisible && !this.$el.contains(event.target)) {
        this.hideMenu();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
  @import "../../../../assets/scss/global";
  @import "../../../../assets/scss/mixins";



  .top-level-links-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    @include themify {
      color: getColor('text','framework');
      &.clicked, &:hover {
        background: getColor('data-bar-icon-hover-background', 'framework');
        border-radius: 8px;
      }
    }
    height: 32px;
    width: 32px;
    font-size: 12px;
    cursor: pointer;
  }

  $icons: s-question-mark;
  @include add-icons-from-list($icons, 50%, 50%);

  .top-level-links-icon {
    width: 20px;
    height: 20px;
    font-size: 17px;
    object-fit: contain;
    @include themify {
      color: getColor('data-bar-icons-active', 'framework');
      &.clicked, &:hover {
        color: getColor('data-bar-icons-hover', 'framework');
      }
    }
  }

  .top-level-links-dropdown {
    transition: all 400ms;
    display: none;
    position: absolute;
    min-width: 220px;
    top: 55px;
    right: -3px;
    z-index: 99999;
    padding: 0 3px 0 3px;
    @include border-radius(2px);
    @include box-shadow(0 0 3px 1px rgba(50, 51, 53, 0.25));
    @include themify {
      background-color: getColor('framework-dropdown-bg', 'framework');
      color: getColor('framework-dropdown-item-text', 'framework');

      &:before {
        position: absolute;
        top: -12px;
        right: 10px;
        @include inline-block;
        border-right: 11px solid transparent;
        border-bottom: 12px solid getColor('framework-dropdown-bg', 'framework');
        border-left: 11px solid transparent;
        content: '';

      }

      &:after {
        position: absolute;
        top: -11px;
        right: 10px;
        @include inline-block;
        border-right: 10px solid transparent;
        border-bottom: 11px solid getColor('framework-dropdown-bg', 'framework');
        border-left: 10px solid transparent;
        content: '';
      }
    };
  }

  .top-level-links-dropdown.open {
    opacity: 1;
  }

  .top-level-links-dropdown.closed {
    opacity: 0;
  }

  .top-level-links-dropdown-container {
    min-width: 220px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  @media(max-width: 1300px) {
    .top-level-links-switch {
      //padding-left: 10px;
    }
  }
</style>

