/**
 * Created by tomers on 5/8/2016.
 *
 * BaseDataManager
 * Base controller for data management in LE modules.
 * Manage the shared and dependent collections (or any other type of data) across the module.
 * This base manager should be extended by a specific DataManager in each of the modules (the same as RevisionedCollection is extended by CampaignsCollection)
 * NOTE: In order to initialize this Base manager correctly you need to call its initialize prototype method before any other code running in your extended instance.
 *
 * When you initialize a new Data Controller you can pass "module" parameter. This parameter can contains the following:
 * - name - module name. pass it if you want the data manager to auto listen when you go in and out from the module. probably you should pass it only for the 3 modules you have at the top bar (visitors/campaigns/users).
 * - notifier - pointer to a notifier component instance that exists on the module and responsible for show the "not updated data" notification.
 * - mainRegion - pointer to the mainRegion of the module. Must if you want to show a "NoData" view if dependencies get error.
 * - showLoader - pointer to a method that shows loader on top of the relevant module.
 * - closeLoader - pointer to a method that closes the loader on top of the relevant module.
 *
 *
 * This Base manager expose some apis:
 * - addDataObj - Add data instance to be managed by this manager. When you add data you need to specify its unique name, the instance of the data and set of default parameters (like fetchInterval, isMandatory etc)
 * - getDATA-NAME - Each of the data that been added to the manager can be get by calling getDATA-NAME (while DATA-NAME replaced with the real data name that was passed). example: getCampaignsCollection returns the data instance that was added with the name CampaignsCollection.
 * - getData - Get as parameter a name and returns the data instance
 * - getDependencies - The main method of the data manager. Get configuration for each of the data that needed, and calls success or error callbacks according to the data fetching.
 * - updateCollection - Get an updated model, and update it in the correct collection. Iterate over all the collections it has, found the correct collection according to instanceof on its model, and update the model in the collection.
 * - getNameById - Get the unique name of the data, and id. Returns the name the relates to this id (or empty string if not found)
 *
 * Each of the attributes in the configuration object can be passed when you add the data to the manager and can be override when you want to get this data in getDependencies.
 * The following attributes can be passed:
 * - fetchInterval - Number (in millis). Default: 60000 (1 minute).Used as interval when we need the data to be persistent.
 * - freshnessTime - Number (in millis). Default: 0. Represents the time we consider the data to be "new".
 * - isMandatory - Boolean. Default: FALSE. Whether or not the data is mandatory to call the success method. NOTE: an OLD data is sufficient to call the success method, but at least one successful fetch is mandatory to call it.
 * - isPersist - Boolean. Default: FALSE. Whether or not the data keeps refreshing every 'fetchInterval' millis.
 * - isLazy - Boolean. Default: FALSE. Whether or not the success callbacks wait for this data to return anyway. TRUE - manager can call the success method before the fetch returns successfully or unsuccessfully. FALSE - manager waits for response - success/error no matter what.
 * - fetchOptions - Object. Default {} (an empty object). Object to pass in the 'options' of the fetch. will be hanged on the 'options' object as 'fetchOptions', so you can touch it in the callbacks as 'options.fetchOptions'
 * - retryInterval - Number (in millis). Default: 1000 (1 second). The time the manager waits before retrying again to reach a data that returns with error. This interval doubled itself when the number of retries reaches the 'maxRetries' parameter.
 * - maxRetries - Number. Default: 2. The number of times the manager accepts a failed fetch of data before doubling the interval between the retries.
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var Marionette = require("marionette");
    var _ = require("underscore");
    var { Logger } = require('vue-infra');
    var RevisionedModel = require("models/RevisionedModel");
    var BaseCollection = require("collections/BaseCollection");
    var NoData = require("ui.components/no-data/NewNoData");
    var Translator = require("i18n/translator");

    var FETCH_INTERVAL = 60000; // 1 minute
    var FRESHNESS_TIME = 0;
    var MAX_RETRIES = 2;
    var RETRY_INTERVAL = 1000; // 1 second

    var DATA_DEFAULT_OPTIONS = {
        dependencyName: "", // should not be passed from outside
        fetchInterval: FETCH_INTERVAL, // CAN be passed from outside
        freshnessTime: FRESHNESS_TIME, // CAN be passed from outside
        isMandatory: false, // CAN be passed from outside
        isPersist: false, // CAN be passed from outside
        isLazy: false, // CAN be passed from outside
        fetchOptions: {}, // CAN be passed from outside. this object will be passed on the fetch options as "fetchOptions" so everyone can get these options in the response as "options.fetchOptions"
        retryInterval: RETRY_INTERVAL, // CAN be passed from outside
        maxRetries: MAX_RETRIES, // CAN be passed from outside
        requestReturned: false, // should not be passed from outside
        requestState: BaseCollection.FetchState.NONE, // should not be passed from outside
        retriesCount: 0, // should not be passed from outside,
        initMaxRetries: MAX_RETRIES, // should not be passed from outside. used to store the init max retries so we can reset the max retries to its init value when data is successfully fetched
        initRetryInterval: RETRY_INTERVAL // should not be passed from outside. used to store the init retry interval so we can reset the interval to its init value when data is successfully fetched
    };

    var BaseDataManager = Marionette.Controller.extend({
        // NOTE!!! This should be called before the extended initialize functionality!
        initialize: function (options) {
            this._baseInitialize(options);
        },

        addDataObj: function (name, data, options) {
            this._addDataObj(name, data, options);
        },

        getDependencies: function (options) {
            return this._getDependencies(options);
        },

        startFetching: function () {
            this._startFetching();
        },

        stopFetching: function () {
            this._stopFetching();
        },

        updateCollection: function (model, options) {
            return this._updateCollection(model, options);
        },

        getData: function (name) {
            return this._getData(name);
        },

        getNameById: function (collectionName, id, nameAttr) {
            return this._getNameById(collectionName, id, nameAttr);
        },

        isFetchActive: function () {
            return this._isFetchActive();
        },

        showNoDataView: function () {
            this._showNoDataView();
        },

        refreshData: function() {
            this._refreshData();
        },


        /**
         * @param options - options might has "module" object with these attributes:
         * name - module name
         * showLoader - pointer to a module showLoader method
         * closeLoader - pointer to a module closeLoader method
         * mainRegion - the main region of the module so datamanager can handle the NoData view by itself
         * @private
         */
        _baseInitialize: function (options) {
            options = options || {};

            this._baseLogger = Logger.getLogger("LEFramework");
            this._baseLogger.info("init BaseDataManager", "BaseDataManager:_baseInitialize");

            this._dataMap = {};
            this._getDependenciesOptions = {};
            this._fetchId = null;
            this._notifierView = null;
            this._currentFetching = {};
            this._module = options.module;

            this._baseBindEvents();
        },

        _baseBindEvents: function () {
            this._baseLogger.debug("bind events BaseDataManager", "BaseDataManager:_baseBindEvents");

            if (this._module && !_.isEmpty(this._module.name)) {
                this.listenTo(window.LE.context, "change:activeModuleName", _.bind(this._baseOnActiveModuleChange, this));
            }
        },

        /**
         * We are listening to the module change because:
         * if the user exits the module - we stop fetching data. when he and re-enters the module we start start fetching again (if it's not already fetching)
         * @param context - LE.context
         * @param activeModuleName - the current active module
         * @private
         */
        _baseOnActiveModuleChange: function (context, activeModuleName) {
            this._baseLogger.debug("on activeModuleName change", "BaseDataManager:_baseBindEvents");

            if (activeModuleName !== this._module.name) {
                this._stopFetching();
            } else if (!this.isFetchActive()) {
                this._startFetching();
            }
        },

        /**
         * Get the required data object (that previously added with "addDataObj") and call the success method once after all dependencies are fetched
         * @param options - {}. should include: 1) "dependencies" - array of data names or objects with "name" and "options" attributes. 2) "success" - success method. 3) "error" - error method.
         * @private
         */
        _getDependencies: function (options) {
            options = options || {};
            this._baseLogger.info("get dependencies", "BaseDataManager:_getDependencies", options);

            this._getDependenciesOptions = options;

            this._stopFetching();
            this._currentFetching = this._prepareDependenciesForFetch(options.dependencies);
            this._startFetching();
        },

        /**
         * Start fetching the "current" data objects. The "this._currentFetching" should be filled outside and before this method.
         * @private
         */
        _startFetching: function () {
            this._baseLogger.debug("start fetching the current data objects", "BaseDataManager:_startFetching", this._currentFetching);

            this._fetchId = (new Date()).getTime().toString();
            this._callbackStates = {
                errorCalled: false,
                successCalled: false
            };

            this._showLoader();

            if (_.isEmpty(this._currentFetching)) {
                this._checkForAllDependenciesReturned();
            } else {
                _.each(this._currentFetching, _.bind(function (dependencyObj) {
                    this._fetchData(dependencyObj, this._fetchId);
                }, this));
            }

        },

        _fetchData: function (dependencyObj, fetchId) {
            var dependencyName = dependencyObj.dependencyName;
            var dependencyData = this._getData(dependencyName);

            if (fetchId === this._fetchId) {
                this._baseLogger.debug("fetch dependency: " + dependencyName, "BaseDataManager:_fetchData");

                dependencyData.fetch({
                    fetchId: fetchId,
                    dependencyName: dependencyObj.dependencyName,
                    fetchInterval: dependencyObj.fetchInterval,
                    freshnessTime: dependencyObj.freshnessTime,
                    fetchOptions: dependencyObj.fetchOptions,
                    success: _.bind(this._onFetchDataSuccess, this),
                    error: _.bind(this._onFetchDataError, this)
                });
            }
        },

        _onFetchDataSuccess: function (data, response, options) {
            var dependencyName = options.dependencyName;
            this._baseLogger.debug("fetch dependency successfully. dependency name: " + dependencyName, "BaseDataManager:_onFetchDataSuccess");

            if (this._fetchId === options.fetchId) {
                this._currentFetching[dependencyName].requestReturned = true;
                this._currentFetching[dependencyName].requestState = BaseCollection.FetchState.SUCCESS;
                this._currentFetching[dependencyName].retriesCount = 0;
                this._currentFetching[dependencyName].maxRetries = this._currentFetching[dependencyName].initMaxRetries;
                this._currentFetching[dependencyName].retryInterval = this._currentFetching[dependencyName].initRetryInterval;

                if (this._currentFetching[dependencyName].isPersist) {
                    _.delay(_.bind(this._fetchData, this, this._currentFetching[dependencyName], options.fetchId), this._currentFetching[dependencyName].fetchInterval);
                }

                this._checkForAllDependenciesReturned();
            }
        },

        _checkForAllDependenciesReturned: function () {
            if (this._isAllDependenciesReturned()) {
                this._baseLogger.debug("all dependencies are fetched", "BaseDataManager:_checkForAllDependenciesReturned");
                if (!this._isDataNotUpdated()) {
                    this._closeNotUpdatedError();
                }

                if (!this._callbackStates.successCalled) {
                    this._baseLogger.debug("this is the first time that all dependencies are fetched", "BaseDataManager:_checkForAllDependenciesReturned");
                    if (this._isDataNotUpdated()) {
                        this._showNotUpdatedError();
                    }
                    this._callbackStates.successCalled = true;
                    this._callbackStates.errorCalled = false;

                    if (this._getDependenciesOptions.success) {
                        this._baseLogger.debug("call the external success handler that was passed to getDependencies", "BaseDataManager:_checkForAllDependenciesReturned");
                        this._getDependenciesOptions.success();
                    }
                    this._closeLoader();

                    return true;
                }
            }
        },

        _onFetchDataError: function (data, response, options) {
            var dependencyName = options.dependencyName;
            this._baseLogger.debug("error while fetch dependency. dependency name: " + dependencyName, "BaseDataManager:_onFetchDataError");

            if (this._fetchId === options.fetchId) {
                this._currentFetching[dependencyName].retriesCount++;

                var isMandatory = this._currentFetching[dependencyName].isMandatory;
                var hasAtLeastOneSuccessfulFetch = this._getData(dependencyName).hasAtLeastOneSuccessfulFetch();
                this._currentFetching[dependencyName].requestReturned = !isMandatory || hasAtLeastOneSuccessfulFetch; // a collection is considered as returned if it's not mandatory or it is, but we already have some data.
                this._currentFetching[dependencyName].requestState = BaseCollection.FetchState.ERROR; // a collection is considered as returned if it's not mandatory or it is, but we already have some data.

                this._checkForMaxRetries(dependencyName);
                _.delay(_.bind(this._fetchData, this, this._currentFetching[dependencyName], options.fetchId), this._currentFetching[dependencyName].retryInterval);

                this._checkForAllDependenciesReturned();
            }
        },

        _checkForMaxRetries: function (dependencyName) {
            var isMandatory = this._currentFetching[dependencyName].isMandatory;
            var retriesCount = this._currentFetching[dependencyName].retriesCount;
            var initMaxRetries = this._currentFetching[dependencyName].initMaxRetries;
            var maxRetries = this._currentFetching[dependencyName].maxRetries;
            var retryInterval = this._currentFetching[dependencyName].retryInterval;
            var hasAtLeastOneSuccessfulFetch = this._getData(dependencyName).hasAtLeastOneSuccessfulFetch();

            if (retriesCount === maxRetries) {
                if (isMandatory && !hasAtLeastOneSuccessfulFetch) { // If dependency failed, its mandatory and we don't have any data - we trigger the error callback.
                    if (!this._callbackStates.errorCalled) {
                        this._baseLogger.debug("this is the max tries (and the first time) that we get an error for mandatory collection", "BaseDataManager:_checkForMaxRetries");
                        this._callbackStates.errorCalled = true;
                        this._callbackStates.successCalled = false;
                        this._showNoDataView();
                        if (this._getDependenciesOptions.error) {
                            this._baseLogger.debug("call the external error handler that was passed to getDependencies", "BaseDataManager:_onFetchDataError");
                            this._getDependenciesOptions.error();
                        }
                        this._closeLoader();
                    }
                }

                // Every MAX RETRIES of failed fetches we double the interval and the retries to infinite retries (with doubled interval) to get the real data.
                this._currentFetching[dependencyName].retryInterval = retryInterval * 2;
                this._currentFetching[dependencyName].maxRetries = maxRetries + initMaxRetries;
            }
        },

        /**
         * Stop fetching the "current" data objects. Using the stop method that defined in the data object - if exists.
         * @private
         */
        _stopFetching: function () {
            this._baseLogger.debug("stop fetching the current data objects", "BaseDataManager:_stopFetching", this._currentFetching);

            // If we are in a middle of fetching and no callback is called yet (so loader is still on the page...) we need to close the loader
            if (this.isFetchActive() && !this._callbackStates.successCalled && !this._callbackStates.errorCalled) {
                this._closeLoader();
            }

            _.each(this._currentFetching, _.bind(function (dependencyObj) {
                var dependencyName = dependencyObj.dependencyName;
                this._baseLogger.debug("stopping dependency: " + dependencyName, "BaseDataManager:_stopFetching");
                this._currentFetching[dependencyName].requestReturned = false;
                this._currentFetching[dependencyName].requestState = BaseCollection.FetchState.NONE;
                this._currentFetching[dependencyName].retriesCount = 0;
                this._currentFetching[dependencyName].maxRetries = this._currentFetching[dependencyName].initMaxRetries;
                this._currentFetching[dependencyName].retryInterval = this._currentFetching[dependencyName].initRetryInterval;
            }, this));

            this._fetchId = null;

            this._closeNotUpdatedError();
        },

        /**
         * Check if all current data dependencies returned from server or it is lazy so we don't need to wait for it
         * @returns {boolean}
         * @private
         */
        _isAllDependenciesReturned: function () {
            var allFetched = true;
            _.each(this._currentFetching, _.bind(function (dependencyObj) {
                var isDependencyReturned = dependencyObj.requestReturned || dependencyObj.isLazy;
                allFetched = allFetched && isDependencyReturned;
            }, this));

            return allFetched;
        },

        /**
         * Get the requested dependencies and prepare its options for this specific fetch. merge the default options for this data and the specific options in this specific getDependencies
         * @param dependencies - the array of dependencies that passed to getDependencies. each item can be string or object. string - for dependencyName, object - with attributes 'name' and 'options'
         * @returns {object} currentFetchingObj - the object to be assigned to this._currentFetching
         * @private
         */
        _prepareDependenciesForFetch: function (dependencies) {
            var currentFetchingObj = {};

            _.each(dependencies, _.bind(function (dependency) {
                var dependencyObj = _.isObject(dependency) ? dependency : {name: dependency};
                var dependencyName = dependencyObj.name;
                var defaultOptions = this._dataMap[dependencyName];
                var specificOptions = dependencyObj.options || {};
                if (_.isUndefined(dependency.isCollectionAllowed) || dependency.isCollectionAllowed()) {
                    currentFetchingObj[dependencyName] = $.extend({}, defaultOptions, specificOptions);
                }
            }, this));

            return currentFetchingObj;
        },

        /**
         * Add a data object to the dataMap. This data can be used later with getDependencies and get fetched
         * @param name - String. The data name. should be unique. (used as the "key" in the dataMap oobject)
         * @param data - instance of Model/Collection. The data instance you want to fetch afterwards.
         * @param options - Object. options for data object. attributes that can be sent are documented in DATA_DEFAULT_OPTIONS
         * @private
         */
        _addDataObj: function (name, data, options) {
            options = options || {};

            this._baseLogger.debug("add data object to data map", "BaseDataManager:_addDataObj", {
                name: name,
                data: data,
                options: options
            });

            var obj = $.extend({}, DATA_DEFAULT_OPTIONS, options, {data: data});
            obj.dependencyName = name;
            obj.initMaxRetries = obj.maxRetries;
            obj.initRetryInterval = obj.retryInterval;

            this._dataMap[name] = obj;

            this._publishMethods(name);
        },

        /**
         * Publish on "this" all the "get" methods - for example: CampaignsCollection -> getCampaignsCollection will be published on "this"
         * @param name
         * @private
         */
        _publishMethods: function (name) {
            this["get" + name] = _.bind(function (name) {
                return this._getData(name);
            }, this, name);
        },

        _getData: function (dataName) {
            return (this._dataMap[dataName]) ? this._dataMap[dataName].data : undefined;
        },

        _getNameById: function (collectionName, id, nameAttr) {
            nameAttr = nameAttr || "name";
            var collection, model;
            var name = "";

            if (id && collectionName) {
                collection = this._getData(collectionName);
                if (collection) {
                    model = collection.get(id);
                    if (!_.isEmpty(model)) {
                        name = model.get(nameAttr);
                    }
                }
            }
            return name;
        },

        /**
         * Update a collection on the with a new/existing model
         * If you would like the model to be jsonized ( for example the model is decorated and you dont want it back on the collection
         * pass option.tojson: true
         * All other options are passed to the collection add function
         * @param model
         * @param options
         */
        _updateCollection: function (model, options) {
            options = options || {};
            options.merge = _.has(options, "merge") ? options.merge : true;
            options.tojson = _.has(options, "tojson") ? options.tojson : true;
            options.exclusively = _.has(options, "exclusively") ? options.exclusively : true;

            this._baseLogger.info("update collection with model", "BaseDataManager:_updateCollection", {
                model: model,
                options: options
            });

            if (model instanceof Backbone.Model) {
                for (var dataName in this._dataMap) { // we are using "for" instead of "_.each"  because you cannot break _.each, and when we find the relevant data - we want to break
                    var collection = this._getData(dataName);
                    var isReallyCollection = (collection instanceof Backbone.Collection); // Note: The data can also be a model, for example: LoggedInUser. so we need to check if the data is collection or not
                    if (isReallyCollection && model instanceof collection.model) {
                        var isExistingModel = this._isExistingModel(model);
                        var mergedModel = collection.add(options.tojson ? model.toJSON() : model, options);
                        if (mergedModel) {
                            if (mergedModel instanceof RevisionedModel) {
                                mergedModel.setRevision(model.getRevision(true));
                            }
                            if (isExistingModel) { // we should trigger the "change" event only for existing models. for the new ones there is a Backbone event "add"
                                collection.trigger("model:change", mergedModel);
                            }
                        }

                        if (options.exclusively) {
                            break;
                        }
                    }
                }
            }
        },

        /**
         * Model is not new because it's likely already saved in the DB before we update the collection.
         * In order to detect if it was new before this save we should check the previous "id" attribute.
         * lodash isEmpty return TRUE for integer, so we need to check (not isEmpty) and (if its number to verify its bigger than 0)
         * @param model
         * @returns {boolean|*}
         * @private
         */
        _isExistingModel: function (model) {
            var previousId = model.previous(model.idAttribute);
            return (!_.isEmpty(previousId) || (_.isNumber(previousId) && previousId > 0));
        },

        _isFetchActive: function () {
            return this._fetchId !== null;
        },

        /**
         * Returns TRUE if there is data instance with data (at least one successful fetch) and its current request state is ERROR
         * @returns {boolean}
         * @private
         */
        _isDataNotUpdated: function () {
            var allUpdated = true;
            _.each(this._currentFetching, _.bind(function (dependencyObj) {
                var dependencyName = dependencyObj.dependencyName;
                var data = this._getData(dependencyName);
                var dataNotUpdated = dependencyObj.requestState === BaseCollection.FetchState.ERROR && data.hasAtLeastOneSuccessfulFetch();
                allUpdated = allUpdated && !dataNotUpdated;
            }, this));
            return !allUpdated;
        },

        /**
         * Shows notifier if data is not updated, with "refresh" link. Using the module notifier.
         * @private
         */
        _showNotUpdatedError: function () {
            if (this._module && this._module.notifier) {
                this._notifierView = this._module.notifier.showMiniError({
                    content: Translator.translate("LEFramework.global.captions.dataNotUpdated"),
                    objectAction: {
                        text: Translator.translate("LEFramework.global.captions.refreshData"),
                        callback: _.bind(this._refreshData, this)
                    }
                });
            }
        },

        _closeNotUpdatedError: function () {
            if (this._notifierView) {
                this._notifierView.close();
                this._notifierView = null;
            }
        },

        /**
         * Show foot steps view (No Data View) if mainRegion of module is provided
         * @private
         */
        _showNoDataView: function () {
            if (!this._getDependenciesOptions.ignoreNoData && this._module && this._module.mainRegion) {
                var view = new NoData({
                    permutation: NoData.PERMUTATION.REFRESH_LINK,
                    callback: _.bind(this._refreshData, this)
                });
                this._module.mainRegion.add(view);
            }
        },

        _refreshData: function () {
            this._stopFetching();
            this._startFetching();
        },

        _showLoader: function () {
            if (!_.isEmpty(this._currentFetching) && !this._getDependenciesOptions.ignoreLoader && this._module && this._module.showLoader) {
                this._module.showLoader();
            }
        },

        _closeLoader: function () {
            if (!_.isEmpty(this._currentFetching) && !this._getDependenciesOptions.ignoreLoader && this._module && this._module.closeLoader) {
                this._module.closeLoader();
            }
        }
    });

    return BaseDataManager;
});
