import { gChannel } from 'vue-infra';


const dispatchApi = {
  notify: {
    validator: 'isValidOptions',
  },
  setPageTitle: {
    validator: 'isValidOptions',
  },
  clearNotification: {
    validator: 'isValidClearNotificationOptions',
  },
};


export default class NotificationCenter {

  constructor() {
    this.appenders = {};

    Object.entries(dispatchApi)
      .forEach(([method, conf]) => {
        this[method] = this._dispatch.bind(this, method, conf.validator);
        gChannel.register('notificationCenter', method, opts => this[method](opts));
      });
  }

  addAppender(options) {
    if (!options || !options.name || !options.appender) {
      return;
    }
    if (options.appender.isSupported()) {
      this.appenders[options.name] = options.appender;
    }
  }

  _dispatch(delegateFn, validatorFn, options = {}) {
    return Object.entries(options)
      .reduce((accum, [name, appenderOptions]) => {
        const result = this._invokeAppender(delegateFn, validatorFn, name, appenderOptions);
        if (result) {
          accum[name] = result;
        }
        return accum;
      }, {});
  }

  _invokeAppender(delegateFn, validatorFn, name, options) {
    const appender = this.appenders[name];
    if (!appender || !appender.isEnabled()) {
      return;
    }
    if (!(validatorFn in appender)) {
      return;
    }
    const valid = appender[validatorFn](options);
    if (valid !== true) {
      const errorMsg = `${name}::${validatorFn} - invalid options${valid ? `, ${valid}` : ''}.`;
      appender.logger.error(errorMsg);
      appender.logger.graph({
        name: `framework:${name}:${validatorFn}:error`,
      });
      return;
    }
    if (!(delegateFn in appender)) {
      return;
    }
    const opts = Object.assign({}, appender.getDefaultOptions(), options);
    appender.logger.info(`${name}::${delegateFn} - called with options: ${JSON.stringify(opts)}`);
    return appender[delegateFn](opts);
  }

}
