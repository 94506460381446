define(function (require) {
  "use strict";

  var LE = require("app");
  var RevisionedCollection = require("collections/RevisionedCollection");
  var IvrModel = require("models/entities/IvrModel");
  var ApiResources = require("assets/data/apiResources.json");
  var _ = require('underscore');


  var NAME = "IvrCollection";

  var IvrCollection = RevisionedCollection.extend({
    model: IvrModel,
    resource: ApiResources.AccountConfig.IvrInstall,
    name: NAME,
    softwareId: "ivrId",

    getIvrInstallModel: function(){
      var result = [];
      this.each(_.bind(function(model){
        result.push(model);
      }, this));
      return result;
    },
  });


  IvrCollection.NAME = NAME;

  return IvrCollection;
});
