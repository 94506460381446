/**
 * Created by danielda on 5/12/2015.
 */
define(function (require) {
    "use strict";

    var SettingsCollection = require("collections/SettingsCollection");
    var { sessionManager, Logger } = require('vue-infra');
    var LanguageSettings  = require("site-settings/languageSettings");
    var Marionette = require("marionette");
    var GeneralUtilities = require("infra-util/GeneralUtilities");
    var Settings = require("codeTables/settings.json");
    var _ = require('underscore');

    var singelton = null;

    var ClassDefinition = Marionette.Controller.extend({

        initialize: function() {
            this.logger = Logger.getLogger("CoreComponents");
            this.logger.info("Initializing LanguageManager", "LanguageManager:initialize");
        },

        reload: function(options) {
            this._fetchData(options);
        },

        _fetchData: function(options) {
            options = options || {};
            var settingsCollection = new SettingsCollection([], {contextType: Settings.CONTEXT_TYPE.USER,
                contextId: sessionManager.getUserId(), contextCascading: true});

            var optionsForFetch = {
                groups: Settings.SETTING_GROUP.languageManager,
                success: _.bind(function (collection, response, fetchOptions) {
                    this.logger.info("fetch success", "LanguageManager:_fetchData", collection);
                    var lang = collection.get(Settings.SETTING_ID.LOCALE).getValue();
                    if (_.isFunction(options.success)) {
                        options.success(collection, response, fetchOptions);
                    }
                    setTimeout(function(){
                      this._applyLanguageChange(lang);
                    }.bind(this), 0);
                },this),
                error: function (collection, response, fetchOptions) {
                    this.logger.error("fetch error", "SettingsCollection:fetchSettingsByIds", response);
                    if (_.isFunction(options.error)) {
                        options.error(collection, response, fetchOptions);
                    }
                    if (options.defaultLanguageForError){
                      setTimeout(function(){
                        this._applyLanguageChange(options.defaultLanguageForError);
                      }.bind(this), 0);
                    }
                }
            };

            settingsCollection.fetch(optionsForFetch);
        },

        _applyLanguageChange: function(lang) {
            if (lang !== LanguageSettings.getAccountLocale()) {
                window.LE.logger.info("Applying language change", "LanguageManager:_applyLanguageChange", {
                    lang: lang
                });

                window.LE.isSilentLeave = true;
                if (lang === "en-US" && window.location.href.split("?").length === 2) {
                    window.location = GeneralUtilities.delParameter(window.location.href);
                } else {
                    window.location = GeneralUtilities.addParameter(window.location.href, "lang", lang);
                }
                window.LE.vent.trigger("LanguageManager:language-change");
            }
        }
    });

    function theConstractor(){
        return singelton;
    }

    if (!singelton) {
        var instance = new ClassDefinition();
        instance.initialize();
        singelton = instance;
    }


    return theConstractor;
});
