/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 4/27/14
 * Time: 3:15 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Translator = require("i18n/translator");
    var Backbone = require("backbone");
  var _ = require('underscore');

    var KEYS = {
        INDEX: "index",
        AVAILABILITY_TITLE: "availabilityTitle",
        TITLE: "title",
        CALLBACK: "callback",
        CONTEXT: "context",
        SELECTED: "selected",
        OPEN: "open",
        ICON_CLASS: "iconClass",
        VALUE: "value",
        CUSTOM_REASONS: "customReasons",
        SELECTED_REASON: "selectedReason"
    };

    var TopLevelMenuStatusItemModel = Backbone.Model.extend({
        KEYS: KEYS,
        defaults: {
            availabilityTitle: "",
            title: "",
            callback: function(){
                return false;
            },
            context: this,
            selected: false,
            open: false,
            iconClass: "",
            value: ""
        },
        name: "TopLevelMenuStatusItemModel",
        getAvailabilityTitle: function() {
            return this.get(KEYS.AVAILABILITY_TITLE);
        },
        setTitle: function(title) {
            this.set(KEYS.TITLE, title);
        },
        setIndex: function(index) {
            this.set(KEYS.INDEX, index);
        },
        isOpen: function() {
            return this.get(KEYS.OPEN);
        },
        setOpen: function(open) {
            this.set(KEYS.OPEN, open);
        },
        isSelected: function() {
            return this.get(KEYS.SELECTED);
        },
        setSelected: function(selected) {
            this.set(KEYS.SELECTED, selected);
        },
        getCustomReasons: function() {
            return this.get(KEYS.CUSTOM_REASONS);
        },
        setCustomReasons: function(customReasonsArr) {
            if (_.isEmpty(customReasonsArr)) {
                this.unset(KEYS.CUSTOM_REASONS);
            }
            else {
                this.set(KEYS.CUSTOM_REASONS, new Backbone.Collection(customReasonsArr));
            }
        },
        getSelectedReason: function() {
            return this.get(KEYS.SELECTED_REASON);
        },
        setSelectedReason: function(selectedReason) {
            this.set(KEYS.SELECTED_REASON, selectedReason);
        },
        getIconClass: function() {
            return this.get(KEYS.ICON_CLASS);
        },
        getValue: function() {
            return this.get(KEYS.VALUE);
        },
        getCallback: function() {
            return this.get(KEYS.CALLBACK);
        },
        getContext: function() {
            return this.get(KEYS.CONTEXT);
        }
    });

    TopLevelMenuStatusItemModel.KEYS = KEYS;

    return TopLevelMenuStatusItemModel;
});
