/**
 * This module is responsible to route users to the Launchpad
 */
define(function(require) {
  const LE = require('app');
  const LEConfig = require('assets/config/le-env-conf');
  const CompoundFeatureIds = require('assets/constants/compound-features-ids.json');
  const Privileges = require('assets/constants/privileges.json');
  const { sessionManager, Logger } = require('vue-infra');
  const LeAppModel = require('models/LeAppModel');
  const LEResourceResolver = require('leResourceResolver');
  const es6MiddleUtils = require('src/es6MiddleUtils');
  const RouteUtils = es6MiddleUtils.removeEsModuleDefault(
    require('routers/RouteUtils')
  );

  const LaunchpadRouter = class {
    /**
     * Creates an instance of LaunchpadRouter
     */
    constructor() {
      this.logger = Logger.getLogger('LaunchpadIntegration');
    }

    /**
     * Execute the Launchpad routing
     * Returns true if routing was executed
     *
     * @returns boolean
     */
    async execute() {
      const fragment = LE.navigator.getFragment() || '';
      const accountId = LEResourceResolver.getCurrentAccountId();
      const loginInfo = RouteUtils.parseLoginFragment({ fragment, accountId });
      const isInitialLogin =
        loginInfo &&
        (loginInfo.otk ||
          (!loginInfo.module && !loginInfo.persona && !loginInfo.prefix));

      if (isInitialLogin) {
        const launchpadEntryUri = await this._fetchEntryUri(accountId);

        if (launchpadEntryUri) {
          const redirectUrl = this._buildRedirectUrl(
            launchpadEntryUri,
            accountId
          );
          this._redirect(redirectUrl);
          return true;
        }
      }

      return false;
    }

    /**
     * Builds the account specific redirect url for Launchpad
     * @param {string} entryUri - the entry uri of Launchpad
     * @param {string} accountId - the account id of the logged in user
     */
    _buildRedirectUrl(entryUri, accountId) {
      return `${entryUri}?accountId=${accountId}`;
    }

    /**
     * Returns the redirect Url for Launchpad or null if a user should not be redirected
     * @param {string} accountId - the account id of the logged in user
     *
     * @returns Promise<string>
     */
    async _fetchEntryUri(accountId) {
      const logNs = '_fetchEntryUri';
      const isAdmin = sessionManager.isAdmin();
      const isLPA = sessionManager.isLPA();
      const isAgent = sessionManager.hasPrivilege(
        Privileges.AGENT_CORE_PERMISSION
      );
      const launchpadClientId = LEConfig.launchpadClientId;
      const leAppModel = new LeAppModel();

      if (!this._hasLaunchpadFeature()) {
        this.logger.debug(
          `Account ${accountId} does not have AC-Feature ${
            CompoundFeatureIds.LAUNCHPAD_ENABLED
          } enabled`,
          logNs
        );
        return null;
      }

      if (!isLPA) {
        if (!isAdmin) {
            this.logger.debug(
            'Only Admin users and LPA users will be redirected to Launchpad',
            logNs
          );
          return null;
        }

        if (isAgent) {
          this.logger.debug(
            'Users with an agent profile are not allowed to be redirected to Launchpad',
            logNs
          );
          return null;
        }
      }

      const launchpadInstallation = await leAppModel.getAppInstallationByClientId(
        accountId,
        launchpadClientId
      );

      if (
        !launchpadInstallation ||
        typeof launchpadInstallation.enabled !== 'boolean'
      ) {
        this.logger.debug(
          `Could not get Launchpad Installation for account ${accountId}`,
          logNs
        );
        return null;
      }

      if (!launchpadInstallation.enabled) {
        this.logger.debug(
          `Launchpad Installation is not enabled for account ${accountId}`,
          logNs
        );
        return null;
      }

      return launchpadInstallation.entry_uri;
    }

    /**
     * Returns true if the account has the AC-Feature for Launchpad enabled
     *
     * @returns boolean
     */
    _hasLaunchpadFeature() {
      return sessionManager.getFeaturePropertyState(
        CompoundFeatureIds.LAUNCHPAD_ENABLED
      );
    }

    /**
     * Performs a redirect to Launchpad
     */
    _redirect(url) {
      window.location = url;
    }
  };

  return LaunchpadRouter;
});
