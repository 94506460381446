/**
 * Created with IntelliJ IDEA.
 * User: elanh
 * Date: 2/21/13
 * Time: 5:37 PM
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var layoutTemplate = require("templates/liveEngageLayout.tmpl");
    var CONST = require("const");
    var _ = require('underscore');
    var $ = require('jquery');

    require("ui.plugins/jquery.viewport");

    /*
     this layout is the main LiveEngage application layout
     it defines the sub-regions of the layout and handles the UI logic that is application wide.
     */
    var LiveEngageLayout = Marionette.Layout.extend({
        template:layoutTemplate,
        regions:{
            "user-bar": ".user-bar-region",
            "connection-bar":".connection-bar-region",
            "reporting":".reporting-region",
            "campaigns":".campaigns-region",
            "content-management":".content-management-region",
            "web-agent":".web-agent-region",
            "registration":".registration-region",
            "user-management":".user-management-region",
            "example":".example-region",
            "manager": ".manager-region",
            "personalization": ".personalization-region",
        },
        ui:{
            userBarWrapper:".user-bar-wrapper",
            reportingWrapper:".reporting-wrapper",
            connectionBarWrapper:".connection-bar-wrapper",
            personalization:".personalization-bar-wrapper",
            mainAreaItemWrapper:".interaction-area-item-wrapper",
        },
        events:{
            "mouseup":"onTouched",
            "keyup":"onTouched",
            "click .work-area-wrapper":"_closeDashboard"
        },
        initialize:function (options) {
            this.LE = options.application;
            this.moduleRegions = options.moduleRegions;
             // "connectionPanel":".connection-panel-bar-wrapper",
            this._initEvents();
        },
        _initEvents: function() {
            // IMPORTANT: I am listening to render an not implementing onRender on the Layout itself
            //            Since the onRender is the last handler to be called upon render, I would like my
            //            Render handler to be the first to be called - this will achieve it
            this.listenTo(this, "render", function() {
                _.each(this.moduleRegions, function(value, key) {
                    this.$el.find("." + value.container).append(value.element);
                }, this);

                this._reInitializeRegions();
            });

          /* removing this keydown in order to let the browser handle the tab keydown with tabindex and a11y attrs
          $(document.body).on("keydown.lelayout", _.bind(function (e) {
                // If Tab is pressed
                if (e.keyCode == 9 || e.which == 9) {
                    // Get the target element
                    var target = $(e.target || e.srcElement);
                    if (0 < target.length) {
                        var disabled = target.closest(".interaction-area-item-wrapper").attr("disabled");
                        disabled = "disabled" === disabled || "true" === disabled;

                        if (disabled) {
                            return false;
                        }

                        var width = target.width();
                        var height = target.height();

                        // Check if it is in view
                        var inView = !$.rightofscreen(target, { threshold:width }) && !$.leftofscreen(target, { threshold:width });

                        if (!inView) {
                            // Not in view so dismiss the key press so focus will no be set
                            return false;
                        }

                        if (!this.LE.context.get(CONST.MODULES.CONNECTION + ".isOpened") && 0 < this.ui.connectionBarWrapper.has(target).length ||
                            !this.LE.context.get(CONST.MODULES.REPORTING + ".isOpened") && 0 < this.ui.reportingWrapper.has(target).length) {
                            return false;
                        }
                    }
                }
            }, this));
            */
        },
        getTopBarView: function() {
            return this.reporting && this.reporting.currentView;
        },
        setTopBarView: function(view) {
            this.reporting.show(view);
        },
        onTouched:function () {
            // On any mouseup or keyup event tell the sessionManager that user has touched the application, to keep the session alive.
            this.LE.sessionManager.touched();
        },
        _closeDashboard:function () {
            this.LE.vent.trigger(CONST.EVENTS.REPORTING_DASHBOARD_CLOSE);
        },
        setViewedWorkarea:function (workAreaToShow, options) {
            //remove all effects on first time loading.
            $(workAreaToShow).removeAttr("disabled").removeAttr("tabindex").css("visibility", "visible");

            var markedMid = false;
            var transition = false;
            _.each(this.moduleRegions, function(region, key) {
                if (!region.static) {
                    var selector = "." + region.unique;
                    var wrapperEl = $(selector);
                    if (workAreaToShow === selector) {
                        markedMid = true;
                        transition = region.transition;
                        wrapperEl.addClass("open").removeClass("rightOff leftOff");
                    } else {
                        wrapperEl.attr({ "disabled": true, "tabindex": "-1" });
                        wrapperEl = (markedMid ? wrapperEl.removeClass("open leftOff").addClass("rightOff") : wrapperEl.removeClass("open rightOff").addClass("leftOff"));

                        if ($(wrapperEl).is(":visible")) {
                            $(wrapperEl).css("visibility", "hidden").attr("disabled", true);
                        }
                    }
                }
            }, this);

            if (transition) {
                _.delay(function () {
                    $(workAreaToShow).removeAttr("disabled").removeAttr("tabindex").css("visibility", "visible");
                }, 500);
            }
        },
        onClose:function () {
            $(document.body).off(".lelayout");
        }
    });

    return LiveEngageLayout;
});
