/**
 * Created with IntelliJ IDEA.
 * User: noam
 * Date: 3/3/13
 * Time: 11:31 AM
 * A backbone collection with a persistence function
 * The implementation may fetch from the server every x seconds
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");

    var PersistentModel =  RevisionedModel.extend({
        //todo see description at the top
        persist: function(){
            //todo implement persistence layer
        }
    });

    return  PersistentModel;
});