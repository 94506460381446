/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/17/14
 * Time: 11:08 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var SessionTimeout = require("codeTables/sessionTimeout.json");
    var LEConfig = require("assets/config/le-env-conf");
  var _ = require('underscore');

    var sessionTimeoutSettings = (function () {
        var currentSessionTimeout = null;

        var getSessionTimeoutList = function () {
            return SessionTimeout.sessionTimeoutCodes;
        };

        var getCurrentSessionTimeout = function () {
            if (currentSessionTimeout === null) {
                currentSessionTimeout = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.SESSION_TIMEOUT, SessionTimeout.defaultSessionTimeout);
            }
            return currentSessionTimeout;
        };

        var getSessionTimeoutListTranslated = function () {
            if (_.isUndefined(SessionTimeout.sessionTimeoutTranslated)) {
                translateSessionTimeoutCodes();
            }

            return SessionTimeout.sessionTimeoutTranslated;
        };

        var translateSessionTimeoutCodes = function () {
            SessionTimeout.sessionTimeoutTranslated = [];

            _.each(SessionTimeout.sessionTimeoutCodes, function (value, key) {
                SessionTimeout.sessionTimeoutTranslated.push({
                    "key": key,
                    "value": getTranslatedSessionTimeout(value.milliseconds)
                });
            });

            SessionTimeout.sessionTimeoutTranslated = _.sortBy(SessionTimeout.sessionTimeoutTranslated, function (obj) {
                return window.Number(obj.key);
            });
        };

        var getTranslatedSessionTimeout = function(milliseconds){
            var keyToTranslate,ergsToTranslate;
            if(milliseconds < 3600000){
                keyToTranslate = "LEFramework.sessionTimeoutCodes.minutes";
                ergsToTranslate = milliseconds/60000;
            }
            else if(milliseconds==3600000){
                keyToTranslate = "LEFramework.sessionTimeoutCodes.hour";
                ergsToTranslate = 1;
            }
            else{
                keyToTranslate = "LEFramework.sessionTimeoutCodes.hours";
                ergsToTranslate = milliseconds/3600000;
            }
            return LE.translator.translate({
                key: keyToTranslate,
                args: [ergsToTranslate]
            });
        };

        var switchSessionTimeout = function (sessionTimeout) {
            currentSessionTimeout = sessionTimeout;

            LEConfig.Session.LeServer = currentSessionTimeout / 1000;
            //LE.vent.trigger("siteSettings:sessionTimeoutSwitched", currentSessionTimeout);
        };

        var setDefaultSessionTimeout = function(){

            if(LEConfig.Session.LeServer){
                SessionTimeout.defaultSessionTimeout = LEConfig.Session.LeServer * 1000;
            }
        };

        setDefaultSessionTimeout();

        return {
            getSessionTimeoutList: getSessionTimeoutList,
            getCurrentSessionTimeout: getCurrentSessionTimeout,
            getSessionTimeoutListTranslated: getSessionTimeoutListTranslated,
            switchSessionTimeout: switchSessionTimeout
        };

    })();
    return sessionTimeoutSettings;
});
