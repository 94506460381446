<template>
  <div class="ftue-conversation">
    <first-time-header :title="title" />
    <div class="ftue-content">
      <first-time-section
        v-for="(section, sectionIndex) in sections"
        :key="section.id"
        :section="section"
        :full-name="fullName"
        :is-last="sectionIndex === sections.length - 1"
        @click-button="handleButtonClick"
      />
    </div>
  </div>
</template>

<script>
import FirstTimeHeader from './FirstTimeHeader';
import FirstTimeSection from './FirstTimeSection';

export default {
  name: 'FirstTimeConversation',
  components: {
    FirstTimeHeader,
    FirstTimeSection
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    }, fullName: {
      type: String,
      default: '',
    }, title: {
      type: String,
      default: '',
    }
  },
  emits: ['clickButton'],
  methods: {
    handleButtonClick: function(data) {
      this.$emit('clickButton', data);
    },
  },
};
</script>

<style scoped lang="scss">
.ftue-conversation {
  height: 100%;
  position: relative;
}
.ftue-content {
  padding: 24px;
  overflow: auto;
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
