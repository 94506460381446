
define(function (require) {
    "use strict";

    var es6MiddleUtils  = require('src/es6MiddleUtils');
    var _ = require("underscore");
    var $ = require("jquery");
    var Marionette = require("marionette");
    var CONST = require("const");
    var LiveEngageLayout = require("layouts/LiveEngageLayout");
    var DefaultDatabar = require("views/DefaultDatabar");
    var workAreaTemplate = require("templates/workAreaItem.tmpl");
    var workAreaNoLEClassTemplate = require("templates/workAreaItemNoLEClass.tmpl");
    var connectionTemplate = require("templates/connectionItem.tmpl");
    var reportingTemplate = require("templates/reportingItem.tmpl");
    var personalizationTemplate = require("templates/personalizationItem.tmpl");
    var { TranslatorVue3Plugin, i18n, store, vueExports } = require('vue-infra');
    var FirstTimeExperience = es6MiddleUtils.removeEsModuleDefault(require('./../components/first-time-experience/FTUE'));
    var TopBarLaunchIcons = es6MiddleUtils.removeEsModuleDefault(require('../components/app-frame/TopBarLaunchIcons'));
    const LpVueComponents = require('@liveperson/lp-vue-components')['default'];

    const { ENVIRONMENT } = require('vue-infra').storeModuleTypes;
    const { ADD_ROUTES } = require('vue-infra').environmentMutationTypes;
    const { REGIONS, CURRENT_ACTIVE_THEME } = require('vue-infra').environmentGetterTypes;
    const { UPDATE_REGIONS } = require('vue-infra').environmentActionTypes;
    const mainVueApps = require('src/components/mainVueApps');

    const { createApp } = vueExports;

    var templates = {
        workAreaItem: workAreaTemplate,
        workAreaItemNoLEClass: workAreaNoLEClassTemplate,
        connectionItem: connectionTemplate,
        reportingItem: reportingTemplate,
        personalizationItem: personalizationTemplate
    };
    var interactionTemplates = [
        "workAreaItem",
        "workAreaItemNoLEClass"
    ];

    /*
     this controller is responsible for controlling the different components of the LiveEngage application
     on start, it displays the LiveEngage layout and sets all its sub-regions
     */
    var LiveEngageLayoutController = Marionette.Controller.extend({
        moduleRegions: {},
        initialize: function (options) {
            this.LE = options.application;
            this._initRegions();
            this._initMembers();
        },
        _initRegions: function () {
            _.each(this.LE.sessionManager.getPersonas(), function (persona) {
                this._initPersonaModules(persona);
            }, this);

            // Create the container for the default Top bar if needed
            var reporting = this.LE.moduleManager.resolveModuleNameById("reporting");
            if (!this.moduleRegions[reporting.moduleName]) {
                this._parseStaticReportingRegion(reporting);
            }
            store.dispatch(`${ENVIRONMENT}/${UPDATE_REGIONS}`,this.moduleRegions);
        },
        _initPersonaModules: function (persona) {
            _.each(this.LE.moduleManager.resolveModules(persona), function (value, key, modules) {
                this._initModuleConfig(value);
            }, this);
        },
        _initModuleConfig: function (value) {
            var persona = this.LE.moduleManager.resolvePersona(value);
            var config = this.LE.moduleManager.resolveModuleConfig(persona);

            if (config.regionId && config.template && config.container) {
                this._initModuleRegion(persona, value, config);
            }
        },
        _initModuleRegion: function (persona, value, config) {
            this._parseModuleRegionBasicAttributes(persona, value, config);
            this._parseModuleRegionLayoutingAttributes(value, config);
            this._parseModuleRegionSwitcherAttributes(value, config);
            this._parseModuleRegionNotifierAttributes(value, config);
        },
        _parseModuleRegionBasicAttributes: function (persona, value, config) {
            this.moduleRegions[value] = {};
            this.moduleRegions[value].persona = persona;
            this.moduleRegions[value].config = config;
        },
        _parseModuleRegionLayoutingAttributes: function (value, config) {
            this.moduleRegions[value].element = $(templates[config.template]({
                name: config.regionId,
                transition: (config.transition ? "interaction-area-transition " : "")
            }));
            this.moduleRegions[value].container = config.container;
            this.moduleRegions[value].static = (-1 === _.indexOf(interactionTemplates, config.template)); // Represents a module which is not located in the interaction area
            this.moduleRegions[value].transition = config.transition;
            this.moduleRegions[value].unique = this._parseModuleRegionUniqueClass(value, config);
        },
        _parseModuleRegionUniqueClass: function (value, config) {
            var classList = this.moduleRegions[value].element.attr("class").split(/\s+/);
            var i = 0;

            for (; i < classList.length; i++) {
                if (0 === classList[i].indexOf(config.regionId)) {
                    return classList[i];
                }
            }
        },
        _parseModuleRegionSwitcherAttributes: function (value, config) {
            if (config.switcher && 0 < config.switcher.index && config.switcher.control) {
                this.moduleRegions[value].switcherControl = config.switcher.control;
                this.moduleRegions[value].switcherIconClassName = config.switcher.iconClass;
                this.moduleRegions[value].fontIconName = config.switcher.fontIconName;
                if(config.switcher && config.switcher.iconClass && !config.switcher.iconClass.includes('switcher-')){
                  config.switcher.iconClass = `switcher-${config.switcher.iconClass}`;
                }
                this.moduleRegions[value].switcherIndex = config.switcher.index;
                this.moduleRegions[value].switcherRoute = config.routePrefix;
                this.moduleRegions[value].registeredVueApp = config.registeredVueApp;
            }
        },
        _parseModuleRegionNotifierAttributes: function (value, config) {
            if (config.notifierContainer) {
                this.moduleRegions[value].notifierContainer = config.notifierContainer;
            }
        },
        _parseStaticReportingRegion: function (reporting) {
            this.moduleRegions[reporting.moduleName] = {};
            this.moduleRegions[reporting.moduleName].container = reporting.container;
            this.moduleRegions[reporting.moduleName].element = $(templates[reporting.template]({
                name: reporting.regionId,
                transition: (reporting.transition ? "interaction-area-transition " : "")
            }));
        },
        _initMembers: function () {
            const moduleRegions = store.getters[`${ENVIRONMENT}/${REGIONS}`];
            // Create the application main layout object
            this.mainLayout = new LiveEngageLayout({
                application: this.LE,
                moduleRegions: moduleRegions
            });
        },
        start: function () {
            //the context of the callback will be this (this controller)
            this.listenTo(this.mainLayout, "render", function () {
                //triggerEventsthrow new Error("KKK");
                //when the layout is rendered, we'll setup the inner regions
                this._setRegions();
                // this.liveEngageUserBarController.show({containerRegion: this.mainLayout["user-bar"]});

                if (!this.mainLayout.getTopBarView()) {
                    this.mainLayout.setTopBarView(new DefaultDatabar());
                }

                if (this._needsToShowAccessibilityFocusOutline()) {
                    $('body').addClass('show-a11y-focus-outline');
                }
            });
            this.LE.vent.on("module:active", function (options) {
                const moduleRegions = store.getters[`${ENVIRONMENT}/${REGIONS}`];
                if (options.moduleName && moduleRegions[options.moduleName] && moduleRegions[options.moduleName].unique) {
                    var region = moduleRegions[options.moduleName];
                    this.mainLayout.setViewedWorkarea("." + region.unique);
                    _.each(moduleRegions, function (region, key) {
                        if (key != options.moduleName && region.switcherControl) {
                            this.LE.context.set(key + ".isVisible", false);
                        }
                    }, this);

                    this.LE.context.set(options.moduleName + ".isVisible", true);
                    // resolve the persona and set it into the context object, this way all the relevant modules
                    var resolvedPersona = this.LE.moduleManager.resolvePersona(options.moduleName);
                    this.LE.context.set("persona", resolvedPersona);

                    //update mtag ude
                    this.LE.lpTagAddVar([
                        {name: "ActivePersona", val: resolvedPersona}
                    ]);
                    var module = this.LE.module(options.moduleName);
                    if (module) {
                        this.LE.context.set("activeModuleName", options.moduleName);
                        this.LE.context.setDisplayedModule(options.moduleName);

                        // Set lockers on Reporting and connectionbar is needed
                        this.LE.vent.trigger(CONST.EVENTS.REPORTING_DASHBOARD_LOCKING, _.result(module, "lockReporting"));
                    }

                    //TODO: set persona in session manager.
                }
            }, this);

            //show the main layout in the main region
            this.LE.mainRegion.show(this.mainLayout);

            var personas = this.LE.sessionManager.getPersonas();

            if (this.LE.sessionManager.isAuthenticated()) {


//                if ((_.contains(personas, this.LE.sessionManager.PERSONA.ADMIN) || _.contains(personas, this.LE.sessionManager.PERSONA.MARKETER))){
//                    this.addDataSourcesTopLevelMenuItem();
//                }
                // remove the upgradeAccount until the links are ready
                // if isLPA && hasMigratingFeature && isAdministrator display 'upgradeAccount item'
                if (!LE.sessionManager.hasLiveEngage2Feature() && this.LE.sessionManager.hasMigratingFeature() &&
                    (this.LE.sessionManager.isLPA() || this.LE.sessionManager.isAdmin())) {
                    // this.addUpgradeAccountTopLevelMenuItem();
                } else { // call to the isTrial API only if we are authenticated
                    this.LE.BillingInformation(false);
                }
            }

            // must execute after i18n is initialized with the dictionaries.
            // TODO - Julie - make sure to use all of the necessary plugins
            mainVueApps.mountContainer
                .use(TranslatorVue3Plugin)
                .use(i18n)
                .use(LpVueComponents)
                .use(store)
                .use(LE.navigator.router)
                .mount('#vue-hybrid-layout-manager');

            // TODO - Julie - make sure to use all of the necessary plugins
            createApp(FirstTimeExperience)
                .use(TranslatorVue3Plugin)
                .use(i18n)
                .use(LpVueComponents)
                .use(store)
                .mount('#vue-first-time-experience');

            // TODO - Julie - make sure to use all of the necessary plugins
            createApp(TopBarLaunchIcons)
                .use(TranslatorVue3Plugin)
                .use(i18n)
                .use(LpVueComponents)
                .use(store)
                .use(LE.navigator.router)
                .mount('.data-bar-icons');

            // TODO - Julie - make sure to use all of the necessary plugins
            mainVueApps.navBar
                .use(TranslatorVue3Plugin)
                .use(i18n)
                .use(LpVueComponents)
                .use(store)
                .use(LE.navigator.router)
                .mount('.user-bar-region');

            if (window.reactElements && window.reactElements.length > 0){
              window.reactElements.forEach(({ appId ,element }) => {
                const appElement = document.querySelector(`react-main-wrapper-${appId}`);
                if (!appElement){
                  const reactRootEl = document.createElement('div');
                  reactRootEl.style.display = 'none';
                  reactRootEl.className = `react-main-wrapper-${appId} opened-nav-bar`;
                  document.querySelector('.vue-main-wrapper').insertAdjacentElement('afterEnd', reactRootEl);
                  const createRoot = window.reactModule.find((module) => module.appId === appId).createRoot;
                  const reactRoot = createRoot(reactRootEl);
                  reactRoot.render(element);
                }
              });
            }

            // LE.navigator.router.addRoutes(Agents.$router.options.routes[0]);
            store.commit(`${ENVIRONMENT}/${ADD_ROUTES}`, LE.navigator.routes);
        },

        _setRegions: function () {
            //set each module main view in the correct region
            _.each(this.LE.moduleManager.moduleRegions, this._setModuleMainView, this);
        },

        _setModuleMainView: function (regionId, moduleName, modules) {
            var delayedStart = false;

            if (!moduleName) {
                delayedStart = true;
                moduleName = regionId.moduleName;
                regionId = this.LE.moduleManager.moduleRegions[moduleName];
            }

            //get the main view from the module and set it in the region
            var module = this.LE.module(moduleName);

            if (module && module.startWithParent || delayedStart) {
                this.LE.vent.trigger("module:before:render", {moduleName: moduleName, regionId: regionId});

                var view = _.result(module, "getMainView");
                if (view) {
                    this.mainLayout[regionId].show(view);
                    this.LE.vent.trigger("LERegion:" + moduleName + ":ready", {
                        moduleName: moduleName,
                        region: this.mainLayout[regionId]
                    }); // fired the event informing that the layout(DOM) is ready
                }
            }
        },
        _needsToShowAccessibilityFocusOutline: function() {
            var loggedInUsersId = this.LE.sessionManager.getUserId();
            var usersThatNeedsAccessibility = this.LE.sessionManager.getAccountSettingValueByID('le.ui.a11y.usersWithFocusOutline', ['*']);
            if (_.isArray(usersThatNeedsAccessibility) && usersThatNeedsAccessibility.length > 0) {
                return usersThatNeedsAccessibility.indexOf('*') > -1 || usersThatNeedsAccessibility.indexOf(loggedInUsersId) > -1;
            }
        }
    });

    return LiveEngageLayoutController;
});
