<template>
  <div
    class="top-level-link"
    role="link"
    tabindex="0"
    @click="onSwitchItem"
    @keyup.enter.space="onSwitchItem"
  >
    <div
      class="top-level-link-icon"
      aria-hidden="true"
    >
      {{ getInitialsLetters }}
    </div>
    <div class="top-level-link-title ellipsis">
      {{ title }}
    </div>
  </div>
</template>

<script>

  let lpUtils = require('../../../../assets/js/utils/lpUtils.js');

  export default {
    name: 'TopLevelLink',
    props: {
      title: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      iconClass: {
        type: String,
        default: '',
      },
    },
    emits: ['clicked'],
    computed: {
      getInitialsLetters() {
        return lpUtils.initialsFromString(this.title);
      },
    },
    methods: {
      onSwitchItem(event) {
        event.stopPropagation();
        window.LE.navigator.open(this.url, '_blank');
        this.$emit('clicked');
      },
    }
  }
</script>

<style lang='scss' scoped>
  @import "../../../../assets/scss/global";
  @import "../../../../assets/scss/mixins";

  .top-level-link {
    min-height: 52px;
    line-height: 52px;
    padding: 0 15px 0 8px;
    cursor: pointer;
    white-space: nowrap; // prevent menu item from flowing into more than one line
    @include transition-duration(100ms);
    @include transition-property(background-color, color);
    @include transition-timing-function(swing);

    &:not(:first-child) {
      border-top: 1px #cccccc solid;
    }

    .top-level-link-icon-disabled {
      display: none;
    }
    &:hover {
      background-color: #E6E8ED;
      border-radius: 2px;
      color: #323436;
    }
    &:active {
      background-color: #cccccc;
     }
    &:last-child {
      border-bottom: 1px #cccccc solid;
     }
    /* .top-level-link-icon {
      float: left;
      margin: 7px 7px 0 0;
      width: 22px;
      height: 22px;
    } */
    .top-level-link-icon {
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      margin: 9px 12px 0 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;
      line-height: 22px;
      background: #ffffff;
      @include themify {
        border: 1px solid getColor('framework-dropdown-icon-border', 'framework');
        color: getColor('framework-dropdown-icon-text', 'framework');
      };
    }
    .top-level-link-title {
      float: left;
      font-size: 12px;
      font-weight: normal;
      max-width: 150px;
    }
    &.disabled {
      color: $gray40;
      cursor: default;
      &:hover {
        background-color: inherit;
      }
    }
    .top-level-link-indicator {
      right: 15px;
      position: absolute;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      margin-top: 1px;
    &.green {
       color: $green;
     }
    &.red {
       color: $red;
     }
    }
  }

</style>
