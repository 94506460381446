/**
 *
 * a wrapper around the popup notification and top bar notification APIs, to allow showing one or the other,
 * based on an LE module active state.
 *
 * when a module is active (the current route belongs to the module), the manager will show popup notifications,
 * otherwise, it will show top bar notifications, that when clicked, perform navigation to the module's main route.
 *
 *
 * implementation details:
 *
 * - the PROGRESS notification type is only supported by top bar notifications
 *
 *
 * example usage:
 *
 *      var usersNotificationManager = new ModuleAwareNotificationManager({
 *          module: LEUserManagement,
 *          returnRoute: 'skills',
 *      });
 *
 *      usersNotificationManager.showNotification({
 *          type: ModuleAwareNotificationManager.TYPES.SUCCESS,
 *          text: LE.translator.translate("LEUserManagement.userList.assignSkills.success"),
 *      });
 *
 */
define(function (require) {
    'use strict';

    var CONST = require('const');
    var Marionette = require('marionette');

    var TYPE_MAP = {
        SUCCESS: {
            popupCmd: 'showSuccess',
            topBarType: CONST.TOP_BAR_NOTIFICATION_TYPE.SUCCESS,
            duration: 10000,
        },
        ERROR: {
            popupCmd: 'showError',
            topBarType: CONST.TOP_BAR_NOTIFICATION_TYPE.ERROR,
            duration: 10000,
        },
        PROGRESS: {
            topBarType: CONST.TOP_BAR_NOTIFICATION_TYPE.PROGRESS,
            duration: 6000,
        },
    };


    var ModuleAwareNotificationManager = Marionette.Controller.extend({

        topBarNotification: null,
        popupNotification: null,

        /**
         * constructs a new instance
         * @param options {object}
         * @param options.module {object} a reference to an LE module (mandatory).
         * @param options.returnRoute {string} a route inside the module, to be navigated when a top-bar notification
         * is clicked (optional).
         */
        initialize: function (options) {
            this.options = options || {};
            this._validate();
        },

        /**
         * shows a notification based on the module active state. popup notification will be shown when the module is
         * active, and top-bar notifications will be shown when the module is inactive.
         * @param notificationOptions {object}
         */
        showNotification: function (notificationOptions) {
            notificationOptions = notificationOptions || {};

            if (this._isModuleActive()) {
                this._showPopupNotification(notificationOptions);
            } else {
                this._showTopBarNotification(notificationOptions);
            }
        },

        /**
         * registers a notification to be shown when the module will be made inactive.
         * @param notificationOptions {object}
         */
        registerModuleLeaveNotification: function (notificationOptions) {
            this.listenToOnce(window.LE.context, 'change:activeModuleName', this._showModuleLeaveNotification.bind(this, notificationOptions));
        },

        /**
         * clears a previous registration for notification on module inactive state events.
         * @see #registerModuleLeaveNotification
         */
        unregisterModuleLeaveNotification: function () {
            this.stopListening(window.LE.context, 'change:activeModuleName');
        },


        _validate: function () {
            if (!this.options.module) {
                throw new Error('options.module is mandatory');
            }
        },

        _isModuleActive: function () {
            return window.LE.context.get('activeModuleName') === this.options.module.moduleName;
        },

        _isLeavingModule: function () {
            return this.options.module.moduleName === window.LE.context.get('previousApplicationRoute.module') && !this._isModuleActive();
        },

        _showPopupNotification: function (notificationOptions) {
            if (!notificationOptions.type.popupCmd || !this.options.module.notifier) {
                return;
            }
            this._clearPopupNotification();
            this.popupNotification = this.options.module.notifier[notificationOptions.type.popupCmd]({
                content: notificationOptions.text,
                timeToShow: notificationOptions.type.duration
            });
        },

        _showTopBarNotification: function (notificationOptions) {
            if (!notificationOptions.type.topBarType) {
                return;
            }
            this._clearTopBarNotification();
            this.topBarNotification = window.LE.notificationCenter.notify({
                TopBarNotification: {
                    type: notificationOptions.type.topBarType,
                    text: notificationOptions.text,
                    onClick: this._onTopBarNotificationClick.bind(this),
                }
            });
        },

        _showModuleLeaveNotification: function (notificationOptions) {
            if (this._isLeavingModule()) {
                this._showTopBarNotification(notificationOptions);
            }
        },

        _clearPopupNotification: function () {
            if (!this.popupNotification) {
                return;
            }
            this.popupNotification.close();
            this.popupNotification = null;
        },

        _clearTopBarNotification: function () {
            if (!this.topBarNotification) {
                return;
            }
            window.LE.notificationCenter.clearNotification({
                TopBarNotification: {
                    id: this.topBarNotification.TopBarNotification
                }
            });
            this.topBarNotification = null;
        },

        _onTopBarNotificationClick: function () {
            // navigate to the desired route, or fallback to the module default route
            this.options.module.router.navigate(this.options.returnRoute);
            // remove existing notification
            this._clearTopBarNotification();
        },

    });


    ModuleAwareNotificationManager.TYPES = TYPE_MAP;

    return ModuleAwareNotificationManager;
});

