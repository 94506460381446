/**
 * Created with IntelliJ IDEA.
 * User: elanh
 * Date: 3/14/13
 * Time: 10:39 AM
 * To change this template use File | Settings | File Templates.
 */

/*
 a Refresh model class which extends the revisioned
 The model is refreshed (fetched ) every x seconds etc
 */
define(function (require) {
    "use strict";

    var { Logger } = require('vue-infra');
    var RevisionedModel = require("models/RevisionedModel");
  var _ = require('underscore');

    var DEFAULTS = {
        FETCH_INTERVAL:  60000,
        FETCH_ERROR_INTERVAL: 400,
        FETCH_ERROR_INTERVAL_AFTER_RETRIES: 300000,
        RETRY: false,
        MAX_RETRY: 5
    };

    var RefreshModel = RevisionedModel.extend({
        /**
         *
         * @param options:
         *               options.fetchInterval     - refresh the fetch every fetchInterval ms
         *               options.fetchErrorInterval  - refresh the fetch on error every  fetchErrorInterval ms
         *               options.retry               -  retry after error ( true,false)
         *               options.maxRetries          -  max number of retried for error after which we stop refreshing
         *
         */
        initialize:function (options) {
            options = options || {};
            this.flogger = Logger.getLogger("LEFramework");
            this.flogger.debug( "options: ","RefreshModel:initialize", options);
            RevisionedModel.prototype.initialize.call(this,options);
            //options members
            this.fetchInterval = (_.isFinite(options.fetchInterval) &&  options.fetchInterval> 0)? options.fetchInterval : DEFAULTS.FETCH_INTERVAL;
            this.fetchErrorInterval = (_.isFinite(options.fetchErrorInterval) &&  options.fetchErrorInterval> 0)?options.fetchErrorInterval:DEFAULTS.FETCH_ERROR_INTERVAL;
            this.fetchErrorIntervalAfterRetries = (_.isFinite(options.fetchErrorIntervalAfterRetries) &&  options.fetchErrorIntervalAfterRetries> 0) ? options.fetchErrorIntervalAfterRetries : DEFAULTS.FETCH_ERROR_INTERVAL_AFTER_RETRIES;
            this.retry = _.has(options,"retry")?options.retry:DEFAULTS.RETRY;
            this.maxRetries = (_.isFinite(options.maxRetries) &&  options.maxRetries> 0)?options.maxRetries:DEFAULTS.MAX_RETRY;
            //internal state members
            this.activeRefresh = false;
            this.refreshId = null;
            this.tryCounter = 0;
        },
        //public functions:

        startRefresh: function (options) {
            this.flogger.debug( "start refresh ","RefreshModel:startRefresh", options);
            options = options  || {} ;
            if (!this.activeRefresh) {  //dont start another refresh if there is one running
                this.activeRefresh = true;
                this.refreshId = (new Date()).getTime().toString();
                //allow to override refresh options
                this.fetchInterval = options.fetchInterval || this.fetchInterval ;
                this.fetchErrorInterval = options.fetchErrorInterval || this.fetchErrorInterval;
                this.retry = _.has(options,"retry") ? options.retry:this.retry;
                this.maxRetries = options.maxRetries || this.maxRetries;
                this.flogger.debug("startRefresh: retry: " + this.retry + " maxRetries: "+this.maxRetries ,"RefreshModel:startRefresh");
                this._refresh(options);
            }
        },

        stopRefresh: function() {
            this.flogger.debug("Stop refresh","RefreshModel:stopRefresh");
            this.activeRefresh = false;
            this.refreshId = null;
        },

        getErrorTryCounter: function(){
            return this.tryCounter;
        },
        getFetchInterval: function() {
            return this.fetchInterval;
        },

        //private functions:

        /**
         * Internal refresh function called using timeout every fetch interval
         * @param options
         * @private
         */
        _refresh: function(options) {
            if (!this.activeRefresh){
                return;
            }

            this.flogger.debug("_refresh tryCounter: " + this.tryCounter + "RefreshModel:_refresh");

            var that = this;
            this.fetch({
                refreshId: this.refreshId,
                fetchOptions: options.fetchOptions,
                success: function (model, resp, bbOptions) {

                    that.fetched = true;
                    that.tryCounter = 0; // error try counter

                    if (_.isFunction(options.success)) {
                        options.success(model, resp, bbOptions);
                    }
                    setTimeout(function () {
                        if (that.refreshId !== bbOptions.refreshId) {
                            return;
                        }
                        that._refresh.call(that,options);
                    }, that.fetchInterval);
                },

                error: function (model, resp, bbOptions) {
                    that.flogger.error("_refresh:fetch:error", "fetch response: ", resp);
                    if (_.isFunction(options.error)) {
                        options.error(model, resp, bbOptions);
                    }

                    if (that.retry) {
                        var timeoutInterval = (that.tryCounter < that.maxRetries) ? that.fetchErrorInterval : that.fetchErrorIntervalAfterRetries;
                        setTimeout(function () {
                            if (that.refreshId !== bbOptions.refreshId) {
                                return;
                            }
                            that.tryCounter ++;
                            that._refresh.call(that, options);
                        }, timeoutInterval);
                    } else {
                        that.activeRefresh = false;
                        that.refreshId = null;
                    }
                }
            });
        }

    });
    RefreshModel.DEFAULTS = DEFAULTS;
    return RefreshModel;
});
