/**
 * Created by tomers on 4/26/2016.
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");
    var ApiResources = require("assets/data/apiResources.json");

    var STATES = {
        ONLINE: "Online",
        AWAY: "Away",
        OCCUPIED: "Occupied"
    };

    var KEYS = {
        TEXT: "text",
        STATE: "state",
        ENABLED: "enabled",
        DELETED: "deleted"
    };

    var AgentReasonModel = RevisionedModel.extend({
        resource: ApiResources.AccountConfig.AgentReasons,
        MAX_TEXT_LENGTH: Config.validators.agentReason.maxTextLength,
        KEYS: KEYS,
        name: "AgentReasonModel",
        defaults: {
            //id: null,
            text: "",
            state: STATES.AWAY,
            enabled: true,
            deleted: false
        },

        saveEnableValidation: {
            text: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.agentReason.errors.text.required"
                }
            ]
        },

        validation: {
            text: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.agentReason.errors.text.required"
                },
                {
                    maxLength: Config.validators.agentReason.maxTextLength,
                    msg: "LEFramework.entities.agentReason.errors.text.length"
                }
            ]
        },

        initialize: function(attrs, options) {
            this.valueIsChanged = false;
            RevisionedModel.prototype.initialize.call(this, attrs, options);
        },

        getText: function () {
            return this.get(KEYS.TEXT);
        },
        setText: function (text) {
            this._set(KEYS.TEXT, text);
        },

        getState: function () {
            return this.get(KEYS.STATE);
        },
        setState: function (state) {
            this._set(KEYS.STATE, state);
        },

        getEnabled: function () {
            return this.get(KEYS.ENABLED);
        },
        setEnabled: function (enabled) {
            this._set(KEYS.ENABLED, enabled);
        },

        isDeleted: function() {
            return (this.get(KEYS.DELETED) === true);
        },

        isValueChanged: function() {
            return this.valueIsChanged;
        },

        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },

        _set: function(key, value) {
            this.valueIsChanged = true;
            this.set(key, value);
        }

    });

    AgentReasonModel.KEYS = KEYS;

    return AgentReasonModel;
});
