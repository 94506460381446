<template>
  <first-time-conversation
    :sections="sections"
    :full-name="fullName"
    title="LEFramework.firstTimeExperience.title"
    @click-button="handleButtonClick"
  />
</template>

<script>
import FirstTimeConversation from './FirstTimeConversation';
import { sessionManager, gChannel } from 'vue-infra';
import ApiResources from 'assets/data/apiResources.json';
import urlResolver from 'urlResolver';
let initialSections = require('./WelcomeExperienceSections.json').sections;
let mobileButtons = require('./MobileChannelButtons.json');

export default {
  name: 'WelcomeExperience',
  components: {
    FirstTimeConversation
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
    productTourUrl: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      sections: [...initialSections],
      timeouts: [],
      fullName: this.userDetails['fullName']
    };
  },
  beforeUnmount() {
    // Cancel timers before the component is destroyed
    this.timeouts.forEach(clearTimeout);
    // Reset the sections back to their initial state
    this.resetSections();
  },
  methods: {
    closeModal: function() {
      gChannel.trigger('LiveEngageCommon', 'FirstTimeExperienceModal:close');
    },
    addSection: function({ delay, showBotIcon, callback, ...content }) {
      if (delay) {
        this.sections.push({
          loading: true,
          showBotIcon: showBotIcon
        });
        const indexOfLoadingSection = this.sections.length - 1;
        const timeout = setTimeout(() => {
          this.sections[indexOfLoadingSection] = {
            showBotIcon,
            ...content
          };

          if (callback) {
            callback();
          }
        }, delay);
        // Save timeout for cancelling later
        this.timeouts.push(timeout);
      } else {
        this.sections.push({ ...content });
      }
    },
    resetSections() {
      this.sections = initialSections;
    },
    getConciergeBotUrl() {
      let env = sessionManager.LEConfig.environment;
      let url = urlResolver.getUrl(
        ApiResources.FirstTimeUserExperience.conciergeBot.url,
        {
          developersCommunityDomain:
            sessionManager.LEConfig.topLevelExternalLinks.developersCommunity,
          firstTime: '&experience=first_time',
          html: 'intro.html',
          accountId: sessionManager.getAccountId(),
          userName: this.userDetails['fullName'],
          env: env === 'ga' ? '' : `&env=${env}`
        }
      );
      return url;
    },
    handleButtonClick: function(data) {
      // Add button text as a response
      this.addSection({ response: data.text });
      const LOADING_DELAY = 1200;
      switch (data.id) {
        case 'conciergeBot':
          this.addSection({
            text: data.responses[0].text,
            textLoading: data.responses[0].textLoading,
            showBotIcon: true,
            delay: LOADING_DELAY,
             callback: () => {
              this.addSection({
                text: data.responses[1].text,
                textLoading: data.responses[1].textLoading,
                delay: 3000,

                callback: () => {
                  const timeout = setTimeout(() => {
                    this.closeModal();
                    gChannel.trigger('LiveEngageCommon', 'ShowNotification', data);
                    window.open(this.getConciergeBotUrl(), '_blank');
                  }, 3000);
                  // Save timeout for cancelling later
                  this.timeouts.push(timeout);
                }
              })
             }
          });
          break;
        case 'intentManager':
          this.addSection({
            text: data.responses[0].text,
            textLoading: data.responses[0].textLoading,
            showBotIcon: true,
            delay: LOADING_DELAY,
            callback: () => {
              this.addSection({
                text: data.responses[1].text,
                textLoading: data.responses[1].textLoading,
                delay: 3000,
                callback: () => {
                  this.addSection({ buttons: data.buttons });
                }
              });
            }
          });
          break;
        case 'mobileMessaging':
          this.addSection({
            text: data.responses[0].text,
            textLoading: data.responses[0].textLoading,
            showBotIcon: true,
            delay: LOADING_DELAY,
            callback: () => {
              this.addSection({
                text: data.responses[1].text,
                textLoading: data.responses[1].textLoading,
                delay: 3000,
                callback: () => {
                  this.addSection(mobileButtons);
                }
              });
            }
          });
          break;
        case 'learnMoreIntent':
          this.addSection({
            text: data.responses[0].text,
            textLoading: data.responses[0].textLoading,
            showBotIcon: true,
            delay: LOADING_DELAY,
            callback: () => {
              this.addSection({
                text: data.responses[1].text,
                textLoading: data.responses[1].textLoading,
                delay: 3000,
                callback: () => {
                  const timeout = setTimeout(() => {
                    this.closeModal();

                    gChannel.trigger(
                      'LiveEngageCommon',
                      'ShowNotification',
                      data
                    );
                    window.open(
                      urlResolver.getUrl(
                        ApiResources.FirstTimeUserExperience[data.id].url,
                        {
                          knowledgeCenterDomain:
                            sessionManager.LEConfig.topLevelExternalLinks
                              .knowledgeCenter
                        }
                      ),
                      '_blank'
                    );
                  }, 3000);
                  // Save timeout for cancelling later
                  this.timeouts.push(timeout);
                }
              });
            }
          });
          break;
        case 'guide':
          this.addSection({
            text: data.responses[0].text,
            textLoading: data.responses[0].textLoading,
            showBotIcon: true,
            delay: LOADING_DELAY,
            callback: () => {
              this.addSection({
                text: data.responses[1].text,
                textLoading: data.responses[1].textLoading,
                delay: 3000,
                callback: () => {
                  const timeout = setTimeout(() => {
                    this.closeModal();
                    window.open(
                      urlResolver.getUrl(
                        ApiResources.FirstTimeUserExperience[data.id].url,
                        {
                          developersCommunityDomain:
                            sessionManager.LEConfig.topLevelExternalLinks
                              .developersCommunity
                        }
                      ),
                      '_blank'
                    );
                  }, 3000);
                  // Save timeout for cancelling later
                  this.timeouts.push(timeout);
                }
              });
            }
          });
          break;
        case 'all-mobile-channels':
          this.addSection({
            text: data.responses[0].text,
            textLoading: data.responses[0].textLoading,
            showBotIcon: true,
            delay: LOADING_DELAY,
            callback: () => {
              this.addSection({
                text: data.responses[1].text,
                textLoading: data.responses[1].textLoading,
                delay: 3000,
                callback: () => {
                  const timeout = setTimeout(() => {
                    this.closeModal();
                    window.open(
                      urlResolver.getUrl(
                        ApiResources.FirstTimeUserExperience[data.id].url,
                        {
                          knowledgeCenterDomain:
                            sessionManager.LEConfig.topLevelExternalLinks
                              .knowledgeCenter
                        }
                      ),
                      '_blank'
                    );
                  }, 3000);
                  // Save timeout for cancelling later
                  this.timeouts.push(timeout);
                }
              });
            }
          });
          break;
        default:
          data.responses.forEach(response => {
            this.addSection({
              text: response.text,
              textLoading: response.textLoading,
              delay: LOADING_DELAY,
              showBotIcon: true,
              callback: () => {
                const delay = 5000 + response.text.length * 30;
                const timeout = setTimeout(() => {
                  this.closeModal();
                  gChannel.trigger('LiveEngageCommon', 'ShowNotification', {
                    helpText: 'LEFramework.firstTimeExperience.goodLuck',
                    autoCloseNotification: true
                  });
                  if (data.internalUrl) {
                    window.LE.router.push(data.internalUrl);
                  }
                }, delay);
                this.timeouts.push(timeout);
              }
            });
          });
      }
    }
  }
};
</script>
