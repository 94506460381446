/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:38 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var routingSourceSettings = (function () {
        var currentRoutingSource = null;


        var getCurrentRoutingSource = function () {
            if (currentRoutingSource === null) {
                currentRoutingSource = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.ROUTING_SETTINGS_SOURCE);
            }
            return currentRoutingSource;
        };


        return {
            getCurrentRoutingSource: getCurrentRoutingSource
        };
    })();

    return routingSourceSettings;
});
