<template>
  <div class="apps-main-wrapper">
    <div class="vue-main-wrapper">
      <div
        id="main-content-work-area"
        class="app-container"
      >
        <template
          v-for="(keepAliveDomApp, index) in keepDOMRenderedApps"
          :key="index"
        >
          <div
            v-show="selectedApp === keepAliveDomApp"
            class="keep-dom-rendered-apps-container"
          >
            <component :is="keepAliveDomApp" />
          </div>
        </template>
        <component
          :is="selectedApp"
          v-if="selectedApp && !keepDOMRenderedApps.includes(selectedApp)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getApplications } from '../apps-loader';
import TopBar from './app-frame/NavBar';
import { store } from 'vue-infra';

const apps = getApplications();

export default {
  name: 'VueHybridLayoutManager',
  components: { TopBar },
  data() {
    return {
      apps: [],
      keepDOMRenderedApps: [],
      selectedApp: null,
    };
  },
  watch: {
    $route: function () {
      this.calculateActiveViewContainer();
    }
  },
  created: function () {
    // store for future use...!!!
    window.MainViewControllerBody = window.document.getElementsByClassName('LP_mainwrapping_container')[0];
    const validApps = store.getters['environment/validApps'];
    Object.values(apps).filter((app) => {
      return app && validApps.indexOf(app.config.routePrefix) > -1;
    }).forEach(app => {
      if (app.keepDOMRendered) {
        this.keepDOMRenderedApps.push(app.config.routePrefix);
      } else {
        this.apps.push(app.config.routePrefix)
      }
    });
  },
  mounted: function () {
    // add app routes to store. (for the persona switcher)
    // this.calculateActiveViewContainer()
  },
  methods: {
    calculateActiveViewContainer: function () {
      const workAreaWrapper = document.getElementsByClassName('main-wrapper');
      const vueWorkAreaWrapper = document.getElementsByClassName('vue-main-wrapper');
      const reactWorkAreaWrappers = document.querySelectorAll('[class^="react-main-wrapper"]');
      const iframeWorkAreaWrappers = document.querySelectorAll('[class^="iframe-main-wrapper"]');
      if (workAreaWrapper.length === 0) {
        return;
      }
      if (!this.$route || !this.$route.meta) {
        return;
      }
      $('.startLoaderImg').remove();



      //Accessibility fix CCUI-70:
      const isSkipToMainContentAnchorAlreadyExists = document.getElementById("skip-to-main-content-anchor");
      if(!isSkipToMainContentAnchorAlreadyExists){
        const defaultSkipToMainContentCSS = {
          position: "absolute",
          left: "-9999px",
          zIndex: "9999999",
          backgroundColor: "white",
          color: "black",
          opacity: 0,
          border: "none",
          borderRadius: "3px",
          boxShadow: "var(--ds-shadow-overlay, 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 10px rgba(9, 30, 66, 0.08), 0 0 20px -4px rgba(9, 30, 66, 0.31))",
          margin: "10px 10px 10px 55px",
          padding: "0.8rem 1rem",
        };
        let skipToMainContent = $('<a tabindex="0" href="#main-content-work-area" id="skip-to-main-content-anchor" class="skip-to-main-content-link">Skip to main content</a>');
        skipToMainContent.css(defaultSkipToMainContentCSS);

        skipToMainContent.focus( function() {
          skipToMainContent.css({ "left":"3%","transform":"translateX(-50%)", "opacity":"1" });
        });
        skipToMainContent.blur( function() {
          skipToMainContent.css(defaultSkipToMainContentCSS);
        });

        $('body').prepend(skipToMainContent);
      }
      //
      if (this.$route.meta.marrionetteModule) {
        workAreaWrapper[0].style.display = "block";
        reactWorkAreaWrappers.forEach((el) => el.style.display = 'none');
        iframeWorkAreaWrappers.forEach((el) => el.style.display = 'none');
        vueWorkAreaWrapper[0].style.display = "none";
        window.LE.context.set('activeVueApp', undefined);
      } else if (this.$route.meta.react) {
        reactWorkAreaWrappers.forEach((el) => el.style.display = 'none');
        const reactApp = window.reactElements.find(({ appId }) => this.$route.path.includes(appId));
        const selectedApp = document.querySelector(`.react-main-wrapper-${reactApp.appId}`);
        workAreaWrapper[0].style.display = "none";
        vueWorkAreaWrapper[0].style.display = "none";
        iframeWorkAreaWrappers.forEach((el) => el.style.display = 'none');
        selectedApp.style.display = 'block';
        window.LE.context.set('activeReactApp', { applicationName: reactApp.appId, pageName: this.$route.name });
        window.LE.context.set('activeVueApp', undefined);
        window.LE.context.set('activeIframeApp', undefined);
      } else if (this.$route.meta.isIframe) {
        const { name: appId, meta } = this.$route;
        const { source } = meta || {};
        workAreaWrapper[0].style.display = "none";
        vueWorkAreaWrapper[0].style.display = "none";
        reactWorkAreaWrappers.forEach((el) => el.style.display = 'none');
        const appElement = document.querySelector(`.iframe-main-wrapper-${appId}`);
        if (!appElement) {
          const iframeElement = document.createElement('iframe');
          iframeElement.className = `iframe-main-wrapper-${appId}`;
          iframeElement.style.width = '100%';
          iframeElement.style.height = '100%';
          iframeElement.src = source;
          document.querySelector('.vue-main-wrapper').insertAdjacentElement('afterEnd', iframeElement);
        } else {
          appElement.style.display = 'block';
        }
        window.LE.context.set('activeIframeApp', { applicationName: appId, pageName: this.$route.name });
        window.LE.context.set('activeReactApp', undefined);
        window.LE.context.set('activeVueApp', undefined);
      } else {
        this.selectedApp = this.$route.path.split('/').filter(function (e) {
          return e
        })[0];
        workAreaWrapper[0].style.display = "none";
        reactWorkAreaWrappers.forEach((el) => el.style.display = 'none');
        iframeWorkAreaWrappers.forEach((el) => el.style.display = 'none');
        vueWorkAreaWrapper[0].style.display = "block";
        const applicationName = this.$route.path.split('/').filter(b => b).shift();
        const pageName = this.$route.name;
        const pageMeta = this.$route.meta;
        window.LE.context.set('activeVueApp', { applicationName, pageName, pageMeta });
        window.LE.navigator.vent.trigger("module:active", { moduleName: applicationName });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/scss/global";

.vue-main-wrapper {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;

  .app-container {
    height: 100%;
    position: relative;

    .keep-dom-rendered-apps-container {
      height: 100%;
      position: relative;
    }
  }
}

.apps-main-wrapper {
  top: $databar-height;
  bottom: 0;
  right: 0;
  left: $user-bar-width;
  position: absolute;
}
</style>
