define(function (require) {
    const registerModule = require('modules/registerModule');
    const { Logger, storeModuleTypes, environmentActionTypes, store } = require('vue-infra');

    const logger = Logger.getLogger('LEFramework');
    function randomMapping() {
        const valid = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let mapping = '';

        for (let i = 0; i < 8; i += 1) {
            mapping += valid.charAt(Math.floor(Math.random() * valid.length));
        }

        return mapping;
    }

    function calculateModules(){
        const moduleIds = {};
        const moduleNames = {
            all: {
                list: [],
                moduleMapping: '*',
                routePrefix: '',
            },
            '*': {
                list: [],
            },
        };
        const personaMap = {
            all: [],
        };
        const prefixMap = {};

        registerModule.modules.forEach((moduleConfig) => {
            const moduleName = moduleConfig.moduleName;
            const routePrefix = moduleConfig.routePrefix;
            const moduleId = moduleConfig.id;

            let personaMapping = moduleConfig.personaMapping;

            moduleIds[moduleId] = moduleConfig;

            moduleNames['*'].list.push(moduleName);

            if (typeof personaMapping === 'undefined') {
                personaMapping = routePrefix || randomMapping();
                personaMap.all.push(personaMapping);
            }

            if (typeof personaMapping !== 'undefined') {
                if (typeof moduleNames[personaMapping] === 'undefined') {
                    logger.debug(`define persona: ${personaMapping}`);
                    moduleNames[personaMapping] = {
                        list: [],
                    };
                } else if (typeof moduleNames[personaMapping].moduleConfig !== 'undefined') {
                    throw new Error(
                        `module config already exists in persona mappings for module
           ${moduleNames[personaMapping].moduleConfig.moduleName} this may indicate
          duplicate persona-mapping, please check the module settings`);
                }
                moduleNames[personaMapping].moduleConfig = moduleConfig;
                moduleNames[personaMapping].moduleMapping = moduleName;
                moduleNames[personaMapping].routePrefix = routePrefix;
                personaMap[moduleName] = personaMapping;
                prefixMap[routePrefix] = personaMapping;

                moduleConfig.personas.forEach((persona) => {
                    const personaName = (persona === '*') ? 'all' : persona;
                    if (typeof personaName !== 'undefined' && typeof moduleNames[personaName] === 'undefined') {
                        logger.debug(`'define persona: ${personaName}`);
                        moduleNames[personaName] = {
                            list: [],
                        };
                    }
                    moduleNames[personaName].list.push(moduleName);
                });
            }
        });

        const modules = {
            moduleNames,
            moduleIds,
            personaMap,
            prefixMap,
        };

        store.dispatch(`${ storeModuleTypes.ENVIRONMENT }/${ environmentActionTypes.MODULES_UPDATE }`, modules);
        return modules;
    }

    return {
        calculateModules
    };
});
