define(function (require) {
  const es6MiddleUtils = require('src/es6MiddleUtils');
  const LocaleResolver = require('i18n/localeResolver');
  const registerModule  = require('modules/registerModule');
  const{ storeModuleTypes, environmentActionTypes, i18n, Logger, store } = require("vue-infra");
  const LEConfig = require('assets/config/le-env-conf');
  const mainVueApps = require('src/components/mainVueApps');
  const logger = Logger.getLogger("LEFramework");
  const applications = {};
  const DEV_STRING = 'dev';
  const APP_STRING = 'app';
  // CDN application Names.
  // TODO: module loader.

  function scriptTagGenerator(src) {
    return new Promise ((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = false;

      script.onload = script.onreadystatechange = function() {
        script.onreadystatechange = script.onload = null;
        resolve();
      };

      script.onerror = function(e) {
        logger.error(`src ${src} fail to load, ${e}`);
        resolve();
      };

      const head = document.getElementsByTagName('head')[0];
      (head || document.body).appendChild(script);
    });
  }

  function calculateAppNameFromCdnName(cdnName){
    const staticPrefix = 'lp-le-';
    if(cdnName.includes(staticPrefix)) {
      return cdnName.replace(staticPrefix, '');
    }
    return cdnName;
  }

  function calculateApplicationAppIdFromName(appName) {
    const delimiter = '-';
    if(appName.includes(delimiter)){
      return appName.split(delimiter).map(name => name[0]).join('');
    } else {
      return appName.substr(0,3);
    }
  }

  function calculateConf(cdnName, version, useOrigLpCdnDomain) {
    const applicationType = LEConfig.dev === true ? DEV_STRING : APP_STRING;
    let conf = LEConfig.appsConfig[applicationType];
    if(LEConfig.dev === true) {
      conf.domain = LEConfig.leDevDomain;
      if(useOrigLpCdnDomain){
        conf = LEConfig.appsConfig.app;
        conf.domain = window.leResourceDomain || LEConfig.defaultLeResourceDomain;
      }
    } else {
      conf.domain = window.leResourceDomain;
    }
    conf.cdnName = cdnName;
    conf.name = calculateAppNameFromCdnName(cdnName);
    conf.appId = getAppIdFromCdnName(cdnName);
    conf.publicPath = conf.basePathTemplate.replace('{lpCdnDomain}', conf.domain)
      .replace('{appName}', conf.name)
      .replace('{version}', version);
    return conf;
  }

  async function loadScripts(array) {
    return new Promise(function(resolve, reject) {
      const promiseList = [];
      array.forEach(url => promiseList.push(scriptTagGenerator(url)));
      Promise.all(promiseList).then((data) => {
        resolve();
      });
    });
  }

  async function loadApps() {
    const bootstrapUrls = [];
    if (!LEConfig.leapps) {
      return Promise.resolve();
    }
    Object.keys(LEConfig.leapps).forEach(cdnName => {
      const conf = calculateConf(cdnName, LEConfig.leapps[cdnName].version, LEConfig.leapps[cdnName].useOrigLpCdnDomain);
      Object.assign(LEConfig.leapps[cdnName], {}, conf);
      bootstrapUrls.push(`${conf.publicPath}${conf.filePath}`);
    });
    await loadScripts(bootstrapUrls);
    loadModules(LEConfig.leapps);
  }

  function getAppIdFromCdnName(cdnName) {
    const name = calculateAppNameFromCdnName(cdnName);
    return calculateApplicationAppIdFromName(name);
  }

  function extractAppFromWindow(cdnName){
    const appLibraryName = cdnName.replace(/-/g, '_');
    const appId = getAppIdFromCdnName(cdnName);
    return es6MiddleUtils.removeEsModuleDefault(window[appId]) || es6MiddleUtils.removeEsModuleDefault(window[appLibraryName]);
  }

  function loadModules() {
    Object.keys(LEConfig.leapps).forEach(cdnName => {
      // TODO: function.
      applications[cdnName] = extractAppFromWindow(cdnName);
      if(applications[cdnName]){
        applications[cdnName].webpackPathInit(LEConfig.leapps[cdnName]);
        var appConfig = applications[cdnName].config;
        if (appConfig && !appConfig.isBackboneModule) {
          registerModule.registerExternal(appConfig);
        } else {
          logger.error(`Application ${cdnName} must contain config`);
        }
      } else{
        logger.error(`application ${cdnName} not loaded correctly`);
      }
    });
  }

  // TODO: after all uses their full name -> remove application project and get all lp_le from window.
  async function kickStartApps(){
    const locale = LocaleResolver.getLocale();
    const fallbackLocale = LocaleResolver.getDefaultLocale();
    window.externalVueComponent = null;
    if(Object.keys(applications).length > 0){
      await Promise.all(Object.keys(applications).map(async (storedAppId) => {
          try {
            const app = applications[storedAppId];
            const appConf = LEConfig.leapps[storedAppId]; // Object.values(LEConfig.leapps).find(app => app.appId === storedAppId);
            appConf.locale = locale;
            appConf.defaultLocale = fallbackLocale; // remove appConf.defaultLocale at 10.8
            appConf.fallbackLocale = fallbackLocale;
            if (app.applicationInit) {
              try {
                const {
                  appId,
                  routes,
                  i18nResource,
                  fallbackI18nResource,
                  appLocaleResource,
                  appFallbackLocaleResource,
                  vueComponent,
                  reactModule,
                  mountVueComponentToHeader,
                } = await app.applicationInit(appConf, LEConfig);
                if (appId !== undefined) {

                  //if the external module will not provide routes, we won׳t add it to sidebar
                  if (routes) {
                    //routes configuration for sidebar routes
                    let updatedRoutes = routes;
                    if (reactModule) {
                      updatedRoutes = routes.map((route) => (
                        {
                          ...route,
                          // create a dummy Vue component as it is required by vue-router
                          component: { template: '' },
                          meta: {
                            ...route.meta,
                            // Add { react: true } for CCUI to identify this route as react
                            react: true
                          }
                        }
                      ));
                    }
                    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.VALID_APPS_UPDATE}`, appId);
                    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.ROUTES_UPDATE}`, updatedRoutes);
                    const route = routes.find(r => r.name === 'default') || undefined;
                    appConf.defaultRoute = route && route.redirect && route.redirect.path;
                  }
                  if (i18nResource || appLocaleResource) { // remove i18nResource at 10.8
                    i18n.i18next.addResourceBundle(locale, appId, i18nResource || appLocaleResource); // remove i18nResource at 10.8
                  }
                  if ((appFallbackLocaleResource || fallbackI18nResource) && locale !== fallbackLocale) { // remove fallbackI18nResource at 10.8
                    i18n.i18next.addResourceBundle(fallbackLocale, appId, appFallbackLocaleResource || fallbackI18nResource);// remove fallbackI18nResource at 10.8
                  }
                  if (reactModule){
                    window.reactModule = [...(window.reactModule || []), { appId, createRoot: reactModule.createRoot }];
                    window.reactElements = [...(window.reactElements || []), { appId, element: reactModule.element }];
                  }
                  if (vueComponent) {
                    mainVueApps.mountContainer.component(vueComponent.name, vueComponent);

                    //Save components to window so the TopBarLaunchIcons component will be able to load them
                    if(mountVueComponentToHeader){
                      //store external components
                      window.externalVueComponent = vueComponent;
                      logger.debug(`>>>>>>>>>>>> header mount for Registered Component ${vueComponent.name  }`);
                    }

                    logger.debug(`>>>>>>>>>>>> after Registered Component ${vueComponent.name  }`);
                  }
                }
              }
              catch (e) {
                logger.error(`fail to load application ${storedAppId}: ${e}`);
              }
            }
          } catch (e) {
            logger.error(`fail to handle application config ${storedAppId}: ${e}`);
          }
        })
      );
      // remove lodash from the global scope.
      if (window._) {
        window._.noConflict();
      }
    }
  }

  /**
   * This function load a Backbone module dictionary and add it to the main dictionary
   * This dictionary is loaded from the backbone module app and isn't loaded from the languino project anymore.
   *
   * @param app - the Backbone app configuration
   *
   */
  async function loadBackboneModuleDictionary(app) {
    const locale = LocaleResolver.getLocale();
    const fallbackLocale = LocaleResolver.getDefaultLocale();
    if (app.config && app.config.isBackboneModule) {
      const moduleName = app.config.moduleName || '';
      if (app.getBackboneModuleDictionary) {
        try {
          const backboneModuleDictionary = await app.getBackboneModuleDictionary(locale, fallbackLocale);
          const dictionaryName = app.config.dictionaryName || app.config.moduleName;
          LE.translator.addModuleDictionary(locale, dictionaryName, backboneModuleDictionary.localResource);
          if (locale !== fallbackLocale) {
            LE.translator.addModuleDictionary(fallbackLocale, dictionaryName, backboneModuleDictionary.fallbackLocaleResource);
          }
        } catch (e) {
          logger.error(`fail to add backbone module dictionary ${moduleName} `, e);
        }
      } else {
        logger.error(`The Backbone module ${moduleName} must contains a getBackboneModuleDictionary function`);
      }
    }
  }

  function getApplications() {
    return applications;
  }

  function getApplicationConfigByCdnName(cndName) {
    return LEConfig.leapps[cndName];
  }

  return {
    kickStartApps,
    loadApps,
    getApplications,
    getApplicationConfigByCdnName,
    loadBackboneModuleDictionary,
  };
});
