/**
 * @fileOverview Contains POSTMESSAGE transport for the jQuery library
 * The transport works by utilizing LPAjax library to preform cross domain communication using jquery
 *
 * @author Gal Schneider
 * @version 0.1 - 2012-08-20
 * @author Itai Koren
 * @version 0.4 - 2013-07-01
 */
/**
 * Adds a new ajaxTransport: "postmessage"
 * Overrides ajaxPrefilter so if the request URL destination is in another domain, it is not JSONP to use the "postmessage" transport
 *
 * @namespace - contains the override ajaxPrefilter to use an added ajaxTransport logic
 */

define(function (require) {
    "use strict";
    var $ = require("jquery");
    var _ = require("underscore");
    var translator = require("i18n/translator");
    var localeResolver = require("i18n/localeResolver");
    var LpAjaxTransport = require("lptransporter/lpajax");
    var LEEndPointConfiguration = require("leEndPointConfiguration");
    var LEResourceResolver = require("leResourceResolver");
    var { Logger } = require('vue-infra');
    var crossDomainRequest = require("assets/js/cross-domain/crossDomainRequest");
    var LEConfig = require("assets/config/le-env-conf");

    // transports should be loaded after lpAjax, as they rely on it on to be present on the window.
    // do not change the order below unless you know what you're doing
    require("lptransporter/transports/websocketwrapper");
    require("traceLogger");
    require("lptransporter/lpajax_utils");
    require("lptransporter/transports/websocket2http");
    require("lptransporter/transports/xhr");
    require("lptransporter/transports/postmessage");
    require("lptransporter/transports/loggostransport");
    require("lptransporter/transports/jsonp");
    require("lptransporter/transports/rest2jsonp");
    require("assets/js/cross-domain/resources/filedownload");
    require("lptransporter/transports/fileupload");

    if(LEConfig.isBamSupportEnabled){
      require("lptransporter/transports/loggosbamtransport");
    }


    var scriptStr = "script",
        jsonStr = "json",
        textStr = "text";

    var logger = Logger.getLogger("LEFramework");
    // Create a transportTypes map which will help with
    // Translations between types defined in jquery and types expected by lpAjax
    // I deliberately use non-classic types in jquery in order to prevent any pre-processing by jquery
    // Which can interfere with my processing
    var transportTypes = {
        lpajax: {
            jquery: "lpajax-lpajax",
            lpajax: "lpajax",
            getCode: _getResponseCode,
            getBody: _getBody
        },
        getTypeByJquery: function (jquery) {
            var type;

            _.forOwn(this, function (value) {
                if (jquery === value.jquery) {
                    type = value;
                }
            });
            return type;
        }
    };

    // Since the lpAjax library already parses the response and converts the data,
    // I need to tell jQuery to ignore the response and not try to parse it.
    // This is done by registering empty converters
    transportTypes.converters = {};
    for (var transport in transportTypes) {
        if (transportTypes.hasOwnProperty(transport)) {
            transportTypes.converters[transportTypes[transport].jquery + " " + jsonStr] = true;
            transportTypes.converters[transportTypes[transport].jquery + " " + textStr] = true;
            if (transportTypes[transport].supportScript) {
                transportTypes.converters[transportTypes[transport].jquery + " " + scriptStr] = true;
            }
        }
    }
    transportTypes.converters["script json"] = true;

    // Register jQuery Ajax Prefilter that detects cross-domain requests and set the request data-type to "postmessage".
    $.ajaxPrefilter(preFilter);

    // Register the transports for handling postmessage & jsonp
    $.ajaxTransport("+" + transportTypes.lpajax.jquery, doTransport);

    /**
     * Acts as an intervention layer between the jQuery ajax request and routing to other transports
     * @param options
     * @param originalOptions
     */
    function preFilter(options, originalOptions) {
        // Skip URLs that start with ./ or ../, etc.
        if (LEResourceResolver.isSameOrigin(options.url) && !options.url.endsWith('session')) {
            return;
        }
        // Since the lpAjax library already parses the response and converts the data,
        // I need to tell jQuery to ignore the response and not try to parse it.
        // This is done by registering empty converters
        options.converters = transportTypes.converters;
        // Get the most matching transport type and update the data-type accordingly
        // We should also add this data-type value to the top of the list of processing types
        var transport = transportTypes.lpajax;
        options.dataType = transport.jquery;
        options.dataTypes = [transport.jquery];
        // When the jsonpCallback option is a string (random generated) and not a function (the default jQuery handler of jsonp),
        // It means that jQuery started processing this request as a jsonp request and already added the callback name and value to the url
        if (_.isString(options.jsonpCallback)) {
            _cleanJSONPCallback(originalOptions, options);
        }
    }

    /**
     * Executed as the last step of the postmessage transport. When the response returns back from the remote server
     * it is handled here.
     *
     * @param data {Object} - the data the is sent back from the remote server.
     * @param transportType  {Object} - the type of transport used for the request.
     * @param responseType (String) - the type of the processed response object.
     * @param done (Function)- the request origin callback.
     * @param req {Object}- the original request object
     */ function handleResponse(data, transportType, responseType, done, req) {

        _logResponse(data, req);

        var body = {};
        body[responseType] = transportType.getBody(data);

        try {
            done(
                transportType.getCode(data, responseType),
                data.HTTPStatus,
                body,
                _getResponseHeadersString(data)
            );
        }
        catch (ex) {
            logger.error("CrossDomain >> handleResponse >> done >> error >> ex=" + ex, "", ex);
        }
    }


    function handleError(data, transportType, responseType, done, req) {
        // In case I got an error response with code, I would like to parse the response and build an error message
        _enhanceErrorMessage(data);
        handleResponse(data, transportType, responseType, done, req);
    }

    function doTransport(options, originalOptions) {
        // Get the dataType
        var transportType = transportTypes.getTypeByJquery(options.dataTypes[0]);
        var responseType = "responseError";
        var complete;

        // Remove the temporary transport dataType
        options.dataTypes.shift();  // DO NOT DO THE REMOVE SINCE I NEED THE DATA CONVERTER TO BE ACTIVE

        return {
            send: function (requestHeaders, done) {
                // Build the request object
                var dataType = originalOptions.dataType || options.dataType;
                var req = $.extend(true, {}, crossDomainRequest);
                req = _setUpRequest(req, requestHeaders, options, originalOptions, transportType);

                req.success = function (data) {
                    // Set the response type to represent the dataType of the request
                    handleResponse(data, transportType, dataType, done, req);
                };

                req.error = function (data) {
                    options.responseFields[responseType] = responseType;
                    handleError(data, transportType, responseType, done, req);
                };
                // Default to not use any progress handler which will get called back for every XHR update and incurs a post message from the iframe
                // For any specific need for this progress handler, it should be supplied via the options object
                if (_.isFunction(options.progress)) {
                    req.progress = options.progress;
                }
                // Save for later for abort option
                complete = done;
                // Issue the request.
                LpAjaxTransport.issueCall(req);
            },

            abort: function () {
                if (_.isFunction(complete)) {
                    handleResponse({
                        code: 400,
                        status: "Request Aborted",
                        body: {},
                        originalHeader: "",
                        headers: {}
                    }, transportType, "responseError", complete);
                }
            }
        };
    }

    /**************************** Private Methods ******************************************
     ***************************************************************************************/

    function _enhanceErrorMessage(data) {
        if (data && data.responseCode === 403 || data.responseCode === 401) {
            if (data.body) {
                data.body.message = translator.translate("LEFramework.errors.serverError.1201");
            }
        }
        else if (data && data.body && !_.isUndefined(data.body.code)) {
            // The convention here is to build a translation key based on the code I've got from the server
            // with an additional prefix
            var constructKeyForTranslation = "LEFramework.errors.serverError." + data.body.code;

            // Check if I have the constructed key in my translation dictionary
            if (translator.hasTranslationKey(localeResolver.getLocale(), constructKeyForTranslation)) {
                // Set the actual translated error message
                data.body.message = translator.translate(constructKeyForTranslation);
            }
            else {
                // Key is not in the dictionary, it is possible I've got an error message which does not exist in my dictionary
                // And is therefore, already built by the server
                if (!_.isUndefined(data.body.message)) {
                    // Just re-parse the message to add the error code to it
                    data.body.message = data.body.code + " - '" + data.body.message + "'";
                }
                else {
                    // There is nothing valuable I can do with this code, I will have to set the default unknown error
                    // as a message - Good Luck understanding the real reason for this
                    data.body.message = translator.translate("LEFramework.errors.serverError.unknownError");
                }
            }
        }
    }

    function _logResponse(req, res) {
        var logMsg = "method: " + (req && req.method || "NONE") + " Url: " + (req && req.url || "NONE") + " code: " + (res && res.code) + " status: " + (res && res.status);
        logger.debug(logMsg, "jquery.crossdomain:handleResponse");
    }

    function _getBody(data) {
        var body = (data && data.body) || data;
        if (body && typeof body === "string") {
            try {
                body = JSON.parse(body);
            } catch (ex) {
                logger.error("Cannot parse JSON body from API,\nERROR: " + ex + ",\nBODY: " + body);
                logger.graph({name: "jquery.crossdomain:_getBody:CannotParseJSONBody"});
            }
        }
        return body;
    }

    function _getResponseHeadersString(data) {
        return data.originalHeader || (data.headers ? _stringifyAndAddLineBreaks(data.headers) : "");
    }

    function _getTransportOrder(req, options) {
        var transportOrder;
        if (options.dataType === "jsonp") {
            transportOrder = ["jsonp"];
        } else if (options.dataType === "filedownload") {
            transportOrder = ["filedownload"];
        } else {
            transportOrder = ["postmessage"];

        }
        return transportOrder;
    }

    function _setUpRequest(req, requestHeaders, options, originalOptions, transportType) {

        req.headers = requestHeaders;
        req.method = originalOptions.type;
        req.callbackName = options.lpAjaxCallbackName || req.callbackName;
        req.transportOrder = _getTransportOrder(req, originalOptions);
        // Pass data attribute for lpAjax to use inside the body of the
        // Request only (POST/PUT). Otherwise, Jquery already parsed it to the
        // URL as querystring parameters and we don't want lpAjax to add it the second time
        // Therfore we first check if jQuery already parsed it (if options.data is undefined it means that jquery already parsed it)
        if (!_.isUndefined(options.data)) {
            req.data = originalOptions.data;
        }
        req.url = options.url;
        req.timeout = options.timeout || $.ajaxSettings.timeout;
        req.retries = options.retries || $.ajaxSettings.retries;
        req.callback = options.lpAjaxCallbackParamName || req.callback;
        req.XMLHTTPOverride = options.emulateHTTP;
        req.mimeType = options.contentType;
        req.transportOrder.unshift(transportType.lpajax);
        req.cache = _.isBoolean(options.cache) ? options.cache : $.ajaxSettings.cache;

        return req;
    }

    function _stringifyAndAddLineBreaks(data) {
        var res;
        if (data) {
            res = JSON.stringify(data.headers);
            if (res) {
                res = res.replace(new RegExp("^{"), "");//Replace mustaches
                res = res.replace(new RegExp("$}"), "");//Replace mustaches
                res = res.replace(new RegExp("\",\"", "g"), "\r\n");//Replace JSON string separation with line breaks
                res = res.replace(new RegExp("\"", "g"), "");//Replace quotes with empty spaces
            }
            return res || "";
        } else {
            return data;
        }
    }


    function _getResponseCode(data, dataType) {
        var resCode = 400;

        if (data && (data.responseCode || Number(data.HTTPStatus))) {
            resCode = data.responseCode || Number(data.HTTPStatus);
        }
        else if (data && data.ResultSet) {
            if (data.ResultSet.lpCallId) {
                resCode = 200;
            }
        }
        else if (data && "jsonp" === dataType && data.status) {
            data.HTTPStatus = data.HTTPStatus || data.status;
            if ("ok" === data.status.toLowerCase()) {
                resCode = 200;
            }
        }

        return resCode;
    }

    function _cleanJSONPCallback(originalOptions, options) {
        // First lets check the original url (before jquery added the jsonp callback to it)
        // I do it in order to find a pattern of cb=? for example which is used by jquery to add the callback
        // This tests are taken straight from the jQuery code version 1.9.1 line #8364
        var callbackParamName;
        var rjsonp = /(=)\?(?=&|$)|\?\?/;
        var jsonProp = ( rjsonp.test(originalOptions.url) ?
                "url" :
                typeof originalOptions.data === "string" && !( originalOptions.contentType || "" ).indexOf("application/x-www-form-urlencoded") && rjsonp.test(originalOptions.data) && "data"
        );

        if (!!jsonProp) {
            // We have a match, lets do needed processing
            // I need to extract the callback name, if any, from the options
            // Match the parameter value pattern
            var match1 = originalOptions[jsonProp].match(rjsonp);

            if (match1 && 0 < match1.index) {
                // Extract the part before the "=value" of the parameter
                var partial = originalOptions[jsonProp].substr(0, match1.index) + "=";
                var match2 = partial.match(/(.*)[?&](.*)=$/);

                // Extract the parameter name
                if (match2 && 3 === match2.length) {
                    callbackParamName = match2[2];
                    options.lpAjaxCallbackParamName = callbackParamName;
                }
            }
        }

        // I would like to remove this from the url
        var param = (callbackParamName || options.jsonp) + "=" + options.jsonpCallback;
        var index = options.url.indexOf("?" + param);

        if (-1 < index) {
            // It is the first parameter
            if ("&" === options.url.substr(index + param.length + 1, 1)) {
                // I also need to replace the "&" of the next with "?"
                options.url = options.url.replace("?" + param + "&", "?");
            } else {
                // Just remove it, no further processing is needed
                options.url = options.url.replace("?" + param, "");
            }
        } else {
            // Just remove it, no further processing is needed
            options.url = options.url.replace("&" + param, "");
        }
    }

    return LEEndPointConfiguration.configureEndPoints;
});
