/**
 * Created with IntelliJ IDEA.
 * User: omerh
 * Date: 3/17/13
 * Time: 11:10 AM
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {
    'use strict';

    var _ = require('underscore');
    var CONST = require('const');
    var enUS = require('i18n/translations/en-US.json');
    var LocaleResolver = require('i18n/localeResolver');

    var Translator = (function () {

        var dictionary = {};
        var loadedLocale = LocaleResolver.getLocale();
        var onDemandLocale = false;
        var fallback = true;

        function loadEn() {
            var en = enUS;
            loadLocale(CONST.LANGUAGE['en-US']);
        }

        function addDictionary(locale, dict) {
            if (!_.has(dictionary, locale)) {
                dictionary[locale] = dict;
            }
        }

        function addModuleDictionary(locale, moduleName, dict) {
            addDictionary(locale, {});
            dictionary[locale][moduleName] = dict;
        }

        function parseKey(key) {
            var keySplit = key && key.split('.');
            var moduleName = keySplit && keySplit[0];
            var moduleKey = keySplit && keySplit.shift() && keySplit.join('.');

            return {
                module: moduleName,
                key: moduleKey
            };
        }

        function translate(key, locale) {

            // if didn't send the locale to translate, get it from the localeResolver
            if (!_.isString(locale) || _.isEmpty(locale)) {
                locale = LocaleResolver.getLocale();
            }

            if (_.isObject(key)) {
                return translateObject(key, locale);
            }
            return translateKey(key, locale);
        }

        function hasTranslationKey(locale, key) {
            var keyExist = false;
            var parsedKey = parseKey(key);
            var moduleName = parsedKey.module;
            var moduleKey = parsedKey.key;

            if (_.isPlainObject(dictionary) &&
              _.has(dictionary, locale) &&
              _.has(dictionary[locale], moduleName) &&
              dictionary[locale] &&
              dictionary[locale][moduleName] &&
              dictionary[locale][moduleName][moduleKey] &&
              _.isString(dictionary[locale][moduleName][moduleKey])) {
                keyExist = true;
            }
            return keyExist;
        }

        function getFallBackKey(key) {
            // in case the key is not found in the fallback(en-US)
            var text = '[??' + key + '??]';
            var fallbackLocale = CONST.LANGUAGE['en-US'];
            var parsedKey = parseKey(key);
            var moduleName = parsedKey.module;
            var moduleKey = parsedKey.key;

            // now check if the given kek exist in the fallback dictionary(en-US)
            if (hasTranslationKey(fallbackLocale, key)) {
                text = dictionary[fallbackLocale][moduleName][moduleKey];
            }

            return text;
        }

        function translateKey(key, locale) {
            // in case the key is not found, not in the given dictionary and not in the fallback(en-US)
            var text = '[??' + key + '??]';
            var parsedKey = parseKey(key);
            var moduleName = parsedKey.module;
            var moduleKey = parsedKey.key;

            if (hasTranslationKey(locale, key)) {
                text = dictionary[locale][moduleName][moduleKey];
            }
            else { // if the key was not found in the dictionary, check if we need to use fallback dictionary(en-US)
                if (fallback) {
                    text = getFallBackKey(key);
                }
            }

            return text;
        }

        /**
         *
         * @param obj
         *  {
         *      key: 'example.object',
         *      args: [
         *          {
         *              key: '',
         *              isTranslate: true
         *          },
         *          'value',
         *          1
         *      ]
         *  };
         *
         * @param locale
         * @returns {string}
         */
        function translateObject(obj, locale) {
            // in case the key is not found.
            var text = '[?? ??]';

            if (_.has(obj, 'key')) {
                text = translateKey(obj.key, locale);

                obj.args = (obj.args || []);

                for (var i = 0; i < obj.args.length; i++) {
                    var innerText, isTranslate;
                    switch (typeof obj.args[i]) {
                        case 'object':
                            innerText = !_.isUndefined(obj.args[i].key) ? obj.args[i].key : '[?? ??]';
                            isTranslate = obj.args[i].isTranslate;
                            break;
                        case 'string':
                            innerText = obj.args[i];
                            isTranslate = true;
                            break;
                        case 'number':
                            innerText = obj.args[i];
                            isTranslate = false;
                            break;
                        default:
                            innerText = '[?? ??]';
                            isTranslate = false;
                            break;
                    }

                    if (isTranslate) {
                        innerText = translateKey(innerText, locale);
                    }
                    text = text.replace(('{' + i + '}'), innerText);
                }
            }

            return text;
        }


        function loadLocale(locale, callback) {
            if (_.has(CONST.LANGUAGE, locale)) {
                if (locale === CONST.LANGUAGE['en-US']) {
                    // en-US is excluded from bundle-loader and pre-loaded by default
                    _doAddLocale(locale, enUS, callback);
                } else {
                    // all other locals are loaded dynamically using the bundle-loader
                    require('i18n/translations/' + locale + '.json')(function (dict) {
                          _doAddLocale(locale, dict, callback);
                    });
                }
            }
        }

        function _doAddLocale(locale, dict, callback) {
            loadedLocale = locale;
            onDemandLocale = true;
            addDictionary(loadedLocale, dict);

            // invoke the callback only if it's a function
            if (_.isFunction(callback)) {
                callback();
            }
        }

        function setUseFallbackDictionary(value) {
            if (!_.isUndefined(value) && _.isBoolean(value)) {
                fallback = value;
            }
        }

        // now we are ready to merge all the dictionaries we loaded
        loadEn();

        return {
            loadLocale,
            dictionary,
            hasTranslationKey: function (locale, key) {
                return hasTranslationKey(locale, key);
            },
            translate: function (key, locale) {
                return translate(key, locale);
            },
            setUseFallbackDictionary,
            addModuleDictionary,
        };

    }());

    return Translator;
});
