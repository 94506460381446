/**
 * Created with IntelliJ IDEA.
 * User: omerh
 * Date: 7/7/13
 * Time: 1:03 PM
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var Notifier = require('ui/notifier/Notifier');
    var CONST = require("const");
    var Translator = require("i18n/translator");

    var BaseRouterController = Marionette.Controller.extend({

        blockNavigation: function (options) {
            var notifier = new Notifier(CONST.MODULES.FRAMEWORK, CONST.APP_ROOT_CONTAINER, CONST.APP_ROOT_CONTAINER);
            var title = Translator.translate('LEFramework.blockNavigation.title');
            var message = Translator.translate('LEFramework.blockNavigation.message');
            var actionText = Translator.translate('LEFramework.blockNavigation.objectAction.message');
            if (options && options.blockTranslation) {
                title = options.blockTranslation.title || title;
                message = options.blockTranslation.message || message;
                actionText = options.blockTranslation.actionText || actionText;
            }
            notifier.showConfirm({
                title: title,
                content: message,
                "objectAction": {
                    text: actionText,
                    callback: options.callback
                }
            });
        }
    });

    return BaseRouterController;

});
