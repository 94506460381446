/***********************************************************************************************************************
 *
 * V.1.0
 * User: Shaul Mazor
 * Date: 27/03/2014

 * OrganizeCollectionDecorator includes 3 internal(!) classes: CollectionFilterer, CollectionSorter, CollectionPaginator.
 * It responsible for sorting, filtering and paging in the an order that ensure result correctness.
 *
 * *********************************************************************************************************************
 * PLEASE NOTE:
 * ADD/DELETE MODEL SHOULD BE DONE ON THE ORIGINAL COLLECTION
 * *********************************************************************************************************************
 * input:
 *   filterModel - optional
 *   pageSize  optional (integer)
 *   orderBy - optional ("desc", "asc")
 *   comparator: optional. Can by a string (column name) or function
 *
 * ********************************************************************************************************************/


define(function (require) {
	"use strict";

	var $ = require("jquery");
    var _ = require("underscore");
    var { Logger } = require('vue-infra');

    var CollectionFilterer = require ("decorators/organizeCollectionDecorator/CollectionFilterer");
    var CollectionPaginator = require ("decorators/organizeCollectionDecorator/CollectionPaginator");
    var CollectionSorter = require ("decorators/organizeCollectionDecorator/CollectionSorter");


	//============================================================
	// OrganizeCollectionDecorator
	//============================================================
    var ORIGINAL_EVENTS = {
        FETCH_SUCCESS: "fetch:success",
        ADD: "add",
        REMOVE: "remove",
        MODEL_CHANGE: "model:change" // This event should be triggered manually on demand by any controller that changes the attributes of a model in the collection
    };

	var OrganizeCollectionDecorator = function (original,_originalOptions) {

        _originalOptions = _originalOptions || {};
        var _lastKnownGoodOptions = _originalOptions;

		var organizeCollection = $.extend({}, original);

        var sorter = new CollectionSorter();
        var filterer = new CollectionFilterer();
        var paging = new CollectionPaginator();
        var logger = Logger.getLogger("LEFramework");

        var collectionName = "";
        if (original && original.name) {
            collectionName = original.name;
        } else if (_.isFunction(original.url)){
            collectionName = original.url();
        }


        function _onOriginalFetchSuccess() {
            //todo handle 304 unmodified  (no need to organize)
            //if (304 !== respnse)  etc
            logger.debug("_onOriginalFetchSuccess "+ collectionName, "OrganizeCollectionDecorator:_onOriginalFetchSuccess");
            organizeCollection.organize();
        }

        function _onOriginalAdd() {
            logger.debug("_onOriginalAdd " + collectionName, "OrganizeCollectionDecorator:_onOriginalAdd");
            organizeCollection.organize();
        }
        function _onOriginalRemove () {
            logger.debug("_onOriginalRemove " + collectionName, "OrganizeCollectionDecorator:_onOriginalRemove");
            organizeCollection.organize();
        }
        // This is NOT a Backbone event.
        // This event should be triggered manually on demand by any controller that changes the attributes of a model in the collection and want the collection re-organized after the change
        function _onModelChange() {
            logger.debug("_onModelChange " + collectionName, "OrganizeCollectionDecorator:_onModelChange");
            organizeCollection.organize();
        }

        organizeCollection.bindToOriginalCollectionEvents = function() {
            organizeCollection.unBindFromOriginalCollectionEvents(); //Dont allow double listeners ugli but working
            logger.debug("bindToOriginalCollectionEvents " + collectionName, "OrganizeCollectionDecorator:bindToOriginalCollectionEvents");
            original.on(ORIGINAL_EVENTS.FETCH_SUCCESS, _onOriginalFetchSuccess);
            original.on(ORIGINAL_EVENTS.ADD, _onOriginalAdd);
            original.on(ORIGINAL_EVENTS.REMOVE, _onOriginalRemove);
            original.on(ORIGINAL_EVENTS.MODEL_CHANGE, _onModelChange);
        };

        organizeCollection.unBindFromOriginalCollectionEvents = function() {
            logger.debug("unBindFromOriginalCollectionEvents " + collectionName, "OrganizeCollectionDecorator:unBindFromOriginalCollectionEvents");
            original.off(ORIGINAL_EVENTS.FETCH_SUCCESS, _onOriginalFetchSuccess);
            original.off(ORIGINAL_EVENTS.ADD, _onOriginalAdd);
            original.off(ORIGINAL_EVENTS.REMOVE, _onOriginalRemove);
            original.off(ORIGINAL_EVENTS.MODEL_CHANGE, _onModelChange);
        };


        //initial bind
        organizeCollection.bindToOriginalCollectionEvents();

		//----------------------------------------------------
		// decorated functions
		//----------------------------------------------------
        /**
         * organize ussualy resets to original list,
         *
         * @param options
         */
        organizeCollection.organize = function (options) {
            logger.debug("organize start" + collectionName, "OrganizeCollectionDecorator:organize");

            // _lastKnownGoodOptions is used to keep the merged options (argument) and originalOptions (the options when you init the decorator).
            // When the options (argument) is Empty we don't want to merge them, we do want to merge only if we get "options" as argument.
            // If we don't get it, we use the last merged object.
            if (!_.isEmpty(options)) {
                _lastKnownGoodOptions = $.extend({}, _lastKnownGoodOptions, options);
            }

            //first reset to original collection:
            organizeCollection.reset(original.models,{sort:false,silent:true});

            //Then organize using filter,pager,sorter
            filterer.filter(organizeCollection, _lastKnownGoodOptions);
            sorter.sort(organizeCollection, _lastKnownGoodOptions);
            paging.paginate(organizeCollection, _lastKnownGoodOptions);
            logger.debug("organize end ( before trigger reset)" + collectionName, "OrganizeCollectionDecorator:organize");
            organizeCollection.trigger("reset", organizeCollection, _lastKnownGoodOptions);
		};

        //----------------------------------------------------

        organizeCollection.getPagerInfo = function (){
            return paging.getInfo();
        };

        organizeCollection.getOriginalCollection = function() {
            return original;
        };

        //----------------------------------------------------

        if(!_originalOptions.lazy){
            organizeCollection.organize();
        }

		return organizeCollection;
	};

    OrganizeCollectionDecorator.decorate = function (backboneCollection,options) {
        return new OrganizeCollectionDecorator(backboneCollection,options);
    };

	return OrganizeCollectionDecorator;
});
