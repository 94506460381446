/**
 * Created with IntelliJ IDEA.
 * User: yniran
 * Date: 03/03/14
 * Time: 11:28
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var TemplateEngine = require("tmpl");
    var _ = require('underscore');

    Marionette.View.prototype.getIsRendered = function(){
      return this._isRendered || this.isRendered;
    };

    // Pre-compile the template before caching it. I am
    // overriding this method of Marionette since I want to change
    // the template engine used by Marionette from underscore to mustache.
    Marionette.TemplateCache.prototype.compileTemplate = function (rawTemplate) {
        return TemplateEngine.compile(rawTemplate);
    };

    /**
     * Overriding default Marionette, otherwise it'll append the itemView at the end no matter the index is
     */
    var compositeAppendHtml = Marionette.CompositeView.prototype.appendHtml;

    Marionette.CompositeView.prototype.appendHtml = function (collectionView, itemView, index) {   //todo research if this messes the document fragment buffering
        var childrenContainer = this.$(this.itemViewContainer);
        var children = childrenContainer.children();

        if (children.size() <= index) {
            compositeAppendHtml.apply(this, [].slice.apply(arguments));
        } else {
            childrenContainer.children().eq(index).before(itemView.el);
        }
    };


    //-------------------------------------------------------------
    // add - an alternative to region.show(), doesn't not remove permanent views
    //-------------------------------------------------------------

    Marionette.Region.prototype.add = function(view) {

        if(_.isObject(view) && !_.isEmpty(view.cid)){

            this.views = this.views || {};
            this.ensureEl();
            this._clean(view);

            if (!this._hasView(view)) {
                this._addView(view);
                view.render();

                var that = this;
                _.defer(function () {
                    that.$el.append(view.el);
                });
            }

            this._showView(view);
            Marionette.triggerMethod.call(view, "show");
            Marionette.triggerMethod.call(this, "show", view);
        }
    };

    //-------------------------------------------------------------

    Marionette.Region.prototype._clean = function(viewToShow) {

        for (var key in this.views) {

            var view = this.views[key];

            if (view && !view.isPermanent && !view.isClosed && view.cid !== viewToShow.cid) {
                if (view.close) {view.close();}
                else if (view.remove) {view.remove();}
               //todo this is missing:  Marionette.triggerMethod.call(this, "close", view);
                delete this.views[key];
            }
        }
    };

    //-------------------------------------------------------------

    Marionette.Region.prototype._hasView = function (view) {

        return _.isObject(this.views[view.cid]);
    };

    //-------------------------------------------------------------

    Marionette.Region.prototype._addView = function(view){

        var that = this;
        this.views[view.cid] = view;

        this.listenToOnce(view, "close", function () {
            delete that.views[view.cid];
        });
    };

    //-------------------------------------------------------------

    Marionette.Region.prototype._showView = function (view) {

        for (var key in this.views) {
            var _view = this.views[key];
            if (_view.cid !== view.cid) {
                _view.$el.hide();
            }
        }
        view.$el.show();
    };


    //-------------------------------------------------------------
    // override close - called by region.show()
    //-------------------------------------------------------------

    var _originalClose = Marionette.Region.prototype.close;

    Marionette.Region.prototype.close = function () {

        _originalClose.apply(this, [].slice.apply(arguments));

        for (var key in this.views) {

            var view = this.views[key];

            if(_.isObject(view)){
                if (view.close) {view.close();}
                else if (view.remove) {view.remove();}
                delete this.views[key];
            }
        }
    };
});
