/**
 * User: gals
 */
define(function (require) {
    "use strict";
    var IndustryCodes = require("codeTables/industryCodes.json");


    var IndustryUtility = (function () {

        return {

            /**
             * @returns {List of all the industries}
             */
            getIndustries: function () {
                return IndustryCodes.IndustryCodesArr;
            }

        };
    })();
    return IndustryUtility;

});
