/**
 * Created with IntelliJ IDEA.
 * User: noam
 * Date: 3/3/13
 * Time: 10:21 AM
 * A backbone model with implementation that wraps for us the whole working with account config revisions
 * It takes the revision from the header and put them into the models
 - todo Will handle the wrapping /unwrapping for account config + revision management.
 - It handle the save using the revision, if error happens return it like regular backbone error
 - todo on New model creation update the model from the result returned + update the revision + change the URL of the model
 - todo to the url with the new id returned ( server with return a URI )
 */
define(function (require) {
    "use strict";

    var { Logger } = require('vue-infra');
    var Memento = require("backbone.memento");
    var RevisionUtility = require("utils/RevisionUtility");

    var BaseModel = require("models/BaseModel");
  var _ = require('underscore');

    // constants
    var IF_MATCH_HEADER = "If-Match";
    var LAST_MODIFIED_HEADER = "X-LP-Last-Modified";

    var RevisionedModel = BaseModel.extend({
        //todo see description at the top
        // Initialize is an empty function by default. I am override it with

        initialize : function (attrs, options) {
            options = options || {};
            BaseModel.prototype.initialize.call(this,attrs, options);

            this.useMemento = this.useMemento || false;
            if (_.has(options, "useMemento")) {
                this.useMemento = options.useMemento;
            }

            this.logger = Logger.getLogger("LEFramework");
            if (this.useMemento && 'function' != typeof this.restart){
                this._initMemento();
            }
        },

        _initMemento: function(){
            var memento = new Memento(this);
            _.extend(this, memento);
        },

        parse: function(resp, options){ //on get requests - update revisions from header to model
            //reset the model memento stack to avoid memory consumption just before setting it with the parse result
            if (this.useMemento) {
                if ('function' != typeof this.restart){
                    this._initMemento();
                }
                this.clearStack();
            }

            this.setRevision(RevisionUtility.parseRevisionFromXhr(options.xhr));

            return resp;
        },


        // The following save and fetch are updating the model with response returned back from the server
        // So we need to: 1. On success Update revision 2. create a memento restore point
        //As the proper place to control the set of the model is parse I update it there with the header
        //Here I just update the memento
        //override original save to add store point to memento on success
        save: function(key, val, options) {
            var optionsIsVal = key == null || typeof key === 'object';
            if (optionsIsVal) {
                options = val;
            }

            options = options ? _.clone(options) : {};

            var success = options.success;
            options.success = function(model, resp, options) {
                if (model.useMemento) {
                    model.store();  //on success model was updated create a memento restore point
                }

                //todo On create url before did not have the correct id, update url from the response
                if (success) {
                    success(model, resp, options);
                } //call original success function
            };

            return BaseModel.prototype.save.call(this, key, val, options);
        },

        //override original fetch to add store point to memento on success
        fetch: function(options) {
            options = options ? _.clone(options) : {};
            var success = options.success;
            options.success = function(model, resp, options) {
                if (model.useMemento) {
                    model.store();  //on success model was updated create a memento restore point
                }

                if (success) {
                    success(model, resp, options);
                } //call original success function
            };
            return BaseModel.prototype.fetch.call(this,options);
        },

        /**
         *  Override original sync function to add revision on header
         *  @override
         */
        sync: function (method, model, options) {
            //on put/delete send revision to server in header, or if we want to use the 304, we need to add it also in the get (read)
            var shouldWeUse304Implementation = _.has(options, "use304Implementation") ? options.use304Implementation : this.use304Implementation;
            if (( method === 'update' || method === 'patch') || method == 'delete' || (shouldWeUse304Implementation && method === 'read')) {
                options.headers = options.headers || {};

                var revision = this.getRevision();
                if (revision) {
                    options.headers[IF_MATCH_HEADER] = revision;
                }

                var lastModified = this.getLastModified();
                if (lastModified) {
                    options.headers[LAST_MODIFIED_HEADER] = lastModified;
                }
            }
            BaseModel.prototype.sync.apply(this, [].slice.apply(arguments));
        },

        getCollectionRevision:function(all) {
            if (this.collection && this.collection.getRevision){
                return this.collection.getRevision(all);
            }
        },

        getCollectionLastModified:function() {
            if (this.collection && this.collection.getLastModified){
                return this.collection.getLastModified();
            }
        },

        setRevisionObject: function(revision) {
            if (revision) {
                this.lpRevision = revision;
            }
        },

        getRevisionObject: function() {
            return this.lpRevision || this.getCollectionRevision(true);
        },

        setRevision: function(revision) {
            if (revision) {
                if ("object" === typeof revision) {
                    this.setRevisionObject(revision);
                }
                else {
                    this.lpRevision = this.lpRevision || {};
                    this.lpRevision.val = revision;
                }
            }
        },

        getRevision: function(all) {
            if (all) {
                return this.getRevisionObject();
            }
            else if (this.lpRevision) {
                return this.lpRevision.val || this.getCollectionRevision(all);
            }
            else {
                return this.getCollectionRevision(all);
            }
        },

        setLastModified: function(lastModified) {
            if (lastModified) {
                this.lpRevision = this.lpRevision || {};
                this.lpRevision.lastModified = lastModified;
            }
        },

        getLastModified: function() {
            return this.lpRevision && this.lpRevision.lastModified || this.getCollectionLastModified();
        }

    });

    RevisionedModel.FetchState = BaseModel.FetchState;

    return RevisionedModel;
});
