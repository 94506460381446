/**
 * Created by odeds on 12/29/15.
 */
define(function (require) {
    "use strict";
    var BaseGallery = require("ui/gallery/gallery");
    var ApiKeysGalleryItemView = require("ui.components/gallery/js/views/ApiKeysGalleryItemView");

    var ApiKeysGallery = BaseGallery.extend({

        itemView: ApiKeysGalleryItemView,

        initialize:function (options) {
            BaseGallery.prototype.initialize.call(this, options);
        }

    });

    return ApiKeysGallery;
});
