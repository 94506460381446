define(function (require) {
    "use strict";

    var _ = require("underscore");
    var { sessionManager } = require("vue-infra");
    var CONST = require("const");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var UserModelAC = require("models/entities/UserModelAC");
    var UserModelAC_v3 = require("models/entities/UserModelAC_v3");
    var UserModelAS = require("models/entities/UserModelAS");
    var ApiResources = require("assets/data/apiResources.json");
    var LEResourceResolver = require("leResourceResolver");

    var URL_SELECT_PARAMETER = {
        ALL: "$all",
        LIST: [
            UserModelAC_v3.KEYS.ID,
            UserModelAC_v3.KEYS.PID,
            UserModelAC_v3.KEYS.DELETED,
            UserModelAC_v3.KEYS.USER_TYPE,
            UserModelAC_v3.KEYS.IS_API_USER,
            UserModelAC_v3.KEYS.EMAIL_ADDRESS,
            UserModelAC_v3.KEYS.LOGIN_NAME,
            UserModelAC_v3.KEYS.NICK_NAME,
            UserModelAC_v3.KEYS.DISPLAY_NAME,
            UserModelAC_v3.KEYS.EMPLOYEE_ID,
            UserModelAC_v3.KEYS.ENABLED,
            UserModelAC_v3.KEYS.RESET_MFA,
            UserModelAC_v3.KEYS.MAX_NUMBER_OF_CHATS,
            UserModelAC_v3.KEYS.MAX_NUMBER_OF_CONVERSATIONS,
            UserModelAC_v3.KEYS.SKILLS,
            UserModelAC_v3.KEYS.MEMBER_OF + "(agentGroupId,assignmentDate)",
            UserModelAC_v3.KEYS.MANAGER_OF + "(agentGroupId,assignmentDate)",
            UserModelAC_v3.KEYS.PROFILES,
            UserModelAC_v3.KEYS.LOBS
        ],
        PARTIAL: [UserModelAC_v3.KEYS.ID, UserModelAC_v3.KEYS.DELETED, UserModelAC_v3.KEYS.DISPLAY_NAME, UserModelAC_v3.KEYS.LOGIN_NAME]
    };

    var NAME = "UsersCollection";

    var UsersCollection = RevisionedCollection.extend({
        name: NAME,
        URL_SELECT_PARAMETER: URL_SELECT_PARAMETER,
        initialize: function(models,options) {
            options = options ||{};
            this.isLegacyUsers = (CONST.SOURCE_ENUM.LEGACY === sessionManager.getAccountSettingValueByID(sessionManager.ACCOUNT_SETTINGS_ID.USERS_SOURCE));
            this.isUsersWithProfiles = sessionManager.hasProfilesFeature();
            this.isUsersWithoutProfiles = !this.isLegacyUsers && !this.isUsersWithProfiles;
            this.source = options.source;

            this.urlSelectParameter = options.urlSelectParameter || URL_SELECT_PARAMETER.LIST;
            if (_.isArray(this.urlSelectParameter)) {
                if (this.isUsersWithoutProfiles) {
                    this.urlSelectParameter.push(UserModelAC.KEYS.PERMISSION_GROUPS);
                }
                this.urlSelectParameter = this.urlSelectParameter.join(",");
            }

            if(this.isLegacyUsers) {
                //this.resource = ApiResources.AccountConfig.Users;
                this.resource = ApiResources.AppServer.Operators;
                this.model = UserModelAS;
                options.change404To200OK = true;
            }
            else if (this.isUsersWithProfiles) {
                this.resource = ApiResources.AccountConfig.UsersWithProfiles;
                this.model = UserModelAC_v3;
            }
            else {
                this.resource = ApiResources.AccountConfig.Users;
                this.model = UserModelAC;
            }

            RevisionedCollection.prototype.initialize.call(this,models,options);
        },
        url: function() {
            var userTypeFilter = UserModelAC_v3.KEYS.USER_TYPE + "=" + JSON.stringify(_.values(UserModelAC_v3.USER_TYPES));
            const UsersWithProfilesWithSource = { ...ApiResources.AccountConfig.UsersWithProfiles, source: this.source };

            if(this.isLegacyUsers) {
                return LEResourceResolver.getUrl(ApiResources.AppServer.Operators);
            }
            else if (this.isUsersWithProfiles) {
                return LEResourceResolver.getUrl(UsersWithProfilesWithSource) + "&select=" + this.urlSelectParameter + "&" + userTypeFilter;
            }
            else {
              return LEResourceResolver.getUrl(ApiResources.AccountConfig.Users) + "&select=" + this.urlSelectParameter + "&" + userTypeFilter;
            }
        },
        getIdsArray: function() {
            var idsArray = this.pluck(this.model.KEYS.ID); // pluck is extracting a list of property values.
            return idsArray;
        },

        fetch: function(options) {
          options = options || {};
          if (options.fetchOptions && !_.isUndefined(options.fetchOptions.data)) {
            options.data = options.fetchOptions.data;
            if (options.data.agentGroupId) {
              this.setRevision(-1);
            }
          }
          return RevisionedCollection.prototype.fetch.call(this, options);
        },

        /**
         * send a batch update request.
         *
         * important:
         * invoking this function must be done on the original collection reference, not on a decorated reference.
         * any decorated collection, e.g. organizedCollection, selectableCollection, organizedCollection.getOriginalCollection(),
         * will have a stale value of the ac-revision, and so will fail the request under some conditions.
         *
         * @param options
         */
        batchUpdate: function (options) {

            var _success = options.success;
            options.success = function (models) {
                // merge the updated models into the collection
                this.add(models, {merge: true});
                // notify everyone about it
                this.trigger('reset', this, options);
                // delegate to passed callback
                if (_.isFunction(_success)) {
                    _success.apply(this, arguments);
                }
            }.bind(this);

            // replace the resource to allow overriding of request headers with the resource resolver
            var _resource = this.resource;
            this.resource = ApiResources.AccountConfig.UsersBatchUpdate;

            // replace the url to allow backbone's sync impl' to detect it via the collection instance
            var _url = this.url;
            this.url = LEResourceResolver.getUrl(ApiResources.AccountConfig.UsersBatchUpdate);

            // send the request
            this.update(options);

            // restore the stolen properties
            this.resource = _resource;
            this.url = _url;
        }
    });

    UsersCollection.NAME = NAME;
    UsersCollection.URL_SELECT_PARAMETER = URL_SELECT_PARAMETER;

    return UsersCollection;
});
