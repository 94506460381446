/**
 *
 * V2.0 danielr - 12/02/15
 *
 * refactor
 * the kpContent now accepts optionally content above the kp link
 * the kp link has now two elements: clickable icon and clickable link
 * option properties changed:
 *
 * container [optional] - the parent container in which to render the kpContent
 * content [optional]  the text to be displayed above/beside the kp link
 * contentPermutation [optional] - neither the content will be inline or above the kp link
 * showKPIcon [optional - default: true] displays the kp icon or not
 * KPLink [optional] the KP link caption
 * kpID [optional] the KP ID
 *
 * ***************
 *
 * V1.0
 *
 * User: danielr
 * Date: 03/11/14
 *
 * this widget will display a bubble with a link to a specific Knowledge Pack (KP) link
 *
 * Options:
 * text - mandatory. the body text of the bubble
 * showKPLink - optional - default: true - displays the KP link or not
 * kpCaption - optional - the KP link caption
 * kpID - optional. the KP ID
 *
 *
 *
 * *******************************************************************************************************
 *
 */
define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var template = require("ui.components/kpContent/templates/kpContent.tmpl");
    var Button = require("ui/button/Button");
    var _ = require('underscore');

    var LEKPEvent = "openKP";

    var KpContent = Marionette.ItemView.extend({
        version: 2.0,
        template: template,
        withUIComponents: true,
        className: "kp-content-container",
        ui: {
            "kpContent": ".kp-content",
            "kpContainer": ".kp-container",
            "kpIcon": ".kp-icon",
            "kpLink": ".kp-link"
        },
        events: {
            "click .kp-icon": "_showKP"
        },

        initialize: function(options) {
            this.container = options.container;
            this.content = options.content || "";
            this.contentPermutation = this._getContentPermutation(options);
            this.showKPIcon = _.has(options, "showKPIcon") ? options.showKPIcon : true;
            this.KPLink = _.has(options, "KPLink") ? options.KPLink : "";
            this.kpID = _.has(options, "kpID") ? options.kpID : "";
        },

        onRender: function(){
            this._displayControls();
            if (!_.isUndefined(this.container)){
                this.container.append(this.$el);
            }
            //Hide learn more for cafeX accounts
            this.ui.kpContainer.toggleClass("hide", LE.siteSettings.customizationSettings.isHideLearnMore());
        },

        setKPID: function(newKPID) {
          this.kpID = newKPID;
        },

        _getContentPermutation: function(options) {
            var contentPermutation;
            var defaultWithoutContent = KpContent.CONTENT_PERMUTATION.NONE;
            var defaultWithContent = KpContent.CONTENT_PERMUTATION.TOP;

            if (_.has(options, "contentPermutation")) {
                contentPermutation = options.contentPermutation;
            }
            else {
                if (!_.isEmpty(this.content)) {
                    contentPermutation = defaultWithContent;
                }
                else  {
                    contentPermutation = defaultWithoutContent;
                }
            }

            return contentPermutation;
        },

        _displayControls: function(){
            if (!this._showKPContentContainer()){
                this.$el.addClass("hide");
            }
            else {

                if (this._showContent()){
                    this.ui.kpContent.html(this.content);
                    this.ui.kpContent.addClass("show");
                    this.ui.kpContent.addClass(this.contentPermutation);
                }

                if (!this._showKPContainer()){
                    this.ui.kpContainer.addClass("hide");
                }
                else {
                    this.ui.kpContainer.addClass(this.contentPermutation);
                }

                if (!this.showKPIcon){
                    this.ui.kpIcon.addClass("hide");
                }

                if (!this._showKPLink()){
                    this.ui.kpLink.addClass("hide");
                }
                else {
                    this._generateKPLink();
                }
            }
        },

        _generateKPLink: function(){
            if (this.uiComponents && !this.uiComponents.kpLink)   {
                this.uiComponents.host$.createAndRender("kpLink", {
                    type : Button,
                    options : {
                        permutation:Button.PERMUTATION.BLUELINK,
                        el : "view.ui.kpLink",
                        text: this.KPLink
                    },
                    events : {
                        click : "_showKP"
                    }
                });
            }
        },

        _showContent: function(){
            return _.isString(this.content) && this.content !== "";
        },

        _showKPLink: function(){
            return _.isString(this.KPLink) && this.KPLink !== "";
        },

        _showKPContainer: function(){
            return this.showKPIcon || this._showKPLink;
        },

        _showKPContentContainer: function(){
            return this._showKPContainer() || this._showContent();
        },

        _showKP: function(){
            if (this.kpID !== ""){
              this.trigger(KpContent.TRIGGERING.KP_LINK_CLICK);
              LE.vent.trigger(LEKPEvent, {fragment: this.kpID});
            }
        }

    });

    KpContent.CONTENT_PERMUTATION = {
        "NONE": "",
        "TOP": "with-top-content",
        "INLINE": "with-inline-content"
    };

    KpContent.TRIGGERING = {
        "KP_LINK_CLICK": "click"
    };

    return KpContent;
});
