import LocaleResolver from 'i18n/localeResolver';
import { i18n, Logger } from 'vue-infra';

const logger = Logger.getLogger('LEFramework');

async function init() {
  const translation = {
    lng: LocaleResolver.getLocale(),
    fallbackLng: LocaleResolver.getDefaultLocale(), // define a default fallback.
  };
  await i18n.init(translation);
  logger.debug('i18n: init >>>>>>>>>>>>>>>>  after i18n.init');
}

export default {
  init
};