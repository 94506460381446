<template>
  <div
    :class="[
      'nav-list__item__dropdown',
      {
        'nav-list__item__dropdown-open':
          this.openCategoryIndex === this.index && this.isCategoryOpen
      },
      { 'nav-list__item__dropdown-animate': this.animate }
    ]"
  >
    <div class="ps-s-icon s-dropdown"></div>
  </div>
</template>

<script>
export default {
  name:"NavBarDropDownIcon",
  props: {
    openCategoryIndex: Number,
    index: Number,
    isCategoryOpen: Boolean,
    animate: Boolean
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/navigation_bar';
@import '../../../../assets/scss/navigation_bar_icons';

.ps-s-icon {
  width: 24px;
  height: 24px;
}
</style>
