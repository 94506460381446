/**
 * Created with IntelliJ IDEA.
 * User: noam
 * Date: 2/26/14
 * Time: 10:53 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var _ = require("underscore");
    var Translator = require("i18n/translator");

    var CASE_INSENSITIVE = "i";

    var StringFormats = (function () {


        return {

            getRegExpForSearch: function(str) {
                var escapedStr = str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"); // taken from: http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
                var regex = new RegExp(escapedStr, CASE_INSENSITIVE);

                return regex;
            },

            getGeneralErrorMsg: function (errorObject) {
                var numOfErrors = _.keys(errorObject).length;
                return  this.getGeneralErrorMsgByNumOfErrors(numOfErrors);
            },
            /******
             * Get a general error that says : you have 1 error on page, or x errors on page according to num of errors
             * @param numOfErrors
             * @returns {String}
             */
            getGeneralErrorMsgByNumOfErrors: function (numOfErrors) {
                var errorWord = (numOfErrors > 1) ? Translator.translate("LEFramework.errors.generalError.errors.text") : Translator.translate("LEFramework.errors.generalError.error.text");
                var generalErrorMessage = Translator.translate("LEFramework.errors.generalError").replace("{0}", numOfErrors).replace("{1}", errorWord);

                return generalErrorMessage;
            }
        };
    })();
    return StringFormats;

});
