/**
 *
 */
define(function (require) {
    "use strict";

    var LE = require("app");
    var { Logger } = require('vue-infra');
    var logger = Logger.getLogger("CoreComponents");

    var _ = require("underscore");
    var Marionette = require("marionette");

    var template = require("ui.components/gallery/templates/apiKeyGalleryItemOptionBubbleView.tmpl");
    var DomUtilities = require("infra-util/DomUtilities");

    var ApiKeyGalleryItemOptionBubbleView = Marionette.ItemView.extend({
        template: template,
        className: "apikey-option-view",
        ui: {
            apiKey: ".apiKey",
            apiSecret: ".apiSecret",
            accessToken: ".accessToken",
            accessTokenSecret: ".accessTokenSecret"
        },
        withUIComponents: true,
        events: {
            "click .value": "selectValue"
        },


        // overridden
        initialize: function (options) {
            logger.debug("enter function", "ApiKeyGalleryItemOptionBubbleView:initialize");
            options = options || {};
        },

        onRender: function () {
            logger.debug("enter function", "ApiKeyGalleryItemOptionBubbleView:onRender");

        },

        onClose: function () {
            logger.debug("enter function", "ApiKeyGalleryItemOptionBubbleView:onClose");

        },

        // public
        selectValue: function (event) {
            logger.debug("enter function", "ApiKeyGalleryItemOptionBubbleView:selectValue");
            var $el= $(event.target);
            DomUtilities.selectItemText($el);
        }



    });

    return ApiKeyGalleryItemOptionBubbleView;
});
