define(function (require) {
    "use strict";

    var _ = require("underscore");
    var Marionette = require("marionette");
    var template = require("ui.components/schedule-picker/templates/schedulePickerItemData.tmpl");
    var ScheduleUtilities = require("utils/ScheduleUtilities");
    var Translator = require("i18n/translator");

    var SchedulePickerItemDataView = Marionette.ItemView.extend({
        className: "schedule-picker-item-data-container",
        template: template,

        customTemplateHelpers: function() {
            return {
                daysArr: this._getDaysArr(),
                timezone: this._getTimezone()
            };
        },

        initialize: function(options) {
            this._initialize(options);
        },
        onRender: function() {
            this._onRender();
        },

        _initialize: function(options) {
            options = options || {};

            this.container = options.container;
            this.model = _.has(options, "model") ? options.model : null;
        },
        _onRender: function() {
            if (!_.isEmpty(this.container)) {
                this.container.append(this.$el);
            }
        },
        _getDaysArr: function() {
            var daysArr = [];
            this.model.getScheduleEventsCollection().each(function(eventModel) {
                if (eventModel.getType() !== ScheduleUtilities.EVENT_TYPES.INACTIVE) {
                    var day = {};
                    day.name = Translator.translate("LEUIComponents.schedulePicker.days." + eventModel.id);
                    day.shifts = [];
                    if (eventModel.getType() === ScheduleUtilities.EVENT_TYPES.ALL_HOURS) {
                        day.shifts.push({from: Translator.translate("LEUIComponents.schedulePicker.allday"), to:""});
                    }
                    else {
                        var timesOptions = ScheduleUtilities.getShiftTimesOptionsForSingleSelect();
                        eventModel.getShiftsCollection().each(function(shiftModel) {
                            var from = _.find(timesOptions, {id:shiftModel.getStartTime()}).value;
                            var to = _.find(timesOptions, {id:shiftModel.getEndTime()}).value;
                            day.shifts.push({
                                from: Translator.translate("LEUIComponents.schedulePicker.custom.from") + from,
                                to: Translator.translate("LEUIComponents.schedulePicker.custom.to") + to,
                            });
                        });
                    }
                    daysArr.push(day);
                }
            });
            return daysArr;
        },
        _getTimezone: function() {
            var timezone = this.model.getTimezone();
            return window.LE.siteSettings.timeZoneSettings.getCurrentTimeZoneTranslated(timezone);
        }


    });

    return SchedulePickerItemDataView;

});
