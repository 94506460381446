const { BaseClient } = require('vue-infra');
const LEConfig = require('assets/config/le-env-conf');

export default class SourceQueryParamDecorator {
  /**
   * Returns the string to be used for the source query parameter
   * @param source Optional source object, containing projectName, appName and contextName.
   * @returns {string}
   */
  static buildSourceName(source) {
    if (!BaseClient || !BaseClient.buildSourceName || !LEConfig || !LEConfig.addSourceQueryParameter) {
      return '';
    }
    return BaseClient.buildSourceName(source);
  }

  /**
   * Adds the source query parameter to the url (if it doesn't already exist, or if it should be replaced)
   * @param url - The URL to add the source query parameter to (if necessary)
   * @param source - The source object, to be used to construct the string for the query parameter
   * @param replace - If true - we should replace the source query parameter if it already exists
   * @returns {string} - The URL including a source query parameter
   */
  static addSourceQueryParam(url, source, replace = false) {
    if (!BaseClient || !BaseClient.checkIfSourceExists || !LEConfig || !LEConfig.addSourceQueryParameter) {
      return url;
    }
    const exists = BaseClient.checkIfSourceExists({config: {baseURL: url}});
    if (exists && !replace) {
      return url;
    }
    if (!source) {
      source = this.source || (this.resource && this.resource.source);
    }
    source = SourceQueryParamDecorator.buildSourceName(source);
    if (exists && replace) {
      url = url.replace(/(source=)[^\&]+/, `$1${source}`);
    } else {
      let prefix = '?';
      if (url.indexOf('?') >= 0) {
        prefix = '&';
      }
      url = `${url}${prefix}source=${source}`;
    }
    return url;
  }
}
