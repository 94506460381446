/**
 * Created by tomers on 9/3/2014.
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var DependencyService = require("src/components/dependency-service/controllers/DependencyService");
  var _ = require('underscore');


    var PageHeaderViewDependencyMethods = {
        fetchDependencies: function(options) {
            var model = options.model;
            var successCallback = options.success;
            var errorCallback = options.error;
            var campaignId = options.campaignId;

            if (model.isNew()) {
                return;
            }

            DependencyService.getDependencies(model, {
                success: _.bind(this._getDependenciesSuccess, this),
                error: _.bind(this._getDependenciesError, this),
                dependeeModel: model,
                successCallback: successCallback,
                errorCallback: errorCallback,
                campaignId: campaignId
            });
        },

        _getDependenciesSuccess: function(collection, response, options) {
            var dependencyCollection;
            var dependeeModel = options.dependeeModel;
            var successCallback = options.successCallback;
            var campaignId = this.context.get("areaState.campaign.selectedItem");

            if (this.isClosed) {
                return;
            }

            if (collection.size() === 0) {
                dependencyCollection = new Backbone.Collection();
            }
            else {
                dependencyCollection = collection.get(dependeeModel.id).get("dependencies");
            }

            if (campaignId) {
                var campaignToBeRemoved = dependencyCollection.findWhere({
                    id: String(campaignId),
                    type: "LECampaignObject"
                });
                if (campaignToBeRemoved) {
                    dependencyCollection.remove(campaignToBeRemoved, {silent: true});
                }
            }

            if (successCallback) {
                successCallback(dependencyCollection);
            }
        },

        _getDependenciesError: function(collection, response, options) {
            var errorCallback = options.errorCallback;
            if (errorCallback) {
                errorCallback(collection, response, options);
            }
        }

    };


    return PageHeaderViewDependencyMethods;
});

