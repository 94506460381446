<template>
  <div
    class="top-level-menu-custom-reason-item"
    role="button"
    tabindex="0"
    @click="clicked"
    @keydown.enter.space.prevent="clicked"
  >
    <div class="top-level-menu-custom-reason-item-text ellipsis">
      {{ customReason.text }}
    </div>
    <div
      v-if="isChosen"
      class="is-chosen-icon"
    >
      <div class="s-mark"></div>
    </div>
  </div>
</template>

<script>

    export default {
        name: "TopLevelMenuCustomReasonItemView",
        props: {
            customReason: {
                type: Object,
                default: () => {},
            },
            isChosen: {
              type: Boolean
            }
        },
        emits: ['clicked'],
        methods: {
            clicked() {
              this.$emit('clicked', this.customReason);
            }
        }
    }

</script>

<style lang='scss' scoped>
@import "../../../../assets/scss/global";
@import "../../../../assets/scss/mixins";

$icons: s-mark;
@include add-icons-from-list($icons);
</style>
