/**
 * User: adieli
 * Date: 7/1/14
 * Storage proxy
 */
define(function (require) {
    "use strict";
    var { Logger } = require('vue-infra');
    var storage = require('js2RestUtilsAsync').storageMethods;
    var logger = Logger.getLogger("LEFramework");
    var DateUtility = require("utils/DateUtility");
    var JsonUtilities = require("infra-util/JsonUtilities");

    var storageAPI = (function () {

        function getItem(key) {
            var item = null;
            try {
                item = JSON.parse(storage.getPersistentData(key));
            } catch(e) {}
            return item;
        }

        function setItem(key, value) { //Double strigified because of backward compatibility issues - previously jquery would do the same
            storage.setPersistentData(key, JSON.stringify(value));
        }

        return {
            /**
             * Returns the local storage value identified by the key.
             * If is of TTL type and expired returns null.
             * If the stored data isn't valid, returns null.
             * @throws exception if the key isn't valid.
             * @param key The key of a value stored in the local storage
             */
            get: function (key) {
                if (!key) {
                    logger.info("Invalid key");
                    throw "Key must be of type String.";
                }
                else {
                    var data = JsonUtilities.loadJSON(getItem(key) || null);
                    if (data){
                        if (data.expirationDate && DateUtility.isExpired(data.expirationDate)) {
                            logger.info("data expired");
                            this.remove(key);
                            return null;
                        }
                        else {
                            return data.val;
                        }
                    }
                    else {
                        return null;
                    }
                }
            },

            /**
             * Sets the key and value to the local storage
             * @param key: should be a string
             * @param value: should be a string
             * @param type: should be storage.TYPE
             * @param period: time  in milliseconds till storage expires. Should be a number
             * @throws exception if the key isn't valid.
             */
            set: function (key, value, type, period) {
                if (!key || !value) {
                    logger.info("Invalid key or value");
                    throw "Key and value must be of type String.";
                }
                else {
                    var data = {
                        val: value
                    };

                    if (type && type === storageAPI.TYPE.TTL){
                        if (typeof period === "number") {
                            data.expirationDate = new Date().getTime() + period;
                        }
                    }
                    setItem(key, JSON.stringify(data));
                }

            },
            /**
             * Removes the value identified by the key from the storage
             * Ignores null or empty key
             * @param key - the identification of the element in the storage
             * @throws exception if the key isn't valid.
             */
            remove: function (key) {
                if (!key) {
                    logger.info("Invalid key");
                    throw "Key must be of type String.";
                }
                else {
                    storage.removeSessionData(key);
                }
            }

        };
    })();

    storageAPI.TYPE = {
        FOREVER: 1, //never expires
        SESSION: 2, //expires when LE session ends
        TTL: 3 //expires when given period passes
    };

    return storageAPI;

});
