<template>
  <li>{{ text }}</li>
</template>

<script>
export default {
  name: 'FirstTimeListItem',
  props: {
    bulletPoint: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      text: this.bulletPoint ? this.$translator.translate(this.bulletPoint) : ''
    };
  }
};
</script>
