/**
 * Created by tomers on 4/26/2016.
 */
define(function (require) {
    "use strict";

  var _ = require('underscore');
    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");
    var ErrorCodes = require("codeTables/errorCodes.json");
    var LocaleResolver = require('i18n/localeResolver');

    var ApiResources = require("assets/data/apiResources.json");


    var SUPPORTED_ENTITY_TYPES = {
        CAMPAIGN: "LECampaignObject",
        USER: "ACUserObject",
        SKILL: "ACSkillObject",
        CANNED_RESPONSE: "ACCannedResponse"
    };

    var KEYS = {
        NAME: "name",
        DESCRIPTION: "description",
        COUNTS: "counts"
    };

    var LobModel = RevisionedModel.extend({
        resource: ApiResources.AccountConfig.Lobs,
        KEYS: KEYS,
        SUPPORTED_ENTITY_TYPES: SUPPORTED_ENTITY_TYPES,
        name: "LobModel",
        defaults: {
            id: null,
            name: ""
        },

        saveEnableValidation: {
            name: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.lob.errors.name.required"
                }
            ]
        },

        validation: {
            name: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.lob.errors.name.required"
                },
                {
                    maxLength: Config.validators.lob.maxNameLength,
                    msg: "LEFramework.entities.lob.errors.name.length"
                }
            ],
            description: {
                required: false,
                maxLength: Config.validators.lob.maxDescriptionLength,
                msg: "LEFramework.entities.lob.errors.description.length"
            }
        },

        initialize: function (attributes, options) {
            options = options || {};
            this.source = options.source;
            this.valueIsChanged = false;
            RevisionedModel.prototype.initialize.call(this, options);
        },

        getName: function () {
            return this.get(KEYS.NAME);
        },
        setName: function (name) {
            this.valueIsChanged = true;
            this.set(KEYS.NAME, name);
        },

        getDescription: function () {
            return this.get(KEYS.DESCRIPTION);
        },
        setDescription: function (description) {
            this.valueIsChanged = true;
            this.set(KEYS.DESCRIPTION, description);
        },

        //getUsersCount: function() {
        //    return this.get(KEYS.USERS_COUNT);
        //},

        getCounts: function(entityName) {
            return this.get(KEYS.COUNTS)[entityName];
        },

        isValueChanged: function () {
            return this.valueIsChanged;
        },

        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },

        destroy: function (options) {
            options = options ? _.clone(options) : {};
            var origError = options.error;
            options.error = _.bind(function (collection, resp, opt) {
                var errorMessage = LE.translator.translate("LEFramework.something.went.wrong");
                if (resp && resp.responseError && resp.responseError.internalCode) {
                    var errorFields, translatedFields = [];
                    var key, translatedField = "";
                    if (resp.responseError.internalCode === ErrorCodes.dependencyConflict || resp.responseError.internalCode === ErrorCodes.dependencyConflictExternal) {
                        if (resp.responseError.fields) {
                            errorFields = resp.responseError.fields;
                        }

                        if (!_.isEmpty(errorFields)) {
                            for (var i = 0; i < errorFields.length; i++) {
                                key = "LEFramework.entities.lob.delete.error.dependencies.field." + errorFields[i];
                                translatedField = LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), key) ? LE.translator.translate(key) : errorFields[i];
                                translatedFields.push(translatedField);
                            }

                            errorMessage = LE.translator.translate({
                                key: "LEFramework.entities.lob.delete.error.dependencies",
                                args: [{key: translatedFields.join('<br>'), isTranslate: false}]
                            });

                        }
                    }
                }
                options.errorMessage = errorMessage;
                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            RevisionedModel.prototype.destroy.call(this, options);
        }
    });

    LobModel.SUPPORTED_ENTITY_TYPES = SUPPORTED_ENTITY_TYPES;

    return LobModel;
});
