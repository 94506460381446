define(function (require) {
    "use strict";

    var Translator = require("i18n/translator");
    var Button = require("ui/button/Button");
    var ToggleButton = require("ui/toggle-button/toggleButton");
    var BaseGalleryItemView = require("ui/gallery/js/views/GalleryItemView");
    var template = require("ui.components/gallery/templates/conversationSourcesGalleryItem.tmpl");

    var NO_CONFIGURED_CLASS = "not-configured";

    var ConversationSourcesGalleryItemView = BaseGalleryItemView.extend({
        initialize:function(options) {
            this.conversationSourcesGalleryCollection = options.originalCollection;
            this.conversationSourcesGalleryModel = this.conversationSourcesGalleryCollection.get(this.model.getKey());
            this.ui.enabledContainer = ".enabled-container";
            this.ui.connectContainer = ".connect-container";
            BaseGalleryItemView.prototype.initialize.call(this, options);
            if (this.model.isRegularType()) {
                this.template = template;
            }
        },

        customTemplateHelpers: function() {
            var templateHelpers = BaseGalleryItemView.prototype.customTemplateHelpers.call(this);
            templateHelpers.shortDescription = this.conversationSourcesGalleryModel.getShortDescription();
            templateHelpers.fullDescription = this.conversationSourcesGalleryModel.getFullDescription();
            templateHelpers.logoUri = this.conversationSourcesGalleryModel.getLogoUri();

            var notConfigured = !this.conversationSourcesGalleryModel.isOnboarding() && (this.conversationSourcesGalleryModel.isConfigurable() && !this.conversationSourcesGalleryModel.isConfigured());
            templateHelpers.notConfiguredClass = notConfigured ? NO_CONFIGURED_CLASS : "";

            return templateHelpers;
        },

        onRender: function(){
            BaseGalleryItemView.prototype.onRender.call(this);

            if (this.conversationSourcesGalleryModel.isConfigurable() || this.conversationSourcesGalleryModel.isOnboarding()) {
                if (this.conversationSourcesGalleryModel.isConfigured()) {
                    this._renderEnabledComponent();
                }
                else {
                    this._renderConnectButton();
                }
            }
        },

        _renderEnabledComponent: function() {
            this.uiComponents.host$.createAndRender("enabledButton", {
                type: ToggleButton,
                options: {
                    container:"view.ui.enabledContainer",
                    checked: this.conversationSourcesGalleryModel.isEnabled(),
                    enabled: false
                }
            });
        },

        _renderConnectButton: function() {
            this.uiComponents.host$.createAndRender("connectButton", {
                type: Button,
                options: {
                    permutation: Button.PERMUTATION.BTN_PRIMARY,
                    text: this.conversationSourcesGalleryModel.getButtonLabel() || Translator.translate("LEFramework.Connect"),
                    el: "view.ui.connectContainer"
                },
                events: {
                    click: "onEditClick"
                }
            });
        }
    });

    return ConversationSourcesGalleryItemView;
});
