/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:37 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var CONST = require("const");
    var SettingsCollection = require("collections/SettingsCollection");

    var contentSettings = (function () {
        var cannedResponsesSource = null;
        var systemMessagesSource = null;
        /**
         * The source represents where to take canned responses from
         * @returns {LEGACY|MIGRATING|LE}
         */
        var getCannedResponsesSource = function () {
            if (cannedResponsesSource == null) {
                cannedResponsesSource = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CANNED_RESPONSES_SOURCE, CONST.SOURCE_ENUM.LEGACY);
            }
            return cannedResponsesSource;
        };
        /**
         * The source represents where to take system messages from
         * @returns {LEGACY|MIGRATING|LE}
         */
        var getSystemMessagesSource = function () {
            if (systemMessagesSource == null) {
                systemMessagesSource = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.SYSTEM_MESSAGES_SOURCE, CONST.SOURCE_ENUM.LEGACY);
            }
            return systemMessagesSource;
        };
        var updateContentSettings = function(){
            var contentSettingsToUpdate = {
                cannedResponsesSource: {
                    initialVal: getCannedResponsesSource(),
                    updatedVal: null
                },
                systemMessagesSource: {
                    initialVal: getSystemMessagesSource(),
                    updatedVal: null
                }
            };
            var counter = 0;
            var siteSettingsCollection = new SettingsCollection();
            var updateContentSettingsInterval = setInterval(function () {
                counter += 1;
                siteSettingsCollection.fetch({
                    success: _ongGtSiteSettingsDeltaSuccess,
                    error: _ongGtSiteSettingsDeltaError
                });

                if (counter == 3) {
                    window.clearInterval(updateContentSettingsInterval);
                }
            }, 30000);

            function _ongGtSiteSettingsDeltaSuccess(collection, response, options) {
                if (contentSettingsToUpdate.cannedResponsesSource) {
                    contentSettingsToUpdate.cannedResponsesSource.updatedVal = collection.get(LE.sessionManager.ACCOUNT_SETTINGS_ID.CANNED_RESPONSES_SOURCE).getValue();
                    if (contentSettingsToUpdate.cannedResponsesSource.updatedVal !== contentSettingsToUpdate.cannedResponsesSource.initialVal) {
                        switchCannedResponsesSource(contentSettingsToUpdate.cannedResponsesSource.updatedVal);
                        delete contentSettingsToUpdate.cannedResponsesSource;
                    }
                }
                if (contentSettingsToUpdate.systemMessagesSource) {
                    contentSettingsToUpdate.systemMessagesSource.updatedVal = collection.get(LE.sessionManager.ACCOUNT_SETTINGS_ID.SYSTEM_MESSAGES_SOURCE).getValue();
                    if (contentSettingsToUpdate.systemMessagesSource.updatedVal !== contentSettingsToUpdate.systemMessagesSource.initialVal) {
                        switchSystemMessagesSource(contentSettingsToUpdate.systemMessagesSource.updatedVal);
                        delete contentSettingsToUpdate.systemMessagesSource;
                    }
                }

                if (!contentSettingsToUpdate.systemMessagesSource && !contentSettingsToUpdate.systemMessagesSource) {
                    window.clearInterval(updateContentSettingsInterval);
                }
            }

            function _ongGtSiteSettingsDeltaError(collection, response, options) {
                LE.logger.error("LEApplication contentSettings updateContentSettings fetch failed");
            }
        };

        var switchCannedResponsesSource = function (val) {
            cannedResponsesSource = val;
        };
        var switchSystemMessagesSource = function (val) {
            systemMessagesSource = val;
        };

        return {
            getCannedResponsesSource: getCannedResponsesSource,
            getSystemMessagesSource: getSystemMessagesSource,
            switchCannedResponsesSource: switchCannedResponsesSource,
            switchSystemMessagesSource: switchSystemMessagesSource,
            updateContentSettings: updateContentSettings
        };

    })();
    return contentSettings;
});
