define(function (require) {
    "use strict";

    var framework = require("mediaCodes/framework.json");
    var campaigns = require("mediaCodes/campaigns.json");
    var connection = require("mediaCodes/connection.json");
    var content = require("mediaCodes/content.json");
    var manager = require("mediaCodes/manager.json");
    var registration = require("mediaCodes/registration.json");
    var reporting = require("mediaCodes/reporting.json");
    var userManagement = require("mediaCodes/userManagement.json");
    var webAgent = require("mediaCodes/webAgent.json");
    var dataModule = require("mediaCodes/dataModule.json");
    var personalization = require("mediaCodes/personalization.json");


    return {
          global: framework
        , campaigns: campaigns
        , connection: connection
        , content: content
        , manager: manager
        , registration: registration
        , reporting: reporting
        , userManagement: userManagement
        , webAgent: webAgent
        , webagent: webAgent
        , dataModule : dataModule
        , personalization : personalization
    };
});
