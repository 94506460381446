define(function (require) {
    "use strict";

    var _ = require("underscore");
    var Marionette = require("marionette");
    var CONST = require("const");
    var Banner = require("ui/banner/Banner");
    var { Logger } = require('vue-infra');
    var LEConfig = require("assets/config/le-env-conf");

    var UserAssistanceController = Marionette.Controller.extend({
        visitorsPersonaVisited: false,

        tagFlowConditions: {
            firstTimeLE_Visit: true,
            firstTimeVisitors_Visit: true,
            needToCloseBanner: false
        },

        initialize: function (options) {
            this.logger = Logger.getLogger("LEFramework");
            this.App = options.application;
            this.vent = options.vent;
            this.translator = options.translator;
            this.sessionManager = options.sessionManager;
            this.isMigrationDoneViewed = false;
            this.isWebAgentReportedVisitors= false;
            this.App.vent.once("webAgent:hasVisitors",function() {
                this.isWebAgentReportedVisitors = true;
                this._addLpTagVarIsTagDeployed(true);
            }, this);
            this.isMigrationDone = this.isMigrationDone();
            this.App.navigator.removeFragment({ replace : true , removeQueryParams : ["migrationDone"]});
            this.setTagFlowBanner(options.isFirstTimeLogin);
            this.setMigrationDoneBanner();
            this.listenTo(LE.context, "change:LEPersonalization.isOpened", this._closeBanners, this);
            var messagingAccount = this.App.sessionManager.getFeatureProperty("Common.Async_Messaging");
            this.messagingAccount = {
                isEnabled: messagingAccount && messagingAccount.value && messagingAccount.value.value
            };
        },

        isMigrationDone: function() {
            return this.App.getQueryStringParameterValue("migrationDone", "") === "true";
        },

        setMigrationDoneBanner: function () {
            if(this.isMigrationDone) {
                this.vent.on("module:active", function () {
                    if (!this.isMigrationDoneViewed) {
                        this.isMigrationDoneViewed = true;
                        this._displayMigrationDoneBanner();
                    }
                }, this);
            }
        },

        setTagFlowBanner: function (isFirstTimeLogin) {
            this._setTagFlowConditions(isFirstTimeLogin);
            if (this.tagFlowConditions.firstTimeLE_Visit) {
                if(!LE.siteSettings.customizationSettings.isHideLeTagPage()) {
                    this.vent.on("module:active", function (options) {
                        if (this.tagFlowConditions.firstTimeVisitors_Visit && this._isSwitchedToVisitor(options)) {
                            this.tagFlowConditions.firstTimeVisitors_Visit = false;
                            this._displayTagFlowBanner();
                        } else if (this.tagFlowConditions.needToCloseBanner) {
                            this._closeTagFlowBanner();
                        }
                    }, this);
                }
            }
            else {
                this.startCheckForVisitorsBanner();
            }
        },
        _closeTagFlowBanner: function () {
            if (this.tagFlowBanner) {
                this.tagFlowBanner.close();
                this.vent.trigger("TagFlowBanner:onClose");
            }
        },
        _closeMigrationDoneBanner: function () {
            if (this.migrationDoneBanner) {
                this.migrationDoneBanner.close();
            }
        },

        _tagFlowBannerActionButtonClick: function () {
            this._closeTagFlowBanner();
            LE.crossModule("manager", LE.mediaCodes.manager.redirects.lptag);
        },

        _migrationDoneBannerActionButtonClick: function () {
            this._closeMigrationDoneBanner();
            this.App.context.set("migrationDone",true);
            this.App.vent.trigger("userManagement:navigateToPeopleList");
        },

        _displayTagFlowBanner: function () {   //1
            this.tagFlowConditions.needToCloseBanner = true;

            this.tagFlowBanner = new Banner({
                callback: _.bind(this._tagFlowBannerActionButtonClick, this),
                container: ".web-agent-wrapper",
                welcomeTitle: this.translator.translate("LEFramework.tagFlowBanner.welcomeTitle"),
                title: this.translator.translate("LEFramework.tagFlowBanner.title"),
                bannerContent: [this.translator.translate("LEFramework.tagFlowBanner.bannerContent.line1"), this.translator.translate("LEFramework.tagFlowBanner.bannerContent.line2")],
                actionBtnText: this.translator.translate("LEFramework.tagFlowBanner.actionBtnText")
            });
            this.tagFlowBanner.render();
        },
        _displayMigrationDoneBanner: function () {
            this.migrationDoneBanner = new Banner({
                callback: _.bind(this._migrationDoneBannerActionButtonClick, this),
                container: ".work-area-wrapper",
                welcomeTitle: this.translator.translate("LEFramework.tagFlowBanner.welcomeTitle"),
                title: this.translator.translate("LEFramework.tagFlowBanner.title"),
                bannerContent: [this.translator.translate("LEFramework.migrationDoneBanner.bannerContent.line1"), this.translator.translate("LEFramework.migrationDoneBanner.bannerContent.line2")],
                actionBtnText: this.translator.translate("LEFramework.migrationDoneBanner.actionBtnText")
            });
            this.migrationDoneBanner.render();
        },
        _isSwitchedToVisitor: function (options) {
            return options && options.moduleName === CONST.MODULES.AGENT;
        },
        _setTagFlowConditions: function (isFirstTimeLogin) {
            this.tagFlowConditions.firstTimeLE_Visit = isFirstTimeLogin;
        },
        startCheckForVisitorsBanner: function () {
            if (this.App.sessionManager.isAuthenticated() && this.App.sessionManager.hasLiveEngage2Feature() && !this.isMigrationDone) {
                this.App.rtDataManager.visitorCount.fetch({success: _.bind(this.setCheckForVisitorsBanner, this)});
            }
        },
        setCheckForVisitorsBanner: function () {
            //if 0 visitors and not a messaging account
            if (!this.App.rtDataManager.visitorCount.getWasVisited() && !this.isWebAgentReportedVisitors &&
                    (!this.messagingAccount.isEnabled) ) {
                var hasLiveEngageTagPrivileges = LE.sessionManager.hasPrivilege([LE.sessionManager.PRIVILEGES.LE_VIEW_CAMPAIGN_MODULE, LE.sessionManager.PRIVILEGES.LE_VIEW_USER_MANAGEMENT_MODULE], true);
                if(hasLiveEngageTagPrivileges && !LE.siteSettings.customizationSettings.isHideLeTagPage()) {
                    this._displayCheckForVisitorsBanner();
                }
                this._addLpTagVarIsTagDeployed(false);
            }
            else{
                this._addLpTagVarIsTagDeployed(true);
            }
        },
        _displayCheckForVisitorsBanner: function () {
            this.checkForVisitorsBanner = new Banner({
                callback: _.bind(this._checkForVisitorsBannerClick, this),
                container: ".work-area-wrapper",
                bannerContent: [this.translator.translate("LEFramework.checkForVisitorsBanner.bannerContent.line1")],
                actionBtnText: this.translator.translate("LEFramework.checkForVisitorsBanner.actionBtnText"),
                permutation: Banner.PERMUTATION.COUNT_DOWN,
                counterText: this.translator.translate("LEFramework.checkForVisitorsBanner.countDown.checkingAgainIn"),
                counterNumber: 11,
                onCounterDone: _.bind(this._checkForVisitors, this),
                spinnerText: this.translator.translate("LEFramework.checkForVisitorsBanner.countDown.checkingForVisitors")
            });
            this.checkForVisitorsBanner.render();
        },
        _checkForVisitorsBannerClick: function () {
            this._closeCheckForVisitorsBanner();
            LE.crossModule("manager", LE.mediaCodes.manager.redirects.lptag);
        },
        _openCountDownSuccessBanner: function () {
            this.countDownSuccessBanner = new Banner({
                container: ".work-area-wrapper",
                bannerContent: [this.translator.translate("LEFramework.bannerCountDownSuccess.bannerContent.line1")],
                permutation: Banner.PERMUTATION.COUNT_DOWN_SUCCESS
            });
            this.countDownSuccessBanner.render();
        },
        _checkForVisitors: function () {
            this.App.rtDataManager.visitorCount.fetch({success: _.bind(this._checkForVisitorsCount, this)});
        },
        _checkForVisitorsCount: function () {
            if (this.App.rtDataManager.visitorCount.getWasVisited() || this.isWebAgentReportedVisitors) {
                this._closeCheckForVisitorsBanner();
                this._openCountDownSuccessBanner();
                this._addLpTagVarIsTagDeployed(true);
            }
        },
        _closeCheckForVisitorsBanner: function () {
            this.checkForVisitorsBanner.close();
        },
        _addLpTagVarIsTagDeployed:function(isTagDeployed){
            this.App.lpTagAddVar([
                {name: "IsTagDeployed", val: isTagDeployed}
            ]);
        },
        _closeBanners:function(model,isOpened){
            if(isOpened){
                if(this.checkForVisitorsBanner){
                    this.checkForVisitorsBanner.close();
                }//tagFlowBanner migrationDoneBanner checkForVisitorsBanner  countDownSuccessBanner
                if(this.migrationDoneBanner){
                    this.migrationDoneBanner.close();
                }
                if(this.countDownSuccessBanner){
                    this.countDownSuccessBanner.close();
                }

            }
        }
    });

    return UserAssistanceController;
});
