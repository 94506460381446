/**
 * Created with IntelliJ IDEA.
 * User: elanh
 * Date: 3/14/13
 * Time: 10:39 AM
 * To change this template use File | Settings | File Templates.
 */

/*
 a BaseModel class which extends the Backbone.Model
 you can pass it a URL in the options in order to set the URL of the model resource
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var _ = require("underscore");
    var DeepModel = require("backbone.deepmodel");
    var LEResourceResolver = require("leResourceResolver");
    var { Logger } = require('vue-infra');
    var es6MiddleUtils = require('src/es6MiddleUtils');
    var SourceQueryParamDecorator = es6MiddleUtils.removeEsModuleDefault(require('../decorators/SourceQueryParamDecorator'));

    var FetchState = {
        NONE:     "none",
        ERROR:    "error",
        SUCCESS:  "success",
        LOADING:  "loading"
    };

    var BaseModel = DeepModel.extend({
        use304Implementation: false, // by default we don't want to use "304 not modified" for models. but we do support it (basically we have one use case - dataUnderAgentManager)
        FetchState: FetchState,
        localAttrs:{},
        // Initialize is an empty function by default. I am override it with
        // initialization logic which allow assignment of URL to model during initialization.
        initialize:function (options) {
            options = options || {};
            this.url = options.url || this.url;
            this.lastFetchSuccess = null;
            this.fetchState =  options.fetchState || FetchState.NONE;
            this._initLogger();
        },

        /**
         * We support resource as an object with url (and version optionally) and resource as a string
         * @return {String} - the api resource urlRoot of the model.
         */
        urlRoot:function () {
            if (this.collection) {
                this.resource = this.resource || this.collection.resource;
            }

            return LEResourceResolver.getUrl.call(this, this.resource, true);
        },

        /**
         * We support resource as an object with url (and version optionally) and resource as a string
         * @return {String} - the api resource url of the model.
         */
        url:function () {
            if (this.collection) {
                this.resource = this.resource || this.collection.resource;
            }

            var resource = LEResourceResolver.extendResourceWithOptions.call(this, this.resource, { url:DeepModel.prototype.url.call(this) });

            var result = LEResourceResolver.getUrl.call(this, resource);
            // If we define a source object on the model it will add the source query parameter to the request URL,
            // and replace it if it already exists in the URL.
            // for example, { appName: 'camp', contextName: 'winStudio' } will become source=ccuiCampWinStudio.
            // Note: if this method is overridden then this implementation should be added if you wish to use it.
            if (this.source) {
              result = SourceQueryParamDecorator.addSourceQueryParam.call(this, result, this.source, true);
            }
            return result;
        },

        // Override 'Backbone.sync' to extend parameters from urlResolver
        sync:function (method, model, options) {
            options = LEResourceResolver.extendOptionsWithResourceOptions.call(this, this.resource, options);
            // If we define a source object on the model it will add the source query parameter to the request URL,
            // and replace it if it already exists in the URL.
            // for example, { appName: 'camp', contextName: 'winStudio' } will become source=ccuiCampWinStudio
            // Note: if this method is overridden then this implementation should be added if you wish to use it.
            if (options.url && this.source) {
                options.url = SourceQueryParamDecorator.addSourceQueryParam.call(this, options.url, this.source, true);
            }

            return Backbone.sync.apply(this, [].slice.apply(arguments));
        },
        fetch: function(options) {
            this._initLogger();
            options = options || {};

            var freshnessTime = _.has(options, "freshnessTime") ? options.freshnessTime : this.freshnessTime;
            if (freshnessTime && this.hasAtLeastOneSuccessfulFetch() && freshnessTime > (new Date().getTime()) - this.getLastFetchSuccess()) {
                this.logger.debug("framework BaseModel is fresh enough returning success" + this._getModeLName(),"BaseModel:fetch");
                options.success(this, {}, options);
                return;
            }



            // Add handler for 404 errors
            var successFunc = options.success;
            var errorFunc = options.error;
            options.success = function(model, response, options) {
                model._setFetchState(FetchState.SUCCESS);
                model.lastFetchSuccess = new Date().getTime();  //todo waiting when we have updated underscore with _.now()
                model.logger.debug("fetchSuccess of " + model._getModeLName(),"BaseModel:fetch");
                if (_.isFunction(model.fetchSuccess)) {
                    model.fetchSuccess(model, response, options);
                }
                if (_.isFunction(successFunc)) {
                    successFunc(model, response, options);
                }
            };
            options.error = function(model, response, options) {
                model.logger.graph({name:"framework:BaseModel:fetchError"});
                model.logger.error("fetchError of " + model._getModeLName(),"BaseModel:fetch",{response: response,options: options});

                model._setFetchState(FetchState.ERROR);

                if (_.isFunction(model.fetchError)) {
                    model.fetchError(model, response, options);
                }

                if (_.isFunction(errorFunc)) {
                    errorFunc(model, response, options);
                }
            };

            this._setFetchState(FetchState.LOADING);


            var shouldWeUse304Implementation = _.has(options, "use304Implementation") ? options.use304Implementation : this.use304Implementation;
            if (shouldWeUse304Implementation) {
                return this._backboneFetchWith304Implemented(options);
            }
            else {
                return DeepModel.prototype.fetch.call(this,options);
            }
        },

        /**
         * Important we are overriding backbone model fetch here to support 304 unmodified
         * This is the exact function used from BB version 1.1.2  ,
         * Any change in backbone should validate this function is still doing the same work needed
         * @param options
         * @returns {*}
         * @private
         */
        _backboneFetchWith304Implemented: function(options) {
            options = options ? _.clone(options) : {};
            if (options.parse === void 0) {
                options.parse = true;
            }
            var model = this;
            var success = options.success;
            options.success = function(resp, statusText, xhr) {
                if (xhr && 304 === xhr.status){
                    //if we received 304 we don't need to do anything
                    if (success) {
                        success(model, resp, options);
                    }
                }
                else {
                    if (!model.set(model.parse(resp, options), options)) {
                        return false;
                    }
                    if (success) {
                        success(model, resp, options);
                    }
                    model.trigger('sync', model, resp, options);
                }
            };

            var error = options.error;
            options.error = function(resp) {
                if (error) {
                    error(model, resp, options);
                }
                model.trigger('error', model, resp, options);
            };
            return this.sync('read', this, options);
        },

        save:function (key, val, options) {
            this._initLogger();
            // Handle both `"key", value` and `{key: value}` -style arguments.
            if (key == null || typeof key === 'object') {
                options = val;
            }

            // Keep the old behaviour of backbone to save compatibility with backbone < 1.0
            // Where invalid callback was invoked if validation failed
            if (options.invalid) {
                this.on("invalid", options.invalid);
            }

            _.forOwn(this.localAttrs, function (value, key) {
                this.unset.call(this, key, { silent:true, saving:true });
            });

            var errorFunc = options.error;
            options.error = function(model, response, options) {
                var calcName = model._getModeLName();
                var calcStatus = (options && options.xhr && options.xhr.status)?options.xhr.status:"";

                model.logger.warn("framework:BaseModel:saveError:" + calcName + ":" + calcStatus,"BaseModel:save",{options: options, response: response});

                if (_.isFunction(errorFunc)) {
                    errorFunc(model, response, options);
                }
            };

            var result = DeepModel.prototype.save.call(this, key, val, options);

            this.set.call(this, this.localAttrs, { silent:true, saving:true });

            if (options.invalid) {
                this.off("invalid", options.invalid);
            }

            return result;
        },

        isValid:function () {
            var validate = this.validate(this.attributes);

            return !Boolean(validate);
        },

        set:function (key, val, options) {
            if (null == key) {
                return this;
            }

            // Handle both `"key", value` and `{key: value}` -style arguments.
            if (typeof key === 'object') {
                options = val;
            }

            if (options && options.local && !options.saving) {
                if (options.unset) {
                    var attr = this.localAttrs[key];

                    if (attr) {
                        delete this.localAttrs[key];
                    }
                } else {
                    if (typeof key === 'object') {
                        _.assign(this.localAttrs, key);
                    } else {
                        this.localAttrs[key] = val;
                    }
                }
            }

            return DeepModel.prototype.set.call(this, key, val, options);
        },
        getFetchState: function(){
            return this.fetchState;
        },

        getLastFetchSuccess: function(){
            return this.lastFetchSuccess;
        },
        setLastFetchSuccess: function(lastFetchSuccess) {
            this.lastFetchSuccess = lastFetchSuccess;
        },
        hasAtLeastOneSuccessfulFetch: function() {
            return (!_.isNull(this.getLastFetchSuccess()));
        },
        isLastFetchReturnedError: function() {
            return  this.getFetchState() === FetchState.ERROR;
        },
        isLastFetchReturnedSuccess: function() {
            return  this.getFetchState() === FetchState.SUCCESS;
        },

        /**
         * Return true if the model is fresh enough by the fresh time you enter
         * @param freshnessTime  - amount of time in ms
         * @returns {boolean}
         */
        isFreshlyFetched: function(freshnessTime) {
            if (!_.isNull(this.lastFetchSuccess) && freshnessTime > (new Date().getTime()) - this.lastFetchSuccess) {
                return true;
            }
            else{
                return false;
            }

        },
        /**
         * When using unset on deepmodel collection the elements in it are undefined
         * if you want to remove the element from the array to avoid [1,2,"undefined",4,5]
         * activate this funcy func
         * @param typedElements
         */
        cleanEmptyElements:function (typedElements) {
            if (null != typedElements && 0 < typedElements.length) {
                for (var i = 0; i < typedElements.length; i++) {
                    if (_.isUndefined(typedElements[i])) {
                        typedElements.splice(i, 1);
                        i--;
                    }
                }
            }
        },

        //---------------------------------------------------------------
        // destroy
        //---------------------------------------------------------------
        destroy: function(options) {
            this._initLogger();
            options = options || {};

            var errorFunc = options.error;
            options.error = function(model, response, options) {
                var calcName = model._getModeLName();
                var calcStatus = (options && options.xhr && options.xhr.status)?options.xhr.status:"";

                model.logger.warn("framework:BaseModel:deleteError:" + calcName + ":" + calcStatus,"BaseModel:destroy",{options: options, response: response});

                if (_.isFunction(errorFunc)) {
                    errorFunc(model, response, options);
                }
            };

            var result = DeepModel.prototype.destroy.call(this,options);

            return result;

        },

        //---------------------------------------------------------------
        // duplicate
        //---------------------------------------------------------------

        duplicate: function(sourceModel, options, collection) {
            this._initLogger();
            var originalResource = this.resource;
            var meta = this._parseResourceUrl(sourceModel.resource.url);

            var duplicateResource = LEResourceResolver.getUrl(LEResourceResolver.apiResources.AccountConfig.Duplicate);
            this.resource = duplicateResource;

            var success = options.success;
            var error = options.error;

            var newSuccess = function(model, response, options) {
                this.resource = originalResource;
                if (success) {
                    success.apply(this, arguments);
                }
                collection.add(model);
            };

            var newError = function(model, response, options) {

                var calcName = model._getModeLName();
                var calcStatus = (options && options.xhr && options.xhr.status)?options.xhr.status:"";

                model.logger.warn({name:"framework:BaseModel:dupError:" + calcName + ":" + calcStatus });

                this.resource = originalResource;

                if (error) {
                    error.apply(this, arguments);
                }
            };

            var data = _.extend(options.data, {
                "accountId": LE.sessionManager.getAccountId(),
                "appName": meta.appId,
                "objectType": meta.objectType,
                "objectId": sourceModel.id.toString(),
                "appVersion": parseFloat(originalResource.version)
            });

            var _options = _.extend(options,{
                data: data,
                type: "POST",
                contentType: "application/json",
                success:_.bind(newSuccess, this),
                error:_.bind(newError, this)
            });

            this.fetch(_options);
        },

        //---------------------------------------------------------------

        _parseResourceUrl: function(url) {
            var urlFragments = url.split("/");
            var size = urlFragments.length;
            if(size < 2) {
                throw "duplicate; >> _parseResourceUrl >> Invalid resource url.";
            }

            return {
                objectType: urlFragments[size-1],
                appId: urlFragments[size-2]
            };
        },
        _setFetchState: function(state) {
            this.fetchState = state;
            this.trigger("fetch:" + state);
        },
        _initLogger: function(){
            if (!this.logger) {
                this.logger = Logger.getLogger("LEFramework");
            }
        },
        _getModeLName: function() {
            var nameResult = "";

            if (this.name) {
                nameResult = this.name;
            } else {
                var resource = _.result(this, "resource");
                if (_.isString(resource)) {
                    nameResult = resource;
                }
                else if (_.isObject(resource) && resource.url) {
                    nameResult = resource.url;
                }
                nameResult = nameResult.replace(/\//g,"-");
            }

            return nameResult;
        }
    });

    BaseModel.FetchState = FetchState;

    return BaseModel;
});
