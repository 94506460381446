/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/26/14
 * Time: 3:12 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var _ = require("underscore");
    var BaseCollection = require("collections/BaseCollection");
    var ApiResources = require("assets/data/apiResources.json");
    var LEResourceResolver = require("leResourceResolver");
    var { Logger } = require('vue-infra');

    var DependencyModel = require("src/components/dependency-service/models/DependencyModel");

    /**
     *  The dependency collection is a list of dependency models which are fetched from the server. Since the server
     *  returns a tree data structure (see AC Meta Data API), the parse method of the collection flatten the tree and
     *  produce a new data structure which is more suitable for the client side usage.
     *
     *  The current logic retrieves all leaf nodes as dependencies. For example, if the dependency tree that is returned
     *  by the server is as follows:
     *
     *  time-on-location-A: {
     *      visitor-behavior-X : {
     *          engagement-Y : {
     *            campaign-Z
     *          },
     *          engagement-U : {
     *            campaign-V
     *          },
     *      }
     *  },
     *  engagement-B: {
     *      campaign-C
     *  },
     *  time-on-location-P: {
     *      visitor-behavior-S
     *  }
     *
     *  Then the returned collection will be:
     *  [ campaign-Z, campaign-V, campaign-C, visitor-behavior-S ]
     *
     *  Collection items are always unique (no duplicates).
     *
     */
    var DEPENDENCY_TYPE = {
        "Model": "model",
        "Collection": "collection"
    };

    var DependencyCollection = BaseCollection.extend({
        apiResource: LEResourceResolver.apiResources.AccountConfig.Dependency,
        resource: function() {
            var meta = this._parseResourceUrl(this.dependeeData.resource.url);
            var params = {
                appId: meta.appId,
                objectType: meta.objectType
            };
            return LEResourceResolver.extendResourceWithOptions.call(this, this.apiResource, params);
        },
        model: DependencyModel,

        /**
         * @param options: {
         *  dependeeModel: BaseModel
         * }
         */
        initialize: function(models, options) {
            this.logger = Logger.getLogger("CoreComponents");

            this._validateOptions(options);

            this.dependeeData = options.dependeeData;
            this.dependencyType = this._getDependencyType(this.dependeeData);
            this.objectIds = this._getObjectIds[this.dependencyType](this.dependeeData);
            BaseCollection.prototype.initialize.call(this, models, options);
        },

        // -------------------------------------------------------------------------------------------------------------

        /**
         * Override Backbone.Collection.fetch to use POST method and pass objectIds
         * @param response
         */
        fetch: function(options) {
            var _options = _.extend(options, {
                data: {
                    "objectIds": this.objectIds
                },
                type: "POST",
                contentType: "application/json"
            });
            BaseCollection.prototype.fetch.call(this, _options);
        },

        // -------------------------------------------------------------------------------------------------------------

        /**
         * Parse the response dependency tree and output an array of dependency objects.
         *
         * Override Backbone.Collection.parse
         * @param response
         */
        parse: function(response, options) {
            var checkDependenciesLeaves = _.has(options, "checkDependenciesLeaves") ? options.checkDependenciesLeaves : true;

            if (checkDependenciesLeaves) {
                return this._getTreeLeaves(response);
            }
            else {
                return this._getTreeFirstLevel(response);
            }

        },

        _getDependencyType: function(dependeeData) {
            var type;
            if (dependeeData instanceof Backbone.Model) {
                type = DEPENDENCY_TYPE.Model;
            }
            else if (dependeeData instanceof Backbone.Collection) {
                type = DEPENDENCY_TYPE.Collection;
            }
            return type;
        },

        _getObjectIds: {
            "model": function(model) {
                return [String(model.id)];
            },
            "collection": function(collection){
                var idsArr;
                idsArr = _.map(collection.models, function(model) {
                    return String(model.id);
                });
                return idsArr;
            }
        },

        // -------------------------------------------------------------------------------------------------------------

        /**
         * Recursively iterate through the tree and retrieve leave nodes.
         *
         * @param dependencyArray
         * @returns {Array}
         * @private
         */
        _getTreeLeavesModel: function(dependencyArray) {
            var _this = this;
            var dependencies = [];

            _.each(dependencyArray, function(dependencyObject) {
                if(dependencyObject.dependencies) { // node has dependencies
                    dependencies.push.apply(dependencies, _this._getTreeLeavesModel(dependencyObject.dependencies));
                } else { // node has no dependencies
                    // Handle parent dependency (see AC Meta Data API)
                    if(dependencyObject.parentType && dependencyObject.parentId) {
                        // Push parent node to array
                        dependencies.push({
                            id: dependencyObject.parentId,
                            type: dependencyObject.parentType
                        });
                    } else { // is a true leaf - no parents and no dependencies
                        // Push item to array
                        dependencies.push({
                            id: dependencyObject.id,
                            type: dependencyObject.type
                        });
                    }
                }
            });

            // remove duplicate dependencies
            return this._distinct(dependencies);
        },

        _getTreeFirstLevelModel: function(dependencyArray) {
            var dependencies = [];

            _.each(dependencyArray, function(dependencyObject) {
                _.each(dependencyObject.dependencies, function(depObj) {
                    dependencies.push({
                        id: depObj.id,
                        type: depObj.type
                    });
                });
            });

            // remove duplicate dependencies
            return this._distinct(dependencies);
        },

        /*
        We create an array of dependecy models that every object's dependencies use the _getTreeLeavesModel method.
        First we separate the dependencies to dependee models and then for each model we call the _getTreeLeavesModel method.
        This is used by Categories dependency and return:
        [
            {
                id: "Category_ID1",
                type: "ACCategoryObject",
                dependencies: [
                    {
                        id: "Campaign_ID",
                        type: "LECampaignObject"
                    },
                    {
                        id: "Canned_ID",
                        type: "ACCannedResponse"
                    },
                ]
            },
            {
                id: "Category_ID2",
                type: "ACCategoryObject",
                dependencies: [
                    {
                        id: "Campaign_ID",
                        type: "LECampaignObject"
                    },
                    {
                        id: "Canned_ID",
                        type: "ACCannedResponse"
                    },
                ]
            }
        ]
         */
        _getTreeLeaves: function(response) {
            var that = this;
            var dependencyCollectionObj = {};
            var dependenciesArr = [];

            _.each(response, function(dependeeObj) {
                if (_.has(dependencyCollectionObj, dependeeObj.id)) {
                    dependencyCollectionObj[dependeeObj.id].push(dependeeObj);
                }
                else {
                    dependencyCollectionObj[dependeeObj.id] = [ dependeeObj ];
                }
            });

            _.each(dependencyCollectionObj, function(dependencies, key) {
                dependenciesArr.push({
                    id: key,
                    type: dependencies[0].type,
                    dependencies: new BaseCollection(_.bind(that._getTreeLeavesModel, that)(dependencies))
                });
            });

            return dependenciesArr;
        },

        _getTreeFirstLevel: function(response) {
            var that = this;
            var dependencyCollectionObj = {};
            var dependenciesArr = [];

            _.each(response, function(dependeeObj) {
                if (_.has(dependencyCollectionObj, dependeeObj.id)) {
                    dependencyCollectionObj[dependeeObj.id].push(dependeeObj);
                }
                else {
                    dependencyCollectionObj[dependeeObj.id] = [ dependeeObj ];
                }
            });

            _.each(dependencyCollectionObj, function(dependencies, key) {
                dependenciesArr.push({
                    id: key,
                    type: dependencies[0].type,
                    dependencies: new BaseCollection(_.bind(that._getTreeFirstLevelModel, that)(dependencies))
                });
            });

            return dependenciesArr;
        },

        // -------------------------------------------------------------------------------------------------------------

        _validateOptions: function(options) {
            if(!_.has(options, "dependeeData")) {
                this.logger.error("Invalid options in DependencyCollection: dependeeData is mandatory", "DependencyCollection:_validateOptions");
                throw "DependencyCollection >> initialize >> _validateOptions >> dependeeData is mandatory.";
            }

            if(_.isUndefined(options.dependeeData.resource)) {
                this.logger.error("Invalid options in DependencyCollection: dependeeData must have a valid resource", "DependencyCollection:_validateOptions");
                throw "DependencyCollection >> initialize >> _validateOptions >> dependeeData must have a valid resource.";
            }

            if(_.isUndefined(options.dependeeData.resource.url)) {
                this.logger.error("Invalid options in DependencyCollection: dependeeData.resource must have a valid url", "DependencyCollection:_validateOptions");
                throw "DependencyCollection >> initialize >> _validateOptions >> dependeeData.resource must have a valid url.";
            }
        },

        // -------------------------------------------------------------------------------------------------------------

        _parseResourceUrl: function(url) {
            var urlFragments = url.split("/");
            var size = urlFragments.length;
            if(size < 2) {
                this.logger.error("Invalid resource url in DependencyCollection", "DependencyCollection:_parseResourceUrl");
                throw "DependencyCollection >> initialize >> _parseResourceUrl >> Invalid resource url.";
            }

            return {
                objectType: urlFragments[size-1],
                appId: urlFragments[size-2]
            };
        },

        // -------------------------------------------------------------------------------------------------------------

        _distinct: function(array) {
            return _.uniq(array, false, function(item) {
                return item.type + "/" + item.id;
            });
        }
    });

    return DependencyCollection;
});
