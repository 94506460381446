/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:36 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var CONST = require("const");
    var Language = require("codeTables/language.json");
  var _ = require('underscore');

    var languageSettings = (function () {

        var accountLocale = null;

        var switchLocale = function (locale) {
            if (locale) {
                accountLocale = locale;
                LE.localeResolver.setLocale(locale);
                LE.context.set("user.language", locale);
            }
            else {
                LE.context.set("user.language", LE.localeResolver.getLocale());
            }

            restart();
        };

        var restart = function () {
            if (LE && CONST.LE_STATUS.STARTED === LE.status) {
                LE.isSilentLeave = true;
                LE.navigator.reload();
            }
        };

        var getLanguageList = function () {
            return Language.languageCodes;
        };

        var getLanguageListTranslated = function () {
            if (_.isUndefined(Language.languageCodesTranslated)) {
                translateLanguageCodes();
            }

            return Language.languageCodesTranslated;
        };

        var translateLanguageCodes = function () {
            Language.languageCodesTranslated = [];

            _.each(Language.languageCodes, function (value, key) {
                Language.languageCodesTranslated.push({"key": key, "value": LE.translator.translate("LEFramework." + key)});
            });

            Language.languageCodesTranslated=_.sortBy(Language.languageCodesTranslated, function(obj){ return obj.value; });

        };

        var getAccountLocale = function () {
            if (accountLocale == null) {
                accountLocale = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.LOCALE,Language.defaultLanguage);

                //backward compatibility for moving to new language codes ( korean and Japanese)
                if (accountLocale === "ja-JA") {
                    accountLocale =  CONST.LANGUAGE["ja-JP"];
                } else if (accountLocale === "ko-KO") {
                    accountLocale =  CONST.LANGUAGE["ko-KR"];
                }

            }
            return accountLocale;
        };

        var getExternalReportingLocale = function() {
            var accountLocale = getAccountLocale();
            var MSTRLocale = accountLocale;

            // since Russian is not supported in report builder and MSTR we need to return English
            if (accountLocale === CONST.LANGUAGE["ru-RU"]) {
                MSTRLocale = CONST.LANGUAGE["en-US"];
            }

            return MSTRLocale;
        };

        return {
            switchLocale: switchLocale,
            getLanguageList: getLanguageList,
            getLanguageListTranslated: getLanguageListTranslated,
            getAccountLocale: getAccountLocale,
            getExternalReportingLocale: getExternalReportingLocale
        };
    })();
    return languageSettings;
});
