<template>
  <div
    v-if="item.show"
    class="tray-menu-item-container"
  >
    <div
      v-if="!item.popoverOptions"
      :title="item.tooltip"
      :class="iconClass"
    >
      {{ item.text }}
    </div>
    <le-bubble
      v-else
      v-bind="item.popoverOptions"
      default-close-button
    >
      <template #target>
        <div
          :title="item.tooltip"
          :class="iconClass"
        >
          {{ item.text }}
        </div>
      </template>
      <template #content>
        <div class="tray-menu-item-popover">
          <div>
            <span class="tray-menu-item-description">{{ item.popoverOptions.innerText }}</span>
          </div>
          <div class="tray-menu-button-wrapper">
            <le-button
              v-if="item.popoverOptions.buttonCaption"
              :label="item.popoverOptions.buttonCaption"
              :class="item.popoverOptions.buttonClass"
              @click="item.popoverOptions.buttonCallback"
            />
          </div>
        </div>
      </template>
    </le-bubble>
  </div>
</template>

<script>
import CONST from 'const';
import { leBubble, leButton } from '@liveperson/lp-vue-components';

export default {
  name: 'TrayMenuItem',
  components: {
    leBubble,
    leButton,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    iconClass() {
      return {
        'tray-menu-item': this.item.type !== CONST.TRAY_MENU_ITEMS_TYPE.TEXT,
        'small': this.item.type !== CONST.TRAY_MENU_ITEMS_TYPE.TEXT,
        'tray-menu-item-text': this.item.type === CONST.TRAY_MENU_ITEMS_TYPE.TEXT,
        [this.item.iconClass]: !!this.item.iconClass,
      };
    },
  },
};
</script>

<style scoped>
  .tray-menu-item-popover {
    color: #fff;
  }

  .tray-menu-item-description {
    color: #dcdcdc;
    word-break: break-word;
    word-wrap: break-word;
  }

  .tray-menu-button-wrapper {
    margin: 5px 0 0 0;
    display: inline-block;
    text-align: center;
    width: 100%;
  }

</style>
