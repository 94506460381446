define(function (require) {
    "use strict";

    var agentOutcomeSettings = (function () {
        var currentAgentOutcome;

        function getAgentOutcome() {
            if (!currentAgentOutcome) {
                currentAgentOutcome = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.AGENT_OUTCOME, {});
            }

            return currentAgentOutcome;
        }

        function setAgentOutcome(agentOutcome) {
            currentAgentOutcome = agentOutcome;

            LE.vent.trigger("siteSettings:setAgentOutcome", agentOutcome);
        }

        return {
            getAgentOutcome: getAgentOutcome,
            setAgentOutcome: setAgentOutcome
        };
    })();

    return agentOutcomeSettings;
});
