define(function (require) {

    var lpUtils = {};

    var _ = require('underscore');


    /**
     * Converting array of:
     * [{$:'value', @name:'parameter'}]
     * into:
     * {parameter: value} json object.
     * @param {Object|Array} value
     * @return {*}
     */
    lpUtils.nameValuePairToJSON = function (value) {
        if (!value) {
            return {};
        }
        var json = {};
        var values = _.isArray(value) ? value : [value];
        values.forEach(function(valueObject){
            var newValue = valueObject.$;
            var propName = valueObject["@name"] || valueObject.name;

            if(!_.isUndefined(propName)) {
                json[propName] = newValue;
            }
        });

        return !_.isEmpty(json) ? json : value;
    };

    lpUtils.getSortFunctionWithDirection = function(property,direction) {
        var d = direction ? direction : 1;
        var compareFn = lpUtils.getSortFunction(property);
        return function(a,b){
            return d * compareFn(a,b);
        };
    };

    /**
     * sorting strategy func. (for Array.sort)
     * if a property is given then complex elements will be sorted according to the given property
     * @param property
     * @return {Function}
     */
    lpUtils.getSortFunction = function(property){
        return function(element1, element2) {
            var a, b, a1, b1, i= 0, minElementLength, numbersAndNonNumbersRegEx =  /(\d+)|(\D+)/g, numbersRegEx=  /\d/;

            // undefined compare - every define value is bigger then undefined.
            if(property &&  element1.hasOwnProperty(property) && !element2.hasOwnProperty(property)){ return  1; }
            if(property && !element1.hasOwnProperty(property) &&  element2.hasOwnProperty(property)){ return -1; }

            if (property && element1.hasOwnProperty(property) && element2.hasOwnProperty(property)) {
                element1 = element1[property];
                element2 = element2[property];
            }
            //both elements are numbers or string numbers
            if(isFinite(element1) && isFinite(element2)){
                return element1 - element2;
            }

            //non-case sensitive copies for both elements
            a = String(element1).toLowerCase();
            b = String(element2).toLowerCase();

            if( a === b){
                return 0;
            }else if(!(numbersRegEx.test(a) && numbersRegEx.test(b))){ //both elements don't contain numbers.
                return a > b ? 1 : -1;
            }

            //convert elements to arrays
            a = a.match(numbersAndNonNumbersRegEx);
            b = b.match(numbersAndNonNumbersRegEx);
            minElementLength = a.length > b.length? b.length: a.length;

            while(i < minElementLength){
                a1= a[i];
                b1= b[i++];
                if(a1!== b1){ //found char-wise difference
                    if(isFinite(a1) && isFinite(b1)){ //both chars are digits
                        if(a1.charAt(0)=== "0"){
                            a1= "." + a1;
                        } //convert to float if starts with zero
                        if(b1.charAt(0)=== "0"){
                            b1= "." + b1;
                        }
                        return a1 - b1;
                    }
                    else{
                        return a1 > b1 ? 1 : -1;
                    }

                }
            }
            return a.length - b.length; //1 element is contained in the other => container is bigger
        };
    };

    lpUtils.encodeXML = function (str) {
      return (str || '')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/'/g, '&apos;')
        .replace(/"/g, '&quot;');
    };

    lpUtils.decodeXML = function (str) {
      return (str || '')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&apos;/g, '\'')
        .replace(/&quot;/g, '"');
    };

    lpUtils.hasHtml = function(text){
        var htmlRegex = /(<([^>]+)>)/ig;
        return htmlRegex.test(text);
    };

    lpUtils.escapeMap = { '"': '&quot;', '&': '&amp;', '<': '&lt;', '>': '&gt;', "'": '&apos;', '$': '&dollar;' };

    lpUtils.escapeHTML = function(text){
        if(typeof text !== 'undefined'){
            return ('' + text).replace(/[\"\'$&<>]/g, function (a) { return lpUtils.escapeMap[a]; });
        }else{
            return text;
        }

    };

    /**
     * Decode an HTML string - using a textarea element to prevent XSS.
     * @param htmlString
     * @returns {String}
     */
    lpUtils.decodeHTML =  function decodeHtml(htmlString) {
        var txt = document.createElement("textarea");
        txt.innerHTML = htmlString;
        return txt.value;
    };

    /**
     * Convert an HTML into plain text - removing all of the HTML tags.
     * Use this if you need to convert HTML into plain string using a string manipulation instead of Element.innerText to
     * prevent any XSS injection into the document.
     * @param htmlString
     * @returns {*}
     */
    lpUtils.stripHTML =  function stripHTML(htmlString){
        var regex = /(<([^>]+)>)/ig
            ,   body = htmlString
            ,   result = body.replace(regex, "");
        return result;
    };


    lpUtils.getParticipantPath = function(modelController, visitorId) {
        var validPaths = ["", "myEngagements.", "visitorList.data."];
        for (var i = 0 ; i < validPaths.length ; i++) {
            if (modelController.getModelParam("participants." + validPaths[i] + visitorId)) {
                return validPaths[i];
            }
        }
    };

    lpUtils.capitalize = function (text) {
        return text[0].toUpperCase() + text.substring(1);
    };

    lpUtils.createDate = function(dateObj, type) {
    if (!dateObj) {
        return;
    }
        var result = new Date(dateObj);
        if (isNaN(result)) { // this check is for IE8 & Safari that fails to create a Date object from the date string that comes from the server.
            result = new Date();
            var match = "";
            switch (type) {
                case lpUtils.FORMAT_TYPES.ENGAGEMENT_WINDOW :
                    match = dateObj.match(/(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+).*([+-])(\d+):(\d+)/);
                    if (match && match.length === 10) {
                        result.setUTCFullYear(parseInt(match[1], 10));
                        result.setUTCMonth(parseInt(match[2], 10) - 1);
                        result.setUTCDate(parseInt(match[3], 10));
                        result.setUTCHours(parseInt(match[4], 10) - parseInt(match[7] + "1", 10) * parseInt(match[8], 10));
                        result.setUTCMinutes(parseInt(match[5], 10) - parseInt(match[7] + "1", 10) * parseInt(match[9], 10));
                        result.setUTCSeconds(parseInt(match[6], 10));
                    } else {
                        LE.logger.error("could not parse Date object from server", "createDate("+type+")");
                        return;
                    }
                    break;
                case lpUtils.FORMAT_TYPES.CHAT_HISTORY :
                    match = dateObj.match(/(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)(.*)([+-])(\d+):(\d+)/);
                    if (match && match.length == 11) {
                        result.setUTCFullYear(parseInt(match[1], 10));
                        result.setUTCMonth(parseInt(match[2], 10) - 1);
                        result.setUTCDate(parseInt(match[3], 10));
                        result.setUTCHours(parseInt(match[4], 10) - parseInt(match[8] + "1", 10) * parseInt(match[9], 10));
                        result.setUTCMinutes(parseInt(match[5], 10) - parseInt(match[8] + "1", 10) * parseInt(match[10], 10));
                        result.setUTCSeconds(parseInt(match[6], 10));
                    } else {
                        LE.logger.error("could not parse Date object from server", "createDate("+type+")");
                        return;
                    }
                    break;
            }
        }
        return result;
    };

    lpUtils.formatDateAsString = function(date,dateOnly) {

        if (!date || (date.getTime && isNaN(date.getTime()))) {
            LE.logger.warn("No date object of invalid date was passed to formatTime", "formatDateAsString");
            return "";
        }
        if (date.constructor !== "Date") {
            date = lpUtils.createDate(date, lpUtils.FORMAT_TYPES.ENGAGEMENT_WINDOW);
        }
        var toReturn = lpUtils.formatParameterDateAsString({year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()});
        if (dateOnly){
            return toReturn;
        }else {
            var hoursParsed = (date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
        hoursParsed = (hoursParsed === 0) ? 12 : hoursParsed;
            var minutesParsed = date.getMinutes();
            minutesParsed = minutesParsed < 10 ? "0" + minutesParsed  : minutesParsed;
            var ampm =  (date.getHours() > 11 ? window.LE.translator.translate("LEReporting.PM") : window.LE.translator.translate("LEReporting.AM"));
            return toReturn + " - " + hoursParsed + ":" + minutesParsed + ampm;
        }
    };

    /**
     * Merge two object's into one object
     * @param targetObj - the target object to merge into
     * @param mergeData - the object to copy data from
     * @returns {*} - a union of the two objects.
     */
    lpUtils.mergeObjects = function(targetObj, mergeData){
        for (var key in mergeData) {
            if (mergeData.hasOwnProperty(key)) {
                targetObj[key] = mergeData[key];
            }
        }
        return targetObj;
    };

    /**
     * Turns a year/month/day object into a date string.
     * @param dateObj Object. Contains 3 numeric fields: year, month, day.
     * @returns String of the date.
     */
    lpUtils.formatParameterDateAsString = function (dateObj) {
        if (!dateObj) {
            return "";
        }
        var toReturn = "";
        var hasDay = typeof dateObj.day === 'number' && dateObj.day >= 1 && dateObj.day <= 31;
        var hasMonth = typeof dateObj.month === 'number' && dateObj.month >= 1 && dateObj.month <= 12;
        var hasYear = typeof dateObj.year === 'number' && dateObj.year >= 0;
        // We only add the day if we have the day and the month (we don't want the day on its own, or just with the year)
        if (hasDay && hasMonth) {
            var dayString = (dateObj.day < 10 ? "0" + dateObj.day.toString(10) : dateObj.day);
            toReturn += dayString;
        }
        // We add the month if we have the month and either the day or the year (we don't want the month on its own)
        if (hasMonth && (hasDay || hasYear)) {
            if (toReturn.length > 0) {
                toReturn += ' ';
            }
            var monthIndexArr = [1,2,3,4,5,6,7,8,9,10,11,12];
            var monthNames = monthIndexArr.map(function(num) {
                return window.LE.translator.translate("LEUIComponents.datePickerComponent.months."+num+".short")+".";
            });
            //var monthNames = [ "Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec." ];
            var monthName = monthNames[parseInt(dateObj.month - 1, 10)];
            toReturn += monthName;
        }
        // We add the year if it exists (we allow the year on its own)
        if (hasYear) {
            if (toReturn.length > 0) {
                toReturn += ' ';
            }
            toReturn += dateObj.year;
        }
        return toReturn;
    };

    lpUtils.formatDateAsShortTime = function (dateObj) {
        if (!dateObj) {
            LE.logger.error("No date object was passed to formatTime", "formatDateAsShortTime");
            return "";
        }
        if (dateObj.constructor !== "Date") {
            dateObj = lpUtils.createDate(dateObj, lpUtils.FORMAT_TYPES.ENGAGEMENT_WINDOW);
        }
        var hoursParsed = (dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours());
        hoursParsed = (hoursParsed === 0) ? 12 : hoursParsed;var minutesParsed = dateObj.getMinutes();
        minutesParsed = minutesParsed < 10 ? "0" + minutesParsed  : minutesParsed;
        var ampm =  (dateObj.getHours() > 11 ? "pm" : "am");

        return hoursParsed + ":" + minutesParsed + ampm;
    };

    lpUtils.FORMAT_TYPES = {
        ENGAGEMENT_WINDOW : "engagementWindow",
        CHAT_HISTORY : "chatHistory"
    };

    lpUtils.getKeyCode = function(e){
        e = e || event;
        return e.keyCode || e.which;
    };

    lpUtils.isDescendant = function(parent, child) {
        if (!parent || !child) {
            return false;
        }
        var node = child.parentNode;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    };

    /**
     * Make sure the element got focus before calling this method (important mainly for IE)
     * @param el
     * @returns {{start: number, end: number}}
     */
    lpUtils.getInputSelection = function (el) {
        var start = 0, end = 0, normalizedValue, range,
            textInputRange, len, endRange;

        if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
            start = el.selectionStart;
            end = el.selectionEnd;
        } else {
            range = document.selection.createRange();

            if (range && range.parentElement() == el) {
                len = el.value.length;
                normalizedValue = el.value.replace(/\r\n/g, "\n");

                // Create a working TextRange that lives only in the input
                textInputRange = el.createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                // Check if the start and end of the selection are at the very end
                // of the input, since moveStart/moveEnd doesn't return what we want
                // in those cases
                endRange = el.createTextRange();
                endRange.collapse(false);

                if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                    start = end = len;
                } else {
                    start = -textInputRange.moveStart("character", -len);
                    start += normalizedValue.slice(0, start).split("\n").length - 1;

                    if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                        end = len;
                    } else {
                        end = -textInputRange.moveEnd("character", -len);
                        end += normalizedValue.slice(0, end).split("\n").length - 1;
                    }
                }
            }
        }

        return {
            start: start,
            end: end
        };
    };

    /**
     * Make sure the element got focus before calling this method (important mainly for IE)
     * @param el
     * @returns {{start: number, end: number}}
     */
    lpUtils.setInputSelection = function (el, start, end) {
        if (arguments.length < 3) {
            end = start;
        }
        if ("selectionStart" in el) {
            el.selectionStart = start;
            el.selectionEnd = end;
        } else if (el.createTextRange) { // IE
            var rng = el.createTextRange();
            rng.collapse(true);
            rng.moveStart("character", start);
            rng.moveEnd("character", end - start);
            rng.select();
        }
    };

    lpUtils.loadJS = function(fileName, onLoadCallback, onErrorCallback) {

        var fileRef = document.createElement('script');
        fileRef.setAttribute("type", "text/javascript");
        fileRef.async = true;
        if (onLoadCallback) {
            fileRef.onload = onLoadCallback;
        }
        if (onErrorCallback) {
            fileRef.onerror = onErrorCallback;
        }
        fileRef.setAttribute("src", '' + fileName);
        document.getElementsByTagName("head")[0].appendChild(fileRef);
    };

    lpUtils.logAccountAndLocaleTimeZone = function(accountNumber) {
        var date = new Date();
        var timeZone = date.getTimezoneOffset();
        var hours;
        var minutes;
        var sign = '-';

        if (timeZone <= 0) {
            sign = '+';
            timeZone *= (-1);
        }

        hours = parseInt(timeZone / 60.0, 10);
        hours = (hours < 10 ? "0" : "") + hours; //2 digits format

        minutes = timeZone %60;
        minutes = (minutes < 10 ? "0" : "") + minutes; //2 digits format

        timeZone = sign + hours + ":" + minutes;
        LE.logger.info("Account number " + accountNumber + " local time zone: " + timeZone + " GMT", "logAccountAndLocaleTimeZone");
    };

    lpUtils.formatTimeFromSeconds = function(time, optionalHours){
        var hours   = Math.floor(time / 3600);
        var minutes = Math.floor((time - (hours * 3600)) / 60);
        var seconds = time - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}

        var timeFragments = [minutes, seconds];

        if (!(optionalHours && hours === '00')){
            timeFragments.unshift(hours);
        }

        return timeFragments.join(':');
    };

//show minutes and hours in format - XX:YY
    lpUtils.formatHoursAndMinutesFromMilliSeconds = function(timeInMilliSeconds) {
        if((!timeInMilliSeconds) && (timeInMilliSeconds !== 0)){
            return;
        }
        timeInMilliSeconds = Number(timeInMilliSeconds);
        if (typeof timeInMilliSeconds === 'number') {
            if(timeInMilliSeconds < 60000) { //if less than a minute show 1 minute
                timeInMilliSeconds += 60000;
            }
            var HoursMinutesSeconds = lpUtils.formatTimeFromSeconds(Math.round( timeInMilliSeconds / 1000));
            return HoursMinutesSeconds.slice(0, HoursMinutesSeconds.lastIndexOf(':'));
        }
        return;
    };

    lpUtils.getTimeBreakdownFromMilliSeconds = function(time) {
        var millisecondsIn = [];
        millisecondsIn.unshift({name: 'seconds', value: 1000});
        millisecondsIn.unshift({name: 'minutes', value: millisecondsIn[0].value * 60});
        millisecondsIn.unshift({name: 'hours', value: millisecondsIn[0].value * 60});
        millisecondsIn.unshift({name: 'days', value: millisecondsIn[0].value * 24});
        var remainder = time;
        if (typeof remainder !== 'number') {
            remainder = 0;
        }
        var breakdown = {};
        for(var index = 0; index < millisecondsIn.length; index++) {
            breakdown[millisecondsIn[index].name] = remainder / millisecondsIn[index].value;
            remainder = remainder % millisecondsIn[index].value;
        }
        return breakdown;
    };

    lpUtils.objectKeysArray = function(object) {
        var resultArray = [];
        if (object) {
            if (Object.keys) { // All browsers
                resultArray = Object.keys(object);
            } else { // IE8
                for (var prop in object) {
                    if (object.hasOwnProperty(prop)) {
                        resultArray.push(prop);
                    }
                }
            }
        }
        return resultArray;
    };

    lpUtils.objectKeysCount = function(object) {
        var keyCount = 0;
        for (var prop in object) {
            if (object.hasOwnProperty(prop)) {
                keyCount++;
            }
        }
        return keyCount;
    };

    lpUtils.getFirstProperty = function(data) {
        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                return prop;
            }
        }
        return;
    };

    lpUtils.getFirstValue = function (data) {
        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                return data[prop];
            }
        }
    };

// Code taken from the ECMA-262 standard since IE8 doesn't support it.
// https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/Array/indexOf#Compatibility
    lpUtils.getIndexOf = function(array, searchElement) {
        if (array === null) {
            throw new TypeError();
        }
        if (Array.prototype.indexOf) {
            return array.indexOf(searchElement);
        } else {
            var index = -1;
            for (var i = 0; i < array.length && index == -1; i++) {
                if (array[i] === searchElement) {
                    index = i;
                }
            }
            return index;
        }
    };

    /**
     * Function to extract the sub domain from any URL
     * @param url
     * @return {String}
     */
    lpUtils.getSubDomain = function(url) {
        var domainRegEx = new RegExp(/(http{1}s{0,1}?:\/\/)([^\/\?]+)(\/?)/ig);
        var matches, domain = null;
        if (url.indexOf("http") === 0) {
            matches = domainRegEx.exec(url);
        } else {
            matches = domainRegEx.exec(window.location.href);
        }
        if (matches && matches.length >= 3 && matches[2] !== "") {
            domain = matches[1].toLowerCase() + matches[2].toLowerCase(); // 0 - full match 1- HTTPS 2- domain
        }
        return domain;
    };

    lpUtils.replaceTableNodeContent = function (tableNode, newTableNodeHtml) {
        if (tableNode && newTableNodeHtml) {
            var mainTableNode = tableNode.parentNode;
            var className = tableNode.className;
            var tempDiv = document.createElement('div');
            tempDiv.innerHTML = '<table><'+tableNode.tagName+'>'+newTableNodeHtml+'</'+tableNode.tagName+'></table>';
            var newTableNode = tempDiv.firstChild.firstChild;
            newTableNode.className = className;
            mainTableNode.replaceChild(newTableNode, tableNode);
            return newTableNode;
        }
    };

    lpUtils.cloneObject = function(cloneObj) {
        if (typeof cloneObj === 'undefined') {
            return undefined;
        }
        if (cloneObj === null) {
            return null;
        }
        var resultObj = {};
        if (cloneObj.constructor === Object) {//If this is an object
            for (var key in cloneObj) {
                if (cloneObj.hasOwnProperty(key)) {
                    resultObj[key] = this.cloneObject(cloneObj[key]);
                }
            }
        } else {//Return copy of Array or primitive type in case of no Object
            if (cloneObj.constructor === Array) {
                resultObj = lpUtils.cloneArray(cloneObj);
            } else if (cloneObj.constructor === Date){
                resultObj = new Date(cloneObj);
            } else {
                resultObj = cloneObj;
            }
        }
        return resultObj;
    };

    lpUtils.cloneArray = function(arrayToClone) {
        var resultArray = [];
        for (var i=0; i<arrayToClone.length; i++) {
            resultArray[i] = lpUtils.cloneObject(arrayToClone[i]);
        }
        return resultArray;
    };

    lpUtils.getDefaultDisplayProperty = function(view) {
        var defaultDisplayProperty = 'block';
        if (view) {
            var viewTagName = view.tagName.toLowerCase();
            switch (viewTagName) {
                case    'table' :
                case    'thead' :
                case    'tbody' :
                case    'th' :
                case    'td' :
                case    'tr' :
                    defaultDisplayProperty = 'table'; break;
                case    'span' :
                    defaultDisplayProperty = 'inline'; break;
            }
        }
        return defaultDisplayProperty;
    };

    lpUtils.getScrollBarSize = function () {
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "100%";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.height = "100px";
        outer.style.overflow = "hidden";
        outer.appendChild (inner);

        document.body.appendChild (outer);

        var w1 = inner.offsetWidth;
        var h1 = inner.offsetHeight;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        var h2 = inner.offsetHeight;
        if (w1 == w2) { w2 = outer.clientWidth; }
        if (h1 == h2) { h2 = outer.clientHeight; }

        document.body.removeChild (outer);

        return [(w1 - w2),(h1 - h2)];
    };

    lpUtils.removeProtocolFromURL = function(url){
        var retVal = url;
        if(typeof url !== "undefined" && url !== null){
            if(url.toLowerCase().indexOf("https://") === 0 || url.toLowerCase().indexOf("http://") === 0){
                retVal = url.replace(/http{1}s{0,1}?:\/\//, "");
            }
        }
        return retVal;
    };

    lpUtils.initialsFromString = function(str){

        var initials = "";
        if(str && typeof str === 'string'){
            str = str.replace(/(\r\n|\n|\r)/gm," ").trim();

            if(str.indexOf(" ") === -1){
                initials = str.substring(0,2);
            } else {
                var wordArray = str.split(' ');
                for(var i=0;i<wordArray.length;i++){
                    initials += wordArray[i].substring(0,1);
                    if(initials.length === 2){
                        break;
                    }
                }
            }
        }
        return initials.toUpperCase();

    };

    /**
     *
     * @param paramArray - array of item, every item in the paramArray should contain key and value {key:"",value:""}
     * @param originalUrl - the url that we want to add the param to
     * @returns return a url that contain the encodeURIComponent param
     */
    lpUtils.getUrlWithParam = function(paramArray,originalUrl){
        if(!paramArray.length){
            return originalUrl;
        }
        var toReturn = originalUrl;
        var questionMarkIndex = originalUrl.indexOf('?');
        var addionMark = (questionMarkIndex !== -1 && questionMarkIndex!== originalUrl.length-1) ?  '&' : '?';

        for(var i=0 ; i < paramArray.length ;i++){
            toReturn = toReturn + addionMark +  encodeURIComponent(paramArray[i].name) + "=" + encodeURIComponent(paramArray[i].value);
            addionMark = '&';
        }
        return toReturn;
    };

//do NOT use this function to unbind callbacks, but capture the bounded function and unbind it
    lpUtils.callbackContextWrapper = function (callback, context) {
        return function(data, additional) {
            var dataToUse = {};
            if (typeof data !== 'undefined') {
                dataToUse = data;
            }
            dataToUse.context = context || this;
            return callback.call(context, dataToUse, additional);
        };
    };

    lpUtils._isIEEdge = function () {
        return navigator && ((navigator.appName === "Netscape") &&
          (navigator.userAgent && navigator.userAgent.indexOf('Edge/') !== -1));
    };

    lpUtils._isIE = function () {
        var docMode = document.documentMode;
        var not_true = false;
        var isIE;

        // If conditional compilation works, then we have IE 11-.
        isIE = /*@cc_on!@*/not_true;

        if (!isIE) {
            /// If document.documentMode is a number, then it is a read-only property, and so we have IE 8+.
            // Try to force this property to be a string.
            try {
                document.documentMode = "";
            }
            catch(e) {}

            isIE = "number" === typeof document.documentMode;

            // Switch back the value to be unobtrusive for non-IE browsers.
            try {
                document.documentMode = docMode;
            }
            catch(e) {}
        }

        if (!isIE && navigator && navigator.userAgent) {
            // Otherwise, if Trident exists in the user agent string, then we have IE 8+.
            isIE = "Netscape" === navigator.appName &&
                /Trident\/.*rv:([0-9]{1,}[.0-9]{0,})/.test(navigator.userAgent);
        }

        // Otherwise, we have a non-IE browser.
        return isIE;
    };

    lpUtils.isIE9 = function(){
        if (this._isIE()){
            return navigator && navigator.appVersion && navigator.appVersion.indexOf("MSIE 9") > -1;
        }
        return false;
    };

    lpUtils.isIpAddress = function(ipStr){
        /* The regular expression pattern */
        var ipPattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
        return ipPattern.test(ipStr);
    };

    /**
     * Method to add DOM events listener to an element
     * @param {Object} element - the element we're binding to
     * @param {String} event - the event we want to bind
     * @param {Function} callback - the function to execute
     */
    lpUtils.addEventListener = function(element, event, callback) {
        if (element.addEventListener) {
            element.addEventListener(event, callback, false);
        }
        else {
            element.attachEvent("on" + event, callback);
        }

        return function() {
            lpUtils.removeEventListener(element, event, callback);
        };
    };

    /**
     * Method to add DOM events listener to an element
     * @param {Object} element - the element we're binding to
     * @param {String} event - the event we want to bind
     * @param {Function} callback - the function to execute
     */
    lpUtils.removeEventListener = function(element, event, callback) {
        if (element.removeEventListener) {
            element.removeEventListener(event, callback, false);
        }
        else {
            element.detachEvent("on" + event, callback);
        }
    };

    /**
     * tests if the passed version string has the passed major version number.
     *
     * @param {string} majorVersion the major version to match.
     * @param {string} versionString the version string under test.
     *
     * @returns {boolean}
     */
    lpUtils.testMajorVersion = function (majorVersion, versionString) {
        if (!_.isString(versionString)) {
            throw 'versionString must be of type string';
        }
        return new RegExp('^' + majorVersion + '.*').test(versionString);
    };

    /**
     * set the inner text of an HTML Dom Element - cross browser
     * @param elm {HTMLElement} Dom element to set the text
     * @param text {string} the value of the text.
     */
    lpUtils.setElementText = function(elm, text){
        if(elm && (typeof elm.innerText !== 'undefined' || typeof elm.textContent !== 'undefined')){
            elm.innerText = text;
            elm.textContent = text;
        }
    };

    /**
     * Dynamic deep setting for object. This function get key path as string, create the keys if needed and set the value.
     * @param object    The root object
     * @param path      The key path as string
     * @param value     The value
     */
    lpUtils.setValue2object = function (object, path, value) {
        if (!_.isObject(object) || !_.isString(path)) {
            return;
        }
        var keys = path.split(".");
        var obj = object;
        // Check that path exist, and if not create it
        for (var i = 0, length = keys.length; i < length-1; i++){
            var n = keys[i];
            if (!(n in obj)) {
                obj[n] = {};
            }
            obj = obj[n];
        }
        // Set the value
        obj[keys[keys.length - 1]] = value;
    };

    /**
     * return the value of the property by the path for the object.
     * @param object - the base object to lookup the data.
     * @param path - the path to the property
     * @param defaultValue - default value if no path found.
     * @returns {*}
     */
    lpUtils.getPropertyValueByPath = function(object, path, defaultValue){
        if (_.isObject(object) && path) {
            var cursor = object;
            var pathParts = path.split('.');
            while (pathParts.length) {
                var curPath = pathParts.shift();
                if(typeof cursor === 'undefined') {
                    return defaultValue;
                }
                cursor = cursor[curPath];
            }
            return cursor;
        }
        return defaultValue;
    };

    /**
     * Extend a "Class" - allow inheritance.
     * @param child - the class to extend.
     * @param parent - the parent class to inherit from.
     */
    lpUtils.extend = function (child, parent) {
        var f = function () {};
        f.prototype = parent.prototype;
        child.prototype = new f();
        child.prototype.constructor = parent;
    };

    /**
     * Generate a random ID for use with unique IDs
     * @param length - optional number length of the string
     */
    lpUtils.randomId = function (length = 8) {
        var chars = 'abcdefghijklmnopqrstuvwxyz123456789';
        var string = '';
        for (var i = length; i > 0; i--) {
            string += chars[Math.floor(Math.random() * chars.length)];
        }
        return string;
    };

   return lpUtils;
});
