import CONST from 'const';
import BaseAppender from './BaseAppender';
import { environmentActionTypes, storeModuleTypes, store } from 'vue-infra';


const { ENVIRONMENT } = storeModuleTypes;
const { TOP_BAR_NOTIFICATION_UPDATE, TOP_BAR_NOTIFICATION_REMOVE } = environmentActionTypes;


class TopBarNotification extends BaseAppender {

  constructor() {
    super();

    this.store = store;
    this.notificationCounter = 0;
  }

  getDefaultOptions() {
    return {};
  }

  /**
   * @param options
   * @param options.type
   * @param options.text
   * @param options.onClick
   *
   * @returns {*}
   */
  notify(options) {
    const id = this.getNotificationId();
    this.store.dispatch(`${ENVIRONMENT}/${TOP_BAR_NOTIFICATION_UPDATE}`, Object.assign({}, options, { id }));
    return id;
  }

  clearNotification({ id }) {
    this.store.dispatch(`${ENVIRONMENT}/${TOP_BAR_NOTIFICATION_REMOVE}`, { id });
  }

  getNotificationId() {
    this.notificationCounter++;
    return this.notificationCounter;
  }

  isValidOptions(options) {
    const isValid = super.isValidOptions(options);
    if (isValid) {
      if (!options.type || !Object.values(CONST.TOP_BAR_NOTIFICATION_TYPE).includes(options.type)) {
        return `'type' is required and must be a value in CONST.TOP_BAR_NOTIFICATION_TYPE`;
      }
      if (!options.text) {
        return `'text' is required for notification with content`;
      }
      if (options.onClick && typeof options.onClick !== 'function') {
        return `'onClick' must be a function`;
      }
    }
    return isValid;
  }

  isValidClearNotificationOptions(options) {
    const isValid = super.isValidClearNotificationOptions(options);
    if (isValid) {
      if (!options.id) {
        return `'id' is required`;
      }
    }
    return isValid;
  }
}


export default TopBarNotification;
