define(function(require) {
  const exported = {
    navBar: null,
    mountContainer: null,
    createApps() {
      const { vueExports } = require('vue-infra');
      const { createApp } = vueExports;

      // Components
      const NavBar = require('src/components/app-frame/NavBar.vue').default;
      const VueAppsMountContainer = require('src/components/VueAppsMountContainer.vue').default;

      exported.navBar = createApp(NavBar);
      exported.mountContainer = createApp(VueAppsMountContainer);
    },
  };

  return exported;
});
