/**
 * Created by tomers on 4/26/2016.
 */
define(function (require) {
    "use strict";

    var LE = require("app");
    var LobModel = require("models/entities/LobModel");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var ApiResources = require("assets/data/apiResources.json");

    var NAME = "LobsCollection";

    var LobsCollection = RevisionedCollection.extend({
        model: LobModel,
        resource: ApiResources.AccountConfig.Lobs,
        name: NAME,

        initialize: function(models, options) {
            options = options || {};
            options.change404To200OK = true;
            RevisionedCollection.prototype.initialize.call(this,models,options);
        },

        isCollectionAllowed: function() {
            return LE.siteSettings.lobSettings.hasLobSettingEnabled() && (LE.sessionManager.hasViewAcLob() || LE.sessionManager.hasEditAcLob());
        },

        url: function() {
          return RevisionedCollection.prototype.url.apply(this, arguments);
        }
    });

    LobsCollection.NAME = NAME;

    return LobsCollection;
});
