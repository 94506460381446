<template>
  <div
    :class="leLogoClass"
    role="img"
    :aria-label="logoAlt"
  >
    <img
      v-show="customLogoUrl"
      :src="customLogoUrl"
      :alt="logoAlt"
    />
  </div>
</template>

<script>
  import { sessionManager } from 'vue-infra';
  import _ from 'lodash';

  var APPLICATION_LOGO_CLASS_NAMES = {
    CUSTOM: "custom-logo",
    LIVE_ENGAGE: "nb-s-icon-logo s-ai-logo"
  };

  export default {
    name: "LeLogo",
    data() {
      return {
        customLogoUrl: undefined,
        logoAlt: undefined,
      }
    },
    computed: {
      leLogoClass() {
        let logoClass = ''
        if (this.customLogoUrl) {
          logoClass = APPLICATION_LOGO_CLASS_NAMES.CUSTOM;
        } else {
          logoClass = APPLICATION_LOGO_CLASS_NAMES.LIVE_ENGAGE;
        }
        return logoClass;
      }
    },
    created(){
        const customizationObj = sessionManager.getAccountSettingValueByID(sessionManager.ACCOUNT_SETTINGS_ID.LE_UI_CUSTOMIZATION);
        if (customizationObj && !_.isEmpty(customizationObj.logoUrl)) {
          this.customLogoUrl = customizationObj.logoUrl;
        }
        this.logoAlt = this.$translator.translate("LEFramework.global.application.logo");
    }
  }

</script>

<style lang='scss' scoped>
  @import "../../../assets/scss/global";
  @import "../../../assets/scss/mixins";

    .live-engage-logo{
      @include lp-bg-image("assets/img/logo.svg");
      position: absolute;
      left: 25px;
      top: 6px;
      width: $live-engage-logo-width;
      height: $live-engage-logo-height;
    }

    .nb-s-icon-logo {
      margin-left: 8px;
      width: $live-engage-logo-width;
      height: $live-engage-logo-height;
    }

    $miniLogo: s-logo-mini;
    $icons: s-logo, s-ai-logo;
    @include add-icons-from-list($miniLogo, 0, 0, false);
    @include add-icons-from-list($icons);

    .custom-logo {
      margin-left: 8px;
      height: $live-engage-custom-logo-height;
      width: $live-engage-custom-logo-width;
      > img {
        height: $live-engage-custom-logo-height;
        width: $live-engage-custom-logo-width;
      }
    }

</style>
