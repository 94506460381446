<template>
  <div class="ring-radio-container">
    <div
      class="ring-radio-label"
      :class="{ 'ring-label-drop': audioUrls.length > 1 }"
    >
      <span class="ring-label-text">{{ currentText }}</span>
      <div
        v-for="(url, index) in audioUrls"
        :key="index"
      >
        <le-audio
          v-show="index === currentIndex"
          :ref="index"
          :src="url"
          @play="playingStarted"
          @pause="playingStopped"
          @end="increasePlayIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
    import { leAudio } from '@liveperson/lp-vue-components';

    export default {
        name: 'CustomRingPlayer',
        components: {
            leAudio,
        },
        props: {
            audioUrls: {
                type: Array,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                required: true,
            },
            canPlay: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['stop', 'play'],
        data() {
            return {
                isPlaying: false,
                currentIndex: 0,
            };
        },
        computed: {
            currentText() {
                if (this.audioUrls.length > 1 && this.isPlaying) {
                    return `${this.text} (${this.$translator.translate({ key: 'LEFramework.userBarMenu.footer.customRings.nameExtension', args: [this.currentIndex + 1, this.audioUrls.length]})})`
                }
                return this.text;
            },
        },
        methods: {
            playingStopped() {
                this.isPlaying = false;
                this.$emit('stop');
            },
            playingStarted() {
                this.isPlaying = true;
                if (!this.canPlay) {
                    const that = this;
                    setTimeout(() => {
                        if (!that.canPlay) {
                            that.$refs[that.currentIndex][0].pauseAudio();
                        }
                    }, 0);
                }
                this.$emit('play');
            },
            increasePlayIndex() {
                this.playingStopped();
                if (this.audioUrls.length > 1) {
                    this.currentIndex = (this.currentIndex + 1) % this.audioUrls.length;
                    if (this.currentIndex > 0) {
                        const that = this;
                        setTimeout(() => {
                            if (that.canPlay) {
                                that.$refs[that.currentIndex][0].onPlayClick();
                            }
                        }, 300);
                    }
                }
            },
        },
    }
</script>

<style scoped lang="scss">
  .ring-radio-container {
    position: relative;
    width: 50%;
  }

  .ring-radio-now-playing-tag {
    padding-top: 4px;
    position: relative;
    left: 20px;
    line-height: 1.3;
    height: 10px;
    width: 55%;
    animation: fadein 0.5s;
    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

  }
  .ring-radio-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: relative;
    left: 1px;
    top: 1px;
    margin-left: 15px;
    margin-right: 20px;
    justify-content: space-between;

    .ring-label-text {
      margin: 0 5px;
      width: 80%;
    }
  }
</style>
