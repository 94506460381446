/**
 * Dynamically resolve a url for the application resources
 * the Resource patterns sit at apiResources.json file
 * Each model,Collection that uses a resource just put it at its definition
 * for example:
 * CampaignsCollection = PersistentCollection.extend({
 model:CampaignModel,
 resource: ApiResources.AccountConfig.Campaigns
 }

 This resolver is used by backbone baseModel,baseCollection to resolve the correct url according to the
 correct domain,accountID etc ...
 */

define(function (require) {
    "use strict";
    var UrlResolver = require("urlResolver");
    var ApiResources = require("assets/data/apiResources.json");
    var LEConfig = require("assets/config/le-env-conf");
    var { Logger } = require('vue-infra');
    var $ = require("jquery");
    var _ = require("underscore");
    var es6MiddleUtils = require('src/es6MiddleUtils');
    var SourceQueryParamDecorator = es6MiddleUtils.removeEsModuleDefault(require('../../src/decorators/SourceQueryParamDecorator'));

    var LEResourceResolver = (function () {

        var KEYS = {
                OPTIONS: "{options}",
                MERGE: "merge",
                OVERRIDE: "override",
                FIRST: "first"
            },
            VERSIONPARAMNAME = "v",
            accountId,
            ver = "0.1";

        var logger = Logger.getLogger("LEFramework");

        /**
         * Returns the current account Id
         * Caches it in first run
         * @returns {*}
         */
        function getCurrentAccountId() {
          var parts, path;
          if (!accountId) {//Cache instead of continually looking this up....
            path = UrlResolver.getWindowLocation({removeTrailing: true});
            parts = path.split("/");
            accountId = parts[parts.length - 1];
          }
          return accountId;
        }

        /**
         * Evaluates the resource and gets the current
         * url from it
         * @param resource
         * @returns {*}
         * @private
         */
        function _evaluateResource(resource) {

            if (_.isFunction(resource)) {//In case of base collections mostly
                /* jshint -W040 */
                resource = resource.call(this);//This needs the collection context since functions from the context might be run here as well
                /* jshint +W040 */
            }
            if (_.isUndefined(resource) || !_.isObject(resource)) {
                resource = {};
            }
            return resource;
        }

        /**
         * Mustaches the url and replaces values
         * @param resource
         * @param map
         * @returns {*}
         * @private
         */
        function _extendMapFromResource(resource, map) {
            _.each(map, function (value, key) {
                if (KEYS.OPTIONS !== key && !_.isUndefined(value)) {
                    if (resource.mapping && resource.mapping[key]) {
                        map[key] = resource.mapping[key][value];
                    }
                }
            });
            return map;
        }

        /**
         * Adds the version needed to the url
         * @param url
         * @param version
         * @returns {string}
         * @private
         */
        function _appendVersion(url, version) {
            return url + ((url.indexOf("?") > 0) ? "&" : "?") + VERSIONPARAMNAME + "=" + version;
        }

        /**
         * Builds the correct url from the resource
         * It does the following:
         * Get the url string from the resource
         * replace parameters on the URL from configuration or LEConfig
         * add the version
         * prefixes the protocol if it is missing
         * @param resource
         * @param rootUrl
         * @param urlResolverOptions - delegate options to the le-core-infra urlResolver#getUrl implementation
         */
        function getUrl(resource, rootUrl, urlResolverOptions) {
            var optionsMap = $.extend(true, {}, LEConfig);
            var url = resource;

            if (!_.isString(resource)) {//Resource is either an invoked function or an object
                /* jshint -W040 */
                resource = _evaluateResource.call(this, resource);
                /* jshint +W040 */
                _.extend(optionsMap, resource, { AbsolutePath: UrlResolver.getWindowLocation()});
                url = optionsMap.url;
            }
            optionsMap = _extendMapFromResource(resource, optionsMap);
            url = UrlResolver.getUrl(url, optionsMap, urlResolverOptions);
            if (optionsMap.version && !rootUrl) {
                url = _appendVersion(url, optionsMap.version);
            }
            if (optionsMap.source && !rootUrl) {
              url = SourceQueryParamDecorator.addSourceQueryParam.call({}, url, optionsMap.source);
            }

            return url;
        }

        var addLocalApiResource = function (key, value) {
            ApiResources[key] = value;
        };

        var addConfigValue = function (key, value) {
            LEConfig[key] = value;
        };

        var removeConfigValue = function (key, value) {
            delete LEConfig[key];
        };

        var getConfigValue = function (key) {
            return LEConfig[key];
        };

        var extendResourceWithOptions = function (resource, options) {
            var res = _.isFunction(resource) ? resource.call(this) : resource;
            var extended = res;

            if (_.isString(res)) {
                extended = {};
                extended.url = res;
            }

            return $.extend(true, {}, extended, options);
        };

        var extendOptionsWithResourceOptions = function (resource, options) {
            var res = _.isFunction(resource) ? resource.call(this) : resource;
            if (_.isObject(res) && _.isObject(res[KEYS.OPTIONS])) {
                options = options || {};

                var opts = res[KEYS.OPTIONS];
                var merge = opts[KEYS.MERGE];
                var override = opts[KEYS.OVERRIDE];
                var firstOverride = _.isString(opts[KEYS.FIRST]) && (KEYS.OVERRIDE == opts[KEYS.FIRST]);

                if (firstOverride) {
                    if (_.isObject(override)) {
                        options = $.extend(options, override);
                    }
                    if (_.isObject(merge)) {
                        options = $.extend(true, options, merge);
                    }
                } else {
                    if (_.isObject(merge)) {
                        options = $.extend(true, options, merge);
                    }
                    if (_.isObject(override)) {
                        options = $.extend(options, override);
                    }
                }
            }

            return options;
        };

        var getResourceBaseUrl = function () {
            if (window.leResourceBaseUrl) {
                return window.leResourceBaseUrl;
            } else {
                return window.location.protocol + '//' + window.location.hostname + '/';
            }
        };

        return {
            apiResources: ApiResources,
            getResourceBaseUrl: getResourceBaseUrl,
            getWindowLocation: UrlResolver.getWindowLocation,
            getCurrentAccountId: getCurrentAccountId,
            getUrlParts: UrlResolver.getUrlParts,
            getUrl: getUrl,
            isSameOrigin: UrlResolver.isSameOrigin,
            addLocalApiResource: addLocalApiResource,
            addConfigValue: addConfigValue,
            removeConfigValue: removeConfigValue,
            getConfigValue: getConfigValue,
            extendResourceWithOptions: extendResourceWithOptions,
            extendOptionsWithResourceOptions: extendOptionsWithResourceOptions,
            version: function () {
                logger.info("leResourceResolver.js version " + ver);
                return ver;
            }
        };

    })();

    return LEResourceResolver;
});
