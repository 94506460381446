/**
 * Created with IntelliJ IDEA.
 * User: tomers
 * Date: 3/3/13
 * Time: 3:27 PM
 * Name utilities
 */

define(function (require) {
    "use strict";

    var $ = require("jquery");
    var _ = require("underscore");

    var DEFAULT_MAX_NAME_LENGTH = 50;

    var NameUtility = (function () {
        return {
            /**
             *  Name (add pre-defined "type #" as default and allow change in-line)
             * number is a max of all collection model names with the pre-defined name +1
             * @return {string}
             * @param collection
             * @param prefix   ( for example campaign, goal, visitorProfile etc )
             * @param attr   ( the attribute for which we generate the next automatic name)
             * @param sep   ( seperator to use between prefix and number)
             * @return {string}
             */
            getUniqueName: function (collection, prefix, attr, sep) {
                var arr = $.isArray(collection) ? collection : collection.models;
                var attributeName = attr || "name";
                var seperator = sep || " ";
                var nameMaxNumber = 1;
                var myRegExp = new RegExp("^" + prefix + seperator + "([0-9]+$)", "i");
                _.each(arr, function (model) {
                    var myMatch = myRegExp.exec(model.get ? model.get(attributeName) : model[attributeName]);
                    if (myMatch != null) {
                        var entityNumber = parseInt(myMatch[1], 10);
                        if (nameMaxNumber <= entityNumber) {
                            nameMaxNumber = entityNumber + 1;
                        }
                    }

                });

                return prefix + seperator + nameMaxNumber;
            },

            /**
             * return name + instance the smallest inst number available.
             * @return {string}
             * @param collection (or array - mandatory)
             * @param original name (mandatory)
             * @param attr (optional)
             * @param maxNameLength (optional)
             * @return {string}
             */
            duplicateName: function (collection, originalName, attr, maxNameLength) {

                var duplicateName, res;

                var maxLength = !_.isUndefined(maxNameLength) ? maxNameLength : DEFAULT_MAX_NAME_LENGTH;
                var arr = $.isArray(collection) ? collection : collection.models;
                var attributeName = attr || "name";
                var index = 1;

                var fn = function (model) {
                    var modelName = model.get ? model.get(attributeName) : model[attributeName];
                    return modelName === duplicateName;
                };

                do {
                    var proposedSuffix = "[" + index.toString() + "]";
                    duplicateName = originalName.substring(0, maxLength - proposedSuffix.length) + proposedSuffix;
                    res = _.find(arr, fn);
                    index++;
                } while (_.isObject(res));

                return duplicateName;
            }
        };
    })();
    return NameUtility;

});
