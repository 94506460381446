<script>
import ApiResources from 'assets/data/apiResources.json';
import * as CONST from 'const';
import * as LpAjaxTransport from 'lptransporter/lpajax';
import urlResolver from 'urlResolver';
import {
  AGENT_STATUS_DATA_STATES,
  environmentActionTypes,
  environmentGetterTypes,
  gChannel,
  Logger,
  sessionManager,
  storeModuleTypes,
  Vuex,
} from 'vue-infra';

const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, [
  environmentGetterTypes.CURRENT_AGENT_STATUS,
  environmentGetterTypes.RESOLVE_CUSTOM_AWAY_REASONS,
  environmentGetterTypes.AGENT_STATUS_DATA_STATE,
]);
const logger = Logger.getLogger('LiveEngage:TopLevelMenu');

export default {
  name: 'AgentStatuses',
  data() {
    const online = this.$translator.translate(
      `LEWebAgent.${CONST.DEFAULT_AGENT_STATUSES.online.translatorParameter}`
    );
    const away = this.$translator.translate(
      `LEWebAgent.${CONST.DEFAULT_AGENT_STATUSES.away.translatorParameter}`
    );
    const backIn5 = this.$translator.translate(
      `LEWebAgent.${CONST.DEFAULT_AGENT_STATUSES.occupied.translatorParameter}`
    );
    return {
      customAwayReasonList: {},
      selectedItem: {},
      statusItems: {
        [CONST.DEFAULT_AGENT_STATUSES.online.value]: {
          ...CONST.DEFAULT_AGENT_STATUSES.online,
          title: online,
          availabilityTitle: online,
        },
        [CONST.DEFAULT_AGENT_STATUSES.occupied.value]: {
          ...CONST.DEFAULT_AGENT_STATUSES.occupied,
          title: backIn5,
          availabilityTitle: backIn5,
        },
        [CONST.DEFAULT_AGENT_STATUSES.away.value]: {
          ...CONST.DEFAULT_AGENT_STATUSES.away,
          title: away,
          availabilityTitle: away,
          customReasonsAvailable: false,
        },
      },
      localCurrentStatus: {},
      away: CONST.DEFAULT_AGENT_STATUSES.away.value,
    };
  },
  computed: {
    currentStatus: getters[environmentGetterTypes.CURRENT_AGENT_STATUS],
    customAwayReasons:
      getters[environmentGetterTypes.RESOLVE_CUSTOM_AWAY_REASONS],
    agentStatusDataState:
      getters[environmentGetterTypes.AGENT_STATUS_DATA_STATE],

    hasConfigForCustomMaxSize() {
      return (
        LE.sessionManager.hasMultipleAgentStatesFeature() &&
        LE.sessionManager.hasConfigForMaximumCustomAwayStatus()
      );
    },
  },
  watch: {
    currentStatus() {
      this.setSelectedItem();
    },
    customAwayReasons: {
      handler() {
        this.extendCustomReasons();

        //set again the selected item after we fetch the custom reasons
        this.setSelectedItem();
      },
      deep: true
    }
  },
  created() {
    gChannel.register(
      'LiveEngageCommon',
      'customAgentAwayReason:update',
      this.getAwayCustomReasons
    );
    this.setSelectedItem();
    this.getAwayCustomReasons();
  },
  methods: {
    getAwayCustomReasons() {
      const domain = sessionManager.getCsdsServiceURI('accountConfigReadWrite');
      let url = urlResolver.getUrl(
        ApiResources.AccountConfig.AgentReasons.url,
        {
          accountConfigReadWrite: domain,
          accountId: sessionManager.getAccountId(),
        }
      );

      this.$store.dispatch(
        `${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_CUSTOM_AWAY_REASONS}`,
        {
          url: `${url}`,
          data: {
            v: 2.0,
            include_deleted: false,
          },
          success: this.extendCustomReasons,
          transporter: LpAjaxTransport,
        }
      );
    },

    updateAgentStatus(item) {
      if (!this.isStatusValid(item)) {
        return;
      }

      this.$store.dispatch(
        `${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`,
        {
          value: item.value,
          reasonId: item.customReason ? item.id : undefined,
        }
      );

      logger.bam({
        serviceName: 'CCUI-CORE',
        appName: 'CCUI',
        eventName: 'user status change',
        widgetName: 'user-menu',
        value: item.value,
        term: item.customReason ? item.text : undefined,
      });
    },

    extendCustomReasons() {
      this.customAwayReasonList = this.customAwayReasons.reduce(
        (accumulator, currentReason) => {
          const statusItem = this.statusItems[currentReason.state];

          // if the currentReason is not away status
          if (Object.keys(statusItem).length > 0) {
            const extendWith = { ...statusItem, customReasonsAvailable: false };
            const reason = {
              customReason: true,
              ...currentReason,
              ...extendWith,
            };

            return { ...accumulator, [currentReason.id]: reason };
          }
          return { ...accumulator };
        },
        {}
      );

      const awayReason = this.statusItems[this.away];
      awayReason.customReasonsAvailable = this.customAwayReasons.length > 0;
    },

    setSelectedItem() {
        let selectedItem = {};

        const hasCurrentStatusChanged = JSON.stringify(this.currentStatus) !== JSON.stringify(this.localCurrentStatus);
        const canUpadte = (!this.currentStatus.isFirstInit && hasCurrentStatusChanged) || (this.currentStatus.isFirstInit && !Object.keys(this.selectedItem).length);
        this.localCurrentStatus = this.currentStatus;

        if (this.agentStatusDataState !== AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY && canUpadte) {
            const customAway = this.customAwayReasonList[this.currentStatus.reasonId] || {};

            if (this.currentStatus.reasonId && this.currentStatus.reasonId > 0 && Object.keys(customAway).length > 0) {
                selectedItem = { ...customAway };
            } else {
                selectedItem = { ...this.statusItems[this.currentStatus.value] };
            }
        }


        if (Object.keys(selectedItem).length > 0) {
            selectedItem.isFirstInit = this.currentStatus.isFirstInit;
            this.selectedItem = selectedItem;
            this.updateAwayStatusTitle(this.selectedItem);
        }
    },

    updateAwayStatusTitle(selectedStatus) {
      const awayReason = this.statusItems[this.away];
      awayReason.availabilityTitle = selectedStatus.customReason
        ? selectedStatus.text
        : awayReason.title;
    },

    isStatusValid(item) {
      return item && item.title && item.availabilityTitle && item.value;
    },
  },
};
</script>

<style>
</style>
