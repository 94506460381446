/**
 * Created by iraphael on 3/24/2019.
 */
define(function (require) {
    "use strict";

    var LE = require("app");
    var _ = require("underscore");
    var BaseModel = require("models/BaseModel");
    var ApiResources = require("assets/data/apiResources.json");
    var ErrorCodes = require("codeTables/errorCodes.json");
    var LocaleResolver = require('i18n/localeResolver');


    var KEYS = {
        COUNTRY_CODE: "key",
        COUNTRY_NAME: "value",
        COUNTRY_ICON: "iconClass"
    };

    var CountriesModel = BaseModel.extend({
        KEYS: KEYS,
        name: "CountriesModel",

        initialize: function(options) {
          this.valueIsChanged = false;
          this.resource = ApiResources.Internal.Countries;
          BaseModel.prototype.initialize.call(this, options);
        },

        isValueChanged: function() {
          return this.valueIsChanged;
        },

        validateSaveEnabled: function () {
          this.originalValidation = this.validation;
          this.validation = this.saveEnableValidation;

          var enable = this.isValid();

          this.validation = this.originalValidation;

          return enable;
        },

        parse: function (response, options) {
          this.setKey(response.key);
          this.setValue(response.value);
          this.setIcon(response.iconClass);
        },

        getKey: function() {
            return this.get('key');
        },

        setKey: function(key) {
          return this.set('key', key);
        },

        getValue: function(){
            return this.get('value');
        },

        setValue: function(value){
            this.set('value', value);
        },

        getIcon: function(){
          return this.get('iconClass');
        },

        setIcon: function(iconClass){
          this.set('iconClass', iconClass);
        },

      save: function(attributes, options) {
        options = options ? _.clone(options) : {};
        var origError = options.error;


        options.error = _.bind(function(collection, resp, opt) {
          var errorObject = {};
          var errorMessage = resp && _.has(resp.responseError, "message") ?
            resp.responseError.message : LE.translator.translate("LEFramework.something.went.wrong");

          if (resp && resp.responseError && resp.responseError.error) {
            if (_.has(resp.responseError.error, "message")) {
              errorMessage = resp.responseError.error.message;
            }

            if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.duplicateEntity)) {
              errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
            }
            if (_.has(resp.responseError.error, "internalCode") && resp.responseError.error.internalCode === ErrorCodes.appServer.internalCodes.duplicateEntity) {
              errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
            }
          }
          if (resp && resp.responseError && resp.responseError.internalCode) {
            if (resp.responseError.internalCode === ErrorCodes.uniquenessViolation) {
              errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
            }
          }

          if(!_.isEmpty(errorObject)) {
            options.errorObject = errorObject;
          }
          options.errorMessage = errorMessage;

          if (origError) {
            origError(collection, resp, options);
          }
        }, this);

        BaseModel.prototype.save.call(this, attributes, options);
      },

      destroy: function(options) {
        options = options ? _.clone(options) : {};
        var origError = options.error;
        options.error = _.bind(function(collection, resp, opt) {
          var errorMessage = LE.translator.translate("LEFramework.something.went.wrong");
          if (resp && resp.responseError && resp.responseError.message) {
            errorMessage = resp.responseError.message;
          }

          if (resp && resp.responseError && resp.responseError.internalCode) {
            if (resp.responseError.internalCode === ErrorCodes.dependencyConflict || resp.responseError.internalCode === ErrorCodes.dependencyConflictExternal) {
              errorMessage = LE.translator.translate('LEUserManagement.skillList.delete.error.assignedToUsers');

              var errorFields = resp.responseError.fields;
              var translatedFields = [];
              if (!_.isEmpty(errorFields)){
                for (var i = 0; i < errorFields.length; i++){
                  var key = "LEFramework.entities.skill.delete.error.dependencies.field." + errorFields[i];
                  var translatedField = LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), key) ? LE.translator.translate(key) : errorFields[i];
                  translatedFields.push(translatedField);
                }

                errorMessage = LE.translator.translate({
                  key: "LEFramework.entities.skill.delete.error.dependencies",
                  args: [{key: translatedFields.join('<br>'), isTranslate:false}]
                });

              }
            }
          }
          options.errorMessage = errorMessage;
          if (origError) {
            origError(collection, resp, options);
          }
        }, this);

        BaseModel.prototype.destroy.call(this, options);
      },

      //private functions:
      _set: function(key,value) {
        this.valueIsChanged = true;
        this.set(key, value);
      }
    });


    CountriesModel.KEYS = KEYS;
    return CountriesModel;
});
