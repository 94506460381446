define(function (require) {
    "use strict";

    var TimeUtilities;

    var SECONDS_TO_TIME_UNIT_CONVERSION = {
        TIME_UNIT_SECONDS: 1,
        TIME_UNIT_MINUTES: 60,
        TIME_UNIT_HOURS: 60 * 60,
        TIME_UNIT_DAYS: 60 * 60 * 24
    };

    var TIME_UNITS = {
        DAYS: "days",
        HOURS: "hours",
        MINUTES: "minutes",
        SECONDS: "seconds"
    };

    TimeUtilities = {

        getTimeUnits: function () {
            return TIME_UNITS;
        },

        gerTranslatedUnits: function () {
            var translatedUnits = [];
            for (const unit in TIME_UNITS) {
                translatedUnits.push({
                    "key": TIME_UNITS[unit],
                    "value": this.getTranslatedUnit(TIME_UNITS[unit])
                });
            }
            return translatedUnits;
        },

        getTranslatedUnit: function (value) {
            return window.LE.translator.translate("LEFramework.timeUnitCodes." + value);
        },

        convertSecondsToOtherUnit: function (sec) {
            var time = sec;
            var unit = TIME_UNITS.SECONDS;

            if (time % 60 === 0) {
                time = time / 60;
                unit = TIME_UNITS.MINUTES;
                if (time % 60 === 0) {
                    time = time / 60;
                    unit = TIME_UNITS.HOURS;
                    if (time % 24 === 0) {
                        time = time / 24;
                        unit = TIME_UNITS.DAYS;
                    }
                }
            }

            return {
                time: time,
                unit: unit
            };
        },

        convertToSeconds: function (timeValue, timeUnit) {
            var unitMapping = {};
            unitMapping[TIME_UNITS.SECONDS] = SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_SECONDS;
            unitMapping[TIME_UNITS.MINUTES] = SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_MINUTES;
            unitMapping[TIME_UNITS.HOURS] = SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_HOURS;
            unitMapping[TIME_UNITS.DAYS] = SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_DAYS;

            return this.convertTimeUnitToSeconds(timeValue, unitMapping[timeUnit]);
        },

        convertTimeUnitToSeconds: function (timeValue, secondsPerTimeUnit) {
            return secondsPerTimeUnit * timeValue;
        },
    };

    return TimeUtilities;
});

