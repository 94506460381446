/**
 * Created with IntelliJ IDEA.
 * User: elina
 * Date: 2/13/14
 * Time: 10:48 AM
 * To change this template use File | Settings | File Templates.
 */


define(function (require) {
    var $ = require("jquery");
    var _ = require("underscore");
    var BaseModel = require("models/BaseModel");
    var LEResourceResolver = require("leResourceResolver");
    var { Logger } = require('vue-infra');
    var RT_VISITOR_COUNT_FETCH_TIME_FRAME = 1440;
    var RT_VISITOR_COUNT_FETCH_INTERVAL = 10000;

    var visitorCountModel = BaseModel.extend({
        defaults: {
            fetchTime: null,
            visitorCount: null,
            wasSiteVisited: null
        },
        name: "visitorCountModel",

        metricType: "enterSite",
        fetchInterval: RT_VISITOR_COUNT_FETCH_INTERVAL,
        timeFrame: RT_VISITOR_COUNT_FETCH_TIME_FRAME,
        resourceApi: LEResourceResolver.apiResources.DataAgent.CountMetricsAPI,
        resource: function() {
            var params = {
                timeFrame: this.timeFrame,
                metricsArr: this.metricType
            };
            return LEResourceResolver.extendResourceWithOptions.call(this, this.resourceApi, params);
        },

        parseArrayStructure: {
            dataMetrics: {
                key: "metricType"
            }
        },

        initialize: function () {
            this.logger = Logger.getLogger("LEFramework");

            this.logger.debug("visitorCountModel initialized. starting to fetch");

            // this.fetchRtVisitorCount();

//            var that=this;
//            this.fetchTimer=window.setInterval(function(){
//                    that.fetchRtVisitorCount();
//                }
//                ,this.fetchInterval);
        },

        fetchRtVisitorCount: function (options) {
            var that = this;
            this.fetch({
                success: function (data) {
                    if(options.success && _.isFunction(options.success)){
                        options.success();
                    }
                },
                error: function (error) {
                    that.logger.error("fetch error : " + error);
                    if(options.error && _.isFunction(options.error)){
                        options.error(error);
                    }
                }

            });
        },

        parse: function (resp, options) {
            var that = this;

            if (resp && resp.dataMetrics) {
                var enterSite = _.filter(resp.dataMetrics, function (obj) {
                    return obj.metricType === that.metricType;
                });
                if (enterSite && enterSite.length > 0) {
                    that.set("fetchTime", new Date());
                    that.set("visitorCount", enterSite[0].counter);
                    that.set("wasSiteVisited", enterSite[0].counter > 0 ? true : false);

                    this.logger.debug("fetch success: " + resp);
                }
                else {
                    that.logger.error("fetch error - fetch returned data without enterSite metricType: " + resp);
                }
            }
            else {
                that.logger.error("fetch error - fetch returned bad data: " + resp);
            }

        }

    });

    return visitorCountModel;

});
