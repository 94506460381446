
define(function (require) {
    "use strict";

    var RevisionedCollection = require("collections/RevisionedCollection");
    var ApiResources = require("assets/data/apiResources.json");
    var SiteSettingsModel = require("models/SiteSettingsModel");
    var { Logger } = require('vue-infra');
    var _ = require("underscore");

    var SETTINGS_ENUM = require("codeTables/settings.json");

    var SettingsCollection = RevisionedCollection.extend({
        model : SiteSettingsModel,
        resource : ApiResources.AccountConfig.SiteSettings,
        SETTINGS_ENUM: SETTINGS_ENUM,

        initialize: function(models, options) {
            options = options || {};

            this.logger = Logger.getLogger("LEFramework");
            this.logger.info("Initializing SettingsCollection", "SettingsCollection:initialize");
            this.groups = options.groups;
            this.source = options.source;

            if (options) {
                this.contextType = options.contextType;
                this.contextId = options.contextId;
                this.contextCascading = options.contextCascading;
            }
            RevisionedCollection.prototype.initialize.call(this, models, options);
        },
        url: function(){
            var url = RevisionedCollection.prototype.url.call(this);
            if (!_.isUndefined(this.contextType)) {
                url += "&context_type=" + this.contextType;
            }
            if (!_.isUndefined(this.contextId)) {
                url += "&context_id=" + this.contextId;
            }
            if (!_.isUndefined(this.contextCascading)) {
                url += "&context_cascading=" + this.contextCascading;
            }

            return url;
        },
        fetch: function (options) {
            options = options || {};

            var groups = options.groups || (options.fetchOptions && options.fetchOptions.groups) || this.groups || SETTINGS_ENUM.SETTING_GROUP.loginSession;
            options.data = $.param({groups: groups});

/*            var newOptions = {
                data: $.param({groups: groups}),
                success: options.success,
                error: options.error
            };*/
            return RevisionedCollection.prototype.fetch.call(this, options);
        }
    });

    SettingsCollection.SETTINGS_ENUM = SETTINGS_ENUM;

    return SettingsCollection;
});
