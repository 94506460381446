/**
 * Created with IntelliJ IDEA.
 * User: yniran
 * Date: 03/03/14
 * Time: 11:48
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {

    var Marionette = require("marionette");

    require("marionette.componentManager");
    require("marionette.leExtensions");
    require("marionette.gridView");
    require("marionette.graphQLGridView");

    return Marionette;
});
