/**
 * created by akirilyuk on 15.10.2018
 * Model class for loading new support config and lptag site id in case of disaster
 */
define(function (require) {
    "use strict";
    var LE = require("app");
    var RefreshModel = require("models/RefreshModel");
  var _ = require('underscore');
    var LEConfig = require("assets/config/le-env-conf");
    var logger = LE.logger;
    var settings = {
        FETCH_INTERVAL: 600000,
        FETCH_ERROR_INTERVAL: 5000,
        RETRY: true
    };

    return RefreshModel.extend({
        name: "LPTagConfigModel",
        resource: {
            url: LEConfig.LPtag_config_url
        },
        initialize: function (options) {
            var _options = _.extend(options);
            _options.fetchInterval = settings.FETCH_INTERVAL;
            _options.retry = settings.RETRY;
            _options.fetchErrorInterval = settings.FETCH_ERROR_INTERVAL;
            RefreshModel.prototype.initialize.call(this, _options);
            this._lpTagStartEventBind = null;
        },
        /**
         * set MtagConfig if provided data is valid and the monitoring siteId is different to the current one
         * @param {Object} data
         */
        setMtagConfig(data) {
            logger.debug('received MTAG Config data', {data});
            if (this._isValidData(data)) {
                if (LE.LPtag_mtaconfig.siteId !== data.siteId) {
                    logger.debug('loaded MTAG config is different to local one, updating to ' + data.siteId, {data});
                    LE.LPtag_mtaconfig = data;
                    LE.urlResolver.addConfigValue("monitoredAccountId", data.siteId);
                    this._loadNewTaglet(data);
                } else {
                    logger.debug('loaded MTAG config is equal to local one, nothing to do.');
                }
            } else {
                logger.error('provided MTAG config data is not valid', {data});
            }
        },
        /**
         * Internal function for verification and validation of the provided lpTag settings
         * @param data
         * @returns {Boolean} true if data is valid else false
         * @private
         */
        _isValidData(data) {
            return (data && data.domain && data.tagjs && data.siteId && data.unifiedWindow && data.unifiedWindow.environment && data.unifiedWindow.windowId && data.unifiedWindow.authenticationType);
        },
        /**
         * Start pulling the lptag config periodically
         */
        startRefresh() {
            if (!this.activeRefresh) {
                logger.debug('Started refresh of LpTagConfigModel');
                this.activeRefresh = true;
                this._refresh();
            }
        },
        /**
         * Stop the refresh thread for the LPTag Config
         */
        stopRefresh() {
            this.activeRefresh = false;
        },
        /**
         * Fully reloads lpTaglet for provided data by removing old script tag,
         * reloading lpTag script and restarting shark monitoring
         * @param {Object} data lpTag settings data
         * @private
         */
        _loadNewTaglet(data) {
            lpTag.events.trigger('LP_OFFERS', 'HIDE');
            logger.debug('replacing tag settings', {data});
            lpTag.site = data.siteId;
            lpTag.ovr.domain = data.domain;
            lpTag.ovr.tagjs = data.tagjs;
            logger.debug('remove old sdes ...');
            lpTag.sdes.reset();
            if(this._lpTagStartEventBind){
                logger.debug('removing old event bind');
                lpTag.events.unbind(this._lpTagStartEventBind);
            }
            this._lpTagStartEventBind = lpTag.events.bind("LPTAG", "ON_STARTED", function () {
                // lpTag has loaded, we can now reinitialize cp messaging flow
                LE.vent.trigger("LPTagConfigModel:config:changed");
            });
            logger.debug('starting lpTag reload');
            lpTag.newPage(window.location.href);
        },
        /**
         * Active pulling the config file from remote
         * @private
         */
        _refresh() {
            if (!this.activeRefresh) {
                return;
            }
            var xhttp = new XMLHttpRequest();
            var that = this;
            xhttp.onload = function (evt) {
                if (xhttp.status === 200 && xhttp.readyState === 4) {
                    logger.debug('Fetching LPTagConfig Model success!');
                    try {
                        var mtagConfig = JSON.parse(xhttp.responseText);
                        that.setMtagConfig.call(that, mtagConfig);
                    } catch (err) {
                        logger.error('Failed parsing JSON config for MTAG');
                    }
                    logger.debug('scheduling a new LPTagConfigModel refresh');
                    setTimeout(function () {
                        that._refresh();
                    }, that.fetchInterval);
                } else if (xhttp.status >= 400) {
                    that._handleError.call(that, xhttp);
                }

            };
            xhttp.onerror = function (evt) {
                that._handleError.call(that, xhttp);
            };
            // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#Bypassing_the_cache
            // don't let the config be cached by adding a timestamp to the url
            xhttp.open('GET', this.resource.url.concat('?d=').concat(new Date().getTime()), true);
            logger.debug('Fetching LPTagConfig model from remote....');
            xhttp.send();
        },
        /**
         * Helper function to handle an error response on LPtag config fetch from remote
         * @param xhttp failed xhttp request object
         * @private
         */
        _handleError(xhttp) {
            logger.error('Error loading MTag config', {responseText: xhttp.responseText});
            if (this.retry) {
                var timeoutInterval = (this.tryCounter < this.maxRetries) ? this.fetchErrorInterval : this.fetchErrorIntervalAfterRetries;
                setTimeout(function () {
                    this.tryCounter++;
                    this._refresh.call(this);
                }.bind(this), timeoutInterval);
            } else {
                this.activeRefresh = false;
            }
        }
    });
});
