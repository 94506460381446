/**
 * Created with IntelliJ IDEA.
 * User: noam
 * Date: 6/18/13
 * Time: 5:23 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var LE = require("app");
    var template = require("ui.components/notification-layer/templates/notificationLayer.tmpl");
    var Loader = require("ui/loader/Loader");
    var Button = require("ui/button/Button");
  var _ = require('underscore');
    var NotificationLayerView = {};

    var SHOWN_CLASS = "shown";
    var ANIMATION_EASING = "swing"; //"easeOutExpo";
    var CLOSE_DELAY = 3500;

    LE.module('LECampaigns', function (LECampaigns, LE, Backbone, Marionette, $, _) {

        NotificationLayerView = Marionette.ItemView.extend({
            template: template,
            className: "notification-body",
            opened: false,

            ui: {
              message: ".message",
              icon: ".icon-container div",
              activity: ".activity",
              close: ".close-btn"
            },

            events: {
                // "click .close-btn": "onCloseButtonClicked"
            },
            initialize: function (options) {
                this.mode = options.mode || MODES.TRACKING_CONNECTION;
                // add the custom state to base states and set the currently used states object
                STATES = $.extend(STATES, this.mode.states);
            },

            onRender: function () {
                this._setState(STATES.INIT);
                this._openNotification(this.mode.animationDelay);
            },


            /***** ANIMATION FUNCTIONS *******/
            /**
             *
             * @param delay milliseconds delay of the animation
             * @private
             */
            _openNotification: function (delay) {
                delay = delay | 0;
                this.opened = true;
                this.$el.addClass(SHOWN_CLASS);
                this.$el.delay(delay).animate({height:this.mode.notificationHeight}, this.mode.animationOpenTime, ANIMATION_EASING );
                this.trigger(EVENTS.HIDE_CONFIGURATION_LINK);
            },
            _closeNotification: function (delay) {
                delay = delay | 0;
                var that = this;
                this.$el.delay(delay).animate({height:"0px"}, this.mode.animationCloseTime, ANIMATION_EASING, function() {
                    that.$el.removeClass(SHOWN_CLASS);
                });
                this.opened = false;
                // show configuration when notification is manually closed
                this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
            },

            /***** STATE FUNCTIONS *******/
            _setState: function (state, parameter) {
                // set state icon
                $(this.ui.icon).attr('class', state);
                // set state message
                this._setMessage(state, parameter);
                // set state activity
                this._setActivity(state);
                // call state action
                if (_.has(this.mode.actions, state)) {
                    this.mode.actions[state].call(this);
                }
                this.trigger(EVENTS.STATE_CHANGED, state);
            },
            _setMessage: function (state, parameter) {
                var message = this.mode.messages[state];
                if (!_.isEmpty(parameter)) {
                    message = message.replace("{0}", parameter);
                }
                $(this.ui.message).text(message);
            },
            _setActivity: function (state) {
                // change the notification activity area
                if (this.activityView) {
                    this.activityView.close();
                }
                if ((this.mode.activity) && (_.has(this.mode.activity, state))) {
                    this.activityView = this.mode.activity[state].call(this);
                    this.activityView.render();
                } else {
                    delete this.activityView;
                }
            },

            delayedClose: function () {
                this._closeNotification(CLOSE_DELAY);
            },

            /***** Configuration FUNCTIONS *******/
            configurationLinkClicked: function () {
                this.openDataSource();
            },
            openDataSource: function() {
                this.mode.actions.openDataSourceDialog.call();
            },

            /***** EVENT FUNCTIONS *******/
            onCloseButtonClicked: function () {
                this._closeNotification();
            }
        });

    });

    var MODES = {
        TRACKING_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.tracking"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.tracking"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.tracking"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.tracking"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isCartConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                        _apiCallback
                        , _apiErrorCallback
                    );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadShoppingCart);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        MARKETING_INFO_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.marketing.info"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.marketing.info"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.marketing.info"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.marketing.info"]})
           },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isMarketingInfoConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                        _apiCallback
                        , _apiErrorCallback
                    );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadCampaignInfo);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        CUSTOMER_INFO_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.customer.info"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.customer.info"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.customer.info"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.customer.info"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isCustomerInfoConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                            _apiCallback
                            , _apiErrorCallback
                        );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadCustomerInfo);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        VIEWED_PRODUCT_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.viewed.product"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.viewed.product"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.viewed.product"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.viewed.product"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isViewedProductConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                        _apiCallback
                        , _apiErrorCallback
                    );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadViewedProduct);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        SERVICE_ACTIVITY_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.service.activity"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.service.activity"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.service.activity"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.service.activity"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isServiceActivityConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                        _apiCallback
                        , _apiErrorCallback
                    );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadServiceActivity);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
		VISITOR_ERROR_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.visitor.error"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.visitor.error"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.visitor.error"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.visitor.error"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isVisitorErrorConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                        _apiCallback
                        , _apiErrorCallback
                    );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadVisitorError);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        TRANSACTION_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.transaction"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.transaction"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.transaction"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.transaction"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isTransactionConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                            _apiCallback
                            , _apiErrorCallback
                        );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadTransaction);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        GOAL_LEAD_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.goal.lead"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.goal.lead"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.goal.lead"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.goal.lead"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isLeadConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                            _apiCallback
                            , _apiErrorCallback
                        );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadLead);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        GOOGLE_ANALYTICS_CONNECTION: {
            messages: {
                init: LE.translator.translate('LEUIComponents.notification.layer.google.analytics.init'),
                connected: LE.translator.translate('LEUIComponents.notification.layer.google.analytics.connected'),
                disconnected: LE.translator.translate('LEUIComponents.notification.layer.google.analytics.disconnected'),
                error: LE.translator.translate('LEUIComponents.notification.layer.google.analytics.disconnected')
            },
            activity: {
                init: function () {
                    this.listenTo(LE.vent, EVENTS.SHOW_ERROR, function(errorParams) {
                        this.connectToGoogleButton.setError(errorParams.showError, errorParams.errorMsg);
                    });

                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    this.connectToGoogleButton = new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.google.disconnected.button'),
                        onClick : function() { that.openDataSource(); }
                    });
                    return this.connectToGoogleButton;
                },
                error: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    this.connectToGoogleButton = new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.google.disconnected.button'),
                        onClick : function() { that.openDataSource(); }
                    });
                    return this.connectToGoogleButton;
                }
            },
            actions: {
                init: function () {
                    var _apiSuccessCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    // listen to connection changed
                    this.listenTo(LE.context, 'change:googleAnalyticsConnectionStatus', function(){
                        LE.logger.info("Google analytics connection status changed to " + LE.context.get('googleAnalyticsConnectionStatus'), "AboutToAbandonCBView:_onConnectToGoogleButtonClicked");

                        // If current state is NOT_CONNECTED and the new state is connected - restart flow
                        var state = (LE.context.get('googleAnalyticsConnectionStatus')) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        if (this.state != state) {
                            this._setState(state);
                        }
                    });

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.connectionStatusGA
                        , {
                            context: this
                        }
                    ).then(
                         _apiSuccessCallback
                        , _apiErrorCallback
                    );
                },
                disconnected: function() {
                    if (!this.opened) {
                        this._openNotification();
                    }
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadDataSources);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        GOOGLE_ADWORDS_CONNECTION: {
            messages: {
                init: LE.translator.translate('LEUIComponents.notification.layer.google.init'),
                connected: LE.translator.translate('LEUIComponents.notification.layer.google.connected'),
                disconnected: LE.translator.translate('LEUIComponents.notification.layer.google.disconnected'),
                error: LE.translator.translate('LEUIComponents.notification.layer.google.disconnected')
            },
            activity: {
                init: function () {
                    this.listenTo(LE.vent, EVENTS.SHOW_ERROR, function(errorParams) {
                        this.connectToGoogleButton.setError(errorParams.showError, errorParams.errorMsg);
                    });

                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    this.connectToGoogleButton = new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.google.disconnected.button'),
                        onClick : function() { that.openDataSource(); }
                    });
                    return this.connectToGoogleButton;
                },
                error: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    this.connectToGoogleButton = new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.google.disconnected.button'),
                        onClick : function() { that.openDataSource(); }
                    });
                    return this.connectToGoogleButton;
                }
            },
            actions: {
                init: function () {
                    var _apiSuccessCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    // listen to connection changed
                    this.listenTo(LE.context, 'change:googleAnalyticsConnectionStatus', function(){
                        LE.logger.info("Google analytics connection status changed to " + LE.context.get('googleAnalyticsConnectionStatus'), "SearchKeywordsCBKwlView:_onConnectToGoogleButtonClicked");

                        // If current state is NOT_CONNECTED and the new state is connected - restart flow
                        var state = (LE.context.get('googleAnalyticsConnectionStatus')) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        if (this.state != state) {
                            this._setState(state);
                        }
                    });

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.connectionStatusGA
                        , {
                            context: this
                        }
                    ).then(
                        _apiSuccessCallback
                        , _apiErrorCallback
                    );
                },
                disconnected: function() {
                    if (!this.opened) {
                        this._openNotification();
                    }
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadDataSources);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        PERSONAL_INFO_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.personal.info"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.personal.info"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.personal.info"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.personal.info"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isPersonalInfoConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                            _apiCallback
                            , _apiErrorCallback
                        );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadPersonalInfo);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        },
        LOCATION_SECTION_CONNECTION: {
            messages: {
                init: LE.translator.translate({key: "LEUIComponents.notification.layer.init", args: ["LEUIComponents.notification.layer.location.section"]}),
                connected: LE.translator.translate({key: "LEUIComponents.notification.layer.connected", args: ["LEUIComponents.notification.layer.location.section"]}),
                disconnected: LE.translator.translate({key: "LEUIComponents.notification.layer.disconnected", args: ["LEUIComponents.notification.layer.location.section"]}),
                error: LE.translator.translate({key: "LEUIComponents.notification.layer.error", args: ["LEUIComponents.notification.layer.location.section"]})
            },
            activity: {
                init: function () {
                    return new Loader({
                        permutation: Loader.PERMUTATION.J,
                        el: this.ui.activity
                    });
                },
                disconnected: function () {
                    var that = this;
                    if (this.ui.activity.find(".but-container").length === 0) { // no button wrapper
                        this.ui.activity.append('<div class="but-container"></div>');
                    }
                    return new Button({
                        el: _.first(this.ui.activity.find(".but-container")),
                        permutation: Button.PERMUTATION.BTN_PRIMARY,
                        text: LE.translator.translate('LEUIComponents.notification.layer.disconnected.button'),
                        onClick : function () { that.openDataSource(); }
                    });
                }
            },
            actions: {
                init: function () {
                    var _apiCallback = function (options) {
                        var state = (options.isConnected) ? STATES.CONNECTED : STATES.DISCONNECTED;
                        this._setState(state);
                    };

                    var _apiErrorCallback = function () {
                        this._setState(STATES.ERROR);
                    };

                    LE.crossModule(
                        "manager"
                        , LE.media.actions.request
                        , LE.mediaCodes.manager.reqres.isLocationSectionsConnectedDS
                        , {
                            context: this
                        }
                    ).then(
                        _apiCallback
                        , _apiErrorCallback
                    );
                },
                connected: function() {
                    this.trigger(EVENTS.SHOW_CONFIGURATION_LINK);
                    this.delayedClose();
                },
                openDataSourceDialog: function() {
                    LE.crossModule("manager", LE.mediaCodes.manager.redirects.loadSection);
                }
            },
            states: { // set custom mode state
                DISCONNECTED: "disconnected",
                CONNECTED: "connected"
            },
            animationDelay: 500,
            animationOpenTime: 1000,
            animationCloseTime: 1000,
            notificationHeight: '62px'
        }
    };

    var STATES = {
        INIT: "init",
        ERROR: "error"
    };

    var EVENTS = {
        SHOW_CONFIGURATION_LINK: "showConfiguration",
        HIDE_CONFIGURATION_LINK: "hideConfiguration",
        STATE_CHANGED: "stateChanged",
        SHOW_ERROR: "showError"
    };

    NotificationLayerView.MODES = MODES;
    NotificationLayerView.EVENTS = EVENTS;
    return NotificationLayerView;
});
