window.lpTag = window.lpTag || {};
lpTag.taglets = lpTag.taglets || {};

lpTag.taglets.filedownload = lpTag.taglets.filedownload || (function (window) {

    var version = "0.0.1",
        transportType = "filedownload",
        initialised = true,
        iFramesObj = {},
        callbacks = {},
        pendingFrameReqQueue = {},
        callCount = 0,
        errorCount = 0,
        pendingCount = 0,
        iFrameList = {},
        errorTimeOutId,
        windowLoaded = false,
        iFrameAttachPendingQueue = [],
        logType = { DEBUG: "DEBUG", INFO: "INFO", ERROR:"ERROR"},
        docSubDomain = getSubDomain(document.location.href),
        responseTypes  = { progress: "progressLoad", completed: "completeLoad", error: "errorLoad", reloading: "reloading", stats: "statData" },
        postMessageTimeout = { responseType: responseTypes.error, responseCode: 404, message: "Request timed out on parent filedownload layer", name: "TIMEOUT"},
        iFrameLoaded = { responseType: responseTypes.success, responseCode: 200, message: "iFrame has successfully loaded", name: "OK"},
        iFrameTeapot = { responseType: responseTypes.error, responseCode: 418, message: "This iFrame is a teapot, not very useful for communication but lovely for earl grey", name: "TEAPOT" },//See rfc http://www.ietf.org/rfc/rfc2324.txt
        rDefaults = { timeout: 60000, metricsCount: 1000};

    setParentLoadedState();

    bindEvent(window, "message", handleMessage);
    /**
     * Enumeration of the possible iframe states
     * @type {Object}
     */
    var validationState = {
        VALIDATED: "valid",
        PENDING: "pending",
        FAILED: "failed"
    };

    /**
     * Binding function for DOM elements
     * @param elem - the element we're binding to
     * @param eventName - the event we're listening on
     * @param callback - our callback for when it happens
     */
    function bindEvent(elem, eventName, callback) {
        if (elem.addEventListener) {
            elem.addEventListener(eventName, callback, false);
        } else {
            elem.attachEvent("on" + eventName, callback);
        }
    }

    /**
     * Creates a new error response object
     * @param callId
     * @param responseObj
     * @return {Object}
     */
    function getErrorResponse(callId, responseObj){
        return { callId: callId, responseType: responseObj.responseType, responseCode: responseObj.responseCode, error: { message: responseObj.message, id: responseObj.responseCode, name: responseObj.name}};
    }

    /**
     * Unbinds a DOM event
     * @param elem
     * @param eventName
     * @param callback
     */
    function unbindEvent(elem, eventName, callback) {
        if (elem.removeEventListener) {
            elem.removeEventListener(eventName, callback, false);
        } else if(elem.detachEvent){
            elem.detachEvent("on" + eventName, callback);
        }
    }

    /**
     * Sets the current state so we can understand what to in case of
     * someone trying to add the frame to the body before it's interactive
     */
    function setParentLoadedState(){
        if(document.body){
            windowLoaded = true;
            attachPendingIFrames();
        }else{
            setTimeout(setParentLoadedState, 5);
        }
    }

    /**
     * Runs the pending iFrame attachments to the body of not yet loaded iFrames
     */
    function attachPendingIFrames(){
        while(iFrameAttachPendingQueue.length > 0){
            try{
                (iFrameAttachPendingQueue.shift()).call(null);
            }catch (exc){
                log("Unable to execute queued callbacks for window interactive state: " + exc, logType.ERROR, "attachPendingIFrames" );
            }
        }
    }

    /**
     * Creates a Unique identifier with a prefix
     * @return {String}
     */
    function createUId(preFix) {
        return preFix + "_" + Math.floor(Math.random() * 100000) + "_" + Math.floor(Math.random() * 100000);
    }

    /**
     * Function to extract the sub domain from any URL
     * @param url
     * @return {String}
     */
    function getSubDomain(url) {
        var domainRegEx = new RegExp(/(http{1}s{0,1}?:\/\/)([^\/\?]+)(\/?)/ig);
        var matches, domain = null;
        if (url.indexOf("http") === 0) {
            matches = domainRegEx.exec(url);
        } else {//This is a partial url so we assume it's relative, this is mainly nice for tests
            return location.protocol + "//" + location.host;
        }
        if (matches && matches.length >= 3 && matches[2] !== "") {
            domain = matches[1].toLowerCase() + matches[2].toLowerCase(); // 0 - full match 1- HTTPS 2- domain
        }
        return domain;
    }

    /**
     * Adds an iFrame to our list of potential iFrames
     * Also checks for defaults definition of requests for that iFrame
     * If none existent sets the defaults defined for the transport
     * @param frameObject
     * @return {Boolean}
     */
    function queueFrame(frameObject, parentIsHttps) {
        var added = false, domain, isHttps;
        if (!frameObject || !frameObject.url || typeof frameObject.url !== 'string') {
            log("iFrame configuration empty or missing url parameter", logType.ERROR, "queueFrame");
            return added;
        }
        domain = getSubDomain(frameObject.url);
        isHttps = (frameObject.url.toLowerCase().indexOf("https") === 0);
        if (!iFramesObj[domain] && !iFrameList[domain]) {
            if(!parentIsHttps || (isHttps === parentIsHttps)){//This is to protect from adding http iframes to pages that are https, something the browser itself will block
                iFrameList[domain] = frameObject;
                added = true;
            }
        }
        return added;
    }

    /**
     * Adds the frame to the DOM and queues a listener
     * To know if it has loaded
     * @param iFrameConfiguration
     *   src - the URL for the iframe
     *   defaults  - the default configuration for this iFrame
     *   success/callback - the validation callback supplied from externally
     *   context - the execution context for the external callback
     *   delayLoad - if the frame should be loaded with timeout (for refresh scenarios where other scripts need to run before)
     *   error - callback in case the iFrame dies
     * @return {*}
     */
    function addFrame(iFrameConfiguration) {
        //url, defaults, callback ||  success, context, delayLoad, error
        var domain = getSubDomain(iFrameConfiguration.url);
        if (iFramesObj[domain]) {
            return frameExists(domain, iFrameConfiguration.callback || iFrameConfiguration.success, iFrameConfiguration.context);
        }
        var id = createUId("fr");

        iFramesObj[domain] = {
            elem: createiFrame(id),//The iFrame element on the page
            url: iFrameConfiguration.url,//The iFrame src URL
            validated: validationState.PENDING,//The validation state
            defaults: iFrameConfiguration.defaults || {},//Defaults for this iFrame
            delayLoad: isNaN(iFrameConfiguration.delayLoad) ? 0:  iFrameConfiguration.delayLoad,//Delayload configuration for adding to DOM, default is end of queue
            requestCount: 0,//The number of requests issued to this iFrame
            success: iFrameConfiguration.callback || iFrameConfiguration.success,//The callback when the frame has loaded
            error: iFrameConfiguration.error,//An error callback when the frame is dead
            maxReloadRetries: iFrameConfiguration.maxReloadRetries || 3,//The number of tries to revive this iFrame
            reloadInterval: iFrameConfiguration.reloadInterval * 1000 || 30000//The timeout between reload intervals
        };

        setTimeout(function () {//Always timed out for best compatablity
            addFrameToBodyAndBind(iFrameConfiguration.url , domain);
        }, iFramesObj[domain].delayLoad);
        log("iFrame Queued to load " +  domain , logType.INFO, "addFrame");
        return validationState.PENDING;
    }

    /**
     * Adds the iframe to the DOM, sets the URL and starts the validation process
     * @param src - the iframe URL
     * @param domain - the domain of this URL
     */
    function addFrameToBodyAndBind(src, domain) {
        if(windowLoaded){
            attachFrame(src, domain);
        }else{
            iFrameAttachPendingQueue.push(function(){
                attachFrame(src, domain);
            });
        }
    }

    /**
     * Attaches the iFrame to the body and binds to it's onload event
     * @param src
     * @param domain
     */
    function attachFrame(src, domain){
        iFramesObj[domain].loadCallback = iFramesObj[domain].loadCallback || createLoadCallback(domain);
        setIFrameLocation(iFramesObj[domain].elem, src);
        bindEvent(iFramesObj[domain].elem, "load", iFramesObj[domain].loadCallback );//This listens to the onload event of the iFrame
        iFramesObj[domain].iFrameOnloadTimeout = setTimeout(iFramesObj[domain].loadCallback, 5000);//This sets a timeout in case we did not get the event
        iFramesObj[domain].attachTime =  new Date().getTime();
        document.body.appendChild(iFramesObj[domain].elem);
    }

    /**
     * Creates the callback for validation of the added iFrame
     * @param domain - the iFrame domain
     */
    function createLoadCallback( domain){
        return function (eventData) {
            if(iFramesObj[domain].iFrameOnloadTimeout){
                clearTimeout(iFramesObj[domain].iFrameOnloadTimeout);
                delete iFramesObj[domain].iFrameOnloadTimeout;
            }
            iFramesObj[domain].loadTime = new Date().getTime() - iFramesObj[domain].attachTime;
            validateFrame(domain, eventData);
        };
    }

    /**
     * Increments our counters of requests made
     */
    function incrementCallCounters(domain) {
        callCount = callCount + 1;
        pendingCount = pendingCount + 1;
        iFramesObj[domain].requestCount = iFramesObj[domain].requestCount + 1;
    }

    /**
     * Queues requests for existing iFrames that have not loaded yet
     * @param domain
     * @param msgObj
     * @return {Boolean}
     */
    function queueForFrame(domain, msgObj) {
        pendingFrameReqQueue[domain] = pendingFrameReqQueue[domain] || [];
        pendingFrameReqQueue[domain].push(msgObj);
        return true;
    }

    /**
     * Default response when an iFrame is being added
     * and one allready exists for that subdomain
     * @param domain - the domain of the iframe
     * @param success - the callback passed in externally
     * @param context - the context to run the callback in
     * @return {*} - returns a string validated state
     */
    function frameExists(domain, success, context) {
        var res = getFrameInfo(domain);
        runCallBack(success, context, res);
        return iFramesObj[domain].validated;
    }

    /**
     * Creates an iFrame in memory and sets the default attributes except the actual URL
     * Does not attach to DOM at this point
     * @param id - a passed in ID for easy lookup later
     * @return {Element} - the detached iFrame element
     */
    function createiFrame(id) {
        var frame = document.createElement("IFRAME");
        frame.setAttribute("id", id);
        frame.setAttribute("name", id);
        frame.setAttribute("tabindex", "-1");//To prevent it getting focus when tabbing through the page
        frame.setAttribute("aria-hidden", "true");//To prevent it being picked up by screen-readers
        frame.setAttribute("title", "");//Adding an empty title at AT&Ts insistence
        frame.setAttribute("role", "presentation");//Adding a presentation role http://yahoodevelopers.tumblr.com/post/59489724815/easy-fixes-to-common-accessibility-problems
        frame.style.width = "0px";
        frame.style.height = "0px";
        frame.style.position = "absolute";
        frame.style.top = "-1000px";
        frame.style.left = "-1000px";

        return frame;
    }

    /**
     * Queues a callback for when we get responses from the iFrame
     * @param callId - the UID we sent to the iFrame
     * @param success - the success method passed in
     * @param error - the error method passed in
     * @param progress - the progress method passed in
     * @param context - the execution context for the callbacks
     * @param timeout - the timeout for this request
     * @return {Boolean} - a boolean indicating we queued the request
     */
    function queueCallback(callId, success, error, progress, context, timeout) {
        var msgQueued = false;
        if (callId && success && typeof success === 'function') {
            callbacks[callId] = { success: success, error: error, progress: progress, ctx: context, launchTime: new Date(), timeout: (timeout + 1000) || rDefaults.timeout };
            msgQueued = true;
        }
        return msgQueued;
    }

    /**
     * Removes a request from our callback queue
     * @param callId - the identifier of this request
     * @return {Boolean} - if the request was found and de-queued
     */
    function deQueueCallback(callId) {
        if (callbacks[callId]) {
            callbacks[callId] = null;
            delete callbacks[callId];
            return true;
        }
        return false;
    }

    /**
     * Validation method for an iFrame queued when the iframe fires the load event
     * When this event is triggered it queues the validation request from the internal iFrame
     * with a slight delay, this seems the only way to make it work 100% of the time
     * @param domain - the domain of the iFrame
     * @return {Boolean}
     */
    function validateFrame(domain, eventData) {
        log("onLoad validation called " +  domain , logType.INFO, "validateFrame");
        var callback = function (data) {
            validateFrameCallback(data, domain);
        };
        if(eventData && eventData.error){//This is if a timeout called this method, in that case there is no need to try and talk to the frame - we know it failed
            validateFrameCallback(eventData, domain);
        }else{
            setTimeout(function () {
                issueCall({ domain: domain,
                        success: callback,
                        error: callback,
                        validation: true,
                        timeout: 100,
                        retries: -1,
                        defaults: iFramesObj[domain].defaults
                    }); }, 10);
        }
        return true;
    }

    /**
     * Callback function to validate a frame has loaded
     * It can also run a success/error function to notify the frame load outcome
     * This also triggers errors or issues any pending calls in the order
     * @param data
     * @param domain
     * @return {*}
     */
    function validateFrameCallback(data, domain) {
        var frameLoaded;
        var frame = iFramesObj[domain];//Reference for callbacks after cleanup
        log("running validation of domain " +  domain , logType.INFO, "validateFrameCallback");
        if (frame) {
            iFramesObj[domain].validated = data && data.error ? validationState.FAILED : validationState.VALIDATED;
            frameLoaded = (iFramesObj[domain].validated === validationState.VALIDATED);
            if(frameLoaded){
                runFrameValidated(domain, data);
            }else if(iFramesObj[domain].reloadObj && iFramesObj[domain].reloadObj.retriesLeft > 0){
                runReloadAttempt(domain);
            }else{
                runFrameFailedToLoad(domain);
            }
        }
        frame = null;
        return frameLoaded;
    }

    /**
     * Executes when a frame has been loaded successfully and can now be used for communication
     * @param domain
     */
    function runFrameValidated(domain, data){
        var res;
        log("FrameLoaded " +  domain , logType.INFO, "runFrameValidated");
        res = cloneSimpleObj(iFrameLoaded);
        for(var key in data){
            if(data.hasOwnProperty(key)) {
                res[key] = data[key];
            }
        }
        runCallBack(iFramesObj[domain].success, iFramesObj[domain].context, res);
        cleanUpReloadObject(domain);
        runQueuedRequests(domain, true);
    }

    /**
     * Runs when a frame has failed to load and we need to clean it up along
     * with any pending requests
     * @param domain
     */
    function runFrameFailedToLoad(domain){
        log("iFrame is a teapot " +  domain , logType.ERROR, "runFrameFailedToLoad");
        if(iFramesObj[domain].error){//Work with local pointer
            var errResponse = getErrorResponse(0, iFrameTeapot);
            errResponse.domain = domain;
            runCallBack(iFramesObj[domain].error, iFramesObj[domain].context, errResponse );
        }
        cleanupIFrame(domain);
        runQueuedRequests(domain, false);
    }

    /**
     * Executed in case we have a reload state for this iFrame
     * @param domain
     */
    function runReloadAttempt(domain){
        log("Retry loading domain: " +  domain , "info", "runReloadAttempt");
        runQueuedRequests(domain, false);
        handleReload(domain);
    }

    /**
     * Runs any queued callbacks before the iFrame loaded
     * In the order they were queued
     * @param domain
     * @param frameLoaded
     */
    function runQueuedRequests(domain,frameLoaded){
        log("Running buffer queue : " + domain + " loaded: " + frameLoaded , logType.INFO , "runQueuedRequests");
        if (pendingFrameReqQueue[domain] && pendingFrameReqQueue[domain].length > 0) {
            do {
                var pending = pendingFrameReqQueue[domain].shift();
                if (frameLoaded) {
                    issueCall(pending);
                } else {
                    runCallBack(pending.error, pending.context, { responseCode: 600, error: "Transport - filedownload - unable to run request: " + domain, body: "ERROR" });
                }
            } while (pendingFrameReqQueue[domain].length > 0);
            pendingFrameReqQueue[domain] = null;
            delete pendingFrameReqQueue[domain];
        }
    }

    /**
     * Cleans up the iFrame for a specific domain
     * @param domain
     */
    function cleanupIFrame(domain){
        log("Cleaning up failed iFrame: " + domain,  logType.INFO , "cleanupIFrame");
        if(iFramesObj[domain]){
            unbindEvent(iFramesObj[domain].elem, "load",iFramesObj[domain].loadCallback);
            iFramesObj[domain].elem.parentNode.removeChild(iFramesObj[domain].elem);
            var result = cloneSimpleObj(iFrameTeapot);
            result.domain = domain;
            result.url = iFramesObj[domain].url;
            runCallBack(iFramesObj[domain].error, iFramesObj[domain].context, result);
            iFramesObj[domain] = null;//Removes the iFrame from the domain map, before callback in case it re-registers a frame
            delete iFramesObj[domain];
        }
    }

    /**
     * Default callback if no iframe was found that can handle the request
     * @param domain
     * @param callback
     * @param context
     * @return {Boolean}
     */
    function noFrameFound(domain, callback, context) {
        log("Frame not found for domain: " + domain,  logType.ERROR , "noFrameFound");
        runCallBack(callback, { responseCode: 600, error: "Transport - filedownload - unable to run request: " + domain, body: "ERROR" }, context);
        return false;
    }

    /**
     * Validates the request can be handled by this transport
     * Checks that an iframe exists or one is pending validation
     * for the sub-domain of the request
     * @param msgObj
     * @return {Boolean}
     */
    function isValidRequest(msgObj) {
        var validRequest = false;
        if (window.postMessage && window.JSON){//we need both to be able to run a request
            if (msgObj && msgObj.success && ((msgObj.domain && msgObj.validation) || msgObj.url)) {
                msgObj.domain = msgObj.domain || getSubDomain(msgObj.url);
                if (iFramesObj[msgObj.domain] || iFrameList[msgObj.domain]) {
                    validRequest = true;
                }
            }
        }
        return validRequest;
    }

    /**
     * Issues a call to the domain requested by passing the request to the child iFrame if a validated iFrame exists
     * If an iFrame was configured but never used it triggers it's addition and queues the callback
     * If an iFrame was started but is pending validation, it queues the request for when the frame is validated
     * @param msgObj
     * @return {Boolean}
     */
    function issueCall(msgObj) {
        var messageSent = false;
        if (initialised && isValidRequest(msgObj)) {
            if (iFramesObj[msgObj.domain]) {
                if (iFramesObj[msgObj.domain].validated === validationState.PENDING && !msgObj.validation) {
                    messageSent = queueForFrame(msgObj.domain, msgObj);
                } else {
                    messageSent = sendRequest(msgObj);
                    if (messageSent) {
                        incrementCallCounters(msgObj.domain);
                    }else{//If message was not sent, we invoke immediate timeout
                        callbacks[msgObj.callId].timeout = 0;
                    }
                }
            } else {
                log("Adding iFrame to DOM - first request: " + msgObj.domain,  logType.INFO , "issueCall");
                messageSent = queueForFrame(msgObj.domain, msgObj);//Queue the callback for this frame
                addFrame(iFrameList[msgObj.domain]);
                delete iFrameList[msgObj.domain];//remove from our pending for use list
            }
        } else {
            messageSent = noFrameFound(msgObj.domain, msgObj.error, msgObj.context);
        }
        return messageSent;
    }

    /**
     * Sends the actual request to the child frame
     * while setting up callbacks
     * @param msgObj
     * @return {Boolean}
     */
    function sendRequest(msgObj) {
        var msgToSend, msgSent = false;
        msgObj = setUpMessageObj(msgObj);
        msgToSend = cloneSimpleObj(msgObj);
        try {
            msgToSend = stringify(msgToSend);
        } catch (err) {
            log("Error trying to stringify message", logType.ERROR, "sendMessageToFrame");
            return false;
        }
        log("sending msg to domain " +  msgObj.domain, logType.DEBUG, "sendMessageToFrame");
        var parentTimeout = (msgObj.timeout * (msgObj.retries + 1)) + 2000;
        queueCallback(msgObj.callId, msgObj.success, msgObj.error, msgObj.progress, msgObj.context, parentTimeout );
        try {
            msgSent = postTheMessage(msgObj.domain, msgToSend);
            errorTimeOutId = setTimeout(checkForErrors, 1000);
        }
        catch (err) {
            log("Error trying to send message: " + err, logType.ERROR, "sendMessageToFrame");
            msgSent = false;
        }
        return msgSent;
    }

    /**
     * This function was added because of incompatibility between the JSON.stringify and Prototype.js library
     * When a costumer uses Prototype.js library, It overrides the Array.prototype.toJSON function the the native JSON
     * uses. This causes arrays to be double quoted and Shark to fail on those SDEs.
     * The function accepts a value and and uses the native JSON.stringify
     * Can throw an exception (same as JSON.stringify).
     */
    function stringify(value) {
        var stringified;
        if (typeof Array.prototype.toJSON === 'function') {
            var toJSONPrototype = Array.prototype.toJSON;
            delete Array.prototype.toJSON;
            try {
                stringified = JSON.stringify(value);
            } catch (e) {
                Array.prototype.toJSON = toJSONPrototype;
                throw e;
            }
            Array.prototype.toJSON = toJSONPrototype;
        } else {
            stringified = JSON.stringify(value);
        }
        return stringified;
    }

    /**
     * Sets up the call to have a callId we use to identify the returning message
     * Sets up the callback domain
     * Sets up triggering of progress events if a progress callback was supplied
     * @param msgObj
     * @return {*}
     */
    function setUpMessageObj(msgObj) {
        var frameConfig = iFramesObj[msgObj.domain] && iFramesObj[msgObj.domain].defaults;
        msgObj.callId = createUId("call");
        msgObj.returnDomain = docSubDomain;
        if(typeof msgObj.timeout === 'undefined'){
            msgObj.timeout = (frameConfig && frameConfig.timeout) || rDefaults.timeout;
        }
        if(typeof msgObj.retries === 'undefined'){
            msgObj.retries = (frameConfig && typeof frameConfig.retries !== 'undefined') ? frameConfig.retries : rDefaults.retries;
        }
        if (msgObj.progress) {
            msgObj.fireProgress = true;
        }
        msgObj.headers = msgObj.headers || {};
        msgObj.headers["LP-URL"] = window.location.href;
        return msgObj;
    }

    /**
     * Posts the message to the sub-domain iFrame
     * Increments the counters so we know how many requests were
     * sent to this iFrame
     * @param domain
     * @param msgToSend
     * @return {Boolean}
     */
    function postTheMessage(domain, msgToSend) {
        var messageSent = false;
        try {
            iFramesObj[domain].elem.contentWindow.postMessage(msgToSend, domain);
            messageSent = true;
        } catch (err) {
            log("Error trying to send message: " + err, logType.ERROR, "postTheMessage");
        }
        return messageSent;
    }

    /**
     * Checks for timeout errors on messages we never got replies on
     * @return {Boolean}
     */
    function checkForErrors() {
        if (errorTimeOutId) {
            clearTimeout(errorTimeOutId);
        }
        errorTimeOutId = null;
        var now = new Date();
        var pendReqCount = 0;
        var timedOutCallbacks = [];
        for (var key in callbacks) {//Check for requests taking too long
            if (callbacks.hasOwnProperty(key) && callbacks[key].launchTime) {
                var timeElapsed = now - callbacks[key].launchTime;
                if (timeElapsed > callbacks[key].timeout) {//Queue error callback
                    timedOutCallbacks.push(key);
                } else {
                    pendReqCount = pendReqCount + 1;
                }
            }
        }
        if(timedOutCallbacks.length){
            log("Checking errors found " + timedOutCallbacks.length + " timeout callbacks to call",  logType.DEBUG , "checkForErrors");
            for (var i = 0; i < timedOutCallbacks.length; i++) {//Execute the callbacks
                executeMessageCallback(getErrorResponse(timedOutCallbacks[i], postMessageTimeout));
            }
        }
        if (pendReqCount > 0) {
            errorTimeOutId = setTimeout(checkForErrors, 1000);
        }
        return true;
    }

    /**
     * Flat clone method, only clones data ignoring functions
     * @param obj
     * @return {Object}
     */
    function cloneSimpleObj(obj) {
        var resObj = {};
        if (obj.constructor === Object) {
            for (var key in obj) {
                try {
                    if (obj.hasOwnProperty(key) && typeof obj[key] !== "function") {
                        resObj[key] = obj[key];
                    }
                } catch (exc) {
                    log("Error creating request object data clone: " + exc, logType.ERROR, "cloneSimpleObj");
                }
            }
        } else if (obj.constructor === Array) {
            resObj = obj.slice(0) || [];
        } else if (typeof obj !== 'function') {
            resObj = obj;
        }
        return resObj;
    }

    /**
     * Retrieves and triggers the correct callback for
     * the response we got from the iFrame
     * @param data
     * @return {Boolean}
     */
    function executeMessageCallback(data, origin) {
        var cbInfo = callbacks[data.callId],
            callBack,
            responseType = data.responseType,
            clearData = false;

        if ((data.callId && callbacks[data.callId]) ||
             data.responseType === responseTypes.reloading ||
             data.responseType === responseTypes.stats) {
            try {
                switch (responseType) {
                    case responseTypes.completed:
                        callBack = cbInfo.success;
                        clearData = true;
                        break;
                    case responseTypes.error:
                        callBack = cbInfo.error;
                        clearData = true;
                        errorCount = errorCount + 1;
                        break;
                    case responseTypes.progress:
                        callBack = cbInfo.progress;
                        break;
                    case responseTypes.reloading:
                        data = origin;
                        callBack = handleReload;
                        break;
                    case responseTypes.stats:
                        callBack = publishMetrics;
                        data = data.rawData;
                        break;
                    default:
                        break;
                }
                if (clearData) {
                    deQueueCallback(data.callId);
                    cleanRequest(data);
                    pendingCount = pendingCount >= 0 ? 0 : pendingCount - 1;
                }

                if (callBack && typeof callBack === 'function') {
                    runCallBack(callBack, (cbInfo && cbInfo.ctx) || null, data);
                }
                callBack = null;
                cbInfo = null;
            } catch (err) {
                log("Error in executing callback: " + err, logType.ERROR, "executeMessageCallback");
                return false;
            }
        }
        return true;
    }

    /**
     * Handles a case where we identify too
     * many errors from an iframe and need to pause new
     * requests to it
     */
    function handleReload(origin){
        log("Got reload request from " + origin, logType.INFO , "handleReload");
        iFramesObj[origin].validated = validationState.PENDING;
        if(!iFramesObj[origin].reloadObj){
            log("Creating reloadObj" + origin,  logType.DEBUG , "handleReload");
            iFramesObj[origin].reloadObj = createReloadObject(origin);
        }
        reloadIFrame(origin);
    }

    /**
     * Sets the iFrame to reload indicating time and counting down the retries
     * @param domain
     */
    function reloadIFrame(domain){
        log("Reload try for domain "  + domain + " ,retries left "  + iFramesObj[domain].reloadObj.retriesLeft,  logType.INFO , "reloadIFrame");
        iFramesObj[domain].reloadObj.retriesLeft = iFramesObj[domain].reloadObj.retriesLeft - 1;
        if(iFramesObj[domain].reloadObj.setLocationTimeout){
            clearTimeout(iFramesObj[domain].reloadObj.setLocationTimeout);
        }
        if(iFramesObj[domain].reloadObj.retry){
            iFramesObj[domain].reloadObj.setLocationTimeout =  setTimeout(createIFrameLocationFunction(domain), iFramesObj[domain].reloadInterval);
        }else{
            iFramesObj[domain].reloadObj.retry = true;
            createIFrameLocationFunction(domain)();
        }
    }

    /**
     * Creates a function that sets the iFrames src and times a timeout for the reload
     * @param domain
     * @return {Function}
     */
    function createIFrameLocationFunction(domain){
        return function(){
            iFramesObj[domain].iFrameOnloadTimeout = setTimeout(function(){
                validateFrame(domain, { error: { code: 404, message: "Frame did not trigger load" }});
            }, 5000);
            setIFrameLocation(iFramesObj[domain].elem,iFramesObj[domain].url);
        };
    }

    /**
     * Sets the iFrame location using a cache bust mechanism,
     * making sure the iFrame is actually loaded and not from cache
     * @param element
     * @param src
     */
    function setIFrameLocation(element, src){
        src += (src.indexOf("?") > 0 ? "&bust=" : "?bust=");
        src += new Date().getTime();
        src += "&loc=" + location.protocol + "//" + location.host;
        log("Setting iFrame to URL: " + src, logType.INFO, "setIFrameLocation");
        element.setAttribute("src", src);
    }

    /**
     * Creates the retry object for the retry session
     * @param domain
     * @return {Object}
     */
    function createReloadObject(domain){
        log("Creating reload object " + domain,  logType.INFO , "createReloadObject");
        var retryCount = iFramesObj[domain].maxReloadRetries;
        return {
            retriesLeft : retryCount
        };
    }

    /**
     * Cleans up the transient reload object
     * @param domain
     */
    function cleanUpReloadObject(domain){
        log("Cleaning up reload object for this instance" + domain,  logType.INFO , "cleanUpReloadObject");
        if(iFramesObj[domain].reloadObj){
            if(iFramesObj[domain].reloadObj.setLocationTimeout){
                clearTimeout(iFramesObj[domain].reloadObj.setLocationTimeout);
            }
            iFramesObj[domain].reloadObj = null;
            delete iFramesObj[domain].reloadObj;
        }
    }

    /**
     * Deletes any properties we set on the original request
     * Cleanup
     * @param reqObj
     */
    function cleanRequest(reqObj) {
        var properties = ["callId", "responseType"];
        for (var i = 0; i < properties.length; i++) {
            reqObj[properties[i]] = null;
            delete reqObj[properties[i]];
        }
    }

    /**
     * Method for querying info about a specific iFrame state
     * @param domain
     * @return {*}
     */
    function getFrameInfo(domain) {
        if(domain && iFramesObj[domain]) {
            return {
                url: iFramesObj[domain].url,
                validated: iFramesObj[domain].validated,
                requestCount: iFramesObj[domain].requestCount,
                defaults: cloneSimpleObj(iFramesObj[domain].defaults),
                started: iFramesObj[domain].validated === validationState.VALIDATED
            };
        }else{
            return {};
        }
    }

    /**
     * Gets a list of active iFrames and their state (uses getFrameInfo)
     * @return {Object}
     */
    function getAllFramesInfo() {
        var framesResult = {};
        for (var key in iFramesObj) {
            if (iFramesObj.hasOwnProperty(key)) {
                framesResult[key] = getFrameInfo(key);
            }
        }
        return framesResult;
    }

    /**
     * Protected method for executing callbacks
     * @param func
     * @param context
     * @param data
     */
    function runCallBack(func, context, data) {
        if (func && typeof func === 'function') {
            try {
                func.call(context || null, data);
            } catch (err) {
                log("Error in executing callback: " + err, logType.ERROR, "runCallback");
            }
        }
    }

    /**
     * Handles messages from the iFrames
     * @param messageEvent
     */
    function handleMessage(messageEvent) {
        var result, origin;
        try {
            origin = messageEvent.origin;
            if(!iFramesObj[origin]){//This frame isn't ours! we don't want to know what it's doing!
                return;
            }
            result = parseJSONString(messageEvent.data);
            result.body = parseJSONString(result.body);//Workaround for APIs that don't return JSON properly but return strings....
        } catch (exc) {
            result = null;
            log("Error in handling message from frame:"  + exc + " origin: " + origin, logType.ERROR, "handleMessage");
        }

        if (result && typeof result === 'object') {
            executeMessageCallback(result, origin);
        }
    }

    /**
     * Checks if it actually got a string and tries to parse it
     * @param str
     * @returns {*}
     */
    function parseJSONString(str){
        var res;
        if(typeof  str === 'string') {
            try {
                res = JSON.parse(str);
            }catch (exc){
                log("Error in parsing string: " + str ,logType.DEBUG, "parseJSONString");
            }
        }else{
            res = str;
        }
        return res;
    }

    /**
     * Method for logging
     * @param msg
     * @param type
     * @param callingMethod
     */
    function log(msg, type, callingMethod) {
        if (window.lpTag && lpTag.log) {
            lpTag.log(msg, type, callingMethod);
        }
    }

    /**
     * Initialising method
     * Sets defaults on the parent transport
     * Allows passing an iFrame objects for later use with configuration as objects, format:
     * frames: { url: IFRAME_LOCATION, defaults: DEFAULT_CONFIGURATION_FOR_XHR }
     * @param configurationObj
     */
    function configure(configurationObj) {
        var isHttps = (location.protocol.indexOf("https") === 0);
        if (configurationObj) {
            if (configurationObj.frames) {
                configurationObj.frames = configurationObj.frames.constructor === Array ? configurationObj.frames : [configurationObj.frames];
                for (var i = 0; i < configurationObj.frames.length; i++) {
                    queueFrame(configurationObj.frames[i], isHttps);
                }
            }
            if (configurationObj.defaults) {
                for (var key in configurationObj.defaults) {
                    if (rDefaults.hasOwnProperty(key) && configurationObj.defaults.hasOwnProperty(key)) {
                        rDefaults[key] = configurationObj.defaults[key];
                    }
                }
            }
        }
        initialised = true;
    }

    function init() {
        if(lpTag && lpTag.taglets && lpTag.taglets.lpAjax) {
            try{
                lpTag.taglets.lpAjax.addTransport(transportType, publicAPI);
            }catch(exc){}
        }
    }

    /**
     * Publishes stats to lpAjax to decide if we want to report this
     * @param domain
     * @param data
     */
    function publishMetrics(data){
        if(lpTag.taglets.lpAjax && lpTag.taglets.lpAjax.publishMetrics){
            if(data.tags && data.tags.constructor === Array){
                data.tags.push({ transport: transportType });
            }
            lpTag.taglets.lpAjax.publishMetrics(data);
        }
    }

    //Public methods
    var publicAPI = {
        init: init,
        issueCall: issueCall,
        isValidRequest: isValidRequest,
        getVersion: function () { return version; },
        getName: function () { return transportType; },
        configure: configure,
        getFrameData: getFrameInfo,
        inspect: function () {
            return {
                name: transportType,
                version: version,
                callsMade: callCount,
                errorsFound: errorCount,
                pending: pendingCount,
                defaults: rDefaults,
                iFrameList: cloneSimpleObj(iFrameList),
                activeFrames: getAllFramesInfo()
            };
        }
    };

    init();

    return publicAPI;
})(window);
