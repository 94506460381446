<template>
  <div class="ftue-header">
    <div class="ftue-header-text">
      {{ parsedTitle }}
    </div>
    <div
      class="ftue-header-close"
      tabindex="0"
      role="button"
      @click="closeModal()"
      @keydown.enter.space="closeModal()"
    >
      <svg
        class="ftue-header-close-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          fill="currentColor"
          d="M14.9,4.9c-0.4-0.4-1-0.4-1.4,0L9.9,8.5L6.4,4.9C6,4.6,5.3,4.6,5,4.9C4.6,5.3,4.6,6,5,6.4l3.5,3.5L5,13.4
      	c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l3.5-3.5l3.5,3.5c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-3.5-3.5l3.5-3.5
      	C15.2,6,15.2,5.3,14.9,4.9z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { gChannel } from 'vue-infra';

export default {
  name: 'FirstTimeHeader',
  props: {
    title: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      parsedTitle: this.title ? this.$translator.translate(this.title) : ''
    };
  },
  created() {
    gChannel.register('LiveEngageCommon');
  },
  methods: {
    closeModal() {
      gChannel.trigger('LiveEngageCommon', 'FirstTimeExperienceModal:close');
      gChannel.trigger('LiveEngageCommon', 'ShowNotification', {
        helpText: 'LEFramework.firstTimeExperience.returnAnytime',
        autoCloseNotification: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ftue-header {
  @include themify {
    background-color: getColor('ftue-header-color', 'framework');
    box-shadow: 0px 4px 4px getColor('ftue-header-shadow', 'framework');
  }
  display: flex;
  justify-content: space-between;
  padding: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: white;
  letter-spacing: 0.01em;
  position: relative;
}
.ftue-header-text {
  flex: 1;
  padding-right: 24px;
}
.ftue-header-close {
  cursor: pointer;
}
.ftue-header-close-icon {
  color: white;
  width: 20px;
  height: 22px;
  display: block;
}
</style>
