define(function (require) {
    "use strict";

    var JwtUtility = (function () {
        return {
            isJwtFormat: function(token){
                token = token || "";
                return token.match(/^([a-zA-Z0-9\-_]+)?\.([a-zA-Z0-9\-_]+)?\.([a-zA-Z0-9\-_]+)?$/).length === 4;
            },

            decodeJwt: function(token){
                var payload = token.split(".")[1];
                var base64 = payload.replace('-', '+').replace('_', '/');
                return JSON.parse(window.atob(base64));
            }
        };
    })();
    return JwtUtility;

});
