/**
 * Created with IntelliJ IDEA.
 * User: itaik
 * Date: 2/20/13
 * Time: 10:03 AM
 * To change this template use File | Settings | File Templates.
 *
 *
 * This is used for registering new modules in the framework as long as the
 * registered modules use the framework modules structure conventions - as follows:
 * inside 'src/modules' dir:
 *  + module-name
 *      + assets
 *          + css
 *          + img
 *          + js
 *              + lib
 *          + templates
 *      + src
 *          + collections
 *          + controllers
 *          + layouts
 *          + models
 *          + views
 *          moduleName.js
 *          config.js
 *      + test
 *          + unit-tests
 *              + collections
 *              + models
 *              + views
 *              init.js
 *
 */

var modules = [
  {
    // the data module must be first
    id: 'dataModule',
    moduleName: 'LEDataModule',
    folderName: 'data-module',
    regionId: 'LEDataModuleNA',
    container: 'LEDataModuleNA',
    routePrefix: 'LEDataModuleNA',
    personas: ['agent', 'messagingAgent', 'marketer', 'admin', 'agentManager'],
    lazy: false,
    autostart: true,
    coreComponentsSupportLevel: 1,
  },
  {
    id: 'manager',
    moduleName: 'LEManager',
    folderName: 'manager',
    regionId: 'manager',
    container: 'work-area-wrapper',
    template: 'workAreaItem',
    routePrefix: 'mng',
    personas: ['agent', 'messagingAgent', 'marketer', 'admin', 'agentManager'],
    lazy: true,
    autostart: true,
    coreComponentsSupportLevel: 2,
  },
  {
    id: 'webagent',
    moduleName: 'LEWebAgent',
    folderName: 'web-agent',
    regionId: 'web-agent',
    container: 'work-area-wrapper',
    template: 'workAreaItemNoLEClass',
    routePrefix: 'wa',
    personaMapping: 'agent',
    personas: ['agent', 'messagingAgent', 'agentManager'],
    lazy: true,
    autostart: true,
    transition: true,
    switcher: {
      control: 'switcher-item-agent',
      fontIconName: 'ico-agent',
      index: 100,
    },
  },
  {
    id: 'campaigns',
    moduleName: 'LECampaigns',
    folderName: 'campaigns',
    regionId: 'campaigns',
    container: 'work-area-wrapper',
    template: 'workAreaItem',
    routePrefix: 'camp',
    personaMapping: 'marketer',
    personas: ['marketer'],
    lazy: true,
    autostart: true,
    transition: true,
    coreComponentsSupportLevel: 2,
    switcher: {
      control: 'switcher-item-marketer',
      fontIconName: 'ico-marketer',
      index: 200,
    },
  },
  {
    id: 'reporting',
    moduleName: 'LEReporting',
    folderName: 'reporting',
    regionId: 'reporting',
    container: 'reporting-wrapper',
    template: 'reportingItem',
    routePrefix: 'data',
    personas: ['agent', 'messagingAgent', 'marketer', 'admin', 'agentManager'],
    lazy: true,
    autostart: true,
    coreComponentsSupportLevel: 1,
  },
  {
    id: 'userManagement',
    moduleName: 'LEUserManagement',
    folderName: 'user-management',
    regionId: 'user-management',
    container: 'work-area-wrapper',
    template: 'workAreaItem',
    routePrefix: 'um',
    personaMapping: 'admin',
    personas: ['admin'],
    lazy: true,
    autostart: true,
    transition: true,
    coreComponentsSupportLevel: 2,
    hasExternalModuleTranslationDictionary: true,
    switcher: {
      control: 'switcher-item-agent-mngt',
      fontIconName: 'ico-agent-mngt',
      index: 300,
    },
  },
  {
    id: 'content',
    moduleName: 'LEContent',
    folderName: 'content',
    regionId: 'content-management',
    container: 'work-area-wrapper',
    template: 'workAreaItem',
    routePrefix: 'cont',
    personas: ['marketer', 'admin'],
    lazy: true,
    autostart: true,
    coreComponentsSupportLevel: 2,
  },
  {
    id: 'connection',
    moduleName: 'LEConnection',
    folderName: 'connection',
    regionId: 'connection-bar',
    container: 'connection-bar-wrapper',
    template: 'connectionItem',
    routePrefix: 'con',
    transition: false,
    personas: ['agent', 'messagingAgent', 'marketer', 'guest', 'admin', 'agentManager'],
    lazy: true,
    autostart: true,
    coreComponentsSupportLevel: 1,
  },
  {
    id: 'personalization',
    moduleName: 'LEPersonalization',
    folderName: 'personalization',
    regionId: 'personalization',
    container: 'personalization-bar-wrapper',
    template: 'personalizationItem',
    routePrefix: 'pers',
    personas: ['customizer'],
    lazy: true,
    autostart: true,
    coreComponentsSupportLevel: 2,
  },
];

var register = function (moduleConfig) {
  modules.unshift(moduleConfig);
};

var registerExternal = function (moduleConfig) {
  register(Object.assign({}, moduleConfig, {
    // mark as an external module
    external: true,
    // provide internal properties that external modules should not have to pass
    regionId: `${moduleConfig.id}-external`,
    container: 'work-area-wrapper',
    template: 'workAreaItemNoLEClass',
  }));
};

var unregister = function (moduleId) {
  modules.splice(modules.indexOf(m => m.id === moduleId), 1);
};

var resolveInternalModules = function () {
  return modules.filter(m => !m.external);
};

var getModuleByPersonaMapping = function (personaMapping) {
  return modules.find(m => m.personaMapping === personaMapping);
};


define({
  modules,
  register,
  unregister,
  registerExternal,
  resolveInternalModules,
  getModuleByPersonaMapping,
});
