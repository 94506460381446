define(function (require) {
    "use strict";

    var LE = require("app");
    var Config = require("data-assets/config/dataConfig.json");
    var RefreshModel = require("models/RefreshModel");

    var ApiResources = require("assets/data/apiResources.json");

    var KEYS = {
        "AGENT_GROUP_IDS": "agentGroups",
        "SKILL_IDS": "skillList",
        "USER_IDS": "userList",
        "USERS": "userList"
    };
    /**
     * Model to fetch periodically extra data associated with AgentManager like the list of skills managed by the current Agent Manager
     * The list of Groups associated with it etc ...
     *
     */
    var AgentManagerDependencyModel = {};
    LE.module("LEDataModule", function (LEDataModule, LE, Backbone, Marionette, $, _) {

        AgentManagerDependencyModel = RefreshModel.extend({
            KEYS: KEYS,
            resource: ApiResources.AccountConfig.AgentManagerGroupData,
            use304Implementation: true,
            name: "AgentManagerDependencyModel",
            defaults: {
                agentGroups: [],
                skillList: [],
                userList: []
            },
            initialize: function (options) {
                options = options || {};
                _.extend(options,Config.AgentManagerDependencyRefreshConfig);
                if (options.getLatest) {
                    this.resource =  ApiResources.AccountConfig.AgentManagerGroupDataV3;
                }

                RefreshModel.prototype.initialize.call(this, options);
            },

            fetch: function(options) {
                options = options || {};
                var newOptions;
                if (options.fetchOptions) {
                    newOptions = _.extend(options, {
                        data: options.fetchOptions.data
                    });
                } else {
                    newOptions = options;
                }
                RefreshModel.prototype.fetch.call(this, newOptions);
            },

            /**
             * Get all skills of the agents
             * Found in all the groups and the sub groups down to their leaves of this AgentManager
             * @returns {*}
             */
            getSkills: function() {
                return this.get(KEYS.SKILL_IDS);
            },
            /**
             * Get All the groups and sub groups to their sub sub sub groups of this agentManagaer
             * @returns {*}
             */
            getGroups: function() {
                return this.get(KEYS.AGENT_GROUP_IDS);
            },
            /**
             * Get All the agents under all the groups and sub groups to their sub sub sub sub sub sub sub sub groups of this agentManagaer
             * @returns {*}
             */
            getAgents: function() {
                return this.get(KEYS.USER_IDS);
            }

        });


    });


    return AgentManagerDependencyModel;
});
