define(function (require) {
    "use strict";

    var _ = require("underscore");
    var Marionette = require("marionette");
    var template = require("ui.components/special-occasion-picker/templates/specialOccasionPickerItemData.tmpl");
    var ScheduleUtilities = require("utils/ScheduleUtilities");
    var Translator = require("i18n/translator");

    var SpecialOccasionPickerItemDataView = Marionette.ItemView.extend({
        className: "special-occasion-picker-item-data-container",
        template: template,

        customTemplateHelpers: function() {
            return {
                details: this._getDetails(),
                timezone: this._getTimezone()
            };
        },

        initialize: function(options) {
            this._initialize(options);
        },
        onRender: function() {
            this._onRender();
        },

        _initialize: function(options) {
            options = options || {};

            this.container = options.container;
            this.model = _.has(options, "model") ? options.model : null;
        },
        _onRender: function() {
            if (!_.isEmpty(this.container)) {
                this.container.append(this.$el);
            }
        },
        _getDetails: function() {
            var details = "";
            var totalEvents = this.model.getEventsCollection().size();
            if (totalEvents === 1) {
                details = Translator.translate("LEUIComponents.specialOccasionPicker.occasions.single");
            }
            else {
                details = Translator.translate({
                    key: "LEUIComponents.specialOccasionPicker.occasions.multiple",
                    args: [{key: totalEvents, isTranslate:false }]
                });
            }
            return details;
        },
        _getTimezone: function() {
            var timezone = this.model.getTimezone();
            return window.LE.siteSettings.timeZoneSettings.getCurrentTimeZoneTranslated(timezone);
        }
    });

    return SpecialOccasionPickerItemDataView;

});
