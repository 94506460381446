import _ from 'lodash';
import UrlResolver from 'urlResolver';
import RouteNameBuilder from './RouteNameBuilder';
import { environmentGetterTypes, Logger, sessionManager, storeModuleTypes, store } from 'vue-infra';
import LEConfig from 'assets/config/le-env-conf';


const logger = Logger.getLogger('LEFramework');


const escapeHash = {
  data(input) {
    let ret = escapeHash[input];
    if (!ret) {
      if (input.length - 1) {
        const len = input.length - 3;
        const pos = len ? 2 : 1;
        ret = String.fromCharCode(parseInt(input.substring(pos), 16));
      } else {
        const code = input.charCodeAt(0);
        ret = code < 256 ? `%${(0 + code.toString(16)).slice(-2).toUpperCase()}` : `%u${(`000${code.toString(16).slice(-4).toUpperCase()}`)}`;
      }
      escapeHash[ret] = input;
      escapeHash[input] = ret;
    }

    return ret;
  },
};

function resolveModuleRoutePrefix(persona) {
  let moduleRoutePrefix = '';
  const localPersona = persona || sessionManager.getActivePersona();
  const moduleNames = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.MODULES}`].moduleNames;
  if (moduleNames && moduleNames[localPersona]) {
    moduleRoutePrefix = moduleNames[localPersona].routePrefix;
  } else if (moduleNames) {
    /* eslint-disable no-unused-vars */
    Object.entries(moduleNames).forEach(([key, value]) => {
      // there are modules that doesn't have persona
      // (personaMapping - LEManager/LEContent ...) and let say
      // the url was saved like: https://omerh-mac.dev
      // .lprnd.net/a/qa42119490/#mng!data/list (bookmark copy paste)
      // our application is building the url to the AppServer like:
      // https://hc1/hc/web/public/pub/ma/lp/login.jsp?
      // lpservice=liveEngage&servicepath=a%2F~~accountid~~%2F%23%2C~~ssokey~~%3Bmng!data/list
      // mng - is acting like "persona"
      // if the "persona" is equal to the routePrefix is the case for the below scenario
      if (value.routePrefix === localPersona) {
        moduleRoutePrefix = localPersona;
      }
    });
  }
  return moduleRoutePrefix;
}

function resolvePersonaByPrefix(prefix) {
  const prefixMap = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.MODULES}`].prefixMap;
  return prefixMap && prefixMap[prefix] ? prefixMap[prefix] : '';
}

function resolveModuleName(persona) {
  const moduleNames = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.MODULES}`].moduleNames;
  const localPersona = persona || sessionManager.getActivePersona();
  return moduleNames && moduleNames[localPersona] ? moduleNames[localPersona].moduleMapping : '';
}

function fixRoute(prefix, route) {
  const fixedRoute = RouteNameBuilder.assembleName(prefix, route);
  logger.debug(`Fixing route. Original route: ${route}; New route: ${fixedRoute}`);
  return fixedRoute;
}

function parseRouteSection(route, external) {
  const emptyValues = {
    persona: '',
    prefix: '',
    module: '',
    route: '',
    fixedRoute: '',
    outerRoute: '',
  };

  const parsed = emptyValues;

  if (route.length !== 0) {
    parsed.route = route;
    const routeParts = parsed.route.split(RouteNameBuilder.getRouteDelimiter()).filter((e) => e);
    const routePersona = routeParts[0];
    const routePathToModule = routeParts.slice(1, routeParts.length);

    let persona = '';
    let prefix = '';

    // Now we have the view-path/with/inside/module (if any)
    // in moduleRoute[1] and the persona in moduleRoute[0]
    if (external) {
      parsed.persona = routePersona;
      // parsed.prefix = this.reqres.request
      // ('module:resolve:prefix', { persona: parsed.persona });
      parsed.prefix = resolveModuleRoutePrefix(parsed.persona);
      if (!_.isString(parsed.persona) || parsed.persona.length === 0) {
        prefix = parsed.persona;
        // persona = this.reqres.request('module:resolve:persona-by-prefix', { prefix });
        persona = resolvePersonaByPrefix(prefix);

        parsed.prefix = prefix;
        parsed.persona = persona;
      }
    } else {
      parsed.prefix = routePersona;
      // parsed.persona = this.reqres.request('module:resolve:
      // persona-by-prefix', { prefix: parsed.prefix });
      parsed.persona = resolvePersonaByPrefix(parsed.prefix);
      if (!_.isString(parsed.persona) || parsed.persona.length === 0) {
        persona = parsed.prefix;
        // prefix = this.reqres.request('module:resolve:prefix', { persona });
        prefix = resolveModuleRoutePrefix(persona);

        parsed.persona = persona;
        parsed.prefix = prefix;
      }
    }
    if (_.isString(parsed.persona) && parsed.persona.length !== 0 &&
      _.isString(parsed.prefix) && parsed.prefix.length !== 0) {
      // parsed.module = this.reqres.request('module:resolve:name', { persona: parsed.persona });
      parsed.module = resolveModuleName(parsed.persona);
      if (routeParts.length > 1) {
        parsed.view = routePathToModule.join(RouteNameBuilder.getRouteDelimiter());
      }
      parsed.fixedRoute = fixRoute.call(this, parsed.prefix, parsed.view);
      parsed.outerRoute = fixRoute.call(this, parsed.persona, parsed.view);
    } else {
      return emptyValues;
    }
  }

  return parsed;
}

// input fragment, modulesMap, prefixMap, personaMap
function parseLoginFragment(options) {
  // The expected pattern of the login url should be:
  // http://lp.net/#account-id-value,otk-value;persona!view-path/with/inside/module (external)
  // EXAMPLES:
  // http://lp.net/#123,abc;campaigns!list/12 (external)
  // http://lp.net/#123,abc (external)
  // http://lp.net/#registration (internal)
  // http://lp.net/#registration!thanks (internal)
  // http://lp.net/#registration!thanks (internal)
  // ??? http://lp.net/#login (internal)

  // We should also handle the application routes pattern (for F5) which should be:
  //
  // http://lp.net/#campains!list/1 (internal)

  const parsed = {
    route: '',
    fixedRoute: '',
    outerRoute: '',
    prefix: '',
    otk: null,
    accountId: null,
    persona: '',
    module: '',
    view: '',
    firstTimeLogin: false,
    loginTimestamp: (new Date()).getTime(),
  };

  let external = true;
  let internal;

  // Get the account Id from the url
  parsed.accountId = options.accountId;

  if (_.isString(options.fragment) && options.fragment.length !== -1) {
    const elements = options.fragment.split(';');
    // Now we have the route fragment (if any) in elements[1] and the rest in elements[0]
    if (elements.length > 1) {
      if(elements[1].includes('!')) {
        elements[1] = elements[1].replace('!', RouteNameBuilder.getRouteDelimiter());
      }
      internal = parseRouteSection(elements[1], external, options);

      parsed.route = internal.route;
      // Now we have the view-path/with/inside/module (if any)
      // in route[1] and the persona in route[0]
      parsed.persona = internal.persona;
      parsed.prefix = internal.prefix;
      parsed.module = internal.module;
      parsed.view = internal.view;
      parsed.fixedRoute = internal.fixedRoute;
      parsed.outerRoute = internal.outerRoute;
      // We should first check if it is not the application internal route pattern e.g. http://lp.net/#campains!list/1
      // RouteNameBuilder.getRouteDelimiter()
    } else if (elements[0].indexOf(RouteNameBuilder.getRouteDelimiter()) > -1) {
      // This is an application internal route pattern
      external = false;
    } else if (elements[0].indexOf(',') === -1) {
      // This is internal e.g. http://lp.net/#login (internal) (external have to have /)
      external = false;
    }

    if (external) {
      // Now we have the otk-value (if any) in tokens[1]
      // and maybe the account-id-value in tokens[0] if any
      const tokens = elements[0].split(',');
      if (tokens.length > 1) {
        parsed.otk = tokens[1];
      }
      if (tokens.length > 2) {
        parsed.firstTimeLogin = true;
      }
      if (tokens[0] === 0) {
        parsed.accountId = tokens[0];
      }
    } else {
      internal = parseRouteSection(elements[0], external, options);

      parsed.route = internal.route;
      // Now we have the view-path/with/inside/module
      // (if any) in route[1] and the persona in route[0]
      parsed.persona = internal.persona;
      parsed.prefix = internal.prefix;
      parsed.module = internal.module;
      parsed.view = internal.view;
      parsed.fixedRoute = internal.fixedRoute;
      parsed.outerRoute = internal.outerRoute;
    }
  }
  return parsed;
}

function unescapeUrl(str) {
  const parsed = str.replace(/\+/g, '%20');
  return parsed.replace(/%(u[\da-f]{4}|[\da-f]{2})/gi, seq => escapeHash.data(seq));
}

function getFragment(router) {
  let fragment = router && router.currentRoute.value.path;

  if (fragment.charAt(0) === '/') {
    fragment = fragment.substr(1);
  }

  if (!fragment) {
    fragment = window.location.search; // for logging in after login error
  }

  return unescapeUrl(fragment);
}

function getCurrentAccountId() {
  let parts = [];
  let path;
  let accountId;
  if (!accountId) { // Cache instead of continually looking this up....
    path = UrlResolver.getWindowLocation({ removeTrailing: true });
    parts = path.split('/');
    accountId = parts[parts.length - 1];
  }
  return accountId;
}

function isLeAppRoute(route){
  return route.name && route.name.indexOf('LEApp') > -1;
}

function extractPrefix(route) {
  const parts = route.path.split('/').filter(function(e){return e;});
  return parts[0];
}

function validateBaseRoutePath() {
  return !window.location.search.includes('error=');
}

function validateRoute(route) {
  const validApps = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.VALID_APPS}`];
  const prefix = extractPrefix(route);
  const isRegisteredModuleOrApp = validApps.indexOf(prefix) > -1;
  const isDefaultLeApp = isLeAppRoute(route);
  return isRegisteredModuleOrApp || isDefaultLeApp;
}

function calculateDefaultRouteByRole() {
  // TODO: fix the function to better implementation..
  const aw = LEConfig.leapps && LEConfig.leapps['lp-le-agent-workspace'];
  const amd = LEConfig.leapps && LEConfig.leapps['lp-le-agent-manager-dashboard'];

  const isAdmin = sessionManager.hasPrivilege(1548);
  const isAgent = sessionManager.hasPrivilege(1740);
  const isAgentManager = sessionManager.hasPrivilege(9);
  const isCampaignManager= sessionManager.hasPrivilege(56);

  let awDefaultRoute;
  let amdDefaultRoute;
  if(aw){
    awDefaultRoute = aw.defaultRoute;
  }
  if(amd){
    amdDefaultRoute = amd.defaultRoute;
  }

  let route = '';
  if( isAgent === true ) {
    if(aw && awDefaultRoute){
      route = awDefaultRoute;
    }
    else {
      route = '/wa/';
    }
  } else if (isAgentManager === true ) {
    if(amd && amdDefaultRoute){
      route = amdDefaultRoute;
    } else {
      route = '/wa/';
    }
  } else if (isCampaignManager === true ) {
    route = '/camp/';
  }  else if (isAdmin === true ) {
    route = '/um/';
  }
  return route;
}

export default {
  isLeAppRoute,
  validateRoute,
  getCurrentAccountId,
  getFragment,
  parseLoginFragment,
  extractPrefix,
  validateBaseRoutePath,
  calculateDefaultRouteByRole,
};
