/**
 * Created by tomers on 5/6/2015.
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");
    var LocaleResolver = require('i18n/localeResolver');
    var ApiResources = require("assets/data/apiResources.json");
    var CONST = require("const");
    var ErrorCodes = require("codeTables/errorCodes.json");
    var _ = require('underscore');

    var MODEL_VALIDATORS = Config.validators.skill;
    var DEFAULT_MAX_WAIT_TIME = "120";
    var SLA_RESPONSE_TIME_TYPES = {
        ACCOUNT: 0,
        CUSTOM: 1
    };
    var WORKING_HOURS_TYPES = {
        ACCOUNT: 0,
        CUSTOM: 1
    };
    var TRANSFER_TO_AGENT_TYPES = {
        ACCOUNT: 0,
        CUSTOM: 1
    };

    var AUTO_CLOSE_TYPES = {
        ACCOUNT: 0,
        CUSTOM: 1
    };

    var FALLBACK_SKILL_TYPES = {
        ACCOUNT: 0,
        CUSTOM: 1
    };

    var STATIC_LOAD_BALANCE_COMPONENTS_TYPES = {
        CHAT: 0,
        MESSAGING: 1
    };

    var KEYS = {
        NAME: "name",
        DESCRIPTION: "description",
        MAX_WAIT_TIME: "maxWaitTime",
        SKILL_ROUTING_CONFIGURATION: "skillRoutingConfiguration",
        DEFAULT_SURVEY_POST_CHAT: "defaultPostChatSurveyId",
        DEFAULT_SURVEY_OFFLINE: "defaultOfflineSurveyId",
        DEFAULT_SURVEY_AGENT: "defaultAgentSurveyId",
        WRAP_UP_TIME: "wrapUpTime",
        LOBS: "lobIds",
        CAN_TRANSFER: "canTransfer",
        TRANSFER_SKILLS: "skillTransferList",
        TRANSFER_TO_AGENT: "transferToAgentMaxWaitInSeconds",
        SLA_DEFAULT_RESPONSE_TIME: "slaDefaultResponseTime",
        SLA_URGENT_RESPONSE_TIME: "slaUrgentResponseTime",
        SLA_FIRST_TIME_RESPONSE_TIME: "slaFirstTimeResponseTime",
        WORKING_HOURS: "workingHoursId",
        SPECIAL_OCCASION: "specialOccasionId",
        AUTO_CLOSE: "autoCloseInSeconds",
        MESSAGING_AGENT_SURVEY: "agentSurveyForMsgId",
        MESSAGING_AGENT_SURVEY_TIMEOUT: "agentSurveyForMsgTimeoutInMinutes",
        FALLBACK_SKILL: "fallbackSkill",
        ROUTE_TO_FALLBACK_SKILL_WHEN_AWAY: "fallbackWhenAllAgentsAreAway",
        REROUTE_TO_CONNECTED_GROUPS_CHECKBOX: "redistributeLoadToConnectedAgentGroups"
    };
    var EMPTY_VALUE = null;//used for Default survey fields reset

    var SECOND = 1;
    var MINUTE = 60 * SECOND;
    var autoCloseMinimalValueInMinutes;

    var enabled = true;

    var SkillModel = RevisionedModel.extend({
        MODEL_VALIDATORS: MODEL_VALIDATORS,
        SLA_RESPONSE_TIME_TYPES: SLA_RESPONSE_TIME_TYPES,
        STATIC_LOAD_BALANCE_COMPONENTS_TYPES: STATIC_LOAD_BALANCE_COMPONENTS_TYPES,
        WORKING_HOURS_TYPES: WORKING_HOURS_TYPES,
        TRANSFER_TO_AGENT_TYPES: TRANSFER_TO_AGENT_TYPES,
        AUTO_CLOSE_TYPES: AUTO_CLOSE_TYPES,
        FALLBACK_SKILL_TYPES: FALLBACK_SKILL_TYPES,
        KEYS: KEYS,
        defaults: {
            id: null,
            name: "",
            description: "",
            maxWaitTime: DEFAULT_MAX_WAIT_TIME,
            skillRoutingConfiguration: [],
            defaultPostChatSurveyId: null,
            defaultOfflineSurveyId: null,
            defaultAgentSurveyId: null,
            wrapUpTime: null,
            lobIds: [],
            canTransfer: true,
            skillTransferList: [],
            slaDefaultResponseTime: null,
            slaUrgentResponseTime: null,
            slaFirstTimeResponseTime: null,
            transferToAgentMaxWaitInSeconds: null,
            workingHoursId: null,
            specialOccasionId: null,
            autoCloseInSeconds: null,
            fallbackSkill: null,
            fallbackWhenAllAgentsAreAway: true,
            agentSurveyForMsgId: null,
            agentSurveyForMsgTimeoutInMinutes: null
        },

        saveEnableValidation: {
            name: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.skill.errors.name.required"
                }
            ],
            description: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.skill.errors.description.required"
                }
            ],
            maxWaitTime: [
                {
                    required: true,
                    min: 0,
                    msg: "LEFramework.entities.skill.errors.maxWaitTime.required"
                }
            ],
            slaDefaultResponseTime: [
                {
                    required: function() {
                        return this.slaResponseTimeType === SLA_RESPONSE_TIME_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.defaultSla.required"
                }
            ],
            slaUrgentResponseTime: [
                {
                    required: function() {
                        return this.slaResponseTimeType === SLA_RESPONSE_TIME_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.urgentSla.required"
                }
            ],
            slaFirstTimeResponseTime: [
                {
                    required: function() {
                        return this.slaResponseTimeType === SLA_RESPONSE_TIME_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.firstTimeSla.required"
                }
            ],
            autoCloseInSeconds: [
                {
                    required: function () {
                        return this.autoCloseType === AUTO_CLOSE_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.autoClose.required"
                }
            ],
            fallbackSkill: [
                {
                    required: function () {
                        return this.fallbackSkillType === FALLBACK_SKILL_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.fallbackSkill.required"
                }
            ],
            transferToAgentMaxWaitInSeconds: [
                {
                    required: function () {
                        return this.transferToAgentType === TRANSFER_TO_AGENT_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.transferToAgent.required"
                }
            ]
        },

        validation: {
            name: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.skill.errors.name.required"
                },
                {
                    maxLength: Config.validators.skill.maxNameLength,
                    msg: "LEFramework.entities.skill.errors.name.length"
                }
            ],
            description: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.skill.errors.description.required"
                },
                {
                    maxLength: Config.validators.skill.maxDescriptionLength,
                    msg: "LEFramework.entities.skill.errors.description.length"
                }
            ],
            maxWaitTime: [
                {
                    required: true,
                    min: 0,
                    msg: "LEFramework.entities.skill.errors.maxWaitTime.required"
                },
                {
                    max: Config.validators.skill.maxWaitTime,
                    msg: "LEFramework.entities.skill.errors.maxWaitTime.max"
                },
                {
                    pattern: "number",
                    msg: "LEFramework.entities.skill.errors.maxWaitTime.nan"
                }
            ],
            wrapUpTime: [
                {
                    required: false,
                    min: 0,
                    max: Config.validators.skill.maxWrapUpTime,
                    msg: "LEFramework.entities.skill.errors.wrapUpTime.max"
                }
            ],
            agentSurveyForMsgTimeoutInMinutes: [
                {
                  required: false,
                  min: 0,
                  max: Config.validators.skill.maxAgentSurveyForMsgTimeoutInMinutes,
                  msg: "LEFramework.entities.skill.errors.agentSurveyForMsgTimeoutInMinutes.max"
                }
            ],
            workingHoursId: [
                {
                    required: function() {
                        return this.getWorkingHoursType() === WORKING_HOURS_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.workingHours.required"
                }
            ],
            specialOccasionId: [
                {
                    required: function() {
                        return this.getSpecialOccasionType() === WORKING_HOURS_TYPES.CUSTOM;
                    },
                    msg: "LEFramework.entities.skill.errors.specialOccasion.required"
                }
            ],
            autoCloseInSeconds: [
                {
                    required: function () {
                        return this.getAutoCloseType() === AUTO_CLOSE_TYPES.CUSTOM;
                    },
                    max: Config.validators.skill.maxAutoClose,
                    min: function () {
                        return this.autoCloseMinimalValue;
                    },
                    msg: {
                      key: "LEFramework.entities.skill.errors.autoClose.with.minimalValue.range",
                      args: [{key: function () {
                          return autoCloseMinimalValueInMinutes;
                        }, isTranslate: false}]
                    }
                }
            ],
            fallbackSkill: [
                {
                    required: function () {
                        return this.getFallbackSkillType() === FALLBACK_SKILL_TYPES.CUSTOM;
                    },
                    minLength: 1,
                    msg: "LEFramework.entities.skill.errors.name.required"
                }
            ],
            transferToAgentMaxWaitInSeconds: [
                {
                    required: function () {
                        return this.getTransferToAgentType() === TRANSFER_TO_AGENT_TYPES.CUSTOM;
                    },
                    max: Config.validators.skill.maxTransferToAgent,
                    min: Config.validators.skill.minTransferToAgent,
                    msg: "LEFramework.entities.skill.errors.transferToAgent.range"
                }
            ]
        },
        name: "SkillModel",
        initialize: function(options) {
            this.valueIsChanged = false;
            options = options || {};
            this.source = options.source;
            if(CONST.SOURCE_ENUM.LEGACY === LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.USERS_SOURCE)) {
                this.resource = ApiResources.AppServer.Skills;
            } else {
                this.resource = ApiResources.AccountConfig.Skills;
            }
            RevisionedModel.prototype.initialize.call(this, options);
        },

        getName: function () {
            return this.get(KEYS.NAME);
        },
        setName: function (name) {
            this._set(KEYS.NAME, name);
        },
        getDescription: function () {
            return this.get(KEYS.DESCRIPTION);
        },
        setDescription: function (description) {
            this._set(KEYS.DESCRIPTION, description);
        },
        getMaxWaitTime: function () {
            return this.get(KEYS.MAX_WAIT_TIME);
        },
        setMaxWaitTime: function (maxWaitTime) {
            this._set(KEYS.MAX_WAIT_TIME, maxWaitTime);
        },
        getWrapUpTime: function(){
            return this.get(KEYS.WRAP_UP_TIME);
        },
        setWrapUpTime: function(wrapUpTime){
            this._set(KEYS.WRAP_UP_TIME, wrapUpTime);
        },
        getSkillRoutingConfiguration: function() {
            return this.get(KEYS.SKILL_ROUTING_CONFIGURATION);
        },
        setSkillRoutingConfiguration: function(configuration) {
            this._set(KEYS.SKILL_ROUTING_CONFIGURATION, configuration);

            // in case there is no load balance or the selected load balancing is for chat, we need to set the reroute to FALSE
            if (_.isEmpty(configuration) || (configuration[0] && configuration[0].interactionType === STATIC_LOAD_BALANCE_COMPONENTS_TYPES.CHAT)) {
              this.setRerouteToConnectedGroupsCheckbox(false);
            }
        },
        getDefaultSurveyPostChat: function() {
            return this.get(KEYS.DEFAULT_SURVEY_POST_CHAT);
        },
        setDefaultSurveyPostChat: function(defaultPostChatSurvey) {
            this._set(KEYS.DEFAULT_SURVEY_POST_CHAT, defaultPostChatSurvey);
        },
        getDefaultSurveyOffline: function() {
            return this.get(KEYS.DEFAULT_SURVEY_OFFLINE);
        },
        setDefaultSurveyOffline: function(defaultOfflineSurvey) {
            this._set(KEYS.DEFAULT_SURVEY_OFFLINE, defaultOfflineSurvey);
        },
        getDefaultSurveyAgent: function() {
            return this.get(KEYS.DEFAULT_SURVEY_AGENT);
        },
        setDefaultSurveyAgent: function(defaultAgentSurvey) {
            this._set(KEYS.DEFAULT_SURVEY_AGENT, defaultAgentSurvey);
        },
        getMessagingAgentSurvey: function() {
            return this.get(KEYS.MESSAGING_AGENT_SURVEY);
        },
        setMessagingAgentSurvey: function(messagingAgentSurvey) {
            this._set(KEYS.MESSAGING_AGENT_SURVEY, messagingAgentSurvey);
        },
        getMessagingAgentSurveyTimeout: function() {
            return this.get(KEYS.MESSAGING_AGENT_SURVEY_TIMEOUT);
        },
        setMessagingAgentSurveyTimeout: function(timeout) {
            this._set(KEYS.MESSAGING_AGENT_SURVEY_TIMEOUT, timeout);
        },
        getLobs: function() {
            return this.get(KEYS.LOBS);
        },
        setLobs: function(lobIds) {
            this._set(KEYS.LOBS, lobIds);
        },
        getTransferSkills: function() {
            return this.get(KEYS.TRANSFER_SKILLS);
        },
        setTransferSkills: function(transferSkills) {
            this._set(KEYS.TRANSFER_SKILLS, transferSkills);
        },
        getCanTransfer: function() {
            return this.get(KEYS.CAN_TRANSFER);
        },
        setCanTransfer: function(canTransferSkills) {
            this._set(KEYS.CAN_TRANSFER, canTransferSkills);
        },
        getWorkingHoursType: function() {
            return this.workingHoursType;
        },
        setWorkingHoursType: function(type) {
            this.workingHoursType = type;
        },
        getWorkingHours: function() {
            return this.get(KEYS.WORKING_HOURS);
        },
        setWorkingHours: function(workingHoursId) {
            this._set(KEYS.WORKING_HOURS, workingHoursId);
        },
        getSpecialOccasionType: function() {
            return this.specialOccasionType;
        },
        setSpecialOccasionType: function(type) {
            this.specialOccasionType = type;
        },
        getSpecialOccasion: function() {
            return this.get(KEYS.SPECIAL_OCCASION);
        },
        setSpecialOccasion: function(specialOccasionId) {
            this._set(KEYS.SPECIAL_OCCASION, specialOccasionId);
        },
        getAutoCloseType: function () {
            return this.autoCloseType;
        },
        setAutoCloseType: function (type) {
            this.autoCloseType = type;
        },
        getAutoClose: function () {
            return this.get(KEYS.AUTO_CLOSE);
        },
        setAutoClose: function (seconds) {
            seconds = seconds ? seconds : null;
            this._set(KEYS.AUTO_CLOSE, seconds);
        },
        getFallbackSkillType: function () {
            return this.fallbackSkillType;
        },
        setFallbackSkillType: function (type) {
            this.fallbackSkillType = type;
        },
        getFallbackSkillId: function () {
            return this.get(KEYS.FALLBACK_SKILL);
        },
        setFallbackSkillId: function (skillId) {
            this._set(KEYS.FALLBACK_SKILL, skillId);
        },
        getRouteToFallbackSkillWhenAway: function () {
            return this.get(KEYS.ROUTE_TO_FALLBACK_SKILL_WHEN_AWAY);
        },
        setRouteToFallbackSkillWhenAway: function (shouldRouteToFallbackSkill) {
            this._set(KEYS.ROUTE_TO_FALLBACK_SKILL_WHEN_AWAY, shouldRouteToFallbackSkill);
        },
        getSLAResponseTimeType: function() {
            return this.slaResponseTimeType;
        },
        setSLAResponseTimeType: function(type) {
            this.slaResponseTimeType = type;
        },
        setAutoCloseMinimalValue: function(seconds) {
            this.autoCloseMinimalValue = seconds;
            autoCloseMinimalValueInMinutes = seconds/MINUTE;
        },
        getSlaDefaultResponseTime: function() {
            return this.get(KEYS.SLA_DEFAULT_RESPONSE_TIME);
        },
        getSlaUrgentResponseTime: function() {
            return this.get(KEYS.SLA_URGENT_RESPONSE_TIME);
        },
        getSlaFirstTimeResponseTime: function() {
            return this.get(KEYS.SLA_FIRST_TIME_RESPONSE_TIME);
        },
        getTransferToAgentType: function () {
            return this.transferToAgentType;
        },
        setTransferToAgentType: function (type) {
            this.transferToAgentType = type;
        },
        getTransferToAgent: function () {
            return this.get(KEYS.TRANSFER_TO_AGENT);
        },
        setTransferToAgent: function (seconds) {
            seconds = seconds ? seconds : null;
            this._set(KEYS.TRANSFER_TO_AGENT, seconds);
        },
        setSlaDefaultResponseTime: function(seconds) {
            seconds = seconds ? seconds : null;
            this._set(KEYS.SLA_DEFAULT_RESPONSE_TIME, seconds);
        },
        setSlaUrgentResponseTime: function(seconds) {
            seconds = seconds ? seconds : null;
            this._set(KEYS.SLA_URGENT_RESPONSE_TIME, seconds);
        },
        setSlaFirstTimeResponseTime: function(seconds) {
            seconds = seconds ? seconds : null;
            this._set(KEYS.SLA_FIRST_TIME_RESPONSE_TIME, seconds);
        },

        setRerouteToConnectedGroupsCheckbox: function(isChecked){
          this._set(KEYS.REROUTE_TO_CONNECTED_GROUPS_CHECKBOX, isChecked);
        },

        getRerouteToConnectedGroupsCheckbox: function () {
            return this.get(KEYS.REROUTE_TO_CONNECTED_GROUPS_CHECKBOX);
        },

        isCustomSla: function() {
            return this.getSlaDefaultResponseTime() && this.getSlaUrgentResponseTime() && this.getSlaFirstTimeResponseTime();
        },

        updateTransferToAgent: function (options) {
            this.setTransferToAgentType(options.transferToAgentType);
            this.setTransferToAgent(options.transferToAgentInSeconds);
        },

        updateSLA: function(slaOptions) {
            this.setSLAResponseTimeType(slaOptions.slaResponseType);
            this.setSlaDefaultResponseTime(slaOptions.defaultResponseTimeInSeconds);
            this.setSlaUrgentResponseTime(slaOptions.urgentResponseTimeInSeconds);
            this.setSlaFirstTimeResponseTime(slaOptions.firstTimeResponseTimeInSeconds);
        },

        updateAutoClose: function(autoCloseOptions) {
          this.setAutoCloseType(autoCloseOptions.autoCloseType);
          this.setAutoClose(autoCloseOptions.autoClose);
          this.setAutoCloseMinimalValue(autoCloseOptions.autoCloseMinimalValue);
        },

        isValueChanged: function() {
            return this.valueIsChanged;
        },

        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },

        parse: function (response, options) {
            if (response && response.maxWaitTime === -1) {
                response.maxWaitTime = DEFAULT_MAX_WAIT_TIME;
            }

            if (response && response.members) {
                delete response.members;
            }

            return RevisionedModel.prototype.parse.call(this, response, options);
        },

        save: function(attributes, options) {
            options = options ? _.clone(options) : {};
            var origError = options.error;

            if(!this.getCanTransfer()) {
                this.setTransferSkills([]);
            }

            options.error = _.bind(function(collection, resp, opt) {
                var errorObject = {};
                var errorMessage = resp && _.has(resp.responseError, "message") ?
                    resp.responseError.message : LE.translator.translate("LEFramework.something.went.wrong");

                if (resp && resp.responseError && resp.responseError.error) {
                    if (_.has(resp.responseError.error, "message")) {
                        errorMessage = resp.responseError.error.message;
                    }

                    if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.duplicateEntity)) {
                        errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
                    }
                    if (_.has(resp.responseError.error, "internalCode") && resp.responseError.error.internalCode === ErrorCodes.appServer.internalCodes.duplicateEntity) {
                        errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
                    }
                }
                if (resp && resp.responseError && resp.responseError.internalCode) {
                    if (resp.responseError.internalCode === ErrorCodes.uniquenessViolation) {
                        errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
                    }
                }

                if(!_.isEmpty(errorObject)) {
                    options.errorObject = errorObject;
                }
                options.errorMessage = errorMessage;

                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            RevisionedModel.prototype.save.call(this, attributes, options);
        },

        destroy: function(options) {
            options = options ? _.clone(options) : {};
            var origError = options.error;
            options.error = _.bind(function(collection, resp, opt) {
                var errorMessage = LE.translator.translate("LEFramework.something.went.wrong");
                if (resp && resp.responseError && resp.responseError.message) {
                    errorMessage = resp.responseError.message;
                }

                if (resp && resp.responseError && resp.responseError.internalCode) {
                    if (resp.responseError.internalCode === ErrorCodes.dependencyConflict || resp.responseError.internalCode === ErrorCodes.dependencyConflictExternal) {
                        errorMessage = LE.translator.translate('LEFramework.entities.skill.delete.errors.skillList.assignedToUsers');

                        var errorFields = resp.responseError.fields;
                        var translatedFields = [];
                        if (!_.isEmpty(errorFields)){
                            for (var i = 0; i < errorFields.length; i++){
                                var key = "LEFramework.entities.skill.delete.error.dependencies.field." + errorFields[i];
                                var translatedField = LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), key) ? LE.translator.translate(key) : errorFields[i];
                                translatedFields.push(translatedField);
                            }

                            errorMessage = LE.translator.translate({
                                key: "LEFramework.entities.skill.delete.error.dependencies",
                                args: [{key: translatedFields.join('<br>'), isTranslate:false}]
                            });

                        }
                    }
                }
                options.errorMessage = errorMessage;
                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            RevisionedModel.prototype.destroy.call(this, options);
        },

        //private functions:
        _set: function(key,value) {
            this.valueIsChanged = true;
            this.set(key, value);
        }
    });


    SkillModel.KEYS = KEYS;
    SkillModel.EMPTY_VALUE = EMPTY_VALUE;
    return SkillModel;
});
