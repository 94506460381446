define(function (require) {
    "use strict";
    // todo - webpack split-point here (dynamic import) - move this async require to whereever this module is consumed
    require(["ckeditor.src"], function(){
        // Load the ckeditor stuff only after login

        // Read the DOC -- http://docs.ckeditor.com/source/focusmanager.html#CKEDITOR-focusManager-_-property-blurDelay
        // Bug for CKEditor: https://dev.ckeditor.com/ticket/16873
        // LE-Bug: LE-67821 - steal focus to other elements.
        if(CKEDITOR) {
            if(CKEDITOR.focusManager && CKEDITOR.focusManager._ ){
                CKEDITOR.focusManager._.blurDelay = 0;
            }
            CKEDITOR.timestamp = LE_VERSION;
        }
    });

    window.CKEDITOR_BASEPATH = (window.leResourceBaseUrl ? window.leResourceBaseUrl : '') + 'assets/js/lib/ckeditor/';
//    return ckEditor;
});
