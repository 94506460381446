/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:37 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var AccountSupportedLanguages = require("codeTables/accountSupportedLanguages.json");
  var _ = require('underscore');

    var supportedLanguageSettings = (function () {

        var defaultSupportedLanguage = null;
        var supportedLanguages = null;

        var getAllLanguageCodes = function () {
            return AccountSupportedLanguages.languageCodes;
        };

        var getAllLanguageCodesTranslated = function () {
            if (_.isUndefined(AccountSupportedLanguages.languageCodesTranslated)) {
                translateAvailableSupportedLanguages();
            }

            return AccountSupportedLanguages.languageCodesTranslated;
        };

        var getAllLanguageNamesTranslated = function () {
            if (_.isUndefined(AccountSupportedLanguages.languageNamesTranslated)) {
                translateAvailableSupportedLanguages();
            }

            return AccountSupportedLanguages.languageNamesTranslated;
        };


        var translateAvailableSupportedLanguages = function () {
            AccountSupportedLanguages.languageCodesTranslated = {};
            AccountSupportedLanguages.languageNamesTranslated = {};

            _.each(AccountSupportedLanguages.languageCodes, function (code) {
                AccountSupportedLanguages.languageCodesTranslated[code] = LE.translator.translate("LEFramework.account.lang." + code +".code");
                AccountSupportedLanguages.languageNamesTranslated[code] = LE.translator.translate("LEFramework.account.lang." + code +".name");
            });
//
//                AccountSupportedLanguages.languageCodesTranslated =_.sortBy(AccountSupportedLanguages.languageCodesTranslated, function(obj){ return obj.value; });
//                AccountSupportedLanguages.languageNamesTranslated =_.sortBy(AccountSupportedLanguages.languageNamesTranslated, function(obj){ return obj.value; });

        };


        var supportedDefaultLanguageCodesChange = function (supportedLangsArray, defaultLanguage) {
        supportedLanguages = _.intersection(supportedLangsArray,getAllLanguageCodes());   //backward compatibility remove unsupported codes
        defaultSupportedLanguage = defaultLanguage;
        var languagesChanges = {
            supportedLanguages: supportedLanguages,
            defaultSupportedLanguage: defaultSupportedLanguage
        };
        LE.vent.trigger("siteSettings:supportedDefaultLanguageCodesChanged", languagesChanges);
    };

    var getSupportedLanguageCodes = function () {
        if (supportedLanguages == null) {
            supportedLanguages = LE.sessionManager.getAccountSettingByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.SUPPORTED_LANGUAGES);
            if (supportedLanguages) {
                if (_.isArray(supportedLanguages.propertyValue.value)) {
                    supportedLanguages = supportedLanguages.propertyValue.value;
                } else {
                    supportedLanguages.propertyValue.value =  supportedLanguages.propertyValue.value || "[]"; //todo replace this too when siteproperties with arraytype for languages finish deployment in production
                    supportedLanguages = JSON.parse(supportedLanguages.propertyValue.value.replace(/'/g,"\"")); //todo wait for full deployment for array type and remove this hack (both parse and replace )(up to 10/4/14 )
                }

                supportedLanguages = _.intersection(supportedLanguages,getAllLanguageCodes()) ;  //backward compatibility remove unsupported codes
            }
            else {
                supportedLanguages = [AccountSupportedLanguages.defaultLanguage];
            }
        }
        return supportedLanguages;
    };

    var getSupportedTranslatedLanguages = function () {

        var res = [];

        var supportedLanguages = getSupportedLanguageCodes();
        var languageNamesTranslated = getAllLanguageNamesTranslated();

        _.each(supportedLanguages, function(langCode){
            res.push({
                key: langCode,
                value: languageNamesTranslated[langCode]
            });
        },this);
        return res;
    };


    var getDefaultLanguage = function () {
        if (defaultSupportedLanguage == null) {
            defaultSupportedLanguage = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.DEFAULT_SUPPORTED_LANGUAGE,AccountSupportedLanguages.defaultLanguage);
        }
        return defaultSupportedLanguage;
    };


    var getTranslatedLanguages = function () {
        return AccountSupportedLanguages.languageTranslatedCodes;
    };

    return {
        supportedDefaultLanguageCodesChange: supportedDefaultLanguageCodesChange,
        getSupportedTranslatedLanguages:     getSupportedTranslatedLanguages,
        getSupportedLanguageCodes:           getSupportedLanguageCodes,
        getAllLanguageCodes:                 getAllLanguageCodes,
        getAllLanguageCodesTranslated:       getAllLanguageCodesTranslated,
        getAllLanguageNamesTranslated:       getAllLanguageNamesTranslated,
        getDefaultLanguage:                  getDefaultLanguage,
        getTranslatedLanguages:              getTranslatedLanguages
    };
})();

    return supportedLanguageSettings;
});
