define(function (require) {
  "use strict";

  const ApiResources = require("assets/data/apiResources.json");
  const { sessionManager, ACClient  } = require('vue-infra');
  const Settings = require('assets/data/codeTables/settings.json');
  const { environmentActionTypes, environmentStateKeys, store } = require('vue-infra');
  const { ENVIRONMENT } = require('vue-infra').storeModuleTypes;

  async function getAndInitApplicationGeneralSettings() {
    let currentThemeName;
    let isSoundEnable;
    let isFirstTimeLogin;
    let defaultSupportedThemes = 'dark';
    let defaultFirstTimeLogin = LE.sessionManager.getAccountSettingValueByID(Settings.SETTING_ID.FIRST_TIME_LOGIN) === 'true' || false;
    let defaultRing = LE.sessionManager.getAccountSettingValueByID(Settings.SETTING_ID.GENERAL_RING) || 'default';
    let currentRing = defaultRing;
    try {
      const supportedThemes = ['dark', 'light'];
      if (supportedThemes.length === 1 && supportedThemes[0] !== 'dark') {
        defaultSupportedThemes = supportedThemes[0];
      }
      const url = ApiResources.AccountConfig.SiteSettings.url;
      const siteSettingsUrl = window.LE.urlResolver.getUrl(url);
      const sessionconfig = sessionManager.getConfig();
      const baseSiteSettings = new ACClient({
        allowedMethods: ['get', 'post'],
        config: {
          baseURL: siteSettingsUrl,
          params: {
            groups: Settings.SETTING_GROUP.loginSession,
            context_type: Settings.CONTEXT_TYPE.USER,
            context_id: sessionconfig.userId,
            context_cascading: false,
            v: ApiResources.AccountConfig.SiteSettings.version
          },
        },
      });
      const loginSessionPerUserSiteSettings = await baseSiteSettings.get();

      if (loginSessionPerUserSiteSettings &&
        loginSessionPerUserSiteSettings.data.length  > 0){
        currentThemeName = getCurrentThemeName(defaultSupportedThemes, loginSessionPerUserSiteSettings);
        isSoundEnable = getIsSoundEnableValue(loginSessionPerUserSiteSettings);
        isFirstTimeLogin = getIsFirstTimeLogin(defaultFirstTimeLogin, loginSessionPerUserSiteSettings);
        currentRing = getCurrentRing(defaultRing, loginSessionPerUserSiteSettings);
      } else {
        currentThemeName =  defaultSupportedThemes;
        isSoundEnable = true;
        isFirstTimeLogin = defaultFirstTimeLogin;
      }

      if (isFirstTimeLogin) {
        baseSiteSettings.patch(undefined, [{
          id: Settings.SETTING_ID.FIRST_TIME_LOGIN,
          type: 2,
          propertyValue: { value: 'false' },
          deleted: false,
        }], {
          revision: -1,
        });
      }
    } catch (e){
      currentThemeName = defaultSupportedThemes;
      isSoundEnable = true;
      isFirstTimeLogin = defaultFirstTimeLogin;
      currentRing = defaultRing;
    }

    saveSiteSettingValueInStore(currentThemeName, isSoundEnable, currentRing);

    return {
      currentThemeName,
      isSoundEnable,
      isFirstTimeLogin,
    };
  }

  function getCurrentThemeName(defaultSupportedThemes, loginSessionPerUserSiteSettings) {
    let currentThemeName;
    const generalTheme = loginSessionPerUserSiteSettings.data.find(item => item.id === Settings.SETTING_ID.GENERAL_THEME);
    if (generalTheme) {
      currentThemeName = generalTheme && generalTheme.propertyValue && generalTheme.propertyValue.value;
    } else {
      currentThemeName =  defaultSupportedThemes;
    }
    return currentThemeName;
  }

  function getIsSoundEnableValue(loginSessionPerUserSiteSettings) {
    let isSoundEnable;
    const hasSound = loginSessionPerUserSiteSettings.data.find(item => item.id === Settings.SETTING_ID.SOUND);
    if (hasSound) {
      isSoundEnable = (hasSound && hasSound.propertyValue && hasSound.propertyValue.value) ? hasSound.propertyValue.value === 'true' : true;
    } else {
      isSoundEnable = true;
    }
    return isSoundEnable;
  }

  function getIsFirstTimeLogin(defaultFirstTimeLogin, loginSessionPerUserSiteSettings) {
    let isFirstTimeLogin;
    const firstTimeLogin = loginSessionPerUserSiteSettings.data.find(item => item.id === Settings.SETTING_ID.FIRST_TIME_LOGIN);
    if (isFirstTimeLogin) {
      isFirstTimeLogin = (firstTimeLogin && firstTimeLogin.propertyValue && firstTimeLogin.propertyValue.value) ? firstTimeLogin.propertyValue.value === 'true' : defaultFirstTimeLogin;
    } else {
      isFirstTimeLogin = defaultFirstTimeLogin;
    }
    return isFirstTimeLogin;
  }

  function getCurrentRing(defaultRing, loginSessionPerUserSiteSettings) {
    let currentRing = defaultRing;
    const generalRing = loginSessionPerUserSiteSettings.data.find(item => item.id === Settings.SETTING_ID.GENERAL_RING);
    if(generalRing) {
      currentRing = generalRing && generalRing.propertyValue && generalRing.propertyValue.value;
    }
    return currentRing;
  }

  function saveSiteSettingValueInStore(currentThemeName, isSoundEnable, currentRing) {
    store.dispatch(`${ENVIRONMENT}/${environmentActionTypes.SET_VALUE}`, {
      attribute: environmentStateKeys.CURRENT_ACTIVE_THEME,
      value: currentThemeName,
    });

    store.dispatch(`${ENVIRONMENT}/${environmentActionTypes.SET_VALUE}`, {
      attribute: environmentStateKeys.LE_SOUND_ENABLE,
      value: isSoundEnable,
    });

    store.dispatch(`${ENVIRONMENT}/${environmentActionTypes.SET_VALUE}`, {
      attribute: environmentStateKeys.CURRENT_RING,
      value: currentRing,
    });
  }

  return {
    getAndInitApplicationGeneralSettings,
  };
});


