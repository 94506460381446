/**
 * Created with IntelliJ IDEA.
 * User: omerh
 * Date: 2/26/14
 * Time: 10:48 AM
 * To change this template use File | Settings | File Templates.
 */


define(function (require) {
    var $ = require("jquery");
    var _ = require("underscore");
    var BaseModel = require("models/BaseModel");
    var ApiResources = require("assets/data/apiResources.json");



    var BillingSessionModel = BaseModel.extend({

        defaults: {
            trial: false,
            isTrialAttrSet: false,
            isTrialAttrSetError: false
        },
        name: "BillingSessionModel",

        resource: ApiResources.CreateAccountFromCSDS.IsTrialAccount,

        save:function () {
            return;
        },
        fetchIsTrialDetails: function(options){
            this.fetch(options);
        }
    });

    return new BillingSessionModel();

});
