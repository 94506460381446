import BaseAppender from './BaseAppender';


const defaultOptions = {
  blinkInterval: 800,
  text: '',
};


class TabTitleNotification extends BaseAppender {

  constructor() {
    super();

    this.defaultTitle = document.title;
  }

  getDefaultOptions() {
    return Object.freeze(defaultOptions);
  }

  setPageTitle({ main, title, subtitle }) {
    let pageTitle = this.defaultTitle;
    if (title) {
      pageTitle = title;
      if (subtitle) {
        pageTitle += ' | ' + subtitle;
        main += ' | ' + pageTitle;
      } else {
        main += ' | ' + pageTitle;
      }
    }
    this.baseTitle = main;
    if (!this.notificationInterval) {
      document.title = this.baseTitle;
    }
  }

  /**
   * @param options
   * @param options.num
   * @param options.text
   * @param options.blinkInterval
   */
  notify(options) {
    this._clearNotificationInterval();

    if (!options.num || Number.isNaN(options.num) || Number(options.num) === 0) {
      this._resetNotification();
    } else {
      this.notificationInterval = setInterval(() => {
        const newTitle2 = '(' + options.num + ') ' + options.text + ' | ' + this.baseTitle;
        document.title = (document.title === this.baseTitle) ? newTitle2 : this.baseTitle;
      }, options.blinkInterval);
    }
  }

  clearNotification(options) {
    return true;
  }

  _clearNotificationInterval() {
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
      this.notificationInterval = null;
    }
  }

  _resetNotification() {
    document.title = this.baseTitle;
  }

}


export default TabTitleNotification;

