/**
 * Created by iraphael on 10/10/2018.
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var ApiResources = require("assets/data/apiResources.json");

    var KEYS = {
        ID: "id",
        NAME: "name",
        IS_DEFAULT: "isDefault",
        ENABLED: "enabled"
    };

    var AgentSurveyModel = RevisionedModel.extend({
        name: "AgentSurveyModel",
        resource: ApiResources.AccountConfig.AgentSurvey,
        KEYS: KEYS,
        defaults: {
            id: null,
            name: "",
            isDefault: false,
            enabled: true
        },

        getName: function() {
            return this.get(KEYS.NAME);
        },

        isDefault: function() {
          return this.get(KEYS.IS_DEFAULT);
        },

        isEnabled: function() {
          return this.get(KEYS.ENABLED);
        }
    });

    AgentSurveyModel.KEYS = KEYS;

    return AgentSurveyModel;
});

