/**
 * This module represents the data model for LiveEngage Applications (LE-App)
 * @see https://developers.liveperson.com/liveengage-applications-what-is-a-liveengage-application.html
 */
define(function(require) {
  const LEConfig = require('assets/config/le-env-conf');
  const { BaseClient, Logger } = require('vue-infra');

  const LeAppModel = class {
    /**
     * Creates an instance of LeAppModel
     */
    constructor() {
      this.logger = Logger.getLogger('LaunchpadIntegration');
    }

    /**
     * Fetches an LE-App installation by its client id
     * @param {string} accountId - the account id of the logged in account
     * @param {string} clientId - the client id of the installation
     *
     * @returns
     * Returns an installation object or null if the LE-App is not installation on the account.
     * Also returns null in failure case.
     */
    async getAppInstallationByClientId(accountId, clientId) {
      const logNs = 'getAppInstallationByClientId';
      const path = `api/account/${accountId}/${clientId}`;
      const baseURL = `https://${LEConfig.appMgmtSvcDomain}`;
      const appMgmtClient = new BaseClient({
        config: { baseURL }
      });

      try {
        const { data, status } = await appMgmtClient.get(path);

        if (status > 200) {
          this.logger.info(
            `Could not find App Installation (${clientId}) on ${accountId}`,
            logNs
          );
          return null;
        }

        return data;
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          this.logger.error(
            `AppManagementService respond with ${status} for fetching a LiveEngage Application by clientId (${clientId})`,
            logNs
          );
        } else if (error.request) {
          this.logger.error(
            `Could not reach AppManagementService ${baseURL} to fetch a LiveEngage Application by clientId (${clientId})`,
            logNs
          );
        } else {
          this.logger.error(
            `BaseClient (axios) misconfigured in getAppByClientId ${JSON.stringify(
              appMgmtClient.getBaseDefaults()
            )}`,
            logNs
          );
        }

        return null;
      }
    }
  };

  return LeAppModel;
});
