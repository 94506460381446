<template>
  <first-time-conversation
    :sections="sections"
    :full-name="fullName"
    @click-button="handleButtonClick"
  />
</template>

<script>
import FirstTimeConversation from './FirstTimeConversation';
import { sessionManager, gChannel } from 'vue-infra';
import ApiResources from 'assets/data/apiResources.json';
import urlResolver from 'urlResolver';
let initialSections = require('./ReturnExperienceSections.json').sections;
const LOADING_DELAY = 2000;

export default {
  name: 'ReturnExperience',
  components: {
    FirstTimeConversation
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
    productTourUrl: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      sections: [...initialSections],
      timeouts: [],
      fullName: this.userDetails['fullName']
    };
  },
  beforeUnmount() {
    // Cancel timers before the component is destroyed
    this.timeouts.forEach(clearTimeout);
    // Reset the sections back to their initial state
    this.resetSections();
  },
  methods: {
    closeModal: function() {
      gChannel.trigger('LiveEngageCommon', 'FirstTimeExperienceModal:close');
    },
    addSection: function({ delay, showBotIcon, callback, ...content }) {
      if (delay) {
        this.sections.push({
          loading: true,
          showBotIcon
        });
        const indexOfLoadingSection = this.sections.length - 1;
        const timeout = setTimeout(() => {
          this.sections[indexOfLoadingSection] = {
            showBotIcon,
            ...content
          };

          if (callback) {
            callback();
          }
        }, delay);
        // Save timeout for cancelling later
        this.timeouts.push(timeout);
      } else {
        this.sections.push({ ...content });
      }
    },
    resetSections() {
      this.sections = initialSections;
    },
    getConciergeBotUrl() {
      let env = sessionManager.LEConfig.environment;
      let url = urlResolver.getUrl(
        ApiResources.FirstTimeUserExperience.conciergeBot.url,
        {
          developersCommunityDomain:
            sessionManager.LEConfig.topLevelExternalLinks.developersCommunity,
          firstTime: '',
          html: 'emulator.html',
          accountId: sessionManager.getAccountId(),
          userName: this.userDetails['fullName'],
          env: env === 'ga' ? '' : `&env=${env}`
        }
      );
      return url;
    },
    getProactiveMessagingURL() {
      let zone = LE.sessionManager.LEConfig.lp_zone;
      if (LE.sessionManager.LEConfig.environment  === 'qa') {
        zone = 'dev';
      } else if (LE.sessionManager.LEConfig.environment === 'alpha') {
        zone = 'stg';
      }
      const baseUrl =
        ApiResources.FirstTimeUserExperience['setupProactive'].url;

      let url = urlResolver.getUrl(baseUrl, {
        env: zone,
        accountId:LE.sessionManager.LEConfig.accountId
      });
      return url;
    },
    openUrl(data) {
      gChannel.trigger('LiveEngageCommon', 'ShowNotification', data);
      if (data.openWebEmulator) {
        window.open(this.getConciergeBotUrl(), '_blank');
      } else if (data.internalUrl) {
        window.LE.router.push(data.internalUrl);
      } else if (data.id === 'setupProactive') {
        window.open(this.getProactiveMessagingURL(), '_blank');
      } else {
        const baseUrl = ApiResources.FirstTimeUserExperience[data.id].url;
        const knowledgeCenter = {
          knowledgeCenterDomain:
            sessionManager.LEConfig.topLevelExternalLinks.knowledgeCenter
        };
        const developersCenter = {
          developersCommunityDomain:
            sessionManager.LEConfig.topLevelExternalLinks.developersCommunity
        };
        if (
          data.id === 'conversational' ||
          data.id === 'knowledgeCenter' ||
          data.id === 'learnMoreProactive'
        ) {
          window.open(urlResolver.getUrl(baseUrl, knowledgeCenter), '_blank');
        } else if (data.id === 'upgrade') {
          window.open(baseUrl, '_blank');
        } else if (data.id === 'developersCenter') {
          window.open(urlResolver.getUrl(baseUrl, developersCenter), '_blank');
        }
      }
    },
    handleButtonClick: function(data) {
      this.addSection({ response: data.text });

      // Handles when there are mutible responses
      if (data.responses[1]) {
        this.addSection({
          text: data.responses[0].text,
          textLoading: data.responses[0].textLoading,
          showBotIcon: true,
          delay: LOADING_DELAY,
          callback: () => {
            this.addSection({
              text: data.responses[1].text,
              textLoading: data.responses[1].textLoading,
              delay: LOADING_DELAY,
              callback: () => {
                this.nextStep(data);
              }
            });
          }
        });
      } else {
        //handles when you have one response
        this.addSection({
          text: data.responses[0].text,
          textLoading: data.responses[0].textLoading,
          delay: LOADING_DELAY,
          showBotIcon: true,
          callback: () => {
            this.nextStep(data);
          }
        });
      }
    },
    nextStep: function(data) {
      // opens new url or adds next set of buttons
      if (data.externalUrl || data.internalUrl || data.openWebEmulator) {
        const delay = 2500 + data.responses[0].text.length * 70;
        const timeout = setTimeout(() => {
          this.closeModal();
          this.openUrl(data);
        }, delay);
        // Save timeout for cancelling later
        this.timeouts.push(timeout);
      }
      if (data.buttons) {
        this.addSection({ buttons: data.buttons });
      }
    }
  }
};
</script>
