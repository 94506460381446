/**
 * Created by danielr on 9/21/15.
 */
define(function (require) {
    "use strict";
    var BaseGallery = require("ui/gallery/gallery");
    var AppKeysGalleryItemView = require("ui.components/gallery/js/views/AppKeysGalleryItemView");

    var AppKeysGallery = BaseGallery.extend({

        itemView: AppKeysGalleryItemView,
        //----------------------------------------
        // initialize
        //---------------------------------------

        initialize:function (options) {
            BaseGallery.prototype.initialize.call(this, options);
        }
    });

    return AppKeysGallery;
});
