/**
 * V1.0
 *
 * User: ivan
 * Date: 28/8/14
 *
 * This component is used to show a no data page with an option to have a refresh button.
 *
 * Options:
 * el - mandatory. jQuery element. The element on the page where the button will be created.
 * permutation - optional. Responsible for the no data permutation: PERMUTATION.DEFAULT - no refresh link (default), REFRESH_LINK - with refresh link.
 * btnPermutation - optional. Responsible for the button style. Button.PERMUTATION.BLUELINK is the default.
 * btnText - optional. Responsible for the button's text. "Refresh" is the default.
 * callback - optional. A function which will be called when button is clicked. (if no function was supplied nothing will happen when clicking on the link).
 * errorMessage - optional. Responsible for the error text. "We seem to have lost track of this page's content." is the default.
 *
 * Using Example:
 *---------------------------
 * template:
 * this.noData = new NoData({
 *      el:'.interaction-area-item-wrapper.open',
 *      permutation: NoData.PERMUTATION.REFRESH_LINK,
 *      callback: _.bind(_btnActionClick, this)
 *  });
 * this.noData.render();
 *
 * *******************************************************************************************************
 */


define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var WidgetUtils = require('ui/WidgetUtils');
    var template = require("./templates/noData.tmpl");
    var Button = require("ui/button/Button");
    var Translator = require("i18n/translator");

    var PERMUTATION = {
        DEFAULT   :1,
        REFRESH_LINK   :2,
        REFRESH_LINK_MEDIUM  :3
    };

    var NoData = Marionette.ItemView.extend({
        template: template,
        ui: {
            btnAction: ".btnAction",
            error404: ".error404"
        },
        withUIComponents: true,

        customTemplateHelpers: function() {
            return {
                errorMessage: this.errorMessage
            };
        },
        initialize: function(options) {
            this.errorMessage = options.errorMessage || Translator.translate("LEUIComponents.errors.no.data.404");
            this.permutation = options.permutation || PERMUTATION.DEFAULT;
            this.btnPermutation = options.permutationButton || Button.PERMUTATION.BLUELINK;
            this.btnText = options.btnText || Translator.translate("LEUIComponents.refresh.data");
            this.callback = options.callback || null;
        },
        onRender: function() {
            this.ui.error404.toggleClass("refresh-link-medium", (this.permutation === PERMUTATION.REFRESH_LINK_MEDIUM));
            this._renderRefreshButton();
        },
        _renderRefreshButton: function() {
            if (this.permutation === PERMUTATION.REFRESH_LINK || this.permutation === PERMUTATION.REFRESH_LINK_MEDIUM) {
                this.uiComponents.host$.createAndRender("btnAction", {
                    type: Button,
                    options: {
                        el: this.ui.btnAction,
                        permutation: this.btnPermutation,
                        text: this.btnText
                    },
                    events: {
                        click: this.callback
                    }
                });
            }
        }
    });

    NoData.PERMUTATION = PERMUTATION;
    NoData.BUTTON_PERMUTATION = Button.PERMUTATION;

    return NoData;

});
