/**
 * Created with IntelliJ IDEA.
 * User: itaik
 * Date: 4/15/14
 * Time: 2:00 PM
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var Template = require("templates/defaultDatabar.tmpl");

    var DefaultDatabar = Marionette.ItemView.extend({
        template: Template
    });

    return DefaultDatabar;
});

