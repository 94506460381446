define(function (require) {
    'use strict';

    var LE = require('app');
    var AgentManagerDependencyModel =  require("data-models/AgentManagerDependencyModel");
    var SkillsCollection = require("collections/entities/SkillsCollection");
    var ProfilesCollection = require("collections/entities/ProfilesCollection");
    var CountriesCollection = require("collections/entities/CountriesCollection");
    var LobsCollection = require("collections/entities/LobsCollection");
    var LobRelationsCollection = require("collections/entities/LobRelationsCollection");
    var AgentReasonsCollection = require("collections/entities/AgentReasonsCollection");
    var AppInstallsCollection = require("collections/entities/AppInstallsCollection");
    var UsersCollection = require("collections/entities/UsersCollection");
    var SoftwaresCollection = require("collections/entities/SoftwaresCollection");
    var UserModel = require("models/entities/UserModel");
    var LEConfig = require("assets/config/le-env-conf");
    var PostSurveyCollection = require("collections/entities/PostSurveyCollection");
    var IvrCollection = require("collections/entities/IvrCollection");
    var AgentSurveyCollection = require("collections/entities/AgentSurveysCollection");
    const { PERSONA } =  require('vue-infra').sessionKeys;

    var DataController = {};
    /**
     * The Idea is very simple
     * Central Controller for Data Living in a Unique Data Module that is loaded always as the first module of the application.
     *
     */
    LE.module('LEDataModule', function (LEDataModule, LE, Backbone, Marionette, $, _) {

        DataController = Marionette.Controller.extend({

            initialize:function () {
                LEDataModule.logger.info("initialize","DataController:initialize");
                this.agentManagerModel = new AgentManagerDependencyModel();
                this.agentManagerModelV3 = new AgentManagerDependencyModel({ getLatest: true });
                this.skillsCollection =  new SkillsCollection();
                this.profilesCollection =  new ProfilesCollection();
                this.countriesCollection =  new CountriesCollection();
                this.lobsCollection =  new LobsCollection();
                this.lobRelationsCollection =  new LobRelationsCollection();
                this.agentReasonsCollection = new AgentReasonsCollection();
                this.appInstallsCollection = new AppInstallsCollection();
                this.softwaresCollection = new SoftwaresCollection();
                this.loggedInUserModel = new UserModel({id:LE.sessionManager.getUserId()}, {isMyUser: true});
                this.agentsInSameGroupCollection = new UsersCollection();
                this.postSurveyCollection = new PostSurveyCollection();
                this.agentSurveysCollection = new AgentSurveyCollection();
                this.isScaleLimitedFunctionalityEnabled = LE.siteSettings.accountSettings.isUsersLimitedDueToScale();
                if (!this.isScaleLimitedFunctionalityEnabled) {
                    this._handleAgentManagerModel();
                }
                this.ivrCollection = new IvrCollection();
                this._handleAgentManagerModel();
                this._handleLoggedInUserModel();
                this._handleSkillsCollection();
                this._handleCountriesCollection();
                this._handleAgentReasonsCollection();
                this._handlePostSurveyCollection();
                this._handleAgentSurveyCollection();
            },
            getAgentManagerModel: function(){
                return  this.agentManagerModel;
            },
            getAgentManagerModelV3: function () {
                return this.agentManagerModelV3;
            },
            getSkillsCollection: function(){
                return  this.skillsCollection;
            },
            getProfilesCollection: function(){
                return this.profilesCollection;
            },
            getCountriesCollection: function(){
              return this.countriesCollection;
            },
            getLobsCollection: function() {
                return this.lobsCollection;
            },
            getLobRelationsCollection: function() {
                return this.lobRelationsCollection;
            },
            getLoggedInUserModel: function() {
                return this.loggedInUserModel;
            },
            getAgentReasonsCollection: function() {
                return this.agentReasonsCollection;
            },
            getAppInstallsCollection: function() {
                return this.appInstallsCollection;
            },
            getSoftwaresCollection: function() {
                return this.softwaresCollection;
            },
            getAgentsInSameGroup: function() {
                return this.agentsInSameGroupCollection;
            },
            getAllowedLobs: function() {
                return this._getAllowedLobs();
            },
            getPostSurveyCollection: function(){
                return  this.postSurveyCollection;
            },
            getIvrCollection: function(){
              return this.ivrCollection;
            },
            getAgentSurveyCollection: function(){
                return  this.agentSurveysCollection;
            },
            isAllLobsAllowedForUser: function() {
                return this._isAllLobsAllowedForUser();
            },
            isLobAllowedForUser: function(lobId) {
                return this._isLobAllowedForUser(lobId);
            },
            isSkillAllowedForSelection: function(skillLobs, entityLobs) {
                return this._isSkillAllowedForSelection(skillLobs, entityLobs);
            },
            onClose: function() {
                LEDataModule.logger.info("onClose","DataController:onClose");
            },

            //private funcs:
            _handleAgentManagerModel: function() {
                // need the agent manager model if the feature is enabled, the user is not LPA and the user is agent manager/user manager/campaign manager (actually, if he is not an agent)
                if (LE.sessionManager.hasAgentGroupsFeature() && !LE.sessionManager.getConfig().isLPA &&
                    LE.sessionManager.hasPrivilege([ LE.sessionManager.PRIVILEGES.LE_VIEW_USER_MANAGEMENT_MODULE, LE.sessionManager.PRIVILEGES.LE_VIEW_AGENT_MANAGER_MODULE, LE.sessionManager.PRIVILEGES.LE_VIEW_CAMPAIGN_MODULE ], true)) {


                    this.agentManagerModelV3.startRefresh({ fetchOptions: { data: { deleted: true } } });
                    this.agentManagerModel.startRefresh();

                    LE.context.bind('change:persona', _.bind(function (model, eventData) {  //eventData is the persona label
                        if (eventData && LE.moduleManager.hasPersona(eventData)) {
                            var persona = eventData;
                            if ((persona === PERSONA.AGENT)) {
                                    this.agentManagerModelV3.startRefresh({ fetchOptions: { data: { deleted: !!LEConfig.supportDeletedUsers } } });
                            } else {
                                    this.agentManagerModelV3.stopRefresh();
                            }

                        }
                    }, this));
                }
            },
            _handleLoggedInUserModel: function() {
                if (!LE.sessionManager.getConfig().isLPA) {
                    this.loggedInUserModel.fetch({
                        success: _.bind(this._onLoggedInUserFetchSuccess, this)
                    });
                }
                //we need this fetched at least once on application start to be used by all modules.
                //The ones that need it more fresh can call fetch themselves
            },

            _onLoggedInUserFetchSuccess: function() {
                var loggedInUserMemberOfGroup = this.loggedInUserModel.getMemberOfGroup();
                var includeDeleted = !!LEConfig.supportDeletedUsers;
                if (loggedInUserMemberOfGroup) {
                    this.agentsInSameGroupCollection.fetch({
                        data: {
                            "agentGroupId": loggedInUserMemberOfGroup,
                            "include_deleted": includeDeleted
                        }
                    });
                }
            },

            _handleSkillsCollection: function(){
                this.skillsCollection.fetch({});
                //currently we fetch it only once on application start,
                // any module that need it fresh should use this same collection and fetch it.
                //we might later want to fetch it periodically.
                //but we shall decide after all modules start using it.
            },
            _handleCountriesCollection: function(){
              this.countriesCollection.fetch({});
            },

            _handleAgentReasonsCollection: function() {
                if (this.agentReasonsCollection.isCollectionAllowed()) {
                    this.agentReasonsCollection.fetch({});
                }
            },

            _handlePostSurveyCollection: function(){
                this.postSurveyCollection.fetch({});

            },

            _handleAgentSurveyCollection: function(){

              if (LE.sessionManager.getFeaturePropertyState('Messaging.Agent_Survey')) {
                  this.agentSurveysCollection.fetch({});
              }
            },

            _getAllowedLobs: function() {
                if (_.isUndefined(this.allowedLobs) || this.allowedLobsRevision !== this.lobsCollection.getRevision()) {
                    var allowedLobs = [];
                    var userLobIds = this.loggedInUserModel.getLobs();

                    if (_.isEmpty(userLobIds)) {
                        this.lobsCollection.each(function(lobModel) {
                            allowedLobs.push(lobModel);
                        });
                    }
                    else {
                        _.each(userLobIds, _.bind(function(userLobId) {
                            allowedLobs.push(this.lobsCollection.get(userLobId));
                        }, this));
                    }

                    allowedLobs = _.sortBy(allowedLobs, function(lobModel) {return lobModel.getName();});
                    this.allowedLobs = allowedLobs;
                    this.allowedLobsRevision = this.lobsCollection.getRevision();
                }

                return this.allowedLobs;
            },

            _isAllLobsAllowedForUser: function() {
                if (_.isUndefined(this.isAllLobsAllowed)) {
                    var userLobIds = this.loggedInUserModel.getLobs();
                    this.isAllLobsAllowed = _.isEmpty(userLobIds);
                }

                return this.isAllLobsAllowed;
            },

            _isLobAllowedForUser: function(lobId) {
                var lobIds = _.isArray(lobId) ? lobId : [lobId];
                var userLobIds = this.loggedInUserModel.getLobs();

                var allowed = (this._isAllLobsAllowedForUser() || _.intersection(userLobIds, lobIds).length > 0);

                return allowed;
            },

            _isSkillAllowedForSelection: function(skillLobs, entityLobs) {
                var isSkillAllowed = false;
                var allowedLobIdsForUser = _.pluck(this._getAllowedLobs(), "id");

                if (_.isEmpty(skillLobs)) { //skill not connected to lobs
                    isSkillAllowed = true;
                }
                else {
                    if (this._isAllLobsAllowedForUser()) { // user connected to all lobs
                        isSkillAllowed = _.isEmpty(entityLobs) || _.intersection(skillLobs, entityLobs).length > 0; // entity connected to all lobs OR skill and content lobs intersects
                    }
                    else {
                        if (_.isEmpty(entityLobs)) { // user and skill connected to some lobs, entity connected to all lobs
                            isSkillAllowed = _.intersection(skillLobs, allowedLobIdsForUser).length > 0; // make sure skill and user lobs intersects
                        }
                        else { // user, skill and entity connected to some lobs
                            isSkillAllowed = _.intersection(skillLobs, allowedLobIdsForUser, entityLobs).length > 0; // make sure user, skill and entity lobs intersects
                        }
                    }
                }

                return isSkillAllowed;
            }

        });
    });
    return DataController;
});
