/**
 * Created by noam on 11/29/15.
 */
define(function (require) {
    "use strict";

    //require
    var _ = require("underscore");
    var { Logger } = require('vue-infra');

    var CONSTS = {
         REVISION_HEADER: "ETag",       //old version revision header
         REVISION2_HEADER:"ac-revision",   //newer version revision header - used when services moves to nginx
         REVISION_MODIFIED_HEADER: "X-LP-Last-Modified" //last modified revision header - used with domain complete entities (meta)
    };

    var RevisionUtility = (function () {
        // constants

        var logger = Logger.getLogger("LEFramework");
        /**
         * parse the revision header from the xhr,
         * know to support both old and new style headers.
         * @param xhr
         */
        function parseRevisionFromXhr(xhr) {
            if (_.isEmpty(xhr)){
                logger.error("xhr should not be empty","RevisionUtility:parseRevisionFromXhr");
            }

            var revision;
            var lastModified;
            var revisionObject;
            var allResponseHeaders = xhr.getAllResponseHeaders();

            if (_.isPlainObject(allResponseHeaders)) {
                revision = allResponseHeaders[CONSTS.REVISION2_HEADER];
                if (_.isNull(revision) || _.isUndefined(revision)) {
                    revision = allResponseHeaders[CONSTS.REVISION_HEADER];
                }
                lastModified = allResponseHeaders[CONSTS.REVISION_MODIFIED_HEADER];
            }
            else {
                revision = xhr.getResponseHeader(CONSTS.REVISION2_HEADER);
                if (_.isNull(revision) || _.isUndefined(revision)) {
                    revision = xhr.getResponseHeader(CONSTS.REVISION_HEADER);
                }
                lastModified = xhr.getResponseHeader(CONSTS.REVISION_MODIFIED_HEADER);
            }

            if (revision) {
                revisionObject = {};
                revisionObject.val = revision;
            }

            if (lastModified) {
                revisionObject = revisionObject || {};
                revisionObject.lastModified = lastModified;
            }

            return revisionObject;
        }

        return {
            parseRevisionFromXhr: parseRevisionFromXhr,
            CONSTS: CONSTS
        };
    }());

    return RevisionUtility;
});

