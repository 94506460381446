/**
 * Created by tomers on 9/1/2014.
 * This is the default header view
 * For internal use of page component only. if you import this from out side I will @#$ you
 */
define(function (require) {
    "use strict";


    var BasicPageHeaderView = require("ui/page/js/views/PageHeaderView");
    var template = require("ui.components/page/templates/pageHeaderView.tmpl");

    var _ = require("underscore");
    var Translator = require("i18n/translator");

    var PageHeaderViewDependencyMethods = require("ui.components/page/js/views/PageHeaderViewDependencyMethods");
    var DependencyIndicator = require("ui.components/dependency-indicator/DependencyIndicator");
    var KpContent = require("ui.components/kpContent/kpContent");
    var SelectableCollectionDecorator = require("decorators/SelectableCollectionDecorator");


    var PageHeaderView = BasicPageHeaderView.extend({
        template: template,
        withUIComponents: true,
        ui: {
            title: ".page-title .title-content",
            badgeWrapper: ".page-title .badge-wrapper",
            dependencyIndicator: ".page-title .dependency-indicator-container",
            description: ".page-description .description-content",
            knowledgePack: ".page-description .knowledge-pack-container"
        },
        initialize: function(options) {
            BasicPageHeaderView.prototype.initialize.call(this, options);
            this.knowledgePack = options.knowledgePack;
            this.dependency = options.dependency;
            this.badge = options.badge;
        },
        onRender: function() {
            BasicPageHeaderView.prototype.onRender.call(this);
            this.updateKnowledgePack();
            this.updateDependency();
            this.updateBadge();
        },
        updateKnowledgePack: function() {
            this.ui.knowledgePack.removeClass("show");
            if (this.uiComponents && this.uiComponents.kpContent) {
                this.uiComponents.kpContent.close();
            }

            if (!_.isEmpty(this.knowledgePack)) {
                this.uiComponents.host$.createAndRender("kpContent", {
                    type : KpContent,
                    options : {
                        container: "view.ui.knowledgePack",
                        KPLink: this.knowledgePack.text,
                        kpID: this.knowledgePack.eventId
                    }
                });

                this.ui.knowledgePack.addClass("show");
            }
        },
        updateDependency: function() {
            if (this.uiComponents && this.uiComponents.dependencyIndicator) {
                this.uiComponents.dependencyIndicator.close();
            }

            if (!_.isEmpty(this.dependency)) {
                this.uiComponents.host$.createAndRender("dependencyIndicator", {
                    type: DependencyIndicator,
                    options: {
                        container : "view.ui.dependencyIndicator",
                        tooltipTitle: Translator.translate("LEUIComponents.page.dependencies.loading")
                    },
                    events: {
                        "item:clicked": "onDependencyIndicatorItemClick"
                    }
                });

                this.uiComponents.dependencyIndicator.hide();
                this.fetchDependencies({
                    model: this.dependency.model,
                    success: _.bind(this._fetchDependenciesSuccess, this)
                });
            }
        },
        updateBadge: function() {
            var badgeWrapper = this.ui.badgeWrapper;
            badgeWrapper.empty();

            if (!_.isArray(this.badge)) {
                this.badge = [this.badge];
            }

            this.badge.forEach(function(item) {
                var badge = this.generateBadge(item);

                if (badge) {
                  badgeWrapper.append(badge);
                }
            }, this);
        },
        generateBadge: function(text) {
            return text ? $('<span>').addClass('badge').text(text) : null;
        },
        _fetchDependenciesSuccess: function(dependencyCollection) {
            var config;

            if (dependencyCollection.size() > 0) {
                if (_.isFunction(this.dependency.success)) {
                    config = this.dependency.success(dependencyCollection);
                }

                this.uiComponents.dependencyIndicator.show();

                if (!_.isEmpty(config)) {
                    if (!_.isEmpty(config.title)) {
                        this.uiComponents.dependencyIndicator.setTooltipTitle(config.title);
                    }
                    if (!_.isEmpty(config.dependencyArr)) {
                        this.uiComponents.dependencyIndicator.setDependencyCollection(new DependencyIndicator.Collection(config.dependencyArr));
                    }
                    if (!_.isEmpty(config.footer)) {
                        this.uiComponents.dependencyIndicator.setTooltipFooterText(config.footer);
                    }
                }

                this.uiComponents.dependencyIndicator.refreshTooltip();
            }
        },
        onDependencyIndicatorItemClick: function(componentId, eventName, model) {
            if (this.dependency && _.isFunction(this.dependency.onItemClick)) {
                this.dependency.onItemClick(model);
            }
        },
        setKnowledgePack: function(knowledgePack) {
            this.knowledgePack = knowledgePack;
            this.updateKnowledgePack();
        },
        setDependency: function(dependency) {
            this.dependency = dependency;
            this.updateDependency();
        },
        setBadge: function(badge) {
            this.badge = badge;
            this.updateBadge();
        }
    });

    _.extend(PageHeaderView.prototype, PageHeaderViewDependencyMethods);

    return PageHeaderView;
});

