/**
 * Created by vanessat on 3/25/15.
 */

define (function(require) {
    'use strict';
    var Attribution = require("codeTables/attribution.json");
  var _ = require('underscore');

    var attributionSettings = (function() {
        var currentAttribution = null;

        var getAttributionList = function() {
            return Attribution.attributionCodes;
        };

        var getCurrentAttribution = function() {
            if (currentAttribution === null) {
                currentAttribution = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.ATTRIBUTION, Attribution.defaultAttribution);
            }
            return currentAttribution;
        };

        var getAttributionListTranslated = function() {
            if (_.isUndefined(Attribution.attributionCodesTranslated)) {
                translateAttributionCodes();
            }
            return Attribution.attributionCodesTranslated;
        };

        var translateAttributionCodes = function() {
            Attribution.attributionCodesTranslated = [];

            _.each(Attribution.attributionCodes, function (value, key) {
                Attribution.attributionCodesTranslated.push({"key": key, "value": LE.translator.translate("LEFramework." + value)});
            });
        };

        var switchAttribution = function(attribution) {
            currentAttribution = attribution;
            LE.vent.trigger("siteSettings:attributionSwitched", currentAttribution);
        };

        var getMessagingAttributionListTranslated = function() {
          if (_.isUndefined(Attribution.messagingAttributionCodesTranslated)) {
            translateMessagingAttributionCodes();
          }
          return Attribution.messagingAttributionCodesTranslated;
        };

        var translateMessagingAttributionCodes = function() {
          Attribution.messagingAttributionCodesTranslated = [];

          _.each(Attribution.messagingAttributionCodes, function (value, key) {
            Attribution.messagingAttributionCodesTranslated.push({"key": key, "value": LE.translator.translate("LEFramework." + value)});
          });
        };

        return {
            getAttributionList: getAttributionList,
            getCurrentAttribution: getCurrentAttribution,
            getAttributionListTranslated: getAttributionListTranslated,
            switchAttribution: switchAttribution,
            getMessagingAttributionListTranslated: getMessagingAttributionListTranslated,
        };

    })();

    return attributionSettings;

});
