<template>
  <div
    :class="classes"
    :data-lp-at="item.title + `-button`"
    role="button"
    tabindex="0"
    :aria-label="item.title"
    @click="onSwitchItem"
    @keydown.enter.space.prevent="onSwitchItem"
  >
    <div
      :data-lp-at="item.title + `-icon`"
      :class="`tlmv-s-icon s-${item.iconClass}`"
    ></div>
    <div
      class="top-level-menu-item-title"
      :data-lp-at="item.title + `-title`"
    >
      {{ item.title }}
    </div>
    <div
      :class="indicatorClasses"
      :data-lp-at="item.title + `-text`"
    >
      {{ item.indicatorText }}
    </div>
    <div
      v-if="item.openInNewTab"
      :data-lp-at="item.title + `-external`"
      style="margin-left: auto;margin-right: 10px;"
      class="tlmv-s-icon s-link-external"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TopLevelMenuItemView",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    selectedTheme: {
      type: Object,
      default: () => {},
    }
  },
  emits: ['clicked'],
  computed: {
    indicatorClasses() {
      const indicatorClass = this.item.indicatorClass || '';
      return {
        'top-level-menu-item-indicator': true,
        [indicatorClass]: true
      }
    },
    classes() {
      return {
        'top-level-menu-item': true,
        enabled: this.item.enabled
      }
    }
  },
  methods: {
    onSwitchItem(event) {
      event.stopPropagation();
      if (this.item.openInNewTab) {
        window.LE.navigator.open(this.item.switcherRoute, 'service_status_dashboard');
      } else {
        this.$router.push(this.item.switcherRoute);
      }
      this.$emit("clicked");
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/global";
@import "../../../../assets/scss/mixins";

.tlmv-s-icon {
  width: 16px;
  height: 16px;
}

$icons: s-calendar, s-web-tag, s-user, s-clipboard, s-speedometer, s-link-external;
@include add-icons-from-list($icons);
</style>
