<template>
  <li>
    <component
      :aria-label="
        `${
          subCategory.isExternal
            ? $translator.translate(
                'LEFramework.' + subCategory.language.appDisplayName
              ) +
              ' ' +
              $translator.translate('LEFramework.navbar.application.newTab')
            : $translator.translate(
                'LEFramework.' + subCategory.language.appDisplayName
              )
        }`
      "
      :aria-current="
        String(
          selectedCategoryIndex === index &&
            selectedSectionIndex === sectionIndex &&
            selectedSubCategoryIndex === subCategoryIndex
        )
      "
      :target="`${subCategory && subCategory.isExternal ? '_blank' : '_self'}`"
      :href="
        `${subCategory && subCategory.isExternal ? '' : '#'}${subCategory &&
          subCategory.routeConfig &&
          subCategory.routeConfig.switcherRoute}`
      "
      :is="'a'"
      :title="
        subCategory &&
          subCategory.routeConfig &&
          subCategory.routeConfig.notification &&
          subCategory.routeConfig.notification.tooltip
      "
      :tooltip-text="
        subCategory &&
          subCategory.routeConfig &&
          subCategory.routeConfig.notification &&
          subCategory.routeConfig.notification.tooltip
      "
      tooltip-position="right"
      :data-lp-at="`${subCategory.language.appDisplayName}`"
      :class="[
        'nav-list__item',
        'nav-list__item nav-list__item-nested',
        {
          'nav-list__item-selected':
            selectedCategoryIndex === index &&
            selectedSectionIndex === sectionIndex &&
            selectedSubCategoryIndex === subCategoryIndex
        }
      ]"
      @click="
        onSwitchItem(subCategory, index, sectionIndex, subCategoryIndex, $event)
      "
      @keydown.enter="
        onSwitchItem(subCategory, index, sectionIndex, subCategoryIndex, $event)
      "
    >
      <div
        class="nav-list__item__title"
        :data-lp-at="`list-sub-category-${subCategoryIndex}`"
      >
        {{
          $translator.translate(
            'LEFramework.' + subCategory.language.appDisplayName
          )
        }}
      </div>
      <notification-bubble
        v-show="
          subCategory &&
            subCategory.routeConfig.notification &&
            subCategory.routeConfig.notification.count > 0 &&
            openCategoryIndex === index &&
            isCategoryOpen
        "
        :notification="subCategory && subCategory.routeConfig.notification"
        class="mr-10"
      ></notification-bubble>
      <div
        v-if="subCategory.isExternal"
        class="nav-list__item__external"
        :data-lp-at="`list-is-external-${subCategoryIndex}`"
      >
        <div class="ps-s-icon s-trail"></div>
      </div>
    </component>
  </li>
</template>

<script>
import NotificationBubble from '../NotificationBubble';

export default {
  name: 'SubCategoryItem',
  components: {
    NotificationBubble
  },
  props: {
    subCategory: Object,
    index: Number,
    sectionIndex: Number,
    subCategoryIndex: Number,
    selectedCategoryIndex: Number,
    selectedSectionIndex: Number,
    selectedSubCategoryIndex: Number,
    openCategoryIndex: Number,
    isCategoryOpen: Boolean
  },
  methods: {
    onSwitchItem(item) {
      if (item && item.isExternal) {return;}
      const { routeConfig } = item;
      this.$emit('requestSwitch', {
        routeConfig,
        subCategory: this.subCategory,
        subCategoryIndex: this.subCategoryIndex
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/navigation_bar';
@import '../../../../assets/scss/navigation_bar_icons';

.ps-s-icon {
  width: 24px;
  height: 24px;
}

.mr-10 {
  margin-right: 10px;
}
</style>
