import App from 'app';
import { gChannel } from 'vue-infra';
import BaseAppender from './BaseAppender';
import LEConfig from 'assets/config/le-env-conf';


const PERMISSION = {
  DEFAULT: 'default',
  GRANTED: 'granted',
  DENIED: 'denied',
};

const defaultOptions = {
  iconUrl: '/assets/img/LP-logo.png',
  focusStatus: LEConfig.NotificationsDefaults.DesktopNotification.focusStatus,
};
const requestPermission = function () {
  Notification.requestPermission(permission => {
    if (!('permission' in Notification)) {
      Notification.permission = permission;
    }
  });
  window.removeEventListener('click', requestPermission);
};


class DesktopNotification extends BaseAppender {

  constructor() {
    super();

    if (!this.isSupported()) {
      return;
    }

    this._setWindowEvents();

    this.notificationCounter = 0;
    this.notifications = {};
  }

  isSupported() {
    return this._hasPlatformSupport() && this._hasAppSupport();
  }

  getDefaultOptions() {
    return Object.freeze(defaultOptions);
  }

  /**
   * @param options
   * @param options.title
   * @param options.body
   * @param options.iconUrl
   * @param options.onClick
   * @param options.onClose
   * @param options.onShow
   * @param options.persona
   * @param options.focusStatus
   */
  notify(options) {
    if (!options.focusStatus || App.context.getFocusStatus() > options.focusStatus) {
      return;
    }

    const tag = this._getNotificationTag();

    this.notifications[tag] = new window.Notification(options.title, {
      icon: options.iconUrl,
      body: options.body,
      tag,
    });
    this.notifications[tag].onshow = this._onNotificationShow.bind(this, options);
    this.notifications[tag].onclose = this._onNotificationClose.bind(this, options);
    this.notifications[tag].onclick = this._onNotificationClick.bind(this, options);

    return { tag };
  }

  clearNotification(options) {
    this._closeNotification(options.tag);
  }

  isValidOptions(options) {
    const isValid = super.isValidOptions(options);
    if (isValid) {
      if (!options.title) {
        return `'title' is invalid or missing`;
      }
      if (typeof options.body === 'undefined') {
        return `'body' is invalid or missing`;
      }
    }
    return isValid;
  }

  isValidClearNotificationOptions(options) {
    const isValid = super.isValidOptions(options);
    if (isValid) {
      if (!options.tag) {
        return `'tag' is invalid or missing`;
      }
    }
    return isValid;
  }

  _hasPlatformSupport() {
    return typeof window.Notification !== 'undefined';
  }

  _hasAppSupport() {
    const { loginInfo: { accountId } = {} } = App;
    return accountId && accountId !== 'new';
  }

  _setWindowEvents() {
    this._setRequestPermission();
    this._setCloseAllOnUnload();
  }

  _getNotificationTag() {
    this.notificationCounter++;
    return this.notificationCounter;
  }

  _setRequestPermission() {
    if (!Notification.permission || Notification.permission !== PERMISSION.GRANTED) {
      window.addEventListener('click', requestPermission);
    }
  }

  _setCloseAllOnUnload() {
    window.addEventListener('unload', this._closeAllNotifications.bind(this));
  }

  _openWindow(options) {
    if (window.focus) {
      window.focus();
    }
    // todo - use data from the vue store instead of app context
    if (options.persona && App.context.get('persona') !== options.persona) {
      this._openPersonaModule(options);
    }
  }

  _onNotificationClick(options, ev, tag) {
    this._openWindow(options);
    if (typeof options.onClick === 'function') {
      options.onClick(ev.currentTarget.tag);
    }
  }

  _onNotificationClose(options, ev) {
    if (typeof options.onClose === 'function') {
      options.onClose(ev.currentTarget.tag);
    }
    const notification = ev.currentTarget;
    delete this.notifications[notification.tag];
  }

  _onNotificationShow(options, ev) {
    const currentTag = this.currentTag;
    if (currentTag) {
      setTimeout(() => {
        this._closeNotification(currentTag);
      }, 5000);
    }
    this.currentTag = ev.currentTarget.tag;

    if (typeof options.onShow === 'function') {
      options.onShow(ev.currentTarget.tag);
    }
  }

  _closeNotification(tag) {
    if (this.notifications[tag]) {
      this.notifications[tag].close();
    }
  }

  _openPersonaModule(options) {
    gChannel.trigger('personaSwitcher', 'switch', {
      switchedPersona: options.persona,
      backBrowser: false,
    });
  }

  _closeAllNotifications() {
    for (const key in this.notifications) {
      this.notifications[key].close();
    }
  }

}


export default DesktopNotification;
