define(function(require){
    var _ = require('underscore');
    var proxyCache = {};
    var $ = require("jquery");
    var { Logger, vueExports } = require('vue-infra');
    var logger = Logger.getLogger("LEFramework");
    const mainVueApps = require('src/components/mainVueApps');
    const { h } = vueExports;

    var LE2Proxy = {
        getMarrionetteModuleProxy: function (moduleName) {
            var componentName = moduleName + "Proxy";
            if (_.isUndefined(proxyCache[componentName])) {
                proxyCache[componentName] = mainVueApps.navBar.component(componentName, {
                    setup() {
                      return () => h('div', { class: componentName });
                    },
                    data: function () {
                        return {};
                    },
                    activated: function () {
                        // window.LE[moduleName].$router = this.$router;
                        logger.debug('activated ' + componentName + ' vue layout');

                        var wrapperClass = window.LE.moduleManager.moduleRegions[moduleName];
                        $('.' + wrapperClass + '-wrapper').css("visibility", "visible");
                        // TODO: recheck if this is needed after all child routes are working
                        // var module = window.LE[moduleName];
                        // var routeController = module.getRouteController();
                        // var route = this.$route;
                        // var routeObj = this.$router.options.routes.find(function(r) {return r.path === route.path});
                        // var params = prepareParametersForMarrionetteRouterCallback(route);
                        // routeController[routeObj.children[0].meta.routeControllerCallback].apply(routeController, params);
                    },
                    deactivated: function () {
                        logger.debug('Deactivated ' + componentName + ' vue layout');
                        // window.LE.hideModule(moduleName);
                        var wrapperClass = window.LE.moduleManager.moduleRegions[moduleName];
                        $('.' + wrapperClass + '-wrapper').css("visibility", "hidden");
                    },
                    methods: {}
                });
            }
            return proxyCache[componentName];
        },
        getMarrionetteModuleChildProxy: function (moduleName) {
            var componentName = moduleName + 'ChildProxy';
            if (_.isUndefined(proxyCache[componentName])) {
                proxyCache[componentName] = mainVueApps.navBar.component(componentName, {
                    setup() {
                      return () => h('div', { class: componentName });
                    },
                    data: function () {
                        return {
                            lastRoute: '',
                            inProcessPath: ''
                        };
                    },
                    activated: function () {
                        logger.debug('activated ' + componentName + ' LE.inProcessPath: ' + LE.inProcessPath);
                        // LE.inProcessPath is a variable that hold the current path being processed, we are checking to make sure we do not process the same path twice
                        if (LE.inProcessPath !== this.$route.path) {
                            this.navigateUsingMarrionetteRouteController(this.$route);
                        }
                    },
                    watch: {
                        $route: function (to, from) {
                            logger.debug(componentName + ' Route change : ' + to.fullPath + ' from: ' + from.fullPath + ' LE.inProcessPath: ' + LE.inProcessPath);
                            // vue activate change route here even when the component is not active so we need to check we are at the same
                            // if marrionetteModule use backbone controller
                             LE.navigator.prevRoute = from;
                             // LE.inProcessPath is a variable that hold the current path being processed, we are checking to make sure we do not process the same path twice
                            if (to.meta.marrionetteModule && to.meta.marrionetteModule === moduleName && LE.inProcessPath !== to.path) {
                                this.navigateUsingMarrionetteRouteController(to);
                            }
                        }
                    },
                    methods: {
                        navigateUsingMarrionetteRouteController: function (route) {
                            var module = LE[moduleName] || LE;
                            var routeController = module.getRouteController && module.getRouteController() || LE.routeController;
                            this.lastRoute = route;
                            this.setInProcessPath(route);
                            module.$route = route;
                            LE.navigator.route = route;
                            var callback = route.meta.routeControllerCallback && routeController[route.meta.routeControllerCallback];
                            LE.navigator.registerContextInformation(module.moduleName, callback, route.path, routeController);
                            LE.navigator.setActiveRouteController(routeController);
                            if (callback) {
                                callback.apply(routeController, prepareParametersForMarrionetteRouterCallback(route));
                            }
                            LE.navigator.vent.trigger("module:active", {moduleName: moduleName});
                        },
                        // When a route is being processed we set the LE.inProcessPath variable 
                        // After 1 second we clear that variable 
                        // The functions above check against this variable to verify we are not trying to process the same route multiple times in 1 second
                        setInProcessPath: function (route) {
                            LE.inProcessPath = route.path;
                            setTimeout(function (context) {
                                LE.inProcessPath = '';
                            }, 1000, this);
                        }
                    }
                });
                // Accounting for the following warning "If you were passing a functional component, make sure to add a "displayName" to the component."
                proxyCache[componentName].displayName = componentName;
            }
            return proxyCache[componentName];
        }
    };

    function prepareParametersForMarrionetteRouterCallback(route) {
        logger.debug('preparing parameters for ' + route.path);
        var resultParams = [];
        if (!_.isEmpty(route.params)) {
            var sortedPairArray = _.sortBy(_.pairs(route.params), function (keyValuePair) {
                return _.indexOf(route.path, keyValuePair[1]);
            });
            _.each(sortedPairArray, function (keyValuePair) {
                resultParams.push(keyValuePair[1]);
            });
        }

        logger.debug(resultParams);
        return resultParams;
    }

    return LE2Proxy;
});
