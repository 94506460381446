/**
 * User: itaik
 * Date: 6/26/13
 * Responsible to manage menu and tray items.
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var TopLevelMenuStatusItemModel = require('src/models/top-level-menu/TopLevelMenuStatusItemModel');
    const CONST = require("const");
    var Translator = require("i18n/translator");

    var BarMenu = (function () {
      const UNKNOWN_STATUS_KEY = "unknown";
        var userBarMenuAPI = {
            statusCollection:new Backbone.Collection()
        };

        userBarMenuAPI.statusCollection.comparator = "index";

        //this is only a hack to not destroy the personalization editor when we move to vue step by step..
        for(let [key, value] of Object.entries(CONST.DEFAULT_AGENT_STATUSES)) {
          if(key !== UNKNOWN_STATUS_KEY) {
            value.title = `${Translator.translate(`LEWebAgent.${value.translatorParameter}`)}`;
            value.availabilityTitle = `${Translator.translate(`LEWebAgent.${value.translatorParameter}`)}`;
            userBarMenuAPI.statusCollection.add(new TopLevelMenuStatusItemModel(value));
          }
        }
        return userBarMenuAPI;
    })();


    return BarMenu;
});
