import ManagerWorkspaceClient from '../clients/ManagerWorkspaceClient';
import {Logger, sessionManager} from 'vue-infra';

const logger = Logger.getLogger('LiveEngage:AgentTimer');

class AgentViewService {

  constructor() {
  }

  createAgentViewClient() {
    return new ManagerWorkspaceClient(ManagerWorkspaceClient.RESOURCES.AGENT_VIEW);
  }

  async fetchAgentView(currentStatus) {
    const agentViewClient = this.createAgentViewClient();
    const agentId = sessionManager.getUserId();
    try {
      const { data } = await agentViewClient.post('', {
        "filters": {
          "time": {
            "from": 1573378572695,
            "to": 1573464967376
          },
          "agentIds": [
            agentId
          ],
          "effectiveAgentStatus": [
            currentStatus
          ]
        },
        "includeAgentMetadata": true,
        "metricsToRetrieveCurrentValue": [
          "agent_current_status",
          "agent_current_status_with_reason",
          "agent_current_status_start_time",
          "agent_current_status_reason_start_time"
        ],
        "v": "1"
      });
      return data;
    } catch (e) {
      logger.error(`Can not fetch agent view data from API, agentId: ${agentId}, accountId:${sessionManager.getAccountId()} , ${e}`, 'AgentViewService:fetchAgentView');
    }
  }
}

const agentViewService = new AgentViewService();

export default agentViewService;
