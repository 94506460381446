/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/26/14
 * Time: 3:15 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var { Logger } = require('vue-infra');

    var DependencyCollection = require("src/components/dependency-service/collections/DependencyCollection");

    /**
     *  See https://docs.google.com/a/liveperson.com/document/d/14XolzLrRSHmX-HzpFrLZm9Ah9UOlGJ0qtbhur3oRG_4/edit
     *  for the AC-Meta Data API.
     *
     * @constructor
     */
    var DependencyService = function() {

        /**
         * Returns the dependency tree for the specified model, that is, all objects depending on that model.
         *
         * @param data - can be instnace of Backbone.Model or Backbone.Collection
         * @param options - {success, error}
         */
        this.getDependencies = function(data, options) {
            var collection = new DependencyCollection(null, {
                dependeeData: data
            });

            collection.fetch(options);
        };
    };

    return new DependencyService();
});
