/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:38 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var ControlGroup = require("codeTables/controlGroup.json");
  var _ = require('underscore');

    var controlGroupSettings = (function () {
        var currentControlGroup = null;

        var getControlGroupList = function () {
            return ControlGroup.controlGroupCodes;
        };
        var getControlGroupArray = function () {
            if (_.isUndefined(ControlGroup.controlGroupArray)) {
                ControlGroup.controlGroupArray = [];
                _.each(ControlGroup.controlGroupCodes, function (val, key) {
                    ControlGroup.controlGroupArray.push({"key": key, "value": val});
                });
            }
            return ControlGroup.controlGroupArray;
        };

        var getCurrentControlGroup = function () {
            if (currentControlGroup === null) {
                currentControlGroup = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CONTROL_GROUP,ControlGroup.defaultControlGroup);
            }
            return currentControlGroup;
        };

        var switchControlGroup = function (controlGroup) {
            currentControlGroup = controlGroup;
            LE.vent.trigger("siteSettings:controlGroupSwitched",currentControlGroup);
        };
        return {
            getControlGroupList: getControlGroupList,
            getControlGroupArray: getControlGroupArray,
            getCurrentControlGroup: getCurrentControlGroup,
            switchControlGroup: switchControlGroup
        };
    })();
    return controlGroupSettings;
});

