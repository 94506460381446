/**
 * Created by tomers on 4/26/2016.
 */
define(function (require) {
    "use strict";

    var LE = require("app");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var Config = require("assets/config/entities/config.json");
    var Settings = require('assets/data/codeTables/settings.json');
    var AgentReasonModel = require("models/entities/AgentReasonModel");
    var ApiResources = require("assets/data/apiResources.json");
  var _ = require('underscore');

    var INCLUDE_DELETED_PARAMETER = "include_deleted";

    var NAME = "AgentReasonsCollection";

    var AgentReasonsCollection = RevisionedCollection.extend({
        model: AgentReasonModel,
        resource: ApiResources.AccountConfig.AgentReasons,
        name: NAME,


        initialize: function(models, options) {
            options = options || {};

            this.deletedItemsMap = {};

            RevisionedCollection.prototype.initialize.call(this,models,options);
        },

        fetch: function(options) {
            options = options || {};
            options.data = options.data || {};
            options.data[INCLUDE_DELETED_PARAMETER] = true;
            return RevisionedCollection.prototype.fetch.call(this, options);
        },

        parse: function(resp, options) {
            var nonDeletedItems = resp;

            if (_.isArray(resp)) {
                nonDeletedItems = [];
                _.each(resp, _.bind(function(agentReasonItem) {
                    if (agentReasonItem.deleted) {
                        this.deletedItemsMap[agentReasonItem.id] = agentReasonItem;
                    }
                    else {
                        nonDeletedItems.push(agentReasonItem);
                    }
                }, this));
            }

            return RevisionedCollection.prototype.parse.call(this, nonDeletedItems, options);
        },

        getTextById: function(id) {
            var text = "";
            if (this.get(id)) {
                text = this.get(id).getText();
            }
            else if (this.deletedItemsMap[id]) {
                text = this.deletedItemsMap[id].text;
            }
            return text;
        },

        getModelsArray: function() {
            return this.toJSON();
        },

        getMaxSize: function () {
            const defaultMaxSize = Config.validators.agentReason.maxEntities;

            return this.hasConfigForCustomMaxSize() ?
                LE.sessionManager.getAccountSettingValueByID(Settings.SETTING_ID.MAXIMUM_NUMBER_CUSTOM_AWAY_STATUS, defaultMaxSize) :
                defaultMaxSize;
        },

        isCollectionAllowed: function() {
            return LE.sessionManager.hasMultipleAgentStatesFeature();
        },

        hasConfigForCustomMaxSize: function() {
            return LE.sessionManager.hasConfigForMaximumCustomAwayStatus(); // && LD || appConfig.config => extendNumberOfAwayStatuses
        },

        hasReasonsForState: function(state) {
            var reasonsForState = this.filter(function(agentReasonModel) {
                return agentReasonModel.getState().toLowerCase() === state.toLowerCase();
            });
            return (reasonsForState.length > 0);
        },

        remove: function(model, options) {
            var JSONModel = model.toJSON();
            this.deletedItemsMap[JSONModel.id] = JSONModel;

            return RevisionedCollection.prototype.remove.call(this, model, options);
        }
    });

    AgentReasonsCollection.NAME = NAME;

    return AgentReasonsCollection;
});
