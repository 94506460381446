define(function (require) {
    "use strict";

    var CountryUtility = require("infra-util/CountryUtility");
    var DomEvents = require("infra-util/DomEvents");
    var DomUtilities = require("infra-util/DomUtilities");
    var GeneralUtilities = require("infra-util/GeneralUtilities");
    var JsonUtilities = require("infra-util/JsonUtilities");
    var NumberFormats = require("infra-util/NumberFormats");
    var SecureUtilities = require("infra-util/SecureUtilities");

    var DateUtility = require("utils/DateUtility");
    var IndustryUtility = require("utils/IndustryUtility");
    var lpUtils = require("utils/lpUtils");
    var NameUtility = require("utils/NameUtility");
    var StringFormats = require("utils/StringFormats");
    var ValidationUtilities = require("utils/ValidationUtilities");

    var PositionUtils = require("ui/PositionUtils");
    var WidgetUtils = require('ui/WidgetUtils');

    return {
        country: CountryUtility,
        date: DateUtility,
        domEvents: DomEvents,
        dom: DomUtilities,
        general: GeneralUtilities,
        industry: IndustryUtility,
        json: JsonUtilities,
        lpUtils: lpUtils,
        name: NameUtility,
        number: NumberFormats,
        security: SecureUtilities,
        string: StringFormats,
        validation: ValidationUtilities,
        position: PositionUtils,
        widget: WidgetUtils
    };
});