/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/14/14
 * Time: 3:14 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var Marionette = require("marionette");

    var Button = require("ui/button/Button");
    var template = require("ui.components/dependency-indicator/templates/dependencyItem.tmpl");

    var DependencyItemView = Backbone.Marionette.ItemView.extend({
        template: template,
        withUIComponents: true,
        ui: {
            "buttonContainer": ".buttonContainer"
        },

        templateHelpers: function() {
            var that = this;

            return {
                index: function() {
                    return that.itemIndex + 1;
                }
            };
        },

        initialize: function (options) {
            this.itemIndex = options.itemIndex; // zero based
        },

        onRender: function() {
            if (this.model.get("isClickable")) {
                this.uiComponents.host$.createAndRender("linkButton", {
                    type: Button,
                    options: {
                        el: "view.ui.buttonContainer",
                        permutation: Button.PERMUTATION.BLUELINK
                    },
                    events: {
                        click: "onClicked"
                    }
                });
            }
        },

        onClicked: function() {
            if (this.model.get("isClickable")) {
                this.trigger("clicked", this.model);
            }
        }
    });

    return DependencyItemView;
});
