<template>
  <div
    ref="modalSection"
    class="ftue-section"
    :class="{
      'ftue-section-right': section.response
    }"
  >
    <div class="ftue-section-bot-icon-wrapper">
      <img
        v-if="section.showBotIcon"
        class="ftue-section-bot-icon"
        src="../../../assets/icons/bot.svg"
        alt="bot-icon"
      />
    </div>
    <div
      class="ftue-section-container"
      :class="{
        'ftue-section-background': section.text || section.namedText,
        'ftue-section-response': section.response,
        'ftue-section-no-icon': !section.showBotIcon,
        'ftue-section-with-buttons': section.buttons
      }"
    >
      <first-time-loading
        v-if="section.loading"
        size="default"
      />
      <p v-if="section.response">
        {{ responseText }}
      </p>
      <first-time-named-text
        v-if="shouldTranslateName"
        :section-text="section.text"
        :full-name="fullName"
      />
      <p
        v-if="section.text && !shouldTranslateName"
        class="ftue-section-text"
      >
        {{ $translator.translate(section.text) }}
      </p>
      <first-time-loading
        v-if="section.textLoading"
        size="small"
      />
      <ul
        v-if="section.bulletPoints"
        class="ftue-section-list"
      >
        <first-time-list-item
          v-for="(bulletPoint, bulletPointIndex) in section.bulletPoints"
          :key="bulletPointIndex"
          :bullet-point="bulletPoint"
        />
      </ul>
      <div
        v-if="section.buttons"
        class="ftue-section-buttons"
      >
        <first-time-button
          v-for="button in section.buttons"
          :key="button.id"
          :button="button"
          :has-clicked="hasClicked"
          @clicked="handleClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FirstTimeButton from './FirstTimeButton';
import FirstTimeLoading from './FirstTimeLoading';
import FirstTimeListItem from './FirstTimeListItem';
import FirstTimeNamedText from './FirstTimeNamedText';

export default {
  name: 'FirstTimeSection',
  components: {
    FirstTimeButton,
    FirstTimeLoading,
    FirstTimeListItem,
    FirstTimeNamedText
  },
  props: {
    section: {
      type: String,
      default: '',
    }, fullName: {
      type: String,
      default: '',
    }, isLast: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['clickButton'],
  data() {
    return {
      hasClicked: false,
      responseText: this.section.response
        ? this.$translator.translate(this.section.response)
        : '',
      shouldTranslateName: this.section.text && this.section.translateName
    };
  },
  updated() {
    if (this.isLast) {
      this.scrollToElement();
    }
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    handleClick: function(data) {
      // set hasClicked to true, to prevent any further clicks
      this.hasClicked = true;
      this.$emit('clickButton', data);
    },
    scrollToElement: function() {
      if (this.$refs.modalSection && !this.section.doNotScrollTo) {
        this.$nextTick(() => {
          this.$refs.modalSection.scrollIntoView({
            behavior: 'smooth'
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ftue-section {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.ftue-section-right {
  justify-content: flex-end;
}
.ftue-section-text {
  display: inline;
}
.ftue-section-container {
  margin-bottom: 16px;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  margin-right: 72px;
}
.ftue-section-background {
  @include themify {
    background-color: getColor('ftue-text-background', 'framework');
    color: getColor('ftue-text-color', 'framework');
  }
  padding: 16px;
  border-radius: 0 16px 16px 16px;
}
.ftue-section-response {
  @include themify {
    background-color: getColor('ftue-response-background', 'framework');
    color: getColor('ftue-response-text', 'framework');
  }
  padding: 16px;
  font-weight: bold;
  margin-right: 0;
  margin-left: 72px;
  margin-top: 8px;
  margin-bottom: 24px;
  border-radius: 16px 0 16px 16px;
}
.ftue-section-no-icon {
}
.ftue-section-with-buttons {
  width: 100%;
}
.ftue-section-list {
  padding-left: 16px;
  list-style-type: circle;
}
.ftue-section-list li {
  padding-top: 16px;
}
.ftue-section-bot-icon-wrapper {
  width: 36px;
  height: 28px;
  margin-right: 8px;
  flex-shrink: 0;
}
.ftue-section-bot-icon {
  width: 36px;
  height: 28px;
}
.ftue-section-buttons {
  display: flex;
  flex-wrap: wrap;
}
</style>
