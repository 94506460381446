/**
 * User: elinar
 * Date: 8/20/13
 * Time: 6:16 pM
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {
    "use strict";

    var _ = require("underscore");
    var Translator = require("i18n/translator");

    var ValidationUtilities = (function () {

        function setValidationErrors(view, model, mustPreValidate, isValidView) {
            if (view && model && model.validationError) {
                var validationSettings = view.validationSettings;
                var errors = model.validationError;

                if (mustPreValidate) {
                    errors = preValidate(model, validationSettings);

                    if (isValidView) {
                        return errors;
                    }
                }

                setErrors(view, errors, validationSettings);
            }
        }

        function preValidate(model, validationSettings) {
            var errors = {};
            var attr;
            var memo;
            var flattened = flatten(model.attributes);

            _.each(validationSettings, function (validationSetting) {

                for (var i = 0; i < validationSetting.errKeys.length; i++) {
                    attr = validationSetting.errKeys[i];
                    memo = model.preValidate(attr, flattened[attr]);
                    if (memo) {
                        errors[attr] = memo;
                    }
                }
            });
            return errors;
        }

        function setErrors(view, errors, validationSettings) {
            _.each(validationSettings, function (validationSetting) {
                if (isErrorKeysInError(errors, validationSetting.errKeys)) {

                    var error = getErrorStr(errors, validationSetting.errKeys);
                    //todo: error text for element with multiple error keys and an error tooltip
                    switch (validationSetting.uiType) {
                        case "uiComponents":
                            setUiComponentsError(true, view, validationSetting, error);
                            break;
                        case "view":
                            setViewError(true, view, validationSetting, error);
                            break;
                        default:
                            setError(true, view, validationSetting);
                            break;
                    }
                }

            });
        }

        function getErrorStr(errors, errorKeys) {
            if (errorKeys.length > 0) {
                for (var i = 0; i < errorKeys.length; i++){
                    if (errors[errorKeys[i]]){
                        return Translator.translate(errors[errorKeys[i]]);
                    }
                }

            }
        }

        function isErrorKeysInError(errors, errorKeys) {
            for (var i = 0; i < errorKeys.length; i++) {
                if (_.has(errors, errorKeys[i])) {
                    return true;
                }
            }
            return false;
        }

        function setError(isError, view, validationSetting) {
            var ui = view[validationSetting.uiType][validationSetting.uiKey];
            if (isError) {
                ui.addClass("error");
            }
            else {
                ui.removeClass("error");
            }
        }

        function setUiComponentsError(isError, view, validationSetting, error) {
            var uiComponent = view.uiComponents[validationSetting.uiKey];

            if (_.isFunction(uiComponent.setError)) {
                uiComponent.setError(isError, error);
            }
            else {
                setError(isError, view, validationSetting);
            }
        }

        function setViewError(isError, view, validationSetting, error) {
            var viewComponent = view[validationSetting.uiKey];

            if (_.isFunction(viewComponent.setError)) {
                viewComponent.setError(isError, error);
            }

        }

        function flatten(obj, into, prefix) {
            into = into || {};
            prefix = prefix || '';

            _.each(obj, function (val, key) {
                if (obj.hasOwnProperty(key)) {
                    if (val && typeof val === 'object' && !(
                        val instanceof Date ||
                        val instanceof RegExp )
                    ) {
                        flatten(val, into, prefix + key + '.');
                    }
                    else {
                        into[prefix + key] = val;
                    }
                }
            });

            return into;
        }

        /**
         * builds array of arrays that represents the or between ands. ex: (1 and 2) OR (3 and 4) will return: [[1,2],[3,4]]
         */
        function _buildArrayOfOrBetweenAnd(orArray, orAttr, andAttr) {
            var arr = [];

            var mappedOrArr = _.map(orArray, orAttr);
            _.each(mappedOrArr, function (orGroup) {
                arr.push(_.map(orGroup, andAttr));
            });

            return arr;
        }


        return {
            /**
             * @param view   the view to set validations on
             * @param mustPreValidate   do a pre validation - for the views keys on the model using the view validation settings
             * @param model    if the real model is not connected to the view as (view.model) pass it here
             */
            setValidationErrors: function (view, mustPreValidate, model) {
                model = model ? model : view.model;
                setValidationErrors(view, model, mustPreValidate);
            },
            isValidView: function (view) {
                return !setValidationErrors(view, true, true);
            },

            // TODO (Gal) START: the following till END are function from registration and userManagement modules (Move to here after modules lazy init). Those modules should use the above  isValidView instead of the below functions
            validateInputField: function (input, maxCharacters) {
                if (this.isEmpty(input)) {
                    return false;
                }
                if (typeof maxCharacters !== "undefined" && this.isInteger(maxCharacters) && input.length > maxCharacters) {
                    return false;
                }
                var re = new RegExp("<[a-zA-Z\/][^>]*>", "g");
                return (input.match(re) == null);
            },

            isEmpty: function (input) {
                return (input == null || typeof input === "undefined" || $.trim(input).length === 0);
            },

            isInteger: function (input) {
                return ($.isNumeric(input) && Math.floor(input) == input);
            },

            isValid: function (input, regex) {
                var re = new RegExp(regex, "g");
                return (input.match(re) != null);
            },

            isPasswordValid: function (input) {
                return input.length < 8 ? false : true;
            },

            isString: function (input) {
                return _.isString(input);
            },

            isNullOrEmptyString: function (input) {
                return (!this.isString(input) || this.isEmpty(input));
            },

            /**
             * Search for duplicates items in collection.
             *
             * @param collection - the collection to search in.
             * @param compFunc - defines how tow items become 'duplicates'. The function should get model1 and model 2, and return true for duplicates or false for not duplicates.
             */
            findDuplicationsInCollection: function (collection, compFunc) {
                var duplicates = [];
                // Loop throw the collection and search for duplicates in n! search.
                for (var i = 1; i < collection.length; i++) {
                    for (var j = 0; j < i; j++) {
                        // If found duplicates items (according to compFunc), push it to duplicates array.
                        if (compFunc(collection.models[i], collection.models[j])) {
                            duplicates.push(collection.models[i]);
                            duplicates.push(collection.models[j]);
                            break;
                        }
                    }

                }
                // Return the duplicates item in the collection, but first, clean the items that pushed twice.
                return _.uniq(duplicates);
            },

            /**
             * Search for items in first collection that duplicated in the second  collection.
             *
             * @param collection1 - the 1st collection to search duplicates in collection2.
             * @param collection2 - the 2nd collection to search duplicates in collection1.
             * @param compFunc - defines how tow items become 'duplicates'. The function should get model1 and model 2, and return true for duplicates or false for not duplicates.
             */
            findDuplicationsInCollections: function (collection1, collection2, compFunc) {
                var duplicates = [];
                // The duplicate items from collection1 inserted to duplicates[0].
                duplicates[0] = [];
                // The duplicate items from collection2 inserted to duplicates[1].
                duplicates[1] = [];

                // Loop throw the collection and search for duplicates in nXn search.
                for (var m = 0; m < collection1.length; m++) {
                    for (var n = 0; n < collection2.length; n++) {
                        // If found duplicates items (according to compFunc), push it to duplicates array.
                        if (compFunc(collection1.models[m], collection2.models[n])) {
                            duplicates[0].push(collection1.models[m]);
                            duplicates[1].push(collection2.models[n]);

                        }
                    }
                }
                // Clean the items that pushed twice.
                duplicates[0] = _.uniq(duplicates[0]);
                duplicates[1] = _.uniq(duplicates[1]);
                return duplicates;
            },

            /**
             * Check if the include array and the exclude array always returns an empty set, so the user cannot pass the condition
             * Logic:   builds array of arrays for the includes and excludes.
             *          for each of the exclude sub-group (AndGroup), check if it exists in each of the include sub groups (AndGroups).
             *          If it is - it means that this sub group (AndGroup) cannot be caught because it's excluded.
             *          If no - there is no exclude condition that exclude this include condition - keep this condition in the result and move on.
             *          After iterating over the exclude array:
             *          If the result array is empty - there is no include condition thats not excluded. this is the case of empty set.
             *          If the result array is not empty - there is situation that the condition can pass.
             * @param includeArr - array of objects (with OR between each of them), that each object is array (with AND between each of them). [{sections:[{name:1}, {name:2}]}, {sections:[{name:3},{name:4}]}] - represents (1 AND 2) OR (3 AND 4)
             * @param excludeArr - array of objects (with OR between each of them), that each object is array (with AND between each of them). [{sections:[{name:5}, {name:6}]}, {sections:[{name:7},{name:8}]}] - represents (5 AND 6) OR (7 AND 8)
             * @param orAttr - the attribute for retrieve the AndGroup - in the example - "sections"
             * @param andAttr - the attribute for retrieve each item in the AndGroup - in the example - "name"
             */
            checkIsEmptySetOrBetweenAnd: function(includeArr, excludeArr, orAttr, andAttr) {
                var includes = _buildArrayOfOrBetweenAnd(includeArr, orAttr, andAttr);
                var excludes = _buildArrayOfOrBetweenAnd(excludeArr, orAttr, andAttr);

                var resultArr = _.cloneDeep(includes);
                _.each(excludes, function(excludeAndGroup) {
                    var newResultArr = [];
                    _.each(resultArr, function(includeAndGroup, index) {
                        if (_.difference(excludeAndGroup, includeAndGroup).length > 0) { //if all exclude condition exists in include - remove it, because it's excluded
                            newResultArr.push(includeAndGroup);
                        }
                    });
                    resultArr = _.cloneDeep(newResultArr);
                });

                return (resultArr.length === 0);
            },
            translateErrorObject: function (errorObject) {
                var translatedObj = {};
                _.each(errorObject, function (value, key, obj) {
                    translatedObj[key] = Translator.translate(value);
                });
                return translatedObj;
            }
        };

    }());
    return ValidationUtilities;
});
