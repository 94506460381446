/**
 * User: danielda
 */
define(function (require) {
    "use strict";

    var BaseModel = require("models/BaseModel");
    var ApiResources = require("assets/data/apiResources.json");

    var PermissionGroupConvertionModel = BaseModel.extend({
        resource: ApiResources.AppServer.ConvertPermissionGroups,
        name: "PermissionGroupConvertionModel"
    });

    return PermissionGroupConvertionModel;
});
