/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 10/15/13
 * Time: 9:28 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var ApiResources = require("assets/data/apiResources.json");

    var SiteSettingsModel = RevisionedModel.extend({
        resource : ApiResources.AccountConfig.SiteSettings,
        name: "SiteSettingsModel",
        initialize: function(options) {
            if (options) {
                this.contextType = options.contextType;
                this.contextId = options.contextId;
                this.contextCascading = options.contextCascading;
                this.source = options.source;
            }
        },
        url: function(){
            var url = RevisionedModel.prototype.url.call(this);
            if (this.contextType) {
                url += "&context_type=" + this.contextType;
            }
            if (this.contextId) {
                url += "&context_id=" + this.contextId;
            }
            if (this.contextCascading) {
                url += "&context_cascading=" + this.contextCascading;
            }
            return url;
        },
        save: function(key, val, options) {
            if(this.collection) {
                this.contextType = this.collection.contextType;
                this.contextId = this.collection.contextId;
                this.contextCascading = this.collection.contextCascading;
            }
            return RevisionedModel.prototype.save.call(this, key, val, options);
        },
        getValue: function() {
            return this.get("propertyValue.value");
        },
        setValue: function(val, options) {
            options = options || {};
            return this.set("propertyValue.value", val, options);
        },
        unSetPropertyValue: function(){
            this.unset("propertyValue", {silent:true});
        }
    });

    return SiteSettingsModel;
});
