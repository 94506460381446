<template>
  <p class="ftue-section-text">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'FirstTimeNamedText',
  props: {
    sectionText: {
      type: String,
      default: '',
    },
    fullName: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      text: this.fullName
        ? LE.translator.translate({
            key: this.sectionText,
            args: [{ key: this.fullName, isTranslate: false }]
          })
        : ''
    };
  },
};
</script>
