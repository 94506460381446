define(function (require) {
    "use strict";

    var _ = require("underscore");

    //============================================================
    // CollectionFilterer
    //============================================================

    var CollectionFilterer = function () {

        var filterModel = null;

        //-------------------------------------------------------

        var filter = function (collection, options) {

            var filtered = false, filteredWith;
            options = options || {};

            if(_.has(options, 'filterModel')){
                filterModel = options.filterModel;
            }

            if (_.isObject(filterModel) && _.isFunction(filterModel.predicate)) {   //passing filterModel without predicate resets the filtering
                var items = _.filter(collection.models, function (model) {
                    return filterModel.predicate(model);  //todo: should do call with collection as the context
                });

                collection.reset(items, {silent:true, sort:false});

                filtered = true;
                filteredWith = filterModel.filteredWith;
            }

            collection.trigger("organize:filter", {filtered: filtered, filteredWith:filteredWith});
        };

        //----------------------------------------------------------

        return{
            filter: filter
        };
    };

    return CollectionFilterer;
});
