/**
 * V1.0
 *
 * User: ivan
 * Date: 28/8/14
 *
 * This component is used to show a no data page with an option to have a refresh button.
 *
 * Options:
 * el - mandatory. jQuery element. The element on the page where the button will be created.
 * permutation - optional. Responsible for the no data permutation: PERMUTATION.DEFAULT - no refresh link (default), REFRESH_LINK - with refresh link.
 * btnPermutation - optional. Responsible for the button style. Button.PERMUTATION.BLUELINK is the default.
 * btnText - optional. Responsible for the button's text. "Refresh" is the default.
 * callback - optional. A function which will be called when button is clicked. (if no function was supplied nothing will happen when clicking on the link).
 * errorMessage - optional. Responsible for the error text. "We seem to have lost track of this page's content." is the default.
 *
 * Using Example:
 *---------------------------
 * template:
 * this.noData = new NoData({
 *      el:'.interaction-area-item-wrapper.open',
 *      permutation: NoData.PERMUTATION.REFRESH_LINK,
 *      callback: _.bind(_btnActionClick, this)
 *  });
 * this.noData.render();
 *
 * *******************************************************************************************************
 */


define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var WidgetUtils = require('ui/WidgetUtils');
    var temp = require("./templates/noData.tmpl");
    var NoDataModel = require("ui.components/no-data/js/models/NoDataModel");
    var Button = require("ui/button/Button");
    var LE = require("app");
    var Backbone = require("backbone");

    function moduleDefinition(LEUIComponents, LE, Backbone, Marionette, $, _, view) {

        var PERMUTATION = {
            DEFAULT   :1,
            REFRESH_LINK   :2,
            REFRESH_LINK_MEDIUM  :3
        };

        var template = temp;
        var ui = {
            btnAction: ".btnAction",
            error404: ".error404"
        };

        function initialize(options) {
            /*jshint validthis:true */
            this.el = options.el;
            this.permutation = options.permutation || PERMUTATION.DEFAULT;
            this.btnPermutation = options.permutationButton || Button.PERMUTATION.BLUELINK;
            this.btnText = options.btnText || LE.translator.translate("LEUIComponents.refresh.data");
            this.callback = options.callback || null;

            this.model = new NoDataModel({
                errorMessage : options.errorMessage
            });
        }

        function onRender() {
            /*jshint validthis:true */
            this.btnAction = _renderByPermutation(this);
            if(this.btnAction) {
                this.btnAction.render();
            }
        }

        function onBeforeClose() {
            /*jshint validthis:true */
            if(this.btnAction) {
                this.btnAction.close();
            }
        }

        function onClose() {
            /*jshint validthis:true */
            this.ui.error404.remove();
        }

        function _renderByPermutation(context) {
            switch (context.permutation) {
                case (PERMUTATION.REFRESH_LINK):
                    return _createBtnAction(context);
                case (PERMUTATION.REFRESH_LINK_MEDIUM):
                    context.ui.error404.addClass("refresh-link-medium");
                    return _createBtnAction(context);
                default:
                    break;
            }
        }

        function _createBtnAction(context) {
            return new Button({
                el: context.ui.btnAction,
                permutation: context.btnPermutation,
                text: context.btnText,
                onClick: context.callback
            });
        }

        view.extend(Marionette.ItemView.extend({
            ui: ui,
            template: template,
            initialize: initialize,
            onRender: onRender,
            onBeforeClose: onBeforeClose,
            onClose: onClose
        }),  { PERMUTATION: PERMUTATION });
    }

    return LE.moduleAddDefinition("LEUIComponents", moduleDefinition);
});
