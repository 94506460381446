define(function (require) {
    "use strict";

    var BaseModel = require("models/BaseModel");
    var RevisionedModel = require("models/RevisionedModel");
    var PersistentModel = require("models/PersistentModel");
    var BaseCollection = require("collections/BaseCollection");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var PersistentCollection = require("collections/PersistentCollection");
    var OrderedCollection = require("collections/OrderedCollection");
    var BaseRouteController = require("controllers/BaseRouterController");
    var SelectableCollectionDecorator = require("decorators/SelectableCollectionDecorator");
    var OrganizeCollectionDecorator = require("decorators/organizeCollectionDecorator/OrganizeCollectionDecorator");

    return {
        routing : {
            BaseRouteController : BaseRouteController
        },
        models : {
            BaseModel : BaseModel,
            RevisionedModel : RevisionedModel,
            PersistentModel : PersistentModel
        },
        collections : {
            BaseCollection : BaseCollection,
            RevisionedCollection : RevisionedCollection,
            PersistentCollection : PersistentCollection,
            OrderedCollection : OrderedCollection
        },
        decorators : {
            SelectableCollectionDecorator : SelectableCollectionDecorator,
            OrganizeCollectionDecorator : OrganizeCollectionDecorator
        }
    };
});