/**
 * V1.0
 *
 * User: tomers
 * Date: 6/25/13
 *
 * This component is used to show a gallery of items.
 * Only one item can be selected.
 * In order to listen fro outside the component to the chosen item you should listen on the selectable collection "change:selection".
 * When the "new item" is selected, the selectedId on the collection will be -1 (because no item in the collection is really selected) but on the event includes options object with "isNewItemSelected" property
 *
 * Options:
 * el - mandatory. jQuery element. The element on the page where the component will be created.
 * collection - mandatory. Selectable Collection. The collection that being shown in the gallery
 * permutation - optional. One of the options in the static Gallery.PERMUTATION enum. no permutation is the default
 * newItemToAdd - optional. Object. {name: XXXXXXX}. If there is newItemToAdd we are adding a button with the name in the object at the end of all other buttons.
 * ellipsis - optional. numeric value that tells how many lines to show and trim the rest with ellipsis for gallery item name . default is 2.
 * descriptionEllipsis - optional. same for gallery item description. default is 3.
 *
 * Using Example:
 *---------------------------
 * template:
 * this.gallery = new Gallery({
 *      collection : galleryItems,
 *      el : this.ui.galleryList,
 *      permutation : Gallery.PERMUTATION.A
 *      newItemToAdd: { name: "Create new goal" }
 * });
 *
 * this.gallery.render();
 *
 * *******************************************************************************************************
 */


define(function (require) {
    "use strict";
    var BaseGallery = require("ui/gallery/gallery");
    var GalleryItemView = require("ui.components/gallery/js/views/GalleryItemView");
    var _ = require('underscore');

    var Gallery = BaseGallery.extend({

        itemView: GalleryItemView,
        //----------------------------------------
        // initialize
        //---------------------------------------

        initialize:function (options) {
            this.itemEventsDeprecated["itemview:dependencyItemClicked"] = "_handleDependencyItemClicked";
            this.showDependencyTypeTitle = !!options.showDependencyTypeTitle;
            this.maxItemsToDisplay = options.maxItemsToDisplay;
            BaseGallery.prototype.initialize.call(this, options);
        },

        updateDependencies: function(dependenciesArr){
            var options = {
                showDependencyTypeTitle: this.showDependencyTypeTitle
            };
            if (this.maxItemsToDisplay) {
                options.maxItemsToDisplay = this.maxItemsToDisplay;
            }

            this.children.each(function(view){
                var dependencyObj = _.find(dependenciesArr, function(dep){
                    return dep.itemId === String(view.model.get("key"));
                });

                if(dependencyObj && !_.isEmpty(dependencyObj.config)){
                    view.updateDependencies(dependencyObj.config, options);
                }
            });
        }
    });

    return Gallery;
});
