define(function (require) {
    "use strict";

    var LE = require("app");
    var _ = require("underscore");
    var Ellipsis = require("ui.plugins/ellipsis");
    var BaseGalleryItemView = require("ui/gallery/js/views/GalleryItemView");
    var TemplateGalleryItem = require("ui.components/gallery/templates/galleryItem.tmpl");
    var DependencyIndicator = require("ui.components/dependency-indicator/DependencyIndicator");

    var GalleryItemView = BaseGalleryItemView.extend({

        initialize:function(options) {
            this.ui.dependencyIndicatorContainer = ".dependency-indicator-container";
            this.ui.description = "p.description";
            BaseGalleryItemView.prototype.initialize.call(this, options);
            if (this.model.isRegularType()) {
                this.template = TemplateGalleryItem;
            }
        },
        customTemplateHelpers: function() {
            var templateHelpers = BaseGalleryItemView.prototype.customTemplateHelpers.call(this);
            if (this.model.isTimezoneBased()) {
                var timezoneStr = this.model.get("timezoneStr");
                templateHelpers.translatedTimezone = LE.siteSettings.timeZoneSettings.getCurrentTimeZoneTranslated(timezoneStr);
            }

            return templateHelpers;
        },
        updateDependencies: function(config, options){
            var dependenciesMapCollections = {};
            _.each(config.dependenciesMap, function(dependenciesArr, dependencyType) {
                dependenciesMapCollections[dependencyType] = new DependencyIndicator.Collection(dependenciesArr);
            });

            var dependencyIndicatorOptions = {
                container : "view.ui.dependencyIndicatorContainer",
                dependenciesMap: dependenciesMapCollections,
                tooltipTitle: config.title,
                tooltipAutohideTimeout: 100
            };

            if (_.has(options, "showDependencyTypeTitle")) {
                dependencyIndicatorOptions.showDependencyTypeTitle = options.showDependencyTypeTitle;
            }

            if (_.has(options, "maxItemsToDisplay")) {
                dependencyIndicatorOptions.maxItemsToDisplay = options.maxItemsToDisplay;
            }

            this.uiComponents.host$.createAndRender("dependencyIndicator", {
                type: DependencyIndicator,
                options: dependencyIndicatorOptions,
                events: {
                    "item:clicked": "onDependencyIndicatorItemClick"
                }
            });

            this.ui.defaultIndicator.addClass("dependency-indicator-shown");
            this.ui.defaultIndicator.toggleClass("api-icon-shown", this.model.isApiBased());
            this.ui.defaultIndicator.toggleClass("timezone-icon-shown", this.model.isTimezoneBased());

            var needToShortenDescription = ((this.model.isApiBased() || this.model.isTimezoneBased()) && !!this.model.isUserDefault());
            this.ui.description.toggleClass("shorten-description", needToShortenDescription);
            _.defer(_.bind(function(){
                Ellipsis.handleNow(this);
            }, this));
        },

        onDependencyIndicatorItemClick: function(componentId, eventName, model) {
            this.trigger("dependencyItemClicked", model.get("url"));
        }
    });

    return GalleryItemView;
});
