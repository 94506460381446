/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:36 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
  'use strict';
  var { PRIVILEGES } = require('vue-infra');

  //todo, move PRIVILEGE_TO_PERSONA_MAP to this place
  //todo refactor usage of PRIVILEGE_TO_PERSONA_MAP and this thingy

  var privilegeSettings = (function () {

    var IDs = PRIVILEGES.privilegeIDs;

    //todo move functionality to json configuration on the privileges file - use one function for all
    var hasMyProfileButtonPrivilege = function () {
      return (LE.sessionManager.hasPrivilege(IDs.CHANGE_MY_DETAILS, [IDs.VIEW_OPERATORS, IDs.EDIT_OPERATORS]));
    };

    var hasContentImportPrivileges = function () {
      return LE.sessionManager.hasPrivilege(IDs.CANNED_RESPONSES_IMPORT);
    };

    var hasAgentManagerPrivilege = function () {
      return LE.sessionManager.hasPrivilege(IDs.LE_VIEW_AGENT_MANAGER_MODULE);
    };
    var hasCampaignEditPrivilege = function () {
      return LE.sessionManager.hasPrivilege(IDs.LE_EDIT_CAMPAIGNS);
    };

    var hasAccountBillingPrivilege = function () {
      //TODO: switch to only ACCOUNT_BILLING when appserver sets billing permission to the CM users.
      // return LE.sessionManager.hasPrivilege(IDs.ACCOUNT_BILLING);
      return LE.sessionManager.hasPrivilege(IDs.LE_EDIT_CAMPAIGNS, IDs.ACCOUNT_BILLING);
    };

    var hasUserManagementPrivilege = function () {
      return LE.sessionManager.hasPrivilege(IDs.LE_VIEW_USER_MANAGEMENT_MODULE);
    };

    var hasAgentTimerPrivilege = function () {
      return LE.sessionManager.hasPrivilege(IDs.VIEW_AGENT_STATUS_TIMER);
    };

    var hasManageDomainsPrivilege = function () {
      return LE.sessionManager.hasPrivilege(IDs.EDIT_REFERER_RESTRICTIONS);
    };

    var hasSiteAdminPrivilege = function () {
      return LE.sessionManager.hasPrivilege(IDs.SITE_ADMINISTRATION);
    };

    var hasViewAppKeyManagementPrivilege = function () {
      return LE.sessionManager.hasPrivilege(IDs.VIEW_API_KEYS_MANAGEMENT);
    };

    var hasViewMessagingSurveysPrivilege = function() {
      return  LE.sessionManager.hasPrivilege(IDs.VIEW_MESSAGING_SURVEYS);
    };

    var hasAutomationAreaPrivilege = function() {
      return LE.sessionManager.hasPrivilege(IDs.VIEW_AUTOMATION_AREA);
    };

    return {
      hasMyProfileButtonPrivilege: hasMyProfileButtonPrivilege,
      hasContentImportPrivileges: hasContentImportPrivileges,
      hasCampaignEditPrivilege: hasCampaignEditPrivilege,
      hasAgentManagerPrivilege: hasAgentManagerPrivilege,
      hasAccountBillingPrivilege: hasAccountBillingPrivilege,
      hasUserManagementPrivilege: hasUserManagementPrivilege,
      hasAgentTimerPrivilege: hasAgentTimerPrivilege,
      hasManageDomainsPrivilege: hasManageDomainsPrivilege,
      hasViewAppKeyManagementPrivilege: hasViewAppKeyManagementPrivilege,
      hasSiteAdminPrivilege: hasSiteAdminPrivilege,
      hasViewMessagingSurveysPrivilege: hasViewMessagingSurveysPrivilege,
      hasAutomationAreaPrivilege: hasAutomationAreaPrivilege,
    };
  })();
  return privilegeSettings;
});
