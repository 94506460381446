/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/14/14
 * Time: 3:08 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var _ = require('underscore');
    var Marionette = require("marionette");
    var template = require("ui.components/dependency-indicator/templates/dependencyTooltip.tmpl");
    var DependencyTypeListView = require("ui.components/dependency-indicator/js/views/DependencyTypeListView");

    var MAX_ITEMS_PER_TYPE = 10;

    var DependencyTooltipView = Backbone.Marionette.CompositeView.extend({
        template: template,
        itemView: DependencyTypeListView,
        itemViewContainer: ".dependency-types-list",
        itemViewOptions: function() {
            return {
                maxItemsToDisplay: this.model.get("maxItemsToDisplay"),
            };
        },

        initialize: function(options) {
            this.dependenciesMap = options.dependenciesMap;
            var typesArr = [];
            _.each(this.dependenciesMap, _.bind(function(val, key) {
                typesArr.push({
                    type: key,
                    showTypeTitle: this.model.get("showDependencyTypeTitle"),
                    dependencies: val
                });
            }, this));
            this.collection = new Backbone.Collection(typesArr);
        }
    });

    return DependencyTooltipView;
});
