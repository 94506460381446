/**
 * Created with IntelliJ IDEA.
 * User: itaik
 * Date: 2/13/13
 * Time: 2:59 PM
 * To change this template use File | Settings | File Templates.
 */
/*
 set application level configurations
 create the LE application object
 add application level services and objects to LE
 sign up all the registered modules to the LE application and set their defaults
 */
define(function (require) {
    "use strict";

    //list all the dependencies in a more readable format
    var LiveEngageApplication = require("src/LEApplication");
    var es6MiddleUtils = require("src/es6MiddleUtils");
    var liveEngageBaseRouteHandler =  es6MiddleUtils.removeEsModuleDefault(require('routers/liveEngageBaseRouteHandler'));
    var Media = require("media");
    var $store = require("jquery.store");
  var _ = require('underscore');


    //create a media channel for the application (I am going to override marionette default wreqr vent, commands and reqres with the media channel
    var channel = Media.channel("LiveEngageApplication");

    //create the application object
    var LE = new LiveEngageApplication({
        channel: channel,
        vent: channel.vent,
        commands: channel.commands,
        reqres: channel.reqres
    });

    // Put it as global
    if (!_.isUndefined(window)) {
        window.LE = LE;
    }

    // Create the main application setting the main region container and the main router - we do it here so it will be the first router created
    LE.create({
        container: ".root",
        routeController: liveEngageBaseRouteHandler
    });

    return LE;
});
