<template>
  <div :data-track="`nav-item-icon-${icon}`" class="nav-list__icon-wrapper">
    <div
      :class="
        `ps-s-icon s-${icon}${
          selectedCategoryIndex === index ? '-selected' : ''
        }`
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: 'NavBarCategoryIcon',
  props: {
    icon: String,
    selectedCategoryIndex: Number,
    index: Number
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/navigation_bar';
@import '../../../../assets/scss/navigation_bar_icons';

.ps-s-icon {
  width: 24px;
  height: 24px;
}
</style>
