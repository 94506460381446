/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/14/14
 * Time: 3:22 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Backbone = require('backbone');
    var DependencyItemModel = require("ui.components/dependency-indicator/js/models/DependencyItemModel");

    var DependencyCollection = Backbone.Collection.extend({
        model: DependencyItemModel
    });

    return DependencyCollection;
});
