import {
  store,
  storeModuleTypes,
  environmentGetterTypes,
  environmentActionTypes,
  gChannel,
  AGENT_STATUS_DATA_STATES,
  Logger,
  sessionManager,
} from 'vue-infra';

import * as CONST from 'const';

const MAX_UMS_UPDATE_STATUS_RETRIES = 20;
const ASYNC_MESSAGING_FEATURE = 'Common.Async_Messaging';
const ENFORCE_NEW_AGENT_WORKSPACE = "LEUI.Enforce_New_Agent_Workspace";
const HAS_MESSAGING_PRIVILEGE = 1734;
const VIEW_CAMPAIGN_MODULE_PRIVILEGE = 1500;
const VIEW_USERS_MODULE_PRIVILEGE = 1501;
const VIEW_AGENT_MODULE_PRIVILEGE = 1502;
const VIEW_AGENT_MANAGER_MODULE_PRIVILEGE = 1503;
const DENVER_POLLING_DEFAULT_TIMEOUT = 2000;

let statusRequestAndPollingMismatchCounter = 0;
let umsUpdateStatusRetries = 0;
let denverUpdateStatusRetries =0;
let manualPollingCounter = 0;
let shouldLogAgentStatus,
  fullUserId,
  logger,
  hasMessaging,
  accountId,
  userId,
  overrideDenverPolling,
  keepAliveTimeout;
let umsIsSetStatusSuccess = false;
let denverIsSetStatusSuccess = false;
let isUMSSetStatusFinish =false;
let isDenverSetStatusFinish = false;

export class AgentStatusManager {
  constructor() {
    logger = Logger.getLogger("AgentStatusManager");
    shouldLogAgentStatus = hasLogAgentStatusSetting();
    hasMessaging = isMessagingEnabled();
    // If denver polling frequency configured to be more than default (2000 mill) than when changing agent status,
    // initiate the polling manually with default timeout (2000 mill), so the knowledge of if the agent status changed, won't be delayed.
    overrideDenverPolling = hasChangeInDenverPollingFrequency();

     accountId = store.getters['session/getValue']('accountId');
     userId = store.getters['session/getValue']('userId');
    fullUserId = accountId + '.' + userId;
    window.logAgentStatusMismatch = logAgentStatusToKibana;
    store.watch(() => store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.REQUEST_AGENT_STATUS_CHANGE}`], onRequestAgentStatusChange);
    gChannel.register('LiveEngageCommon', 'onDenverError', onDenverError);
    gChannel.register('LiveEngageCommon', 'onUMSError', onUMSError);
    gChannel.register('LiveEngageCommon', 'onStatusChangeError', onStatusChangeError);
    if(hasChatFeature()){
      gChannel.register('LiveEngageCommon', 'agentStatusPollingSuccess', onAgentStatusPollingSuccess);
    }

    // in case agent with out messaging premission and without chat

    if(!isMessagingEnabled() && !hasChatFeature()){
      logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature} agent has not permissions`);
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
        value: 'AWAY',
      });
    }
  }
}

function onRequestAgentStatusChange() {
  if(!isAgentStatusChangeInProcess()) {
    const newStatus = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.REQUEST_AGENT_STATUS_CHANGE}`];
    if (newStatus.value !== null) {
      const currentStatus = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.CURRENT_AGENT_STATUS}`] || {};

      if (newStatus.value !== currentStatus.value || ((newStatus.reasonId !== currentStatus.reasonId) && (newStatus.reasonId || currentStatus.reasonId))) {
        sendAgentStatusChange(newStatus);
      }
    }
  }
}

function  sendAgentStatusChange(status){
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature()} enter to sendAgentStatusChange :${JSON.stringify(status)}`);
  if(sessionManager.isLPA()){
    status.value='AWAY';
    logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature()} enter to sendAgentStatusChange theUserIsLPA :${JSON.stringify(status)}`);
  }
  if(isMessagingEnabled()){
   if(hasChatFeature()){
     //blended account
     logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature()} enter to blended account`);
      sendAgentStatusChangeToDenver(status);
      requestStatusChangeInUMS(status);
    } else {
     isDenverSetStatusFinish = true;
     // messaging only account
     logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature()} enter to messaging only account`);
      requestStatusChangeInUMS(status);
    }
  } else if(hasChatFeature()) {
    // chat only account
    isUMSSetStatusFinish = true;
    logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature()} enter to chat only account`);
    sendAgentStatusChangeToDenver(status);
  }else{
    // agent without messaging and chat
    logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature()} enter agent with no chat or messaging`);
    onFailedToUpdateStatus();
  }
}
function convertAvailabilityForDenver(status){
  switch (status){
    case 'AWAY':
      return 'Away';
    case'ONLINE':
    case "ONLINE":
      return 'Online';
    case 'BACK_SOON':
    case "BACK_SOON":
      return 'Occupied';
    case 'OCCUPIED':
    case "OCCUPIED":
      return 'Occupied';
    case 'OFFLINE':
      return 'Away';
    default:
      return status;
  }
}
function sendAgentStatusChangeToDenver(status) {
  isDenverSetStatusFinish = false;
  denverIsSetStatusSuccess = false;
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.AWAITING_DENVER);
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId} ,isMessagingEnabled ${isMessagingEnabled()} ,hasChatFeature ${hasChatFeature()} enter to  sendAgentStatusChangeToDenver :${convertAvailabilityForDenver(status.value)}`);
  try{
  lpTag.WebAgent.AppMediator.setAvailability({
    chatAvailability: convertAvailabilityForDenver(status.value),
    chatReasonId: status.reasonId || '',
    // success: onStatusUpdateCompleted.bind(this, status,'denver'),
    success: onDenverStatusChangeSuccess.bind(this, status),
    error: onStatusChangeError.bind(this, status,'denver'),
  });
  } catch (e){
    logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, failed to send setAvailability to denver ${JSON.stringify(e)}`);
    isDenverSetStatusFinish = true;
    onStatusChangeError(status,'denver');
  }

}
function onDenverStatusChangeSuccess(status) {
  statusRequestAndPollingMismatchCounter = 0;
  if(overrideDenverPolling) {
    manualPollingCounter = 0;
    startOverrideDenverPolling();
  }
  logAgentStatusToKibana(`AgentStatusManager Denver:setAvailability:success. Status=${status.value}, reasonId=${status.reasonId}`);
}

/**
 * If denver polling frequency configured to be more than default (2000 mill) than when changing agent status,
 * initiate the polling manually with default timeout (2000 mill), so the knowledge of if the agent status changed, won't be delayed.
 * Pull only 2 times. 2 times is enough to know if the status was really updated in denver.
 */
function startOverrideDenverPolling() {
  clearTimeout(keepAliveTimeout);
  if(manualPollingCounter <= 2) {
    manualPollingCounter++;
    gChannel.trigger('LiveEngageCommon', 'agentStatus:requestDenverPolling');
    keepAliveTimeout = setTimeout(startOverrideDenverPolling, DENVER_POLLING_DEFAULT_TIMEOUT);
  }
}

/**
 * If denver polling frequency configured to be more than default (2000 mill) than when changing agent status,
 * initiate the polling manually with default timeout (2000 mill), so the knowledge of if the agent status changed, won't be delayed.
 * Pull only 2 times. 2 times is enough to know if the status was really updated in denver.

function startOverrideDenverPolling() {
  console.log('startOverrideDenverPolling manualPollingCounter '+manualPollingCounter);
  clearTimeout(keepAliveTimeout);
  if(manualPollingCounter <= MAX_STATUS_POLLING_RETRIES) {
    manualPollingCounter++;
    gChannel.trigger('LiveEngageCommon', 'agentStatus:requestDenverPolling');
    keepAliveTimeout = setTimeout(startOverrideDenverPolling, DENVER_POLLING_DEFAULT_TIMEOUT);
  }
}
 */

function onAgentStatusPollingSuccess(status) { // When we get by polling from Denver agent status changed
    if (status.value) {
      const requestAgentStatusChange = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.REQUEST_AGENT_STATUS_CHANGE}`];
      if (requestAgentStatusChange.value !== null) { // If there was any request to change the status
        logAgentStatusToKibana(`AgentStatusManager - Denver:polling. Status=${status.value}, reasonId=${status.reasonId}`);
        logAgentStatusToKibana('AgentStatusManager - onAgentStatusPollingSuccess -requestAgentStatusChange ' +convertAvailabilityForDenver(requestAgentStatusChange.value) + " status " + JSON.stringify(status));
        if(status.maxChats == '0' && isMessagingEnabled()){
          onStatusUpdateCompleted(requestAgentStatusChange, 'denver');
        }
        if (status.value !== convertAvailabilityForDenver(requestAgentStatusChange.value)) {
          onStatusChangeError(requestAgentStatusChange, 'denver');
        } else {
          onStatusUpdateCompleted(status, 'denver');
        }
      } else {
        const currentStatus = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.CURRENT_AGENT_STATUS}`] || {};
        if(status.maxChats != '0' && currentStatus.value != status.value && currentStatus.value != AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY){
          onStatusUpdateCompleted(status, 'denver');
          if(isMessagingEnabled()) {
            requestStatusChangeInUMS(status);
            }
          }
        }
      }
  }



function onFailedToUpdateStatus(){
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, enter onFailedToUpdateStatus }`);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
    value: null,
  });
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_MESSAGING_AGENT_STATUS}`, null);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CHAT_AGENT_STATUS}`, null);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.STATUS_UPDATE_FAILED);
}



function isAgentStatusChangeInProcess() {
  const agentStatusDataState = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.AGENT_STATUS_DATA_STATE}`];
  return !(agentStatusDataState !== AGENT_STATUS_DATA_STATES.AWAITING_DENV && agentStatusDataState !== AGENT_STATUS_DATA_STATES.AWAITING_UMS);
}
function convertAvailabilityForUMS(status){
  switch (status){
    case 'OCCUPIED':
      return 'BACK_SOON';
    default:
      return status;
  }
}
function requestStatusChangeInUMS(status,accountId,userId) {
  isUMSSetStatusFinish = false;
  umsIsSetStatusSuccess = false;
  // If the requested status is equal to the status returned from Denver
  statusRequestAndPollingMismatchCounter = 0;
  clearTimeout(keepAliveTimeout);
  if(hasMessaging) {
    const currentAgentStatusDataState = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.AGENT_STATUS_DATA_STATE}`];
    if(currentAgentStatusDataState !== AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY) {
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.AWAITING_UMS);
      status.value=convertAvailabilityForUMS(status.value);
      accountId = store.getters['session/getValue']('accountId');
      userId = store.getters['session/getValue']('userId');
      logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, enter requestStatusChangeInUMS status ${JSON.stringify(status) }`);
      gChannel.trigger('LiveEngageCommon', 'agentStatus:requestUMSChange', {
        data: {
          status,
        },
        success: onStatusUpdateCompleted.bind(this, status,'ums',accountId,userId),
        error: onFailedSendUMSRequest.bind(this, status,'ums'),
      });
    }
  } else {
    onStatusUpdateCompleted(status,'ums');
  }
}

function onFailedSendUMSRequest(status) {
  if(umsUpdateStatusRetries >= MAX_UMS_UPDATE_STATUS_RETRIES) {
    umsUpdateStatusRetries = 0;
    onStatusChangeError(status,'ums');

  } else {
    const REQUEST_AGAIN_STATUS_CHANGE_TIMEOUT = 2000;
    umsUpdateStatusRetries++;
    setTimeout(requestStatusChangeInUMS.bind(this, status), REQUEST_AGAIN_STATUS_CHANGE_TIMEOUT);
  }
}

function onStatusUpdateCompleted(status,origin) {
  if(origin === 'ums'){
    umsIsSetStatusSuccess = true;
    isUMSSetStatusFinish = true;
  }
  if(origin === 'denver'){
    denverIsSetStatusSuccess = true;
    isDenverSetStatusFinish = true ;
    denverUpdateStatusRetries = 0;
  }
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, enter onStatusUpdateCompleted status ${JSON.stringify(status)}, origin: ${origin} , umsIsSetStatusSuccess  ${umsIsSetStatusSuccess}  , denverIsSetStatusSuccess:${denverIsSetStatusSuccess}`);

  if(isMessagingEnabled()){
    if(hasChatFeature()){
      //blended account
      if(isDenverSetStatusFinish && isUMSSetStatusFinish){
        if(denverIsSetStatusSuccess && umsIsSetStatusSuccess){
          onStatusComplete(status);
        } else {
          onDiscrepancyStatus(status);
        }
      }
    } else {
      //messaging-a only ccount
      if(umsIsSetStatusSuccess){
        onStatusComplete(status);
      } else{
        onStatusChangeError(status,origin);
      }
    }
  } else if(hasChatFeature()) {
    // chat only account
    if(denverIsSetStatusSuccess){
      onStatusComplete(status);
    }else{
      onStatusChangeError(status,origin);
    }
  }
}

function onStatusComplete(status){
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, enter onStatusComplete status ${JSON.stringify(status)}, origin: ${origin} , umsIsSetStatusSuccess  ${umsIsSetStatusSuccess}  , denverIsSetStatusSuccess:${denverIsSetStatusSuccess}`);
  status.value=convertAvailabilityForDenver(status.value);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CURRENT_AGENT_STATUS}`, status);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
    value: null,
  });
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_MESSAGING_AGENT_STATUS}`, status);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CHAT_AGENT_STATUS}`, status);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.SUCCESS);
  statusRequestAndPollingMismatchCounter = 0;
  umsUpdateStatusRetries = 0;
}

function onDenverError(){
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, enter onDenverError`);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
    value: 'AWAY',
  });
}

function onUMSError(){
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, enter onUMSError`);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
    value: 'AWAY',
  });
}
function onStatusChangeError(status,origin) {
  if(!(umsIsSetStatusSuccess && denverIsSetStatusSuccess)) {
    logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, origin : ${origin},  status ${JSON.stringify(status)}  enter onStatusChangeError`);
    if (origin === 'ums') {
      umsIsSetStatusSuccess = false;
      isUMSSetStatusFinish = true;
    }
    if (origin === 'denver' || status.origin === 'denver') {
      if (denverUpdateStatusRetries < 5) {
        denverUpdateStatusRetries++;
        logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId}, origin : ${origin},  status ${JSON.stringify(status)}  enter onStatusChangeError denverUpdateStatusRetries${denverUpdateStatusRetries}`);
        sendAgentStatusChangeToDenver(status);
        return;
      }
      denverIsSetStatusSuccess = false;
      isDenverSetStatusFinish = true;
    }
    logAgentStatusToKibana(`AgentStatusManager onStatusChangeError isUMSSetStatusFinish ${isUMSSetStatusFinish}, isDenverSetStatusFinish ${isDenverSetStatusFinish} umsIsSetStatusSuccess ${umsIsSetStatusSuccess} , denverIsSetStatusSuccess ${denverIsSetStatusSuccess}`);
    if (isUMSSetStatusFinish && isDenverSetStatusFinish && ((umsIsSetStatusSuccess === false || umsIsSetStatusSuccess === undefined) && (denverIsSetStatusSuccess === false || denverIsSetStatusSuccess === undefined))) {
      onFailedToUpdateStatus();
    } else {
      if (isMessagingEnabled() && hasChatFeature()) {
        //blended account
        if (umsIsSetStatusSuccess === true && denverIsSetStatusSuccess === false || umsIsSetStatusSuccess === false && denverIsSetStatusSuccess === true) {
          onDiscrepancyStatus(status);
        }
      }
    }
  }
}
function onDiscrepancyStatus(status) {
  logAgentStatusToKibana(`AgentStatusManager ,account: ${accountId} userId: ${userId} fullUserId: ${fullUserId},enter  onDiscrepancyStatus  ums: ${umsIsSetStatusSuccess}, denver: ${denverIsSetStatusSuccess===true}`);
  if(isUMSSetStatusFinish && isDenverSetStatusFinish &&(umsIsSetStatusSuccess === false  && denverIsSetStatusSuccess === false )) {
      onFailedToUpdateStatus();
  } else if(isUMSSetStatusFinish && isDenverSetStatusFinish && ((denverIsSetStatusSuccess === true && umsIsSetStatusSuccess === false) || denverIsSetStatusSuccess === false && umsIsSetStatusSuccess === true)){
    if(denverIsSetStatusSuccess === true && umsIsSetStatusSuccess === false){
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_MESSAGING_AGENT_STATUS}`, CONST.DEFAULT_AGENT_STATUSES.unknown.value);
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CHAT_AGENT_STATUS}`, status);
    }else if( denverIsSetStatusSuccess === false && umsIsSetStatusSuccess === true){
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_MESSAGING_AGENT_STATUS}`, status);
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CHAT_AGENT_STATUS}`, CONST.DEFAULT_AGENT_STATUSES.unknown.value);
    }
    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CURRENT_AGENT_STATUS}`, {
      value: CONST.DEFAULT_AGENT_STATUSES.unknown.value
    });
    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
      value: null,
    });
    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`,
      AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY);
    denverUpdateStatusRetries = 0;

  }
}

function isMessagingEnabled() {
  return hasMessagingFeature() && hasMessagingPrivilege();
}

function hasMessagingFeature() {
  return sessionManager.getFeaturePropertyState(ASYNC_MESSAGING_FEATURE);
}

function hasChatFeature() {
  return sessionManager.getFeaturePropertyState(CONST.FEATURES.IS_CHAT_ENABLE_FEATURE) || !sessionManager.getFeaturePropertyState(ENFORCE_NEW_AGENT_WORKSPACE);
}

function hasMessagingPrivilege() {
  return sessionManager.hasPrivilege(HAS_MESSAGING_PRIVILEGE) || !!sessionManager.isLPA();
}

function isAgentOnly() {
  const hasAgentManagerModulePrivilege = sessionManager.hasPrivilege(VIEW_AGENT_MANAGER_MODULE_PRIVILEGE);
  const hasUsersViewPrivilege = sessionManager.hasPrivilege(VIEW_USERS_MODULE_PRIVILEGE);
  const hasCampaignManagerModulePrivilege = sessionManager.hasPrivilege(VIEW_CAMPAIGN_MODULE_PRIVILEGE);
  const hasAgentViewPrivilege = sessionManager.hasPrivilege(VIEW_AGENT_MODULE_PRIVILEGE);

  return hasAgentViewPrivilege &&
    !hasCampaignManagerModulePrivilege &&
    !hasUsersViewPrivilege &&
    !hasAgentManagerModulePrivilege;
}

function logAgentStatusToKibana(message, enforceLog) {
  if (shouldLogAgentStatus || enforceLog) {
    logger.error(`${message}`,'ccui');
  }
}

function hasLogAgentStatusSetting() {
  const GET_SESSION_SETTINGS_PATH = 'session/getSettingsMap';
  const DIAGNOSTIC_KEY = 'le.agent.diagnostics';
  const LOG_AGENT_STATUS_KEY = 'logAgentStatus';

  const diagnostics = store.getters[GET_SESSION_SETTINGS_PATH]()[DIAGNOSTIC_KEY];
  const diagnosticsArray = diagnostics && diagnostics.propertyValue && diagnostics.propertyValue.value;
  return diagnosticsArray && diagnosticsArray.indexOf && diagnosticsArray.indexOf(LOG_AGENT_STATUS_KEY) >= 0;
}

function hasChangeInDenverPollingFrequency() {
  const isOverrideDenverPollingForSetStatus = (sessionManager.getLEConfigProp && sessionManager.getLEConfigProp('isOverrideDenverPollingForSetStatus')) || {};
  if (isOverrideDenverPollingForSetStatus) {
    return true;
  }
  else {
    // If there is no phase configuration (AKA feature flag) configured, check if there is there is site settings
    let denverPollingTimeout = sessionManager.getAccountSettingValueByID('messaging.only.account.denver.polling.frequency.milliseconds', false);
    denverPollingTimeout = parseInt(denverPollingTimeout, 10);
    return (!isNaN(denverPollingTimeout) && denverPollingTimeout > DENVER_POLLING_DEFAULT_TIMEOUT);
  }
}
