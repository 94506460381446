define(function (require) {
    "use strict";

    var Backbone = require("backbone");

    var RevisionedModel = require("models/RevisionedModel");
    var ApiResources = require("assets/data/apiResources.json");
    var Config = require("assets/config/entities/config.json");
    var JwtUtility = require("utils/JwtUtility");
    var SoftwareModel = require("models/entities/SoftwareModel");
  var _ = require('underscore');

    var KEYS = {
        CLIENT_ID: "client_id",
        CLIENT_NAME: "client_name",
        ENABLED: "enabled",
        SOFTWARE_STATEMENT: "software_statement",
        LOGO_URI: "logo_uri",
        CAPABILITIES: "capabilities",
        CAPABILITIES_ENGAGEMENT: "capabilities.engagement",
        CAPABILITIES_BROADCAST: "capabilities.broadcast",
        DESCRIPTION: "description",
        ENTRY_POINT: "capabilities.engagement.entry_point"
    };

    var LOGO_URI_EXTENSIONS = ["PNG", "GIF", "JPEG", "JPG"];

    var LOGO_PREVIEW_STATES = {
        NONE: "none",
        LOADING: "loading",
        LOADED: "loaded",
        ERROR: "error"
    };

    var SPECIAL_SOURCES_IDS = {
        WEB: "web",
        OFFSITE: "offsite",
        EXTERNAL: "external"
    };

    var AppInstallModel = RevisionedModel.extend({
        idAttribute: "client_id",
        resource: ApiResources.AccountConfig.AppInstall,
        KEYS: KEYS,
        name: "AppInstallModel",
        defaults: {
            client_id: null,
            client_name: "",
            enabled: true,
            logo_uri: "",
            capabilities: {}
        },
        logoUriState: LOGO_PREVIEW_STATES.NONE,
        softwareLogoUri: "",

        validation: {
            client_name: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.appInstall.errors.name.required"
                },
                {
                    maxLength: Config.validators.appInstall.maxNameLength,
                    msg: "LEFramework.entities.appInstall.errors.name.maxLength"
                }
            ],
            description: [
                {
                    required: false,
                    minLength: 0,
                    maxLength: Config.validators.appInstall.maxDescriptionLength,
                    msg: "LEFramework.entities.appInstall.errors.description.maxLength"
                }
            ],
            logo_uri: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.appInstall.errors.logoUri.required"
                },
                {
                    maxLength: Config.validators.appInstall.maxLogoUriLength,
                    msg: "LEFramework.entities.appInstall.errors.logoUri.maxLength"
                },
                {
                    fn: function() {
                        var error;
                        var logoUri = this.getLogoUri();
                        var hasLogoExtension = this._hasLogoExtension(logoUri);
                        var isSoftwareLogoUri = logoUri === this.softwareLogoUri;
                        if (this.logoUriState === LOGO_PREVIEW_STATES.ERROR) {
                            error = "LEFramework.entities.appInstall.errors.broken.url";
                        }
                        if (!isSoftwareLogoUri && !logoUri.match(Backbone.Validation.patterns.httpsOnly)) {
                            error = "LEFramework.entities.appInstall.errors.logo.uri.https.mandatory";
                        }
                        if (!isSoftwareLogoUri && !logoUri.match(Backbone.Validation.patterns.fullUrl)) {
                            error = "LEFramework.entities.appInstall.errors.logo.uri.not.valid";
                        }
                        if (!hasLogoExtension) {
                            error = "LEFramework.entities.appInstall.errors.logo.uri.unsupported.format";
                        }
                        return error;
                    }
                }
            ]
        },

        initialize: function(attrs, options) {
            RevisionedModel.prototype.initialize.call(this, attrs, options);
        },

        parse: function (response, options) {
            if (response && response.logo_uri &&
                response.logo_uri.indexOf('/src/modules/campaigns/assets/img/') === 0) {
                response.logo_uri = window.leImageBaseUrl + response.logo_uri.substring(1);
            }

            return RevisionedModel.prototype.parse.call(this, response, options);
        },

        toJSON: function (options) {
            var json = RevisionedModel.prototype.toJSON.apply(this, [].slice.apply(arguments));

            if (options && options.emulateHTTP === true && // avoid removing when rendering, only when saving
                json && json.logo_uri &&
                json.logo_uri.indexOf(window.leImageBaseUrl) === 0) {
                json.logo_uri = json.logo_uri.substring(window.leImageBaseUrl.length - 1);
            }
            return json;
        },

        getAppInstallId: function(){
            return this.get(KEYS.CLIENT_ID);
        },

        getSoftwareStatement: function(){
            return this.get(KEYS.SOFTWARE_STATEMENT);
        },

        getSoftwareId: function(){
            var softwareId = null;
            var softwareModel = this.getSoftwareModel();
            if (!_.isUndefined(softwareModel)) {
                softwareId = softwareModel.getSoftwareId();
            }
            return softwareId;
        },

        getLogoUri: function(){
            return this.get(KEYS.LOGO_URI);
        },

        getCapabilities: function(){
            return this.get(KEYS.CAPABILITIES);
        },

        getEngagementCapabilities: function() {
            return this.get(KEYS.CAPABILITIES_ENGAGEMENT);
        },

        getBroadcastCapabilities: function() {
            return this.get(KEYS.CAPABILITIES_BROADCAST);
        },

        getName: function(){
            return this.get(KEYS.CLIENT_NAME);
        },

        setLogoUriState: function(state){
            this.logoUriState = state;
        },
        getLogoUriState: function() {
            return this.logoUriState;
        },

        getDescription: function(){
            return this.get(KEYS.DESCRIPTION);
        },

        getEnabled: function(){
            return this.get(KEYS.ENABLED);
        },

        setEnabled: function(enabled){
            this.set(KEYS.ENABLED, enabled);
        },

        setLogoUri: function(logoUri){
            this.set(KEYS.LOGO_URI, logoUri);
        },

        setDescription: function(description){
            this.set(KEYS.DESCRIPTION, description);
        },

        setName: function(name){
            this.set(KEYS.CLIENT_NAME, name);
        },

        isSpecial: function(){
            var appInstallId = this.getAppInstallId();
            return  _.include(_.values(SPECIAL_SOURCES_IDS), appInstallId);
        },

        setSoftwareLogoUri: function(softwareLogoUri) {
            this.softwareLogoUri = softwareLogoUri;
        },

        _hasLogoExtension: function() {
            var result = false;
            var logoUri = this.getLogoUri();
            if (!_.isEmpty(logoUri)){
                var ext = logoUri.substr(logoUri.lastIndexOf('.')+1);
                if (ext) {
                    for (var ii=0; ii < LOGO_URI_EXTENSIONS.length; ii++){
                        if (ext.toLowerCase() === LOGO_URI_EXTENSIONS[ii].toLowerCase()) {
                            result = true;
                        }
                    }
                }
            }
            return result;
        },
        getEntryPoint: function(){
            return this.get(KEYS.ENTRY_POINT);
        },
        isPrivateAppInstall: function(){
            return _.isUndefined(this.getSoftwareStatement());
        },
        getSoftwareModel: function() {
            if (_.isUndefined(this.softwareModel) && !this.isPrivateAppInstall()){
                this.softwareModel = new SoftwareModel({software_statement: this.getSoftwareStatement()}, {parse: true});
            }
            return this.softwareModel;
        }
    });

    AppInstallModel.KEYS = KEYS;
    AppInstallModel.SPECIAL_SOURCES_IDS = SPECIAL_SOURCES_IDS;
    AppInstallModel.LOGO_PREVIEW_STATES = LOGO_PREVIEW_STATES;

    return AppInstallModel;
});
