/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:38 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var featureSettings = (function () {

        var hasLiveEngage2Feature = function () {
            return LE.sessionManager.hasLiveEngage2Feature();
        };

        var hasDataEncryptionFeature = function () {
            return LE.sessionManager.hasDataEncryptionDataFeature();
        };

        var hasMigratingFeature = function() {
            return LE.sessionManager.hasMigratingFeature();
        };

        var hasBlockAddingUsersFeature = function() {
            return LE.sessionManager.hasBlockAddingUsersFeature();
        };

        var hasBlockSupportAccessFeature = function() {
            return LE.sessionManager.hasBlockSupportAccessFeature();
        };

        var isSeatBasedFeature = function() {
            return LE.sessionManager.isSeatBasedFeature();
        };

        return {
            hasLiveEngage2Feature: hasLiveEngage2Feature,
            hasDataEncryptionFeature: hasDataEncryptionFeature,
            hasMigratingFeature: hasMigratingFeature,
            hasBlockAddingUsersFeature: hasBlockAddingUsersFeature,
            hasBlockSupportAccessFeature: hasBlockSupportAccessFeature,
            isSeatBasedFeature: isSeatBasedFeature
        };

    })();
    
    return featureSettings;
});
