/**
 * User: danielda
 */
define(function (require) {
    "use strict";

    var BaseModel = require("models/BaseModel");
    var ApiResources = require("assets/data/apiResources.json");

    var DefaultCampaignModel = BaseModel.extend({

        resource: ApiResources.CreateAccount.Campaign,
        name: "DefaultCampaignModel"
    });

    return DefaultCampaignModel;
});
