<template>
  <div class="top-level-menu-filtered-status-list-view">
    <div class="header-container">
      <div class="top-header-container">
        <span
          class="ico-single-arrow-left"
          tabindex="0"
          :aria-label="backToUserMenuAriaLabel"
          role="button"
          @click="returnBack"
          @keydown.enter.space.prevent="returnBack"
        ></span>
        <span class="header-text">{{ awayReasonsTitle }}</span>
        <span
          class="top-level-menu-status-icon ico-agent-status-away away"
        ></span>
      </div>
      <le-input-field
        v-model="inputFieldSearchValue"
        class="search-input-field"
        input-type="search"
        :placeholder="searchPlaceholder"
        :aria-label="searchPlaceholder"
        border-radius="4"
        input-height="44px"
      />
    </div>
    <div class="body-container">
      <div
        :class="[
          'status-notification',
          { visible: showEmptyFilteredAwayStatusesMsg },
        ]"
      >
        {{ emptyFilteredAwayStatusesMsg }}
      </div>
      <div
        :class="[
          'filtered-items-container',
          { visible: !showEmptyFilteredAwayStatusesMsg },
        ]"
      >
        <top-level-menu-filtered-item
          v-for="awayStatus in filteredCustomAwayReasonList"
          :key="awayStatus.id"
          :away-status="awayStatus"
          @clicked="onItemClicked"
        />
      </div>
    </div>
    <div
      ref="aria-live-region"
      role="status"
      aria-live="polite"
      class="sr-only"
    ></div>
  </div>
</template>

<script>
import { leInputField } from '@liveperson/lp-vue-components';
import TopLevelMenuFilteredItem from './TopLevelMenuFilteredItem';
import * as CONST from 'const';

export default {
  name: 'TopLevelMenuFilteredStatusListView',
  components: {
    leInputField,
    TopLevelMenuFilteredItem,
  },
  props: {
    customAwayReasonList: {
      type: Object,
      required: true,
    },
  },
  emits: ['clicked', 'returnBack'],
  data() {
    return {
      awayReasonsTitle: this.$translator.translate(
        'LEFramework.userBarMenu.item.customReasons.awayResons'
      ),
      backToUserMenuAriaLabel: this.$translator.translate(
        'LEFramework.userBarMenu.item.customReasons.backToUserMenu'
      ),
      inputFieldSearchValue: '',
      searchPlaceholder: this.$translator.translate(
        'LEFramework.userBarMenu.item.customReasons.searchReasonPlaceholder'
      ),
      emptyFilteredAwayStatusesMsg: this.$translator.translate(
        'LEFramework.userBarMenu.item.customReasons.noStatusFoundMsg'
      ),
      showEmptyFilteredAwayStatusesMsg: false,
    };
  },
  computed: {
    filteredCustomAwayReasonList() {
      const searchedValue = this.inputFieldSearchValue.toLowerCase();
      return Object.values(this.customAwayReasonList).filter(
        (customAwayReason) =>
          customAwayReason.text.toLowerCase().includes(searchedValue)
      );
    },
  },
  watch: {
    filteredCustomAwayReasonList: {
      handler() {
        this.showEmptyFilteredAwayStatusesMsg =
          !this.filteredCustomAwayReasonList.length;

        this.a11yAriaLivePolite();
      },
      deep: true
    }
  },
  methods: {
    returnBack() {
      this.$emit('returnBack', CONST.AGENT_MENU_VIEWS.MAIN);
    },
    onItemClicked(awayStatus) {
      this.$emit('clicked', awayStatus);
    },
    a11yAriaLivePolite() {
      setTimeout(() => {
        this.$refs['aria-live-region'].textContent = this.$translator.translate(
          `LEFramework.userBarMenu.item.customReasons.${
            this.filteredCustomAwayReasonList.length
              ? 'itemsFoundA11y'
              : 'noStatusFoundMsg'
          }`
        );
      }, 700); // give screenreader time to notice new changes, will avoid it from being too noisy
    },
  },
};
</script>

<style lang="scss" scoped>
.top-level-menu-filtered-status-list-view {
  @include themify {
    color: getColor('user-menu-text', 'framework');
  }

  .header-container {
    margin-bottom: 16px;
    padding: 18px 16px 0 16px;

    .top-header-container {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;

      .ico-single-arrow-left {
        cursor: pointer;
        margin-right: auto;
      }
      .header-text {
        margin-right: 11px;
      }
      .top-level-menu-status-icon {
        display: inline-block;
      }
    }
  }

  .body-container {
    position: relative;
    padding: 0 0 13px 16px;

    .status-notification {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .filtered-items-container {
      overflow-y: scroll;
      scrollbar-width: auto; // The scrollbar in Firefox and Chrome should be the same width
      height: 500px;
    }

    .status-notification,
    .filtered-items-container {
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }
  }
}
</style>
