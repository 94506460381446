/**
 * User: gals
 */
define(function (require) {
    "use strict";

    var template = require("src/components/migration-activation/templates/migrationWelcome.tmpl");
    var Marionette = require("marionette");

    var MigrationWelcomeView = Marionette.Layout.extend({

        tagName: 'div',
        className: 'migrationWelcome',
        template: template,

        regions: {
            mainRegion: '.migrationWelcome-main-region'
        }
    });

    return MigrationWelcomeView;
});
