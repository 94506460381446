define(function (require) {
    "use strict";

    var LEConfig = require("assets/config/le-env-conf");
    var { Logger, ConsoleAppender, RemoteLoggosAppender, RemoteLoggosBamAppender } = require('vue-infra');
    var LEResourceResolver = require("leResourceResolver");

    function addLoggosBamAppender() {
        if(LEConfig.isBamSupportEnabled) {
            var accountId = LEResourceResolver.getCurrentAccountId();

            var bamRestStrUrl = LEResourceResolver.apiResources.LoggosBamRest.url;
            var bamRestStrUrlRep = bamRestStrUrl.replace("{accountId}",accountId);
            var restBamURL =  LEResourceResolver.getUrl(bamRestStrUrlRep);

            var loggosBAMAppender = new RemoteLoggosBamAppender();
            loggosBAMAppender.initialize({rest: restBamURL});
            Logger.addAppender({name: "RemoteLoggosBamAppender", appender: loggosBAMAppender});
        }
    }

    function addLoggosAppenders() {
        //build wss url
        var wsURL = LEResourceResolver.getUrl(LEResourceResolver.apiResources.LoggosWs.url);

        //build rest url
        var accId = LEResourceResolver.getCurrentAccountId();
        var restStrUrl = LEResourceResolver.apiResources.LoggosRest.url;
        var addAccIdToRestUrl = restStrUrl.replace("{accountId}",accId);
        var restURL = LEResourceResolver.getUrl(addAccIdToRestUrl);

        var loggosAppender = new RemoteLoggosAppender();
        loggosAppender.initialize({ws: wsURL, rest: restURL});
        Logger.addAppender({name: "RemoteLoggosAppender", appender: loggosAppender});
    }

    function addDefaultAppenders() {
        Logger.addAppender({ name: "ConsoleAppender", appender: new ConsoleAppender() });
    }

    addDefaultAppenders();
    addLoggosAppenders();
    addLoggosBamAppender();
});
