/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/14/14
 * Time: 9:38 AM
 * V.1.0
 *
 * Options:
 *
 * + collection - Optional. Must be of type DependencyIndicator.Collection.
 * + tooltipTitle - the text to be displayed in the title of the tooltip.
 * + tooltipFooterText - the text to be displayed after the list of dependencies. Displayed only in case the 'and more.'
 *                       text is not displayed.
 * + bubblePosition - see DependencyIndicator.BubblePosition
 * + bubbleAlignment - see DependencyIndicator.BubbleAlignment
 * + tooltipAutohideTimeout - time in ms
 *
 * Public methods:
 * + show: function()
 * + hide: function()
 * + setDependencyCollection: function(collection)
 * + setTooltipTitle: function(text)
 * + setTooltipFooterText: function(text)
 * + showTooltip: function()
 * + hideTooltip: function()
 * + refreshTooltip: function()
 *
 * Triggering events:
 * + change: function(locked)
 */
define(function (require) {
    "use strict";

    var $ = require("jquery");
    var _ = require("underscore");
    var { Logger } = require('vue-infra');
    var Marionette = require("marionette");
    var Backbone = require("backbone");
    var template = require("ui.components/dependency-indicator/templates/dependencyIndicator.tmpl");

    var DependencyIndicatorModel = require("ui.components/dependency-indicator/js/models/DependencyIndicatorModel");
    var DependencyCollection = require("ui.components/dependency-indicator/js/collections/DependencyCollection");
    var DependencyTooltipView = require("ui.components/dependency-indicator/js/views/DependencyTooltipView");

    var Bubble = require("ui/bubble/Bubble");

    var DependencyIndicator = Backbone.Marionette.ItemView.extend({
        template: template,
        className: "dependency-indicator",

        ui: {
            icon: ".dependency-icon-container"
        },

        events: {
            "mouseover": "onMouseOverDependencyIcon",
            "mouseout": "onMouseOutDependencyIcon"
        },

        uiComponents: {
        },

        initialize: function(options) {
            this.logger = Logger.getLogger("LEFramework");
            this.closeBubbleTimeOut = null;

            //this._validateOptions(options);

            this.container = options.container;

            this.model = new DependencyIndicatorModel({
                tooltipTitle: options.tooltipTitle || "",
                tooltipFooterText: options.tooltipFooterText || ""
            });
            if (options.maxItemsToDisplay) {
                this.model.set("maxItemsToDisplay", options.maxItemsToDisplay);
            }
            if (_.has(options, "showDependencyTypeTitle")) {
                this.model.set("showDependencyTypeTitle", options.showDependencyTypeTitle);
            }

            this.dependenciesMap = options.dependenciesMap || {};

            this.options = {};
            this.options.bubblePosition = options.bubblePosition || DependencyIndicator.BubblePosition.BOTTOM;
            this.options.bubbleAlignment = options.bubbleAlignment || DependencyIndicator.BubbleAlignment.LEFT;
            this.options.tooltipAutohideTimeout = options.tooltipAutohideTimeout || 500; // time in ms
        },

        // -------------------------------------------------------------------------------------------------------------
        // Events Handlers
        // -------------------------------------------------------------------------------------------------------------

        onRender: function() {
            //this.$el.addClass(this.className);
            this.container.append(this.$el);
        },

        onMouseOverDependencyIcon: function() {
            this._openBubble();
            this._killBubbleAutohide();
        },

        onMouseOutDependencyIcon: function() {
            this._startBubbleAutohide();
        },

        onClose: function() {
            // Make sure the bubble is closed
            this._closeBubble(true);
        },

        // -------------------------------------------------------------------------------------------------------------
        // EO Events Handlers
        // -------------------------------------------------------------------------------------------------------------

        // -------------------------------------------------------------------------------------------------------------
        // Public methods
        // -------------------------------------------------------------------------------------------------------------

        show: function() {
            if(this.$el) {
                this.$el.fadeIn();
            }
        },

        hide: function() {
            if(this.$el) {
                this.$el.hide();
            }
        },

        setDependencyCollection: function(collection) {
            //this._validateCollectionType(collection);
            this.dependenciesMap = {"fakeEntity": collection};
        },

        setTooltipTitle: function(title) {
            this.model.set("tooltipTitle", title);
        },

        setTooltipFooterText: function(text) {
            this.model.set("tooltipFooterText", text);
        },

        showTooltip: function () {
            this._openBubble();
            this._killBubbleAutohide();
        },

        /**
         * Hide the tooltip using the auto-hide mechanism (to prevent flickering and allow hovering on the tooltip)
         */
        hideTooltip: function () {
            this._startBubbleAutohide();
        },

        // Refresh the tooltip after its content was changed
        refreshTooltip: function() {
            if(this._isBubbleOpened()) {
                this._openBubble();
            }
        },

        // -------------------------------------------------------------------------------------------------------------
        // EO Public methods
        // -------------------------------------------------------------------------------------------------------------

        // -------------------------------------------------------------------------------------------------------------
        // EO Private methods
        // -------------------------------------------------------------------------------------------------------------

        _validateOptions: function(options) {
            if(options.collection) {
                this._validateCollectionType(options.collection);
            }
        },

        _validateCollectionType: function(collection){
            if(!(collection instanceof DependencyCollection)) {
                this.logger.error("collection must be of type DependencyCollection", "DependencyIndicator:_validateOptions", collection);
                throw "DependencyIndicator: collection must be of type DependencyCollection";
            }
        },

        _openBubble: function() {
            var bubble;
            this._closeBubble(null, true);

            bubble = this.uiComponents.host$.createAndRender("dependenciesBubble", {
                type: Bubble,
                options: {
                    targetControl: this.container,
                    contentView: this._createDependenciesBubbleView(),
                    permutation: Bubble.PERMUTATION.C,
                    position: this.options.bubblePosition,
                    align: this.options.bubbleAlignment,
                    showCloseButton: false
                },
                events: {
                    "mouseenter": "_onMouseEnterBubble",
                    "mouseleave": "_onMouseLeaveBubble"
                }
            });
            this._fitToScreen(bubble);
        },

        _closeBubble: function(event, forceClose) {
            if(this.uiComponents.dependenciesBubble) {
                this.uiComponents.dependenciesBubble.close(forceClose);
            }
        },

        _isBubbleOpened: function() {
            return  !!this.uiComponents.dependenciesBubble;
        },

        _onMouseEnterBubble: function() {
            this._killBubbleAutohide();
        },

        _onMouseLeaveBubble: function() {
            this._startBubbleAutohide();
        },

        _startBubbleAutohide: function() {
            this._killBubbleAutohide();
            this.closeBubbleTimeOut = setTimeout(_.bind(this._closeBubble, this), this.options.tooltipAutohideTimeout);
        },

        _killBubbleAutohide: function () {
            if(this.closeBubbleTimeOut) {
                clearTimeout(this.closeBubbleTimeOut);
                this.closeBubbleTimeOut = null;
            }
        },

        _createDependenciesBubbleView : function() {
            this.tooltipView = new DependencyTooltipView({
                model: this.model,
                dependenciesMap: this.dependenciesMap,
            });
            this.listenTo(this.tooltipView , "itemview:clicked", this._onDependencyItemClicked);
            return this.tooltipView;
        },

        _onDependencyItemClicked: function(itemview, model) {
            this._closeBubble();
            this.trigger(DependencyIndicator.TRIGGERING.ITEM_CLICKED, model);
        },

        /**
         * Method for handling cases, if dependency indicator tooltip goes over the top or bottom of the screen
         * If it does, the top position of the bubble is recalculated to fit the screen top or bottom
         * @param bubbleView - Bubble view of dependency indicator
         * @private
         */
        _fitToScreen: function (bubbleView) {
            var bubbleContainer = bubbleView.$el[0];
            var bubbleWrapper = bubbleContainer.children[0];
            var bubbleWrapperSize = bubbleWrapper.getBoundingClientRect();
            var bodyBottom = document.body.getBoundingClientRect().bottom;
            var dependencyList = bubbleWrapper.querySelector('.dependency-types-list');
            var dependencyListSize = dependencyList.getBoundingClientRect();
            var delta;

            if (this.options.bubblePosition === DependencyIndicator.BubblePosition.BOTTOM) {
                if (bubbleWrapperSize.bottom > bodyBottom) {
                    delta = bubbleWrapperSize.bottom - bodyBottom;
                    dependencyList.style.maxHeight = Math.abs(dependencyListSize.height - delta - 20) + 'px';
                    dependencyList.style.minHeight = '30px';
                }
            }
            if (this.options.bubblePosition === DependencyIndicator.BubblePosition.TOP) {
                if (bubbleWrapperSize.top < 0) {
                    delta = Math.abs(bubbleWrapperSize.top);
                    var originalTop = parseInt(bubbleWrapper.style.top);
                    bubbleWrapper.style.top = (originalTop + delta) + 'px';
                    dependencyList.style.maxHeight = Math.abs(dependencyListSize.height - delta) + 'px';
                }
            }
        }
    });

    DependencyIndicator.Collection = DependencyCollection;
    DependencyIndicator.BubblePosition = Bubble.POSITION;
    DependencyIndicator.BubbleAlignment = Bubble.ALIGN;

    DependencyIndicator.TRIGGERING = {
        CHANGE: "change",
        ITEM_CLICKED: "item:clicked"
    };

    return DependencyIndicator;
});
