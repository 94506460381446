define(function (require) {
    "use strict";

    var LE = require("app");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var AppInstallModel = require("models/entities/AppInstallModel");
    var ApiResources = require("assets/data/apiResources.json");
  var _ = require('underscore');


    var NAME = "AppInstallsCollection";

    var AppInstallsCollection = RevisionedCollection.extend({
        model: AppInstallModel,
        resource: ApiResources.AccountConfig.AppInstall,
        name: NAME,

        getAppInstallModelBySoftwareId: function(softwareId){
            var result = [];
            this.each(_.bind(function(model){
                if (model.getSoftwareId() === softwareId){
                    result.push(model);
                }
            }, this));
            return result;
        }
    });

    AppInstallsCollection.NAME = NAME;

    return AppInstallsCollection;
});
