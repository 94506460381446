define(function (require) {
    "use strict";

    require("watchWindow");

    var $ = require("jquery");

    // Handle EverNote and possible other browser plugins which may try to override our version of jQuery in a violent way
    if (window.watch) {
        var watchHandler = function (id, oldValue, newValue) {
            if (newValue && oldValue !== newValue) {
                setTimeout(function () {
                    window.unwatch("_$");
                    $.noConflict(true);
                    window.watch("_$", watchHandler);
                }, 1);

                return newValue;
            }
        };

        window.watch("_$", watchHandler);
    }
});