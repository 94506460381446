/**
 * Created by tomers on 5/19/2015.
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");
    var ApiResources = require("assets/data/apiResources.json");
    var ErrorCodes = require("codeTables/errorCodes.json");
  var _ = require('underscore');

    var MODEL_VALIDATORS = Config.validators.user;
    var PASSWORD_FROM_SERVER = "*****";
    var MAX_CHATS_CONST = {
        NO_CHATS: "0",
        UNLIMITED: "Unlimited"
    };

    var KEYS = {
        PID: "pid",
        EMAIL_ADDRESS: "emailAddress",
        LOGIN_NAME: "loginName",
        NICK_NAME: "nickName",
        DISPLAY_NAME: "displayName",
        PERMISSION_GROUP: "permissionGroup",
        ENABLED: "enabled",
        MAX_NUMBER_OF_CHATS: "maxNumberOfChats",
        MAX_NUMBER_OF_CONVERSATIONS: "maxAsyncChats",
        SKILLS: "skills",
        OLD_PASSWORD: "oldPassword",
        PASSWORD: "password",
        MEMBER_OF: "memberOf",
        MANAGER_OF: "managerOf",
        LOBS: "lobIds"
    };

    var ERROR_CODES = {
        PASSWORD_RESTRICTIONS: "passwordRestrictions"
    };

    var UserModel = RevisionedModel.extend({
        resource: ApiResources.AppServer.Operators,
        MODEL_VALIDATORS: MODEL_VALIDATORS,
        MAX_CHATS_CONST: MAX_CHATS_CONST,
        KEYS: KEYS,
        ERROR_CODES: ERROR_CODES,
        defaults: {
            id: null,
            emailAddress: "",
            loginName: "",
            nickName: "",
            displayName: "",
            enabled: true,
            maxNumberOfChats: "4",
            skills: [],
            password: "",
            oldPassword: "",
            lobIds: []
        },
        name: "UserModelAS",
        useMemento: true,

        saveEnableValidation: {
            loginName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.loginName.required.new"
            },
            emailAddress: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.email.required"
            },
            nickName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.nickName.required"
            },
            displayName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.displayName.required"
            },
            password: {
                required: function() {
                    return this.isNew();
                },
                minLength: 1,
                msg: "LEFramework.entities.user.errors.password.length"
            }

        },

        validation: {
            emailAddress: {
                required: true,
                pattern: "email",
                msg: "LEFramework.entities.user.errors.email.invalid"
            },
            loginName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.loginName.required.new"
            },
            nickName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.nickName.required"
            },
            displayName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.displayName.required"
            },
            password: {
                required: function() {
                    return this.isNew();
                },
                minLength: Config.validators.user.minPasswordLength,
                msg: "LEFramework.entities.user.errors.password.length"
            },
            oldPassword: {
                fn: function(val) {
                    if (this._isMyUser && _.isEmpty(val) && !_.isEmpty(this.getPassword())) {
                        return "LEFramework.entities.user.errors.oldPassword.required";
                    }
                }
            }
        },

        initialize: function(options) {
            this._isMyUser = (_.has(options, "isMyUser")) ? options.isMyUser : false;
        },

        _setValuesInModel: function(attributes) {
            if (attributes.id) {
                this.set("id", attributes.id);
            }

            _.each(KEYS, _.bind(function(key) {
                if (_.has(attributes, key)) {
                    this.set(key, attributes[key]);
                }
            }, this));
        },

        getPid: function() {
            return this.get(KEYS.PID);
        },
        getLoginName: function() {
            return this.get(KEYS.LOGIN_NAME);
        },
        setLoginName: function(loginName, options) {
            this.set(KEYS.LOGIN_NAME, loginName, options);
        },
        getEmail: function() {
            return this.get(KEYS.EMAIL_ADDRESS);
        },
        setEmail: function(email, options) {
            this.set(KEYS.EMAIL_ADDRESS, email, options);
        },
        getNickName: function() {
            return this.get(KEYS.NICK_NAME);
        },
        setNickName: function(nickName, options) {
            this.set(KEYS.NICK_NAME, nickName, options);
        },
        getEmployeeId: function() {},

        setEmployeeId: function(employeeId, options) {},

        getDisplayName: function() {
            return this.get(KEYS.DISPLAY_NAME);
        },
        setDisplayName: function(displayName, options) {
            this.set(KEYS.DISPLAY_NAME, displayName, options);
        },
        getEnabled: function() {
            return this.get(KEYS.ENABLED);
        },
        setEnabled: function(enabled, options) {
            this.set(KEYS.ENABLED, enabled, options);
        },
        getPermissionGroup: function() {
            return this.get(KEYS.PERMISSION_GROUP);
        },
        getProfiles: function() {
            return this.get(KEYS.PERMISSION_GROUPS);
        },
        setPermissionGroup: function(permissionGroup, options) {
            this.set(KEYS.PERMISSION_GROUP, permissionGroup, options);
        },
        unsetPermissionGroup: function() {
            this.unset(KEYS.PERMISSION_GROUP);
        },
        getMaxNumberOfChats: function() {
            return this.get(KEYS.MAX_NUMBER_OF_CHATS);
        },
        setMaxNumberOfChats: function(maxNumberOfChats, options) {
            this.set(KEYS.MAX_NUMBER_OF_CHATS, maxNumberOfChats, options);
        },
        getMaxConversations: function() {},
        setMaxConversations: function(maxNumberOfConversations, options) {},
        getSkills: function() {
            return this.get(KEYS.SKILLS);
        },
        setSkills: function(skills, options) {
            this.set(KEYS.SKILLS, skills, options);
        },
        addSkill: function(skill) {
            var skills = this.getSkills();
            if (!_.include(skills, skill)) {
                skills.push(skill);
                this.setSkills(skills);
            }
        },
        getPassword: function() {
            return this.get(KEYS.PASSWORD);
        },
        setPassword: function(password, options) {
            this.set(KEYS.PASSWORD, password, options);
        },
        getOldPassword: function() {
            return this.get(KEYS.OLD_PASSWORD);
        },
        setOldPassword: function(oldPassword, options) {
            this.set(KEYS.OLD_PASSWORD, oldPassword, options);
        },
        getMemberOf: function() {
            return null;
        },
        getManagerOf: function() {
            return null;
        },
        setManagerOf: function(groups){
        },
        setMemberOfGroup: function(options) {
        },
        getGroups: function() {
            return [];
        },
        setPictureUrl: function() {
        },
        getPictureUrl: function() {
        },
        setPictureUrlValidated: function() {
        },
        getPictureUrlValidated: function() {
        },
        setPictureUrlErrors: function(){
        },
        getPictureUrlErrors: function(){
        },
        getChangePasswordNextLogin: function () {
        },
        setChangePasswordNextLogin: function () {
        },
        getLobs: function() {
            return this.get(KEYS.LOBS);
        },
        setLobs: function(lobIds) {
            return this.set(KEYS.LOBS, lobIds);
        },
        /**
         * When you want to save the current logged in user (my user) you have to declare it in the options when you init the model {isMyUser:true}
         * In addition to handling the validation for this specific case (oldPassword), when we want to save the current logged in user (my user) we cannot send the "enabled" attribute. so we unset it before the save and reset it after
         * TODO: remove this "hack" of unsetting the "enabled" after USERS api move to AC
         * @param key
         * @param val
         * @param options
         * @returns {Session}
         */
        save: function(attributes, options) {
            options = options ? _.clone(options) : {};
            var origError = options.error;
            options.error = _.bind(function(collection, resp, opt) {
                var errorObject = {};
                var errorMessage = LE.translator.translate("LEFramework.something.went.wrong");

                if (resp && resp.responseError && resp.responseError.error) {
                    if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.wrongCredentials)) {
                        errorObject[KEYS.OLD_PASSWORD] = LE.translator.translate("LEFramework.entities.user.errors.oldPassword.wrongCredentials");
                    }

                    if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.loginNameExists.replace("{0}", this.getLoginName()))) {
                        errorObject[KEYS.LOGIN_NAME] = LE.translator.translate("LEFramework.entities.user.errors.loginName.duplicated.new");
                    }

                    if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.displayNameExists.replace("{0}", this.getDisplayName()))) {
                        errorObject[KEYS.DISPLAY_NAME] = LE.translator.translate("LEFramework.entities.user.errors.displayName.duplicated");
                    }

                    if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.createPasswordPolicyRestrictions)) {
                        errorObject[ERROR_CODES.PASSWORD_RESTRICTIONS] = LE.translator.translate("LEFramework.entities.user.errors.password.passwordRestrictions");
                    }

                    if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.updatePasswordPolicyRestrictions)) {
                        errorObject[ERROR_CODES.PASSWORD_RESTRICTIONS] = LE.translator.translate("LEFramework.entities.user.errors.password.passwordRestrictions");
                    }
                }

                if(!_.isEmpty(errorObject)) {
                    options.errorObject = errorObject;
                }
                options.errorMessage = errorMessage;

                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            var enabled = this.getEnabled();
            if (this._isMyUser) { // when we save our user - we cannot send the enabled attribute
                this.unset(KEYS.ENABLED, {silent:true});
            }
            var saveResult = RevisionedModel.prototype.save.call(this, attributes, options);
            this.setEnabled(enabled, {silent:true});

            return saveResult;
        },

        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },

        parse: function(response, options) {
            if (response && response.password === PASSWORD_FROM_SERVER) {
                response.password = "";
            }

            return RevisionedModel.prototype.parse.call(this, response, options);
        },

        setAgentStatus: function(status) {
            this.isAgent = status;
        },

        getAgentStatus: function() {
            return this.isAgent;
        },

        getErrorCodes: function() {
            return this.ERROR_CODES;
        },

        hasRoleType: function(permissionGroupsCollection, roleType) {
            var permissionGroupModel = permissionGroupsCollection.where({name: roleType});
            var permissionGroupId = parseInt(permissionGroupModel.id, 10);
            var hasRoleType = _.include(this.getPermissionGroup(), permissionGroupId);

            return hasRoleType;
        }
    });

    return UserModel;
});

