/**
 * Created by tomers on 5/19/2015.
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");
    var LEResourceResolver = require("leResourceResolver");
    var ErrorCodes = require("codeTables/errorCodes.json");
  var _ = require('underscore');

    var MODEL_VALIDATORS = Config.validators.user;
    var MAX_CHATS_CONST = {
        NO_CHATS: "0",
        UNLIMITED: "Unlimited"
    };

    var KEYS = {
        ID: "id",
        PID: "pid",
        DELETED: "deleted",
        EMAIL_ADDRESS: "email",
        OBJECT_NAME: "objectName",
        LOGIN_NAME: "loginName",
        NICK_NAME: "nickname",
        DISPLAY_NAME: "fullName",
        EMPLOYEE_ID: "employeeId",
        PERMISSION_GROUPS: "permissionGroups",
        ENABLED: "isEnabled",
        RESET_MFA: "resetMfaSecret",
        MAX_NUMBER_OF_CHATS: "maxChats",
        MAX_NUMBER_OF_CONVERSATIONS: "maxAsyncChats",
        SKILLS: "skillIds",
        PASSWORD: "passwordSh",
        OLD_PASSWORD: "oldPassword",
        CHANGE_PASSWORD_NEXT_LOGIN: "changePwdNextLogin",
        MEMBER_OF: "memberOf",
        MANAGER_OF: "managerOf",
        PICTURE_URL: "pictureUrl",
        INVALID_PICTURE_URL: "invalidUrl",
        UNSUPPORTED_PICTURE_FORMAT: "unsupportedPictureFormat",
        LOBS: "lobIds"
    };

    var ERROR_CODES = {
        PASSWORD_RESTRICTIONS: "passwordRestrictions"
    };

    var UserModelAC = RevisionedModel.extend({
        resource: LEResourceResolver.apiResources.AccountConfig.Users,
        MODEL_VALIDATORS: MODEL_VALIDATORS,
        MAX_CHATS_CONST: MAX_CHATS_CONST,
        KEYS: KEYS,
        ERROR_CODES: ERROR_CODES,
        defaults: {
            id: null,
            pid: null,
            deleted: false,
            loginName: "",
            email: "",
            fullName: "",
            employeeId: "",
            nickname: "",
            isEnabled: true,
            maxChats: 4,
            maxAsyncChats: null,
            isActive: true,
            skillIds: [],
            permissionGroups: [],
            memberOf: null,
            managerOf: [],
            pictureUrl: null,
            lobIds: [],
            changePwdNextLogin: false
        },
        name: "UserModelAC",
        useMemento: true,

        saveEnableValidation: {
            loginName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.loginName.required.new"
            },
            email: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.email.required"
            },
            nickname: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.nickName.required"
            },
            fullName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.displayName.required"
            },
            passwordSh: {
                required: function() {
                    return this.isNew();
                },
                minLength: 1,
                msg: "LEFramework.entities.user.errors.password.length"
            },
            memberOf: {
                fn: function(val) {
                    if (_.isEmpty(val) && this.getAgentStatus()) {
                        return "LEFramework.entities.user.errors.assignToGroup.required";
                    }
                }
            }
        },

        validation: {
            email: {
                required: true,
                pattern: "email",
                msg: "LEFramework.entities.user.errors.email.invalid"
            },
            loginName: {
                required: true,
                pattern: "alphaNumericSpecialCharacters",
                minLength: 1,
                msg: "LEFramework.entities.user.errors.loginName.invalid.new"
            },
            nickname: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.nickName.required"
            },
            fullName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.displayName.required"
            },
            passwordSh: {
                required: function() {
                    return this.isNew();
                },
                minLength: Config.validators.user.minPasswordLength,
                msg: "LEFramework.entities.user.errors.password.length"
            },
            oldPassword: {
                fn: function(val) {
                    if (this._isMyUser && _.isEmpty(val) && !_.isEmpty(this.getPassword())) {
                        return "LEFramework.entities.user.errors.oldPassword.required";
                    }
                }
            },
            memberOf: {
                fn: function(val) {
                    if (_.isEmpty(val) && this.getAgentStatus()) {
                        return "LEFramework.entities.user.errors.assignToGroup.required";
                    }
                }
            }
        },

        initialize: function(options) {
            //this._isMyUser = (_.has(options, "isMyUser")) ? options.isMyUser : false;
        },

        _setValuesInModel: function(attributes) {
            if (attributes.id) {
                this.set("id", attributes.id);
            }

            _.each(KEYS, _.bind(function(key) {
                if (_.has(attributes, key)) {
                    this.set(key, attributes[key]);
                }
            }, this));
        },

        getPid: function() {
            return this.get(KEYS.PID);
        },

        getLoginName: function() {
            return this.get(KEYS.LOGIN_NAME);
        },
        setLoginName: function(loginName, options) {
            this.set(KEYS.LOGIN_NAME, loginName, options);
        },
        getEmail: function() {
            return this.get(KEYS.EMAIL_ADDRESS);
        },
        setEmail: function(email, options) {
            this.set(KEYS.EMAIL_ADDRESS, email, options);
        },
        getNickName: function() {
            return this.get(KEYS.NICK_NAME);
        },
        setNickName: function(nickName, options) {
            this.set(KEYS.NICK_NAME, nickName, options);
        },
        getDisplayName: function() {
            return this.get(KEYS.DISPLAY_NAME);
        },
        setDisplayName: function(displayName, options) {
            this.set(KEYS.DISPLAY_NAME, displayName, options);
        },
        getEmployeeId: function() {
            return this.get(KEYS.EMPLOYEE_ID);
        },
        setEmployeeId: function(employeeId, options) {
            this.set(KEYS.EMPLOYEE_ID, employeeId, options);
        },
        setMemberOfGroup: function(groupId) { //the API expects a null object if the user isn't a member of any group
            var val= groupId ? {agentGroupId: groupId} : null;
            this.set(KEYS.MEMBER_OF, val);
        },
        getMemberOf: function() {
            return this.get(KEYS.MEMBER_OF);
        },
        getMemberOfGroup: function() {
            if (this.getMemberOf() && this.getMemberOf().agentGroupId){
                return this.getMemberOf().agentGroupId;
            }
        },
        getManagerOf: function() {
            return this.get(KEYS.MANAGER_OF);
        },
        setManagerOf: function(options) {
            this.set(KEYS.MANAGER_OF, options);
        },
        getEnabled: function() {
            return this.get(KEYS.ENABLED);
        },
        setEnabled: function(enabled, options) {
            this.set(KEYS.ENABLED, enabled, options);
        },
        getResetMFA: function() {
            return this.get(KEYS.RESET_MFA);
        },
        setResetMFA: function(resetMFA, options) {
            this.set(KEYS.RESET_MFA, resetMFA, options);
        },
        setPictureUrl: function(url) {
            this.set(KEYS.PICTURE_URL, url);
        },
        getPictureUrl: function() {
           return this.get(KEYS.PICTURE_URL);
        },
        setPictureUrlValidated: function(value) {
            this.pictureUrlValidated = value;
        },
        getPictureUrlValidated: function() {
            return this.pictureUrlValidated;
        },
        setPictureUrlErrors: function(value){
            this.pictureUrlErrors = value;
        },
        getPictureUrlErrors: function(){
            return this.pictureUrlErrors;
        },
        getPermissionGroup: function() {
            var permissionGroups = this.get(KEYS.PERMISSION_GROUPS);
            if(permissionGroups && permissionGroups.length === 1) {
                return permissionGroups[0];
            }
            return permissionGroups;
        },
        setPermissionGroup: function(permissionGroup, options) {
            var pg = [];
            pg.push(permissionGroup);
            this.set(KEYS.PERMISSION_GROUPS, pg, options);
        },
        getProfiles: function() {
            return this.get(KEYS.PERMISSION_GROUPS);
        },
        unsetPermissionGroup: function() {
            this.unset(KEYS.PERMISSION_GROUPS);
        },
        getMaxNumberOfChats: function() {
            var maxChats = this.get(KEYS.MAX_NUMBER_OF_CHATS);
            if (maxChats === -1) {
                maxChats = MAX_CHATS_CONST.UNLIMITED;
            }
            return maxChats.toString();
        },
        setMaxNumberOfChats: function(maxNumberOfChats, options) {
            var maxChats = parseInt(maxNumberOfChats,10);
            if (isNaN(maxChats)) {
                maxChats = -1;
            }
            this.set(KEYS.MAX_NUMBER_OF_CHATS, maxChats, options);
        },
        getMaxConversations: function() {
            return this.get(KEYS.MAX_NUMBER_OF_CONVERSATIONS);
        },
        setMaxConversations: function(maxNumberOfConversations, options) {
            var maxConversations = parseInt(maxNumberOfConversations,10);
            if (isNaN(maxConversations)) {
                maxConversations = null;
            }
            this.set(KEYS.MAX_NUMBER_OF_CONVERSATIONS, maxConversations, options);
        },
        getMemberAssignmentDate: function() {
            if (this.get(KEYS.MEMBER_OF)) {
                return this.get(KEYS.MEMBER_OF).assignmentDate;
            } else {
                return null;
            }

        },
        getSkills: function() {
            return this.get(KEYS.SKILLS);
        },
        setSkills: function(skills, options) {
            this.set(KEYS.SKILLS, skills, options);
        },
        addSkill: function(skill) {
            var skills = this.getSkills();
            if (!_.include(skills, skill)) {
                skills.push(skill);
                this.setSkills(skills);
            }
        },
        getLobs: function() {
            return this.get(KEYS.LOBS);
        },
        setLobs: function(lobIds) {
            return this.set(KEYS.LOBS, lobIds);
        },
        getPassword: function() {
            return this.get(KEYS.PASSWORD);
        },
        setPassword: function(password, options) {
            if (password && !_.isEmpty(password)) {
                this.set(KEYS.PASSWORD, password, options);
            } else {
                this.unset(KEYS.PASSWORD);
            }
        },
        getOldPassword: function() {
            return this.get(KEYS.OLD_PASSWORD);
        },
        setOldPassword: function(oldPassword, options) {
            this.set(KEYS.OLD_PASSWORD, oldPassword, options);
        },
        getChangePasswordNextLogin: function() {
            return this.get(KEYS.CHANGE_PASSWORD_NEXT_LOGIN);
        },
        setChangePasswordNextLogin: function(changePwdNextLogin) {
            this.set(KEYS.CHANGE_PASSWORD_NEXT_LOGIN, changePwdNextLogin);
        },
        getGroups: function(agentGroupsCollection) {
            this._buildGroupsObj(agentGroupsCollection);
            return this.groupsObj.groups;
        },
        _buildGroupsObj: function(agentGroupsCollection) {
            this.groupsObj = this.groupsObj || {userRevision: -1, agentGroupsRevision: -1, groups:[]};
            if (this.getRevision() > this.groupsObj.userRevision || agentGroupsCollection.getRevision() > this.groupsObj.agentGroupsRevision) {
                this.groupsObj.userRevision = this.getRevision();
                this.groupsObj.groups = [];
                var managerGroups = this.getManagerOf() || [];
                var memberOf = this.getMemberOf() || {};
                for (var ii=0; ii<managerGroups.length; ii++){
                    if (managerGroups[ii].agentGroupId && agentGroupsCollection.get(managerGroups[ii].agentGroupId)) {
                        this.groupsObj.groups.push({"name": agentGroupsCollection.get(managerGroups[ii].agentGroupId).getName()});
                    }
                    if (managerGroups[ii].agentGroupId === memberOf.agentGroupId) {
                        memberOf = {};
                    }
                }
                if(memberOf.agentGroupId && agentGroupsCollection.get(memberOf.agentGroupId)) {
                    this.groupsObj.groups.push({"name": agentGroupsCollection.get(memberOf.agentGroupId).getName()});
                }

                this.groupsObj.groups.sort(function(a, b){
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1:-1;
                });
            }
        },

        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },

        save: function(attributes, options) {
            options = options ? _.clone(options) : {};

            var origError = options.error;
            options.error = _.bind(function(collection, resp, opt) {
                var errorObject = {};
                var errorMessage = LE.translator.translate("LEFramework.something.went.wrong");

                if (resp && resp.responseError && resp.responseError.internalCode && resp.responseError.fields) {

                    var field = resp.responseError.fields[0] ;

                    if (resp.responseError.internalCode === ErrorCodes.uniquenessViolation) {
                        if (field === KEYS.LOGIN_NAME || field === KEYS.OBJECT_NAME) {
                            errorObject[KEYS.LOGIN_NAME] = LE.translator.translate("LEFramework.entities.user.errors.loginName.duplicated.new");
                        } else if (field === KEYS.EMAIL_ADDRESS) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.email.duplicated");
                        } else if (field === KEYS.DISPLAY_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.displayName.duplicated");
                        } else {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.uniqueness.violation");
                        }
                    }

                    if (resp.responseError.internalCode === ErrorCodes.fieldInvalidLengthOrPattern) {
                        if (field === KEYS.LOGIN_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.loginName.invalid.new");
                        } else if (field === KEYS.EMAIL_ADDRESS) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.email.invalid");
                        } else if (field === KEYS.PASSWORD) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.length");
                        } else {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.invalidLengthOrPattern");
                        }
                    }

                    if (resp.responseError.internalCode === ErrorCodes.fieldIsMandatory) {
                        if (field === KEYS.LOGIN_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.loginName.required.new");
                        } else if (field === KEYS.EMAIL_ADDRESS) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.email.required");
                        } else if (field === KEYS.NICK_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.nickName.required");
                        }  else if (field === KEYS.DISPLAY_NAME) {
                                errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.displayName.required");
                        } else {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.mandatoryField");
                        }
                    }

                    if (resp.responseError.internalCode === ErrorCodes.invalidRangeValue) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.invalidRangeValue");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMismatch) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.oldPassword.wrongCredentials");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMissingAlphaCharacters) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.missingAlphaCharacters");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.maximumNumberOfSequentialCharactersInPassword) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.maximumNumberOfSequentialCharacters");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.tooShortPassword) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.tooShort");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMissingDigits) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.missingDigits");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.maximumNumberOfCharacterOccurrencesInPassword) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.maximumNumberOfCharacterOccurrences");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMissingSpecialCharacters) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.missingSpecialCharacters");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordIncludesUsername) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.includesUsername");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.restrictedPasswordPhrase) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.restrictedPasswordPhrase");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordResetTooManyTimesToday) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.passwordResetTooManyTimesToday");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordRepeatsPreviouslyUsedPasswords) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.repeatsPreviouslyUsedPasswords");
                    }


//                    if (resp.responseError.internalCode === ErrorCodes.wrongCredentials) {
//                        errorObject[KEYS.OLD_PASSWORD] = LE.translator.translate("LEFramework.entities.user.errors.oldPassword.wrongCredentials");
//                    }
//                    if (resp.responseError.internalCode === ErrorCodes.appServer.messages.loginNameExists.replace("{0}", model.getLoginName())) {//??
//                        errorObject[KEYS.LOGIN_NAME] = LE.translator.translate("LEFramework.entities.user.errors.loginName.duplicated.new");
//                    }
//                    if (resp.responseError.internalCode === ErrorCodes.appServer.messages.displayNameExists.replace("{0}", model.getDisplayName())) { //????
//                        errorObject[KEYS.DISPLAY_NAME] = LE.translator.translate("LEFramework.entities.user.errors.displayName.duplicated");
//                    }
                }
                if(!_.isEmpty(errorObject)) {
                    options.errorObject = errorObject;
                }
                options.errorMessage = errorMessage;

                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            RevisionedModel.prototype.save.call(this, attributes, options);
        },

        setAgentStatus: function(status) {
            this.isAgent = status;
        },

        getAgentStatus: function() {
            return this.isAgent;
        },

        getErrorCodes: function() {
            return this.ERROR_CODES;
        },

        hasRoleType: function(permissionGroupsCollection, roleType) {
            var permissionGroupModel = permissionGroupsCollection.where({name: roleType});
            var permissionGroupId = parseInt(permissionGroupModel.id, 10);
            var hasRoleType = _.include(this.getPermissionGroup(), permissionGroupId);

            return hasRoleType;
        }
    });

    UserModelAC.KEYS = KEYS;

    return UserModelAC;
});

