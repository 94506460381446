/**
 * Created by tomers on 4/25/2016.
 */
define(function (require) {
    "use strict";

    var lobSettings = (function () {

        var hasLobSettingEnabled = function () {
            var lobSetting = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.LOB_ENABLED);
            return (lobSetting === "true");
        };


        return {
            hasLobSettingEnabled: hasLobSettingEnabled
        };

    })();

    return lobSettings;
});