<template>
  <div
    :aria-expanded="isNavBarOpen && openCategoryIndex === index && isCategoryOpen"
    :title="isNavBarOpen ? undefined : tooltipText"
    :tooltip-text="isNavBarOpen ? undefined : tooltipText"
    tooltip-position="right"
    :role="isNavBarOpen ? 'button' : ''"
    :tabindex="isNavBarOpen ? 0 : -1"
    :aria-labelledby="
      `${value.languageKey}-id ${value.languageKey}-number-of-conversations-id`
    "
    :data-lp-at="`${value.languageKey}`"
    :class="{
      'nav-list__item': true,
      'nav-list__item-selected': selectedCategoryIndex === index,
      'nav-list__item-open': openCategoryIndex === index && isCategoryOpen
    }"
    @keydown.enter.space="
      onSwitchItem({
        routeConfig: value.pinnedRoute,
        subCategoryIndex: value,
        index,
        sectionIndex: null
      })
    "
    @click="
      onSwitchItem({
        routeConfig: value.pinnedRoute,
        subCategoryIndex: value,
        index,
        sectionIndex: null
      })
    "
  >
    <nav-bar-category-icon
      :icon="value.icon"
      :selectedCategoryIndex="selectedCategoryIndex"
      :index="index"
    />
    <nav-bar-title :index="index" :languageKey="value.languageKey" />
    <notification-bubble
      v-show="
        (!isNavBarOpen &&
          value.pinnedRoute.notification &&
          value.pinnedRoute.notification.count > 0) ||
          (value.pinnedRoute.notification &&
            value.pinnedRoute.notification.count > 0 &&
            (openCategoryIndex !== index ||
              (openCategoryIndex === index && !isCategoryOpen)))
      "
      :notification="value.pinnedRoute.notification"
      :customId="`${value.languageKey}-number-of-conversations-id`"
      :inline="isNavBarOpen"
      :is-nav-bar-open="isNavBarOpen"
    />
  </div>
</template>
<script>
import NavBarCategoryIcon from './NavBarCategoryIcon.vue';
import NotificationBubble from '../NotificationBubble.vue';
import NavBarCategoryLabel from './NavBarCategoryLabel.vue';
import NavBarTitle from './NavBarTitle.vue';
import { Logger } from 'vue-infra';

const logger = Logger.getLogger('LiveEngage:PersonaSwitcher');

export default {
  name: 'NavBarPinnedApp',
  components: {
    NavBarTitle,
    NavBarCategoryLabel,
    NotificationBubble,
    NavBarCategoryIcon
  },
  props: {
    value: Object,
    isNavBarOpen: Boolean,
    name: String,
    isCategoryOpen: Boolean,
    index: Number,
    selectedCategoryIndex: Number,
    openCategoryIndex: Number
  },
  data() {
    return {
      tooltipText: this.$translator.translate(
        'LEFramework.' + this.value.languageKey
      )
    };
  },
  methods: {
    onSwitchItem(data) {
      this.$emit('requestSwitch', {
        routeConfig: data.routeConfig,
        subCategoryIndex: data.subCategoryIndex,
        index: data.index,
        sectionIndex: data.sectionIndex
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/navigation_bar';

</style>
