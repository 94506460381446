define(function (require) {
    "use strict";

    var BaseGalleryItemView = require("ui/gallery/js/views/GalleryItemView");
    var AppKeysGalleryItem = require("ui.components/gallery/templates/appKeysGalleryItem.tmpl");

    var GalleryItemView = BaseGalleryItemView.extend({

        initialize:function(options) {
            this.appKeysCollection = options.originalCollection;
            this.ui.operatingSystemIcon = ".operating-system";
            this.ui.missingCertificateIcon = ".missing-certificate";
            BaseGalleryItemView.prototype.initialize.call(this, options);
            if (!(this.model.isTypeNew() || this.model.isTypeLink())) {
                this.appKeysModel = this.appKeysCollection.getModelByApplicationKey(this.model.getKey());
                this.template = AppKeysGalleryItem;
            }
        },

        onRender: function(){
            if (!this.model.isTypeNew()){
                var hasMissingFile = this.appKeysModel.isIos() && this.appKeysModel.hasMissingFile();
                this.ui.missingCertificateIcon.toggleClass("show", hasMissingFile);
                if (hasMissingFile){
                    this.ui.missingCertificateIcon.prop("title", LE.translator.translate('LEManager.manager.dataSources.appKey.gallery.missing.certificates.tooltip'));
                }

                this.ui.operatingSystemIcon.prop("title", this._getOperatingSystemTooltip());
                this.ui.operatingSystemIcon.addClass(this.appKeysModel.getOperatingSystemName());
            }
            BaseGalleryItemView.prototype.onRender.call(this);
        },
        _getOperatingSystemTooltip: function(){
            return LE.translator.translate('LEManager.manager.dataSources.appKey.gallery.operating.system.' + this.appKeysModel.getOperatingSystemName() + '.tooltip');
        }

    });

    return GalleryItemView;
});
