define(function (require) {
    "use strict";

    require("ui.plugins/jquery.ba-outside-events");
    require("ui.plugins/jquery.inputCursor");
    require("ui.plugins/jquery.ellipsis");
    require("ui.plugins/jquery.viewport");

    var Button = require("ui/button/Button");
    var InputField = require("ui/input-field/InputField");
    var Dropdown = require("ui/dropdown/Dropdown");
    var Loader = require("ui/loader/Loader");
    var Bubble = require("ui/bubble/Bubble");
    var Link = require("ui/link/Link");
    var StepIndicator = require("ui/stepIndicator/StepIndicator");
    var DependencyIndicator = require("ui.components/dependency-indicator/DependencyIndicator");
    var KPContent = require("ui.components/kpContent/kpContent");
    var TabsBar = require("ui/tabsbar/TabsBar");
    var Page = require("ui.components/page/Page");
    var Checkbox = require("ui/checkbox/Checkbox");
    var Tooltip = require("ui/tooltip/Tooltip");

    var DependencyService = require("src/components/dependency-service/controllers/DependencyService");

    return {
        ui:{
            Button: Button,
            InputField: InputField,
            Dropdown: Dropdown,
            Loader: Loader,
            Bubble: Bubble,
            Link: Link,
            DependencyIndicator: DependencyIndicator,
            KPContent: KPContent,
            TabsBar: TabsBar,
            Page: Page,
            Checkbox: Checkbox,
            Tooltip: Tooltip
        },
        data: {
            services: {
                DependencyService: DependencyService
            }
        }
    };
});