/**
 * Created by tomers on 3/1/2015.
 */
define(function (require) {
    "use strict";

    var agentStatusSettings = (function () {
        var currentDefaultAgentStatus = null;

        var getAgentStatusCollection = function() {
            return LE.userBarMenu.statusCollection;
        };

        var getCurrentDefaultAgentStatus = function() {
            if (currentDefaultAgentStatus === null) {
                currentDefaultAgentStatus = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.DEFAULT_AGENT_STATUS);
            }
            return currentDefaultAgentStatus;
        };

        var getCurrentDefaultAgentStatusObject = function(currentDefaultStatusFromFetch) {
            var currentDefaultStatus = currentDefaultStatusFromFetch || this.getCurrentDefaultAgentStatus();
            return getAgentStatusCollection().findWhere({value: currentDefaultStatus});
        };

        var switchDefaultAgentStatus = function(status) {
            currentDefaultAgentStatus = status;
            LE.vent.trigger("siteSettings:defaultAgentStatusSwitched", currentDefaultAgentStatus);
        };

        /**
         * This account setting is responsible for shutting off Sentiment for agents, to make it less confusing for them.
         * If MCS_AGENT_VIEW is 'false' in the account settings, it means that the required behavior is to HIDE all Sentiment
         * for agent-level users only.
         * Otherwise leave the Sentiment on for all roles/profiles to see.
         * @returns {boolean}
         */
        var getMcsAgentViewIndication = function() {
            var mcs_agent_view = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.MCS_AGENT_VIEW);
            return (mcs_agent_view !== "false");
        };

        return {
            getAgentStatusCollection: getAgentStatusCollection,
            getCurrentDefaultAgentStatus: getCurrentDefaultAgentStatus,
            getCurrentDefaultAgentStatusObject: getCurrentDefaultAgentStatusObject,
            switchDefaultAgentStatus: switchDefaultAgentStatus,
            getMcsAgentViewIndication: getMcsAgentViewIndication
        };
    })();

    return agentStatusSettings;
});
