import BaseAppender from './BaseAppender';
import { environmentActionTypes, storeModuleTypes, store } from 'vue-infra';


const { ENVIRONMENT } = storeModuleTypes;
const { PERSONA_NOTIFICATION_UPDATE } = environmentActionTypes;

const defaultOptions = {
  toolTip: '',
};


class PersonaNotification extends BaseAppender {

  constructor() {
    super();

    this.store = store;
  }

  getDefaultOptions() {
    return Object.freeze(defaultOptions);
  }

  /**
   * @param options
   * @param options.num
   * @param options.toolTip
   * @param options.persona
   * @param options.showTooltipOnNotification
   */
  notify(options) {
    if (!options.num || isNaN(options.num) || Number(options.num) === 0) {
      this._resetNotification(options);
    } else {
      this.store.dispatch(`${ENVIRONMENT}/${PERSONA_NOTIFICATION_UPDATE}`, {
        persona: options.persona,
        count: options.num,
        tooltip: options.toolTip,
        showTooltipOnNotification: options.showTooltipOnNotification === undefined || options.showTooltipOnNotification === null ? true : options.showTooltipOnNotification
      });
    }
  }

  isValidOptions(options) {
    const isValid = super.isValidOptions(options);
    if (isValid) {
      if (!options.persona) {
        return `'persona' is required`;
      }
    }
    return isValid;
  }

  clearNotification(options) {
    return true;
  }

  _resetNotification(options) {
    this.store.dispatch(`${ENVIRONMENT}/${PERSONA_NOTIFICATION_UPDATE}`, {
      persona: options.persona,
      count: 0,
    });
  }

}


export default PersonaNotification;
