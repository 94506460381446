/**
 * User: danielda
 */

define(function (require) {
    "use strict";

    var { Logger } = require('vue-infra');
    var MigrationController = require("src/components/migration-activation/controllers/MigrationController");

    var MigrationActivationSource = function (options) {
        var logger = Logger.getLogger("CoreComponents");
        if (!options.application) {
            logger.error("application(LE) for MigrationActivationSource is mandatory");
            return null;
        }

        var el;
        if (options && options.el) {
            el = options.el;
        }
        var migrationController = new MigrationController({
            application: options.application,
            el : el
        });

        LE.vent.on("migration:upgradeButtonClick", migrationController.onActivate);
        return migrationController;
    };

    return MigrationActivationSource;
});
