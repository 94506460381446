define(function (require) {
	"use strict";

	var _ = require("underscore");
	var $ = require("jquery");

	//============================================================
	// CollectionSorter
	//============================================================

	var CollectionSorter = function () {

        var comparator, orderBy ="asc";

        var sort = function(collection, options){

            setOptions(options, collection);

            if(!_.isUndefined(comparator)){
                var originalComparator =  collection.comparator;

                setComparator(collection, options);
                collection.sort({silent: true});

                collection.comparator = originalComparator;
            }
        };

        //--------------------------------------------------------

        var setOptions = function(options, collection){

            options = options || {};

            if(_.has(options, 'comparator')){
                comparator = options.comparator;
            }
            else{
                comparator = collection.comparator;
            }

            if(_.has(options, 'orderBy')){
                orderBy = options.orderBy;
            }
            else if (_.has(collection, "sortOrder")){
                orderBy = _.result(collection, "sortOrder");
            }
        };

        //----------------------------------------------------------

		var setComparator = function (collection, options) {

            var compareBy = null;

            if(_.isFunction(comparator)){
                compareBy = comparator;
            }else if(_.isString(comparator)){
                compareBy = sortBy(comparator);
            }

            if(!_.isNull(compareBy)){
                if(orderBy === 'asc'){
                    collection.comparator = compareBy;
                }else{
                    collection.comparator = reverseSortBy(compareBy);
                }
            }else{
                throw "OrganizeCollectionDecorator:CollectionSorter:setComparator comparator not valid";
            }
		};

        //--------------------------------------------------------

		var sortBy = function (field) {

			return function (model) {
				if (!model.has(field)) {
					throw "Model has no attribute '" + field + "' in OrganizeCollectionDecorator:CollectionSorter:sortBy";
				}

				var val = model.get(field).valueOf();

				if (_.isString(val)) {
					val = val.toLowerCase();
				}
				return val;
			};
		};

        //--------------------------------------------------------

		var reverseSortBy = function (sortByFunction) {

			return function (left, right) {
				var l = sortByFunction.call(this,left);
				var r = sortByFunction.call(this,right);

				if (typeof l === 'undefined') {
					return -1;
				}
				if (typeof r === 'undefined') {
					return 1;
				}

				return l < r ? 1 : l > r ? -1 : 0;
			};
		};

        //----------------------------------------------------------

        return{
           sort:sort
        };
	};

	return CollectionSorter;
});
