<template>
  <div
    v-if="isVisible"
    class="notification-widget"
  >
    <le-bubble v-bind="popoverOptions">
      <template #target>
        <div
          class="topBarWidgetIcon topBarNotification-trigger-icon"
          :class="toggleIconClass"
          tabindex="0"
          role="button"
          @click="togglePopover"
          @keydown.enter.space.prevent="togglePopover"
        >
        </div>
      </template>
      <template #content>
        <div
          tabindex="0"
          role="button"
          @click="handleTopBarNotificationClick"
          @keydown.enter.space.prevent="handleTopBarNotificationClick"
        >
          <i
            :class="messageIconClass"
            class="topBarNotification-message-icon"
          ></i>
          <span>{{ topBarNotification.text }}</span>
        </div>
      </template>
    </le-bubble>
  </div>
</template>

<script>
    import CONST from 'const';
    import { CONSTS, leBubble } from '@liveperson/lp-vue-components';
    import { environmentGetterTypes, storeModuleTypes, Vuex } from 'vue-infra';

    const componentName = 'top-bar-notification';
    const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
      topBarNotification: environmentGetterTypes.TOP_BAR_NOTIFICATION,
    });

    // todo - have the UI team create these icons as glyphs
    const iconMap = {
      [CONST.TOP_BAR_NOTIFICATION_TYPE.INFO]: {
        name: 'info',
        color: 'white',
      },
      [CONST.TOP_BAR_NOTIFICATION_TYPE.SUCCESS]: {
        name: 'success',
        color: 'green',
      },
      [CONST.TOP_BAR_NOTIFICATION_TYPE.ERROR]: {
        name: 'error',
        color: 'red',
      },
      [CONST.TOP_BAR_NOTIFICATION_TYPE.PROGRESS]: {
        name: 'progress',
        color: 'white',
      },
    };

    export default {
      name: componentName,
      components: {
        leBubble,
      },
      data() {
        return {
          popoverOptions: {
            html: true,
            shown: false,
            autoHide: false,
            triggers: ['click'],
            container: '.user-bar-region',
            theme: CONSTS.LE_BUBBLE.THEMES.DARK,
          },
        };
      },
      computed: {
        isVisible() {
          return !!this.topBarNotification;
        },

        toggleIconClass() {
          return this.popoverOptions.shown ? 'topBarNotification-trigger-icon-active' : '';
        },

        messageIconClass() {
          const icon = this.getMessageIcon(this.topBarNotification.type);
          // fixme - we return only the icon name for now. this is used with the old icons (from a sprite), ditch this when we switch to glyph icons.
          return icon.name;
          // fixme - use this impl' when icons are available as glyphs, and we can annotate them with 'ico-*' classnames
          // return `ico-32 ico-${icon.name} ico-${icon.color}`;
        },

        topBarNotification: getters.topBarNotification,
      },
      watch: {
        topBarNotification(newNotification) {
          if (newNotification) {
            this.togglePopover(true);
          }
        },
      },
      methods: {
        togglePopover(state) {
          if (typeof state === 'undefined') {
            state = !this.popoverOptions.shown;
          }
          this.popoverOptions.shown = state;
          if (this.popoverOptions.shown) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(function() {
              this.popoverOptions.shown = false;
            }.bind(this), 5000);
          }
        },

        getMessageIcon(notificationType) {
          let icon;
          if (notificationType in iconMap) {
            icon = iconMap[notificationType];
          } else {
            icon = iconMap[CONST.TOP_BAR_NOTIFICATION_TYPE.INFO];
          }
          return icon;
        },
        handleTopBarNotificationClick() {
          if (this.topBarNotification && typeof this.topBarNotification.onClick === 'function') {
            this.topBarNotification.onClick();
          }
        },
      },
    };
</script>

<style lang="scss">
  @import "../../../assets/scss/_mixins";
  @import "../../../node_modules/marionette-ui-components/assets/scss/mixins";



  .le {
    .notification-widget {
      margin-right: 15px;
    }
    .user-bar-top-level-widgets {
      > div {
        .topBarWidgetIcon {
          &.topBarNotification-trigger-icon {
            width: 16px;
            height: 16px;
            margin-top: 1px;
            @include lp-bg-image("assets/img/importing-on-header.svg");
          }
        }
      }
    }

    .topBarNotification-message-icon {
      float: left;
      margin: 0 10px 10px 0;


      /* fixme - the following rules fit the old icons (from a sprite), ditch them when switching to glyph icons */

      width: 22px;
      height: 22px;

      &.success {
        @include lp-comp-bg-image("22x22-sprite.png", -200px, 0);
      }

      &.error {
        @include lp-comp-bg-image("22x22-sprite.png", -120px, 0);
      }

      &.progress {
        @include lp-comp-bg-image("loader_on_gray100_22.gif");
      }
    }
  }
</style>
