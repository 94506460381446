define(function (require) {
    "use strict";

    var Translator = require("i18n/translator");

    var BaseModel = require("models/BaseModel");
    var { Logger } = require('vue-infra');
    var GeneralUtilities = require("infra-util/GeneralUtilities");
    var Marionette = require("marionette");
    var MigrationWelcomeView  = require("src/components/migration-activation/views/MigrationWelcomeView");
    var PermissionGroupConvertionModel  = require("src/components/migration-activation/models/PermissionGroupConvertionModel");
    var ProvisionModel  = require("src/components/migration-activation/models/ProvisionModel");
    var DefaultCampaignModel = require("models/DefaultCampaignModel");
    var AccountCreationMigrationModel = require("src/components/migration-activation/models/AccountCreationMigrationModel");
    var Loader = require("ui/loader/Loader");
    var that;

    var MigrationController = Marionette.Controller.extend({

        retryAfter : 5000,
        timeoutRetries : 30000,

        initialize: function (options) {
            that = this;
            this.LE = options.application;
            this.logger = Logger.getLogger("CoreComponents");
            this.provisionModel = new ProvisionModel();
            this.permissionGroupConvertionModel = new PermissionGroupConvertionModel();

            if(options.el) {
                var view = this.getMigrationView();
                view.render();
            }
        },

        onLogin: function() {
            callAccountCreationCreateDefaultsResource();
        },


        onActivate: function(isCPI){
            var flow= isCPI ? AccountCreationMigrationModel.CONST.FLOW_CPI : AccountCreationMigrationModel.CONST.FLOW_SEAT_BASED;
            callAccountCreationActivation(null, null, flow);
        },

        getMigrationView:function () {
            this.migrationView = new MigrationWelcomeView({model: new BaseModel(), application: this.LE});
            return this.migrationView;
        }
    });

    function callAccountCreationCreateDefaultsResource() {
        var accountCreationMigrationModel = new AccountCreationMigrationModel();
        var options = {
            headers : {
                'Content-Type': 'application/json',
                'X-HTTP-Method-Override':'PUT'
            },
            success : function (model, response, xhr) {
                LE.logger.info("call Migration Step 1 success");
            },
            error : function (model, errors) {
                LE.logger.error("call Migration Step 1 failed, calling defaultCampaign");
            }
        };

        accountCreationMigrationModel.save({'step': AccountCreationMigrationModel.CONST.ON_LOGIN}, options);
    }

    function callAccountCreationActivation(_startTime, _accountCreationMigrationModel, flow) {
        that.logger.info("start migration");
        showLoader();
        var accountCreationMigrationModel = _accountCreationMigrationModel || new AccountCreationMigrationModel();
        var startTime =  _startTime || new Date().getTime();
        var timeNow =  new Date().getTime();

        var options = {
            headers : {
                'Content-Type': 'application/json',
                'X-HTTP-Method-Override':'PUT'
            },
            success: function (model, response, xhr) {
                that.logger.info("MigrationController >> callAccountCreationActivation >> success >> START");
                //call api's
                convertPermissionGroup();
                that.logger.info("MigrationController >> callAccountCreationActivation >> success >> END");
            },
            error: function (model, errors) {
                that.logger.error("MigrationController >> callAccountCreationActivation >> error >> START");
                try {
                    if (startTime + that.timeoutRetries > timeNow) {
                        //try again
                        setTimeout(function () {
                            callAccountCreationActivation(startTime, accountCreationMigrationModel, flow);
                        }, that.retryAfter);
                    } else {
                        // incase gave up retrying on migration api
                        that.logger.error("MigrationController >> callAccountCreationActivation >> error >> timeout, calling provision resource instead");
                        that.logger.graph({name:"framework:MigrationController:error"});

                        //Deprecated api call
                        //callProvisionResource();

                        hideLoader();
                        that.LE.notifier.showError(that.LE.translator.translate("LEFramework.upgrade.failed.try.again"));
                    }
                } catch(ex){
                    that.logger.error("MigrationController >> callAccountCreationActivation >> error >> ex="+ex);
                    that.logger.graph({name:"framework:MigrationController:error"});
                    hideLoader();
                    that.LE.notifier.showError(that.LE.translator.translate("LEFramework.upgrade.failed.try.again"));
                }
            }
        };

        accountCreationMigrationModel.save({'step': AccountCreationMigrationModel.CONST.ON_ACTIVATION, 'flow': flow}, options);
    }

    function callProvisionResource(_startTime) {
        that.logger.info("start migration");
        showLoader();
        var startTime =  _startTime || new Date().getTime();
        var timeNow =  new Date().getTime();

        var options = {
            headers : {
                'Content-Type': 'application/json',
                'X-HTTP-Method-Override':'PUT'
            },
            success: function (model, response, xhr) {
                that.logger.info("MigrationController >> callProvisionResource >> success >> START");
                //call api's
                convertPermissionGroup();
                that.logger.info("MigrationController >> callProvisionResource >> success >> END");
            },
            error: function (model, errors) {
                that.logger.error("MigrationController >> callProvisionResource >> error >> START");
                try {
                    if (startTime + that.timeoutRetries > timeNow) {
                        //try again
                        setTimeout(function () {
                            callProvisionResource(startTime);
                        }, that.retryAfter);
                    } else {
                        that.LE.notifier.showError(that.LE.translator.translate("LEFramework.upgrade.failed.try.again"));
                        that.logger.error("MigrationController >> callProvisionResource >> error >> END");
                        that.logger.graph({name:"framework:MigrationController:error"});
                        hideLoader();
                    }
                } catch(ex){
                    that.logger.error("MigrationController >> callProvisionResource >> error >> ex="+ex);
                    that.logger.graph({name:"framework:MigrationController:error"});
                    hideLoader();
                }
            }
        };

        that.provisionModel.save(null, options);
    }

    function convertPermissionGroup(_startTime) {

        var startTime =  _startTime || new Date().getTime();
        var timeNow =  new Date().getTime();

        var options = {
            headers : {
                'Content-Type': 'application/json'
            },
            success: function (model, response, xhr) {
                that.logger.info("MigrationController >> startMigration >> success >> START");
                LE.isSilentLeave = true;
                window.location = GeneralUtilities.addParameter(window.location.href, "migrationDone" , "true");
                that.logger.info("MigrationController >> startMigration >> success >> END");
            },
            error: function (model, errors) {
                that.logger.error("MigrationController >> startMigration >> error >> START");
                try {
                    if (startTime + that.timeoutRetries > timeNow) {
                        //try again
                        setTimeout(function () {
                            convertPermissionGroup(startTime);
                        }, that.retryAfter);
                    } else {
                        hideLoader();
                        that.LE.notifier.showError(that.LE.translator.translate("LEFramework.upgrade.permissions.failed.try.again"));
                        that.logger.error("MigrationController >> startMigration >> error >> END");
                        that.logger.graph({name:"framework:MigrationController:error"});
                    }
                } catch(ex){
                    that.logger.error("MigrationController >> startMigration >> error >> ex="+ex);
                    that.logger.graph({name:"framework:MigrationController:error"});
                    hideLoader();
                }
            }
        };

        that.permissionGroupConvertionModel.save(null, options);
    }

    function showLoader(){
        if (that.loader) {
            that.loader.close();
        }else{
            that.loader = new Loader({
                permutation: Loader.PERMUTATION.A,
                el: '.root',
                zIndex: 9999,
                isLEWrapRequired: true
            });
        }
        that.loader.render();
    }

    function hideLoader(){
        if (that.loader){
            that.loader.close();
        }
    }

    return MigrationController;
});

