/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 3/16/13
 * Time: 16:07 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var { Logger } = require('vue-infra');
    var VisitorCountModel = require("models/visitorCountModel");

    var rtDataManager = (function () {


        var initialize = function (options) {

            if (this.initialized) {
                return rtDataManager;
            }

            options = options || {};

            this.logger = Logger.getLogger("LEFramework");

            this.initialized = true;

            return rtDataManager;
        };


        var visitorCount = (function () {
            var visitorCountModel = null;

            var getVisitorCount = function () {
                var model = getVisitorCountModel();
                return model.get("visitorCount");
            };
            var getWasVisited = function () {
                var model = getVisitorCountModel();
                return model.get("wasSiteVisited");
            };
            var fetch = function (options) {
                if (options && options.success) {
                    var model = getVisitorCountModel();
                    model.fetchRtVisitorCount(options);
                }
            };

            var getVisitorCountModel = function () {
                if (null === visitorCountModel) {
                    visitorCountModel = new VisitorCountModel();
                }
                return visitorCountModel;
            };

            return {
                getVisitorCount: getVisitorCount,
                getWasVisited: getWasVisited,
                fetch: fetch
            };

        })();

        return {
            initialize: initialize,
            visitorCount:visitorCount
        };
    })();

    return rtDataManager;
});
