define(function (require) {
    "use strict";

    var _ = require("underscore");
    var { sessionManager } = require("vue-infra");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var ApiResources = require("assets/data/apiResources.json");
    var ProfileModel = require("models/entities/ProfileModel");

    var URL_SELECT_PARAMETER = {
        ALL: "$all",
        PARTIAL: [ProfileModel.KEYS.ID, ProfileModel.KEYS.NAME, ProfileModel.KEYS.DESCRIPTION, ProfileModel.KEYS.ROLE_TYPE_ID, ProfileModel.KEYS.IS_ASSIGNED_TO_LPA]
    };

    var NAME = "ProfilesCollection";

    var ProfilesCollection = RevisionedCollection.extend({
        ROLES: ProfileModel.ROLES,
        model: ProfileModel,
        name: NAME,
        resource: ApiResources.AccountConfig.Profiles,

        initialize: function (models, options) {
            options = options || {};
            this.source = options.source;

            RevisionedCollection.prototype.initialize.call(this, models, options);
            this.urlSelectParameter = options.urlSelectParameter || URL_SELECT_PARAMETER.PARTIAL;
            if (_.isArray(this.urlSelectParameter)) {
                this.urlSelectParameter = this.urlSelectParameter.join(",");
            }
        },

        url: function(){ //we need to override backbone to not include '/' and the temp id we give to the model before deleting
            var url = RevisionedCollection.prototype.url.apply(this, arguments);
            url = url + "&select=" + this.urlSelectParameter;
            return url;
        },

        fetch: function (options) {
            options = options ? _.clone(options) : {};
            var origSuccess = options.success;
            var origError = options.error;

            options.success = _.bind(function (collection, resp, opt) {
                if (origSuccess) {
                    origSuccess(collection, resp, options);
                }
            }, this);

            RevisionedCollection.prototype.fetch.call(this, options);
        },

        fetchSuccess: function(collection, response, options) {
            if (options && options.xhr && options.xhr.status !== 304) {
                this._buildProfilesRolesMap();
            }
        },

        _buildProfilesRolesMap: function() {
            this.profilesRolesMap = {};
            this.each(_.bind(function(profileModel) {
                var profileRoleType = profileModel.getRoleTypeId();
                this.profilesRolesMap[profileRoleType] = this.profilesRolesMap[profileRoleType] || [];
                this.profilesRolesMap[profileRoleType].push(profileModel.id);
            }, this));
        },

        getProfilesByRole: function() {
            return this.profilesRolesMap;
        },

        isCollectionAllowed: function() {
            return sessionManager.hasProfilesFeature();
        },

        checkIfLpaAssigned: function(){
            var modelFound = _.find(this.models, function(model){
                return model.getIsAssignedToLPA();
            });
            return !_.isUndefined(modelFound);
        }
    });

    ProfilesCollection.URL_SELECT_PARAMETER = URL_SELECT_PARAMETER;
    ProfilesCollection.NAME = NAME;

    return ProfilesCollection;
});

