define(function (require) {
  "use strict";



  var RevisionedModel = require("models/RevisionedModel");
  var ApiResources = require("assets/data/apiResources.json");
  var Config = require("assets/config/entities/config.json");
  var SoftwareModel = require("models/entities/SoftwareModel");
  var _ = require('underscore');

  var KEYS = {
    CLIENT_ID: "client_id",
    CLIENT_NAME: "client_name",
    ENABLED: "enabled",
    SOFTWARE_STATEMENT: "software_statement",
    LOGO_URI: "logo_uri",
    CAPABILITIES: "capabilities",
    CAPABILITIES_ENGAGEMENT: "capabilities.engagement",
    CAPABILITIES_BROADCAST: "capabilities.broadcast",
    DESCRIPTION: "description",
    ENTRY_POINT: "capabilities.engagement.entry_point",
    OFFER_TEXT_SMS: "offerTextSMS",
    TWILIO_OUTBOUND_NUMBER: "twilioOutboundNumber",
    OFFER_WHATSAPP: "offerWhatsApp",
    WHATSAPP_TEMPLATE_NAME: "whatsappTemplateName",
    WHATSAPP_TEMPLATE_NAMESPACE: "whatsappTemplateNamespace",
    WHATSAPP_OUTBOUND_NUMBER: "whatsappOutboundNumber",
    CHANNEL_PRIORITY_SMS: "channelPrioritySms",
    CHANNEL_PRIORITY_WA: "channelPriorityWa",
    SMS_ENABLED: "smsEnabled",
    WHATSAPP_ENABLED: "waEnabled"
  };

  var LOGO_URI_EXTENSIONS = ["PNG", "GIF", "JPEG", "JPG"];

  var LOGO_PREVIEW_STATES = {
    NONE: "none",
    LOADING: "loading",
    LOADED: "loaded",
    ERROR: "error"
  };

  var SPECIAL_SOURCES_IDS = {
    WEB: "web",
    OFFSITE: "offsite",
    EXTERNAL: "external",
    IVR_APP: "Smart Connect"
  };

  var IvrModel = RevisionedModel.extend({
    idAttribute: "client_id",
    resource: ApiResources.AccountConfig.IvrInstall,
    KEYS: KEYS,
    name: "IvrModel",
    defaults: {
      client_id: null,
      client_name: "Smart Connect",
      enabled: true,
      logo_uri: window.leImageBaseUrl + "src/modules/campaigns/assets/img/software/IVR.png",
      description: "",
      capabilities: {},
      settings: {}
    },
    logoUriState: LOGO_PREVIEW_STATES.NONE,
    softwareLogoUri: "",

    validation: {
      client_name: [
        {
          required: false,
          minLength: 1,
          msg: "LEFramework.entities.appInstall.errors.name.required"
        },
        {
          maxLength: Config.validators.appInstall.maxNameLength,
          msg: "LEFramework.entities.appInstall.errors.name.maxLength"
        }
      ],
      description: [
        {
          required: false,
          minLength: 0,
          maxLength: Config.validators.appInstall.maxDescriptionLength,
          msg: "LEFramework.entities.appInstall.errors.description.maxLength"
        }
      ]
    },


    getChannelPrioritySms: function() {
      return this.get(KEYS.CHANNEL_PRIORITY_SMS);
    },
    setChannelPrioritySms: function(value) {
      this.set(KEYS.CHANNEL_PRIORITY_SMS, value);
    },
    getChannelPriorityWa: function() {
      return this.get(KEYS.CHANNEL_PRIORITY_WA);
    },
    setChannelPriorityWa: function(value) {
      this.set(KEYS.CHANNEL_PRIORITY_WA, value);
    },
    getOfferTextSMS: function() {
      return this.get(KEYS.OFFER_TEXT_SMS);
    },
    setOfferTextSMS: function(value) {
      this.set(KEYS.OFFER_TEXT_SMS, value);
    },
    getTwilioOutboundNumber: function() {
      return this.get(KEYS.TWILIO_OUTBOUND_NUMBER);
    },
    setTwilioOutboundNumber: function(value) {
      this.set(KEYS.TWILIO_OUTBOUND_NUMBER, value);
    },
    getOfferWhatsApp: function() {
      return this.get(KEYS.OFFER_WHATSAPP);
    },
    setOfferWhatsApp: function(value) {
      this.set(KEYS.OFFER_WHATSAPP, value);
    },
    getWhatsAppTemplateName: function() {
      return this.get(KEYS.WHATSAPP_TEMPLATE_NAME);
    },
    setWhatsAppTemplateName: function(value) {
      this.set(KEYS.WHATSAPP_TEMPLATE_NAME, value);
    },
    getWhatsAppTemplateNamespace: function() {
      return this.get(KEYS.WHATSAPP_TEMPLATE_NAMESPACE);
    },
    setWhatsAppTemplateNamespace: function(value) {
      this.set(KEYS.WHATSAPP_TEMPLATE_NAMESPACE, value);
    },
    getWhatsAppOutboundNumber: function() {
      return this.get(KEYS.WHATSAPP_OUTBOUND_NUMBER);
    },
    setWhatsAppOutboundNumber: function(value) {
      this.set(KEYS.WHATSAPP_OUTBOUND_NUMBER, value);
    },

    getAppInstallId: function(){
      return this.get(KEYS.CLIENT_ID);
    },

    getLogoUri: function(){
      return this.get(KEYS.LOGO_URI);
    },

    getCapabilities: function(){
      return this.get(KEYS.CAPABILITIES);
    },

    getEngagementCapabilities: function() {
      return this.get(KEYS.CAPABILITIES_ENGAGEMENT);
    },

    getBroadcastCapabilities: function() {
      return this.get(KEYS.CAPABILITIES_BROADCAST);
    },

    getName: function(){
      return this.get(KEYS.CLIENT_NAME);
    },

    setLogoUriState: function(state){
      this.logoUriState = state;
    },
    getLogoUriState: function() {
      return this.logoUriState;
    },

    getDescription: function(){
      return this.get(KEYS.DESCRIPTION);
    },

    getEnabled: function(){
      return this.get(KEYS.ENABLED);
    },

    setIvrId: function(id){
      return this.set(KEYS.CLIENT_ID, id);
    },

    setEnabled: function(enabled){
      this.set(KEYS.ENABLED, enabled);
    },

    setEngagementCapabilities: function(data) {
      this.set(KEYS.CAPABILITIES_ENGAGEMENT, data);
    },

    setLogoUri: function(logoUri){
      this.set(KEYS.LOGO_URI, logoUri);
    },

    setDescription: function(description){
      this.set(KEYS.DESCRIPTION, description);
    },

    setName: function(name){
      this.set(KEYS.CLIENT_NAME, name);
    },

    isSpecial: function(){
      var appInstallId = this.getAppInstallId();
      return  _.include(_.values(SPECIAL_SOURCES_IDS), appInstallId);
    },


    setSMSEnabled: function(value) {
      this.set(KEYS.SMS_ENABLED, value);
    },

    getSMSEnabled: function() {
      return this.get(KEYS.SMS_ENABLED);
    },

    setWAEnabled: function(value) {
      this.set(KEYS.WHATSAPP_ENABLED, value);
    },

    getWAEnabled: function() {
      this.get(KEYS.WHATSAPP_ENABLED);
    },

    getEntryPoint: function(){
      return this.get(KEYS.ENTRY_POINT);
    },
    isPrivateAppInstall: function(){
      return _.isUndefined(this.getSoftwareStatement());
    },
    getSoftwareModel: function() {
      if (_.isUndefined(this.softwareModel) && !this.isPrivateAppInstall()){
        this.softwareModel = new SoftwareModel({software_statement: this.getSoftwareStatement()}, {parse: true});
      }
      return this.softwareModel;
    },

    save: function(attributes, options) {
      this.set("settings",{
        connectors_priority: [{name: "sms", priority: parseInt(attributes[KEYS.CHANNEL_PRIORITY_SMS],10)|| 1}, {name: "wa", priority: parseInt(attributes[KEYS.CHANNEL_PRIORITY_WA],10) || 1}],
        connectors_settings:[
          {name: "sms", enabled: this.get(KEYS.SMS_ENABLED) || false, data: {[KEYS.OFFER_TEXT_SMS]: this.getOfferTextSMS(), [KEYS.TWILIO_OUTBOUND_NUMBER]: this.getTwilioOutboundNumber()}},
          {name: "wa", enabled: this.get(KEYS.WHATSAPP_ENABLED) || false, data:{[KEYS.OFFER_WHATSAPP]: this.getOfferWhatsApp(), [KEYS.WHATSAPP_TEMPLATE_NAME]: this.getWhatsAppTemplateName(), [KEYS.WHATSAPP_TEMPLATE_NAMESPACE]: this.getWhatsAppTemplateNamespace(), [KEYS.WHATSAPP_OUTBOUND_NUMBER]: this.getWhatsAppOutboundNumber()}}]});
      this.unset(KEYS.OFFER_TEXT_SMS, {silent:true});
      this.unset(KEYS.TWILIO_OUTBOUND_NUMBER, {silent: true});
      this.unset(KEYS.SMS_ENABLED, {silent: true});
      this.unset(KEYS.WHATSAPP_ENABLED, {silent: true});
      this.unset(KEYS.OFFER_WHATSAPP, {silent: true});
      this.unset(KEYS.WHATSAPP_TEMPLATE_NAME, {silent:true});
      this.unset(KEYS.WHATSAPP_TEMPLATE_NAMESPACE, {silent:true});
      this.unset(KEYS.WHATSAPP_OUTBOUND_NUMBER, {silent: true});
      this.unset(KEYS.CHANNEL_PRIORITY_WA, {silent:true});
      this.unset(KEYS.CHANNEL_PRIORITY_SMS, {silent:true});
      RevisionedModel.prototype.save.call(this, attributes, options);
    },

    parse: function(resp, options) {
      if(resp && resp.settings.connectors_settings[0] && !_.isEmpty(resp.settings.connectors_settings[0].data)) {
        this.setOfferTextSMS(resp.settings.connectors_settings[0].data[KEYS.OFFER_TEXT_SMS]);
        this.setTwilioOutboundNumber(resp.settings.connectors_settings[0].data[KEYS.TWILIO_OUTBOUND_NUMBER]);
      }
      if(resp && resp.settings.connectors_settings[1] && !_.isEmpty(resp.settings.connectors_settings[1].data)) {
        this.setOfferWhatsApp(resp.settings.connectors_settings[1].data[KEYS.OFFER_WHATSAPP]);
        this.setWhatsAppTemplateName(resp.settings.connectors_settings[1].data[KEYS.WHATSAPP_TEMPLATE_NAME]);
        this.setWhatsAppTemplateNamespace(resp.settings.connectors_settings[1].data[KEYS.WHATSAPP_TEMPLATE_NAMESPACE]);
        this.setWhatsAppOutboundNumber(resp.settings.connectors_settings[1].data[KEYS.WHATSAPP_OUTBOUND_NUMBER]);
      }
      if(resp && resp.settings.connectors_settings[0] && !_.isEmpty(resp.settings.connectors_settings[0].data) && !_.isEmpty(resp.settings.connectors_settings[1]).data) {
        var smsPriority = resp.settings.connectors_priority[0].priority;
        var waPriority = resp.settings.connectors_priority[1].priority;
        this.setChannelPrioritySms(smsPriority.toString());
        this.setChannelPriorityWa(waPriority.toString());
      }
      return RevisionedModel.prototype.parse.call(this, resp, options);
    },
    });

  IvrModel.KEYS = KEYS;
  IvrModel.SPECIAL_SOURCES_IDS = SPECIAL_SOURCES_IDS;
  IvrModel.LOGO_PREVIEW_STATES = LOGO_PREVIEW_STATES;

  return IvrModel;
});
