
define(function (require) {
    "use strict";

    var Marionette = require("marionette");
    var LE = require("app");
    var Backbone = require("backbone");

    function noDataModelDefinition(LEUIComponents, LE, Backbone, Marionette, $, _, model) {

        var defaults = {
            errorMessage: LE.translator.translate("LEUIComponents.errors.no.data.404")
        };

        function initialize(options) {
            /*jshint validthis:true */
            this.errorMessage = options.errorMessage || this.defaults.errorMessage;
        }

        model.extend(Backbone.Model.extend({
            defaults: defaults,
            initialize: initialize
        }));
    }

    return LE.moduleAddDefinition("LEUIComponents", noDataModelDefinition);
});
