/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/25/15
 * Time: 5:21 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

  var _ = require('underscore');
    /*
     visibility types:
     none/0 - CP is visible to all
     1 - CP is visible to everyone, chat tab is not visible to agents
     2- CP is not visible to all
     */

    var connectionPanelVisibilitySettings = (function () {

        var currentVisibility;
        var viewCaseVisibility;
        var openCaseVisibility;
        var notificationCenterVisibility;

        var getCurrentVisibility = function () {
            if (_.isUndefined(currentVisibility)) {
                currentVisibility = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CONNECTION_PANEL_VISIBILITY, null);
            }
            return currentVisibility;
        };

        var getViewCaseVisibility = function () {
            if (_.isUndefined(viewCaseVisibility)) {
                viewCaseVisibility = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CP_VIEW_CASE_VISIBILITY, "admin");
            }
            return viewCaseVisibility;
        };
        var getOpenCaseVisibility = function () {
            if (_.isUndefined(openCaseVisibility)) {
                openCaseVisibility = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CP_OPEN_CASE_VISIBILITY, "admin");
            }
            return openCaseVisibility;
        };

        var getNotificationCenterVisibility = function () {
            if (_.isUndefined(notificationCenterVisibility)) {
                notificationCenterVisibility = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CP_NOTIFICATION_CENTER_VISIBILITY, "none");
            }
            return notificationCenterVisibility;
        };


        return {
            getCurrentVisibility: getCurrentVisibility,
            getViewCaseVisibility: getViewCaseVisibility,
            getOpenCaseVisibility: getOpenCaseVisibility,
            getNotificationCenterVisibility: getNotificationCenterVisibility
        };
    })();

    return connectionPanelVisibilitySettings;
});

