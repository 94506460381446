<template>
  <div
    class="ftue-button-container"
    :class="{ 'ftue-button-container-large': button.isLarge }"
    tabindex="0"
    role="button"
    @click="handleClick"
    @keydown.enter.space.prevent="handleClick"
  >
    <div
      class="ftue-button"
      :class="{
        'ftue-button-large': button.isLarge,
        'ftue-button-enabled': !hasClicked
      }"
      tabindex="0"
      role="button"
      @click="handleClick"
      @keydown.enter.space.prevent="handleClick"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirstTimeButton',
  props: {
    button: { type: Object, default: () => { }, },
    hasClicked: { type: Boolean, default: false }
  },
  emits: ['clicked'],
  data() {
    return {
      text: this.button.text ? this.$translator.translate(this.button.text) : ''
    };
  },
  methods: {
    handleClick: function() {
      if (!this.hasClicked) {
        this.$emit('clicked', this.button);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ftue-button-container {
  width: 50%;
  padding-right: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  display: flex;
}
.ftue-button-container-large {
  width: 100%;
}
.ftue-button {
  @include themify {
    color: getColor('ftue-button-disabled', 'framework');
    border-color: getColor('ftue-button-disabled', 'framework');
  }
  flex: 1;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 16px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.01em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ftue-button-enabled {
  cursor: pointer;
  @include themify {
    color: getColor('ftue-button-color', 'framework');
    border-color: getColor('ftue-button-color', 'framework');
  }
}
.ftue-button-enabled:hover {
  @include themify {
    color: getColor('ftue-button-text', 'framework');
    background-color: getColor('ftue-button-hover', 'framework');
  }
}
</style>
