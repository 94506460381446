/* globals console: true */

/**
 * an le module-loader, adapted for webpack
 */
define(function (require) {
    'use strict';

    var registerModule  = require('modules/registerModule');
    var ModuleManager = require('src/moduleManager');
    var external_api = require('src/external_api');
    var { getApplications, loadBackboneModuleDictionary } = require('src/apps-loader');

    window.external_api = external_api;

    var CONFIG_FLAG = 'config';
    var I18N_FLAG = 'i18n';


    function lowerFirst(input) {
        if (input && 0 < input.length) {
            input = input.charAt(0).toLowerCase() + input.substr(1);
        }

        return input;
    }

    function toDash(input) {
        input = lowerFirst(input);

        // First check if the string does not contain dashes already
        if (input.indexOf('-') !== -1) {
            return input.replace(/ /g, '-');
        }

        return input.replace(/([A-Z])/g, function (first) {
            // Our conventions states that File names are camelCased unless it holds class definitions and therefore are PascalCased
            // But Folder names should be low-casing with dashes-between-words
            // This will parse any camelCased string to produce low-casing-with-dashes
            return '-' + first.toLowerCase();
        }).replace(/ /g, '-');
    }

    function publishComponents(LE, registerExtendedComponentLevel) {
        if (registerExtendedComponentLevel && registerExtendedComponentLevel.Button) {
            LE.comps = registerExtendedComponentLevel;
        }
    }

    function getPackLazyLoader(moduleDir) {
        switch (moduleDir) {
            case 'campaigns':
              const campaignsBackboneApp = getApplications()['lp-le-campaigns'];
              if (campaignsBackboneApp !== undefined) {
                return new Promise(async (resolve) =>  {
                  console.log("Used external campaigns module");
                  await loadBackboneModuleDictionary(campaignsBackboneApp);
                  return campaignsBackboneApp.moduleInit(resolve);
                });
              } else {
                console.log("Used internal campaigns module");
                return new Promise((resolve) =>  {
                  return require.ensure(['pack.campaigns'], function (require) {
                    var campaigns = require('pack.campaigns');
                    resolve();
                  });
                });
              }
              /* falls through */
            case 'connection':
                return new Promise((resolve) => {
                    return require.ensure(['pack.connection'], function (require) {
                        var connection = require('pack.connection');
                      resolve();
                    });
                });
            case 'content':
              // this is a temporary fallback for the external module
              // if the external module is not found it will revert to the internal module
              const contentBackboneApp = getApplications()['lp-le-content'];
              if (contentBackboneApp !== undefined) {
                return new Promise(async (resolve) =>  {
                  console.log("Used external content module");
                  await loadBackboneModuleDictionary(contentBackboneApp);
                  return contentBackboneApp.moduleInit(resolve);
                });
              } else {
                console.log("Used internal content module");
                return new Promise((resolve) =>  {
                  return require.ensure(['pack.content'], function (require) {
                    var data = require('pack.content');
                    resolve();
                  });
                });
              }
              /* falls through */
            case 'data-module':
                return new Promise((resolve) =>  {
                    return require.ensure(['pack.data-module'], function (require) {
                        var data = require('pack.data-module');
                        resolve();
                    });
                });
            case 'manager':
              // this is a temporary fallback for the external module
              // if the external module is not found it will revert to the internal module
              const managerBackboneApp = getApplications()['lp-le-manager'];
              if (managerBackboneApp !== undefined) {
                return new Promise(async (resolve) =>  {
                  console.log("Used external manager module");
                  await loadBackboneModuleDictionary(managerBackboneApp);
                  return managerBackboneApp.moduleInit(resolve);
                });
              } else {
                console.log("Used internal manager module");
                return new Promise((resolve) =>  {
                  return require.ensure(['pack.manager'], function (require) {
                    var data = require('pack.manager');
                    resolve();
                  });
                });
              }
              /* falls through */
            case 'personalization':
                return new Promise((resolve) => {
                    return require.ensure(['pack.personalization'], function (require) {
                        var data = require('pack.personalization');
                      resolve();
                    });
                });

            case 'reporting':
                return new Promise((resolve) => {
                    return require.ensure(['pack.reporting'], function (require) {
                        var data = require('pack.reporting');
                      resolve();
                    });
                });
            case 'user-management':
                return new Promise(async (resolve) =>  {
                  const usersBackboneApp = getApplications()['lp-le-user-management'];
                    await loadBackboneModuleDictionary(usersBackboneApp);
                    return usersBackboneApp.moduleInit(resolve);
                });
            case 'web-agent':
                return new Promise(async (resolve) => {
                  const webAgentApp = getApplications()['lp-le-web-agent'];
                  await loadBackboneModuleDictionary(webAgentApp);
                  return webAgentApp.moduleInit(resolve);
                });
        }
    }

    function load(onload) {
        var moduleConfig;
        var level = 0;
        var highestLevel = 0;
        var lazyLoaders = [];
        var internalModules = registerModule.resolveInternalModules();

        // Iterate over the array of modules knowing their structure and load only their names
        for (var index in internalModules) {
            if (!index || !internalModules.hasOwnProperty(index)) {
                continue;
            }

            moduleConfig = internalModules[index];

          // NOTE: This is only relevant after the module manager is initialized (during the application initialization)
          //       Only after initialization, modules can be loaded (this is currently only relevant to moduleLoader!load and not to config, test
          // TODO: please note that now we are loading all dictionaries of all the modules, the reason is because currently the dictionary of the 'LERegistration' is using from another modules,
          // even this module sometimes is not loaded(in case you already log-in)
          if (name.indexOf(I18N_FLAG) === -1 && ModuleManager.isInitialized() && !ModuleManager.hasAtLeastOneOfPersona(moduleConfig.personas)) {
            // If the module manager is initialized and does not 'allow' this module to load than skip it
            continue;
          }

          if (typeof (moduleConfig.folderName) === 'undefined' || typeof (moduleConfig.folderName) === 'function') {
            continue;
          }

            var folder = toDash(moduleConfig.folderName);
            var loader = getPackLazyLoader(folder);
            lazyLoaders.push(loader);

            if (!isNaN(moduleConfig.coreComponentsSupportLevel)) {
                level = parseInt(moduleConfig.coreComponentsSupportLevel, 10);

                if (0 < level && highestLevel < level) {
                    highestLevel = level;
                }
            }
        }

        if (0 < highestLevel) {
            switch (highestLevel) {
                case 1:
                    lazyLoaders.splice(0, 0, new Promise(function (done) {
                        require.ensure(['coreComponentsSupportLevel1'], function (require) {
                            var registerExtendedComponentLevel = require('coreComponentsSupportLevel1');
                            // todo - DRY up: extract this to the promise-chain resolved callback
                            var LE = require('app');
                            publishComponents(LE, registerExtendedComponentLevel);
                            done();
                        });
                    }));
                    break;
                case 2:
                    lazyLoaders.splice(0, 0, new Promise(function (done) {
                        require.ensure(['coreComponentsSupportLevel2'], function (require) {
                            var registerExtendedComponentLevel = require('coreComponentsSupportLevel2');
                            // todo - DRY up: extract this to the promise-chain resolved callback
                            var LE = require('app');
                            publishComponents(LE, registerExtendedComponentLevel);
                            done();
                        });
                    }));
                    break;
            }
        }


        // load eager modules
        require('pack.data-module');

        Promise.all(lazyLoaders).then(() => {
            onload(/* todo - send something */);
        });
    }

    return {
        load: load
    };

});
