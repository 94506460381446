define(function (require) {
    "use strict";

    var LE = require("app");
    var _ = require("underscore");
    var LocaleResolver = require('i18n/localeResolver');
    var BaseModel = require("models/BaseModel");
    var JwtUtility = require("utils/JwtUtility");

    var KEYS = {
        SOFTWARE_ID: "software_details.software_id",
        SOFTWARE_STATEMENT: "software_statement",
        INFORMATION_DETAILS: "information_details",
        VIDEO_URL: "information_details.video_url",
        LONG_DESCRIPTION: "information_details.long_description",
        INSTRUCTIONS_TYPE: "information_details.instructions_type",
        INSTRUCTIONS: "information_details.instructions",
        KP_ID: "information_details.kpId",
        SOFTWARE_DETAILS: "software_details",
        CLIENT_NAME: "software_details.client_name",
        SOFTWARE_VERSION: "software_details.software_version",
        LOGO_URI: "software_details.logo_uri",
        DESCRIPTION: "software_details.description",
        CAPABILITIES: "software_details.capabilities",
        LANGUINO_KEY: "software_details.languino_key"
    };

    var INSTRUCTION_TYPES = {
        STRUCTURED: "structured",
        MARKDOWN: "markdown"
    };

    var SoftwareModel = BaseModel.extend({
        idAttribute: "software_id",
        KEYS: KEYS,
        defaults: {
            software_id: "",
            software_statement: "",
            information_details: null
        },
        name: "SoftwareModel",

        parse: function (response, options) {
            if (response && response.software_statement && JwtUtility.isJwtFormat(response.software_statement)) {
                response.software_details = JwtUtility.decodeJwt(response.software_statement);
                response.software_details.client_name = this._getTranslatedName(response);
                response.software_details.description = this._getTranslatedShortDescription(response);

                if (response.software_details.logo_uri &&
                    response.software_details.logo_uri.indexOf('/src/modules/campaigns/assets/img/software/') === 0) {
                    response.software_details.logo_uri = window.leImageBaseUrl + response.software_details.logo_uri.substring(1);
                }
            }

            if(_.isUndefined(response.information_details)) {
                response.information_details = {};
            }
            response.information_details.instructions = this._getTranslatedInstructions(response);
            response.information_details.long_description = this._getTranslatedLongDescription(response);

            if (response.software_details.software_id) {
                response.software_id = response.software_details.software_id;
            }

            return BaseModel.prototype.parse.call(this, response, options);
        },

        getSoftwareId: function() {
            return this.get(KEYS.SOFTWARE_ID);
        },

        getName: function(){
            return this.get(KEYS.CLIENT_NAME);
        },

        setName: function(clientName){
            this.set(KEYS.CLIENT_NAME, clientName);
        },

        getInstructions: function() {
            return this.get(KEYS.INSTRUCTIONS);
        },

        setInstructions: function(instructions) {
            this.set(KEYS.INSTRUCTIONS, instructions);
        },

        getSoftwareVersion: function(){
            return this.get(KEYS.SOFTWARE_VERSION);
        },

        getLogoUri: function(){
            return this.get(KEYS.LOGO_URI);
        },

        getShortDescription: function(){
            return this.get(KEYS.DESCRIPTION);
        },

        getSoftwareStatement: function(){
            return this.get(KEYS.SOFTWARE_STATEMENT);
        },

        getCapabilities: function(){
            return this.get(KEYS.CAPABILITIES);
        },

        getLongDescription: function(){
            return this.get(KEYS.LONG_DESCRIPTION);
        },

        setLongDescription: function(description){
            this.set(KEYS.LONG_DESCRIPTION, description);
        },

        setShortDescription: function(description){
            this.set(KEYS.DESCRIPTION, description);
        },

        getVideoUrl: function(){
            return this.get(KEYS.VIDEO_URL);
        },

        getKpId: function(){
            return this.get(KEYS.KP_ID);
        },

        getInstructionsType: function(){
            return this.get(KEYS.INSTRUCTIONS_TYPE);
        },

        isStructuredInstructionType: function(){
            return this.getInstructionsType() === INSTRUCTION_TYPES.STRUCTURED;
        },

        isMarkdownInstructionType: function(){
            return this.getInstructionsType() === INSTRUCTION_TYPES.MARKDOWN;
        },

        _getTranslatedName: function(response){
            var languinoKey = response.software_details.languino_key;
            var localLanguageKey = LE.localeResolver.getLocale();
            var key = _.has(response.software_details, "client_name#" + localLanguageKey) ? "client_name#" + localLanguageKey : "client_name";
            var name = response.software_details[key];

            var nameTranslationKey = "LEFramework.entities.software." + languinoKey + ".name";
            if (languinoKey && LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), nameTranslationKey)) {
                name = LE.translator.translate(nameTranslationKey);
            }
            return name;
        },

        _getTranslatedInstructions: function(response) {
            var languinoKey = response.software_details.languino_key;
            var localLanguageKey = LE.localeResolver.getLocale();
            var key = _.has(response.information_details, "instructions#" + localLanguageKey) ? "instructions#" + localLanguageKey : "instructions";
            var instructions = response.information_details[key];

            if (languinoKey) {
                if (response[KEYS.INSTRUCTIONS_TYPE] === INSTRUCTION_TYPES.STRUCTURED) {
                    instructions = _.map(instructions, function(instruction, index) {
                        var translatedInstruction = instruction;
                        var instructionTranslationKey = "LEFramework.entities.software.instructions." + languinoKey + "." + index;
                        if (LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), instructionTranslationKey)) {
                            translatedInstruction = LE.translator.translate(instructionTranslationKey);
                        }
                        return translatedInstruction;
                    });
                }
            }
            return instructions;
        },

        _getTranslatedLongDescription: function(response) {
            var languinoKey = response.software_details.languino_key;
            var localLanguageKey = LE.localeResolver.getLocale();
            var key = _.has(response.information_details, "long_description#" + localLanguageKey) ? "long_description#" + localLanguageKey : "long_description";
            var longDescription = response.information_details[key];

            var longDescriptionTranslationKey = "LEFramework.entities.software." + languinoKey + ".longDescription";
            if (languinoKey && LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), longDescriptionTranslationKey)) {
                longDescription = LE.translator.translate(longDescriptionTranslationKey);
            }
            return longDescription;
        },

        _getTranslatedShortDescription: function(response) {
            var languinoKey = response.software_details.languino_key;
            var localLanguageKey = LE.localeResolver.getLocale();
            var key = _.has(response.software_details, "description#" + localLanguageKey) ? "description#" + localLanguageKey : "description";
            var shortDescription = response.software_details[key];

            var shortDescriptionTranslationKey = "LEFramework.entities.software." + languinoKey + ".shortDescription";
            if (languinoKey && LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), shortDescriptionTranslationKey)) {
                shortDescription = LE.translator.translate(shortDescriptionTranslationKey);
            }
            return shortDescription;
        }

    });


    SoftwareModel.KEYS = KEYS;
    SoftwareModel.INSTRUCTION_TYPES = INSTRUCTION_TYPES;
    return SoftwareModel;
});
