import {
  store,
  storeModuleTypes,
  environmentGetterTypes,
  environmentActionTypes,
  gChannel,
  AGENT_STATUS_DATA_STATES,
  Logger,
  sessionManager,
} from 'vue-infra';
import * as CONST from 'const';

const MAX_STATUS_POLLING_RETRIES = 2;
const MAX_UMS_UPDATE_STATUS_RETRIES = 20;
const ASYNC_MESSAGING_FEATURE = 'Common.Async_Messaging';
const PREVENT_UPDATE_AGENT_STATUS_AT_UMS_FEATURE = 'Messaging.Set_Agent_State_By_Facts';
const HAS_MESSAGING_PRIVILEGE = 1734;
const VIEW_CAMPAIGN_MODULE_PRIVILEGE = 1500;
const VIEW_USERS_MODULE_PRIVILEGE = 1501;
const VIEW_AGENT_MODULE_PRIVILEGE = 1502;
const VIEW_AGENT_MANAGER_MODULE_PRIVILEGE = 1503;
const DENVER_POLLING_DEFAULT_TIMEOUT = 2000;

let statusRequestAndPollingMismatchCounter = 0;
let umsUpdateStatusRetries = 0;
let manualPollingCounter = 0;
let shouldLogAgentStatus,
  fullUserId,
  logger,
  hasMessaging,
  preventUpdateAgentStatusInUMS,
  overrideDenverPolling,
  keepAliveTimeout;

export class AgentStatusManagerOld {
  constructor() {
    logger = Logger.getLogger("AgentStatusManager");

    shouldLogAgentStatus = hasLogAgentStatusSetting();
    hasMessaging = isMessagingEnabled();
    preventUpdateAgentStatusInUMS = preventUpdateAgentStatusInUMSEnabled();
    // If denver polling frequency configured to be more than default (2000 mill) than when changing agent status,
    // initiate the polling manually with default timeout (2000 mill), so the knowledge of if the agent status changed, won't be delayed.
    overrideDenverPolling = hasChangeInDenverPollingFrequency();

    const accountId = store.getters['session/getValue']('accountId');
    const userId = store.getters['session/getValue']('userId');

    fullUserId = accountId + '.' + userId;

    window.logAgentStatusMismatch = logAgentStatusToKibana;
    store.watch(() => store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.REQUEST_AGENT_STATUS_CHANGE}`], onRequestAgentStatusChange);
    gChannel.register('LiveEngageCommon', 'agentStatusPollingSuccess', onAgentStatusPollingSuccess);
  }
}

function onRequestAgentStatusChange() {
  if(!isAgentStatusChangeInProcess()) {
    const newStatus = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.REQUEST_AGENT_STATUS_CHANGE}`];
    if (newStatus.value !== null) {
      const currentStatus = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.CURRENT_AGENT_STATUS}`] || {};

      if (newStatus.value !== currentStatus.value || ((newStatus.reasonId !== currentStatus.reasonId) && (newStatus.reasonId || currentStatus.reasonId))) {
        sendAgentStatusChangeToDenver(newStatus);
      }
    }
  }
}

function sendAgentStatusChangeToDenver(status) {
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.AWAITING_DENVER);
  logAgentStatusToKibana(`Denver:setAvailability:sendRequest. Status=${status.value}, reasonId=${status.reasonId}`);
  lpTag.WebAgent.AppMediator.setAvailability({
    chatAvailability: status.value,
    chatReasonId: status.reasonId,
    success: onDenverStatusChangeSuccess.bind(this, status),
    error: onDenverStatusChangeError.bind(this, status),
  });
}

function onDenverStatusChangeSuccess(status) {
  statusRequestAndPollingMismatchCounter = 0;
  if(overrideDenverPolling) {
    manualPollingCounter = 0;
    startOverrideDenverPolling();
  }
  logAgentStatusToKibana(`Denver:setAvailability:success. Status=${status.value}, reasonId=${status.reasonId}`);
}

/**
 * If denver polling frequency configured to be more than default (2000 mill) than when changing agent status,
 * initiate the polling manually with default timeout (2000 mill), so the knowledge of if the agent status changed, won't be delayed.
 * Pull only 2 times. 2 times is enough to know if the status was really updated in denver.
 */
function startOverrideDenverPolling() {
  clearTimeout(keepAliveTimeout);
  if(manualPollingCounter <= MAX_STATUS_POLLING_RETRIES) {
    manualPollingCounter++;
    gChannel.trigger('LiveEngageCommon', 'agentStatus:requestDenverPolling');
    keepAliveTimeout = setTimeout(startOverrideDenverPolling, DENVER_POLLING_DEFAULT_TIMEOUT);
  }
}

function onDenverStatusChangeError(status) {
  logAgentStatusToKibana(`Denver:setAvailability:error. Status=${status.value}, reasonId=${status.reasonId}`, true);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
    value: null,
  });
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.STATUS_UPDATE_FAILED);
}

function isAgentStatusChangeInProcess() {
  const agentStatusDataState = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.AGENT_STATUS_DATA_STATE}`];
  return !(agentStatusDataState !== AGENT_STATUS_DATA_STATES.AWAITING_DENV && agentStatusDataState !== AGENT_STATUS_DATA_STATES.AWAITING_UMS);
}

function onAgentStatusPollingSuccess(status) { // When we get by polling from Denver agent status changed
  logAgentStatusToKibana(`Denver:polling. Status=${status.value}, reasonId=${status.reasonId}`);

  if(status.value) {
    const requestAgentStatusChange = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.REQUEST_AGENT_STATUS_CHANGE}`];
    const agentStatusDataState = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.AGENT_STATUS_DATA_STATE}`];
    if (requestAgentStatusChange.value !== null) { // If there was any request to change the status
      // Check if the requested status is not equal to the status returned from Denver
      if (status.value !== requestAgentStatusChange.value || status.reasonId !== `${requestAgentStatusChange.reasonId || ''}`) {
        onDenverPollingStatusMismatch(status);
      } else if (agentStatusDataState !== AGENT_STATUS_DATA_STATES.AWAITING_UMS) {
        requestStatusChangeInUMS(status);
      }
    } else {
      const currentStatus = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.CURRENT_AGENT_STATUS}`] || {};
      if (((status.value !== currentStatus.value) || (status.reasonId !== `${(currentStatus.reasonId || '')}`)) &&
        agentStatusDataState !== AGENT_STATUS_DATA_STATES.AWAITING_UMS) {
        requestStatusChangeInUMS(status);
      }
    }
  } else {
    const currentAgentStatusDataState = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.AGENT_STATUS_DATA_STATE}`];
    if(currentAgentStatusDataState === AGENT_STATUS_DATA_STATES.NOT_REQUESTED) {
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.SUCCESS);
    }
  }
}

function onDenverPollingStatusMismatch(status) {
  if((!overrideDenverPolling || manualPollingCounter >= MAX_STATUS_POLLING_RETRIES) &&
    statusRequestAndPollingMismatchCounter === MAX_STATUS_POLLING_RETRIES) {
    onDenverStatusChangeError(status);
    logAgentStatusToKibana(`Denver:polling:error. Polling not reflect the set status. Status=${status.value}, reasonId=${status.reasonId}`);
  } else {
    statusRequestAndPollingMismatchCounter++;
  }
}

function requestStatusChangeInUMS(status) {
  // If the requested status is equal to the status returned from Denver
  statusRequestAndPollingMismatchCounter = 0;
  clearTimeout(keepAliveTimeout);
  if(hasMessaging && !preventUpdateAgentStatusInUMS) {
    const currentAgentStatusDataState = store.getters[`${storeModuleTypes.ENVIRONMENT}/${environmentGetterTypes.AGENT_STATUS_DATA_STATE}`];
    if(currentAgentStatusDataState !== AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY) {
      store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.AWAITING_UMS);
      logAgentStatusToKibana(`UMS:setAgentState:sendRequest. Status=${status.value}, reasonId=${status.reasonId}`);
      gChannel.trigger('LiveEngageCommon', 'agentStatus:requestUMSChange', {
        data: {
          status,
        },
        success: onStatusUpdateCompleted.bind(this, status),
        error: onStatusDiscrepancy.bind(this, status),
      });
    }
  } else {
    onStatusUpdateCompleted(status);
  }
}

function onStatusDiscrepancy(status) {
  if(umsUpdateStatusRetries >= MAX_UMS_UPDATE_STATUS_RETRIES) {
    umsUpdateStatusRetries = 0;
    logAgentStatusToKibana(`Status discrepancy error. Status=${status.value}, reasonId=${status.reasonId}`, true);
    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CURRENT_AGENT_STATUS}`, {
      value: CONST.DEFAULT_AGENT_STATUSES.unknown.value
    });
    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
      value: null,
    });
    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`,
      AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY);
  } else {
    const REQUEST_AGAIN_STATUS_CHANGE_TIMEOUT = 2000;
    umsUpdateStatusRetries++;
    setTimeout(requestStatusChangeInUMS.bind(this, status), REQUEST_AGAIN_STATUS_CHANGE_TIMEOUT);
  }
}

function onStatusUpdateCompleted(status) {
  logAgentStatusToKibana(`Change status completed successfully. Status=${status.value}, reasonId=${status.reasonId}`);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CURRENT_AGENT_STATUS}`, status);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.REQUEST_AGENT_STATUS_CHANGE}`, {
    value: null,
  });
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_MESSAGING_AGENT_STATUS}`, status);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.SET_CHAT_AGENT_STATUS}`, status);
  store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_STATUS_DATA_STATE}`, AGENT_STATUS_DATA_STATES.SUCCESS);
  statusRequestAndPollingMismatchCounter = 0;
  umsUpdateStatusRetries = 0;
}

function isMessagingEnabled() {
  let isMessagingEnabled = hasMessagingFeature();
  isMessagingEnabled = isMessagingEnabled && hasMessagingPrivilege();
  return isMessagingEnabled;
}

function hasMessagingFeature() {
  return sessionManager.getFeaturePropertyState(ASYNC_MESSAGING_FEATURE);
}

function hasMessagingPrivilege() {
  return sessionManager.hasPrivilege(HAS_MESSAGING_PRIVILEGE) || !!sessionManager.isLPA();
}

function isAgentOnly() {
  const hasAgentManagerModulePrivilege = sessionManager.hasPrivilege(VIEW_AGENT_MANAGER_MODULE_PRIVILEGE);
  const hasUsersViewPrivilege = sessionManager.hasPrivilege(VIEW_USERS_MODULE_PRIVILEGE);
  const hasCampaignManagerModulePrivilege = sessionManager.hasPrivilege(VIEW_CAMPAIGN_MODULE_PRIVILEGE);
  const hasAgentViewPrivilege = sessionManager.hasPrivilege(VIEW_AGENT_MODULE_PRIVILEGE);

  return hasAgentViewPrivilege &&
    !hasCampaignManagerModulePrivilege &&
    !hasUsersViewPrivilege &&
    !hasAgentManagerModulePrivilege;
}

function logAgentStatusToKibana(message, enforceLog) {
  if (shouldLogAgentStatus || enforceLog) {
    logger.error(`${message}. agentId=${fullUserId}`);
  }
}

function hasLogAgentStatusSetting() {
  const GET_SESSION_SETTINGS_PATH = 'session/getSettingsMap';
  const DIAGNOSTIC_KEY = 'le.agent.diagnostics';
  const LOG_AGENT_STATUS_KEY = 'logAgentStatus';

  const diagnostics = store.getters[GET_SESSION_SETTINGS_PATH]()[DIAGNOSTIC_KEY];
  const diagnosticsArray = diagnostics && diagnostics.propertyValue && diagnostics.propertyValue.value;
  return diagnosticsArray && diagnosticsArray.indexOf && diagnosticsArray.indexOf(LOG_AGENT_STATUS_KEY) >= 0;
}

function preventUpdateAgentStatusInUMSEnabled() {
  return sessionManager.getFeaturePropertyState(PREVENT_UPDATE_AGENT_STATUS_AT_UMS_FEATURE);
}

function hasChangeInDenverPollingFrequency() {
  const isOverrideDenverPollingForSetStatus = (sessionManager.getLEConfigProp && sessionManager.getLEConfigProp('isOverrideDenverPollingForSetStatus')) || {};
  if (isOverrideDenverPollingForSetStatus) {
    return true;
  }

  else {
    // If there is no phase configuration (AKA feature flag) configured, check if there is there is site settings
    let denverPollingTimeout = sessionManager.getAccountSettingValueByID('messaging.only.account.denver.polling.frequency.milliseconds', false);
    denverPollingTimeout = parseInt(denverPollingTimeout, 10);
    return (!isNaN(denverPollingTimeout) && denverPollingTimeout > DENVER_POLLING_DEFAULT_TIMEOUT);
  }
}
