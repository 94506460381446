<template>
  <div
    class="nav-list__item__title"
    :data-lp-at="`list-title-${index}`"
    :id="`${languageKey}-id`"
  >
    {{ $translator.translate('LEFramework.' + languageKey) }}
  </div>
</template>

<script>
export default {
  name: "NavBarTitle",
  props: {
    index: Number,
    languageKey: String
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/navigation_bar';
</style>
