<template>
  <div :class="agentTimerClass">
    <span
      v-if="shouldDisplayTimer"
      class="a11y-label"
      :aria-label="agentTimer"
    ></span>
    <span
      v-if="shouldDisplayTimer"
      data-lp-at="status-timer-agent"
      :class="['status-timer', { 'has-timer': isSelected }]"
      aria-hidden="true"
    > {{ displayTimer }} </span>
  </div>
</template>

<script>

import { DEFAULT_AGENT_STATUSES } from 'const';
import {
  store,
  storeModuleTypes,
  environmentActionTypes,
  environmentGetterTypes, Vuex
} from 'vue-infra';
import agentViewService from '../../../Services/AgentViewService';

const timeout = 5000;
const interval = 1000;
const waitBeforeFetchingAgain = 5000;
const AWAY = DEFAULT_AGENT_STATUSES.away.value
const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
  agentTimer: environmentGetterTypes.AGENT_TIMER,
});

export default {
  name: "AgentTimer",
  props: {
    statusItem: {
      type: Object,
      default: () => {},
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
    isFirstStatusAfterLogin: {
      type: Boolean,
      default: true,
    },
    shouldDisplayTimerInsideMenu: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['makeFirstStatusFalse'],
  data() {
    return {
      time: {
        hours: null,
        minutes: null,
        seconds: null,
      },
      currentStatusStartTime: {
        startTimerFromAPI: 0,
        startTimerFromClick: 0,
      },
      statuses: {
        'Online': 'ONLINE',
        'Occupied': 'BACK_SOON',
        'Away': 'AWAY'
      },
      statusTimerTimeout: null,
      statusTimerInterval: null,
      isStatusStillAwayByDefault: true, // the status is away upon login until the system figures out the real current status
      shouldUpdateFromAPI: false,
      APINotUpdatedCounter: 0,
    };
  },
  computed: {
    agentTimer: getters.agentTimer,
    displayTimer() {
      return this.time.hours && this.time.minutes && this.time.seconds ?
        `${this.time.hours}:${this.time.minutes}:${this.time.seconds}` : '';
    },
    // currentStatusStartTime bigger than 0 means we've got the data we need for the timer
    hasTimerBegun() {
      return this.currentStatusStartTime.startTimerFromAPI > 0 || this.currentStatusStartTime.startTimerFromClick > 0;
    },
    isSelected() {
      return this.statusItem.value === this.selectedItem.value;
    },
    // isStatusStillAwayByDefault equals false means the system has figured out what's the real status is
    // this way we start displaying the timer only with the first "real" status
    shouldDisplayTimer() {
      return this.hasTimerBegun && !this.isStatusStillAwayByDefault;
    },
    isStatuseAway() {
      return this.statusItem.value === AWAY;
    },
    agentTimerClass() {
      if (this.shouldDisplayTimerInsideMenu) {
        return 'has-agent-timer';
      } else {
        return 'hide-agent-timer';
      }
    }
  },
  watch: {
    selectedItem() {
      this.selectedItemHandler();
    },
    'time.minutes'() {
      // storing the timer in the vue-infra store
      if(this.isSelected && this.time.hours && this.time.minutes) {
        store.dispatch(
          `${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.UPDATE_AGENT_TIMER}`,
          `${this.time.hours}:${this.time.minutes}`
        );
      }
    },
    'currentStatusStartTime.startTimerFromAPI'(newValue) {
      if (newValue) {
        this.timer(this.currentStatusStartTime.startTimerFromAPI);
      }
    },
    'currentStatusStartTime.startTimerFromClick'(newValue) {
      if (newValue) {
        this.timer(this.currentStatusStartTime.startTimerFromClick);
      }
    },
  },
  beforeUnmount() {
    this.resetToDefaultStates();
  },
  methods: {
    selectedItemHandler() {
      if (!this.selectedItem.isFirstInit) {
        if (this.isSelected) {
          this.onResetTimerMode();
          this.onSelectedMode();
        } else {
          this.onUnSelectedMode();
        }
      }
    },
    onSelectedMode() {
      // in case this is the first status upon log in, fetch the start time from the api
      if (this.isFirstStatusAfterLogin) {
        this.shouldUpdateFromAPI = true;
        this.statusTimerTimeout = setTimeout(
          () => this.fetchAgentStatusStartTime(),
          timeout
        );
        this.$emit('makeFirstStatusFalse');
      } else {
        // in case it is not the first status, just set the start time as now (happens when the agent clicks on a new status)
        this.currentStatusStartTime.startTimerFromClick = new Date().getTime();
      }
    },
    onUnSelectedMode() {
      this.resetToDefaultStates();
    },
    onResetTimerMode() {
      this.resetTimer();
      this.resetStatusTimerInterval();
      this.shouldUpdateFromAPI = false;
    },
    resetToDefaultStates() {
      this.resetTimer(null);
      this.resetStatusTimerInterval();
      this.shouldUpdateFromAPI = false;
    },
    resetTimer(timerInit = '00') {
      this.time.hours = timerInit;
      this.time.minutes = timerInit;
      this.time.seconds = timerInit;
    },
    async fetchAgentStatusStartTime() {
      let result;
      const now = new Date().getTime();
      result = await agentViewService.fetchAgentView(this.statuses[this.selectedItem.value]);
      if (!this.shouldUpdateFromAPI) {
        return;
      }
      if (!result || this.APINotUpdatedCounter >= 6) { // 6 times comes from the fact that this takes up to 30 seconds for this API to reflect the current status
        this.currentStatusStartTime.startTimerFromAPI = now;
        this.APINotUpdatedCounter = 0;
      } else if ((result.agentViewRecords && result.agentViewRecords.length > 0)) {
        this.currentStatusStartTime.startTimerFromAPI = result.agentViewRecords[0].agentCurrentStatusReasonStartTime;
        this.APINotUpdatedCounter = 0;
      } else {
        this.APINotUpdatedCounter++;
        setTimeout(async () => {
          await this.fetchAgentStatusStartTime();
        }, waitBeforeFetchingAgain);
      }
    },
    timer(startTime) {
      // if timer has been called, we've passed the first status, so we certainly passed the default away status
      if (this.isStatusStillAwayByDefault) {
        this.isStatusStillAwayByDefault = false;
      }
      let hour = 0;
      let minute = 0;
      let second = 0;

      this.statusTimerInterval = setInterval(() => {
        let delta = new Date().getTime() - startTime;
        const finalDelta = new Date(delta);

        // these lines make the hours, minutes and seconds fit the local time zone of the agent
        hour = finalDelta.getUTCHours();
        minute = finalDelta.getUTCMinutes();
        second = finalDelta.getUTCSeconds();

        this.time.hours = this.returnData(hour);
        this.time.minutes = this.returnData(minute);
        this.time.seconds = this.returnData(second);
      }, interval);
    },
    returnData(input) {
      return input >= 10 ? input : `0${input}`
    },
    resetStatusTimerInterval() {
      if (this.statusTimerInterval) {
        clearInterval(this.statusTimerInterval);
      }
    },
    resetStatusTimerTimeout() {
      if (this.statusTimerTimeout) {
        clearTimeout(this.statusTimerTimeout);
        this.statusTimerTimeout = null;
      }
    },
  }
}
</script>

<style lang='scss' scoped>
  .has-agent-timer {
    display: flex;
    justify-content: center;
    position: relative;
    top: 25px;
    .status-timer {
      position: absolute;
      font-weight: bold;
      display: none;
      padding-top: 2px;
      justify-content: center;
      &.has-timer {
        display: block;
        bottom: 10px;
      }
    }
  }
  .hide-agent-timer {
    display: none;
  }
</style>
