/**
 * Created by harels on 2/15/18.
 */
define(function (require) {
  var registerModule = require('modules/registerModule');
  var LE2ProxyFactory = require('../LE2proxy/LE2ProxyFactory');

  const buildRoutes = () => registerModule.resolveInternalModules().map((module) => {
    return {
      path: '/' + module.routePrefix,
      name: module.moduleName,
      component: LE2ProxyFactory.getMarrionetteModuleProxy(module.moduleName),
      meta: {
        marrionetteModule: module.moduleName
      }
    };
  });

  return { buildRoutes };
});
