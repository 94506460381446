/**
 * Created by itaic on 11/15/15.
 */
define(function(){
   var LEResourceResolver = require("leResourceResolver");
   var account = LEResourceResolver.getCurrentAccountId();
    // todo - how to lose the lpTag namespace here?
    window.lpTag = window.lpTag || {};
   lpTag.LE = lpTag.LE || {};
   lpTag.LE.traceLog = (account && account.toLowerCase().indexOf("qa") === 0);
   window.lpTaglogListeners = window.lpTaglogListeners || [];
   window.lpTaglogListeners.push(function(msgObj, level, appName){
       if(lpTag.LE.traceLog && level && level.toLowerCase() === "trace"){
           if(window.console && window.console.log){
                window.console.log(msgObj);
           }
       }
   });
});
