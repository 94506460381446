/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/14/14
 * Time: 9:46 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Backbone = require('backbone');

    var DependencyIndicatorModel = Backbone.Model.extend({
        defaults : {
            locked: false,
            enabled: true,
            tooltipTitle: "",
            tooltipFooterText: "",
            maxItemsToDisplay: 10,
            showDependencyTypeTitle: false
        }
    });

    return DependencyIndicatorModel;
});
