/**
 * Created with IntelliJ IDEA.
 * User: noam
 * Date: 3/3/13
 * Time: 10:21 AM
 * A backbone collection with implementation that wraps for us the whole working with account config revisions
 * It will take the revision from the header and put them into the models
 - Will handle the wrapping /unwrapping for account config + revision management.
 - Will handle the save using the revision, if error happens forward the error to the view


 ***  added by Shahara  ***

 Options:
    revision: set the revision in case of a new collection

 */
define(function (require) {
    "use strict";

    var RevisionUtility = require("utils/RevisionUtility");
    var BaseCollection = require("collections/BaseCollection");
    var { Logger } = require('vue-infra');
  var _ = require('underscore');


    var IF_MATCH_HEADER = "If-Match";
    var LAST_MODIFIED_HEADER = "X-LP-Last-Modified";

    var RevisionedCollection = BaseCollection.extend({

        initialize : function (models, options) {
            options = options || {};
            options.change404To200OK = _.has(options,"change404To200OK")? options.change404To200OK: false;  //AC services returns empty array with 200 ok not 404
            BaseCollection.prototype.initialize.call(this,models,options);
            this.logger = Logger.getLogger("LEFramework");
            if (_.has(options, 'revision')) {
                this.setRevision(options.revision);
            }
        },
        setRevisionObject: function(revision) {
            if (revision) {
                this.lpRevision = revision;
            }
        },
        getRevisionObject: function() {
            return this.lpRevision;
        },
        setRevision: function(revision) {
            if (revision) {
                if ("object" === typeof revision) {
                    this.setRevisionObject(revision);
                }
                else {
                    this.lpRevision = this.lpRevision || {};
                    this.lpRevision.val = revision;
                }
            }
        },
        getRevision: function(all) {
            if (all) {
                return this.getRevisionObject();
            }
            else if (this.lpRevision) {
                return this.lpRevision.val;
            }

            return void 0;
        },
        setLastModified: function(lastModified) {
            if (lastModified) {
                this.lpRevision = this.lpRevision || {};
                this.lpRevision.lastModified = lastModified;
            }
        },
        getLastModified: function() {
            if (this.lpRevision) {
                return this.lpRevision.lastModified;
            }

            return void 0;
        },
        parse: function(resp, options){
            this.setRevision(RevisionUtility.parseRevisionFromXhr(options.xhr));
            return resp;
        },

        /**
         *  Override original sync function to add revision on header
         *  @override
         */
        sync: function (method, collection, options) {
            var that = this;
            //on put/delete/get send revision to server in header
            if (( method === 'update' || method === 'patch') || method == 'delete' || method == 'read') {
                options.beforeSend = function (xhr) {

                    var revision = that.getRevision();
                    if (revision) {
                        xhr.setRequestHeader(IF_MATCH_HEADER, revision);
                    }

                    var lastModified = that.getLastModified();
                    if (lastModified) {
                        xhr.setRequestHeader(LAST_MODIFIED_HEADER, lastModified);
                    }

                    return xhr;
                };
            }
            BaseCollection.prototype.sync.apply(this, [].slice.apply(arguments));
        },

        update: function(options) {
            options = options || {};
            var originalSuccess = options.success;
            options.success = _.bind(function(models, resp, respOptions) {
                if (respOptions) {
                    this.setRevision(RevisionUtility.parseRevisionFromXhr(respOptions));
                }

                if (_.isFunction(originalSuccess)) {
                    originalSuccess(models, resp, respOptions);
                }
            }, this);
            var originalError = options.error;
            options.error = _.bind(function(resp) {
                if (_.isFunction(originalError)) {
                    originalError(this, resp, options);
                }
            }, this);

            this.sync("update", this, options);
        }
    });

    RevisionedCollection.IF_MATCH_HEADER = IF_MATCH_HEADER;

    return  RevisionedCollection;
});
