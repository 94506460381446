/**
 * Created with IntelliJ IDEA.
 * User: shaulm
 * Date: 3/3/13
 * Time: 11:31 AM
 * A backbone collection with a persistence function
 * The implementation may fetch from the server every x seconds
 */
define(function (require) {
    "use strict";

    var _ = require("underscore");
    var { Logger } = require('vue-infra');
    var RevisionedCollection = require("collections/RevisionedCollection");

    var DEFAULTS = {
        FETCH_INTERVAL:  20000,
        FETCH_ERROR_INTERVAL: 400,
        FETCH_ERROR_INTERVAL_AFTER_RETRIES: 300000,
        RETRY: false,
        MAX_RETRY: 5
    };

    var PersistentCollection = RevisionedCollection.extend({
        initialize: function(models, options) {
            this._initialize(models, options);
        },
        persist: function(options) {
            this._persist(options);
        },
        stopPersist: function() {
            this._stopPersist();
        },


        _initialize: function(models, options) {
            options = options || {};

            this.logger = Logger.getLogger("LEFramework");
            this.logger.debug("options: ", "PersistentCollection:initialize", options);

            RevisionedCollection.prototype.initialize.call(this, models, options);

            this.fetchInterval = (_.isFinite(options.fetchInterval) && options.fetchInterval > 0) ? options.fetchInterval : DEFAULTS.FETCH_INTERVAL;
            this.fetchErrorInterval = (_.isFinite(options.fetchErrorInterval) && options.fetchErrorInterval > 0) ? options.fetchErrorInterval : DEFAULTS.FETCH_ERROR_INTERVAL;
            this.fetchErrorIntervalAfterRetries = (_.isFinite(options.fetchErrorIntervalAfterRetries) && options.fetchErrorIntervalAfterRetries > 0) ? options.fetchErrorIntervalAfterRetries : DEFAULTS.FETCH_ERROR_INTERVAL_AFTER_RETRIES;
            this.maxRetries = (_.isFinite(options.maxRetries) && options.maxRetries > 0) ? options.maxRetries : DEFAULTS.MAX_RETRY;

            //internal state members
            this.activeRefresh = false;
            this.refreshId = null;
            this.errorTryCounter = 0;
            this.isError = true;
        },

        _persist: function(options) {
            options = options  || {} ;

            this.logger.debug("start persist ","PersistentCollection:_persist", options);

            this._stopPersist();

            this.activeRefresh = true;
            this.refreshId = (new Date()).getTime().toString();

            //allow to override refresh options
            this.fetchInterval = options.fetchInterval || this.fetchInterval;
            this.fetchErrorInterval = options.fetchErrorInterval || this.fetchErrorInterval;
            this.maxRetries = options.maxRetries || this.maxRetries;
            this.isError = false;

            this._refresh(options);
        },

        _refresh: function(options) {
            if (!this.activeRefresh){
                this.logger.debug("_refresh was called but activeRefresh is false", "PersistentCollection:_refresh");
                return;
            }

            this.logger.debug("_refresh errorTryCounter: " + this.errorTryCounter, "PersistentCollection:_refresh");


            this.fetch({
                refreshId: this.refreshId,
                freshnessTime: options.freshnessTime,
                requestTime: (new Date()).getTime(), // to ensure that data collection belong to the last request, we add to fetch options requestTime and lastUpdateTime. lpAjax.transport compare those values and prevent response if needed.
                lastUpdateTime: this.lastUpdateTime || null,
                fetchOptions: options.fetchOptions || {},

                success: _.bind(function (model, resp, bbOptions) {
                    this.logger.debug("_refresh:fetch:success", "PersistentCollection:_refresh" , resp);

                    if (this.refreshId !== bbOptions.refreshId) { // if this is success function for not relevant refreshId - ignore the success because someone already want it to stop.

                        return;
                    }


                    this.errorTryCounter = 0; // reset the error try counter because we succesfully get the collection
                    this.isError = false;
                    this.lastUpdateTime = (new Date()).getTime();

                    if (_.isFunction(options.success)) {
                        options.success(model, resp, bbOptions);
                    }

                    _.delay(_.bind(function () {
                        if (this.refreshId === bbOptions.refreshId) {
                            this._refresh.call(this, options);
                        }
                    }, this), this.fetchInterval);
                }, this),

                error: _.bind(function (model, resp, bbOptions) {
                    this.logger.error("_refresh:fetch:error", "PersistentCollection:_refresh" , resp);

                    if (this.refreshId !== bbOptions.refreshId) { // if this is success function for not relevant refreshId - ignore the success because someone already want it to stop.

                        return;
                    }

                    var statusIsInvalid = (401 === resp.status || 403 === resp.status);

                    if (statusIsInvalid || (!this.isError && this.errorTryCounter >= this.maxRetries)) {
                        this.isError = true;
                        if (_.isFunction(options.error)) {
                            options.error(model, resp, bbOptions);
                        }
                    }

                    if (!statusIsInvalid) {
                        var timeoutInterval = (this.errorTryCounter >= this.maxRetries) ? this.fetchErrorIntervalAfterRetries : this.fetchErrorInterval;
                        _.delay(_.bind(function () {
                            if (this.refreshId === bbOptions.refreshId) {
                                this.errorTryCounter ++;
                                this._refresh.call(this, options);
                            }
                        }, this), timeoutInterval);
                    }
                }, this)
            });
        },

        _stopPersist: function() {
            this.logger.debug("Stop persist","PersistentCollection:_stopPersist");
            this.activeRefresh = false;
            this.refreshId = null;
        }

    });

    PersistentCollection.IF_MATCH_HEADER = RevisionedCollection.IF_MATCH_HEADER;
    PersistentCollection.LAST_MODIFIED_HEADER = RevisionedCollection.LAST_MODIFIED_HEADER;

    return  PersistentCollection;
});
