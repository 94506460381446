/**
 * Created by vanessat on 3/25/15.
 */

define(function(require) {
    'use strict';
    var ConversionsWindow = require("codeTables/conversionsWindow.json");

    var conversionsWindowSettings = (function() {
        var currentConversionsWindow = null;

        var getConversionsWindowList = function() {
            return ConversionsWindow.conversionWindowCodes;
        };

        var getDefaultConversionsWindow = function() {
            return ConversionsWindow.defaultConversionsWindow;
        };

        var getCurrentConversionsWindow = function() {
            if (currentConversionsWindow === null) {
                currentConversionsWindow = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CONVERSIONS_WINDOW_SIZE, ConversionsWindow.defaultConversionsWindow);
            }
            return currentConversionsWindow;
        };

        var switchConversionsWindow = function(conversionsWindow) {
            currentConversionsWindow = conversionsWindow;
            LE.vent.trigger("siteSettings:conversionsWindowSwitched", currentConversionsWindow);
        };

        return {
            getConversionsWindowList: getConversionsWindowList,
            getDefaultConversionsWindow: getDefaultConversionsWindow,
            getCurrentConversionsWindow: getCurrentConversionsWindow,
            switchConversionsWindow: switchConversionsWindow
        };

    })();

    return conversionsWindowSettings;

});
