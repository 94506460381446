define(function (require) {
    "use strict";

    var _ = require("underscore");
    var $ = require("jquery");

    var CappingSettings  = require("site-settings/cappingSettings");
    var CobrowseSettings  = require("site-settings/cobrowseSettings");
    var ContentSettings  = require("site-settings/contentSettings");
    var ControlGroupSettings  = require("site-settings/controlGroupSettings");
    var CurrencySettings  = require("site-settings/currencySettings");
    var FeatureSettings  = require("site-settings/featureSettings");
    var AccountSettings  = require("site-settings/accountSettings");
    var SupportedLanguageSettings  = require("site-settings/supportedLanguageSettings");
    var SlaUnitsSettings = require("site-settings/slaUnitsSettings");
    var TimeZoneSettings  = require("site-settings/timeZoneSettings");
    var LanguageSettings  = require("site-settings/languageSettings");
    var PrivilegeSettings  = require("site-settings/privilegeSettings");
    var SessionTimeoutSettings = require("site-settings/sessionTimeoutSettings");
    var RoutingSourceSettings = require("site-settings/routingSourceSettings");
    var AgentStatusSettings = require("site-settings/agentStatusSettings");
    var AttributionSettings = require("site-settings/attributionSettings");
    var ConversionsWindowSettings = require("site-settings/conversionsWindowSettings");
    var ConnectionPanelVisibilitySettings = require("site-settings/connectionPanelVisibilitySettings");
    var AgentOutcomeSettings = require("site-settings/agentOutcomeSettings");
    var lobSettings = require("site-settings/lobSettings");
    var smartCapacitySettings  = require("site-settings/smartCapacitySettings");
    var customizationSettings  = require("site-settings/customizationSettings");

    var SiteSettings = (function () {
        var initialize = function (options) {
            options = options || {};
            LanguageSettings.switchLocale(LanguageSettings.getAccountLocale());
            SessionTimeoutSettings.switchSessionTimeout(SessionTimeoutSettings.getCurrentSessionTimeout());

            // Set "lang" attribute at html level - for hyphenation, if browser supports
            $("html").attr("lang", LE.localeResolver.getLang());

            return SiteSettings;
        };

        return {
            initialize: initialize,
            languageSettings: LanguageSettings,
            contentSettings: ContentSettings,
            supportedLanguageSettings: SupportedLanguageSettings,
            currencySettings: CurrencySettings,
            timeZoneSettings: TimeZoneSettings,
            cappingSettings: CappingSettings,
            controlGroupSettings: ControlGroupSettings,
            featureSettings: FeatureSettings,
            sessionTimeoutSettings: SessionTimeoutSettings,
            privilegeSettings: PrivilegeSettings,
            routingSourceSettings: RoutingSourceSettings,
            agentStatusSettings: AgentStatusSettings,
            attributionSettings: AttributionSettings,
            SlaUnitsSettings: SlaUnitsSettings,
            conversionsWindowSettings: ConversionsWindowSettings,
            connectionPanelVisibilitySettings: ConnectionPanelVisibilitySettings,
            agentOutcomeSettings: AgentOutcomeSettings,
            cobrowseSettings: CobrowseSettings,
            accountSettings: AccountSettings,
            lobSettings: lobSettings,
            smartCapacitySettings: smartCapacitySettings,
            customizationSettings: customizationSettings,
        };

    })();


    return SiteSettings;
});
