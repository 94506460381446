/*
 this is the entry point to the application
 we will load the require configuration and then the application logic
 */
define(function (require) {
    "use strict";

    __webpack_public_path__ = window.leResourceBaseUrl || '/'; // jshint ignore:line
    window.leImageBaseUrl = window.leResourceBaseUrl ? window.leResourceBaseUrl : '../../';

    // make webpack load scss
    require('assets/scss/app.scss');
    require('assets/fonts/inter.css');

    function bootstrap(){
        // Since index.html on production is controlled somewhere else,
        // portal target element on top level is dynamically created
        // Create the portal target if it does not exist in DOM
        const id = 'fe-root-mounting-portal-target';
        if (!document.getElementById(id)) {
          document.body.append(Object.assign(document.createElement('div'), { id }));
        }

        const registerModule = require('modules/registerModule');
        const modulesLoader = require('./modules-loader');
        modulesLoader.calculateModules();

        require("configErrorHandlers");
        require("src/configLoggerAppenders");
        require("uiRequireConf");

        const LEConfig = require("assets/config/le-env-conf");

        if (LEConfig.__blockInternetExplorer && !!document.documentMode) {
          window.location.replace(`${LEConfig.__backeryDomains[LEConfig.zone || 'z0']}/outdated.html`);
        }

        require("lib/jquery/jquery.watcher");

        const Events = require("Chronos.Events");
        const PostMessageCourier = require("Chronos.PostMessageCourier");
        const Channels = require("Chronos.Channels");
        window.lpTag = window.lpTag || {}; //Set on global for all as well as defined in AMD
        lpTag.Chronos = {
            Events: Events,
            PostMessageCourier: PostMessageCourier,
            Channels: Channels
        };

        // checking if connection panel enabled - if not remove it
        if (false === LEConfig.connectionPanelEnabled) {
          registerModule.unregister("connection");
        }

        require("appStart");
    }

    function getJSON(url, successHandler, errorHandler) {
        const xhr = (("undefined" !== typeof XMLHttpRequest) ?
            new XMLHttpRequest() :
            new ActiveXObject("Microsoft.XMLHTTP"));

        xhr.open("get", url, true);
        xhr.onreadystatechange = function () {
            let status;
            let data;

            // http://xhr.spec.whatwg.org/#dom-xmlhttprequest-readystate
            if (4 === xhr.readyState) { // 'DONE'
                status = xhr.status;
                if (200 === status) {
                    try {
                        data = JSON.parse(xhr.responseText);
                        if (successHandler) {
                            successHandler(data);
                        }
                    }
                    catch (err) {
                        if (errorHandler) {
                            errorHandler(err);
                        }
                        else {
                            throw err;
                        }
                    }
                }
                else {
                    if (errorHandler) {
                        errorHandler(status);
                    }
                    else {
                        throw status;
                    }
                }
            }
        };

        xhr.send();
    }

    // Code here will be linted with JSHint.
    /* jshint ignore:start */
    function applicationBootstrap() {
        const loader = require('./apps-loader');
        require('./modules-loader');
        loader.loadApps().then(() => {
            bootstrap();
        });
    }
    // Code here will be linted with JSHint.
    /* jshint ignore:end */

    function getConfig() {
        if (typeof window.leConfiguration === 'object' && Object.keys(window.leConfiguration).length > 0) {
            applicationBootstrap();
        } else {
            getJSON("./assets/config/le-conf.json?bust=" + (new Date()).getTime(), function (conf) {
                window.leConfiguration = conf;
                // try fetch the devConf file - if not exist continue to load the old modules.
                getJSON("./assets/config/le-dev-env.json?bust=" + (new Date()).getTime(), function (externalApplicationConf) {
                    Object.assign(window.leConfiguration, externalApplicationConf);
                    // load old modules with new applications
                    applicationBootstrap();
                },function() {
                    // load old modules
                    applicationBootstrap();
                });
            });
        }
    }

    getConfig();

});
