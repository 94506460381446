/**
 * Created by meirr on 1/29/17.
 */
define(function(require){

    var Settings = require("codeTables/settings.json");

    function isEnabled() {
        var enableSetting = LE.sessionManager.getAccountSettingByID(Settings.SETTING_ID.SMART_CAPACITY_ENABLED);
        if (enableSetting) {
            return enableSetting.propertyValue.value;
        }
    }

    return {
        isEnabled: isEnabled
    };

});
