/**
 * PaginableCollectionDecorator
 * Params :  collection, options
 * options : Page size, current page number
 * listen collection events : add, reset, remove
 */

define(function (require) {
	"use strict";

	var _ = require("underscore");
	var $ = require("jquery");

	var CollectionPaginator = function () {

        var pageSize = 500;
        var collection = null;
        var totalPages;
        var currentPageNumber = 1;
        var totalItems = 0;

        //-------------------------------------------------------------
        // paginatedCollection.paginate
        //-------------------------------------------------------------

        var paginate = function (collection, options) {

            setVars(collection, options);

            if(currentPageNumber > 0){
                var items = collection.models.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize);
                collection.reset(items,{silent:true,sort:false});
            }
        };

        //-------------------------------------------------------------

        var setVars = function(_collection, options){

            options = options || {};

            collection = _collection;
            pageSize = options.pageSize || pageSize;
            totalPages = Math.ceil(collection.size() / pageSize);
            currentPageNumber = getCurrentPage(options);
            totalItems = collection.size();
        };

        //-------------------------------------------------------------

        var getCurrentPage = function (options) {
            /*
            * this check is for prevent current page be under the first page.
             *there is times when currentPage is reset to zero. For example:
             *when using search component on Users sub-tab under Users tab and shifting to Skills
             * and return to Users sub-tab the table loss the scope of the currntPage and reset it to zero.
             */
            if(!options.currentPage || options.currentPage < 1){
                options.currentPage = 1;
            }

            var pageNumber = options.currentPage;

            if(!_.isNumber(pageNumber)){
                pageNumber = currentPageNumber;
            }
            return (pageNumber > 0) && (pageNumber <= totalPages) ? pageNumber  : totalPages;
        };

        //-------------------------------------------------------------

        var getInfo = function(){
            return{
                pageSize: pageSize,
                totalPages:totalPages,
                currentPageNumber:currentPageNumber,
                totalItems: totalItems
            };
        };

        return{
            paginate:paginate,
            getInfo:getInfo
        };
	};

	return CollectionPaginator;
});
