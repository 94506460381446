<template>
  <div class="user-bar-launch-icons">
    <div class="user-bar-top-level-widgets">
      <!--      Upgrade link is disabled until further notice from CCUI Platform Team -->
      <!--      <div class="top-level-widget trial-counter" v-if="trailAccountText.visible && trailAccountText.text">{{trailAccountText.text}} <a target="_blank" href="https://payments.liveperson.com/">Upgrade</a></div>-->

      <!-- Dynamic load external header component from the window object -->
      <div
        v-if="externalHeaderComponent"
        class="top-level-widget external-wrapper"
      >
        <component :is="externalHeaderComponent.name" />
      </div>

      <div class="top-level-widget">
        <top-bar-notification-widget />
      </div>
      <div class="top-level-widget sound">
        <tray-menu />
      </div>
      <div class="top-level-widget">
        <announcement-pendo-icon />
      </div>
      <div class="top-level-widget">
        <personalization-widget
          v-if="hasPersonalization"
        />
      </div>
      <div class="top-level-widget">
        <top-level-links />
        <top-level-notification />
      </div>
      <div class="top-level-widget">
        <top-level-menu />
      </div>
    </div>
  </div>
</template>

<script>
import TrayMenu from './tray-menu/TrayMenu';
import TopLevelLinks from './top-level-links/TopLevelLinks';
import TopLevelNotification from './top-level-menu/TopLevelNotification';
import PersonalizationWidget from './PersonalizationWidget';
import TopBarNotificationWidget from './TopBarNotificationWidget';
import AnnouncementPendoIcon from './AnnouncementPendoIcon.vue';
import TopLevelMenu from './top-level-menu/TopLevelMenu';
import {
  environmentGetterTypes,
  sessionManager,
  storeModuleTypes,
  Vuex
} from 'vue-infra';
import LEConfig from '../../../assets/config/le-env-conf';

const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
  trailInformation: environmentGetterTypes.RESOLVE_LE_TRAIL_INFORMATION
});

export default {
  name: 'TopBar',
  components: {
    TrayMenu,
    TopLevelLinks,
    PersonalizationWidget,
    TopBarNotificationWidget,
    TopLevelNotification,
    TopLevelMenu,
    AnnouncementPendoIcon
  },
  data() {
    return {
      hasPersonalization: false,
      externalHeaderComponent: null,
      showTrailMenuTrayItem: () =>
        sessionManager.hasLiveEngage2Feature() &&
        sessionManager.hasPrivilege(sessionManager.PRIVILEGES.ACCOUNT_BILLING),
      trailAccountText: {
        text: '',
        // eslint-disable-next-line vue/no-computed-properties-in-data
        visible: this.showTrailMenuTrayItem && this.trailInformation.trial
      }
    };
  },
  computed: {
    trailInformation: getters.trailInformation
  },
  watch: {
    trailInformation() {
      this.buildTrialTrayItem();
    }
  },
  mounted() {
    if (window.externalVueComponent) {
      this.externalHeaderComponent = window.externalVueComponent;
    }
  },
  created() {
    this.hasPersonalization = sessionManager.hasPersona('customizer');
    this.buildTrialTrayItem();
  },
  methods: {
    buildTrialTrayItem() {
      let remainingDays =
        this.trailInformation && this.trailInformation.remainingDays;
      let oneSeat = LEConfig.Billing.oneSeatEnabled;
      let freemiumDaysBarrier = LEConfig.Billing.freemiumDaysBarrier;
      let isFreemium = oneSeat && remainingDays > freemiumDaysBarrier;

      let text = '';
      if (
        !isFreemium &&
        remainingDays !== 'undefined' &&
        remainingDays !== undefined
      ) {
        const daysText = remainingDays === 1 ? ' day left' : ' days left';
        text = 'Free trial - ' + remainingDays + daysText;
      }

      this.trailAccountText = {
        text,
        visible: this.showTrailMenuTrayItem && this.trailInformation.trial
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/global';
@import '../../../assets/scss/mixins';

.user-bar-launch-icons {
  flex-shrink: 0;
  padding-right: 27px;
  position: relative;
  bottom: 10px;
}
.top-bar-delimiter {
  margin: 0 14px 0 0;
  position: relative;
  width: 1px;
  height: 40px;
  @include themify {
    $header-divider: getAttr('nav-bar-header-divider', 'framework');
    @include lp-bg-image('assets/img/' + $header-divider);
  }
  content: '';
}

.user-bar-logo {
  border-right: 1px solid $black;
  width: 132px;
  height: 100%;
}

.user-bar-top-level-widgets {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .top-level-widget {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 3px;

    &.sound {
      left: 0;

      @include themify {
        background-image: getColor('header-bg', 'framework');
      }
    }
  }

  .v-popper {
    .trigger {
      outline: none;
    }
  }
}

.trial-counter {
  display: none;
}

@media (min-width: 1100px) {
  .trial-counter {
    display: block;
    @include themify {
      color: getColor('user-menu-text', 'framework');
    }
    font-size: 14px;
    font-weight: 500;

    & > a {
      font-size: 14px;
      font-weight: 700;
      color: #ff6e00;
      :visited,
      :link,
      :hover,
      :active {
        color: #ff6e00;
      }
    }
  }
}

@media (max-width: 1300px) {
  .user-bar-launch-icons {
    padding-right: 10px;
  }
  .top-bar-delimiter {
    margin: 0 10px 0 0;
  }
}
</style>
