/**
 * Created with IntelliJ IDEA.
 * User: shahars
 * Date: 8/18/13
 * Time: 3:45 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var _ = require("underscore");
    var TimeZone = require("codeTables/timeZone.json");
    var Translator = require("i18n/translator");
    var DateUtility = require("infra-util/DateUtility");
    var $ = require("jquery");
    require("jqueryui/datepicker");
    var LEDateUtility = _.extend({

            /**
             * Month string is translated by the dictionary
             * @param date - can be startDate or expirationDate from the model or any JS Date object
             * @return {String} - Returns a date string in format: 1-JAN-2013
             * @private
             */
            getDateToShow: function(date) {

                var jsDate = (_.isString(date)) ? DateUtility.strToDateObj(date) : date;
                var day = jsDate.getDate();
                var month = jsDate.getMonth() + 1; // in JS Date obj the month range is 0-11 so we need to add 1 to it to get the real month
                var monthStr = Translator.translate("LEFramework.months."+month+".short");
                var year = jsDate.getFullYear();

                var jsDateStr = day + " " + monthStr + ". " + year;
                return jsDateStr;
            },

             /**
              * Returns time formatted string of time zone. for example: (GMT +02:00) Israel Daylight Time (Asia/Jerusalem)
              * @param time zone id or time zone obj.
              * @returns string or undefined
              */
            timeZoneIdToFormattedString: function (timeZone){
                var str;
                if(timeZone){
                    if(!_.isObject(timeZone)){
                        timeZone = TimeZone.timeZoneCodes[timeZone];
                    }
                }
                if(timeZone){
                    var tempUTC = Math.abs(timeZone.UTC);
                    var mantissa = tempUTC % 1;
                    str = "(GMT" + (timeZone.UTC >= 0 ? "+" : "-") + Math.floor(tempUTC / 10) + Math.floor(tempUTC % 10) + (mantissa ? ":" + Math.floor(60 * mantissa) + ") " : ":00) ");
                    str+=Translator.translate("LEFramework." + timeZone.id);
                }

                return str;
            },
            /******
             * Get dateFormat & date. Return the formatted date with translated month
             * @param format
             * @param date
             * @returns {String}
             */
            formatDateTranslated: function(format, date){
                var newDate = null;
                if(_.isString(format) && _.isDate(date)) { // Check that date is Date and format is string

                    // Translate the month
                    var month = date.getMonth() + 1; // in JS Date obj the month range is 0-11 so we need to add 1 to it to get the real month
                    var monthStr = Translator.translate("LEFramework.months."+month+".short");

                    // Replace the M in the format string to $ so we can replace it after the format with the translated month
                    format = format.replace("M","$");
                    newDate = $.datepicker.formatDate(format, date);
                    newDate = newDate.replace("$",monthStr);
                }
                return newDate;
            }
        }, DateUtility);
    return LEDateUtility;

});
