/**
 * Created with IntelliJ IDEA.
 * User: itaik
 * Date: 2/13/13
 * Time: 2:59 PM
 * To change this template use File | Settings | File Templates.
 */
/*
 set application level configurations
 create the LE application object
 add application level services and objects to LE
 sign up all the registered modules to the LE application and set their defaults
 */




 define(function (require) {
    "use strict";
    //list all the dependencies in a more readable format
    var es6MiddleUtils = require('src/es6MiddleUtils');
    // load Base64 Pollyfill (window.atob & window btoa)
    require('Base64');
    const { Logger, lpStorage, sessionManager, gChannel, BaseClient, store  } = require('vue-infra');
    const RouteUtils  = es6MiddleUtils.removeEsModuleDefault(require('./routers/RouteUtils'));
    const _ = require("underscore");
    const $ = require("jquery");
    const $store = require('jquery.store');
    const AppsLoader = require("./apps-loader");
    const Backbone = require("backbone");
    const Marionette = require("marionetteLoader");
    const LEConfig = require("assets/config/le-env-conf");
    const Mustache = require("mustache");
    const Navigator = require("routers/navigator");
    const Translator = require("i18n/translator");
    const LocaleResolver = require("i18n/localeResolver");
    const ModuleManager = require("src/moduleManager");
    const CONST = require("const");
    const Context = require("assets/js/context");
    const Notifier = require("ui/notifier/Notifier");
    const Tooltip = require("ui/tooltip/Tooltip");
    const RichTextEditor = require("richTextEditor"); //Don't remove it
    const LEResourceResolver = require("leResourceResolver");
    const BackboneValidation = require("backbone.validation");
    const BarMenu = require("src/barMenu");
    const Bubble = require("ui/bubble/Bubble");
    const InputField = require("ui/input-field/InputField");
    const Button = require("ui/button/Button");
    const SiteSettings = require("src/siteSettings");
    const BillingSessionModel = require("models/BillingSessionModel");
    const AppServerSessionModel = require("models/AppServerSessionModel");
    const RtDataManager = require("src/rtDataManager");
    const TransportPrefiltersAdapter = require("src/transportPrefiltersAdapter");
    const UserAssistanceController = require("./controllers/UserAssistanceController");
    const LiveEngageLayoutController = require("./controllers/LiveEngageLayoutController");
    let XRegExp = require('xregexp');
    let { AgentStatusManager } = require('src/components/AgentStatusManager');
    let { AgentStatusManagerOld } = require('src/components/AgentStatusManagerOld');
    const ApplicationGeneralSettings = require('src/ApplicationGeneralSettings');

    if (XRegExp.XRegExp) {
        XRegExp = XRegExp.XRegExp;
    }

    var ContentSettings = require("site-settings/contentSettings");
    var registerCoreComponent = require("coreComponentsBaseSupport");
    var registerUtils = require("assets/js/registerUtils");
    var registerMediaCodes = require("assets/data/registerMediaCodes");
    var registerBaseDataModel = require("src/registerBaseDataModel");
    var Media = require("media");
    var Authority = require("authority");
    var DomEvents = require("infra-util/DomEvents");
    var BaseModel = require("models/BaseModel");
    var Events = require("Chronos.Events");
    var Commands = require("Chronos.Commands");
    var { PRIVILEGES } = require('vue-infra');
    var { OUTER_ROUTE_UPDATE } = require('vue-infra').environmentActionTypes;
    var { VALID_APPS, ROUTES } = require('vue-infra').environmentGetterTypes;
    var { GET_CONFIG } = require('vue-infra').sessionGetterTypes;
    var { ENVIRONMENT, SESSION } = require('vue-infra').storeModuleTypes;

    const appName = 'LiveEngage';
    const eventsEngine = new Events({
        appName: appName,
        cloneEventData: false,
        eventBufferLimit: 50
    });

    const { LOGIN_ERROR_TO_AUTH_ERROR_MAP, PERMISSION_GROUP } =  require('vue-infra').sessionKeys;
    var LE2ProxyFactory = require("src/LE2proxy/LE2ProxyFactory");

    const { buildRoutes } = require('./vrouter');
    const { environmentActionTypes, storeModuleTypes,  environmentStateKeys } = require('vue-infra');


    var LEApplication = Marionette.Application.extend({
        doSustain: function(){
            this.sessionManager.sustain({async: false});
        },
        create: function (options) {
            this._preConfigurationsSettingsInitialize();

            this.setStatus(this.CONST.LE_STATUS.CREATED);
            this._setJQueryConfigurations();
            this._setBackboneConfigurations();
            this._setMarionetteConfigurations();
            this._setLiveEngageConfigurations();
            this._i18nHelpersRegister();
            this._modelValidationRegister();
            this._setMainRegion(options.container);
            this._eventHandlersRegister();
            this.setStatus(this.CONST.LE_STATUS.CONFIGURED);
            this.setMTagConfigProperties();

            this._registerMainRouter(options.routeController);
            this.navigator.setUpRoutesGateKeeper();

            // if url contain errors dont update the url with the end point => do logout process
            if(RouteUtils.validateBaseRoutePath()) {
                this._processEntryPointUrl();

                this._buildApplicationLevelMenuItems();

                this._handleNavigationAttempt();

                this.setStatus(this.CONST.LE_STATUS.READY);
            }
        },
        moduleAddDefinition: function (name) {
            var args = [].slice.apply(arguments);
            var customArg = {
                extend: function (obj, staticMembers) {
                    this.extended = obj;

                    if (staticMembers) {
                        // Add the static methods
                        _.each(staticMembers, function (value, key) {
                            this.extended[key] = value;
                        }, this);
                    }
                },
                getExtended: function () {
                    return this.extended;
                }
            };

            args.push(customArg);

            var module = this.module.apply(this, args);

            return customArg && customArg.getExtended() || module;
        },
        setStatus: function (status) {
            this.logger.debug("LE.status set to: " + status);
            this.status = status;
        },
        _preConfigurationsSettingsInitialize: function () {
            // Get needed constants
            this.CONST = CONST;

            // Parse the QueryString for further use
            this.queryString = Navigator.parseQueryString(false);

            // version const assigned via define plugin in webpack conf
            this.version = LE_VERSION;

            // Initialize the logger
            this._setLoggerInitialValues();
        },
        _displayUserInformation: function () {
            this.logger.info("Login Time: " + Date(this.sessionManager.getServerLoginTime()));
            this.logger.info("AccountID: " + this.sessionManager.getAccountId());
            this.logger.info("LoginName: " + this.sessionManager.getLoginName());
            this.logger.info("Timezone: " + this.sessionManager.getServerTimeZoneID());
            this.logger.info("UserAgent: " + Navigator.getUserAgent());
        },
        _setJQueryConfigurations: function () {
            var requestTimeout = 30 * 1000;

            // Configure ajax calls to be non cached by default
            //YN: adding default for jquery ajax to not cache requests
            $.ajaxSetup({cache: false, timeout: requestTimeout, retries: 0});
        },
        _setBackboneConfigurations: function () {
            // Since using PUT and DELETE as HTTP verbs bothers us,
            // Backbone allows us to force a POST (with the needed headers) by adding Backbone.emulateHTTP = true;
            // See http://backbonejs.org/#Sync-emulateHTTP for API details.
            Backbone.emulateHTTP = true;
        },
        _setMarionetteConfigurations: function () {
            // Implement extend on the module level
            Marionette.Module.prototype.extend = function (methods) {
                if (methods) {
                    _.each(methods, function (method, methodName) {
                        this[methodName] = method;
                    }, this);
                }

                return this;
            };
        },
        registerTokensAsHeader: function(options) {
            /*jshint validthis:true */
            TransportPrefiltersAdapter.registerTokensAsHeader.call(this, options);
        },
        registerTokensAsParam: function(options) {
            /*jshint validthis:true */
            TransportPrefiltersAdapter.registerTokensAsParam.call(this, options);
        },
        registerStatusCodeHandler: function(options) {
            /*jshint validthis:true */
            TransportPrefiltersAdapter.registerStatusCodeHandler.call(this, options);
        },
        unregisterTokensAsHeader: function() {
            /*jshint validthis:true */
            TransportPrefiltersAdapter.unregisterTokensAsHeader.call(this);
        },
        unregisterTokensAsParam: function() {
            /*jshint validthis:true */
            TransportPrefiltersAdapter.unregisterTokensAsParam.call(this);
        },
        unregisterStatusCodeHandler: function() {
            /*jshint validthis:true */
            TransportPrefiltersAdapter.unregisterStatusCodeHandler.call(this);
        },
        _setLiveEngageConfigurations: function () {
            // Listen to a request on the events bus to create and get a module
            var that = this;
            this.reqres.setHandler("application:module:get", function (options) {
                return that.module.call(that, options.moduleName, options.callback);
            });
            this.reqres.setHandler("application:modules:get", function (options) {
                return that.submodules;
            });


            // Attach some services to the LE object that modules can access
            this.notifier = new Notifier(CONST.MODULES.FRAMEWORK, CONST.APP_ROOT_CONTAINER, CONST.APP_ROOT_CONTAINER);
            this.translator = Translator;
            this.translator.setUseFallbackDictionary(LEConfig.useFallBackDicitionary);
            this.localeResolver = LocaleResolver;
            this.localeResolver.setLocale(this._getInitialConfigurationValue("lang"));

            this.urlResolver = LEResourceResolver;
            this.context = new Context({}, {application: this});

            this.utilities = registerUtils;
            this.comps = registerCoreComponent;
            this.dataModels = registerBaseDataModel;

            this.media = Media;
            this.mediaCodes = registerMediaCodes;
            this.authority = Authority;

            // Initialize the Session Manager which manages the authentication with the messaging layers of the application
            const appServerResource = LEResourceResolver.getUrl(LEResourceResolver.apiResources.AppServer.Session.RealTime);
            const ACResource = LEResourceResolver.getUrl(LEResourceResolver.apiResources.AccountConfig.Session.RealTime);
            // eslint-disable-next-line

            this.sessionManager = sessionManager.initialize({
                LEConfig,
                PRIVILEGES,
                translator: this.translator,
                logger: this.logger,
                configOptions: {
                    BlacklistCodes: LEConfig.Session.BlacklistCodes,
                    FaultlistCodes: LEConfig.Session.FaultlistCodes,
                    MaxFaultTolerance: LEConfig.Session.MaxFaultTolerance,
                    KeepAliveInterval: LEConfig.Session.KeepAliveInterval,
                    AppServerKeepAliveInterval: LEConfig.Session.AppServerKeepAliveInterval,
                    WarnBeforePeriod: LEConfig.Session.WarnBeforePeriod,
                    LeServer: LEConfig.Session.LeServer,
                },
                accountId: LEResourceResolver.getCurrentAccountId(),
                ACResource: ACResource,
                appServerResource: appServerResource,
                AppServerSessionModel: AppServerSessionModel,
                events: eventsEngine,
            });

            if ('localStorage' in window && window.localStorage !== null) {
                $(window).on('storage', (e) => {
                    this.sessionManager.validateLoginOwnership(this.sessionManager, e.originalEvent);
                });
            }

            this.sessionManager.registerEvent('session:onSessionStatePopupShow', (data) => {
                if (!this.notification) {
                    // Show notification for session about to expire
                    this.notification = this.notifier.showGlobalConfirm(data.notification);
                }
            });

            this.sessionManager.registerEvent('session:onSessionStatePopupClean', () => {
                if (this.notification) {
                    this.notification.close();
                    delete this.notification;
                }
            });

            this.sessionManager.registerEvent('session:sendLoginCommand', (data) => {
                this.commands.execute(`navigate:${data.command}`, data.options);
            });

            this.reqres.setHandler('session:isAuthenticated', _.bind(() => {
                return this.sessionManager.isAuthenticated.call(this.sessionManager);
            }, this));

            // Listen to a command on the events bus to expire and logout
            this.commands.setHandler('session:expire', _.bind((_options) => {
                this.sessionManager.expire.call(this.sessionManager, _options);
            }, this));

            TransportPrefiltersAdapter.preparePrefilters(this);

            // Initialize the Module Manager which manages the modules lifecycle with the messaging layers of the application
            this.moduleManager = ModuleManager.initialize({
                vent: this.vent,
                commands: this.commands,
                reqres: this.reqres,
                notifier: Notifier
            });

            this.DomEvents = DomEvents;

            this.rtDataManager = RtDataManager.initialize();

            this.tooltip = Tooltip;
            //    this.tooltip = Tooltip.initialize({Host: LE});
            // set the language into the context
            //            this.context.set("user.language", this.localeResolver.getLocale());

            this.userBarMenu = BarMenu;

            // Configure the CSRF token to be added to all requests
            // The following configurations are defaults, and can
            // be overridden at the API configuration level for each API
            this.registerTokensAsHeader({
                glob: true,
                csrf: false,
                wsuk: false
            });

            this.registerTokensAsParam({
                glob: false,
                csrf: false,
                wsuk: false
            });

            // Set the handlers for 401/403 responses
            this.registerStatusCodeHandler({
                "401": true,
                "403": false
            });

            // open KPs externally instead of in CP (like learn more and stuff)
            if (LEConfig.enableExternalKnowledgeCenterKpLinks) {
              this.vent.on('openKP', function (options) {

                var template = LEConfig.externalKnowledgeCenterLinkTemplate || '';
                var kpID = options && options.fragment;

                if (kpID && kpID.startsWith('_DEV_COMMUNITY_')) {
                  template = LEConfig.externalDevelopersCommunityLinkTemplate || '';
                  kpID = kpID.replace('_DEV_COMMUNITY_', '');
                }

                const link = template.replace('{{kpID}}', kpID);
                that.navigator.open(link, '_blank');
              });
            }

            this.vent.on("module:touch-session", function (options) {
                if (true === that.context.get(options.moduleName + ".isVisible")) {
                    that.sessionManager.touched();
                }
            });

            this.vent.on("module:revalidate-session", function (options) {
                that.sessionManager.sustain();
            });

            this.vent.on("module:expire-session", function (options) {
                if (true === that.context.get(options.moduleName + ".isVisible")) {
                    that.sessionManager.expire(options);
                }
            });

            this.vent.on("module:logout", function (options) {
                if (true === that.context.get(options.moduleName + ".isVisible")) {
                    that.logout(options.prompt, options.options);
                }
            });

            // Listen to authentication errors from modules
            this.vent.on("module:auth-error", function (options) {
              if (!_.isUndefined(options.errorCode) && !_.isUndefined(LOGIN_ERROR_TO_AUTH_ERROR_MAP[options.errorCode])) {
                that.sessionManager.expire(options);
              }
            });

            this.vent.on("route:unauthorized", function (data) {
                if (data && data.isFirstNavigate) {
                    that.router.push(LEConfig.defaultRoute); //TODO: consider navigating to account default route from ac site settings
                }
                that.notifier.showGlobalError({
                    title: that.translator.translate("LEFramework.unauthorized.title"),
                    content: that.translator.translate("LEFramework.unauthorizedRoute.content"),
                    removePrimaryButton: true
                });
            });
        },
        _i18nHelpersRegister: function () {
            var that = this;

            // We add a template helpers to marionette base view. Since marionette serializes the model data before rendering
            // we cannot put our magic function on the view and instead marionette gives us the ability to set template helpers
            // On the view itself (which is even cleaner than the first approach since translations should be
            // Under the view responsibility (and not the model).
            // Allowing the view to also supply template helpers by calling it customTemplateHelpers and merging it to the framework helpers
            Marionette.View.prototype.templateHelpers = function () {
                //        this.logger.info("Check if there are custom template helpers defined on the view");
                var customTemplateHelpers = this.customTemplateHelpers;
                if (_.isFunction(customTemplateHelpers)) {
                    customTemplateHelpers = customTemplateHelpers.call(this);

                }

                // get the current view, we will check on the view if the 'getLocale' method is implemented
                var view = this;
                var templateHelpers = {
                    _i18n: function () {
                        return function (text) {
                            var defaultLocale = that.localeResolver.getLocale();
                            var locale;

                            if (this.originalView) {
                                locale = _.result(this.originalView(), "getLocale");
                            }
                            if (!_.isString(locale) || _.isEmpty(locale) || !_.has(that.CONST.LANGUAGE, locale)) {
                                locale = defaultLocale;
                            }
                            // I would like to support two options here:
                            // 1) The key to translate is dependent to the model e.g. LEExample.studio.availabilityStates.{{name}}
                            // 2) The translated text is dependent to the model e.g. "My name is {{username}}"
                            // This is the reason for calling the renderer twice (on the key and on the translation)
                            return Mustache.render(that.translator.translate(Mustache.render(text, this), locale), this);
                        };
                    },
                    originalView: function () {
                        return view;
                    }
                };

                if (customTemplateHelpers) {
                    //            this.logger.info("Merge the framework template helpers with the custom template helpers defined on the view");
                    templateHelpers = _.extend(customTemplateHelpers, templateHelpers);
                }

                //        this.logger.info("Return our template helpers");
                return templateHelpers;
            };
        },
        _modelValidationRegister: function () {
            var that = this;

            var isValid = Backbone.Model.prototype.isValid;
            BackboneValidation.mixin.isValidAttr = function (attr) {
                return isValid.call(this, attr);
            };

            _.extend(Backbone.Model.prototype, BackboneValidation.mixin);

            var required = Backbone.Validation.validators.required;
            var min = Backbone.Validation.validators.min;
            var max = Backbone.Validation.validators.max;
            var range = Backbone.Validation.validators.range;
            var length = Backbone.Validation.validators.length;
            var minLength = Backbone.Validation.validators.minLength;
            var maxLength = Backbone.Validation.validators.maxLength;
            var rangeLength = Backbone.Validation.validators.rangeLength;
            var oneOf = Backbone.Validation.validators.oneOf;
            var equalTo = Backbone.Validation.validators.equalTo;
            var pattern = Backbone.Validation.validators.pattern;

            _.extend(Backbone.Validation.validators, {
                // Required validator
                // Validates if the attribute is required or not
                required: function (value, attr, requiredVal, model, computed) {
                    requiredVal = _.isFunction(requiredVal) ? requiredVal.call(model, value, attr, requiredVal, model, computed) : requiredVal;

                    return required.call(this, value, attr, requiredVal, model, computed);
                },

                // Min validator
                // Validates that the value has to be a number and equal to or greater than
                // the min value specified
                min: function (value, attr, minValue, model) {
                    minValue = _.isFunction(minValue) ? minValue.call(model, value, attr, minValue, model) : minValue;

                    return min.call(this, value, attr, minValue, model);
                },

                // Max validator
                // Validates that the value has to be a number and equal to or less than
                // the max value specified
                max: function (value, attr, maxValue, model) {
                    maxValue = _.isFunction(maxValue) ? maxValue.call(model, value, attr, maxValue, model) : maxValue;

                    return max.call(this, value, attr, maxValue, model);
                },

                // Range validator
                // Validates that the value has to be a number and equal to or between
                // the two numbers specified
                range: function (value, attr, rangeVal, model) {
                    rangeVal = _.isFunction(rangeVal) ? rangeVal.call(model, value, attr, rangeVal, model) : rangeVal;

                    return range.call(this, value, attr, rangeVal, model);
                },

                // Length validator
                // Validates that the value has to be a string with length equal to
                // the length value specified
                length: function (value, attr, lengthVal, model) {
                    lengthVal = _.isFunction(lengthVal) ? lengthVal.call(model, value, attr, lengthVal, model) : lengthVal;

                    return length.call(this, value, attr, lengthVal, model);
                },

                // Min length validator
                // Validates that the value has to be a string with length equal to or greater than
                // the min length value specified
                minLength: function (value, attr, minLengthVal, model) {
                    minLengthVal = _.isFunction(minLengthVal) ? minLengthVal.call(model, value, attr, minLengthVal, model) : minLengthVal;

                    return minLength.call(this, value, attr, minLengthVal, model);
                },

                // Max length validator
                // Validates that the value has to be a string with length equal to or less than
                // the max length value specified
                maxLength: function (value, attr, maxLengthVal, model) {
                    maxLengthVal = _.isFunction(maxLengthVal) ? maxLengthVal.call(model, value, attr, maxLengthVal, model) : maxLengthVal;

                    return maxLength.call(this, value, attr, maxLengthVal, model);
                },

                // Range length validator
                // Validates that the value has to be a string and equal to or between
                // the two numbers specified
                rangeLength: function (value, attr, rangeLengthVal, model) {
                    rangeLengthVal = _.isFunction(rangeLengthVal) ? rangeLengthVal.call(model, value, attr, rangeLengthVal, model) : rangeLengthVal;

                    return rangeLength.call(this, value, attr, rangeLengthVal, model);
                },

                // One of validator
                // Validates that the value has to be equal to one of the elements in
                // the specified array. Case sensitive matching
                oneOf: function (value, attr, values, model) {
                    values = _.isFunction(values) ? values.call(model, value, attr, values, model) : values;

                    return oneOf.call(this, value, attr, values, model);
                },

                // Equal to validator
                // Validates that the value has to be equal to the value of the attribute
                // with the name specified
                equalTo: function (value, attr, equalToVal, model, computed) {
                    equalToVal = _.isFunction(equalToVal) ? equalToVal.call(model, value, attr, equalToVal, model) : equalToVal;

                    return equalTo.call(this, value, attr, equalToVal, model);
                },

                // Pattern validator
                // Validates that the value has to match the pattern specified.
                // Can be a regular expression or the name of one of the built in patterns
                pattern: function (value, attr, patternVal, model) {
                    patternVal = _.isFunction(patternVal) ? patternVal.call(model, value, attr, patternVal, model) : patternVal;

                    var patternObj = Backbone.Validation.patterns[patternVal] || patternVal;
                    var newVal = value;

                    if (patternObj && true === patternObj.xRegExp) {
                        patternVal = patternObj.pattern;
                        newVal = {
                            toString: function () {
                                return {
                                    match: function (xPattern) {
                                        return XRegExp.call(this, xPattern).test(value);
                                    }
                                };
                            }
                        };
                    } else if (patternObj && patternObj instanceof XRegExp) {
                        newVal = {
                            toString: function () {
                                return {
                                    match: function (xPattern) {
                                        return patternObj.test(value);
                                    }
                                };
                            }
                        };
                    }

                    return pattern.call(this, newVal, attr, patternVal, model);
                },

                pathUrl: function (value, attr, pathUrlVal, model) {
                    pathUrlVal = _.isFunction(pathUrlVal) ? pathUrlVal.call(model, value, attr, pathUrlVal, model) : pathUrlVal;
                    //this.logger.debug("pathUrl: value="+value+" pathUrlVal="+pathUrlVal);
                    this.pattern(pathUrlVal, attr, "url", model);
                },

                customValidator: function (value, attr, customValidator, model) {
                    if (!_.isFunction(customValidator)) {
                        //this.logger.error("LE.customValidator must be a function: ");
                        return;
                    }
                    customValidator = customValidator.call(model, value, attr, customValidator, model);

                    return oneOf.call(this, customValidator, attr, [true], model);
                }
            });

            _.extend(Backbone.Validation.patterns, {
                safeString: {
                    xRegExp: true,
                    pattern: "^[\\p{L}\\p{Nd},\\-\\+\\_();:\\.\\,\\s\\+]+$"
                    //pattern:"^[\\p{L}\\p{Nd}\\p{Sc}\\%\\-\\+\\_();:\\.\\,\\'\\?\\!\\s]+$"  //letters, digits, spaces, currency symbols, % - + _ ( ) ; : . , ' ? !
                },
                numberOnly: {
                    xRegExp: true,
                    pattern: "^\\d+$"
                },
                safeStringWithMacro: {
                    xRegExp: true,
                    pattern: "^[\\p{L}\\p{Nd}\\p{Sc}\\%\\{\\}\\-\\+\\_();:\\.\\,\\'\\?\\!\\s]+$"  //letters, digits, spaces, currency symbols, % { }- + _ ( ) ; : . , ' ? !
                },
                elementDomIdSafeString: {
                    xRegExp: true,
                    pattern: "^[\\p{L}\\p{Nd}\\-\\_:\\{\\}]{1,1024}$"
                },
                url: {
                    xRegExp: true,
                    pattern: "^[\\p{L}\\p{Nd}\\-\\.\\?\\,\\:\\'\\/\\\\\\+=&amp;%\\$#_\\!\\*]+$"
                },
                urlWithSpace : {
                    xRegExp : true,
                    pattern : "^[\\p{L}\\p{Nd}\\-\\.\\?\\,\\:\\'\\/\\\\\\+=&amp;%\\$#_\\!\\*\\s]+$"
                },
                fullUrl : /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i, //taken from http://mathiasbynens.be/demo/url-regex (diego perini)
                httpHttps : /^((https?):\/\/)/i,
                httpsOnly : /^((https):\/\/)/i,
                fullHttpsUrl : /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i, //taken from http://mathiasbynens.be/demo/url-regex (diego perini)
                fullLowerCaseHttpsUrl: /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/, //taken from http://mathiasbynens.be/demo/url-regex (diego perini)
                floatNumber : /^[-]?(\d+)?(\d+\.\d+)?$/,
                IPv4 : /^(([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){1}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){2}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
                alphaNumericSpecialCharacters: /^[A-Za-z0-9\s\-\.\?,:'\\/\+=&amp;%@^()\$#_!\*]+$/,
                color: /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i,
                limitedElementDomIdSafeString: {
                    xRegExp: true,
                    pattern: "^[\\p{L}\\p{Nd},\\-\\_]*$"
                },
                certificateCommonName: {
                    xRegExp: true,
                    pattern: "^(\\*\\.)?((?!-)[A-Za-z0-9-]{1,63}\\.)+[A-Za-z]{2,63}$"
                }
                //alphaNumeric: /^[a-zA-Z0-9_-]*$/
            });

            var translateErrorObject = function (errorObject) {
                var translatedObj = {};
                _.each(errorObject, function (value, key, obj) {
                    translatedObj[key] = that.translator.translate(value);
                });
                return translatedObj;
            };

            var delegateEvents = Marionette.View.prototype.delegateEvents;
            Marionette.View.prototype.delegateEvents = function () {
                delegateEvents.apply(this, [].slice.apply(arguments));

                var view = this;
                var viewModel = view.model || view.collection;// || (_.isFunction(view.getViewModel) ? view.getViewModel : void 0);

                if (!_.isEmpty(viewModel)) {

                    view.listenTo(viewModel, "invalid", function (model, errorObject) {
                        if (_.isFunction(view.onInvalid)) {
                            view.onInvalid(model, translateErrorObject(errorObject));
                        }
                    });

                    view.listenTo(viewModel, "validated:invalid", function (model, errorObject) {
                        if (_.isFunction(view.onValidatedInvalid)) {
                            view.onValidatedInvalid(model, translateErrorObject(errorObject));
                        }
                    });

                    view.listenTo(viewModel, "validated:valid", function (model, errorObject) {
                        if (_.isFunction(view.onValidatedValid)) {
                            view.onValidatedValid(model, translateErrorObject(errorObject));
                        }
                    });

                    view.listenTo(viewModel, "error", function (model, errorObject, options) {
                        if (options && options.ignoreError) {
                            return;
                        }
                        var isErrorHandled = false;
                        if (_.isFunction(view.onError)) {
                            isErrorHandled = view.onError(model, errorObject);
                        }

                        var activeModuleName = that.context.get("activeModuleName");

                        if (!isErrorHandled && activeModuleName) {
                            var activeModule = that.module(activeModuleName);
                            if (activeModule && errorObject.responseError && !_.isEmpty(errorObject.responseError.message)) {
                                activeModule.notifier.showError({
                                    content: errorObject.responseError.message,
                                    timeToShow: 6000
                                });
                            }
                        }
                    });
                }
            };
        },

        _loadRoutes: function (){
          const routes = buildRoutes();
          this.loadVueRoutes(routes);
          this.navigator.router.addRoutes(routes);
          this.navigator.routes = this.navigator.routes.concat(routes);
        },

        _addWalkMeSegmentsToWindow(){
            // check if LiveEngage_WalkMe_Tag exist in session.
            if(this.sessionManager.getFeaturePropertyState('Common.LiveEngage_WalkMe_Tag')){
                const accountId = LEResourceResolver.getCurrentAccountId();
                const locale = this.localeResolver.getLocale();
                const userRole = this.sessionManager.getPermissionGroupByPrivilege();
                const hasAsyncMessagingFeature = this.sessionManager.hasAsyncMessagingFeature();
                const userId = this.sessionManager.getUserId();
                const isFirstLoginForAccount = this.firstTimeLogin;

                window.le_walkme_segment = {
                    locale,
                    accountId,
                    userRole,
                    hasAsyncMessagingFeature,
                    userId,
                    isFirstLoginForAccount
                };
            }
        },

        _eventHandlersRegister: function () {
            /*
             add initializers that will run when the application is started
             */
            this.on("initialize:before", function () {

            });

            /*
             add initializers that will run when the application is started
             */
            this.on("initialize", function () {
                this.setStatus(this.CONST.LE_STATUS.INITIALIZED);
            }, this);

            this.on("start", function () {
                this.setStatus(this.CONST.LE_STATUS.STARTED);

                // load routes
                this._loadRoutes();

                // Start the application main view controller
                new LiveEngageLayoutController({ application: this }).start();

                this._addWalkMeSegmentsToWindow();

                setTimeout(function() {
                  try {
                    // set user assistance controller
                    this.userAssistanceController = new UserAssistanceController({
                      application: this,
                      vent: this.vent,
                      translator: this.translator,
                      isFirstTimeLogin: this.isFirstTimeLogin(),
                      sessionManager: this.sessionManager,
                      persistenceManager: this.persistenceManager,
                    });
                  } catch (e) {
                    this.logger.error(`fail to handle routes, ${JSON.stringify(e)}`);
                  }
                }.bind(this), 0);
            }, this);

            // add callback to run when module is started after initializer have run
            // IU would like to initialize the module router at this point
            this.vent.on("module:started", function (options) {
                var module = this.module(options.moduleName);

                // Small validity check (to see that the module was initialized by the application and it has a route controller)
                if (module.moduleName === options.moduleName && _.isString(module.routePrefix) && module.moduleName !== "LEReporting" && _.isFunction(module.getRouteController)) {
                    var ctrl = module.getRouteController();

                    // represents if the router should ignore privileges array on the route - according to profile AC feature
                    var isPrivilegesIgnored = !this.sessionManager.hasProfilesFeature();

                    // Initialize the module router
                    module.router = this.navigator.getRouter(module.moduleName, module.routePrefix, ctrl, isPrivilegesIgnored);

                    // parse the redirects
                    if (this.mediaCodes[module.appId] && this.mediaCodes[module.appId].redirects) {
                        _.each(this.mediaCodes[module.appId].redirects, function (to, from) {
                            module.channel.comply("redirect:" + to, function () {
                                module.router.navigate(to); // TODO: update it according to the single router
                            });
                        }, this);
                    }
                }
            }, this);
        },
        _setMainRegion: function (container) {
            // set the main region of the application
            this.addRegions({
                mainRegion: container
            });
        },
        _registerMainRouter: function (routeController) {
            routeController.initialize({
                AppServeresources: LEResourceResolver.apiResources.AppServer,
                calculateUrlFn: LEResourceResolver.getUrl
            });

            gChannel.register('LiveEngageCommon', 'infra_router:doLogin', function(url) {
                this.moduleManager.stopAllModules(function() {
                    this.isSilentLeave = true;
                    this.navigator.navigate(name, url, { redirect: true });
                }.bind(this));
            }.bind(this));

            gChannel.register('LiveEngageCommon', 'infra_router:doNavigateToDefaultRoute', function() {
               this.navigateToEntryPointUrl();
            }.bind(this));

            this.routes = routeController.navigation.routes;
            this.router = routeController.Router;
            this.routeController = routeController;

            // Initialize the navigator with the messaging layers of the application
            this.navigator = Navigator.initialize({
                vent: this.vent,
                commands: this.commands,
                reqres: this.reqres,
                context: this.context,
                routes: this.routes,
                router: this.router
            });

            // Create the main application router
            this.router = this.navigator.router;
        },

        _processEntryPointUrl: function () {
            // Get + Parse the current hash fragment in order to use it later (after the application had been started and the routers had been created
            //var fragment = this.sessionManager.isAuthenticated() ? "" : this.navigator.getFragment() || ""; // This check should always returned false for isAuthenticated indication - just for debug. TODO: remove when login integration completed
            // Lets parse the entry point URL fragment for later use
            var fragment = this.navigator.getFragment() || "";
            var accountId = LEResourceResolver.getCurrentAccountId();
            this.loginInfo = RouteUtils.parseLoginFragment({ fragment, accountId });
            store.dispatch(`${ENVIRONMENT}/${OUTER_ROUTE_UPDATE}`, this.loginInfo.outerRoute);
            this.isNewUrlAccountIdPlaceHolder = this.sessionManager.parseUrlNewAccountIdPlaceHolder();
            // Just remove un-needed garbage from the url
            //this.navigator.removeQueryString();

            this.navigator.removeFragment({replace: true});
        },
        navigateToEntryPointUrl: function (options) {

            var router = this.navigator.router;
            var route;

            options = options || {};


            // Now route to the right place
            // Validate the persona and make default fixes if needed
            if (this.isValidLoginInfoPersona(options)) {
                this.context.set("persona", this.loginInfo.persona);
                if (_.isUndefined(this.loginInfo.module)) {
                    this.loginInfo.module = this.moduleManager.resolveModuleName(this.loginInfo.persona);
                }
                if (_.isUndefined(this.loginInfo.prefix)) {
                    this.loginInfo.prefix = this.moduleManager.resolveModuleRoutePrefix(this.loginInfo.persona);
                }
                route = this.loginInfo.view;
            } else if (_.isUndefined(this.loginInfo.module) || (_.isEmpty(this.loginInfo.module) && !_.isEmpty(this.loginInfo.prefix))){
                // Could not find by persona, probably the route is for a module with no persona mapping
                // Try resolving module by route prefix
                this.loginInfo.module = this.loginInfo.prefix && this.moduleManager.resolveModuleNameByPrefix(this.loginInfo.prefix);
                // Get the needed module router
                if (_.isUndefined(this.loginInfo.module)) {
                    // Fallback to default
                    route = LEConfig.defaultRoute;
                }
            } else {
                route = RouteUtils.calculateDefaultRouteByRole();
                LEConfig.defaultRoute = route;
            }

            // Navigate
            if (router && !this.silent) {
                this.navigator.navigate(this.loginInfo && this.loginInfo.module, route || "", {replace: true, nonBackable:true});
            }
        },
        isValidLoginInfoPersona: function (options) {
            var isValid = this.loginInfo && this.loginInfo.fixedRoute && !this.silent && !options.ignoreLoginInfo;
            if (!isValid) {
                this.logger.debug("can not create router from login info");
                return isValid;
            }

            isValid = (this.loginInfo.persona && this.moduleManager.hasPersona(this.loginInfo.persona));
            if (!isValid) {
                this.logger.debug("can not create router from login due to invalid persona");
                return isValid;
            }

            this.logger.debug("router from login can be created");
            return isValid;
        },
        startHistory: function () {
            // Start the history
            if (!Backbone.History.started) {
                Backbone.history.start();
            }
        },
        isFirstTimeLogin: function () {
            return this.loginInfo.firstTimeLogin || "true" === this.getQueryStringParameterValue("firstTimeLogin");
        },
        getQueryStringParameterValue: function (name, defaultValue) {
            var param = defaultValue;
            if (this.queryString) {
                param = this.queryString[name];
                if ((!_.isString(param) || 0 === param.length)) {
                    param = defaultValue;
                }
            }

            return param;
        },
        _getInitialConfigurationValue: function (name, defaultValue) {
            var variable = this.getQueryStringParameterValue(name);
            if (_.isUndefined(variable)) {
                variable = lpStorage.localStorage(name);
                if ((!_.isString(variable) || 0 === variable.length)) {
                    variable = defaultValue;
                }
            }

            return variable;
        },
        resolvePermissionGroup: function () {
            var isAdmin;
            var isLPA = this.sessionManager.isLPA();
            if (isLPA) {
                return PERMISSION_GROUP.LPA;
            }

            isAdmin = this.sessionManager.isAdmin();
            if (isAdmin) {
                return PERMISSION_GROUP.ADMIN;
            }

            return this.sessionManager.getPermissionGroupByPrivilege();
        },
        authenticate: function (options) {
            this.startHistory();

            var that = this;
            var params = _.extend({}, options);
            var success = params.success;

            var registrationHandler = function () {
                // now we can display user information for the authenticated user
                that._displayUserInformation();
                // now we set user information for the authenticated user in the logger for future use
                that.logger.setAdditionalInfo({
                    accountId: that.sessionManager.getAccountId(),
                    loginName: that.sessionManager.getLoginName(),
                    loginTime: Date(that.sessionManager.getServerLoginTime())
                });

                // Now we can register the modules for the authenticated user
                that.moduleManager.registerModules();

                if (success) {
                    success.apply(that, [].slice.apply(arguments));
                }
            };

            params.success = async function () {
                if (!that.isNewUrlAccountIdPlaceHolder && that.sessionManager.isAuthenticated()) {
                    // Initialize the site settings
                    that.siteSettings = SiteSettings.initialize({LE: that});

                    that.navigator.setCurrentPrivileges(that.sessionManager.getPrivileges());

                    // Check for existence of SSO Logout URL
                    var ssoUrl = that.sessionManager.getSSOLogoutUrl();
                    if (_.isString(ssoUrl) && 0 < ssoUrl.length) {
                        // Add a Resource for logoutUrl to represent the SSO Logout URL
                        // that.urlResolver.addLocalApiResource("LogoutUrl", {url: ssoUrl});
                    }

                    // Add the account Id as a config value to the Url resolver for the parsing of API urls
                    that.urlResolver.addConfigValue("accountId", that.loginInfo.accountId || that.sessionManager.getAccountId());
                    that.urlResolver.addConfigValue("userName", that.sessionManager.getLoginName());
                    that.urlResolver.addConfigValue("userId", that.sessionManager.getUserId());
                    that.urlResolver.addConfigValue("wsuk", that.sessionManager.getWsuk());

                    // Add the services base Uri's as config values to the Url resolver for the parsing of API urls
                    //                    var loc = !_.isUndefined(window) ? window.location : document.location;
                    //                    var protocol = loc.protocol + "//";
                    var uris = that.sessionManager.getBaseUris();
                    if (!_.isUndefined(uris)) {
                        _.each(uris, function (uri, index) {
                            that.urlResolver.addConfigValue(uri.service, uri.baseURI);
                        });
                    }

                    // for social-connect enabled accounts, we need to use alternate facadeMsg and msgHist domains.
                    // these are empty by default, but will be set for social-connect enabled accounts.
                    if (that.sessionManager.getFeaturePropertyState(CONST.FEATURES.SOCIAL_CONNECT)) {
                      _.each({ facadeMsg: 'socialMsgDomain', msgHist: 'socialMsgHistDomain' }, function (repl, domain) {
                        var val = that.urlResolver.getConfigValue(repl);
                        if (val) {
                          that.urlResolver.addConfigValue(domain, val);
                        }
                      });
                    }

                    let user = {};
                    const config = store.getters[`${SESSION}/${GET_CONFIG}`]();
                    user.data = { loginName: config.loginName, id: config.userId };

                    if( !that.sessionManager.isLPA() && (CONST.SOURCE_ENUM.LEGACY !== LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.USERS_SOURCE))) {
                      const UsersResourceParts = LEResourceResolver.getUrl(LEResourceResolver.apiResources.AccountConfig.UsersWithProfiles).split('?');

                      const baseUsersClient = new BaseClient({
                        allowedMethods: ['get'],
                        config: {
                          baseURL: UsersResourceParts[0],
                        },
                      });
                      try {
                        const tempUser = await baseUsersClient.get(`${ config.userId }`, { params: UsersResourceParts[1] });
                        if(tempUser && tempUser.data && tempUser.data.id){
                          user = tempUser;
                        }
                      }
                      catch(e) {
                        this.logger.error(`error: ${e} user not exist in AC_users, taking details from session`);
                      }
                      store.dispatch(`${ENVIRONMENT}/${environmentActionTypes.LOGGED_IN_USER_DETAILS_UPDATE}`, user.data);
                    }

                    const applicationGeneralSettings = await ApplicationGeneralSettings.getAndInitApplicationGeneralSettings();
                    that.firstTimeLogin = applicationGeneralSettings.isFirstTimeLogin;
                    if (LE.isFirstTimeLogin()) {
                        ContentSettings.updateContentSettings();
                    }

                    that.permissionGroup = that.resolvePermissionGroup();
                    if (LE.sessionManager.getAccountSettingValueByID('common.legacyAgentState') === 'false') {
                        if (LEConfig.__isAgentStateIsDecoupleFromDenver) {
                            new AgentStatusManager();
                        } else {
                            new AgentStatusManagerOld();
                        }
                    } else {
                        new AgentStatusManagerOld();
                    }
                }

                that.translator.loadLocale(that.localeResolver.getLocale(), registrationHandler);
            };

            if (!this.sessionManager.isAuthenticated() && !this.isNewUrlAccountIdPlaceHolder && !this.silent) { // && (this.sessionManager.PERSONA.GUEST !== this.loginInfo.persona)) { // || this.sessionManager.PERSONA.GUEST !== this.sessionManager.getActivePersona())) {
                // We will give it
                // A call back when we know what the auth status is
                params[this.sessionManager.KEY.ACCOUNT_ID] = this.loginInfo && this.loginInfo.accountId;
                params[this.sessionManager.KEY.OTK] = this.loginInfo && this.loginInfo.otk;
                params[this.sessionManager.KEY.PERSONA] = this.loginInfo && this.loginInfo.persona;

                const accountId = (this.loginInfo && this.loginInfo.accountId) || LEResourceResolver.getCurrentAccountId();
                var isNewLogin = this.getCookie('newLoginFlow') === 'true' || this.getCookie(`newLoginFlow-${accountId}`) === 'true';
                if (isNewLogin) {
                  const ACResource = LEResourceResolver.getUrl(LEResourceResolver.apiResources.AccountConfig.SessionV2.RealTime);
                  this.sessionManager.setBaseClientBaseUrl(ACResource.replace('{accountId}', LEResourceResolver.getCurrentAccountId()));
                }
                this.sessionManager.getAuthentication(params);
            } else {
                // register the modules for the authenticated user
                // Now load the modules
                if (params.success) {
                    params.success.apply(this, [].slice.apply(arguments));
                }
            }
        },
        getCookie: function(name) {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) {
            return parts.pop().split(';').shift();
          }
        },
        _crossChannel: function (moduleId, action, message) {
            // Fetch module
            var moduleConfig = this.moduleManager.resolveModuleNameById(moduleId);
            var moduleName = moduleConfig && moduleConfig.moduleName;
            var module = moduleName && this.submodules[moduleName];

            // Build arguments for method
            var args = [];

            if (module) {
                if (3 < arguments.length) {
                    args = [].slice.call(arguments, 3);
                }

                args.unshift(message);

                // Send on channel
                return module.channel[action] && module.channel[action].apply(this, args);
            }
        },

        _crossRedirect: function (moduleId, redirect) {
            // Build arguments for method
            var args = [];

            if (2 < arguments.length) {
                args = [].slice.call(arguments, 2);
            }

            // Create a redirect command
            args.unshift("redirect:" + redirect);
            args.unshift(Media.actions.command);
            args.unshift(moduleId);

            // Invoke the cross channel
            return this._crossChannel.apply(this, args);
        },

        crossModule: function (moduleId, actionOrRedirect, message) {
            var args = [].slice.apply(arguments);

            // Check whether I've a mean of communication
            if (Media.actions[actionOrRedirect]) {
                return this._crossChannel.apply(this, args);
            }
            else if (!message) {
                // No message, it is a redirect
                return this._crossRedirect.apply(this, args);
            }
            else {
                // No action, lets set a default to trigger
                args.splice(1, 0, Media.actions.trigger);
                return this._crossChannel.apply(this, args);
            }
        },

        _buildApplicationLevelMenuItems: function () {
            this.menuItems = {};
        },

        getLPComMyAccountBillingUrl: function () {
            var myAccountBillingUrl = LEConfig.Billing.LPComMyAccountUrl.replace("{accountId}", LE.sessionManager.getAccountId());
            return myAccountBillingUrl;
        },

        getUpgradeBillingUrl: function () {
            var upgradeBillingUrl = LEConfig.Billing.LPComRegisterUrl.replace("{accountId}", LE.sessionManager.getAccountId());
            return upgradeBillingUrl;
        },

        getUpdateDetailsBillingUrl: function () {
            return LEResourceResolver.getUrl(LEResourceResolver.apiResources.AppServer.SSPLogin);
        },

        triggerTrialTrayItem: function () {
            if (this.menuItems && this.menuItems.trialAccount) {
                this.vent.trigger("triggerTrayItem", this.menuItems.trialAccount);
            } else {
                var activeModuleName = this.context.get("activeModuleName");
                var activeModule = activeModuleName ? this.module(activeModuleName) : this;
                activeModule.notifier.showError({
                    content: LE.translator.translate("LEFramework.userBarMenu.menuItemTray.AgentManager.AddSeat"),
                    timeToShow: 6000
                });
            }
        },

        setLoggerState: function () {
            this.logger.setLoggerState(!this.logger.getLoggerState());
            if (this.logger.getLoggerState()) {
                this.menuItems.debug.set("description", this.translator.translate("LEFramework.userBarMenu.menuItem.debug.description.off"));
            } else {
                this.menuItems.debug.set("description", this.translator.translate("LEFramework.userBarMenu.menuItem.debug.description.on"));
            }
        },

        logout: function (prompt, options) {
            if (true === prompt) {
                this._handleSignOutAttempt(options);
            } else {
                this._doSignOut(options);
            }
        },

        _closeAllChildWindows: function () {
            if (this.navigator) {
                this.navigator.closeAll();
            }
        },

        _handleNavigationAttempt: function () {
            var that = this;
            // calling "one" instead of "on" and then re-calling myself - to fix Chrome issue. see task LE-8314.
            $(window).one("beforeunload", function (e) {
                if (!that.isSilentLeave && e.originalEvent) {
                    var message;
                    var defaultMessage = that.translator.translate("LEFramework.leaveConfirmation.title");

                    var leaveMessage = that._getLeaveAttemptMessage.call(that, false);
                    if (!_.isEmpty(leaveMessage)) {
                        message = defaultMessage + "\r\n" + leaveMessage;
                    } else if (that.sessionManager.isAuthenticated()) {
                        message = defaultMessage + "\r\n" + that.translator.translate("LEFramework.leaveConfirmation.message");
                    }

                    //                    that._closeAllChildWindows();
                    _.defer(function () {
                        that._handleNavigationAttempt();
                    });

                    return message;
                }

                //              that._closeAllChildWindows();
            });
        },

        _notifySignOut: function () {
            _.each(this.submodules, function (module, moduleName) {
                module.trigger("signout");
            }, this);
        },

        _handleSignOutAttempt: function (options) {
            var confirmationText = this._getLeaveAttemptMessage(true);
            var opts = $.extend(true, {signOut: true}, options);

            if (_.isEmpty(confirmationText)) {
                this._doSignOut(opts);
            } else {
                this.notifier.showGlobalConfirm({
                    title: this.translator.translate("LEFramework.signOutConfirmation.end.user.session.title"),
                    content: confirmationText,
                    objectAction: {
                        text: this.translator.translate("LEFramework.signOutConfirmation.okText"),
                        callback: _.bind(this._doSignOut, this, opts),
                        cancelCallback: function() {
                          gChannel.trigger('LiveEngageCommon', 'logout:cancelled');
                        }
                    }
                });
            }
        },

        _getLeaveAttemptMessage: function (isSignOut) {
            // Go over all modules and ask if to show a confirmation message before logging out (each module might have its one message)
            // if there is at least one - show the messages before logging out
            var moduleConfirmationText;
            var confirmationTexts = [];
            var msg = "";

            _.each(this.submodules, function (module, moduleName) {
                if (module.getLeaveConfirmationText) {
                    moduleConfirmationText = module.getLeaveConfirmationText.call(module, isSignOut);
                    if (_.isString(moduleConfirmationText) && 0 < moduleConfirmationText.length) {
                        confirmationTexts.push(moduleConfirmationText);
                    }
                }
            }, this);
            var confirmationTextsCount = confirmationTexts.length;
            if (confirmationTextsCount === 1) {
                msg = "<span>" + confirmationTexts[0] + "</span>";
            }
            if (confirmationTextsCount > 1) {
                msg = "<ul><li>" + confirmationTexts.join("</li><li>") + "</li></ul>";
            }


            /*
             * Override the logout message from other modules
             * LE-61458
             * */
            msg = this.sessionManager.hasAsyncMessagingFeature() ? this.translator.translate("LEFramework.signOutConfirmation.title") : this.translator.translate("LEFramework.signOutConfirmation.warning.chat");

            return msg;
        },

        _doSignOut: function (options) {
            if (this.sessionManager.getAccountSettingValueByID('common.legacyAgentState') === 'false') {
                if (LEConfig.__isAgentStateIsDecoupleFromDenver) {
                    gChannel.trigger('LiveEngageCommon', 'dologout');
                }
            }
            options = options || {};

            if (options.signOut) {
                this._notifySignOut.call(this);
            }

            _.defer(_.bind(this.sessionManager.expire, this.sessionManager, options));
        },

        _setLoggerInitialValues: function () {
            this.logger = Logger.getLogger("LEFramework");

            var options = {};
            options.enable = this.getQueryStringParameterValue("LELogger");
            options.enable = (options.enable === "true" || options.enable === "false") ? true : options.enable;
            options.context = this.getQueryStringParameterValue("LELoggerContext");
            options.level = this.getQueryStringParameterValue("LELoggerLevel");
            options.trace = this.getQueryStringParameterValue("LELoggerTrace");
            options.trace = (options.trace === "true" || options.trace === "false") ? true : options.trace;

            this.logger.setInitialValues(options);
            this.logger.setAdditionalInfo({appVersion: this.version});
        },

        lpTagAddVar: function (args) {
            window.lpMTagConfig = window.lpMTagConfig || {};
            window.lpMTagConfig.sessionVar = window.lpMTagConfig.sessionVar || [];

            if (_.isArray(args)) {
                for (var i = 0; i < args.length; i++) {
                    window.lpMTagConfig.sessionVar.push(_.escape(args[i].name) + "=" + _.escape(args[i].val));
                }
            } else {
                window.lpMTagConfig.sessionVar.push(_.escape(args.name) + "=" + _.escape(args.val));
            }
        },

        /**
         *
         * @param force     if the call is forced this means we apply whatever state we came up with, paying or trial,
         * without retrying the ajax for a second state querying.
         * @constructor
         */
        BillingInformation: function (force) {
            // paying customer, no need to check again.
            if (( !force && this.context.get("user.isTrialAttrSet") && !this.context.get("user.isTrial") ) ||
                LE.siteSettings.accountSettings.hasHideIsTrial()) {
                return;
            } else {
                // either trial, or login was made before zuora account was created (buy now flow, login is done faster than zuora account creation).
                // get is trial account
                BillingSessionModel.fetchIsTrialDetails({
                    success: _.bind(function (data) {
                        // Add the Trial menu only if the account is trial and user has the correct privileges
                        if (data && data.attributes) {

                            // disregard action on the response and fire the ajax again with a delay
                            // giving the backend account creation process enough time to complete
                            if (!force && data.attributes.trial && this.isFirstTimeLogin()) {
                                setTimeout(function () {
                                    this.LE.BillingInformation(true);// second check - force update
                                }, LEConfig.Billing.trialTimeout * 1000);
                                return;// if trial, wait for second call. if paying or second call, set everything
                            }

                            store.dispatch(`${ENVIRONMENT}/${environmentActionTypes.SET_VALUE}`, {
                                attribute: environmentStateKeys.LE_TRAIL_INFORMATION,
                                value: data.attributes
                            });

                            // Havin got the response make the proper adjustments to LE to reflect the trial|paying modes
                            this.context.set("user.isTrial", data.attributes.trial || false);

                            this.context.set("user.remainingDays", data.attributes.remainingDays || -1);
                            this.addLpTagVarBillingInfoCustomerType(data.attributes);

                            this.context.set("user.isTrialAttrSet", true);
                            this.context.set("user.isTrialAttrSetError", false);
                        }
                    }, this),
                    error: _.bind(function (model, response, options) {
                        //TODO: Incase the isTrial api fails the system will assume not to be in Trial and
                        store.dispatch(`${ENVIRONMENT}/${environmentActionTypes.SET_VALUE}`, {
                            attribute: environmentStateKeys.LE_TRAIL_INFORMATION,
                            value: {}
                        });


                        this.context.set("user.isTrialAttrSetError", true);
                    }, this)
                });
            }
        },

        addLpTagVarBillingInfoTrialDaysLeft: function (options) {
            if (options.remainingDays) {
                this.lpTagAddVar([
                    {name: "TrialDaysLeft", val: options.remainingDays}
                ]);
            }
        },
        addLpTagVarBillingInfoCustomerType: function (options) {
            var customerType = options.trial ? "Trial" : "Paid";
            this.lpTagAddVar([
                {name: "CustomerType", val: customerType}
            ]);
        },
        setMTagConfigProperties: function () {
            this.LPtag_mtaconfig = LEConfig.LPtag_mtaconfig;
            this.LPtag_mtaconfig.unifiedWindow = LEConfig.connectionPanelSettings.unifiedWindow;
        },

        getConfig: function () {
            return _.clone(LEConfig, true);
        },

        // TODO: mng/* navigation not loading a thing
        // TODO: if backbone module load first routing not working.
        loadVueRoutes: function(vueRoutes, controller) {
            //fixme: change to dynamic load... from somewhere !!!!
            const activeModules = ['LEAutomation', 'LEUserManagement', 'LEWebAgent', 'LECampaigns', 'LEManager', 'LEContent'];
            const validModulesPrefixes = store.getters[`${ENVIRONMENT}/${VALID_APPS}`];
            const applicationRoutes = store.getters[`${ENVIRONMENT}/${ROUTES}`];
            const context = this;


            // create routes for all marrionette modules here dynamically
            for (let i = 0; i < validModulesPrefixes.length; i++) {
              const modulePrefix = validModulesPrefixes[i];
              const personaName = LE.moduleManager.prefixMap[modulePrefix];
              const moduleName = LE.moduleManager.moduleNames[personaName].moduleMapping;
              if(!activeModules.includes(moduleName)) {
                continue;
              }

              var module = context.module(moduleName);
              var ctrl = module.getRouteController && module.getRouteController() || context.routeController;
              var vueModuleRouteToUpdate = _.find(vueRoutes, function(route) {
                return route && route.name === moduleName;
              });

              var vueRouteToUpdate;
              if (vueModuleRouteToUpdate) {
                vueModuleRouteToUpdate.children = [];
                vueRouteToUpdate = vueModuleRouteToUpdate.children;
              } else {
                vueRouteToUpdate = vueRoutes;
              }

              var componentRoutes = Object.entries(ctrl.navigation.routes)
                .map(this._componentRouteEntryMapper(moduleName));
              vueRouteToUpdate.push(...componentRoutes);


              if(ctrl.navigation.defaultRoute){
                vueRouteToUpdate.push({
                  name: 'defaultRoute' + vueModuleRouteToUpdate.name,
                  path: vueModuleRouteToUpdate.path,
                  component: { template: '' },
                  redirect: vueModuleRouteToUpdate.path + '/' + ctrl.navigation.defaultRoute
                });

                // default for cases when path is "/um/<inner>/*"
                vueRouteToUpdate.push({
                  name: 'defaultInnerRoute' + vueModuleRouteToUpdate.name,
                  path: vueModuleRouteToUpdate.path + ':pathMatch(\/.*)',
                  component: { template: '' },
                  redirect: vueModuleRouteToUpdate.path + '/' + ctrl.navigation.defaultRoute
                });
              }
            }

            vueRoutes.push(...applicationRoutes);
            // remove all redundant routes. (not valid or unauthorized routes)
            // vueRoutes = vueRoutes.filter((route) => {
            //   const personaName = LE.moduleManager.personaMap[route.name];
            //   const routePrefix = LE.moduleManager.moduleNames[personaName].routePrefix;
            //   return validModulesPrefixes.includes(routePrefix);
            // });
        },

        _componentRouteEntryMapper : function(moduleName) {
          var componentProxy = LE2ProxyFactory.getMarrionetteModuleChildProxy(moduleName);
          return ([key, value]) => ({
            name: value.name,
            path: `${key}`,
            component: componentProxy,
            hasSpecialNavigate: value.hasSpecialNavigate,
            meta: {
              routeControllerCallback: value.callback,
              auth: value.auth,
              marrionetteModule: moduleName,
            }
          });
        },
    });

    return LEApplication;
});
