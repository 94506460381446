define(function (require) {
    "use strict";

    var _ = require('underscore');
    var BaseModel = require("models/BaseModel");

    var KEYS = {
        ID: "id",
        IS_ENABLED: "isEnabled"
    };

    var PermissionModel = BaseModel.extend({
        KEYS: KEYS,
        defaults: {
            id: null,
            isEnabled: false
        },

        initialize: function(attributes, options) {
            options = options || {};
            this._buildModel();

        },
        _buildModel: function() {
            this.unset();
        },
        getId: function() {
            return this.get(KEYS.ID);
        },
        isEnabled: function() {
            return this.get(KEYS.IS_ENABLED);
        },
        setIsEnabled: function(isEnabled) {
            this.valueIsChanged = true;
            this.set(KEYS.IS_ENABLED, isEnabled);
        },
        isValueChanged: function() {
            return this.valueIsChanged;
        }
    });

    return PermissionModel;
});
