/**
 * User: danielda
 */
define(function (require) {
    "use strict";

    var BaseModel = require("models/BaseModel");
    var ApiResources = require("assets/data/apiResources.json");

    var ProvisionModel = BaseModel.extend({
        name: "ProvisionModel",
        resource: ApiResources.CreateAccount.Provision
    });

    return ProvisionModel;
});
