/**
 * Created with IntelliJ IDEA.
 * User: itaik
 * Date: 4/22/13
 * Time: 10:07 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    const _ = require('underscore');
    const $ = require('jquery');
    const Media = require('media');
    const CONST = require('const');
    const { store } = require('vue-infra');
    const { Logger } = require('vue-infra');
    const { sessionManager } = require('vue-infra');
    const { MODULES } = require('vue-infra').environmentGetterTypes;
    const { ENVIRONMENT } = require('vue-infra').storeModuleTypes;

    var ModuleManager = (function () {
        var ver = "0.1";

        var initialize = function(options) {

            if (this.initialized) {
                // Allow Chaining
                return ModuleManager;
            }

            options = options || {};

            this.vent = options.vent || Media.channel("ModuleManager").vent;
            this.commands = options.commands || Media.channel("ModuleManager").commands;
            this.reqres = options.reqres || Media.channel("ModuleManager").reqres;

            this.logger = Logger.getLogger("LEFramework");
            this.Notifier = options.notifier;

            // Initialize the Session Manager which manages the authentication with the messaging layers of the application
            this.sessionManager = sessionManager.getManager();

            this.moduleRegions = {};
            this.moduleNames = store.getters[`${ENVIRONMENT}/${MODULES}`].moduleNames;
            this.moduleIds = store.getters[`${ENVIRONMENT}/${MODULES}`].moduleIds;
            this.personaMap = store.getters[`${ENVIRONMENT}/${MODULES}`].personaMap;
            this.prefixMap = store.getters[`${ENVIRONMENT}/${MODULES}`].prefixMap;

            // The manager can support registering and loading modules lazily (so only the selected persona modules will be loaded)
            // But since the application does not support this feature yet, we will register all modules for all the personas of the user
            this.lazyPersonas = options.lazyPersonas;

            // Listen to a request on the events bus to return the module route prefix
            this.reqres.setHandler("module:persona:valid", function (options) {
                return hasPersona.call(ModuleManager, options.persona);
            });

            // Listen to a request on the events bus to return the module route prefix
            this.reqres.setHandler("module:resolve:prefix", function (options) {
                return resolveModuleRoutePrefix.call(ModuleManager, options.persona);
            });

            // Listen to a request on the events bus to return the module name
            this.reqres.setHandler("module:resolve:name", function (options) {
                return resolveModuleName.call(ModuleManager, options.persona);
            });

            // Listen to a request on the events bus to return the module persona
            this.reqres.setHandler("module:resolve:persona-by-prefix", function (options) {
                return resolvePersonaByPrefix.call(ModuleManager, options.prefix);
            });

            this.initialized = true;

            // Allow Chaining
            return ModuleManager;
        };

        var checkPersona = function(persona) {
            return "*" == persona || !!this.moduleNames[persona] && this.sessionManager.hasPersona(persona);
        };

        var hasPersona = function(persona) {
            return -1 < _.indexOf(this.personaMap.all, persona) || checkPersona.call(this, persona);
        };

        var hasAtLeastOneOfPersona = function(personas) {
            var hasOne = false;

            _.each(personas, function (persona, index, col) {
                hasOne = hasOne || "*" === persona;
                if (!hasOne && checkPersona.call(this, persona)) {
                    hasOne = true;
                    return;
                }
            }, this);

            return hasOne;
        };

        var resolveModuleConfig = function(persona) {
            if (!persona) {
                persona = this.sessionManager.getActivePersona();
            }

            return this.moduleNames && this.moduleNames[persona] ? this.moduleNames[persona].moduleConfig : "";
        };

        var resolveModuleRoutePrefix = function(persona) {
            var moduleRoutePrefix = "";
            if (!persona) {
                persona = this.sessionManager.getActivePersona();
            }

            if (this.moduleNames && this.moduleNames[persona]) {
                moduleRoutePrefix = this.moduleNames[persona].routePrefix;
            }
            else {
                for (var name in this.moduleNames) {
                    // there are modules that doesn't have persona(personaMapping - LEManager/LEContent ...) and let say
                    // the url was saved like: https://omerh-mac.dev.lprnd.net/a/qa42119490/#mng!data/list (bookmark copy paste)
                    // our application is building the url to the AppServer like:
                    // https://hc1/hc/web/public/pub/ma/lp/login.jsp?lpservice=liveEngage&servicepath=a%2F~~accountid~~%2F%23%2C~~ssokey~~%3Bmng!data/list
                    // mng - is acting like "persona"
                    // if the "persona" is equal to the routePrefix is the case for the below scenario
                    if (this.moduleNames[name].routePrefix === persona) {
                        moduleRoutePrefix = persona;
                    }
                }
            }
            return moduleRoutePrefix;
        };

        var resolveModuleNameByPrefix = function(prefix) {
            var persona = resolvePersonaByPrefix.call(this, prefix);

            if (persona && "" !== persona) {
                return resolveModuleName.call(this, persona);
            }

            return "";
        };

        var resolveModuleNameById = function(id) {
            return this.moduleIds && this.moduleIds[id];
        };

        var resolveModuleName = function(persona) {
            if (!persona) {
                persona = this.sessionManager.getActivePersona();
            }

            return this.moduleNames && this.moduleNames[persona] ? this.moduleNames[persona].moduleMapping : "";
        };

        var resolveModules = function(persona) {
            if (!persona) {
                persona = this.sessionManager.getActivePersona();
            }

            return this.moduleNames && this.moduleNames[persona] ? this.moduleNames[persona].list : [];
        };

        var resolvePersona = function(moduleName) {
            return this.personaMap && this.personaMap[moduleName] ? this.personaMap[moduleName] : "";
        };

        var resolvePersonaByPrefix = function(prefix) {
            return this.prefixMap && this.prefixMap[prefix] ? this.prefixMap[prefix] : "";
        };

        var doRegister = function(moduleName, index, modules) {
            var persona = resolvePersona.call(this, moduleName);
            var moduleConfig = resolveModuleConfig.call(this, persona);

            if (moduleName === moduleConfig.moduleName && !this.moduleNames[persona].registered) {
                this.logger.debug("registering module: " + moduleName + " to LE");

                //add the module-regionId mapping to the app object
                this.moduleRegions[moduleName] = moduleConfig.regionId;

                var startWithParent = (false !== moduleConfig.autostart);

                var appVent = this.vent;
                var Notify = this.Notifier;

                // register the module on the application
                var module = this.reqres.request("application:module:get", { moduleName:moduleName, callback:function (Module, LE, Backbone, Marionette, $, _) {
                    //todo: if we want, here we can prevent to module from starting with the app automatically

                    // Set the module application Id of this module
                    Module.appId = moduleConfig.id;

                    // create a media & channel for the specific module (for internal-module communication)
                    Module.media = Media.getMediaForContext(moduleName);
                    Module.channel = Media.channel(moduleName);

                    // This is for backword compatibility
                    // create an event aggregator (vent) for the specific module (for internal-module communication)
                    Module.vent = Module.channel.vent;
                    Module.commands = Module.channel.commands;
                    Module.reqres = Module.channel.reqres;

                    Module.logger = Logger.getLogger(moduleName);
                    //create a notifier for the specific module(for displaying notifications)
                    Module.notifier = new Notify(moduleName, moduleConfig.notifierContainer || moduleConfig.container, CONST.APP_ROOT_CONTAINER);
                    Module.routePrefix = moduleConfig.routePrefix;

                    // prevent starting with parent
                    Module.startWithParent = startWithParent;
                } });

                // And subscribe to the module main events
                module.on("before:start", function (options) {
                    // do stuff before the module is started
                    appVent.trigger("module:starting", { moduleName:moduleName });
                });
                module.on("start", function (options) {
                    // do stuff after the module has been started
                    appVent.trigger("module:started", { moduleName:moduleName });
                });
                module.on("before:stop", function () {
                    // do stuff before the module is stopped
                    appVent.trigger("module:stopping", { moduleName:moduleName });
                });
                module.on("stop", function () {
                    // do stuff after the module has been stopped
                    appVent.trigger("module:stopped", { moduleName:moduleName });
                });
                module.on("auth-error", function (options) {
                    // do stuff when the module triggers authentication error event
                    appVent.trigger("module:auth-error", { moduleName:moduleName, errorCode:options.errorCode });
                });
                module.on("logout", function (options) {
                    // do stuff when the module triggers authentication error event
                    appVent.trigger("module:logout", { moduleName:moduleName, prompt:options.prompt, options:options.options });
                });

                module.touchSession = function () {
                    // do stuff when the module touches session
                    appVent.trigger("module:touch-session", { moduleName:moduleName });
                };

                module.revalidateSession = function () {
                    // do stuff when the module revalidates session
                    appVent.trigger("module:revalidate-session", { moduleName:moduleName });
                };

                module.expireSession = function (options) {
                    // do stuff when the module touches session
                    appVent.trigger("module:expire-session", $.extend(true, { moduleName:moduleName }, options));
                };

                module.triggerAuthError = function (errorCode) {
                    module.trigger("auth-error", { errorCode:errorCode });
                };

                module.logout = function (prompt, options) {
                    module.trigger("logout", { prompt:prompt, options:options });
                };

                var moduleStop = module.stop;

                module.stop = function (callback) {
                    if (module.close) {
                        try {
                            module.close.call(this, callback);
                        }
                        catch (ex) {
                            callback();
                        }
                    }

                    moduleStop.call(this);
                };

                this.moduleNames[persona].registered = true;
            }
        };

        var registerModules = function(persona, registerRenderEvent) {
            // The manager can support registering and loading modules lazily (so only the selected persona modules will be loaded)
            // But since the application does not support this feature yet, we will register all modules for all the personas of the user
            if (!persona && !this.lazyPersonas) {
                // Iterate over personas of the user
                var personas = this.sessionManager.getPersonas();
                _.each(personas, function (item, index, col) {
                    // Iterate over the registered modules definitions
                    _.each(resolveModules.call(this, item), doRegister, this);
                }, this);
            }
            else {
                // Iterate over the registered modules definitions
                _.each(resolveModules.call(this, persona), doRegister, this);
            }

            if (registerRenderEvent && !this.vent.hasHandler("module:before:render")) {
                this.vent.on("module:before:render", moduleBeforeRender, this);
            }

            // Allow Chaining
            return ModuleManager;
        };

        var registerAllModules = function(registerRenderEvent) {
            // Iterate over the registered modules definitions
            _.forOwn(this.moduleNames, function (value, key, modules) {
                registerModules.call(this, key, false);
            }, this);

            if (registerRenderEvent && !this.vent.hasHandler("module:before:render")) {
                this.vent.on("module:before:render", moduleBeforeRender, this);
            }

            // Allow Chaining
            return ModuleManager;
        };

        var moduleBeforeRender = function(options) {
            var module = this.reqres.request(options);

            // do stuff before the module is started
            module.triggerMethod("before:render");
        };

        var startModules = function(persona) {
            var personas = [persona];
            var moduleNames = [];

            if (_.isUndefined(persona)) {
                personas = this.sessionManager.getPersonas();
            }

            _.each(personas, function (item, index, col) {
                moduleNames = _.union(moduleNames, resolveModules.call(this, persona));
            }, this);

            this.vent.trigger("before:" + persona + "-modules:start", { moduleNames:moduleNames });
            _.each(moduleNames, function (moduleName, index, modules) {
                this.reqres.request("application:module:get", { moduleName:moduleName }).start();
            }, this);
            this.vent.trigger("after:" + persona + "-modules:stop", { moduleNames:moduleNames });

            // Allow Chaining
            return ModuleManager;
        };

        var stopModules = function(persona, callback) {
            var personas = [persona];
            var moduleNames = [];
            var count = 0;
            var closes = 0;
            var afterClose;

            if (_.isUndefined(persona)) {
                personas = this.sessionManager.getPersonas();
            }

            _.each(personas, function (item, index, col) {
                moduleNames = _.union(moduleNames, resolveModules.call(this, persona));
            }, this);

            count = moduleNames.length;
            closes = _.filter(moduleNames, function(module) {
                return _.isFunction(module.close);
            }).length;

            if (callback && 0 < closes) {
                afterClose = _.after(closes, callback);
            }

            this.vent.trigger("before:" + persona + "-modules:stop", { moduleNames:moduleNames });
            _.each(moduleNames, function (moduleName, index, modules) {
                this.reqres.request("application:module:get", { moduleName:moduleName }).stop(afterClose);
            }, this);
            this.vent.trigger("after:" + persona + "-modules:stop", { moduleNames:moduleNames });

            if (callback && 0 === closes) {
                callback();
            }

            // Allow Chaining
            return ModuleManager;
        };

        var stopAllModules = function(callback) {
            var submodules = this.reqres.request("application:modules:get");
            var count = _.keys(submodules).length;
            var closes = _.filter(_.values(submodules), function(module) {
                return _.isFunction(module.close);
            }).length;
            var afterClose;

            if (callback && 0 < closes) {
                afterClose = _.after(closes, callback);
            }

            this.vent.trigger("before:modules:stop", { modules:submodules });
            _.each(submodules, function (module, moduleName) {
                module.stop(afterClose);
            }, this);
            this.vent.trigger("after:modules:stop", { modules:submodules });

            if (callback && 0 === closes) {
                callback();
            }

            // Allow Chaining
            return ModuleManager;
        };

        var isInitialized = function() {
            return this.initialized;
        };

        var getVent = function() {
            return this.vent;
        };

        var getReqRes = function() {
            return this.reqres;
        };

        var getCommands = function() {
            return this.commands;
        };

        var getModuleRegions = function() {
            return this.moduleRegions;
        };

        var getModuleNames = function() {
            return this.moduleNames;
        };

        return {
              initialize:initialize
            , isInitialized:isInitialized
            , vent:getVent
            , reqres:getReqRes
            , commands:getCommands
            , moduleRegions:getModuleRegions
            , moduleNames:getModuleNames
            , hasPersona:hasPersona
            , hasAtLeastOneOfPersona:hasAtLeastOneOfPersona
            , resolveModules: resolveModules
            , resolveModuleRoutePrefix:resolveModuleRoutePrefix
            , resolveModuleNameByPrefix:resolveModuleNameByPrefix
            , resolveModuleNameById:resolveModuleNameById
            , resolveModuleName:resolveModuleName
            , resolveModuleConfig:resolveModuleConfig
            , resolvePersona:resolvePersona
            , resolvePersonaByPrefix:resolvePersonaByPrefix
            , registerModules:registerModules
            , registerAllModules:registerAllModules
            , startModules:startModules
            , stopModules:stopModules
            , stopAllModules:stopAllModules
        };
    }());

    return ModuleManager;
});
