/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:38 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var Capping = require("codeTables/capping.json");
  var _ = require('underscore');

    var cappingSettings = (function () {
        var currentCapping = null;

        var getCappingArray = function () {
            return Capping.cappingCodes;
        };

        var getCurrentCapping = function () {
            var cappingArr = getCappingArray();
            var minCapping = cappingArr[0].value;
            var maxCapping = cappingArr[cappingArr.length - 2].value;
            var unlimitedCapping = cappingArr[cappingArr.length - 1].value;

            if (_.isNull(currentCapping)) {
                currentCapping = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CAPPING,Capping.defaultCapping);

            }

            currentCapping = (currentCapping > maxCapping) ? unlimitedCapping : currentCapping; // if capping is greater than the max - take the unlimited
            currentCapping = (currentCapping < minCapping && currentCapping != unlimitedCapping) ? minCapping : currentCapping; // if capping is lower than the min AND is not unlimited(-1) - take the min
            return currentCapping;
        };

        var switchCapping = function (capping) {
            currentCapping = capping;
            LE.vent.trigger("siteSettings:cappingSwitched",currentCapping);
        };

        return {
            getCappingArray: getCappingArray,
            getCurrentCapping: getCurrentCapping,
            switchCapping: switchCapping
        };
    })();

return cappingSettings;
});
