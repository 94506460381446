/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/14/14
 * Time: 3:17 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Backbone = require('backbone');

    var DependencyItemModel = Backbone.Model.extend({
        defaults : {
            name: "",
            url: null,
            isClickable: true,
            subText: ""
        }
    });

    return DependencyItemModel;
});
