define(function (require) {
    "use strict";

    var _ = require('underscore');
    var BaseCollection = require("collections/BaseCollection");
    var PermissionModel = require("models/entities/PermissionModel");


    var PermissionsCollection = BaseCollection.extend({
      model: PermissionModel,
      name: "PermissionsCollection"
    });

    return PermissionsCollection;
});

