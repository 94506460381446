/**
 * Created with IntelliJ IDEA.
 * User: Tomer /.... (noam  - refactored)
 * Date: 6/11/14
 * Time: 1:16 PM
 *
 * ***************************************************************************
 * NOTE:
 * OrderedCollection is a special collection that handles some of the methods for its models.
 * It's responsible for the models deletion - when you delete a model, the other models "order" attribute might be change (by the BE) so the BE respond with the entire collection.
 * It's responsible for the models "order" attribute change - when you change an "order" attribute of one of the models,
 * the other models order should be change as well, so we send to the API the list of the models that been changed.
 * Both are done in a tricky way.
 * Because we get in the response the entire collection we chose the 'fetch' method to do it.
 * We override the type to 'PUT' or 'DELETE' and set the revision as the Revisioned Collection does for 'update' or 'delete'.
 * Also, the 'multiple save' API request array of JSONs so we get the model.toJSON(), update it according to the requested change and send an array of JSONs to the API.
 * ***************************************************************************
 */
define(function (require) {
    "use strict";

    var PersistentCollection = require("collections/PersistentCollection");
  var _ = require('underscore');

    var URL_OPTIONS = {
        WITH_YOGA: "WITH_YOGA",
        LEGACY_CBE: "LEGACY_CBE"
    };
    var URL_PARAMETERS = {};
    URL_PARAMETERS[URL_OPTIONS.WITH_YOGA] = "&select=$all&return=active"; //parameters for Ordered collection API as planed by Infra team with "return" field  and Yoga support
    URL_PARAMETERS[URL_OPTIONS.LEGACY_CBE] = "&field_set=all";    //parameters for ordered collection API as partially implemented by CBE

    var OrderedCollection = PersistentCollection.extend({

        initialize: function(models,options){
            options = options  || {} ;
            this.orderByKey = options.orderByKey || "order";

            options.urlOption = options.urlOption || URL_OPTIONS.WITH_YOGA;
            if (!_.has(URL_OPTIONS,options.urlOption)){
                throw new Error("OrderedCollection: urlOption must be of type OrderedCollection.URL_OPTIONS");
            }
            this.urlParaemters = URL_PARAMETERS[options.urlOption];

            PersistentCollection.prototype.initialize.call(this,models,options);
        },

        /**
         * we concat the select=all and return=active in order to get always the full collection with all the data (also when delete or update specific models)
         * @returns {string}
         */
        url: function(){
            var url = PersistentCollection.prototype.url.call(this);
            return url += this.urlParaemters;
        },

        /**
         * Move the specific model one order up (ex: from 3 to 2) and re-order the models in the requested order.
         * @param modelId - The model Id to move up
         * @param options
         */
        moveUp: function(modelId, options) {
            var orderedModel = this.get(modelId);
            var requestedOrder = orderedModel.get(this.orderByKey) - 1;
            this.updateOrder(orderedModel, requestedOrder, options);
        },
        /**
         * Move the specific model to order:1 and re-order the other models.
         * @param modelId - The model Id to move top
         * @param options
         */
        moveTop: function(modelId, options) {
            var orderedModel = this.get(modelId);
            var requestedOrder = 1;
            this.updateOrder(orderedModel, requestedOrder, options);
        },

        /**
         * Move the specific model one order down (ex: from 2 to 3) and re-order the models in the requested order.
         * @param modelId - The model Id to move down
         * @param options
         */
        moveDown: function(modelId, options) {
            var orderedModel = this.get(modelId);
            var requestedOrder = orderedModel.get(this.orderByKey) + 1;
            this.updateOrder(orderedModel, requestedOrder, options);
        },

        /**
         * Move the specific model to order:collection.length and re-order the other models.
         * @param modelId - The model Id to move bottom
         * @param options
         */
        moveBottom: function(modelId, options) {
            var orderedModel = this.get(modelId);
            var requestedOrder = orderedModel.collection.size();
            this.updateOrder(orderedModel, requestedOrder, options);
        },

        updateOrder: function(orderedModel, requestedOrder, options) {
            options = options || {};

            if (!_.isEmpty(orderedModel)) {
                var modelJSON = orderedModel.toJSON();
                modelJSON[this.orderByKey] = requestedOrder;

                var saveOptions = _.extend(options, {
                    "data": modelJSON,
                    "contentType": "application/json",
                    "type": "PUT"
                });

                saveOptions.headers = saveOptions.headers || {};

                var revision = orderedModel.getRevision();
                if (revision) {
                    saveOptions.headers[PersistentCollection.IF_MATCH_HEADER] = revision;
                }

                var lastModified = orderedModel.getLastModified();
                if (lastModified) {
                    saveOptions.headers[PersistentCollection.LAST_MODIFIED_HEADER] = lastModified;
                }

                var originalUrl = this.url;

                var modelUrl = _.result(orderedModel, "url");
                this.url = modelUrl + this.urlParaemters;

                this.fetch(saveOptions);

                this.url = originalUrl;
            }
        },

        /**
         * Destroy models from the collection, according to the comment at the top of this file
         * @param modelsIds - Ids to be destroyed
         * @param options
         */
        destroyModels: function(modelsIds, options) {
            options = options || {};
            var destroyOptions = _.extend(options, {
                "data": modelsIds,
                "contentType": "application/json",
                "type": "DELETE"
            });

            destroyOptions.headers = destroyOptions.headers || {};
            destroyOptions.headers[PersistentCollection.IF_MATCH_HEADER] = this.getRevision();

            var lastModified = this.getLastModified();
            if (lastModified) {
                destroyOptions.headers[PersistentCollection.LAST_MODIFIED_HEADER] = lastModified;
            }

            this.fetch(destroyOptions);
        }
    });

    OrderedCollection.URL_OPTIONS = URL_OPTIONS;
    return OrderedCollection;
});
