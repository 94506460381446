<template>
  <div class="container-divider">
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: 'Divider'
};
</script>

<style lang="scss" scoped>
.container-divider {
  padding: 0 16px;
}
.divider {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  height: 1px;
  background-color: getPaletteColor('framework-light', 'grey-6c');
}
</style>
