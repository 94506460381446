<template>
  <div class="container">
    <div
      v-for="i in count"
      :key="i"
      class="skeleton-wrapper"
    >
      <div class="skeleton"></div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Skeleton',
  props: {
  count: {
    type: Number,
    default: 0,
    }
   }
};
</script>

<style lang='scss' scoped>
@import "../../../assets/scss/global";
@import "../../../assets/scss/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  gap: 8px;
}

.skeleton-wrapper {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton {
  @include themify {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background: getColor('navbar-skeleton-bg', 'framework');
    border-radius: 4px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;

    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }
  }
}
</style>

