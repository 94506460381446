/**
 * Created by tomers on 4/26/2016.
 */
define(function (require) {
    "use strict";

    var LE = require("app");
    var _ = require("underscore");
    var LobModel = require("models/entities/LobModel");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var ApiResources = require("assets/data/apiResources.json");

    var NAME = "LobRelationsCollection";

    var LobRelationsCollection = RevisionedCollection.extend({
        model: LobModel,
        resource: ApiResources.AccountConfig.LobRelations,
        name: NAME,
        revisions: {},

        initialize: function(models, options) {
            options = options || {};
            options.change404To200OK = true;
            RevisionedCollection.prototype.initialize.call(this,models,options);
        },

        isCollectionAllowed: function() {
            return LE.siteSettings.lobSettings.hasLobSettingEnabled() && (LE.sessionManager.hasViewAcLob() || LE.sessionManager.hasEditAcLob());
        },

        parse : function (response, options) {
            var parsedData = [];
            if (response) {
                this.revisions.lob = response.revisions ? response.revisions.ACLobObject : -1;
                parsedData = response.data ? response.data : [];
            }

            return RevisionedCollection.prototype.parse.call(this, parsedData, options);
        },

        fetch: function(options) {
            options = options || {};
            options.data = options.data || {};
            options.data.types = options.data.types || _.values(LobModel.SUPPORTED_ENTITY_TYPES).join(",");

            return RevisionedCollection.prototype.fetch.call(this, options);
        },

        getLobsRevision: function() {
            return this.revisions.lob;
        }
    });

    LobRelationsCollection.NAME = NAME;

    return LobRelationsCollection;
});
