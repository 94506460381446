/**
 * Created with IntelliJ IDEA.
 * User: yniran
 * Date: 09/09/13
 * Time: 23:47
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {
    "use strict";

    var _ = require("underscore");
    var $ = require("jquery");

    var SelectableCollectionDecorator = function (original, selector, settings) {

        if (!_.isPlainObject(settings)) {
            settings = {};
        }

        settings = _.extend({"uniqueAttr":"id"}, settings);

        var decorator = {

            _selection:[],

            _getSelector:function (selector) {

                var selectorFn;

                var idAttr = settings.uniqueAttr;

                if (_.isNumber(selector) || _.isString(selector)) {
                    if (idAttr === "id") {
                        selectorFn = function () {
                            return this.get(selector);
                        }; //for ids use the optimized 'get' method on the collection
                    }
                    else {
                        selectorFn = function () {

                            return this.find(function (model) {
                                return model.get(idAttr) === selector;
                            });
                        };
                    }
                }
                else if (_.isObject(selector)) {
                    selectorFn = function () {
                        return this.find(selector);
                    };
                }
                else //selector could be null or undefined and will break the calling method
                {
                    selectorFn = selector;
                }

                return selectorFn;
            },

            selectModel:function (selector, options) { //select single model based on predicate

                var selectorFn = this._getSelector(selector);

                var model = selectorFn.call(this);

                if (model) {
                    options = _.extend(options || {}, {"exclusively":true});//single select is always exclusive, only one can be selected
                    this.addToSelection(model, options);
                }

                return this;
            },

            selectModels:function (selector, options) {//select multiple model based on predicate

                var models = this.filter(selector);

                this.addToSelection(models, options);

                return this;
            },

            addToSelection:function (models, options) {

                var c = this;
                var changed = false;
                var exclusively = options ? options.exclusively : null;

                if (!_.isArray(models)) {
                    models = [models];
                }

                if (!models || models.length === 0) {
                    throw new Error("SelectableCollectionDecorator : addToSelection : No models were found!!!");
                }

                //TODO: Need to compare existing selection to new one and only raise change if different

                if (exclusively === true) {
                    this.clearSelection(options, true);
                    changed = true;
                }

                _.each(models, function (model) {

                    var id = model.get(settings.uniqueAttr); //get value of unique attribute

                    if (_.indexOf(c._selection, id) === -1) {
                        c._selection.push(id);
                        changed = true;
                    }
                });

                if (changed) {
                    this.triggerChange(options);
                }

                return this;
            },

            getSelectionLength:function () {

                return this._selection.length;
            },

            getSelected:function () {

                var attr = settings.uniqueAttr;
                var selection = this._selection;

                var selectedModels = this.filter(function (model) {

                    return _.indexOf(selection, model.get(attr)) > -1;
                });

                return selectedModels;
            },

            getSelectedModel:function () { //for backwards compatibility

                return this.getFirstSelected();
            },

            getFirstSelected:function () {

                return this.getSelected()[0];   //will fail if no selection
            },

            getSelectedIds:function () {

                return this._selection.slice();//return a copy of the ids
            },

            getSelectedId:function () { //for backwards compatibility

                return this.getFirstSelectedId();
            },

            getFirstSelectedId:function () {

                return this._selection.slice()[0];   //returns undefined if no selection
            },

            isSelected:function (selector) {

                //todo: maybe do a simple check first and check indexof of selector on the _selection array, if not found then continue

                var selectorFn = this._getSelector(selector);

                var model = selectorFn.call(this);

                if (!_.isUndefined(model)) {
                    var id = model.get(settings.uniqueAttr);

                    return _.indexOf(this._selection, id) !== -1;
                }

                return false;
            },

            clearSelection:function (options, forceSilent) {

                if (this._selection.length !== 0) {
                    this._selection.length = 0;

                    if (!forceSilent) {
                        this.triggerChange(options);
                    }
                }

                return this;
            },

            _removeModel:function (model, attr) {

                var index = _.indexOf(this._selection, model.get(attr));

                if (index !== -1) {
                    this._selection.splice(index, 1);
                    return true;
                }

                return false;
            },

            deselectModel:function (model, options) {   //remove a single model from selection

                var changed = this._removeModel(model, settings.uniqueAttr);

                if (changed) {
                    this.triggerChange(options);
                }

                return this;
            },

            removeSelection:function (selector, options) { //remove multiple models from selection based on predicate

                var c = this;
                var changed = false;
                var models = this.filter(selector);  //find models to remove
                var attr = settings.uniqueAttr;

                _.each(models, function (model) {

                    if (c._removeModel(model, attr) && !changed) {
                        changed = true;
                    }
                });

                if (changed) {
                    this.triggerChange(options);
                }

                return this;
            },

            triggerChange:function (options) {

                var silent = options ? options.silent : null;

                if (silent !== true) {
                    this.trigger("change:selection", this._selection, options);
                }
            }
        };

        var decorated = $.extend({}, original, decorator); //dont change the original collection

        if (typeof(selector) !== "undefined" && selector !== null) {
            decorated.selectModel(selector);
        }

        return decorated;
    };

    SelectableCollectionDecorator.decorate = function (backboneCollection, selector, options) {

        return new SelectableCollectionDecorator(backboneCollection, selector, options);
    };

    return SelectableCollectionDecorator;
});
