/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:38 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var TimeZone = require("codeTables/timeZone.json");
    var DateUtility = require("utils/DateUtility");
  var _ = require('underscore');

    var timeZoneSettings = (function () {
        var serverTimeZone = null;
        var currentTimeZone = null;
        var currentTimeZoneTranslated = null;
        var serverTimeGMTDiff = null;
        var serverLoginTime = null;

        var getServerTimeZone = function () {
            if (!serverTimeZone) {
                serverTimeZone = LE.sessionManager.getServerTimeZoneID();
                if (serverTimeZone) {
                    serverTimeZone = TimeZone.timeZoneCodes[serverTimeZone];
                }
            }
            return serverTimeZone;
        };

        var getTimeZoneList = function () {
            return TimeZone.timeZoneCodes;
        };

        var getTimeZoneListTranslated = function () {
            if (_.isUndefined(TimeZone.timeZoneCodesTranslated)) {
                translateTimeZoneCodes();
            }

            return TimeZone.timeZoneCodesTranslated;
        };

        var translateTimeZoneCodes = function () {
            TimeZone.timeZoneCodesTranslated = [];

            var sortedArr = _.sortBy(_.toArray(TimeZone.timeZoneCodes), function (val) {
                return val.UTC;
            });

            _.each(sortedArr, function (value) {
                TimeZone.timeZoneCodesTranslated.push(
                    {
                        "key": value.id,
                        "value": (DateUtility.timeZoneIdToFormattedString(value) || "")
                    });
            });
        };

        var getCurrentTimeZone = function () {
            if (currentTimeZone === null) {
                currentTimeZone = LE.sessionManager.getAccountSettingByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.TIME_ZONE);

                if (currentTimeZone) {
                    currentTimeZone = currentTimeZone.propertyValue.value;
                    if (!TimeZone.timeZoneCodes[currentTimeZone]) {
                        handleOldTimeZone(currentTimeZone);
                    }
                }
                else {
                    currentTimeZone = null;
                }
            }
            return currentTimeZone;
        };

        var getCurrentTimeZoneTranslated = function(timeZone) {
            timeZone = timeZone || getCurrentTimeZone();
            currentTimeZoneTranslated = _.find(getTimeZoneListTranslated(), {key: timeZone});
            if (currentTimeZoneTranslated) {
                currentTimeZoneTranslated = currentTimeZoneTranslated.value;
            }
            return currentTimeZoneTranslated;
        };

        var getServerTimeGMTDiff = function () {
            if (serverTimeGMTDiff === null) {
                serverTimeGMTDiff = LE.sessionManager.getServerTimeGMTDiff();
            }
            return  serverTimeGMTDiff;
        };

        var switchTimeZone = function (timeZone) {
            currentTimeZone = timeZone;
            LE.lpTagAddVar([
                {name: "AccountTimeZone", val: currentTimeZone}
            ]);
            LE.vent.trigger("siteSettings:timeZoneSwitched", currentTimeZone);
        };

        var getServerLoginTime = function () {
            if (!serverLoginTime) {
                serverLoginTime = LE.sessionManager.getServerLoginTime();
            }
            return serverLoginTime;
        };
        var handleOldTimeZone = function (timeZoneId) {
            if (TimeZone.oldTimeZoneCodes[timeZoneId]) {
                TimeZone.timeZoneCodes[timeZoneId] = TimeZone.oldTimeZoneCodes[timeZoneId];
                if (TimeZone.timeZoneCodesTranslated) {
                    translateTimeZoneCodes();
                }
            }
        };

        return {
            getTimeZoneList: getTimeZoneList,
            getTimeZoneListTranslated: getTimeZoneListTranslated,
            getCurrentTimeZone: getCurrentTimeZone,
            getCurrentTimeZoneTranslated: getCurrentTimeZoneTranslated,
            switchTimeZone: switchTimeZone,
            getServerTimeGMTDiff: getServerTimeGMTDiff,
            getServerLoginTime: getServerLoginTime
        };
    })();

    return timeZoneSettings;
});
