import Logger from 'logger';


export default class BaseAppender {

  constructor() {
    this.logger = Logger.getLogger('NotificationAppender');
  }

  isEnabled() {
    return true;
  }

  isSupported() {
    return true;
  }

  setPageTitle() {
    return true;
  }

  getDefaultOptions() {
    throw new Error('getDefaultOptions() must be implemented');
  }

  notify(options) {
    throw new Error('notify() must be implemented');
  }

  clearNotification(options) {
    throw new Error('clearNotification() must be implemented');
  }

  /**
   * used for validation on the notify() method input.
   * @param options - the options passed to notify().
   * @returns {boolean|string} `true` if the input is valid, `false`y value if not, or an error message as a string,
   * if the input is invalid and additional info is desired to be passed to the logger.
   */
  isValidOptions(options) {
    return typeof options === 'object';
  }

  /**
   * used for validation on the clearNotification() method input.
   * @param options - the options passed to clearNotification().
   * @returns {boolean|string} `true` if the input is valid, `false`y value if not, or an error message as a string,
   * if the input is invalid and additional info is desired to be passed to the logger.
   */
  isValidClearNotificationOptions(options) {
    return typeof options === 'object';
  }

}
