/**
 * Created with IntelliJ IDEA.
 * User: elinar
 * Date: 7/16/14
 * Time: 5:38 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var Currency = require("codeTables/currency.json");
  var _ = require('underscore');

    var currencySettings = (function () {
        var currentCurrency = null;

        var getCurrencyList = function () {
            return Currency.currencyCodes;
        };

        var getCurrentCurrency = function () {
            if (currentCurrency === null) {
                currentCurrency = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.CURRENCY,Currency.defaultCurrency);
            }
            return currentCurrency;
        };

        // return the currency object as on config
        var getCurrentCurrencyObject = function() {
            return Currency.currencyCodes[getCurrentCurrency()];
        };

        var getCurrencyListTranslated = function () {
            if (_.isUndefined(Currency.currencyCodesTranslated)) {
                translateCurrencyCodes();
            }

            return Currency.currencyCodesTranslated;
        };

        var translateCurrencyCodes = function () {
            Currency.currencyCodesTranslated = [];

            _.each(Currency.currencyCodes, function (value, key) {
                Currency.currencyCodesTranslated.push({"key": key, "value": LE.translator.translate("LEFramework." + key)});
            });
            Currency.currencyCodesTranslated = _.sortBy(Currency.currencyCodesTranslated, function(obj){ return obj.value; });
        };


        var switchCurrency = function (currency) {
            currentCurrency = currency;
            LE.vent.trigger("siteSettings:currencySwitched",currentCurrency);
        };


        return {
            getCurrencyList: getCurrencyList,
            getCurrencyListTranslated: getCurrencyListTranslated,
            getCurrentCurrency: getCurrentCurrency,
            getCurrentCurrencyObject: getCurrentCurrencyObject,
            switchCurrency: switchCurrency
        };
    })();

    return currencySettings;
});
