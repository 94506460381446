/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/19/14
 * Time: 8:28 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var BaseModel = require("models/BaseModel");

    var DependencyModel = BaseModel.extend({
        defaults: {
            type: "",
            parentType: "",
            parentId: null,
            dependencies: null // array of dependency objects
        },
        name: "DependencyModel"
    });

    return DependencyModel;
});
