<template>
  <div
    :title="revert.tooltip"
    :tooltip-text="revert.tooltip"
    tooltip-position="right"
    tabindex="0"
    role="button"
    @click="showNewAgentWorkspaceInvitationRevertDialog"
    @keydown.enter.space.prevent="showNewAgentWorkspaceInvitationRevertDialog"
  >
    <!-- the v-if is here, because of listener to invitation -->
    <div
      class="tlm-s-icon s-revert"
      :data-lp-at="revert.text + `revert-icon`"
    ></div>
    <div class="item-title ellipsis">
      {{ revert.text }}
    </div>
    <!--Revert to the old  Agent work space-->

    <teleport to="#fe-root-mounting-portal-target">
      <le-dialog
        v-if="isShowRevertDialog"
        :header="revert.header"
        :primary-button-text="revert.confirm"
        :cancel-text="revert.cancel"
        class="theme-light revert-naw-dialog"
        :z-index="3000"
        width="450px"
        @primary-button-click="onConfirmRevert"
        @cancel-click="onCancelRevert"
      >
        <template #content>
          <p class="revert-description">
            {{ revert.description }}
          </p>
          <br />
          <le-select
            ref="reasonSelect"
            v-model="form.reason"
            class="revert-reasons"
            type="dropdown"
            :items="revertReasons.list"
            error-placement="bottom-end"
            container=".revert-naw-dialog"
          />
          <le-textarea
            v-model="form.explanation"
            class="theme-light revert-explained"
            :rows="8"
            :placeholder="revertReasons.explained"
          />
        </template>
      </le-dialog>
    </teleport>
  </div>
</template>

<script>
import { leDialog, leSelect, leTextarea } from '@liveperson/lp-vue-components';
import { gChannel, lpStorage } from 'vue-infra';
import Settings from '../../../../assets/data/codeTables/settings.json';
import SiteSettingsModel from '../../../models/SiteSettingsModel';
import Loader from 'ui/loader/Loader';

export default {
  name: 'NawSwitch',
  components: {
    leDialog,
    leSelect,
    leTextarea
  },
  props: {
    invitationBubbleStorageKey: {
      type: String,
      required: true
    },
    loggedInUserDetails: {
      type: Object,
      required: true
    },
    showNewAgentWorkspaceInvitationRevert: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      revert: {
        tooltip: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.tooltip'),
        text: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.text'),
        title: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.title'),
        description: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.description'),
        confirm: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.button'),
        cancel: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.cancel')
      },
      invite: {
        title: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitation.dialog.title'),
        confirm: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitation.dialog.button'),
        content: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitation.dialog.content'),
        cancel: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitation.dialog.cancel')
      },
      revertReasons: {
        explained: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.explained'),
        list: [
          {
            id: 0,
            value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.title')
          },
          {
            id: 1,
            value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.performance')
          },
          {
            id: 2,
            // prettier-ignore
            value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.functionality')
          },
          {
            id: 3,
            value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.orientation')
          },
          {
            id: 4,
            value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.visual')
          },
          {
            id: 5,
            value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.bug')
          },
          {
            id: 6,
            value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.other')
          }
        ]
      },
      isShowRevertDialog: false,
      form: {
        explanation: '',
        reason: {
          id: 0,
          value: this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.title')
        }
      }
    };
  },
  mounted() {
    gChannel.register(
      'LiveEngageCommon',
      'showNewAgentWorkspaceInvitationDialog',
      this.showNewAgentWorkspaceInvitationDialog
    );
  },
  methods: {
    showNewAgentWorkspaceInvitationDialog() {
      window.LE.notifier.showConfirm({
        title: this.invite.title,
        content: this.invite.content,
        objectAction: {
          text: this.invite.confirm,
          textAdditionalButton: this.invite.cancel,
          callback: () => { this.saveNewAgentWorkspaceInvitationValue('true') }
        }
      });
    },
    showNewAgentWorkspaceInvitationRevertDialog() {
      this.isShowRevertDialog = true;
    },
    async onConfirmRevert() {
      const isFormValid = this.validateRevertForm();
      if (isFormValid) {
        await this.saveFeedback();
        this.saveNewAgentWorkspaceInvitationValue('false');
        this.isShowRevertDialog = false;
      }
    },
    onCancelRevert() {
      this.isShowRevertDialog = false;
    },
    saveNewAgentWorkspaceInvitationValue(value) {
      const loader = new Loader({
        permutation: Loader.PERMUTATION.A,
        el: '.root',
        zIndex: 9999,
        isLEWrapRequired: true
      });
      loader.render();
      const translator = this.$translator;
      const invitationBubbleStorageKey = this.invitationBubbleStorageKey;
      const userId = this.loggedInUserDetails.id;
      const siteSettingsModel = new SiteSettingsModel({
        id: Settings.SETTING_ID.SHOW_NEW_AGENT_WORKSPACE,
        contextType: Settings.CONTEXT_TYPE.USER,
        contextId: userId,
        contextCascading: false
      });
      siteSettingsModel.fetch({
        success: function(model) {
          model.setValue(value);
          model.setRevision(-1); // workaround to get over a bug in BE in the revision mechanism
          model.save(null, {
            success: function() {
              setTimeout(() => {
                // loader.close(); no need to close the loader before the refresh
                if (value === 'true') {
                  lpStorage.localStorage(invitationBubbleStorageKey, 'true');
                }
                if (window.LE) {
                  window.LE.isSilentLeave = true;
                }
                location.href = location.origin; // we are refreshing while taking the domain only so we are gonna get the default page
              }, 5000);
            },
            error: function() {
              loader.close();
              window.LE.notifier.showError(translator.translate('LEFramework.errors.serverError.unknownError'));
            }
          });
        },
        error: function() {
          loader.close();
          window.LE.notifier.showError(translator.translate('LEFramework.errors.serverError.unknownError'));
        }
      });
    },
    validateRevertForm() {
      let result = true;
      const reasonId = this.form && this.form.reason && this.form.reason.id;
      if (!reasonId) {
        this.$refs.reasonSelect.setError(
          true,
          this.$translator.translate('LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.dialog.reasons.title')
        );
        result = false;
      }
      return result;
    },
    saveFeedback() {
      return new Promise((resolve, reject) => {
        const loader = new Loader({
          permutation: Loader.PERMUTATION.A,
          el: '.root',
          zIndex: 9999,
          isLEWrapRequired: true
        });
        loader.render();
        const translator = this.$translator;
        const userId = this.loggedInUserDetails.id;
        const siteSettingsModel = new SiteSettingsModel({
          id: Settings.SETTING_ID.NEW_AGENTS_WORKSPACE_REVERT_FEEDBACK,
          contextType: Settings.CONTEXT_TYPE.USER,
          contextId: userId,
          contextCascading: false
        });
        const feedbackModel = this.createFeedbackModel();
        siteSettingsModel.fetch({
          success: function(model) {
            let currentFeedback = model.getValue();
            if (!Array.isArray(currentFeedback && currentFeedback.feedbacks) /* This will also check if object initialized at all */) {
              currentFeedback = { feedbacks: [] };
            }
            currentFeedback.feedbacks.push(feedbackModel);
            model.setValue(currentFeedback);
            model.setRevision(-1); // workaround to get over a bug in BE in the revision mechanism
            model.save(null, {
              success: function() {
                loader.close();
                resolve();
              },
              error: function() {
                loader.close();
                window.LE.notifier.showError(translator.translate('LEFramework.errors.serverError.unknownError'));
                reject();
              }
            });
          },
          error: function() {
            loader.close();
            window.LE.notifier.showError(translator.translate('LEFramework.errors.serverError.unknownError'));
            reject();
          }
        });
      });
    },
    createFeedbackModel() {
      return {
        timestampUTC: new Date().toISOString(),
        revertReasonId: (this.form && this.form.reason && this.form.reason.id || '').toString(),
        revertReasonText: this.form && this.form.reason && this.form.reason.value,
        feedback: this.form && this.form.explanation
      };
    },
  }
};
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global";
@import "../../../../assets/scss/mixins";

.tlm-s-icon {
  width: 16px;
  height: 16px;
}

$icons: s-revert;
@include add-icons-from-list($icons);

.revert-description,
.revert-reasons {
  margin-bottom: 10px;
}


.ico-refresh-button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  @include themify {
    color: getColor('user-menu-status-icon-color', 'framework');
  }
}

.item-title{
  @include themify {
    color: getColor('user-menu-text', 'framework');
  }
}

</style>
