<template>
  <div class="user-bar-tray-menu">
    <div class="tray-menu">
      <div
        v-if="!soundEnabled"
        class="top-bar-delimiter"
      ></div>
      <div class="tray-menu-container-top">
        <tray-menu-item
          v-for="(item, key) in items"
          :key="key"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { environmentGetterTypes, storeModuleTypes, Vuex } from 'vue-infra';
  import TrayMenuItem from './TrayMenuItem';


  const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
    soundEnable: environmentGetterTypes.RESOLVE_SOUND_ENABLE,
    trailInformation: environmentGetterTypes.RESOLVE_LE_TRAIL_INFORMATION,
    agentStatus: environmentGetterTypes.CURRENT_AGENT_STATUS,
    awayReasons: environmentGetterTypes.RESOLVE_CUSTOM_AWAY_REASONS
  });

  export default {
    name: "TrayMenu",
    components: {
      TrayMenuItem
    },
    data() {
      return {
        items: {
          muteTrayItem: {
            iconClass: "sound-icon-tray-off",
            tooltip: this.$translator.translate("LEFramework.userBarMenu.menuItemTray.sound.off.tooltip"),
            show: false,
            index: 2
          },
          trialAccountTrayItem: {
            show: false
          }
        }
      }
    },
    computed: {
      soundEnabled: getters.soundEnable,
    },
    watch: {
      soundEnabled(newValue) {
        this.items.muteTrayItem.show = !newValue;
      },
    },
    created() {
      this.items.muteTrayItem.show = !this.soundEnabled;
    },
    methods: {
    }
  }

</script>

<style lang="scss" scoped>
  @import "../../../../assets/scss/global";
  @import "../../../../assets/scss/mixins";

  .top-bar-delimiter {
    position: absolute;
    width: 1px;
    right: 10px;
    top: -11px;
    height: 40px;
    @include themify {
      $header-divider: getAttr('nav-bar-header-divider', 'framework');
      @include lp-bg-image('assets/img/' + $header-divider);
    }
    content: "";
  }
  .trigger {
    outline: none;
  }
</style>
