/**
 * Created with IntelliJ IDEA.
 * User: omerh
 * Date: 5/5/13
 * Time: 10:29 AM
 * To change this template use File | Settings | File Templates.
 */
define(function() {
  'use strict';

  var CONST = (function() {
    var LANGUAGE = {
      'en-US': 'en-US',
      'de-DE': 'de-DE',
      'es-ES': 'es-ES',
      'zh-CN': 'zh-CN',
      'fr-FR': 'fr-FR',
      'ko-KR': 'ko-KR',
      'pt-BR': 'pt-BR',
      'pt-PT': 'pt-PT',
      'it-IT': 'it-IT',
      'ja-JP': 'ja-JP',
      'tr-TR': 'tr-TR',
      'da-DK': 'da-DK',
      'fi-FI': 'fi-FI',
      'ro-RO': 'ro-RO',
      'ru-RU': 'ru-RU',
      'vi-VN': 'vi-VN',
      'id-ID': 'id-ID',
      'es-419': 'es-419',
      'tl-PH': 'tl-PH'
    };

    var LE_STATUS = {
      CREATED: 'created',
      CONFIGURED: 'configured',
      READY: 'ready',
      INITIALIZED: 'initialized',
      STARTED: 'started'
    };

    var SOURCE_ENUM = {
      LEGACY: 'LEGACY',
      MIGRATING: 'MIGRATING',
      LE: 'LE'
    };

    var ROLES = {
      ADMINISTRATOR: "LEPersonalization.webAgent.widget.profiles.roles.admin",
      AGENT: "LEPersonalization.webAgent.widget.profiles.roles.agent",
      AGENT_MANAGER: "LEPersonalization.webAgent.widget.profiles.roles.agentManager",
      CAMPAIGN_MANAGER: "LEPersonalization.webAgent.widget.profiles.roles.campaignManager"
  };

  var ROLES_IDS = {
    "1": ROLES.ADMINISTRATOR,
    "2": ROLES.AGENT,
    "3": ROLES.AGENT_MANAGER,
    "4": ROLES.CAMPAIGN_MANAGER
  }

    var AGENT_SURVEY_SUPPORT = {
      SINGLE: 'SINGLE',
      MIGRATING: 'MIGRATING',
      MULTIPLE: 'MULTIPLE'
    };

    var NOTIFICATIONS = {
      CHAT: 'chat',
      ERROR: 'error'
    };

    var SYSTEM_NOTIFICATIONS = {
      INFO: 'info',
      ERROR: 'error',
      ERROR_BLOCKING: 'blocking_error',
      ERROR_GLOBAL: 'error_global',
      ERROR_MINI: 'error_mini',
      SUCCESS: 'success',
      CONFIRM: 'confirm',
      CONFIRM_GLOBAL: 'confirm_global',
      WARNING: 'warning',
      WARNING_NO_CLOSE: 'warning_no_close',
      ALLOW_INFO_NUMBER: 3,
      ALLOW_NUMBER_PER_APPLICATION: 1
    };

    var APP_ROOT_CONTAINER = 'root';

    var MODULES = {
      FRAMEWORK: 'LEFramework',
      CAMPAIGNS: 'LECampaigns',
      CONTENT: 'LEContent',
      CONNECTION: 'LEConnection',
      REPORTING: 'LEReporting',
      AGENT: 'LEWebAgent',
      ADMIN: 'LEUserManagement',
      EXAMPLE: 'LEExample',
      MANAGER: 'LEManager',
      PERSONALIZATION: 'LEPersonalization',
      AUTOMATION: 'LEAutomation'
    };

    var COLORS = {
      BLACK: '#000',
      GRAY100: '#323336',
      GRAY90: '#46474a',
      GRAY85: '#515254',
      GRAY80: '#5a5b5e',
      GRAY75: '#656568',
      GRAY74: '#747474',
      GRAY70: '#6e7070',
      GRAY6A: '#6A6A6A',
      GRAY67: '#767678',
      GRAY67_A11Y: '#555555',
      GRAY56: '#656568', // GRAY75
      GRAY56_A11Y: '#666666',
      GRAY50: '#98999b',
      GRAY40: '#adadad',
      GRAY30: '#c1c2c3',
      GRAY25: '#ccc',
      GRAY20: '#d6d6d6',
      GRAY17: '#dcdcdc',
      GRAY13: '#e5e5e5',
      GRAY9: '#ededed',
      GRAY5: '#f5f5f5',
      GRAY4: '#f7f7f7',
      WARMGRAY5: '#f4f4f3',
      WHITE: '#fff',
      ORANGE: '#ff9801',
      ORANGE90: '#ffa92c',
      ORANGEDRK1: '#eb8200',
      ORANGEDRK2: '#bf6913',
      ORANGEDOTA11Y: '#DA7816',
      ORANGE_FB: '#FB6500',
      ORANGE_DB: '#DB3B00',
      BLUE: '#4b91b9',
      LIGHTBLUE: '#52c7ef',
      GREEN: '#5da322',
      LIMEGREEN: '#32CD32',
      LIGHTGREEN: '#8cc63f',
      RED: '#de0a23',
      RED85: '#e32f44',
      LIGHTRED: '#ea3e03',
      YELLOW: '#feec96',
      AGENTDEFAULT: '#222222',
      AGENTORANGE_DARK: '#925C0C',
      AGENTLIGHTGREEN: '#63801B',
      AGENTLIGHTGREEN_DARK: '#5a7712',
      AGENTGREEN_DARK: '#506911',
      AGENTLIGHTBLUE: '#2F7C9D',
      AGENTLIGHTBLUE_DARK: '#267394',
      AGENTBLUE_DARK: '#396D89',
      AGENTPINK: '#A0596C',
      AGENTPINK_DARK: '#874A5A',
      AGENTVIOLET: '#9D3D98',
      AGENTVIOLET_DARK: '#81327D',
      AGENTCYAN: '#217E87',
      AGENTCYAN_DARK: '#186A72',
      DEFAULTCOLOR: '#323336', // GRAY100
      POSITIVE: '#8cc63f', // LIGHTGREEN
      NEGATIVE: '#ea3e03' // LIGHTRED
    };

    var AC_ERRORS = {
      AC_UNKNOWN: 1000,
      AC_ILLEGAL_REVISION: 1001,
      ENTITY_NOT_FOUND: 1002,
      AC_DEPENDENCY_CONFLICT: 1003,
      INVALID_SITE_ID: 1004,
      INVALID_IF_MATCH_HEADER: 1005,
      UNMODIFIED_FIELD: 1006,
      NO_GOAL_FOR_PUBLISHED_CAMPAIGN: 1007,
      NO_VP_FOR_PUBLISHED_CAMPAIGN: 1008,
      RESOURCE_NOT_MODIFIED: 1009,
      FIELD_VALIDATION_FAILURE: 1010,
      MISSING_OSL_IN_ENGAGEMENT: 1011,
      MISSING_VB_IN_ENGAGEMENT: 1012,
      INVALID_ZONE_TYPE_IN_ENGAGEMENT: 1013,
      MAIN_ZONE_INVALID_UPDATE: 1014,
      DEPENDANCY_VALIDATION_FAILURE: 1015,
      UNIQUENESS_FAILURE: 1016,
      UNEXPECTED_API_ERROR: 1100,
      SESSION_EXPIRED: 1200,
      ACTION_UNAUTHORIZED: 1201,
      INSUFFICIENT_PRIVILEGES: 1205,
      NOT_AUTHENTICATED: 1206
    };

    var LP_TAG_LOG_TYPES = {
      DEBUG: 'DEBUG',
      ERROR: 'ERROR',
      INFO: 'INFO',
      METRICS: 'METRICS'
    };

    //log levels
    var LOG_LEVEL = {
      DEBUG: 'debug',
      INFO: 'info',
      WARN: 'warn',
      ERROR: 'error',
      METRICS: 'metrics'
    };

    var EVENTS = {
      REPORTING_DASHBOARD_CLOSE: 'LEReporting:dashboard:close',
      REPORTING_DASHBOARD_OPEN: 'LEReporting:dashboard:open',
      REPORTING_DASHBOARD_LOCKING: 'LEReporting:dashboard:locking'
    };

    var FOCUS_STATUS = {
      VISIBLE_FOCUSED: 2, //window is open and the tab open and focused
      VISIBLE_NOT_FOCUSED: 1, // window is open and the tab open but not focused
      NOT_VISIBLE: 0 // window or tab not open (minimized or other tab selected)
    };

    var ROUTING = {
      NEW_ITEM_ID: 'new',
      DUPLICATE_ITEM: 'duplicate'
    };

    var ENABLE_MY_PROFILE = {
      OFF: 0, // off for all users
      ON: 1, // on for all users
      ADMIN: 2 // on for admins
    };

    var ENVIRONMENTS = {
      DEV: 'dev',
      QA: 'qa',
      CI: 'ci',
      ALPHA: 'alpha',
      GA: 'ga',
      UK: 'uk'
    };

    var TOP_BAR_NOTIFICATION_TYPE = {
      INFO: 'info',
      SUCCESS: 'success',
      ERROR: 'error',
      PROGRESS: 'progress'
    };

    var HTTP_METHOD = {
      GET: 'GET',
      POST: 'POST',
      PUT: 'PUT',
      DELETE: 'DELETE',
      PATCH: 'PATCH'
    };

    var WALK_ME_IDS = {
      MIGRATION_MODE: 90994
    };

    var WS = {
      CONNECTING: 0,
      OPEN: 1,
      CLOSING: 2,
      CLOSED: 3
    };

    var WRAP_UP_TIME = {
      MIN_VALUE: 0,
      MAX_VALUE: 1800
    };

    var TRANSFER_MAX_WAIT_IN_QUEUE_TIME = {
      MIN_VALUE: 1,
      MAX_VALUE: 9999,
      MAX_ALLOWED: 60 * 60 * 24 * 21, // 100 days
      MIN_ALLOWED: 1 // 1 second
    };

    const ICON_STATUS_PREFIX = 'webagent_status_widget icon ';
    const DEFAULT_AGENT_STATUSES = {
      away: {
        iconClass: `${ICON_STATUS_PREFIX} away`,
        imageClass: 'offline',
        translatorParameter: 'away',
        value: 'Away',
        index: 0
      },
      occupied: {
        iconClass: `${ICON_STATUS_PREFIX} brb`,
        imageClass: 'away',
        translatorParameter: 'backIn5',
        value: 'Occupied',
        index: 1
      },
      online: {
        iconClass: `${ICON_STATUS_PREFIX} online`,
        imageClass: 'online',
        translatorParameter: 'online',
        value: 'Online',
        index: 2
      },
      unknown: {
        iconClass: `${ICON_STATUS_PREFIX} unknown`,
        imageClass: 'unknown',
        translatorParameter: 'unknown',
        value: 'Unknown',
        index: 3
      }
    };

    const TRAY_MENU_ITEMS_TYPE = {
      TEXT: 'text',
      AVAILABILITY: 'availability',
      ICON: 'icon'
    };

    const FEATURES = {
      WEB_TO_SMS: 'Common.Web_To_SMS_Eng',
      UN_AUTH_MESSAGING: 'Messaging.Un_Auth_Messaging',
      SMS_NUMBER_MANAGEMENT: 'Common.SMS_numbers_management',
      MULTI_CHANNEL_ENGAGEMENTS: 'LEUI.Multi_Channel_Engagements',
      ENFORCE_NEW_AGENT_WORKSPACE: 'LEUI.Enforce_New_Agent_Workspace',
      EMAIL_CONNECT: 'LEUI.EmailConnector',
      SOCIAL_CONNECT: 'SocialConnect.Enabled',
      USE_CUSTOM_RING: 'LEUI.Use_Custom_Rings',
      MY_ACCOUNT_MENU_ILNK: 'LEUI.MyAccountMenuItem',
      IS_PENDO_ENABED: 'LEUI.isPendoEnabled',
      IS_CHAT_ENABLE_FEATURE: 'Common.Legacy_Chat',
      IS_CSAT_DATA_BAR_HIDDEN: 'Workspace.HideCsatDataBar'
    };

    const PRIVILEGES = {
      VIEW_NEW_AGENT_WORKSPACE: 1786
    };

    const AUDIENCES = {
      ALL: 'ALL',
      AGENTS_AND_MANAGERS: 'AGENTS_AND_MANAGERS'
    };

    const AGENT_MENU_VIEWS = {
      MAIN: 'top-level-menu-main',
      AWAY_STATUSES: 'top-level-menu-filtered-away-statuses'
    };

    const ROUTE_PREFIX = {
      AW: '/aw',
      WA: '/wa'
    };

    return {
      LANGUAGE,
      LE_STATUS,
      SOURCE_ENUM,
      ROLES,
      ROLES_IDS,
      APP_ROOT_CONTAINER,
      NOTIFICATIONS,
      SYSTEM_NOTIFICATIONS,
      MODULES,
      COLORS,
      AC_ERRORS,
      LP_TAG_LOG_TYPES,
      LOG_LEVEL,
      EVENTS,
      FOCUS_STATUS,
      ROUTING,
      ENABLE_MY_PROFILE,
      ENVIRONMENTS,
      TOP_BAR_NOTIFICATION_TYPE,
      HTTP_METHOD,
      WALK_ME_IDS,
      WS,
      AGENT_SURVEY_SUPPORT,
      WRAP_UP_TIME,
      TRANSFER_MAX_WAIT_IN_QUEUE_TIME,
      DEFAULT_AGENT_STATUSES,
      TRAY_MENU_ITEMS_TYPE,
      ICON_STATUS_PREFIX,
      FEATURES,
      PRIVILEGES,
      AUDIENCES,
      AGENT_MENU_VIEWS,
      ROUTE_PREFIX
    };
  })();

  return CONST;
});
