<template>
  <span
    class="ftue-loading"
    :class="{
      'ftue-loading-small': size === 'small',
      'ftue-loading-default': size === 'default'
    }"
  >
    <span class="ftue-loading-dot"></span>
    <span class="ftue-loading-dot"></span>
    <span class="ftue-loading-dot"></span>
  </span>
</template>

<script>
export default {
  name: 'FirstTimeLoading',
  props: {
    size: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.ftue-loading {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.ftue-loading-default {
  @include themify {
    background-color: getColor('ftue-text-background', 'framework');
  }
  width: 51px;
  height: 58px;
  border-radius: 0 16px 16px 16px;
  padding: 16px;
}

.ftue-loading.ftue-loading-small {
  width: 12px;
  height: 12px;
}

.ftue-loading-small .ftue-loading-dot {
  width: 2px;
  height: 2px;
  animation-name: bounce-small;
}

.ftue-loading-dot {
  @include themify {
    background-color: getColor('ftue-text-color', 'framework');
  }
  width: 3px;
  height: 3px;
  border-radius: 50%;
  animation-name: bounce;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.ftue-loading-dot:nth-of-type(2) {
  animation-delay: 0.15s;
}

.ftue-loading-dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-3px);
  }
}
@keyframes bounce-small {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-2px);
  }
}
</style>
