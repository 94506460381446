<template>
  <div
    class="user-bar-top-level-menu"
    data-personalization="DefaultAgentStatus"
  >
    <agent-avatar
      ref="agentAvatar"
      data-personalization="agent-avatar"
      size="big"
      :image-url="loggedInUserDetails && loggedInUserDetails.pictureUrl"
      :agent-status-class="currentAgentStatusClass"
      :tooltip="currentAgentStatusTooltip"
      :aria-label="buttonLabel"
      aria-describedby="agent-details-section-login-name"
      :tooltip-text="currentAgentStatusTooltip"
      class="top-level-menu-switch"
      tooltip-position="right"
      :display-loader="statusChangeRequested"
      :aria-expanded="menuVisible || null"
      @clicked="toggleMenu"
    />
    <div
      v-if="hasStatusTimerUnderMenu"
      data-lp-at="data-agent-timer"
      class="agent-timer"
      tabindex="0"
      role="button"
      :aria-label="agentStatusTimer"
    >
      <span>
        {{
          agentTimer
        }}
      </span>
    </div>
    <div
      id="avatar-menu"
      ref="avatarMenu"
      class="top-level-menu"
      :style="{ display: menuDisplay }"
      :class="menuStateClass"
      tabindex="-1"
      role="menu"
      @keyup.esc="onEscape"
    >
      <div :class="['top-level-menu-main', { 'active': menuViewItem === CONST.AGENT_MENU_VIEWS.MAIN }]">
        <div class="menu-header-container">
          <div class="items">
            <div class="agent-avatar-section">
              <agent-avatar
                :image-url="loggedInUserDetails && loggedInUserDetails.pictureUrl"
                size="big"
                :is-button="false"
              />
            </div>
            <div class="agent-details-section">
              <div
                id="agent-details-section-login-name"
                data-lp-at="agent-details-section-login-name"
                class="agent-name"
                :title="loginName"
                :tooltip-text="loginName"
              >
                {{ loginName }}
              </div>
              <div
                data-lp-at="agent-details-section-account-id"
                class="top-level-menu-account-id"
              >
                {{ accountText }}: {{ accountId }}
              </div>
              <div
                v-if="trailAccountText.visible"
                class="trail-account-message"
                :title="trailAccountText.tooltip"
              >
                <div>{{ trailAccountText.text }}</div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
        <div :class="['top-level-menu-status-list-container', { 'has-loader': statusChangeRequested }]">
          <div
            v-if="statusChangeRequested"
            class="status-change-loader-container"
          >
            <le-loader
              class="status-change-loader"
              :size="40"
            />
          </div>
          <top-level-menu-status-list-view
            class="top-level-menu-status-list-component"
            :status-items="statusItems"
            :selected-item="selectedItem"
            :extended-custom-away-reasons="customAwayReasonList"
            :has-config-for-custom-max-size="hasConfigForCustomMaxSize"
            :selected-theme="selectedTheme"
            :custom-reasons-open="customReasonsOpen"
            :toggle-custom-reasons="toggleCustomReasons"
            @display-filtered-custom-away-satuses="onDispalyMenuItem"
            @clicked="onClickStatusItem"
          />
        </div>
        <top-level-menu-item-list-view
          v-if="!customReasonsOpen"
          :selected-theme="selectedTheme"
          @clicked="hideMenu"
        />
        <div
          v-if="!customReasonsOpen"
          class="menu-footer-container"
        >
          <naw-switch
            v-show="showNewAgentWorkspaceInvitationRevert"
            class="menu-footer-item menu-footer-item-naw-invitation enabled first"
            :invitation-bubble-storage-key="invitationBubbleStorageKey"
            :logged-in-user-details="loggedInUserDetails"
            :show-new-agent-workspace-invitation-revert="showNewAgentWorkspaceInvitationRevert"
          />
          <div
            class="menu-footer-item sound enabled"
            :class="{ 'first': !showNewAgentWorkspaceInvitationRevert }"
          >
            <!--            <div class="sound-button-ico ico-sound-on" :title="soundButtonTooltip" :tooltip-text="soundButtonTooltip" tooltip-position="right"></div>-->
            <div
              class="tlm-s-icon s-volume-max"
              :data-lp-at="soundButtonText + `sound-icon`"
            ></div>
            <div
              class="item-title ellipsis"
              :data-lp-at="soundButtonText + `sound-button-text`"
            >
              {{ soundButtonText }}
            </div>
            <!--Sound On /Sound Off-->
            <ring-selector-dialog
              v-show="showCustomRingsFeature"
              class="enabled custom-rings-settings first"
              :disabled="!soundEnabled"
              :set-ring="currentRing"
              :supported-rings="getSupportedRings()"
              @input="updateRing"
              @clicked="hideMenu"
            />
            <div class="item-indicator">
              <toggle-switch
                data-lp-at="user-menu-sound-toggle-switch"
                :state="soundEnabled"
                @on-switch="onSoundButtonClicked"
              />
            </div>
          </div>

          <div
            v-if="themesItems.length > 1"
            class="menu-footer-item themes enabled"
          >
            <themes-selector
              class="themes-selector"
              :items="themesItems"
              :selected-item="selectedTheme"
              @input="updateTheme"
            />
          </div>
          <div
            class="menu-footer-item logout enabled"
            role="button"
            tabindex="0"
            @click="onAuthButtonClicked"
            @keydown.enter.space.prevent="onAuthButtonClicked"
          >
            <div
              class="logout-wrapper"
              tabindex="0"
            >
              <div class="tlm-s-icon s-logout"></div>
              <div
                class="top-level-menu-auth-button"
                :v-tooltip="authItemText"
              >
                <a
                  role="button"
                  class="le-link btn-link"
                  tabindex="-1"
                >{{ authItemText }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <top-level-menu-filtered-status-list-view
        :class="['top-level-menu-filtered-away-statuses', { 'active': menuViewItem === CONST.AGENT_MENU_VIEWS.AWAY_STATUSES }]"
        :custom-away-reason-list="customAwayReasonList"
        @return-back="onDispalyMenuItem"
        @clicked="onClickStatusItem"
      />
    </div>
    <div
      v-if="showNewAgentWorkspaceMenuBubble"
      class="new-agent-workspace-menu-bubble"
    >
      <div class="new-agent-workspace-menu-bubble-text">
        {{ $translator.translate("LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.bubble.text") }}
      </div>
      <div
        class="new-agent-workspace-menu-bubble-button"
        tabindex="0"
        role="button"
        @click="showNewAgentWorkspaceMenuBubble = false"
        @keydown.enter.space.prevent="showNewAgentWorkspaceMenuBubble = false"
      >
        {{ $translator.translate("LEFramework.userBarMenu.footer.newAgentWorkspaceInvitationRevert.bubble.button") }}
      </div>
    </div>
    <teleport to="#fe-root-mounting-portal-target">
      <le-dialog
        v-if="statusDiscrepancyDialogOpen"
        :header="$translator.translate('LEWebAgent.agentStatusDiscrepancyErrorTitle')"
        :description="$translator.translate('LEWebAgent.agentStatusDiscrepancyErrorDescription')"
        :primary-button-text="$translator.translate('LEWebAgent.ok')"
        class="theme-light"
        :z-index="3000"
        width="450px"
        @primary-button-click="closeDiscrepancyDialog"
        @cancel-click="closeDiscrepancyDialog"
      />
    </teleport>
  </div>
</template>

<script>
import TopLevelMenuStatusListView from './TopLevelMenuStatusListView';
import TopLevelMenuItemListView from './TopLevelMenuItemListView';
import AgentAvatar from '../AgentAvatar';
import ToggleSwitch from '../../simplification/ToggleSwitch';
import { leDialog, leLoader } from '@liveperson/lp-vue-components';
import ThemesSelector from './ThemesSelector'
import LEConfig from 'assets/config/le-env-conf';
import CONST from 'const';
import {
  ACClient,
  AGENT_STATUS_DATA_STATES,
  environmentActionTypes,
  environmentGetterTypes,
  environmentStateKeys,
  gChannel,
  Logger,
  lpStorage,
  sessionActionTypes,
  sessionManager,
  storeModuleTypes,
  Vuex,
} from "vue-infra";
import lpUtils from 'utils/lpUtils';
import NawSwitch from './NawSwitch'
import RingSelectorDialog from './RingSelectorDialog'
import Settings from 'assets/data/codeTables/settings.json';
import ApiResources from "assets/data/apiResources.json";
import LocaleResolver from 'i18n/localeResolver';
import AgentStatuses from './mixins/AgentStatuses';
import TopLevelMenuFilteredStatusListView from './custom-filtered-away-reason/TopLevelMenuFilteredStausListView'

const SESSION = storeModuleTypes.SESSION,
  ENVIRONMENT = storeModuleTypes.ENVIRONMENT,
  SET_VALUE = sessionActionTypes.SET_VALUE;

var logger = Logger.getLogger("LiveEngage:TopLevelMenu");

const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
  soundEnable: environmentGetterTypes.RESOLVE_SOUND_ENABLE,
  agentStatus: environmentGetterTypes.CURRENT_AGENT_STATUS,
  agentTimer: environmentGetterTypes.AGENT_TIMER,
  trailInformation: environmentGetterTypes.RESOLVE_LE_TRAIL_INFORMATION,
  awayReasons: environmentGetterTypes.RESOLVE_CUSTOM_AWAY_REASONS,
  loggedInUserDetails: environmentGetterTypes.LOGGED_IN_USER_DETAILS,
  validApps: environmentGetterTypes.VALID_APPS,
  currentActiveTheme: environmentGetterTypes.CURRENT_ACTIVE_THEME,
  currentRing: environmentGetterTypes.CURRENT_RING,
  requestAgentStatusChange: environmentGetterTypes.REQUEST_AGENT_STATUS_CHANGE,
  agentStatusDataState: environmentGetterTypes.AGENT_STATUS_DATA_STATE
});
const actions = Vuex.mapActions(storeModuleTypes.ENVIRONMENT,
  { outerRouteUpdate: environmentActionTypes.OUTER_ROUTE_UPDATE });
let outsideClickHandler;

export default {
  name: 'TopLevelMenu',
  components: {
    TopLevelMenuStatusListView,
    TopLevelMenuItemListView,
    AgentAvatar,
    ThemesSelector,
    ToggleSwitch,
    NawSwitch,
    RingSelectorDialog,
    leLoader,
    leDialog,
    TopLevelMenuFilteredStatusListView,
  },
  mixins: [AgentStatuses],
  data() {
    return {
      customReasonsOpen: false,
      baseSiteSettings: undefined,
      selectedTheme: undefined,
      selectedRing: undefined,
      themesItems: [],
      currentAgentStatusClass: '',
      currentAgentStatusTooltip: '',
      menuVisible: false,
      menuViewItem: CONST.AGENT_MENU_VIEWS.MAIN,
      soundButtonAppear: true,
      soundButtonTooltip: '',
      soundButtonText: '',
      authItemText: this.$translator.translate("LEFramework.userBarMenu.menuItem.logout"),
      accountText: this.$translator.translate("LEFramework.userBarMenu.header.account"),
      menuStateClass: 'closed',
      trailAccountText: {
        text: '',
        visible: false,
        tooltip: this.$translator.translate("LEFramework.userBarMenu.trailAccount.tooltip.msg")
      },
      showNewAgentWorkspaceMenuBubble: true,
      statusDiscrepancyDialogOpen: false,
      CONST,
    }
  },
  computed: {
    agentTimer: getters.agentTimer,
    agentStatusTimer() {
      return `Time on current status is ${this.agentTimer}`;
    },
    hasStatusTimerUnderMenu() {
      // AC FEATURE
      const agentStatusTimer = LE.sessionManager.hasAgentTimer();

      // PERMISSION
      const viewAgentStatusTimer = LE.siteSettings.privilegeSettings.hasAgentTimerPrivilege();

      // SITE SETTING
      const agentStatusTimerUnderMenu = LE.sessionManager.getAccountSettingValueByID(
        Settings.SETTING_ID.AGENT_STATUS_TIMER_UNDER_MENU);

      return (agentStatusTimer && viewAgentStatusTimer && agentStatusTimerUnderMenu === 'true');
    },
    menuDisplay() {
      return this.menuVisible ? 'block' : 'none';
    },
    loginName: () => sessionManager.getLoginName(),
    accountId: () => sessionManager.getAccountId(),
    showTrailMenuTrayItem: () => sessionManager.hasLiveEngage2Feature() && sessionManager.hasPrivilege(
      sessionManager.PRIVILEGES.ACCOUNT_BILLING),
    soundEnabled: getters.soundEnable,
    trailInformation: getters.trailInformation,
    currentStatus: getters.agentStatus,
    customAwayReasons: getters.awayReasons,
    loggedInUserDetails: getters.loggedInUserDetails,
    validApps: getters.validApps,
    currentActiveTheme: getters.currentActiveTheme,
    currentRing: getters.currentRing,
    requestAgentStatusChange: getters.requestAgentStatusChange,
    agentStatusDataState: getters.agentStatusDataState,
    invitationBubbleStorageKey() {
      return `${this.accountId}_${this.loginName}_newAgentWorkspaceRevertBubble`
    },
    isNewAgentWorkspaceVisible() {
      return this.validApps.indexOf('aw') !== -1;
    },
    showNewAgentWorkspaceInvitationRevert() {
      const isNewAgentWorkspaceVisible = this.isNewAgentWorkspaceVisible;
      const isLPA = sessionManager.isLPA();
      const hasNewAgentWorkspaceForce = sessionManager.getFeaturePropertyState(
        CONST.FEATURES.ENFORCE_NEW_AGENT_WORKSPACE);
      const hasNewAgentWorkspacePrivilege = sessionManager.hasPrivilege(CONST.PRIVILEGES.VIEW_NEW_AGENT_WORKSPACE);
      return (isNewAgentWorkspaceVisible && !isLPA && !hasNewAgentWorkspaceForce && !hasNewAgentWorkspacePrivilege);
    },
    statusChangeRequested() {
      return this.agentStatusDataState &&
        (this.agentStatusDataState === AGENT_STATUS_DATA_STATES.NOT_REQUESTED ||
          (this.agentStatusDataState === AGENT_STATUS_DATA_STATES.AWAITING_DENVER ||
            this.agentStatusDataState === AGENT_STATUS_DATA_STATES.AWAITING_UMS));
    },
    showCustomRingsFeature() {
      const isNewAgentWorkspaceVisible = this.isNewAgentWorkspaceVisible;
      const hasCustomRingsFeature = sessionManager.getFeaturePropertyState(CONST.FEATURES.USE_CUSTOM_RING);
      const isCustomRingsSiteSettingEnabled = sessionManager.getAccountSettingValueByID(
        Settings.SETTING_ID.CUSTOM_RINGS_ENABLED) === 'true';
      return isNewAgentWorkspaceVisible && hasCustomRingsFeature && isCustomRingsSiteSettingEnabled;
    },
    buttonLabel() {
      if (this.$translator.hasTranslationKey(LocaleResolver.getLocale(), 'LEFramework.userBarMenu.button.label')) {
        return this.$translator.translate('LEFramework.userBarMenu.button.label')
          .replace('{0}', this.currentAgentStatusTooltip);
      }
      return this.currentAgentStatusTooltip;
    },
  },

  watch: {
    menuVisible(newValue) {
      if (newValue) {
        gChannel.trigger('LiveEngageCommon', 'brand:getProfile');
        this.updateUI();
        this.showNewAgentWorkspaceMenuBubble = false;
      }
    },
    trailInformation() {
      this.buildTrialTrayItem();
    },
    currentStatus() {
      this.updateAgentAvatarStatus();
    },
    agentStatusDataState: {
      handler: function () {
        if (this.statusChangeRequested) {
          this.currentAgentStatusClass = CONST.DEFAULT_AGENT_STATUSES.unknown.iconClass;
          this.currentAgentStatusTooltip = this.$translator.translate('LEWebAgent.processingStatus');
        } else if (this.agentStatusDataState === AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY) {
          this.currentAgentStatusTooltip = this.$translator.translate('LEWebAgent.statusDiscrepancy');
          this.currentAgentStatusClass = CONST.DEFAULT_AGENT_STATUSES.unknown.iconClass;
          this.statusDiscrepancyDialogOpen = true;
        } else {
          this.updateAgentAvatarStatus();
          if (this.agentStatusDataState === AGENT_STATUS_DATA_STATES.STATUS_UPDATE_FAILED) {
            LE.notifier.showError({
              content: this.$translator.translate('LEWebAgent.agentStatusChangeFailed'),
              timeToShow: 6000
            });
          }
        }
      },
      immediate: true,
    }
  },
  async created() {
    const url = ApiResources.AccountConfig.SiteSettings.url;
    const siteSettingsUrl = window.LE.urlResolver.getUrl(url);
    const sessionconfig = sessionManager.getConfig();
    this.baseSiteSettings = new ACClient({
      allowedMethods: ['get', 'post'],
      config: {
        baseURL: siteSettingsUrl,
        params: {
          context_type: Settings.CONTEXT_TYPE.USER,
          context_id: sessionconfig.userId,
          context_cascading: false,
          v: ApiResources.AccountConfig.SiteSettings.version
        },
      },
    });

    if (!sessionManager.isAuthenticated()) {
      this.$store.dispatch(`${SESSION}/${SET_VALUE}`, {
        attribute: environmentStateKeys.LOGIN_NAME,
        value: this.$translator.translate("LEFramework.userBarMenu.menuItem.login")
      });
    }
    this.showNewAgentWorkspaceMenuBubble = this.showNewAgentWorkspaceInvitationRevert && (lpStorage.localStorage(
      this.invitationBubbleStorageKey) === 'true');
    if (this.showNewAgentWorkspaceMenuBubble) {
      lpStorage.localStorage(this.invitationBubbleStorageKey, 'false');
    }

    this.themesItems = this.calculateSupportedThemes();
    this.selectedTheme = await this.setCurrentTheme();
    outsideClickHandler = event => this.checkForOutsideClick(event);
    this.updateUI();
    this.buildTrialTrayItem();
    // set theme for the first time.
  },
  mounted() {
    gChannel.register('LiveEngageCommon', 'logout', this.onAuthButtonClicked);
    gChannel.register('LiveEngageCommon', 'logout:cancelled', this.onLogoutCancelled);


  },
  methods: {
    toggleCustomReasons: function () {
      this.customReasonsOpen = !this.customReasonsOpen;
    },
    calculateSupportedThemes() {
      const defaultSupportedThemes = ['dark'];
      // TODO: [AE-6656] - remove the usage le.ui.supportedThemes till all account will be updated with ['dark', 'light']
      // const supportedThemes = sessionManager.getAccountSettingValueByID('le.ui.supportedThemes', defaultSupportedThemes);
      let supportedThemes = ['light', 'dark'];
      // let supportedThemes = sessionManager.getAccountSettingValueByID('le.ui.supportedThemes', defaultSupportedThemes);
      // TODO: remove when all costumers working with multiple themes
      if (LEConfig.multipleThemesSupportEnabled === false) {
        supportedThemes = defaultSupportedThemes;
      }
      return supportedThemes.map(theme => {
        let themeName = '';
        if (theme === 'dark') {
          themeName = this.$translator.translate("LEFramework.userBarMenu.themes.dark")
        } else {
          themeName = this.$translator.translate("LEFramework.userBarMenu.themes.light")
        }
        return {
          keyName: theme,
          key: `theme-${theme}`,
          value: themeName,
          indicatorClass: `theme-${theme}-indicator`
        }
      });
    },
    async setCurrentTheme() {
      const selectedTheme = this.themesItems.find((theme) => {
        return theme.keyName === this.currentActiveTheme;
      });
      $('body').addClass(selectedTheme.key);

      return selectedTheme;
    },
    async updateTheme(item) {

      if (this.selectedTheme !== item) {
        $('body').addClass(item.key).removeClass(this.selectedTheme.key);
        this.selectedTheme = item;
        logger.bam({
          serviceName: 'CCUI-CORE',
          appName: 'CCUI',
          eventName: 'theme selected',
          widgetName: 'user-menu',
          value: item,
        });

        this.$store.dispatch(`${ENVIRONMENT}/${SET_VALUE}`, {
          attribute: environmentStateKeys.CURRENT_ACTIVE_THEME,
          value: item,
        });
        try {
          await this.baseSiteSettings.patch(undefined, [{
            id: Settings.SETTING_ID.GENERAL_THEME,
            type: 2,
            propertyValue: { value: `${item.keyName}` },
            deleted: false,
          }], {
            revision: -1,
          });
        } catch (e) {
          logger.error(`Can not update Theme,${e}`);
        }
      }
    },
    getSupportedRings() {
      return LE.sessionManager.getAccountSettingValueByID(Settings.SETTING_ID.SUPPORTED_RINGS) ||
        ['acoustic', 'bass', 'clean', 'distortion', 'lead', 'organ', 'overdrive', 'synth'];
    },
    async updateRing(item) {
      logger.bam({
        serviceName: 'CCUI-CORE',
        appName: 'CCUI',
        eventName: 'custom ring changed',
        widgetName: 'user-menu',
        value: item.selectedRing,
      });
      this.$store.dispatch(`${ENVIRONMENT}/${SET_VALUE}`, {
        attribute: environmentStateKeys.CURRENT_RING,
        value: item.selectedRing,
      });
      try {
        this.baseSiteSettings.patch(undefined, [{
          id: Settings.SETTING_ID.GENERAL_RING,
          type: 2,
          propertyValue: { value: `${item.selectedRing}` },
          deleted: false,
        }], {
          revision: -1,
        });
      } catch (e) {
        logger.error(`Can not update Ring,${e}`);
      }
      item.resetCallback();
    },
    outerRouteUpdate: actions.outerRouteUpdate,
    buildTrialTrayItem() {
      let remainingDays = this.trailInformation && this.trailInformation.remainingDays;
      let oneSeat = LEConfig.Billing.oneSeatEnabled;
      let freemiumDaysBarrier = LEConfig.Billing.freemiumDaysBarrier;
      let isFreemium = oneSeat && remainingDays > freemiumDaysBarrier;

      let text = this.$translator.translate("LEFramework.userBarMenu.menuItemTray.TrialAccount");
      if (isFreemium) {
        text = '';
      } else {
        if (remainingDays !== "undefined" && remainingDays !== undefined) {
          text = (remainingDays === 1) ? this.$translator.translate(
            "LEFramework.userBarMenu.menuItemTray.TrialAccountDayLeft") : this.$translator.translate(
            "LEFramework.userBarMenu.menuItemTray.TrialAccountDaysLeft");
          text = text.replace("{0}", remainingDays);
        }
      }

      this.trailAccountText = {
        text,
        visible: this.showTrailMenuTrayItem && this.trailInformation.trial,
        tooltip: this.$translator.translate("LEFramework.userBarMenu.trailAccount.tooltip.msg")
      }
    },
    onClickStatusItem(item) {
      this.updateAgentStatus(item);
      this.hideMenu();

      if (this.hasConfigForCustomMaxSize) {
        this.onDispalyMenuItem(CONST.AGENT_MENU_VIEWS.MAIN);
      }
    },
    hideMenu() {
      this.menuStateClass = 'closed';
      lpUtils.removeEventListener(document, 'click', outsideClickHandler);
      setTimeout(() => this.menuVisible = false, 400);
    },
    showMenu() {
      this.menuVisible = true;
      lpUtils.addEventListener(document, 'click', outsideClickHandler);
      setTimeout(() => {
        this.menuStateClass = 'open';
        if (this.$refs && this.$refs.avatarMenu) {
          this.$refs.avatarMenu.focus();
        }
      }, 250);
      logger.bam({
        serviceName: 'CCUI-CORE',
        appName: 'CCUI',
        eventName: 'open user menu',
        widgetName: 'user-menu',
      });
    },
    toggleMenu() {
      if (this.menuVisible) {
        this.hideMenu();
      } else {
        this.showMenu();
      }
    },
    onEscape() {
      if (this.menuVisible) {
        this.hideMenu();
      }
    },
    async onSoundButtonClicked() {
      const newValue = !this.soundEnabled;
      this.$store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${SET_VALUE}`, {
        attribute: environmentStateKeys.LE_SOUND_ENABLE,
        value: newValue
      });

      logger.bam({
        serviceName: 'CCUI-CORE',
        appName: 'CCUI',
        eventName: 'sound toggled',
        widgetName: 'user-menu',
        value: newValue,
      });
      try {
        await this.baseSiteSettings.patch(undefined, [{
          id: Settings.SETTING_ID.SOUND,
          type: 2,
          propertyValue: { value: newValue },
          deleted: false,
        }], {
          revision: -1,
        });
      } catch (e) {
        logger.error(`Can not update sound value,${e}`);
      }
    },
    onLogoutCancelled() {
      if (this.$refs && this.$refs.agentAvatar && this.$refs.agentAvatar.$el) {
        this.$refs.agentAvatar.$el.focus();
      }
      logger.bam({
        serviceName: 'CCUI-CORE',
        appName: 'CCUI',
        eventName: 'logout cancelled',
        widgetName: 'user-menu',
      });
    },
    onAuthButtonClicked() {
      logger.bam({
        serviceName: 'CCUI-CORE',
        appName: 'CCUI',
        eventName: 'logout clicked',
        widgetName: 'user-menu',
      });
      this.hideMenu();
      const authenticated = sessionManager.isAuthenticated();
      if (!authenticated) {
        // Remove the outerRoute so it will not be processed and added to login redirect
        this.outerRouteUpdate('');
        // window.LE.loginInfo.outerRoute = "";
      }
      window.LE.logout(authenticated);
    },
    updateUI() {
      this.soundButtonAppear = sessionManager.isAuthenticated();
      this.soundButtonTooltip = this.$translator.translate(
        "LEFramework.userBarMenu.footer.soundButton.tooltip." + (this.soundEnabled ? "on" : "off"));
      this.soundButtonText = this.$translator.translate(
        "LEFramework.userBarMenu.sound.general") + ":";
    },
    checkForOutsideClick(event) {
      if (this.menuVisible && !(this.$el.contains(event.target) && event.target.className.indexOf(
        'quick-launch-icon') === -1)) {
        this.hideMenu();
      }
    },
    closeDiscrepancyDialog() {
      this.statusDiscrepancyDialogOpen = false;
    },
    updateAgentAvatarStatus() {
      if (this.statusChangeRequested) {
        this.currentAgentStatusClass = CONST.DEFAULT_AGENT_STATUSES.unknown.iconClass;
        this.currentAgentStatusTooltip = this.$translator.translate('LEWebAgent.processingStatus');
      } else {
        const val = this.currentStatus.value.toLowerCase();
        if (CONST.DEFAULT_AGENT_STATUSES[val]) {
          const translatorParameter = this.agentStatusDataState === AGENT_STATUS_DATA_STATES.STATUS_DISCREPANCY ?
            'statusDiscrepancy' : CONST.DEFAULT_AGENT_STATUSES[this.currentStatus.value.toLowerCase()].translatorParameter;
          let tooltipText = this.$translator.translate(`LEWebAgent.${translatorParameter}`);
          if (this.currentStatus.reasonId) {
            const awayReason = this.customAwayReasons.filter(reason => reason.id == this.currentStatus.reasonId);
            if (awayReason.length > 0) {
              tooltipText = awayReason[0].text;
            }
          }
          this.currentAgentStatusClass = CONST.DEFAULT_AGENT_STATUSES[this.currentStatus.value.toLowerCase()].iconClass;
          this.currentAgentStatusTooltip = tooltipText;
        }
      }
    },

    onDispalyMenuItem(view) {
      if (!view) {return;}
      this.menuViewItem = view;
    }
  }
};

</script>
<style lang='scss' scoped>

@import "../../../../assets/scss/global";
@import "../../../../assets/scss/mixins";

.tlm-s-icon {
  width: 16px;
  height: 16px;
}

$icons: s-volume-max, s-logout;
@include add-icons-from-list($icons);

.agent-timer {
  display: flex;
  justify-content: center;

  min-width: 50px;
  min-height: 14px;
  margin-top: 2.5px;
  margin-left: 2px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  line-height: 18px;
  border-radius: 14px;
  position: absolute;
  bottom: -10px;
  @include themify {
    color: getColor('user-menu-timer', 'framework');
    background-color: getColor('user-menu-timer-background', 'framework');
    border: solid 1.5px getColor('user-menu-timer-border', 'framework');
  }


}

.user-bar-top-level-menu {
  padding-left: 8px;

  .top-level-menu {
    &.open {
      opacity: 1;
    }

    &.closed {
      opacity: 0; // can move it to the root level
    }
  }
}

.theme-dark-indicator {
  @include themify {
    color: getColor('user-menu-theme-dark-indicator', 'framework');
  }
}

.theme-light-indicator {
  color: getColor('user-menu-theme-light-indicator', 'framework');
}

.user-bar-top-level-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-header-container {
  padding: 16px 6px 0 6px;

  .items {
    .agent-avatar-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 22px;
    }

    .agent-details-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 22px;
      padding-top: 10px;
      font-size: 12px;

      .top-level-menu-account-id {
        float: left;
        @include themify {
          color: getColor('user-menu-text', 'framework');
        }
      }

      .trail-account-message {
        @include themify {
          color: getColor('user-menu-text', 'framework');
        }
      }

      .agent-name {
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        font-size: 14px;
        text-align: left;
        @include themify {
          color: getColor('user-menu-text', 'framework');
        }
        cursor: pointer;
      }
    }
  }
}

.top-level-menu-item-list-container {
  padding: 0 6px;
}

.top-level-menu-status-list-container {
  padding: 18px 0 0 0;
  position: relative;

  &.has-loader .top-level-menu-status-list-component {
    opacity: 0.2;
    pointer-events: none;
  }

  .status-change-loader-container {
    //position: absolute;
    display: block;
    z-index: 1;
    width: 100%;
    height: 100%;

    .status-change-loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

.menu-footer-container {
  min-width: 260px;

  .menu-footer-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 16px 0 16px;
    min-height: 38px;
    line-height: 38px;
    cursor: pointer;
    white-space: nowrap; // prevent menu item from flowing into more than one line

    &:focus{
      @include themify(null, 'show-a11y-focus-outline') {
        box-shadow: 0 0 0 4px getColor('user-menu-item-away-reasons-border-chosen', 'framework') !important;
      }
    }

    @include transition-duration(100ms);
    @include transition-property(background-color, color);
    @include transition-timing-function(swing);

    &.sound {
      display: flex;
      align-items: center;
      cursor: default;

      &:hover {
        @include themify {
          background-color: transparent;
        }
      }
    }

    &.themes {
      @include themify {
        border-top: 1px getColor('user-menu-item-border-color', 'framework') solid;
        border-bottom: 1px getColor('user-menu-item-border-color', 'framework') solid;
      }
    }

    &.logout {
      min-height: 50px;
      padding: 0 6px;

      .logout-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        padding-left: 10px;
        border-radius: 6px;

        &:focus {
          @include themify(null, 'show-a11y-focus-outline') {
            background-color: getColor('user-menu-item-hover', 'framework');
          }
        }

        &:hover {
          @include themify {
            background-color: getColor('user-menu-item-hover', 'framework');
          }
        }
      }
    }

    &.first {
      //border-top: none;
    }

    .item-title {
      float: left;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      max-width: 150px;
      flex: 2;
      @include themify {
        color: getColor('user-menu-text', 'framework');
      }
    }

    .item-indicator {
      position: relative;
      font-weight: normal;
      margin-left: auto;
    }

    &.menu-footer-item-naw-invitation {
      &:hover {
        @include themify {
          background-color: getColor('user-menu-item-hover', 'framework');
        }
      }

      .item-title {
        max-width: 250px;
      }
    }

    .sound-button-ico {
      margin: 0 16px 0 7px;
      font-size: 17px;
      @include themify {
        color: getColor('user-menu-status-icon-color', 'framework');
      }
    }

    .auth-button-img {
      width: 22px;
      height: 18px;
      display: flex;
      align-items: center;
      @include themify {
        color: getColor('user-menu-text', 'framework');
      }
    }

    .btn-link {
      font-size: 14px;
      line-height: 20px;

      &:visited, &:link, &:active, &:hover {
        text-decoration: none !important;
      }

      @include themify {
        color: getColor('user-menu-text-logout', 'framework');
      }
    }
  }
}
</style>

