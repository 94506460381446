/**
 * Created with IntelliJ IDEA.
 * User: omerh
 * Date: 4/29/13
 * Time: 9:45 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";
    var BaseModel = require("models/BaseModel");
  var _ = require("underscore");
    var CONST = require("const");
    var DateUtility = require("utils/DateUtility");

    var Context = BaseModel.extend({
        defaults: {
            siteId: "", // siteID of the user
            persona: "", // persona type the user is login
            sessionKey: null, // session Key after login
            loggedin: false, // true if the user is login, false otherwise,

            // persona states
            personaState: {
                agent: {
                    errors: 0,
                    warnings: 0,
                    info: 0
                },
                marketer: {
                    errors: 0,
                    warnings: 0,
                    info: 0
                }
            },

            // information about the login user
            user: {
                name: "", // name of the user
                language: CONST.LANGUAGE['en-US'], // language of the user
                lastTimeLogIn: null, // last date user login into the application
                nickName: "", // nickname of the user
                isTrial: false,
                remainingDays: -1
            },
            // to use for every module
            areaState: {
                connect: {
                },
                webAgent: {
                    isOnVisitorList: true
                },
                campaign: {
                    selectedItem: -1
                },
                reporting: {
                    campaignsTimeframeObj: null
                }
            },
            modulesNavigation: {
                activeModule: {fixedRoute: null}
            },
            displayedModuleName: null, // the most top visible module (not including connection panel)
            googleAnalyticsConnectionStatus: null
        },

        initialize: function (attributes, options) {
            this.App = options.application;
            this.setReportVals();
            this._handleVisibilityChange();
            this._handleWindowFocusChange();
        },

        getDisplayedModule: function() {
            return this.get("displayedModuleName");
        },
        setDisplayedModule: function(displayedModuleName) {
            this.set("previousDisplayedModuleName", this.get("displayedModuleName"));
            this.set("displayedModuleName", displayedModuleName);
        },
        resetDisplayedModule: function() {
            var previousDisplayedModuleName = this.get("previousDisplayedModuleName");
            previousDisplayedModuleName = previousDisplayedModuleName || this.get("activeModuleName");

            this.setDisplayedModule(previousDisplayedModuleName);
        },

        // addNavigationBackRoute: function(routeObj) {
        //     var navigationBackRouteStack = this.get("navigationBackRouteStack") || [];
        //     navigationBackRouteStack.push(routeObj);
        //     this.set("navigationBackRouteStack", navigationBackRouteStack);
        // },

        // popLastNavigationBackRoute: function() {
        //     var navigationBackRouteStack = this.get("navigationBackRouteStack") || [];
        //     var lastRoute = navigationBackRouteStack.pop();
        //     this.set("navigationBackRouteStack", navigationBackRouteStack);
        //     return lastRoute;
        // },

        // clearNavigationBackRoutes: function() {
        //     this.set("navigationBackRouteStack", []);
        // },

        setReportVals: function () {
            var currDate = new Date();
            var fromDate = new Date();
            fromDate.setDate(currDate.getDate() - 30);
            this.set("areaState.reporting.campaignsTimeframeObj", {fromdate: DateUtility.date2StrddMMYYYY(fromDate), todate: DateUtility.date2StrddMMYYYY(currDate)});
        },

        focusStatus: CONST.FOCUS_STATUS.VISIBLE_FOCUSED,
        isHidden: false,
        isWindowFocused: true,
        setFocusStatus: function (options) {
            if (!_.isUndefined(options.isHidden)) {
                this.isHidden = options.isHidden;
            }
            if (!_.isUndefined(options.isWindowFocused)) {
                this.isWindowFocused = options.isWindowFocused;
            }

            var newFocusStatus = this.calculateFocusStatus();
            if (this.focusStatus !== newFocusStatus) {
                this.focusStatus = newFocusStatus;
                if (this.App) {
                    this.App.vent.trigger("focusStatus:change", this.focusStatus);
                }
            }
        },
        calculateFocusStatus: function () {
            var focusStatus = CONST.FOCUS_STATUS.VISIBLE_FOCUSED;
            if (this.isHidden) {
                focusStatus = CONST.FOCUS_STATUS.NOT_VISIBLE;
            }
            else if (!this.isWindowFocused) {
                focusStatus = CONST.FOCUS_STATUS.VISIBLE_NOT_FOCUSED;
            }
            return focusStatus;
        },

        _handleVisibilityChange: function () {
            var that = this;
            var hidden, visibilityChange;
            if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
                hidden = "hidden";
                visibilityChange = "visibilitychange";
            } else if (typeof document.mozHidden !== "undefined") {
                hidden = "mozHidden";
                visibilityChange = "mozvisibilitychange";
            } else if (typeof document.msHidden !== "undefined") {
                hidden = "msHidden";
                visibilityChange = "msvisibilitychange";
            } else if (typeof document.webkitHidden !== "undefined") {
                hidden = "webkitHidden";
                visibilityChange = "webkitvisibilitychange";
            }

            document.addEventListener(visibilityChange, function () {
                that.setFocusStatus({isHidden: document[hidden]});
            });
        },
        _handleWindowFocusChange: function () {
            var that = this;
            window.addEventListener("focus", function () {
                that.setFocusStatus({isWindowFocused: true});
            });
            window.addEventListener("blur", function () {
                that.setFocusStatus({isWindowFocused: false});
            });
        },
        getFocusStatus:function(){
            return this.focusStatus;
        }

    });

    return Context;
});
