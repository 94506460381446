<template>
  <div
    v-if="!isNavBarOpen"
    :class="{
      'nav-bar-category-label': true,
      'nav-bar-category-label__selected': this.selectedCategoryIndex === this.index
    }"
    :data-lp-at="`list-title-below-${index}`"
    :id="`${app.languageKey}-id`"
  >
    {{ $translator.translate('LEFramework.' + app.languageKey) }}
  </div>
</template>

<script>
export default {
  name: 'NavBarCategoryLabel',
  props: {
    isNavBarOpen: Boolean,
    app: Object,
    index: Number,
    selectedCategoryIndex: Number
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/_mixins';

.nav-bar-category-label {
  @include themify {
    color: getColor('s-nav-bar-text-color', 'framework');
  }
  margin-top: 8px;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  line-height: 16px;

  &__selected {
    @include themify {
      color: getColor('s-nav-bar-text-color-selected', 'framework') !important;
    }
  }
}
</style>
