import ApiResources from '../../assets/data/apiResources';
import { BaseClient, APILayerConsts } from "vue-infra";

  const RESOURCES = {
    AGENT_VIEW: 'AgentView',
  };

  const RESOURCES_CONFIGURATION = {
    [RESOURCES.AGENT_VIEW]: {
      ALLOWED_METHODS: APILayerConsts.CRUDMethods.POST,
      TIMEOUT: 10000,
    },
  };

class ManagerWorkspaceClient extends BaseClient {

  constructor(resource) {
    const infraBaseClientOptions = createInfraBaseOptions(resource);
    super(infraBaseClientOptions);
  }
}

  export function createInfraBaseOptions(resource) {
    const urlResolver = window.LE.urlResolver;
    const urlFromApiResources = ApiResources.AgentManagerWorkspace.AgentView.url;

    const baseURL = urlResolver.getUrl(urlFromApiResources);

    return {
      allowedMethods:[RESOURCES_CONFIGURATION[resource].ALLOWED_METHODS],
      config: {
        baseURL,
        timeout: RESOURCES_CONFIGURATION[resource].TIMEOUT,
      },
      retryConfig: {
        retries: 20,
        retryCondition: () => true,
        retryDelay: () => 5000,
        shouldResetTimeout: true,
      },
    };
  }

ManagerWorkspaceClient.RESOURCES = RESOURCES;

export default ManagerWorkspaceClient;
