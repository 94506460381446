define(function (require) {
    const $ = require("jquery");
    const _ = require("underscore");
    const { store } = require('vue-infra');
    const { sessionModel } = require('vue-infra');
    const { LOGIN_COMMANDS } = require('vue-infra').sessionKeys;
    const { SESSION } = require('vue-infra').storeModuleTypes;
    const { GET_VALUE } = require('vue-infra').sessionGetterTypes;

    const { IS_VALID_STATE_ATTRIBUTE } = require('vue-infra').sessionGetterTypes;

    const TransportPrefiltersAdapter = (function () {
        var decodeRE = /\+/g;  // Regex for replacing addition symbol with a space
        var re = /([^&=]+)=?([^&]*)/g;

        var TOKEN_DEFAULTS = {
            CSRF: {
                header: {
                    key: "X-CSRF-Token",
                    format: "{token}"
                },

                query: {
                    key: "X-CSRF-Token",
                    format: "{token}"
                },
                body: {
                    key: "X-CSRF-Token",
                    format: "{token}"
                }
            },
            GLOB: {
                header: {
                    key: "Authorization",
                    format: "Bearer {token}"
                },
                query: {
                    key: "access_token",
                    format: "{token}"
                },
                body: {
                    key: "access_token",
                    format: "{token}"
                }
            },
            WSUK: {
                header: {
                    key: "X-WSUK-Token",
                    format: "{token}"
                },
                query: {
                    key: "X-WSUK-Token",
                    format: "{token}"
                },
                body: {
                    key: "X-WSUK-Token",
                    format: "{token}"
                }
            }
        };
        var OPTION_KEYS = {
            CSRF: "csrf",
            GLOB: "glob",
            WSUK: "wsuk"
        };
        var validTokenKeys = ["CSRF", "GLOB", "WSUK"];
        var validStatusKeys = ["401", "403"];
        var prefilters = {};
        var prefiltersOptions = {};

        // TODO remove to different class;
        function preparePrefilters(context) {
            // Create a callback queue and add it to the prefilters list
            context.preFilters = $.Callbacks("unique");
            $.ajaxPrefilter(context.preFilters.fire);

            // Create the available prefilters
            prefilters.headers = _createHeaderPrefilter("headers");
            prefilters.param = _createParamPrefilter("param");
            prefilters.status = _createStatusCodePrefilter(context, "status");
        }

        function registerTokensAsHeader(options) {
            /*jshint validthis:true */
            _registerPrefilter(this, "headers", options);
        }

        function registerTokensAsParam(options) {
            /*jshint validthis:true */
            _registerPrefilter(this, "param", options);
        }

        function registerStatusCodeHandler(options) {
            /*jshint validthis:true */
            _registerPrefilter(this, "status", options);
        }

        function unregisterTokensAsHeader() {
            /*jshint validthis:true */
            _unregisterPrefilter(this, "headers");
        }

        function unregisterTokensAsParam() {
            /*jshint validthis:true */
            _unregisterPrefilter(this, "param");
        }

        function unregisterStatusCodeHandler() {
            /*jshint validthis:true */
            _unregisterPrefilter(this, "status");
        }

        function _parseParams(query) {
            var params = {};
            var elem = re.exec(query);

            while (elem) {
                var key = _decode(elem[1]);
                var val = _decode(elem[2]);
                if ("[]" === key.substring(key.length - 2)) {
                    key = key.substring(0, key.length - 2);
                    (params[key] || (params[key] = [])).push(val);
                }
                else {
                    params[key] = val;
                }

                elem = re.exec(query);
            }

            return params;
        }

        function _shouldAddTokenValue(configOptions, key, type) {
            if (_.isBoolean(configOptions) && !configOptions) {
                return false;
            }
            else if (store.getters[`${SESSION}/${IS_VALID_STATE_ATTRIBUTE}`](sessionModel.KEY[key])) {
                if (_.isBoolean(configOptions) && configOptions) {
                    return true;
                }
                else {
                    return !_.isUndefined(configOptions) && (
                        (_.isObject(configOptions) && _.isEmpty(configOptions)) ||
                        (_.isBoolean(configOptions.header) && configOptions.header || _.isObject(configOptions.header)) ||
                        (_.isBoolean(configOptions.query) && configOptions.query || _.isObject(configOptions.query)) ||
                        (_.isBoolean(configOptions.body) && configOptions.body || _.isObject(configOptions.body))
                    );
                }
            }
            else {
                return false;
            }
        }

        function _getTokenDefinitions(options, key, type) {
            var definitions = {
                key: TOKEN_DEFAULTS[key][type].key,
                format: TOKEN_DEFAULTS[key][type].format
            };

            var pointer = options[type];
            if (_.isObject(pointer)) {
                if (_.isString(pointer.key)) {
                    definitions.key = pointer.key;
                }
                if (_.isString(pointer.format)) {
                    definitions.format = pointer.format;
                }
            }

            return definitions;
        }

        function _addHeaderToken(options, configOptions, jqXHR, key) {
            var definitions = _getTokenDefinitions(configOptions, key, "header");
            jqXHR.setRequestHeader(definitions.key, definitions.format.replace(/{token}/g, store.getters[`${SESSION}/${GET_VALUE}`](sessionModel.KEY[key])));
        }

        function _addParameterToken(options, configOptions, jqXHR, key) {
            options = options || {};
            configOptions = configOptions || {};

            var stringify = false;
            var data = $.ajaxSettings.data || {};
            if (_.isString(options.data) && 0 < options.data.length) {
                try {
                    data = JSON.parse(options.data);
                    stringify = true;
                }
                catch (e) {
                    data = _parseParams(options.data);
                }
            }

            var token = store.getters[`${SESSION}/${GET_VALUE}`](sessionModel.KEY[key]);
            var booleanValue = _.isBoolean(configOptions) && configOptions;
            var definitions;
            var keyToken;
            if (booleanValue || !_.isUndefined(configOptions) && configOptions.query) {
                definitions = _getTokenDefinitions(configOptions, key, "query");
                keyToken = definitions.key;
                data[keyToken] = data[keyToken] || definitions.format.replace(/{token}/g, token);

                if ("GET" === options.type.toUpperCase() || "DELETE" === options.type.toUpperCase()) {
                    options.data = $.param(data);
                }
                else {
                    var qMark = /\?/;
                    options.url += (qMark.test(options.url) ? "&" : "?") + keyToken + "=" + token;
                }
            }
            if (booleanValue || !_.isUndefined(configOptions) && configOptions.body && "GET" !== options.type.toUpperCase() && "DELETE" !== options.type.toUpperCase()) {
                definitions = _getTokenDefinitions(configOptions, key, "query");
                keyToken = definitions.key;
                data[keyToken] = data[keyToken] || definitions.format.replace(/{token}/g, token);
                options.data = stringify ? JSON.stringify(data) : data;
            }
        }

        function _shouldAddStatusCodeValidation(configOptions) {
            if (_.isBoolean(configOptions) && !configOptions) {
                return false;
            }
            else {
                return !_.isUndefined(configOptions) && (
                    (_.isObject(configOptions) && _.isEmpty(configOptions)) ||
                    (_.isBoolean(configOptions) && configOptions || _.isObject(configOptions) && true === configOptions.logout)
                );
            }
        }

        function _addStatusCodeValidation(context, options, configOptions, key) {
            if (!_.isUndefined(configOptions)) {
                switch (key) {
                    case "401":
                        if (true === configOptions.logout) {
                            options.statusCode = options.statusCode || $.ajaxSettings.statusCode || {};

                            options.statusCode["401"] = function (jqXHR, textStatus, errorThrown) {
                                // 401 -- Unauthorized
                                context.sessionManager.sendLoginCommand.call(context, LOGIN_COMMANDS.LOGIN, options);
                            };
                        }
                        else {
                            options.statusCode = options.statusCode || $.ajaxSettings.statusCode || {};

                            options.statusCode["401"] = function (jqXHR, textStatus, errorThrown) {
                                // Lets first validate that the user is unauthorized
                                return context.doSustain();
                            };
                        }

                        if (_.isUndefined($.ajaxSettings.statusCode) || !_.isFunction($.ajaxSettings.statusCode["401"])) {
                            // Tell jQuery we have a special status code implementation
                            $.ajaxSetup({
                                statusCode: {
                                    401: function () {
                                    }
                                }
                            });
                        }
                        break;
                    case "403":
                        options.statusCode = options.statusCode || $.ajaxSettings.statusCode || {};

                        options.statusCode["403"] = function (jqXHR, textStatus, errorThrown) {
                            // 403 -- Access denied
                            _handleUnAuthorizedError(context, options);
                        };

                        if (_.isUndefined($.ajaxSettings.statusCode) || !_.isFunction($.ajaxSettings.statusCode["403"])) {
                            // Tell jQuery we have a special status code implementation
                            $.ajaxSetup({
                                statusCode: {
                                    403: function () {
                                    }
                                }
                            });
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        function _createHeaderPrefilter(name) {
            return function (options, originalOptions, jqXHR) {
                // If we have a csrf/glob token send it through with the request as an header
                options = options || {};

                var callerOptions = prefiltersOptions[name] || {};

                _.each(validTokenKeys, function (key) {
                    var configOptions = _prepareConfigOptions(key, options.withTokens, callerOptions);
                    if (_shouldAddTokenValue(configOptions, key, "header")) {
                        _addHeaderToken(options, configOptions, jqXHR, key);
                    }
                });
            };
        }

        function _prepareConfigOptions(key, options, callerOptions) {
            options = options || {};
            callerOptions = callerOptions || {};
            var configOptions;
            var keyOptions = OPTION_KEYS[key] || key;
            var appOptions = _.isBoolean(callerOptions[keyOptions]) && callerOptions[keyOptions] ? {} : callerOptions[keyOptions] || false;

            if (appOptions) {
                configOptions = appOptions;
            }

            var apiOptions = _.isBoolean(options[keyOptions]) && options[keyOptions] ? {} : options[keyOptions];

            if (false === apiOptions) {
                configOptions = void 0;
            }
            else if (_.isObject(apiOptions)) {
                configOptions = $.extend(true, configOptions, apiOptions);
            }

            if (_.isUndefined(configOptions)) {
                return false;
            }

            return configOptions;
        }

        function _createParamPrefilter(name) {
            // Hook into jquery
            return function (options, originalOptions, jqXHR) {
                // If we have a csrf/glob token send it through with the next request as a parameter
                options = options || {};

                var callerOptions = prefiltersOptions[name] || {};

                _.each(validTokenKeys, function (key) {
                    var configOptions = _prepareConfigOptions(key, options.withTokens, callerOptions);
                    if (_shouldAddTokenValue(configOptions, key, "param")) {
                        _addParameterToken(options, configOptions, jqXHR, key);
                    }
                });
            };
        }

        function _createStatusCodePrefilter(context, name) {
            return function (options, originalOptions, jqXHR) {
                var callerOptions = prefiltersOptions[name] || {};

                _.each(validStatusKeys, function (key) {
                    var configOptions = _prepareConfigOptions(key, options.validateStatus, callerOptions);
                    if (_shouldAddStatusCodeValidation(configOptions)) {
                        _addStatusCodeValidation(context, options, configOptions, key);
                    }
                });
            };
        }

        function _registerPrefilter(context, name, options) {
            if (_.isFunction(prefilters[name])) {
                prefiltersOptions[name] = $.extend(true, {}, options);
                context.preFilters.add(prefilters[name]);
            }
        }

        function _unregisterPrefilter(context, name) {
            if (_.isFunction(prefilters[name])) {
                context.preFilters.remove(prefilters[name]);
                delete prefiltersOptions[name];

                // Remove special status code implementation
                delete $.ajaxSettings.statusCode["401"];
                delete $.ajaxSettings.statusCode["403"];
            }
        }

        function _handleUnAuthorizedError(context, options) {
            if (!_.isUndefined(context.notifier)) {
                if (_.isUndefined(context.notification)) {
                    context.notification = context.notifier.showGlobalConfirm({
                        title: context.translator.translate("LEFramework.session.unauthorized.title"),
                        content: context.translator.translate("LEFramework.session.unauthorized.message"),
                        objectAction: {
                            text: context.translator.translate("LEFramework.session.unauthorized.okText"),
                            callback: _.bind(context.sessionManager.sendLoginCommand, context, LOGIN_COMMANDS.LOGIN, options)
                        }
                    });
                }
            }
            else {
                context.sessionManager.sendLoginCommand.call(context, LOGIN_COMMANDS.LOGIN, options);
            }
        }

        function _decode(str) {
            return decodeURIComponent(str.replace(decodeRE, " "));
        }

        return {
            preparePrefilters: preparePrefilters,
            registerTokensAsHeader: registerTokensAsHeader,
            registerTokensAsParam: registerTokensAsParam,
            registerStatusCodeHandler: registerStatusCodeHandler,
            unregisterTokensAsHeader: unregisterTokensAsHeader,
            unregisterTokensAsParam: unregisterTokensAsParam,
            unregisterStatusCodeHandler: unregisterStatusCodeHandler,

        };
    })();
    return TransportPrefiltersAdapter;
});
