import { Logger } from 'vue-infra';
const logger = Logger.getLogger('CCUI');

/**
 * Detect zoom example for LiveEngage frame
 * Inspired by this codepen: https://codepen.io/reinis/pen/RooGOE
 */
export function  detectZoom() {
    try {
    /**
     * Fallback function to set default values
     */
    const fallback = function() {
        return 1;
    }

	/**
	* For chrome
	*
	*/
    const chrome = function() {
    	const zoom = Math.round(((window.outerWidth) / window.innerWidth)*100) / 100;
        return zoom;
    }

	/**
	* For safari (same as chrome)
	*
	*/
    const safari= function() {
    	const zoom = Math.round(((window.outerWidth) / window.innerWidth)*100) / 100;
        return zoom;
    }
    /**
     * Mobile WebKit
     * the trick: window.innerWIdth is in CSS pixels, while
     * screen.width and screen.height are in system pixels.
     * And there are no scrollbars to mess up the measurement.
     */
    const webkitMobile = function() {
        const deviceWidth = (Math.abs(window.orientation) == 90) ? screen.height : screen.width;
        const zoom = deviceWidth / window.innerWidth;
        return zoom;
    };

    /**
     * Desktop Webkit
     * the trick: an element's clientHeight is in CSS pixels, while you can
     * set its line-height in system pixels using font-size and
     * -webkit-text-size-adjust:none.
     * device-pixel-ratio: http://www.webkit.org/blog/55/high-dpi-web-sites/
     *
     * Previous trick (used before http://trac.webkit.org/changeset/100847):
     * documentElement.scrollWidth is in CSS pixels, while
     * document.width was in system pixels. Note that this is the
     * layout width of the document, which is slightly different from viewport
     * because document width does not include scrollbars and might be wider
     * due to big elements.
     */
    const webkit = function() {
        const important = function (str) {
            return str.replace(/;/g, " !important;");
        };

        const div = document.createElement('div');
        div.innerHTML = "1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>0";
        div.setAttribute('style', important('font: 100px/1em sans-serif; -webkit-text-size-adjust: none; text-size-adjust: none; height: auto; width: 1em; padding: 0; overflow: visible;'));

        // The container exists so that the div will be laid out in its own flow
        // while not impacting the layout, viewport size, or display of the
        // webpage as a whole.
        // Add !important and relevant CSS rule resets
        // so that other rules cannot affect the results.
        const container = document.createElement('div');
        container.setAttribute('style', important('width:0; height:0; overflow:hidden; visibility:hidden; position: absolute;'));
        container.appendChild(div);

        document.body.appendChild(container);
        const zoom = 1000 / div.clientHeight;
        zoom = Math.round(zoom * 100) / 100;
        document.body.removeChild(container);

        return zoom;
    };

    /**
     * Firefox 18.x
     * Mozilla added support for devicePixelRatio to Firefox 18,
     * but it is affected by the zoom level, so, like in older
     * Firefox we can't tell if we are in zoom mode or in a device
     * with a different pixel ratio
     */
    const firefox = function() {
        const zoom = mediaQueryBinarySearch('min--moz-device-pixel-ratio', '', 0, 10, 20, 0.0001);
        zoom = Math.round(zoom * 100) / 100;
        return zoom;
    };

    /**
     * Use a binary search through media queries to find zoom level in Firefox
     * @param property
     * @param unit
     * @param a
     * @param b
     * @param maxIter
     * @param epsilon
     * @return {Number}
     */
    const mediaQueryBinarySearch = function (property, unit, a, b, maxIter, epsilon) {
        const matchMedia = 0, head = 0, style = '', div = ''
        if (window.matchMedia) {
            matchMedia = window.matchMedia;
        } else {
            head = document.getElementsByTagName('head')[0];
            style = document.createElement('style');
            head.appendChild(style);

            div = document.createElement('div');
            div.className = 'mediaQueryBinarySearch';
            div.style.display = 'none';
            document.body.appendChild(div);

            matchMedia = function (query) {
                style.sheet.insertRule('@media ' + query + '{.mediaQueryBinarySearch ' + '{text-decoration: underline} }', 0);
                const matched = getComputedStyle(div, null).textDecoration == 'underline';
                style.sheet.deleteRule(0);
                return {matches: matched};
            };
        }
        const ratio = binarySearch(a, b, maxIter);
        if (div) {
            head.removeChild(style);
            document.body.removeChild(div);
        }
        return ratio;

        function binarySearch(a, b, maxIter) {
            const mid = (a + b) / 2;
            if (maxIter <= 0 || b - a < epsilon) {
                return mid;
            }
            const query = "(" + property + ":" + mid + unit + ")";
            if (matchMedia(query).matches) {
                return binarySearch(mid, b, maxIter - 1);
            } else {
                return binarySearch(a, mid, maxIter - 1);
            }
        }
    };

    const crossBrowserDetectFunction = (function() {
        let func = fallback;

        if (!!window.chrome) {
            func = chrome;
        } else if (Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0) {
            func = safari;
        } else if ('orientation' in window && 'webkitRequestAnimationFrame' in window) {
            func = webkitMobile;
        } else if ('webkitRequestAnimationFrame' in window) {
            func = webkit;
        } else if (window.devicePixelRatio) {
            func = firefox;
        }
        return func;
    }());

    return crossBrowserDetectFunction();
    } catch (error) {
        logger.error(`An error occurred in detectZoom: ${error}`);
        return false;
    }
}