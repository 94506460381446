/**
 * Created by odeds on 12/29/15.
 */
define(function (require) {
    "use strict";

    var { Logger } = require('vue-infra');
    var logger = Logger.getLogger("CoreComponents");
    var Bubble = require("ui/bubble/Bubble");
  var _ = require('underscore');

    var PositionUtils = require("ui/PositionUtils");
    var BaseGalleryItemView = require("ui/gallery/js/views/GalleryItemView");
    var template = require("ui.components/gallery/templates/apiKeysGalleryItem.tmpl");
    var ApiKeyGalleryItemOptionBubbleView = require("ui.components/gallery/js/views/ApiKeyGalleryItemOptionBubbleView");

    var OPTION_BUBBLE_COMPONENT_ID = "apiKeyOptionBubble";

    var ApiKeysGalleryItemView = BaseGalleryItemView.extend({
        /*NOTE: you cannot declare here  ui; events; and uiComponents attr since you'll override those inherited by BaseGallery
        * append them in the initialize method
        **/
        initialize:function(options) {
            logger.debug("enter function", "ApiKeysGalleryItemView:initialize");
            this.apiKeysCollection = options.originalCollection;
            this.events["click .apikey-options"]= "_onApiKeyOptionClick";
            this.ui.galleryItem = ".gallery-item-button";
            this.ui.apikeyBubbleContainer=  ".apikey-bubble-container";
            this.ui.apiKeyOptions=  ".apikey-options";
            this.channel = LE.media.channel("APIKEYS_GALLERY_OPTIONS");

            BaseGalleryItemView.prototype.initialize.call(this, options);
            if (!(this.model.isTypeNew() || this.model.isTypeLink())) {
                this.apikeyGalleryModel= this.apiKeysCollection.getModelByApiKey(this.model.getKey());
                this.model.set("apiKeysModel", this.apikeyGalleryModel.toJSON());
                this.template = template;
            }

            this.listenTo(this.channel, "apikeys:closeBubble", this._closeApiKeyBubble);
            this.listenTo(this, "itemClick", this._closeAllBubbles);
        },
        onRender: function(){
            logger.debug("enter function", "ApiKeysGalleryItemView:onRender");
            BaseGalleryItemView.prototype.onRender.call(this);
            if (this.apikeyGalleryModel && this.apikeyGalleryModel.hasIpRestrictionIcon()) {
                this.ui.galleryItem.addClass("has-ip-restriction-icon");
            }
        },
        onClose:function () {
            logger.debug("enter function", "ApiKeysGalleryItemView:onClose");

        },

        /*apikey bubble*/
        _onApiKeyOptionClick: function () {
            logger.debug("enter function", "ApiKeysGalleryItemView:_onApiKeyOptionClick");
            this._showApiKeyBubble();
        },

        /**
         * defered to end of callstack so GalleryItemView itemClick handler will first close all bubbles
         * @private
         */
        _showApiKeyBubble: function () {
            _.defer(_.bind(function(){
                var bubbleContentView= new ApiKeyGalleryItemOptionBubbleView({
                    model: this.apikeyGalleryModel
                });
                this.uiComponents.host$.createAndRender(OPTION_BUBBLE_COMPONENT_ID, {
                    type: Bubble,
                    options: {
                        container: this.ui.apikeyBubbleContainer,
                        targetControl: this.ui.apiKeyOptions,
                        contentView: bubbleContentView,
                        position: Bubble.POSITION.BOTTOM,
                        align: Bubble.ALIGN.MIDDLE,
                        showCloseButton: true,
                        permutation: Bubble.PERMUTATION.F
                    },
                    events: {
                        "closeBubble": "_closeApiKeyBubble"
                    }
                });
            }, this));
        },

        _closeAllBubbles: function() {
            logger.debug("enter function", "ApiKeysGalleryItemView:_closeAllBubbles");
            this.channel.trigger("apikeys:closeBubble");
        },

        _closeApiKeyBubble: function () {
            logger.debug("enter function", "ApiKeysGalleryItemView:_closeApiKeyBubble");
            if (this.uiComponents[OPTION_BUBBLE_COMPONENT_ID]) {
                this.uiComponents[OPTION_BUBBLE_COMPONENT_ID].close(true);
            }
        }

    });

    return ApiKeysGalleryItemView;
});
