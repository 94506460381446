<template>
  <div class="top-level-menu-item-list-container">
    <top-level-menu-item-view
      v-for="item in activeItems"
      :key="item.value"
      :item="item"
      :selected-theme="selectedTheme"
      @clicked="itemClicked(item)"
    />
  </div>
</template>

<script>
    import TopLevelMenuItemView from './TopLevelMenuItemView';
    import * as LEResourceResolver from "leResourceResolver";
    import { sessionManager, environmentGetterTypes, storeModuleTypes, Vuex, Logger } from "vue-infra";
    import _ from 'lodash';
    import CONST from 'const';

    const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
        regions: environmentGetterTypes.REGIONS,
        brand: environmentGetterTypes.RESOLVE_BRAND_PROFILE
    });

    const logger = Logger.getLogger('LiveEngage:TopLevelMenu');

    export default {
        name: "TopLevelMenuItemList",
        components: {
            TopLevelMenuItemView
        },
        props: {
          selectedTheme: {
            type: Object,
            default: () => {},
          }
        },
        emits: ['clicked'],
        data() {
            return {
                activeItems: [],
                shiftStatusButton: {
                    title: this.$translator.translate("LEFramework.userBarMenu.item.shiftStatus"),
                    iconClass: "calendar",
                    enabled: true,
                    indicatorText: '',
                    indicatorClass: ''
                },
                liveEngageButton: {
                    title: this.$translator.translate("LEFramework.LiveEngageTag.title"),
                    iconClass: "web-tag",
                    enabled: true,
                    indicatorText: ''
                },
                myProfileButton: {
                    title: this.$translator.translate("LEFramework.userBarMenu.item.myDetails"),
                    iconClass: "user",
                    enabled: true,
                    indicatorText: ''
                },
                myAccountButton: {
                    title: this.$translator.translate("LEFramework.userBarMenu.item.myAccount"),
                    iconClass: "agents",
                    enabled: true,
                    indicatorText: '',
                    openInNewTab: true
                },
                auditTrailButton: {
                    title: this.$translator.translate("LEFramework.userBarMenu.item.auditTrail"),
                    iconClass: "clipboard",
                    enabled: true,
                    indicatorText: ''
                },
                statusPageButton: {
                    title: this.$translator.translate("LEFramework.userBarMenu.item.statusPage"),
                    iconClass: "speedometer",
                    enabled: true,
                    indicatorText: '',
                    path: '',
                    openInNewTab: true
                }
            }
        },
        computed: {
            routePrefix() {
                if (this.regions && this.regions.LEManager) {
                    const managerRegion = this.regions.LEManager;
                    return (managerRegion.config && managerRegion.config.routePrefix) || '';
                }
                return '';
            },
            regions: getters.regions,
            brand: getters.brand
        },
        watch: {
            brand() {
                this.updateShiftStatusButton();
            }
        },
        created() {
            this.getMenuButtons();
        },
        methods: {
            shouldDisplayLiveEngageTagButton() {
                return sessionManager.hasPrivilege([sessionManager.PRIVILEGES.LE_VIEW_CAMPAIGN_MODULE, sessionManager.PRIVILEGES.LE_VIEW_USER_MANAGEMENT_MODULE], true);
            },
            shouldDisplayMyProfileButton() {
                return !sessionManager.isLPA()
                    && sessionManager.hasPrivilege(sessionManager.PRIVILEGES.CHANGE_MY_DETAILS);
            },
            shouldDisplayMyAccountButton() {
                return sessionManager.getFeaturePropertyState(CONST.FEATURES.MY_ACCOUNT_MENU_LINK);
            },
            shouldDisplayAuditTrailButton() {
                return sessionManager.hasAuditTrailFeature()
                    && sessionManager.hasPrivilege(sessionManager.PRIVILEGES.VIEW_AUDIT_TRAIL);
            },
            shouldDisplayShiftStatusButton() {
                const isFeatureEnabled = sessionManager.getFeatureProperty('Common.Async_Messaging')
                  && sessionManager.getFeatureProperty('Common.Async_Messaging').value
                  && sessionManager.getFeatureProperty('Common.Async_Messaging').value.value;
                const isSufficientPrivileges = sessionManager.isLPA()
                  || (
                    sessionManager.hasPrivilege(sessionManager.PRIVILEGES.CONFIGURE_SHIFT_STATUS)
                    && sessionManager.hasPrivilege(sessionManager.PRIVILEGES.VIEW_ACTIVE_CONNECTIONS)
                  );

                return isFeatureEnabled && isSufficientPrivileges;
            },
            shouldDisplayStatusPageButton() {
                return sessionManager.hasPrivilege(sessionManager.PRIVILEGES.VIEW_STATUS_PAGE);
            },
            getMenuButtons() {
                this.activeItems = [];

                this.shiftStatusButton.switcherRoute = `/${this.routePrefix}/${window.LE.mediaCodes.manager.redirects.shiftStatus}`;
                this.liveEngageButton.switcherRoute = `/${this.routePrefix}/${window.LE.mediaCodes.manager.redirects.lptag}`;
                this.myProfileButton.switcherRoute = `/${this.routePrefix}/${window.LE.mediaCodes.manager.redirects.myProfile}`;
                this.myAccountButton.switcherRoute = LEResourceResolver.getUrl(LEResourceResolver.apiResources.Registration.MyAccount);
                this.auditTrailButton.switcherRoute = `/${this.routePrefix}/${window.LE.mediaCodes.manager.redirects.auditTrail}`;
                this.statusPageButton.switcherRoute = LEResourceResolver.getUrl(LEResourceResolver.apiResources.ServiceStatusDashboard.HealthPage);

                if (this.shouldDisplayMyProfileButton()) {
                    this.activeItems.push(this.myProfileButton);
                }

                if (this.shouldDisplayLiveEngageTagButton()) {
                    this.activeItems.push(this.liveEngageButton);
                }

                if (this.shouldDisplayMyAccountButton()) {
                    this.activeItems.push(this.myAccountButton);
                }

                if (this.shouldDisplayAuditTrailButton()) {
                    this.activeItems.push(this.auditTrailButton);
                }

                if (this.shouldDisplayShiftStatusButton()) {
                    this.activeItems.push(this.shiftStatusButton);
                }

                if (this.shouldDisplayStatusPageButton()) {
                    this.activeItems.push(this.statusPageButton);
                }

            },
            itemClicked(item) {
                this.$emit('clicked');
                logger.bam({
                    serviceName: 'CCUI-CORE',
                    appName: 'CCUI',
                    eventName: 'link clicked',
                    widgetName: 'user-menu',
                    value: item.title,
                });
            },
            _showManualShiftStatusIndicator(){
                this.shiftStatusButton.enabled = true;
                let indicatorText = '';
                let indicatorClass = '';
                if (this.brand && this.brand.delay && Date.now() >= this.brand.delay.tillWhen) {
                    indicatorText = this.$translator.translate("LEFramework.userBarMenu.item.shiftStatus.online");
                    indicatorClass = 'green-color';
                }
                else {
                    indicatorText = this.$translator.translate("LEFramework.userBarMenu.item.shiftStatus.off.hours");
                    indicatorClass = 'red';
                }

                this.shiftStatusButton.indicatorText = indicatorText;
                this.shiftStatusButton.indicatorClass = indicatorClass;
            },
            _hideManualShiftStatusIndicator(){
                this.shiftStatusButton.enabled = false;
                this.shiftStatusButton.indicatorText = '';
            },
            updateShiftStatusButton() {
                var siteSettingsManualShiftStatusModel = LE.crossModule("manager", LE.media.actions.request, LE.mediaCodes.manager.reqres.getSiteSettingsManualShiftStatusModel);
                siteSettingsManualShiftStatusModel.fetch({
                    success: _.bind(function(model) {
                        if (model.getValue() === "true") {
                            this._showManualShiftStatusIndicator();
                        }
                        else {
                            this._hideManualShiftStatusIndicator();
                        }
                    }, this),
                    error: _.bind(function() {
                        this._hideManualShiftStatusIndicator();
                    }, this)
                });


            }
        }
    }

</script>

<style scoped>

</style>
