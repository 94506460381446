/**
 * Created by iraphael on 10/10/2018.
 */
define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var ApiResources = require("assets/data/apiResources.json");
    var ErrorCodes = require("codeTables/errorCodes.json");
    var LocaleResolver = require('i18n/localeResolver');
    var _ = require('underscore');


    var DEFAULT_MAX_WAIT_TIME = "120";


    var KEYS = {
        ID: "id",
        FLOW: "flow",
        NAME: "flow.name"
    };

    var PostSurveyModel = RevisionedModel.extend({
        KEYS: KEYS,
        defaults: {
            id: null,
            name: ""
        },
        name: "PostSurveyModel",
        useMemento: true,

        initialize: function(options) {
            this.valueIsChanged = false;
            this.resource = ApiResources.AccountConfig.PostSurvey;
            RevisionedModel.prototype.initialize.call(this, options);
        },

        getId: function() {
            return this.get(KEYS.ID);
        },

        setId: function(id) {
            this._set(KEYS.ID, id);
        },

        getName: function() {
            return this.get('name');
        },

        setName: function(name) {
            this._set('name', name);
        },

        isValueChanged: function() {
            return this.valueIsChanged;
        },

        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },

        parse: function (response, options) {
            if (response && response.maxWaitTime === -1) {
                response.maxWaitTime = DEFAULT_MAX_WAIT_TIME;
            }

            if (response && response.members) {
                delete response.members;
            }

            return RevisionedModel.prototype.parse.call(this, response, options);
        },

        save: function(attributes, options) {
            options = options ? _.clone(options) : {};
            var origError = options.error;


            options.error = _.bind(function(collection, resp, opt) {
                var errorObject = {};
                var errorMessage = resp && _.has(resp.responseError, "message") ?
                    resp.responseError.message : LE.translator.translate("LEFramework.something.went.wrong");

                if (resp && resp.responseError && resp.responseError.error) {
                    if (_.has(resp.responseError.error, "message")) {
                        errorMessage = resp.responseError.error.message;
                    }

                    if (_.include(resp.responseError.error.message, ErrorCodes.appServer.messages.duplicateEntity)) {
                        errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
                    }
                    if (_.has(resp.responseError.error, "internalCode") && resp.responseError.error.internalCode === ErrorCodes.appServer.internalCodes.duplicateEntity) {
                        errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
                    }
                }
                if (resp && resp.responseError && resp.responseError.internalCode) {
                    if (resp.responseError.internalCode === ErrorCodes.uniquenessViolation) {
                        errorObject[KEYS.NAME] = LE.translator.translate("LEFramework.entities.skill.errors.name.duplicated");
                    }
                }

                if(!_.isEmpty(errorObject)) {
                    options.errorObject = errorObject;
                }
                options.errorMessage = errorMessage;

                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            RevisionedModel.prototype.save.call(this, attributes, options);
        },

        destroy: function(options) {
            options = options ? _.clone(options) : {};
            var origError = options.error;
            options.error = _.bind(function(collection, resp, opt) {
                var errorMessage = LE.translator.translate("LEFramework.something.went.wrong");
                if (resp && resp.responseError && resp.responseError.message) {
                    errorMessage = resp.responseError.message;
                }

                if (resp && resp.responseError && resp.responseError.internalCode) {
                    if (resp.responseError.internalCode === ErrorCodes.dependencyConflict || resp.responseError.internalCode === ErrorCodes.dependencyConflictExternal) {
                        errorMessage = LE.translator.translate('LEFramework.entities.skill.delete.errors.skillList.assignedToUsers');

                        var errorFields = resp.responseError.fields;
                        var translatedFields = [];
                        if (!_.isEmpty(errorFields)){
                            for (var i = 0; i < errorFields.length; i++){
                                var key = "LEFramework.entities.skill.delete.error.dependencies.field." + errorFields[i];
                                var translatedField = LE.translator.hasTranslationKey(LocaleResolver.getDefaultLocale(), key) ? LE.translator.translate(key) : errorFields[i];
                                translatedFields.push(translatedField);
                            }

                            errorMessage = LE.translator.translate({
                                key: "LEFramework.entities.skill.delete.error.dependencies",
                                args: [{key: translatedFields.join('<br>'), isTranslate:false}]
                            });

                        }
                    }
                }
                options.errorMessage = errorMessage;
                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            RevisionedModel.prototype.destroy.call(this, options);
        },

        //private functions:
        _set: function(key,value) {
            this.valueIsChanged = true;
            this.set(key, value);
        }
    });

    PostSurveyModel.KEYS = KEYS;

    return PostSurveyModel;
});

