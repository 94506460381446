define(function (require) {
    "use strict";
    var Modernizr = require('modernizr.src');

    Modernizr.addTest('isedge', function () {
        return (/Edge/.test(navigator.userAgent) && /Windows/.test(navigator.userAgent));
    });

    return Modernizr;
});
