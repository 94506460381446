/**
 * Created by itaic on 10/18/15.
 */
define(function (require) {
  'use strict';

  var _ = require('underscore');
  var CoreUtils = require('CoreUtils');
  var DomainUtils = require('domainUtil');
  var LEResourceResolver = require('leResourceResolver');
  var PostmessageTransport = require('lptransporter/transports/postmessage');
  var FileDownloadTransport = require('assets/js/cross-domain/resources/filedownload');
  var Rest2JsonpTransport = require('lptransporter/transports/rest2jsonp');


  function _setPostMessageandFileDownloadConfig(configuration) {
    var singleConf, url;
    for (var i = 0; i < configuration.length; i++) {
      singleConf = configuration[i];
      url = LEResourceResolver.getUrl(singleConf.url);
      if (!LEResourceResolver.isSameOrigin(url)) {
        singleConf.url = url;
        singleConf.delayLoad = _.isNumber(singleConf.delayLoad) ? singleConf.delayLoad : 0;
      }
    }
    return singleConf;
  }

  var _configureEndpoint = {
    postmessage: function (endpoint) {
      var configs, conf;
      configs = _.isArray(endpoint.configuration) ? endpoint.configuration : [endpoint.configuration];
      conf = _setPostMessageandFileDownloadConfig(configs);
      if (DomainUtils.isValidDomain(conf.url) && conf) {
        PostmessageTransport.configure({ frames: conf });
      }
    },
    filedownload: function (endpoint) {
      var configs, conf;
      configs = _.isArray(endpoint.configuration) ? endpoint.configuration : [endpoint.configuration];
      conf = _setPostMessageandFileDownloadConfig(configs);
      if (DomainUtils.isValidDomain(conf.url) && conf) {
        FileDownloadTransport.configure({ frames: conf });
      }
    },
    rest2jsonp: function (endpoint) {
      var domain = LEResourceResolver.getUrl(endpoint.configuration.domain);
      if (domain && endpoint.site) {
        Rest2JsonpTransport.configure({ domain: domain, site: endpoint.site });
      }
    },
    jsonp: function (endpoint) {
    },
    websocket2http: function (endpoint) {
      var config = endpoint.configuration;
      config.server = LEResourceResolver.getUrl(config.server);
    }
  };

  /**
   * Turns an object into a cloned list of objects
   * if they are endpoints
   * @param obj
   * @param list
   * @returns {*|Array}
   */
  function _collectEndPoints(obj, list) {
    list = list || [];

    _.each(obj, function (val, key) {
      if (key === 'endpoint') {
        list.push(CoreUtils.clone(val));
      } else if (_.isObject(val)) {
        _collectEndPoints(val, list);
      }
    });

    return list;
  }

  function configureEndPoints() {
    var list = _collectEndPoints(LEResourceResolver.apiResources);
    _.each(list, function (endpoint) {
      if (!_.isUndefined(endpoint) && !_.isUndefined(endpoint.transport) && !_.isUndefined(endpoint.configuration)) {
        _configureEndpoint[endpoint.transport](endpoint);
      }
    });
  }

  return {
    configureEndPoints: configureEndPoints
  };
});
