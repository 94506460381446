define(function (require) {
    "use strict";

    var RevisionedCollection = require("collections/RevisionedCollection");
    var AgentSurveyModel = require("models/entities/AgentSurveyModel");
    var ApiResources = require("assets/data/apiResources.json");
    var LEResourceResolver = require("leResourceResolver");
    var _ = require("underscore");

    var URL_SELECT_PARAMETER = {
        ALL: "$all",
        PARTIAL: [AgentSurveyModel.KEYS.ID, AgentSurveyModel.KEYS.NAME, AgentSurveyModel.KEYS.ENABLED, AgentSurveyModel.KEYS.IS_DEFAULT]
    };

    var NAME = "AgentSurveysCollection";

    var AgentSurveysCollection = RevisionedCollection.extend({
        name: NAME,
        model : AgentSurveyModel,
        resource: ApiResources.AccountConfig.AgentSurvey,
        URL_SELECT_PARAMETER: URL_SELECT_PARAMETER,
        initialize: function(models, options) {
            options = options || {};
            this.source = options.source;
            this.urlSelectParameter = options.urlSelectParameter || URL_SELECT_PARAMETER.PARTIAL;
            this.defaultId = null;

            RevisionedCollection.prototype.initialize.call(this, models, options);
        },
        url: function() {
          const ACWithSource = { ...ApiResources.AccountConfig.AgentSurvey, source: this.source };

          return LEResourceResolver.getUrl(ACWithSource) + "&select=" + this.urlSelectParameter;
        },
        fetchSuccess: function( ){
          this._setDefaultId();
        },
        getDefaultId: function() {
          return this.defaultId;
        },
        _setDefaultId: function() {
          var defaultModel = this.find(function(model){ return model.isDefault(); });
          if (!_.isEmpty(defaultModel)) {
            this.defaultId = defaultModel.id;
          }
          else if ( this.size() > 0 ){
            this.defaultId = this.at(0).id;
          }
        }
    });

    AgentSurveysCollection.NAME = NAME;
    AgentSurveysCollection.URL_SELECT_PARAMETER = URL_SELECT_PARAMETER;

    return AgentSurveysCollection;
});
