define(function (require) {
    "use strict";
    var BaseGallery = require("ui/gallery/gallery");
    var ConversationSourcesGalleryItemView = require("ui.components/gallery/js/views/ConversationSourcesGalleryItemView");

    var ConversationSourcesGallery = BaseGallery.extend({

        itemView: ConversationSourcesGalleryItemView,
        //----------------------------------------
        // initialize
        //---------------------------------------

        initialize:function (options) {
            BaseGallery.prototype.initialize.call(this, options);
        }
    });

    return ConversationSourcesGallery;
});
