<template>
  <div
    class="top-level-notification"
    v-if="message"
    @click="closeNotification()"
  >
    <svg
      viewBox="0 0 26.1 12.5"
      xmlns="http://www.w3.org/2000/svg"
      class="top-level-notification-shape"
    >
      <path
        fill="currentColor"
        d="M0,12.5c3.6,0,7.2-1.2,10-3.5l9.6-7.7c2.6-2.1,6.5-0.2,6.5,3.1v8.1H0z"
      />
      />
    </svg>
    <div class="top-level-notification-content">
      <div class="top-level-notification-message">{{ message }}</div>
      <svg
        class="top-level-notification-close"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          fill="currentColor"
          d="M14.9,4.9c-0.4-0.4-1-0.4-1.4,0L9.9,8.5L6.4,4.9C6,4.6,5.3,4.6,5,4.9C4.6,5.3,4.6,6,5,6.4l3.5,3.5L5,13.4
      	c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l3.5-3.5l3.5,3.5c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-3.5-3.5l3.5-3.5
      	C15.2,6,15.2,5.3,14.9,4.9z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { gChannel } from 'vue-infra';
export default {
  name: 'top-level-notification',
  data() {
    return {
      message: null
    };
  },
  created() {
    gChannel.register('LiveEngageCommon', 'ShowNotification', this.setMessage);
  },
  methods: {
    setMessage: function(obj) {
      if (obj.helpText) {
        this.message = this.$translator.translate(obj.helpText);
      }
      if (data.autoCloseNotification) {
        setTimeout(() => this.closeNotification(), 5000);
      }
    },
    closeNotification: function() {
      this.message = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/_mixins';
.top-level-notification {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 8px;
  color: #b9bfd2;
  width: 418px;
}
.top-level-notification-shape {
  width: 26px;
  height: 12px;
  float: right;
}
.top-level-notification-content {
  background-color: #b9bfd2;
  display: flex;
  padding: 16px;
  border-radius: 16px 0 16px 16px;
  clear: right;
}
.top-level-notification-message {
  color: #090c43;
  flex: 1;
  width: 350px;
  line-height: 20px;
  font-size: 14px;
  padding-right: 16px;
}
svg {
  display: block;
}
.top-level-notification-close {
  color: #675d55;
  width: 20px;
  height: 20px;
}
</style>
