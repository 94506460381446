/**
 * Created with IntelliJ IDEA.
 * User: itaik
 *
 * Date: 4/17/13
 * Time: 2:59 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
  var BaseModel = require("models/BaseModel");
  var LEResourceResolver = require("leResourceResolver");
  require('lptransporter/transports/jsonp');

  var AppServerSessionModel = BaseModel.extend({
    resource:LEResourceResolver.apiResources.AppServer.Session.RealTime,
    untouched: true,
    name: "AppServerSessionModel",

    save:function () {
      throw new Error("AppServerSessionModel::save::Method Not Supported");
    },

    fetch:function (options) {
      options = options || {};

      // Lets do the refresh
      return BaseModel.prototype.fetch.call(this, options);
    },

    destroy:function (options) {
      options = options || {};

      // jQuery cannot parse empty strings and since the platform return no response from the refresh API,
      // I'll need to treat the response as text instead of JSON
      options.dataType = "text";

      // Lets do the destroy
      return BaseModel.prototype.destroy.call(this, options);
    }
  });

  return new AppServerSessionModel();

});
