
define(function (require) {
    "use strict";

  var _ = require('underscore');

    var customizationSettings = (function () {

        var isHideBI = function() {
            var customizationObj = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.LE_UI_CUSTOMIZATION);
            return !_.isUndefined(customizationObj) && (customizationObj.hideBI === "true" || customizationObj.hideBI === true);
        };

        var isHideLeTagPage = function() {
            var customizationObj = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.LE_UI_CUSTOMIZATION);
            return !_.isUndefined(customizationObj) && (customizationObj.hideLeTagPage === "true" || customizationObj.hideLeTagPage === true);
        };

        var isBlockAddUsers = function() {
            var customizationObj = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.LE_UI_CUSTOMIZATION);
            return !_.isUndefined(customizationObj) && (customizationObj.blockAddUsers === "true" || customizationObj.blockAddUsers === true);
        };

        var isHideLearnMore = function() {
            var customizationObj = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.LE_UI_CUSTOMIZATION);
            return !_.isUndefined(customizationObj) && (customizationObj.hideLearnMore === "true" || customizationObj.hideLearnMore === true);
        };

        return {
            isHideBI: isHideBI,
            isHideLeTagPage: isHideLeTagPage,
            isBlockAddUsers: isBlockAddUsers,
            isHideLearnMore: isHideLearnMore
        };

    })();

    return customizationSettings;
});
