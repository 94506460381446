<template>
  <!-- TODO: get it back after removing the Backbone user bar -->
  <div class="personalization-widget">
    <div>
      <le-bubble v-bind="popoverOptions">
        <template #target>
          <div
            :aria-label="personalizationLinkName"
            class="topBarWidgetIcon personalizationIcon ico-night-vision tooltip-target"
            :class="{ 'personalizationIcon-disabled': featuresByRoutCount <= 0 }"
            role="button"
            tabindex="0"
            :aria-disabled="featuresByRoutCount <= 0 ? 'true' : null"
            :disabled="featuresByRoutCount <= 0 ? 'disabled' : null"
            @click="onPersonalizationIconClick()"
            @keydown.enter.space.prevent="onPersonalizationIconClick()"
            @mouseenter="onTargetMouseEnter"
            @mouseleave="onTargetMouseLeave"
            @focus="onTargetMouseEnter"
            @blur="onTargetMouseLeave"
          >
          </div>
        </template>
        <template #content>
          <div
            class="personalization-popover-content"
            tabindex="0"
            role="button"
            @mouseenter="onPopOverMouseEnter"
            @mouseleave="onPopOverMouseLeave"
            @focusin="onPopOverMouseEnter"
            @focusout="onPopOverMouseLeave"
          >
            <div>
              <span>{{ innerText }}</span>
            </div>
            <div class="kp-content-container">
              <!-- <div>
                <span class="kp-icon"></span>
              </div> -->
              <div
                v-close-popper
                class="kp-link bluelink "
              >
                <span
                  class="theme-light"
                  role="button"
                  tabindex="0"
                  @click="onLinkClick()"
                  @keyup.enter="onLinkClick()"
                >{{ navigationLinkName }}</span>
              </div>
            </div>
          </div>
        </template>
      </le-bubble>
    </div>
  </div>
</template>

<script>
import { leBubble } from '@liveperson/lp-vue-components';
import { environmentActionTypes, environmentGetterTypes, Logger, storeModuleTypes, Vuex } from 'vue-infra';

const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
  page: environmentGetterTypes.PERSONALIZATION_WIDGET_PAGE,
  isOpen: environmentGetterTypes.PERSONALIZATION_WIDGET_IS_OPEN,
  featuresByRoutCount: environmentGetterTypes.PERSONALIZATION_WIDGET_FEATURE_BY_ROUT_COUNT
});
const LEKPEvent = 'openKP';
const componentName = 'personalization-widget';
const logger = Logger.getLogger('LiveEngage:TopBar');

export default {
  name: componentName,
  components: {
    leBubble,
  },
  data() {
    return {
      popoverTimer: undefined,
      navigationLinkName: this.$translator.translate('LEPersonalization.webAgent.ACD.title.learnMore'),
      personalizationLinkName: this.$translator.translate('LEPersonalization.widget.itemsToPersonalize'),
      popoverOptions: {
        html: true,
        triggers: [],
        container: '.data-bar-wrapper',
        autoHide: false,
        delay: {
          show: 500,
          hide: 0
        },
        shown: false,
        // TODO - Julie / Rosen - popoverBaseClass was removed - investigate other options for adding our own classes to the poppers.
        // https://v-tooltip.netlify.app/migration/migration-from-v2#removed-props
        // bubbleBaseClass: 'popover personalization-popover',
      },
    };
  },
  computed: {
    featuresByRoutCount: getters.featuresByRoutCount,
    isOpen: getters.isOpen,
    innerText: function () {
      var text;
      if (this.featuresByRoutCount > 0) {
        text = this.$translator.translate({
          key: 'LEPersonalization.widget.itemsToPersonalize',
          args: [this.featuresByRoutCount],
        });
      } else {
        text = this.$translator.translate('LEPersonalization.widget.noItemsToPersonalize');
      }
      return text;
    },
  },
  methods: {
    onTargetMouseEnter() {
      clearTimeout(this.popoverTimer);
      this.popoverOptions.shown = true;
    },
    onTargetMouseLeave() {
      this.popoverTimer = setTimeout(function () {
        this.popoverOptions.shown = false;
      }.bind(this), 500)
    },
    onPopOverMouseEnter() {
      clearTimeout(this.popoverTimer);
    },
    onPopOverMouseLeave() {
      this.popoverOptions.shown = false;
    },
    onPersonalizationIconClick() {
      if (this.featuresByRoutCount > 0 || this.isOpen) {
        this.$store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.PERSONALIZATION_WIDGET_IS_OPEN_TOGGLE}`);
        logger.bam({
          serviceName: 'CCUI-CORE',
          appName: 'CCUI',
          eventName: 'night vision opened',
          widgetName: 'header',
        });
      }
    },
    onLinkClick() {
      LE.vent.trigger(LEKPEvent, { fragment: 'advanced-configuration-overview' });
    },
  },
};

</script>

<style lang='scss'>
@import "../../../assets/scss/global";
@import "../../../assets/scss/mixins";

.personalization-widget {
  .trigger {
    outline: none;
  }
}

.topBarWidgetIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;

  /* todo - move this to its own component */
  &.personalizationIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-size: 16px;
    @include themify {
      color: getColor('data-bar-icons-active', 'framework');
      &:hover {
        background: getColor('data-bar-icon-hover-background', 'framework');
        border-radius: 8px;
      }
      &.personalizationIcon-disabled {
        color: getColor('data-bar-icons-disabled', 'framework');
        cursor: default;
      }
    }
  }
}

// TODO - Julie / Rosen - popoverBaseClass was removed - investigate other options for adding our own classes to the poppers.
// https://v-tooltip.netlify.app/migration/migration-from-v2#removed-props
// .le-bubble.personalization-popover {
//   .tooltip-arrow{
//     @include themify {
//       border-bottom-color: getColor('reporting-tooltip-bubble-bg', 'framework');
//       border-left-color: transparent !important;
//       &:after {
//         border-right-color: getColor('reporting-tooltip-bubble-bg', 'framework') !important;
//         border-left-color: transparent !important;
//         border-bottom-color: getColor('reporting-tooltip-bubble-bg', 'framework') !important;
//       }
//     }
//   }
//   .le-bubble-inner {
//     @include themify {
//       background-color: getColor('reporting-tooltip-bubble-bg', 'framework');
//       border-color: getColor('reporting-tooltip-bubble-bg', 'framework');
//       color: getColor('reporting-tooltip-bubble-text', 'framework');
//     }

//     .personalization-popover-content {
//       max-width: 250px;
//       line-height: 18px;
//       > div {
//         display: inline;
//       }
//       .kp-content-container {
//         display: inline-block;
//         vertical-align: top;
//         margin-left: 7px;

//         > div {
//           display: inline-flex;
//           vertical-align: middle;
//         }
//       }

//       .kp-icon {
//         padding-left: 0;
//         top: -4px;
//         height: 17px;
//         left: 0;
//       }

//       .kp-link {
//         cursor: pointer;
//         padding-left: 0;
//         span {
//           @include themify {
//             color: getColor('link');
//           }
//         }

//         > a {
//           outline: none;
//         }
//       }
//     }
//   }
// }

</style>




