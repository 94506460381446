define(function (require) {
    "use strict";

    var $ = require("jquery");

    var LpAjaxRequestTransport = require("lptransporter/lpajax.request");

    var req = $.extend(true, {}, LpAjaxRequestTransport);

    // Default to not use init
    delete req.init;

    delete req.transportOrder;
    // Default to not use any progress handler which will get called back for every XHR update and incurs a post message from the iframe
    // For any specific need for this progress handler, it should be supplied via the options object
    delete req.progress;

    return req;
});
