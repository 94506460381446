<template>
  <div
    v-if="showModal"
    class="ftue-modal-mask"
  >
    <div class="ftue-modal">
      <return-experience
        v-if="openFromQuestionLink"
        :user-details="userDetails"
        :product-tour-url="productTourUrl"
      />
      <welcome-experience
        v-else
        :product-tour-url="productTourUrl"
        :user-details="userDetails"
      />
    </div>
  </div>
</template>

<script>
import WelcomeExperience from './WelcomeExperience';
import ReturnExperience from './ReturnExperience';
import CompoundFeatureIds from 'assets/constants/compound-features-ids.json';
import {
  Vuex,
  gChannel,
  sessionManager,
  storeModuleTypes,
  environmentGetterTypes
} from 'vue-infra';

const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
  loggedInUserDetails: environmentGetterTypes.LOGGED_IN_USER_DETAILS
});

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Ftue',
  components: {
    WelcomeExperience,
    ReturnExperience
  },
  data() {
    return {
      firstTimeExperienceEnabled: true,
      showModal: false,
      openFromQuestionLink: false,
      productTourUrl: null
    };
  },
  computed: {
    userDetails: getters.loggedInUserDetails
  },
  created() {
    //send action from TopLevelLinks (?) button
    gChannel.register(
      'LiveEngageCommon',
      'FirstTimeExperienceModal:open',
      this.handleOpen
    );
    gChannel.register(
      'LiveEngageCommon',
      'FirstTimeExperienceModal:open-initial',
      this.handleOpenInitial
    );
    gChannel.register(
      'LiveEngageCommon',
      'FirstTimeExperienceModal:close',
      this.close
    );
  },
  beforeMount() {
    let firstTimeLogin = LE.firstTimeLogin;
    let firstTimeExperienceEnabled = sessionManager.getFeaturePropertyState(
      CompoundFeatureIds.FIRST_TIME_EXPERIENCE_ENABLED
    );
    this.firstTimeExperienceEnabled = firstTimeExperienceEnabled;
    this.showModal = firstTimeLogin && firstTimeExperienceEnabled;
    this.productTourUrl = sessionManager.LEConfig.developersCommunityDomain;
  },
  methods: {
    close: function() {
      this.showModal = false;
    },
    handleOpen: function() {
      if (this.firstTimeExperienceEnabled) {
        this.showModal = true;
        this.openFromQuestionLink = true;
      }
    },
    handleOpenInitial: function() {
      if (this.firstTimeExperienceEnabled) {
        this.showModal = true;
        this.openFromQuestionLink = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ftue-modal-mask {
  @include themify {
    background-color: getColor('ftue-background', 'framework');
  }
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: auto;
  z-index: 10000;
  position: fixed;
  transition: opacity 0.3s ease;
  .ftue-modal {
    @include themify {
      background-color: getColor('ftue-container', 'framework');
    }
    width: 60%;
    max-width: 672px;
    height: 80%;
    margin: 0 auto;
    margin-top: 4%;
    overflow: hidden;
    border-radius: 16px;
    -webkit-font-smoothing: antialiased;
  }
}
</style>
