define(function (require) {
    "use strict";

    var BaseCollection = require("collections/BaseCollection");
    var CountriesModel = require("models/entities/CountriesModel");
    var ApiResources = require("assets/data/apiResources.json");
    var countryCodes = require('le-core-infra/src/data/codeTables/countryCodes.json');
    var _ = require('underscore');
    var FETCH_METHOD = "GET";
    var NAME = "CountriesCollection";
 // /Users/iraphael/dev/live-engage-fe/fe-framework/node_modules/le-core-infra/src/data/codeTables/countryCodes.json

  var CountriesCollection = BaseCollection.extend({
        model: CountriesModel,
        name: NAME,
        resource: ApiResources.Internal.Countries,
        initialize: function(models,options) {
          BaseCollection.prototype.initialize.call(this, models, options);
        },
        sync: function (method, collection, options) {
          options.success(countryCodes.countryCodesArr);
        },

        parse : function (response, options) {

          response = _.filter(response, function (countriesAttributes) {
            if(countriesAttributes.key === "other") {
              return;
            }
            countriesAttributes.iconClass = "icon_" + countriesAttributes.key;
            return countriesAttributes; //basically I should create a new object but performance wize ...
          });
          return BaseCollection.prototype.parse.call(this, response, options);
        },

        fetch: function(options) {
          var successFunc = options.success;
          options.success = function (collection, response, options) {
            //collection.setDefault();
            if (_.isFunction(successFunc)) {
              successFunc(collection, response, options);
            }
            return response;
          };
          //Call BaseCollection's fetch
          return BaseCollection.prototype.fetch.call(this, options);
        },
        getDefault : function () {
          return this.defaultCountry;
        },
        setDefault : function () {
          var defaultCountry = this.find(function (country) {
            return country.get("isDefault");
          });
          this.defaultCountry = defaultCountry || null;
        },

        getFetchMethod: function(){ //used by fetcher
          return FETCH_METHOD;
        },

        getFetchHeaders: function(){  //used by fetcher
          return {"accept": "application/json"};
        }
    });

    CountriesCollection.NAME = NAME;

    return CountriesCollection;
});
