/**
 * In order to decouple the LiveEngage project into micro frontend, we created this API that will be used in the external module applications.
 * This API exposes all the files that are shared across the LiveEngage modules and must remain to be located in the framework.
 * This file is exposed on the window (Added in the pack-loader.js file).
 * This file should includes only required files - const files (includes all the json files - channel, siteSettings and etc.) and APIResources should be decoupled as well!!!
 * Please try to keep this file as small as you can :)
 */

define(function (require) {
  const Media = require("media");
  const Storage = require("assets/js/storage");
  const LEResourceResolver = require("leResourceResolver");
  const LocaleResolver = require('i18n/localeResolver');
  const Translator = require("i18n/translator");
  const LEConfig = require("assets/config/le-env-conf");
  const ErrorCodes = require("codeTables/errorCodes.json"); // remove
  const ValidationUtilities = require("utils/ValidationUtilities"); // remove
  const NameUtility = require("utils/NameUtility"); // remove
  const StringFormats = require("utils/StringFormats"); // remove
  const LanguageManager =  require("LanguageManager");
  const TimeUtils = require("reporting-assets/js/TimeUtils"); // remove
  const TimeUtilities = require("utils/TimeUtilities"); // remove
  const Backbone = require("backbone");
  const Marionette = require("marionette");
  const lpUtils = require("utils/lpUtils");
  const DateUtility = require("utils/DateUtility");
  const es6MiddleUtils = require("src/es6MiddleUtils");

  // controllers
  const BaseDataManager = require("controllers/BaseDataManager");
  const BaseRouterController = require("controllers/BaseRouterController");
  const ModuleAwareNotificationManager = require("src/controllers/ModuleAwareNotificationManager");

  // collections
  const BaseCollection = require("collections/BaseCollection");
  const RevisionedCollection = require("collections/RevisionedCollection");
  const UsersCollection = require("collections/entities/UsersCollection"); // remove
  const SelectableCollectionDecorator = require("decorators/SelectableCollectionDecorator"); // remove
  const OrganizeCollectionDecorator = require ("decorators/organizeCollectionDecorator/OrganizeCollectionDecorator"); // remove
  const SkillsCollection = require("collections/entities/SkillsCollection"); // remove
  // const SurveyCollection = require("collections/entities/SurveyCollection"); // remove
  const AgentSurveysCollection = require("collections/entities/AgentSurveysCollection"); // remove
  const ProfilesCollection = require("collections/entities/ProfilesCollection"); // remove
  const SettingsCollection = require("collections/SettingsCollection");

  // models
  const BaseModel = require("models/BaseModel");
  const RevisionedModel = require("models/RevisionedModel");
  const ProfileModel = require("models/entities/ProfileModel"); // remove
  const PersonModel = require("models/entities/PersonModel"); // remove
  const SiteSettingsModel = require("models/SiteSettingsModel"); // remove
  const LobModel = require("models/entities/LobModel"); // remove
  const SkillModel = require("models/entities/SkillModel"); // remove
  const UserModel = require("models/entities/UserModel"); // remove
  // const SurveyModel = require("models/entities/SurveyModel");

  // components
  const DependencyService = require("src/components/dependency-service/controllers/DependencyService"); // remove
  const DependencyCollection = require("src/components/dependency-service/collections/DependencyCollection"); // remove
  const DependencyModel = require("src/components/dependency-service/models/DependencyModel"); // remove

  // ui-components
  const Page = require("ui.components/page/Page");
  const KPContent = require("ui.components/kpContent/kpContent");
  const SchedulePicker = require("ui.components/schedule-picker/SchedulePicker");
  const SpecialOccasionPicker = require("ui.components/special-occasion-picker/SpecialOccasionPicker");

  // Moved to registerExtendedComponentLevel2 to avoid issues with early calls to data manager
  const NotificationLayerView = window.LE && window.LE.getConfig() && window.LE.getConfig().dev ?
    {} :
    require("ui.components/notification-layer/NotificationLayerView");
  const Gallery = require("ui.components/gallery/gallery");
  const ConversationSourcesGallery = require("ui.components/gallery/ConversationSourcesGallery");
  const ApiKeysGallery = require("ui.components/gallery/apiKeysGallery");
  const AppKeysGallery = require("ui.components/gallery/appKeysGallery");
  const NoData = require("ui.components/no-data/NoData");
  const Ellipsis = require("ui.plugins/ellipsis");

  // marionette-ui-components
  const PlusCompositeView = require("ui/plus-composite-view/PlusCompositeView"); // remove
  const Dropdown = require("ui/dropdown/Dropdown"); // remove
  const InputField = require("ui/input-field/InputField"); // remove
  const RadioButtonList = require("ui/radio-button-list/RadioButtonList"); // remove
  const ToggleButton = require("ui/toggle-button/toggleButton"); // remove
  const MultiSelect = require("ui/multiselect/Multiselect"); // remove
  const MultiselectWithToggle = require("ui/multiselect-with-toggle/MultiselectWithToggle");
  const Button = require("ui/button/Button"); // remove
  const Bubble = require("ui/bubble/Bubble"); // remove
  const SingleSelect = require("ui/singleselect-new/Singleselect"); // remove
  const Checkbox = require("ui/checkbox/Checkbox"); // remove
  const Loader = require("ui/loader/Loader"); // remove
  const Dialog = require("ui/dialog/Dialog"); // remove
  const Paging = require("ui/paging/Paging"); // remove
  const DropdownMenu = require("ui/dropdown-menu/DropdownMenu"); // remove
  const InlineTextarea = require("ui/inline-textarea/InlineTextarea"); // remove
  const InputSearch = require("ui/input-search/InputSearch"); // remove
  const TagsContainer = require("ui/tags-container/TagsContainer");

  return {
    Backbone,
    Marionette,
    Media,
    Storage,
    LEResourceResolver,
    LocaleResolver,
    Translator,
    LEConfig,
    ErrorCodes, // remove
    ValidationUtilities, // remove
    NameUtility, // remove
    StringFormats, // remove
    LanguageManager,
    TimeUtils, // remove
    TimeUtilities, // remove
    lpUtils,
    DateUtility,
    es6MiddleUtils,
    BaseDataManager,
    BaseRouterController,
    ModuleAwareNotificationManager,
    BaseCollection,
    RevisionedCollection,
    UsersCollection, // remove
    SelectableCollectionDecorator, // remove
    OrganizeCollectionDecorator, // remove
    SkillsCollection, // remove
    // SurveyCollection, // remove
    AgentSurveysCollection, // remove
    ProfilesCollection, // remove
    SettingsCollection,
    BaseModel,
    RevisionedModel,
    ProfileModel, // remove
    PersonModel, // remove
    SiteSettingsModel, // remove
    LobModel, // remove
    SkillModel, // remove
    UserModel, // remove
    // SurveyModel, // remove
    DependencyService, // remove
    DependencyCollection, // remove
    DependencyModel, // remove
    Page,
    KPContent,
    SchedulePicker,
    SpecialOccasionPicker,
    NotificationLayerView,
    Gallery,
    ConversationSourcesGallery,
    ApiKeysGallery,
    AppKeysGallery,
    NoData,
    Ellipsis,

    PlusCompositeView, // remove
    Dropdown, // remove
    InputField, // remove
    RadioButtonList, // remove
    ToggleButton, // remove
    MultiSelect, // remove
    MultiselectWithToggle,
    Button, // remove
    Bubble, // remove
    SingleSelect, // remove
    Checkbox, // remove
    Loader, // remove
    Dialog, // remove
    Paging, // remove
    DropdownMenu, // remove
    InlineTextarea, // remove
    InputSearch, // remove
    TagsContainer,
  };
});
