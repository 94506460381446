/**
 * Created with IntelliJ IDEA.
 * User: elanh
 * Date: 3/14/13
 * Time: 8:59 AM
 * To change this template use File | Settings | File Templates.
 */

/*
 @title - navigator.js
 @author - Elan Hami
 @version - 0.1
 @date - 3/14/2013

 A Navigator object that abstracts the usage of Backbone.Router in order to create prefixed routes
 it allows you to navigate to sub-routes under the prefix without the need to use the prefix every time you want to
 call navigate.
 To use, create a Marionette.Controller instance that holds a routes object of routes/methods and the implementation
 of those methods, and call Navigator.getRouter("myModuleName", "myRoutePrefix", myRouteController); and you will get a router that
 exposes the function:
 navigate(route[, options]) -
 route - the route you want to navigate WITHOUT the prefix
 options - an options object that can include the following properties:
 //TBD
 */
 define(function (require) {
  "use strict";
  const es6MiddleUtils = require("src/es6MiddleUtils");
  const RouteUtils  = es6MiddleUtils.removeEsModuleDefault(require('./RouteUtils'));
  const _ = require("underscore");
  const $ = require("jquery");
  const Media = require("media");
  const Marionette = require("marionette");
  const RouteNameBuilder  = es6MiddleUtils.removeEsModuleDefault(require('./RouteNameBuilder'));
  const { Logger } = require('vue-infra');
  const LEResourceResolver = require("leResourceResolver");
  const Modernizr = require("modernizr");
  const { LOGIN_ERROR_TO_AUTH_ERROR_MAP } =  require('vue-infra').sessionKeys;
  const LEConfig = require("assets/config/le-env-conf");
  const { sessionManager, environmentActionTypes, storeModuleTypes, store } = require('vue-infra');

  var Navigator = (function () {
    var ver = "0.1";

    var isFirstNavigate = true;
    // var storeRouteInHistoryStack = STORE_ROUTE_IN_HISTORY_DEFAULT;

    // TODO: remove LEWebAgentVeu: "rep"
    var modulePrefixes = {
      LEWebAgentVeu: "aw",
      LEAgentManagerDashboardVue: 'amd',
      LESettingsVue: 'set',
      //moduleName : prefix
    };

    var modulesRouters = {
      //moduleName : router
    };

    //TODO: something smart....,
    var defaultRoutes = {
      LEWebAgentVeu: "my-connections",
      LEAgentManagerDashboardVue: 'dashboard',
      LESettingsVue: 'set',
      //moduleName : defaultRoute
    };

    var modulesCurrentHash = {
      //moduleName : modulecurrentHash
    };

    // var modulesNavigationActivated = {
    //     //moduleName : bool
    // };

    var routesAuthentication = {
      //route : { privileges: [], redirect: "" }
    };

    var prevController = null;

    var context = null;

    var defaultRouter = null; // This will reference the first registered router (assumes that this should be the application router)
    // var activeRouter = null;  // This will reference the active router
    var currentPrivileges = []; // This will reference authentication privileges
    var childWindows = []; // This will reference opened childWindows

    var getRouteByKey = function (key, controller, pointer) {
      pointer = pointer || controller;

      if (_.isFunction(pointer[key])) {
        return _.findKey(controller.navigation.routes, function (callback) {
          return key === callback;
        });
      } else {
        return pointer[key];
      }
    };

    function getDefaultRoute(noduleName){
      return defaultRoutes[noduleName];
    }

    // var setActiveRouter = function (router) {
    //     activeRouter = router;
    // };

    var setCurrentPrivileges = function (privileges) {
      currentPrivileges = privileges;
    };

    // var getActiveRouter = function () {
    //     return this.router && this.router.controller;
    // };

    var getActiveModuleName = function() {
      return this.router.currentRoute.value.meta.marrionetteModule;
    };

    // var catchUpCurrentHash = function (moduleName) {
    //     modulesCurrentHash[moduleName] = getFragment.call(this);
    // };

    /*
     resolve the default route and save it for later use
     */
    var resolveDefaultRoute = function (moduleName, controller) {
      //resolve the default route and add it to the default routes object for later use
      if (controller.navigation && controller.navigation.defaultRoute) {
        defaultRoutes[moduleName] = getRouteByKey.call(this, "defaultRoute", controller, controller.navigation);
      } else if (controller.navigation && controller.navigation.routes && controller.navigation.routes[":defaultRoute"]) {
        defaultRoutes[moduleName] = getRouteByKey.call(this, ":defaultRoute", controller, controller.navigation.routes);
      } else {
        defaultRoutes[moduleName] = "";
      }
    };

    /**
     * checks for backbone dynamic routes, by matching any of the following characters:
     * ":" - params
     * "*" - splats
     * "(" - optional segments
     * @type {RegExp}
     */
    var dynamicRouteRegExp = /^.*[:\*\(].*$/;

    /**
     * checks if the route is dynamic, by matching backbone dynamic routes placeholder characters in the route.
     * @param route the route to be checked.
     * @returns {*} a truthy value, if the route is dynamic (the match result), or a falsy value otherwise (the result
     * of the match will be <code>null</code>).
     */
    var isDynamicRoute = function (route) {
      return _.isString(route) && route.match(dynamicRouteRegExp);
    };

    /**
     * resolves the callback's matching route, by iterating over the controller's routes and matching static routes,
     * and than by matching the (dynamic or non-existing) route to the current hash fragment.
     * @param router the module's effective router, which owns the controller for the lookup.
     * @param callback the callback to look for.
     * @returns {*} the resolved route, or <code>false</code> if non was found.
     */
    // var resolveCallbackRoute = function (router, callback) {
    //     // Resolve the callback route
    //     for (var rt in router.controller.navigation.routes) {
    //         if (rt && router.controller.navigation.routes.hasOwnProperty(rt) &&
    //             (callback === router.controller.navigation.routes[rt].callback ||
    //             callback === router.controller.navigation.routes[rt])) {
    //             return rt;
    //         }
    //     }
    // };

    /**
     * resolves the route from the given router, using the module's currently mapped hash.
     * @returns {*} the resolved route, or a falsy value if non was found.
     */
    var resolveCurrentHashRoute = function () {
      var module = this.route && this.route.meta && this.route.meta.marrionetteModule;
      return RouteNameBuilder.getRoute(modulesCurrentHash[module]);
    };

    /**
     * checks if the current route is ahead of the effective route, which detects if a navigation from module to
     * module was performed without calling navigate(), i.e. via browser history events - back/forward/bookmark.
     * @param moduleName the handled module name.
     * @returns {boolean} <code>true</code> if the route is ahead of the effective route, <code>false</code> otherwise.
     */
    // var aheadOfRoute = function (moduleName) {
    //     // A head of route is a route which had already completed since the browser URL had been manually updated by the user
    //     // This means that the navigator did not invoke the navigate method but only the callback and the context had not been updated with the real current route
    //
    //     var effective = this.getEffectiveModuleRouter(moduleName);
    //     var activeRouter = this.getActiveRouter();
    //
    //     return effective !== activeRouter;
    // };

    /**
     * checks if the current hash fragment is ahead of the registered hash fragment, which detects if a navigation
     * inside a module was performed without calling navigate(), i.e. via browser history events - back/forward/bookmark.
     * @param moduleName the handled module name.
     * @returns {boolean} <code>true</code> if the current hash fragment is ahead of the registered hash fragment,
     * <code>false</code> otherwise.
     */
    // var aheadOfHash = function (moduleName) {
    //     return modulesCurrentHash[moduleName] !== getFragment.call(this);
    // };

    /**
     * attempts to register navigation information to the context.
     * if fails, falls back to navigation to the module's default route, if any was found.
     * @param moduleName the handled module name
     * @param callback the route callback from the controller's navigation routes.
     * @param route
     * @param routeController
     */
    var registerContextInformation = function (moduleName, callback, route, routeController) {
      // All route attributes should be re-calculated since it is ahead of route
      // First lets get the effective router
      // var effective = Navigator.getEffectiveModuleRouter.call(this, moduleName);

      // get the default route for this module
      var defaultRoute = defaultRoutes[moduleName];
      // Get the prefix for this module
      var prefix = modulePrefixes[moduleName];
      // var route;

      if (/*effective &&*/ prefix) {
        //     // Resolve the route callback
        //     route = resolveCallbackRoute.call(this, effective, callback);
        //     // if we got a dynamic route (e.g. campaigns/:campaignId), we can't use it, resort to resolve route by the hash
        if (isDynamicRoute.call(this, route)) {
          route = resolveCurrentHashRoute.call(this);
        }

        if (route) {
          // Assemble the fixed route
          var fixedRoute = fixRoute.call(this, prefix, route);
          // Store all needed information on the context
          storeNavigationContextInformation.call(this, moduleName, route, fixedRoute, {controller: routeController});  //make sure navigation information is stored for the "smart back" and module navigation info
        } else if (defaultRoute) {
          // Something is invalid - fallback to the default route of the module
          navigate.call(this, defaultRoute);
        }
      }
      // else if (defaultRoute) {
      //     // Something is invalid - fallback to the default route of the module
      //     activeRouter.navigate(defaultRoute);
      // }
    };

    var initialize = function (options) {
      options = options || {};
      this.vent = options.vent || Media.channel("Navigator").vent;
      this.commands = options.commands || Media.channel("Navigator").commands;
      this.reqres = options.reqres || Media.channel("Navigator").reqres;
      this.context = options.context || null;

      //context logger
      this.logger = Logger.getLogger("LEFramework:Navigator");

      //create a local app router for the navigator
      this.router = options.router;
      this.routes = options.routes;
      this.route = "";

      //listen to navigation commands
      this.commands.setHandler("navigate:login", function (options) {
        navigateLogin.call(Navigator, options);
      });
      this.commands.setHandler("navigate:denied", function (options) {
        navigateDenied.call(Navigator, options);
      });
      this.commands.setHandler("navigate:switch", function (options) {
        navigateNewAccountIdUrl.call(Navigator, options);
      });

      // - method stub: this is an empty implementation for usage documentation -
      // Backbone.history.on("route") callbacks will be called after the handler
      // for this.router.on("route:" + routeName) is called, and is invoked for every navigation scenario -
      // i.e. calling navigate() on a router, or via browser history events.
      // if navigate() was called, navigate() will be invoked before
      // Backbone.history.on("route", function (router, name) {
      // }, this);

      // Allow Chaining
      return Navigator;
    };

    var navigateLogin = function (options) {
      var route = "login";

      if (!_.isUndefined(options.errorCode) && !_.isUndefined(LOGIN_ERROR_TO_AUTH_ERROR_MAP[options.errorCode])) {
        route += "/" + LOGIN_ERROR_TO_AUTH_ERROR_MAP[options.errorCode];
      }

      // Redirect the to the login page.
      // this.router.push('/' + route, options.success, options.error);
      this.router.push('/' + route);
    };

    var navigateDenied = function (options) {
      var route = "denied";

      if (!_.isUndefined(options.errorCode) && !_.isUndefined(LOGIN_ERROR_TO_AUTH_ERROR_MAP[options.errorCode])) {
        route += "/" + LOGIN_ERROR_TO_AUTH_ERROR_MAP[options.errorCode];
      }

      // 403 -- Access denied
      this.router.navigate(route, options);
    };

    var navigateNewAccountIdUrl = function (options) {
      if (_.isString(options.accountId) && _.isString(options.newAccountId)) {
        var loc = window ? window.location : document.location;

        if (loc) {
          var section = "/" + options.accountId;
          var newSection = "/" + options.newAccountId;

          if (-1 < loc.pathname.indexOf(section)) {
            loc.pathname = loc.pathname.replace(section, newSection);
          }
        }
      } else {
        navigateLogin.call(this, options);
      }
    };


    //adds the routes and handlers in controller to router

    var extendRoutes = function (moduleName, controller, isPrivilegesIgnored) {
      _.each(controller.navigation.routes, function (data, route, routes) {
        //adds a route to the router

        var cb = data.callback || data;

        // skip default flag
        if (":defaultRoute" !== route) {
          // Assemble a unique name from the module and the callback
          var routeName = RouteNameBuilder.assembleName(moduleName, cb);

          //this.logger.debug("adding a route event handler to the router: " + routeName + "; callback: " + cb);

          // this will be called before the handler for Backbone.history.on("route"), and is invoked for
          // every navigation scenario - i.e. calling navigate() on a router, or via browser history events.
          // if navigate() was called, navigate() will be invoked before
          // this.router.on("route:" + routeName, function () {
          //
          //   // checks for detecting if a user clicked back/forward or pasted a bookmarked url:
          //   // if, as a result, the module has changed
          //   if (aheadOfRoute.call(this, moduleName)) {
          //     // recalculate the state for module to module navigation
          //     setActiveRouter.call(this, modulesRouters[moduleName]);
          //   }
          //   // if, as a result, the path was changed within a module
          //   if (aheadOfHash.call(this, moduleName)) {
          //     // recalculate the state for navigation inside a module
          //     catchUpCurrentHash.call(this, moduleName);
          //   }
          //
          //   // now it's safe to register info, regardless of whether navigate() was activated or
          //   // browser back/forward etc. got us here
          //   registerContextInformation.call(this, moduleName, cb);
          //
          //
          //   //run callback only if not switching OR if Its the first module navigate (No previous hash was found).
          //   if (!modulesRouters[moduleName].switching || !modulesNavigationActivated[moduleName]) {
          //     modulesNavigationActivated[moduleName] = true;
          //     controller[cb].apply(controller, [].slice.apply(arguments));
          //   }
          //
          //   modulesRouters[moduleName].switching = false;
          //   // we are adding setTimeout in order to set the process in the end of the queue, this is for fixing LE-8307,
          //   // adding the setTimeout will give time to marionnette to render the view and call it callback
          //   _.defer(function (context) {
          //     context.vent.trigger("module:active", {moduleName: moduleName});
          //   }, this);
          // }, this);


          var auth = (isPrivilegesIgnored) ? null : data.auth;

          addRoute.call(this, modulePrefixes[moduleName], route, routeName, auth);
        }
      }, this);
    };

    /*
     fixes a route to include the prefix and adds it to the router with the callback as a handler
     prefix - the prefix to of the route
     route - the route fragment
     name - a name of the event that will be fired "router:name"
     auth - authentication data for this route
     */
    var addRoute = function (prefix, route, name, auth) {
        var fixedRoute = fixRoute.call(this, prefix, route);

        this.logger.debug("adding a route authentication data for: " + fixedRoute);
        routesAuthentication[fixedRoute] = preProcessAuthData(auth);

        this.logger.debug("adding a route to the router: " + fixedRoute);
        //this.router.route(fixedRoute, name, null, routesAuthentication[fixedRoute]);
    };

    /*
     fixes a route by adding the prefix to it
     */
    var fixRoute = function (prefix, route) {
      var fixedRoute = RouteNameBuilder.assembleName(prefix, route);
      this.logger.debug("Fixing route. Original route: " + route + "; New route: " + fixedRoute);
      return fixedRoute;
    };

    var escapeHash = {
      data: function (input) {
        var ret = escapeHash[input];
        if (!ret) {
          if (input.length - 1) {
            var len = input.length - 3;
            var pos = len ? 2 : 1;
            ret = String.fromCharCode(parseInt(input.substring(pos), 16));
          } else {
            var code = input.charCodeAt(0);
            ret = code < 256 ? "%" + (0 + code.toString(16)).slice(-2).toUpperCase() : "%u" + ("000" + code.toString(16)).slice(-4).toUpperCase();
          }
          escapeHash[ret] = input;
          escapeHash[input] = ret;
        }

        return ret;
      }
    };

    var escapeUrl = function (str) {
      return str.replace(/[^\w @\*\-\+\.\/]/g, function (aChar) {
        return escapeHash.data(aChar);
      });
    };

    var unescapeUrl = function (str) {
      var parsed = str.replace(/\+/g, "%20");
      return parsed.replace(/%(u[\da-f]{4}|[\da-f]{2})/gi, function (seq) {
        return escapeHash.data(seq);
      });
    };

    var redirect = function (url) {
      var loc = window ? window.location : document.location;
      loc.href = url;
    };

    var reload = function (noCache) {
      var loc = window ? window.location : document.location;
      loc.reload(noCache);
    };

    var replace = function (url, options) {
      options = options || {full: false};

      var loc = window ? window.location : document.location;

      if ("" !== url && !options.full) {
        var split = loc.toString().split("#");
        if (1 == split.length) {
          split.push("");
        }
        split[split.length - 1] = url;
        url = split.join("#");
      }

      loc.replace(url);
    };

    var hash = function (url) {
      var loc = window ? window.location : document.location;
      loc.hash = url;
    };

    var storeNavigationContextInformation = function (moduleName, route, fixedRoute, options) {

      this.logger.debug("storing information for navigation action", "storeNavigationContextInformation");

      var context = this.context;

      if (!isFirstNavigate && context.get("activeModuleName")) {  //set the previous route's information
        context.set("previousApplicationRoute.module", context.get("activeModuleName")); //save the current module name before it potentially changes during navigation
        context.set("previousApplicationRoute.route", context.get("navigationCurrent.route"));
        context.set("previousApplicationRoute.fixedRoute", context.get("navigationCurrent.fixedRoute"));

        // if (!isSpecialNavigationRoute.call(this, route)) {
        //     context.clearNavigationBackRoutes();
        // }
        // else if (storeRouteInHistoryStack) {
        //     context.addNavigationBackRoute({
        //         module: context.get("activeModuleName"),
        //         route: context.get("navigationCurrent.route"),
        //         fixedRoute: context.get("navigationCurrent.fixedRoute")
        //     });
        // }
      }
      // else {
      //     context.clearNavigationBackRoutes();
      // }
      // storeRouteInHistoryStack = STORE_ROUTE_IN_HISTORY_DEFAULT;

      //set the current route information

      function alignRouteToVueRouter(route){
        const parts = route.split('/');
        parts.splice(1,1);
        return parts.join('/');
      }
      context.set("navigationCurrent.route", alignRouteToVueRouter(route));
      context.set("navigationCurrent.fixedRoute", alignRouteToVueRouter(fixedRoute));

      // set the current hash in to the context for the current module
      context.set("modulesNavigation." + moduleName + ".route", alignRouteToVueRouter(route));
      context.set("modulesNavigation." + moduleName + ".fixedRoute", alignRouteToVueRouter(fixedRoute));
      context.set("modulesNavigation.activeModule.fixedRoute", alignRouteToVueRouter(fixedRoute));
    };

    /**
     *  doApplicationBack
     *
     * the method performs "back" according to several requirements
     *
     * 1. if a previous application route from the history stack is available it will be the one to navigate to
     * 2. if the first is not possible, the default route for the current user will calculated and navigated to
     *
     */
    var doApplicationBack = function () {
      if(!this.prevRoute || this.prevRoute.fullPath === this.router.currentRoute.value.fullPath){
        this.router.replace(LEConfig.defaultRoute);
      }
      else {
        this.router.go(-1);
      }
      // var navInfo = this.context.popLastNavigationBackRoute();
      //
      // if (_.isObject(navInfo)) {
      //     this.logger.debug("Navigating to provided context: ", "doApplicationBack", navInfo);
      //
      //     var effective = getEffectiveModuleRouter(navInfo.module);
      //     effective.navigate.call(this, navInfo.route);
      // }
      // else { //fallback to the application entry point
      //     this.logger.debug("Navigating to user's default home", "doApplicationBack");
      //
      //     var moduleToRoute = this.getActiveRouter().controller.navigation.fallbackRoute || "";
      //     LE.navigateToEntryPointUrl({ignoreLoginInfo: true, moduleToRoute: moduleToRoute}); // TODO: Tomer: navigator cannot use "LE". need different solution. expected functionality: let the application decide where to the send the user to
      // }
    };

    /*
     navigates to the route
     */
    var navigate = async function (moduleName, route, options) {

      var prefix = modulePrefixes[moduleName] || "";
      var referrer = document.location.href;

      options = options || {};

      if (prevController && !options.skipBlock && options.isBlockNavigation) {
        prevController.blockNavigation($.extend(options, {
          callback: _.bind(function () {
            navigate.call(this, moduleName, route, $.extend(options, {skipBlock: true}));
            return true;
          }, this)
        }));
      } else {
        if (options.controller) {
          prevController = options.controller;
        }
        if ((!route || "" === route) && defaultRoutes[moduleName]) {
          route = defaultRoutes[moduleName];
        }
        var fixedRoute = fixRoute.call(this, prefix, route);
        modulesCurrentHash[moduleName] = fixedRoute;

        this.logger.debug("Navigating to: " + fixedRoute);

        if (!options.location && !options.redirect && !options.nonBackable) {
          this.router.push(fixedRoute);
          // hash.call(this, fixedRoute);
          // redirect.call(this, fixedRoute);
        } else if (options.nonBackable) {
          // hash.call(this, fixedRoute);
          // Waiting for this.router.replace() to finish resolves the flow issue...
          // caused by calling updateFirstTimeNavFlag before setUpRoutesGateKeeper has time to react
          await this.router.replace(fixedRoute);
          // history.replaceState(undefined, undefined, `#${fixedRoute}`);
          // this.router.replace(fixedRoute);
        } else if (options.replace) {
          if (options.redirect) {
            redirect.call(this, fixedRoute);
          } else {
            replace.call(this, fixedRoute);
          }
        } else if (options.redirect) {
          redirect.call(this, fixedRoute);
        } else {
          hash.call(this, fixedRoute);
        }
      }
      updateFirstTimeNavFlag.call(this);
      setPageOnLPtag(referrer);
    };

    var updateFirstTimeNavFlag = function () {

      if (isFirstNavigate === true) {
        isFirstNavigate = false;
      }
    };

    /*
     get the hash fragment using the Backbone History object
     */
    var getFragment = function () {
      var fragment = (this.router && this.router.currentRoute && this.router.currentRoute.value && this.router.currentRoute.value.path) || '';

      // Account for vue-router > 4.x.x providing '/' for currentRoute if no navigation has occurred yet
      if (fragment === '/' ) {
        const { hash } = location;
        const otkRegex = /^#\/,/;
        const pathRegex = /^#\//;

        fragment = otkRegex.test(hash) ?
          hash.replace(otkRegex, ',') :
          hash.replace(pathRegex, '');
      }

      if (fragment.charAt(0) === '/') {
        fragment = fragment.substr(1);
      }

      if (!fragment) {
        fragment = window.location.search; // for logging in after login error
      }

      return unescapeUrl(fragment);
    };

    /*
     remove the hash fragment without triggering a browser refresh
     */
    var removeFragment = function (options) {
      var scrollV;
      var scrollH;
      var loc = window ? window.location : document.location;
      if (loc) {
        if ("pushState" in history) {
          const hash = `#${this.router.currentRoute.value.fullPath}`;
          if (options.replace) {
            var search = loc.search;
            if (options.removeQueryParams) {
              if (!_.isEmpty(search)) {
                for (var i = 0, arrayLength = options.removeQueryParams.length; i < arrayLength; i++) {
                  search = removeURLParameter(search, options.removeQueryParams[i]);
                }
              }
              history.replaceState(history.state, document.title, `${loc.pathname}${search}${hash}`);
            }
          } else {
            history.pushState(history.state, document.title, `${loc.pathname}${loc.search}${hash}`);
          }
        } else {
          // Prevent scrolling by storing the page's current scroll offset
          scrollV = document.body.scrollTop;
          scrollH = document.body.scrollLeft;

          if (options.replace) {
            hash.call(this, "");
          } else {
            replace.call(this, "");
          }

          // Restore the scroll offset, should be flicker free
          document.body.scrollTop = scrollV;
          document.body.scrollLeft = scrollH;
        }
      }

      // Allow Chaining
      return Navigator;
    };

    function removeURLParameter(url, parameter) {
      //prefer to use l.search if you have a location/link object
      var urlparts = url.split("?");
      if (urlparts.length === 2) {

        var prefix = encodeURIComponent(parameter) + "=";
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
          }
        }
        if (pars.length === 0) {
          return urlparts[0];
        }
        return urlparts[0] + "?" + pars.join("&");
      } else {
        return url;
      }
    }

    var removeQueryString = function () {
      if (window && window.history && window.history.replaceState) {
        var loc = window ? window.location : document.location;
        if (loc) {
          var search = loc.search;

          if (0 < search.length) {
            if ("?" !== search.substring(0, 1)) {
              search = "?" + search;
            }

            var href = loc.href;
            var clean = href.replace(search, "");
            window.history.replaceState({}, document.title, clean);
          }
        }
      }
    };

    var parseQueryString = function (remove, location) {
      var params = {};

      var loc = location || (window ? window.location : document.location);
      if (loc) {
        var args = (loc.search.substr(1)).split("&");
        _.each(args, function (arg, index) {
          var param = arg.split("=");
          if (1 < param.length && 0 < param[1].length) {
            params[param[0]] = unescapeUrl(param[1]);
          }
        });
      }

      if (true === remove) {
        removeQueryString.call(this);
      }

      return params;
    };

    var getVent = function () {
      return this.vent;
    };

    var getReqRes = function () {
      return this.reqres;
    };

    var getCommands = function () {
      return this.commands;
    };

    var getLogger = function () {
      return this.logger;
    };

    var getEffectiveModuleRouter = function (moduleName) {
      return modulesRouters[moduleName];
    };
    var getUserAgent = function () {
      return _.isUndefined(navigator) ? "" : navigator.userAgent;
    };

    var open = function (url, name, features, closeOnUnload) {
      var win = window.open.apply(window, [].slice.apply(arguments));

      childWindows.push(win);

      return win;
    };

    var close = function (win) {
      if (win && 0 < childWindows.length) {
        for (var i = (childWindows.length - 1); i >= 0; i--) {
          if (childWindows[i] && win === childWindows[i]) {
            if (!childWindows[i].closed) {
              childWindows.close();
            }
            childWindows.splice(i, 1);
          }
        }
      }
    };

    var closeAll = function () {
      if (0 < childWindows.length) {
        for (var i = (childWindows.length - 1); i >= 0; i--) {
          if (childWindows[i] && !childWindows[i].closed) {
            childWindows[i].close();
          }
        }

        childWindows.length = 0;
      }
    };

    var setPageOnLPtag = function (referrer) {
      if (window.lpMTag && window.lpMTag.lpSetPage) {
        var params = {
          title: document.title,
          referrer: referrer,
          pageUrl: document.location.href
        };
        window.lpMTag.lpSetPage(params);
      }
      if (window.lpTag && window.lpTag.newPage) {
        lpTag.newPage(document.location.href);
      }
    };

    function normalizeRoutePath(path){
      return path.replace('!', '/');
    }

    const setUpRoutesGateKeeper = function () {
      this.router.beforeEach((to, from, next) => {
        // This prevents an infinite navigation loop after logout
        if (to.fullPath.match('^/login')) {
          return next();
        }

        this.logger.debug(`routing from: ${from.path} , to: ${to.path}`);
        // const prefix = RouteUtils.extractPrefix(to);
        // const moduleName = Object.entries(modulePrefixes).find(([key, value]) => {
        //   return value === prefix;
        // })[0];
        var newRoute;
        if (to.path.indexOf('!') !== -1) {
          newRoute = this.normalizeRoutePath(to.path);
        }
        // Routes Backwards Compatibility:
        // Rewrite Old Routes to match the New Format
        // e.g. camp!something/something-else => camp/something/something-else

        this.logger.bam({
          eventName: `User Enter ${to.path}`,
          serviceName: 'CCUI-CORE',
        });
        this.logger.bam({
          eventName: `User Leave ${from.path}`,
          serviceName: 'CCUI-CORE',
        });

        if (!RouteUtils.validateRoute(to)) {
          // if routing to module that not registered navigate to defaule
          this.router.replace(LEConfig.defaultRoute);
        } else {
          const validationInfo = validateRoutePermissions(to.path, to, !sessionManager.hasProfilesFeature());
          if (validationInfo.auth.valid === true) {
            // if first route to LE vue router not active and need to trigger first route
              return next(newRoute);
          } else {
            // TODO: Handle default route
            if (validationInfo.auth && validationInfo.auth.redirect) {
              // setActiveRouter.call(Navigator, modulesRouters[moduleName]);
              // options = $.extend(options || {}, {controller: controller});
              newRoute = this.normalizeRoutePath(validationInfo.auth.redirect);
              return next(newRoute);
            }
            next(false);
            // Trigger unauthorized route event
            Navigator.vent.trigger("route:unauthorized", {
              route: validationInfo.route,
              isFirstNavigate: isFirstNavigate
            });
          }
        }
        updateFirstTimeNavFlag.call(this);
      });
    };

    var getActiveRouteName = function() {
      return this.route && this.route.name;
    };

    var setActiveRouteController = function(controller) {
      this.router.controller = controller;
    };

    function preProcessAuthData(auth) {
        var info = {
            valid: true
        };
        if (auth.privileges) {
            info.redirect = auth.redirect;

            // Iterate through the needed privileges for the route and look for it in the authenticated privileges
            if (auth.privileges && auth.privileges.length) {
                for (var i = 0; i < auth.privileges.length; i++) {
                    if (-1 !== currentPrivileges.indexOf(auth.privileges[i])) {
                        info.valid = true;
                        return info;
                    }
                }

                info.valid = false;
            }
        }
        return info;
    }

    function validateRoutePermissions(fragment, route, isPrivilegesIgnored) {
      let info = {};
      if (!isPrivilegesIgnored) {
          info = {
            privileges: route &&
            route.meta &&
            route.meta.auth &&
            route.meta.auth.privileges ? route.meta.auth.privileges : undefined,
            route: route,
          };
      }
      // default valid value is true.
      info.auth = preProcessAuthData(info);
      return info;
    }

    // function validateRouteInMap(fragment, router) {
    //
    //   var info = {
    //     route: router.match(fragment),
    //     valid: true
    //   };
    //
    //   if (info.route && info.route.path) {
    //     var auth = routesAuthentication[info.route.path]; // the needed privileges for the route (only OR - one of the values)
    //
    //     if (auth) {
    //       info.valid = auth.valid;
    //       info.redirect = auth.redirect;
    //     }
    //   }
    //
    //   return info;
    // }

    return {
      getDefaultRoute,
      setUpRoutesGateKeeper,
      normalizeRoutePath,
      initialize: initialize,
      navigate: navigate,
      vent: getVent,
      reqres: getReqRes,
      commands: getCommands,
      getFragment: getFragment,
      getActiveRouteName: getActiveRouteName,
      // setPageDataByRoutes: setPageDataByRoutes,
      removeFragment: removeFragment,
      // parseLoginFragment: parseLoginFragment,
      parseQueryString: parseQueryString,
      removeQueryString: removeQueryString,
      open: open,
      close: close,
      closeAll: closeAll,
      reload: reload,
      // getActiveRouter: getActiveRouter,
      getActiveModuleName: getActiveModuleName,
      // setActiveRouter: setActiveRouter,
      getEffectiveModuleRouter: getEffectiveModuleRouter,
      getUserAgent: getUserAgent,
      removeURLParameter:removeURLParameter,
      setCurrentPrivileges: setCurrentPrivileges,
      registerContextInformation: registerContextInformation,
      setActiveRouteController: setActiveRouteController,
      getRouter: function (moduleName, routePrefix, controller, isPrivilegesIgnored) {
        //route prefix and controller are mandatory
        //controller must be a RouteController object include a routes object
        //                if (!(moduleName) || !_.isString(routePrefix) || !(controller instanceof Marionette.Controller) || !(controller instanceof BaseRouterController) || typeof (controller.navigation) != "object" || typeof (controller.navigation.routes) != "object") {
        if (!(moduleName) || !_.isString(routePrefix) || !(controller instanceof Marionette.Controller) || typeof (controller.navigation) != "object" || typeof (controller.navigation.routes) != "object") {
          this.logger.error("error getting router in Navigator.getRouter.");
          this.logger.error("expected moduleName string, got: " + moduleName);
          this.logger.error("expected routePrefix string, got: " + routePrefix);
          this.logger.error("expected controller with navigation.routes object, got: " + controller);
          this.logger.graph({name:"framework:navigator:error:moduleNameAndRoute"});
          return null;
        }

        store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${environmentActionTypes.VALID_APPS_UPDATE}`, routePrefix);

        modulePrefixes[moduleName] = routePrefix;
        modulesCurrentHash[moduleName] = "";

        //resolve the default route and add it to the default routes object for later use
        resolveDefaultRoute.call(this, moduleName, controller);

        // Set default pages for night vision
        // setPageDataByRoutes.call(this);

        // add the routes and handlers to the router
        //extendRoutes.call(this, moduleName, controller, isPrivilegesIgnored);

        //if the module doesn't already have a router, create one and store it in the hash
        if (!modulesRouters[moduleName]) {
          modulesRouters[moduleName] = {
            controller: controller,
            routerModule: moduleName,
            navigate: function (route, options) {
              navigate.call(Navigator, moduleName, route, options);
              updateFirstTimeNavFlag.call(this);
            },
            navigateBack: function () {
              // storeRouteInHistoryStack = false;
              doApplicationBack.call(Navigator);
            },
            navigateNonBackable: function (route, options) {
              // storeRouteInHistoryStack = false;
              options = options || {};
              options.nonBackable = true;
              this.navigate(route, options);
            },
            redirect: function (url) {
              // setActiveRouter.call(Navigator, modulesRouters[moduleName]);
              redirect.call(Navigator, url);
            },
            replace: function (url, options) {
              // setActiveRouter.call(Navigator, modulesRouters[moduleName]);
              replace.call(Navigator, url, options);
            },
            hash: function (url) {
              // setActiveRouter.call(Navigator, modulesRouters[moduleName]);
              hash.call(Navigator, url);
            },
            open: open,
            close: close,
            closeAll: closeAll,
            reload: reload
          };
        }

        // Save the default router (assuming that this is the application main router
        if (!defaultRouter) {
          defaultRouter = modulesRouters[moduleName];
        }

        return modulesRouters[moduleName];
      },
      version: function () {
        getLogger().info("navigator.js version " + ver);
      },


    };
  }());

  return Navigator;
});
