define(function (require) {
    "use strict";

	var LE = require("app");
    var DataController =  require("data-controllers/DataController");

    LE.module('LEDataModule', function (LEDataModule, LE, Backbone, Marionette, $, _) {

        // Expose the public (cross modules) handlers on the module channel
        (function() {
            if(!LEDataModule.channel){
               return;
            }
            // get the AgentManager data from the data manager
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getAgentManagerModel, function() {
                return LEDataModule.dataController.getAgentManagerModel();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getAgentManagerModelV3, function () {
                return LEDataModule.dataController.getAgentManagerModelV3();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getSkillsCollection, function() {
                return LEDataModule.dataController.getSkillsCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getProfilesCollection, function() {
                return LEDataModule.dataController.getProfilesCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getLobsCollection, function() {
                return LEDataModule.dataController.getLobsCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getLobRelationsCollection, function() {
                return LEDataModule.dataController.getLobRelationsCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getLoggedInUserModel, function() {
                return LEDataModule.dataController.getLoggedInUserModel();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getAgentsInSameGroup, function() {
                return LEDataModule.dataController.getAgentsInSameGroup();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getAgentReasonsCollection, function() {
                return LEDataModule.dataController.getAgentReasonsCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getAllowedLobs, function() {
                return LEDataModule.dataController.getAllowedLobs();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.isAllLobsAllowedForUser, function() {
                return LEDataModule.dataController.isAllLobsAllowedForUser();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.isLobAllowedForUser, function(lobId) {
                return LEDataModule.dataController.isLobAllowedForUser(lobId);
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.isSkillAllowedForSelection, function(skillLobs, entityLobs) {
                return LEDataModule.dataController.isSkillAllowedForSelection(skillLobs, entityLobs);
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getAppInstallsCollection, function() {
                return LEDataModule.dataController.getAppInstallsCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getSoftwaresCollection, function() {
                return LEDataModule.dataController.getSoftwaresCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getPostSurveyCollection, function() {
                return LEDataModule.dataController.getPostSurveyCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getCountriesCollection, function() {
                return LEDataModule.dataController.getCountriesCollection();
            });
            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getAgentSurveyCollection, function() {
                return LEDataModule.dataController.getAgentSurveyCollection();
            });

            LEDataModule.channel.reply(LE.mediaCodes.dataModule.reqres.getIvrCollection, function() {
              return LEDataModule.dataController.getIvrCollection();
            });


        })();

        LEDataModule.addInitializer(function () {
            LEDataModule.dataController = new DataController();
        });

    });


    return LE.module("LEDataModule");
});


