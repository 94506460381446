/**
 * User: omarg
 */
define(function (require) {
    "use strict";

    var BaseModel = require("models/BaseModel");
    var ApiResources = require("assets/data/apiResources.json");
    var LEResourceResolver = require("leResourceResolver");

    var AccountCreationMigrationModel = BaseModel.extend({
        name: "AccountCreationMigrationModel",
        initialize: function () {

        },
        /**
         * for both step 1 and 2
         * @param attr
         * @param options
         */
        save: function(attr, options){
            var params = {
                step: attr && attr.step,
                flow: attr && attr.flow || AccountCreationMigrationModel.CONST.FLOW_SEAT_BASED
            };
            this.resource = LEResourceResolver.extendResourceWithOptions.call(this, ApiResources.CreateAccount.Migration, params);

            BaseModel.prototype.save.call(this, attr, options);
        }
    });

    AccountCreationMigrationModel.CONST = {
        ON_LOGIN: '1',
        ON_ACTIVATION: '2',
        FLOW_CPI: 'cpi',
        FLOW_SEAT_BASED_DEPRECATED: 'UsersBuyMigrating',
        FLOW_SEAT_BASED: 'SeatsBuyMigrating'
    };

    return AccountCreationMigrationModel;
});
