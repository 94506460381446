define(function (require) {
    "use strict";

    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");
    var LEResourceResolver = require("leResourceResolver");
    var ErrorCodes = require("codeTables/errorCodes.json");
  var _ = require('underscore');
    var CompoundFeatureIds = require("assets/constants/compound-features-ids.json");

    var MODEL_VALIDATORS = Config.validators.user;
    var UNKNOWN_USER_TYPE_KEY = "unknown";
    var USER_TYPES = {
        HUMAN: 1,
        BOT: 2
    };
    var USER_TYPES_KEYS = {};

    USER_TYPES_KEYS[USER_TYPES.HUMAN] = "human";
    USER_TYPES_KEYS[USER_TYPES.BOT] = "bot";

    var MAX_CHATS_CONST = {
        NO_CHATS: "0",
        UNLIMITED: "Unlimited"
    };

    var KEYS = {
        ID: "id",
        PID: "pid",
        DELETED: "deleted",
        USER_TYPE: "userTypeId",
        IS_API_USER: "isApiUser",
        ALLOWED_APP_KEYS: "allowedAppKeys",
        EMAIL_ADDRESS: "email",
        OBJECT_NAME: "objectName",
        LOGIN_NAME: "loginName",
        NICK_NAME: "nickname",
        DISPLAY_NAME: "fullName",
        EMPLOYEE_ID: "employeeId",
        ENABLED: "isEnabled",
        RESET_MFA: "resetMfaSecret",
        MAX_NUMBER_OF_CHATS: "maxChats",
        MAX_NUMBER_OF_CONVERSATIONS: "maxAsyncChats",
        SKILLS: "skillIds",
        PASSWORD: "passwordSh",
        OLD_PASSWORD: "oldPassword",
        CHANGE_PASSWORD_NEXT_LOGIN: "changePwdNextLogin",
        MEMBER_OF: "memberOf",
        MANAGER_OF: "managerOf",
        PICTURE_URL: "pictureUrl",
        INVALID_PICTURE_URL: "invalidUrl",
        UNSUPPORTED_PICTURE_FORMAT: "unsupportedPictureFormat",
        PROFILES: "profileIds",
        LOBS: "lobIds"
    };

    var URL_PARAMETERS = "&select=$all";
    var ERROR_CODES = {
        PASSWORD_RESTRICTIONS: "passwordRestrictions"
    };

    var counter = 0;

    var UserModelAC_v3 = RevisionedModel.extend({
        resource: LEResourceResolver.apiResources.AccountConfig.UsersWithProfiles,
        MODEL_VALIDATORS: MODEL_VALIDATORS,
        MAX_CHATS_CONST: MAX_CHATS_CONST,
        USER_TYPES: USER_TYPES,
        USER_TYPES_KEYS: USER_TYPES_KEYS,
        KEYS: KEYS,
        ERROR_CODES: ERROR_CODES,
        defaults: {
            id: null,
            pid: null,
            deleted: false,
            loginName: "",
            userTypeId: USER_TYPES.HUMAN,
            isApiUser: false,
            allowedAppKeys: "",
            email: "",
            fullName: "",
            employeeId: "",
            nickname: "",
            isEnabled: true,
            maxChats: 4,
            maxAsyncChats: null,
            isActive: true,
            skillIds: [],
            memberOf: null,
            managerOf: null,
            pictureUrl: null,
            profileIds: null,
            changePwdNextLogin: false,
            lobIds: []
        },
        name: "UserModelAC_v3",
        useMemento: true,
        isApiUserChanged: false,

        saveEnableValidation: {
            loginName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.loginName.required.new"
            },
            email: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.email.required"
            },
            nickname: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.nickName.required"
            },
            fullName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.displayName.required"
            },
            passwordSh: {
                required: function() {
                    return !this.getIsApiUser() && (this.isNew() || this.isApiUserChanged);
                },
                minLength: 1,
                msg: "LEFramework.entities.user.errors.password.length"
            },
            allowedAppKeys: {
                required: function() {
                    return this.getIsApiUser();
                },
                minLength: 1,
                msg: "LEFramework.entities.user.errors.appKey.required"
            },
            memberOf: {
                fn: function(val) {
                    if (_.isEmpty(val) && this.getAgentStatus()) {
                        return "LEFramework.entities.user.errors.assignToGroup.required";
                    }
                }
            },
            managerOf: {
                required: function() {
                    return this.getAgentManagerStatus() && this.hasMandatoryManageGroupFeature();
                },
                minLength: 1,
                msg: "LEUserManagement.userEdit.error.manageGroups.noItems",
            },
            profileIds: {
                required: true,
                msg: "LEFramework.entities.user.errors.profile.required"
            }
        },

        validation: {
            email: {
                required: true,
                pattern: "email",
                msg: "LEFramework.entities.user.errors.email.invalid"
            },
            loginName: {
                required: true,
                pattern: "alphaNumericSpecialCharacters",
                minLength: 1,
                msg: "LEFramework.entities.user.errors.loginName.invalid.new"
            },
            nickname: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.nickName.required"
            },
            fullName: {
                required: true,
                minLength: 1,
                msg: "LEFramework.entities.user.errors.displayName.required"
            },
            passwordSh: [
                {
                    required: function () {
                        return !this.getIsApiUser() && (this.isNew() || this.isApiUserChanged);
                    },
                    msg: "LEFramework.entities.user.errors.password.required"
                },
                {
                    pattern: "alphaNumericSpecialCharacters",
                    msg: "LEFramework.entities.user.errors.password.invalid"
                },
                {
                    minLength: Config.validators.user.minPasswordLength,
                    msg: "LEFramework.entities.user.errors.password.length"
                }
            ],
            allowedAppKeys: {
                required: function() {
                    return this.getIsApiUser();
                },
                minLength: 1,
                msg: "LEFramework.entities.user.errors.appKey.required"
            },
            oldPassword: {
                fn: function(val) {
                    if (this._isMyUser && _.isEmpty(val) && !_.isEmpty(this.getPassword())) {
                        return "LEFramework.entities.user.errors.oldPassword.required";
                    }
                }
            },
            memberOf: {
                fn: function(val) {
                    if (_.isEmpty(val) && this.getAgentStatus()) {
                        return "LEFramework.entities.user.errors.assignToGroup.required";
                    }
                }
            },
            managerOf: {
                required: function() {
                    return this.getAgentManagerStatus() && this.hasMandatoryManageGroupFeature();
                },
                minLength: 1,
                msg: "LEUserManagement.userEdit.error.manageGroups.noItems",
            },
            profileIds: {
                required: true,
                msg: "LEFramework.entities.user.errors.profile.required"
            }
        },
        url: function(){ //we need to override backbone to not include '/' and the temp id we give to the model before deleting
            var url = RevisionedModel.prototype.url.apply(this, arguments);
            url = url + URL_PARAMETERS;
            return url;
        },

        initialize: function(options) {
          options = options || {};
          this.source = options.source;
            //this._isMyUser = (_.has(options, "isMyUser")) ? options.isMyUser : false;
        },

        _setValuesInModel: function(attributes) {
            if (attributes.id) {
                this.set("id", attributes.id);
            }

            _.each(KEYS, _.bind(function(key) {
                if (_.has(attributes, key)) {
                    this.set(key, attributes[key]);
                }
            }, this));
        },
        getPid: function() {
            return this.get(KEYS.PID);
        },
        getUserType: function(){
            return this.get(KEYS.USER_TYPE);
        },
        getUserTypeKey: function(userType){
            var type = !_.isUndefined(userType) ? userType : this.get(KEYS.USER_TYPE);
            var key = this.USER_TYPES_KEYS[type];
            return key ? key : UNKNOWN_USER_TYPE_KEY;
        },
        setUserType: function(userType, options){
            this.set(KEYS.USER_TYPE, userType, options);
        },
        getIsApiUser: function(){
            return this.get(KEYS.IS_API_USER);
        },
        setIsApiUser: function(isApiUser, options){
            if(isApiUser !== this.getIsApiUser()){
                this.isApiUserChanged = !this.isApiUserChanged;
            }
            this.set(KEYS.IS_API_USER, isApiUser, options);
        },
        getAllowedAppKeys: function() {
            return this.get(KEYS.ALLOWED_APP_KEYS);
        },
        setAllowedAppKeys: function(appKey, options) {
            if (_.isNull(appKey)) {
                appKey = "";
            }
            this.set(KEYS.ALLOWED_APP_KEYS, appKey, options);
        },
        getLoginName: function() {
            return this.get(KEYS.LOGIN_NAME);
        },
        setLoginName: function(loginName, options) {
            this.set(KEYS.LOGIN_NAME, loginName, options);
        },
        getEmail: function() {
            return this.get(KEYS.EMAIL_ADDRESS);
        },
        setEmail: function(email, options) {
            this.set(KEYS.EMAIL_ADDRESS, email, options);
        },
        getNickName: function() {
            return this.get(KEYS.NICK_NAME);
        },
        setNickName: function(nickName, options) {
            this.set(KEYS.NICK_NAME, nickName, options);
        },
        getDisplayName: function() {
            return this.get(KEYS.DISPLAY_NAME);
        },
        setDisplayName: function(displayName, options) {
            this.set(KEYS.DISPLAY_NAME, displayName, options);
        },
        getEmployeeId: function() {
            return this.get(KEYS.EMPLOYEE_ID);
        },
        setEmployeeId: function(employeeId, options) {
            this.set(KEYS.EMPLOYEE_ID, employeeId, options);
        },
        setMemberOfGroup: function(groupId) { //the API expects a null object if the user isn't a member of any group
            var val= groupId ? {agentGroupId: groupId} : null;
            this.set(KEYS.MEMBER_OF, val);
        },
        getMemberOf: function() {
            return this.get(KEYS.MEMBER_OF);
        },
        getMemberOfGroup: function() {
            if (this.getMemberOf() && this.getMemberOf().agentGroupId){
                return this.getMemberOf().agentGroupId;
            }
        },
        getManagerOf: function() {
            var managerOf = this.get(KEYS.MANAGER_OF);

            return managerOf || [];
        },
        setManagerOf: function(managerOf, options) {
          if (!managerOf || !managerOf.length) {
            managerOf = this.hasMandatoryManageGroupFeature() ? null : [];
          }

          this.set(KEYS.MANAGER_OF, managerOf, options);
        },
        getEnabled: function() {
            return this.get(KEYS.ENABLED);
        },
        setEnabled: function(enabled, options) {
            this.set(KEYS.ENABLED, enabled, options);
        },
        getResetMFA: function() {
            return this.get(KEYS.RESET_MFA);
        },
        setResetMFA: function(resetMFA, options) {
            this.set(KEYS.RESET_MFA, resetMFA, options);
        },
        setPictureUrl: function(url) {
            this.set(KEYS.PICTURE_URL, url);
        },
        getPictureUrl: function() {
           return this.get(KEYS.PICTURE_URL);
        },
        setPictureUrlValidated: function(value) {
            this.pictureUrlValidated = value;
        },
        getPictureUrlValidated: function() {
            return this.pictureUrlValidated;
        },
        setPictureUrlErrors: function(value){
            this.pictureUrlErrors = value;
        },
        getPictureUrlErrors: function(){
            return this.pictureUrlErrors;
        },
        getMaxNumberOfChats: function() {
            var maxChats = this.get(KEYS.MAX_NUMBER_OF_CHATS);
            if (maxChats === -1) {
                maxChats = MAX_CHATS_CONST.UNLIMITED;
            }
            return maxChats.toString();
        },
        setMaxNumberOfChats: function(maxNumberOfChats, options) {
            var maxChats = parseInt(maxNumberOfChats,10);
            if (isNaN(maxChats)) {
                maxChats = -1;
            }
            this.set(KEYS.MAX_NUMBER_OF_CHATS, maxChats, options);
        },
        getMaxConversations: function() {
            return this.get(KEYS.MAX_NUMBER_OF_CONVERSATIONS);
        },
        setMaxConversations: function(maxNumberOfConversations, options) {
            var maxConversations = parseInt(maxNumberOfConversations,10);
            if (isNaN(maxConversations)) {
                maxConversations = null;
            }
            this.set(KEYS.MAX_NUMBER_OF_CONVERSATIONS, maxConversations, options);
        },
        getMemberAssignmentDate: function() {
            if (this.get(KEYS.MEMBER_OF)) {
                return this.get(KEYS.MEMBER_OF).assignmentDate;
            } else {
                return null;
            }

        },
        getSkills: function() {
            return this.get(KEYS.SKILLS);
        },
        setSkills: function(skills, options) {
            this.set(KEYS.SKILLS, skills, options);
        },
        addSkill: function(skill) {
            var skills = this.getSkills();
            if (!_.include(skills, skill)) {
                skills.push(skill);
                this.setSkills(skills);
            }
        },
        getProfiles: function() {
            var profiles = this.get(KEYS.PROFILES);

            return profiles || [];
        },
        setProfiles: function(profiles, options) {
            if (!profiles || !profiles.length) {
                profiles = null;
            }

            this.set(KEYS.PROFILES, profiles, options);
        },
        addProfile: function(profile) {
            var profiles = this.getProfiles();
            if (!_.include(profiles, profile)) {
                profiles.push(profile);
                this.setProfiles(profiles);
            }
        },
        getPassword: function() {
            return this.get(KEYS.PASSWORD);
        },
        setPassword: function(password, options) {
            if (password && !_.isEmpty(password)) {
                this.set(KEYS.PASSWORD, password, options);
            } else {
                this.unset(KEYS.PASSWORD);
            }
        },
        getOldPassword: function() {
            return this.get(KEYS.OLD_PASSWORD);
        },
        setOldPassword: function(oldPassword, options) {
            this.set(KEYS.OLD_PASSWORD, oldPassword, options);
        },
        getChangePasswordNextLogin: function() {
            return this.get(KEYS.CHANGE_PASSWORD_NEXT_LOGIN);
        },
        setChangePasswordNextLogin: function(changePwdNextLogin) {
            this.set(KEYS.CHANGE_PASSWORD_NEXT_LOGIN, changePwdNextLogin);
        },
        getLobs: function() {
            return this.get(KEYS.LOBS);
        },
        setLobs: function(lobIds) {
            return this.set(KEYS.LOBS, lobIds);
        },
        isMismatchLobsAndSkills: function(skillsCollection) {
            var isMismatch = false;

            var userSkillIds = this.getSkills();
            var userLobIds = this.getLobs();
            if (!_.isEmpty(userLobIds)) {
                _.each(userSkillIds, function(userSkillId) {
                    var skillModel = skillsCollection.get(userSkillId);
                    if (skillModel) {
                        var skillLobs = skillModel.getLobs();
                        if (!_.isEmpty(skillLobs)) {
                            isMismatch = _.intersection(skillLobs, userLobIds).length === 0;
                            return !isMismatch; // break the iteration if a mismatch was found
                        }
                    }
                });
            }

            return isMismatch;
        },

        getGroups: function(agentGroupsCollection) {
            this._buildGroupsObj(agentGroupsCollection);
            return this.groupsObj.groups;
        },
        _buildGroupsObj: function(agentGroupsCollection) {
            this.groupsObj = this.groupsObj || {userRevision: -1, agentGroupsRevision: -1, groups:[]};
            if (this.getRevision() > this.groupsObj.userRevision || agentGroupsCollection.getRevision() > this.groupsObj.agentGroupsRevision) {
                this.groupsObj.userRevision = this.getRevision();
                this.groupsObj.groups = [];
                var managerGroups = this.getManagerOf() || [];
                var memberOf = this.getMemberOf() || {};
                for (var ii=0; ii<managerGroups.length; ii++){
                    if (managerGroups[ii].agentGroupId && agentGroupsCollection.get(managerGroups[ii].agentGroupId)) {
                        this.groupsObj.groups.push({"name": agentGroupsCollection.get(managerGroups[ii].agentGroupId).getName()});
                    }
                    if (managerGroups[ii].agentGroupId === memberOf.agentGroupId) {
                        memberOf = {};
                    }
                }
                if(memberOf.agentGroupId && agentGroupsCollection.get(memberOf.agentGroupId)) {
                    this.groupsObj.groups.push({"name": agentGroupsCollection.get(memberOf.agentGroupId).getName()});
                }

                this.groupsObj.groups.sort(function(a, b){
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1:-1;
                });
            }
        },

        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },

        save: function(attributes, options) {
            options = options ? _.clone(options) : {};

            var origError = options.error;
            options.error = _.bind(function(collection, resp, opt) {
                var errorObject = {};
                var errorMessage = LE.translator.translate("LEFramework.something.went.wrong");

                if (resp && resp.responseError && resp.responseError.internalCode && resp.responseError.fields) {

                    var field = resp.responseError.fields[0] ;

                    if (resp.responseError.internalCode === ErrorCodes.uniquenessViolation) {
                        if (field === KEYS.LOGIN_NAME || field === KEYS.OBJECT_NAME) {
                            errorObject[KEYS.LOGIN_NAME] = LE.translator.translate("LEFramework.entities.user.errors.loginName.duplicated.new");
                        } else if (field === KEYS.EMAIL_ADDRESS) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.email.duplicated");
                        } else if (field === KEYS.DISPLAY_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.displayName.duplicated");
                        } else {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.uniqueness.violation");
                        }
                    }

                    if (resp.responseError.internalCode === ErrorCodes.fieldInvalidLengthOrPattern) {
                        if (field === KEYS.LOGIN_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.loginName.invalid.new");
                        } else if (field === KEYS.EMAIL_ADDRESS) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.email.invalid");
                        } else if (field === KEYS.PASSWORD) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.length");
                        } else {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.invalidLengthOrPattern");
                        }
                    }

                    if (resp.responseError.internalCode === ErrorCodes.fieldIsMandatory) {
                        if (field === KEYS.LOGIN_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.loginName.required.new");
                        } else if (field === KEYS.EMAIL_ADDRESS) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.email.required");
                        } else if (field === KEYS.NICK_NAME) {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.nickName.required");
                        }  else if (field === KEYS.DISPLAY_NAME) {
                                errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.displayName.required");
                        } if(field === KEYS.ALLOWED_APP_KEYS){
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.appKey.required");
                        } else {
                            errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.mandatoryField");
                        }
                    }

                    if (resp.responseError.internalCode === ErrorCodes.invalidRangeValue) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.general.invalidRangeValue");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMismatch) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.oldPassword.wrongCredentials");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMissingAlphaCharacters) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.missingAlphaCharacters");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.maximumNumberOfSequentialCharactersInPassword) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.maximumNumberOfSequentialCharacters");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.tooShortPassword) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.tooShort");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMissingDigits) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.missingDigits");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.maximumNumberOfCharacterOccurrencesInPassword) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.maximumNumberOfCharacterOccurrences");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordMissingSpecialCharacters) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.missingSpecialCharacters");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordIncludesUsername) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.includesUsername");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.restrictedPasswordPhrase) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.restrictedPasswordPhrase");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordResetTooManyTimesToday) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.passwordResetTooManyTimesToday");
                    }

                    if (resp.responseError.internalCode === ErrorCodes.passwordRepeatsPreviouslyUsedPasswords) {
                        errorObject[field] = LE.translator.translate("LEFramework.entities.user.errors.password.repeatsPreviouslyUsedPasswords");
                    }

//                    if (resp.responseError.internalCode === ErrorCodes.wrongCredentials) {
//                        errorObject[KEYS.OLD_PASSWORD] = LE.translator.translate("LEFramework.entities.user.errors.oldPassword.wrongCredentials");
//                    }
//                    if (resp.responseError.internalCode === ErrorCodes.appServer.messages.loginNameExists.replace("{0}", model.getLoginName())) {//??
//                        errorObject[KEYS.LOGIN_NAME] = LE.translator.translate("LEFramework.entities.user.errors.loginName.duplicated.new");
//                    }
//                    if (resp.responseError.internalCode === ErrorCodes.appServer.messages.displayNameExists.replace("{0}", model.getDisplayName())) { //????
//                        errorObject[KEYS.DISPLAY_NAME] = LE.translator.translate("LEFramework.entities.user.errors.displayName.duplicated");
//                    }
                }
                if(!_.isEmpty(errorObject)) {
                    options.errorObject = errorObject;
                }
                options.errorMessage = errorMessage;

                if (origError) {
                    origError(collection, resp, options);
                }
            }, this);

            RevisionedModel.prototype.save.call(this, attributes, options);
        },

        hasMandatoryManageGroupFeature: function() {
            return LE.sessionManager.getFeaturePropertyState(CompoundFeatureIds.COMMON_AGENT_MANAGER_MANDATORY_GROUPING);
        },

        setAgentStatus: function(status) {
            this.isAgent = status;
        },

        getAgentStatus: function() {
            return this.isAgent;
        },

        setAgentManagerStatus: function(status) {
            this.isAgentManager = status;
        },

        getAgentManagerStatus: function() {
            return this.isAgentManager;
        },

        getErrorCodes: function() {
            return this.ERROR_CODES;
        },

        hasRoleType: function(profilesCollection, roleType) {
            var userProfiles = this.getProfiles();
            var hasRoleType = _.some(userProfiles, function(profileId) {
                var profileModel = profilesCollection.get(profileId);
                if (profileModel) {
                    return profileModel.getRoleTypeId() === parseInt(roleType, 10);
                }
            });

            return hasRoleType;
        },

        parse: function(response, options) {
            if (_.isArray(response) && response.length) {
                response = response[0];
            }

            return RevisionedModel.prototype.parse.call(this, response, options);
        },

        /**
         * Does the current logged in user have sufficient
         * @param currentPrivileges - Object with specific settings. if the user has no editAgent and no editAgentManager it means he can view all users. {editAgent: Boolean, editAgentManager:Boolean}
         * @param groupsUnderLoggedInUser - Ids of groups under the logged in user
         * @param profilesCollection
         */
        isViewableForLoggedInUser: function(currentPrivileges, groupsUnderLoggedInUser, profilesCollection) {
            var isUserViewable = false;
            var userRoles = _.map(this.getProfiles(), _.bind(function(userProfileId) {
                var profileModel = profilesCollection.get(userProfileId);
                if (profileModel) {
                    return profileModel.getRoleTypeId().toString();
                } else {
                    return "";
                }

            }, this));


            // if the logged in user has no specific privilege - he should see all account users
            if (currentPrivileges.viewAll) {
                isUserViewable = true;
            }
            else {
                // if the logged in user has privilege to edit agents only and the user in the table is agent (or doesn't have a role at all...)
                if (currentPrivileges.editAgent && (_.isEmpty(userRoles) || _.contains(userRoles, profilesCollection.model.ROLES.AGENT))) {
                    // make sure the user in the table is located in an agent group that under the logged in agent manager
                    if (this.getMemberOf() && _.contains(groupsUnderLoggedInUser, this.getMemberOf().agentGroupId)) {
                        isUserViewable = true;
                    }
                }

                // if the logged in user has privilege to edit agent managers only and the user in the table is agent manager
                if (currentPrivileges.editAgentManager && _.contains(userRoles, profilesCollection.model.ROLES.AGENT_MANAGER)) {
                    // make sure the user in the table is a manager of the same group of the logged in user or a group in the tree under the logged in user
                    var managerOf = _.map(this.getManagerOf(), "agentGroupId");
                    if (_.intersection(groupsUnderLoggedInUser, managerOf).length > 0) {
                        isUserViewable = true;
                    }
                }
            }

            return isUserViewable;
        }
    });

    UserModelAC_v3.KEYS = KEYS;
    UserModelAC_v3.USER_TYPES = USER_TYPES;
    UserModelAC_v3.USER_TYPES_KEYS = USER_TYPES_KEYS;
    UserModelAC_v3.UNKNOWN_USER_TYPE_KEY = UNKNOWN_USER_TYPE_KEY;

    return UserModelAC_v3;
});

