/**
 * Created with IntelliJ IDEA.
 * User: ivanm
 * Date: 5/24/15
 * Time: 5:38 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var CONST = require("const");
    var SETTINGS_ENUM = require("codeTables/settings.json");

    var acountSettings = (function () {

        var isAcUser = function() {
            return CONST.SOURCE_ENUM.LE === LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.USERS_SOURCE);
        };
        var hasHideIsTrial = function () {
            var hideIsTrial = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.HIDE_IS_TRIAL);
            return (hideIsTrial === "true");
        };
        var isAutomotive = function () {
            var accountInfo = LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.ACCOUNT_INFO);
            return accountInfo && accountInfo.accountVertical === "automotive";
        };
        var hideChatOptions = function () {
            return isAutomotive();
        };
        var isUsersLimitedDueToScale = function() {
          var value = LE.sessionManager.getAccountSettingValueByID(SETTINGS_ENUM.SETTING_ID.USERS_LIMITED_FUNCTIONALITY);
          return value === "true";
        };

        return {
            isAcUser: isAcUser,
            hasHideIsTrial: hasHideIsTrial,
            isAutomotive: isAutomotive,
            hideChatOptions: hideChatOptions,
            isUsersLimitedDueToScale: isUsersLimitedDueToScale
        };

    })();

    return acountSettings;
});
