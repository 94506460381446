define(function (require) {
    "use strict";

    var { sessionManager } = require("vue-infra");
    var _ = require("underscore");
    var RevisionedModel = require("models/RevisionedModel");
    var Config = require("assets/config/entities/config.json");

    var PermissionsCollection = require("collections/entities/PermissionsCollection");
    var ApiResources = require("assets/data/apiResources.json");
    var NameUtility = require("utils/NameUtility");

    var NO_DATA = "noData";

    var URL_PARAMETERS = "&select=$all";

    var KEYS = {
        ID: "id",
        NAME: "name",
        DESCRIPTION: "description",
        ROLE_TYPE_ID: "roleTypeId",
        NUM_OF_ASSIGNED_USERS: "numOfAssignedUsers",
        PERMISSIONS: "permissionPackages",
        IS_ASSIGNED_TO_LPA: "isAssignedToLPA"
    };

    var ROLES_BY_IDS = require("assets/data/roles.json");
    var ROLES_BY_NAME = _.invert(ROLES_BY_IDS);
    var ROLES = {
        ADMINISTRATOR: ROLES_BY_NAME["LEUserManagement.profiles.roles.admin"],
        AGENT: ROLES_BY_NAME["LEUserManagement.profiles.roles.agent"],
        AGENT_MANAGER: ROLES_BY_NAME["LEUserManagement.profiles.roles.agentManager"],
        CAMPAIGN_MANAGER: ROLES_BY_NAME["LEUserManagement.profiles.roles.campaignManager"]
    };
    var DEFAULT_ROLE = ROLES.ADMINISTRATOR;

    var ProfileModel = RevisionedModel.extend({
        resource: ApiResources.AccountConfig.Profiles,
        KEYS: KEYS,
        NO_DATA: NO_DATA,
        ROLES: ROLES,
        ROLES_BY_IDS: ROLES_BY_IDS,
        ROLES_BY_NAME: ROLES_BY_NAME,
        defaults: {
            id: null,
            name: "",
            description: "",
            roleTypeId: DEFAULT_ROLE,
            numOfAssignedUsers: void 0,
            isAssignedToLPA: false,
            permissions: []
        },
        name: "ProfileModel",

        saveEnableValidation: {
            name: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.profile.errors.profileEdit.saveButton.tooltip.mandatoryFields"
                }
            ]
        },

        validation: {
            name: [
                {
                    required: true,
                    minLength: 1,
                    msg: "LEFramework.entities.profile.errors.profileEdit.saveButton.tooltip.mandatoryFields"
                },
                {
                    maxLength: Config.validators.profile.maxNameLength,
                    msg: "LEFramework.entities.profile.errors.profileEdit.name.maxLength"
                }
            ],
            description: {
                required: false,
                maxLength: Config.validators.profile.maxDescriptionLength,
                msg: "LEFramework.entities.profile.errors.description.maxLength"
            }
        },
        url: function(){ //we need to override backbone to not include '/' and the temp id we give to the model before deleting
            var url = RevisionedModel.prototype.url.apply(this, arguments);
            url = url + URL_PARAMETERS;
            return url;
        },

        initialize: function (attributes, options) {
            options = options || {};
            this.source = options.source;
            this._buildModel();

            RevisionedModel.prototype.initialize.call(this, options);
        },
        _buildModel: function () {
            this.unset();
        },
        setId: function (id) {
            this.set(KEYS.ID, id);
        },
        getName: function () {
            return this.get(KEYS.NAME);
        },
        setName: function (name) {
            this.valueIsChanged = true;
            this.set(KEYS.NAME, name);
        },
        getDescription: function () {
            return this.get(KEYS.DESCRIPTION);
        },
        setDescription: function (description) {
            this.valueIsChanged = true;
            this.set(KEYS.DESCRIPTION, description);
        },
        getRoleTypeId: function () {
            return this.get(KEYS.ROLE_TYPE_ID);
        },
        setRoleTypeId: function (roleTypeId) {
            this.valueIsChanged = true;
            this.set(KEYS.ROLE_TYPE_ID, roleTypeId);
        },
        getNumOfAssingnedUsers: function () {
            return this.get(KEYS.NUM_OF_ASSIGNED_USERS);
        },
        setNumOfAssingnedUsers: function (numOfAssignedUsers) {
            this.set(KEYS.NUM_OF_ASSIGNED_USERS, numOfAssignedUsers);
        },
        getIsAssignedToLPA: function () {
            return this.get(KEYS.IS_ASSIGNED_TO_LPA);
        },
        getPermissions: function () {
            return this.get(KEYS.PERMISSIONS);
        },
        getPermissionsCollection: function () {
            return this.permissionCollection;
        },
        setPermissionsCollection: function (collection) {
            if (!this.permissionCollection) {
                this.permissionCollection = new PermissionsCollection(collection);
            } else {
                this.permissionCollection.set(collection);
            }
            this.setPermissions(collection);
        },
        setPermissions: function (permissions) {
            this.valueIsChanged = true;
            this.set(KEYS.PERMISSIONS, permissions);
        },
        validateSaveEnabled: function () {
            this.originalValidation = this.validation;
            this.validation = this.saveEnableValidation;

            var enable = this.isValid();

            this.validation = this.originalValidation;

            return enable;
        },
        isValueChanged: function () {
            return this.valueIsChanged;
        },
        parse: function (response, options) {
            var packages = [];
            if (response && response.permissionPackages) {
                response.permissionPackages = _filterPackages(response.permissionPackages);
                packages = response.permissionPackages;
            }
            this.permissionCollection = new PermissionsCollection(packages);
            return RevisionedModel.prototype.parse.call(this, response, options);
        },
        duplicate: function (sourceModel, options, collection) {
            var duplicateData = {
                "setAttributes": {}
            };

            var duplicateModelName = {
                "name": NameUtility.duplicateName(sourceModel.collection, sourceModel.getName())
            };
            var entityPath = sourceModel.collection.resource.entityPathForDuplicate;
            if (entityPath) {
                duplicateData.setAttributes[entityPath] = duplicateModelName;

                this.set(KEYS.PERMISSIONS, this.permissionCollection);
                this.setNumOfAssingnedUsers(void 0);

                return RevisionedModel.prototype.duplicate.call(this, sourceModel, _.extend(options,{ data: duplicateData }), collection);
            }
            else {
                return false;
            }
        },
        /**
         * Does the current logged in user have sufficient
         * @param currentPrivileges - Object with specific settings. if the user has no editAgentProfile and no editAgentManagerProfile it means he can view all users. {viewAll: Boolean, editAgentProfile: Boolean, editAgentManagerProfile:Boolean}
         */
        isViewableForLoggedInUser: function(currentPrivileges) {
            var isProfileViewable = false;
            var profileRole = this.getRoleTypeId().toString();

            // if the logged in user has no specific privilege - he should see all account profiles
            if (currentPrivileges.viewAll) {
                isProfileViewable = true;
            }
            else {
                // if the logged in user has privilege to edit agent profile only and this is a profile of agent role
                if (currentPrivileges.editAgentProfile && profileRole === ROLES.AGENT) {
                    isProfileViewable = true;
                }

                // if the logged in user has privilege to edit agent manager profile only and this is a profile of agent manager role
                if (currentPrivileges.editAgentManagerProfile && profileRole === ROLES.AGENT_MANAGER) {
                    isProfileViewable = true;
                }
            }

            return isProfileViewable;
        }
    });

    function _filterPackages (permissionPackages) { //will filter packages by display and features
        var filtered = [];
        if (permissionPackages && permissionPackages.length) {
            filtered = _.filter(permissionPackages, function (currPackage) {
                return currPackage.isDisplayed && _validateFeatures(currPackage.featureKeys);
            });
        }
        return filtered;
    }

    function _validateFeatures (features) {
        var isValid = true;
        var feature;
        if (features && features.length) {
            for (var i = 0; i < features.length; i++) {
                feature = features[i];
                var featureData = sessionManager.getFeatureProperty(feature);
                if (!featureData || (featureData && featureData.value && !featureData.value.value)){
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    ProfileModel.KEYS = KEYS;
    ProfileModel.NO_DATA = NO_DATA;
    ProfileModel.ROLES = ROLES;
    ProfileModel.ROLES_BY_NAME = ROLES_BY_NAME;
    ProfileModel.ROLES_BY_IDS = ROLES_BY_IDS;
    ProfileModel.DEFAULT_ROLE = DEFAULT_ROLE;

    return ProfileModel;
});
