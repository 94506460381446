<template>
  <div
    v-tooltip="inputTooltip"
    class="top-level-menu-filtered-item away-reason-item"
    role="button"
    tabindex="0"
    :aria-label="awayStatus.text"
    @click="clicked"
    @keydown.enter.space.prevent="clicked"
  >
    {{ awayStatus.text }}
  </div>
</template>

<script>
export default {
  name: 'TopLevelMenuFilteredItem',
  props: {
    awayStatus: {
      type: Object,
      required: true,
    },
  },
  emits: ['clicked'],
  computed: {
    inputTooltip() {
      return {
        content: this.awayStatus.text,
        placement: 'right',
        html: false,
        autoHide: false,
      };
    },
  },
  methods: {
    clicked() {
      this.$emit('clicked', this.awayStatus);
    },
  },
};
</script>

<style lang='scss' scoped>
.top-level-menu-filtered-item.away-reason-item {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  line-height: 1.17;
  padding: 13px 16px;
  width: 254px;
  @include ellipsis;

  @include themify {
    background-color: getColor('user-menu-custom-away-status-bg', 'framework');
    border: 1px solid
      getColor('user-menu-custom-away-status-border', 'framework');

    box-shadow: 0px 1px 1px 0px
      getColor('user-menu-custom-away-status-box-shadow-color', 'framework');

    &:hover {
      background-color: getColor(
        'user-menu-custom-away-status-bg-hover',
        'framework'
      );
    }
  }

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
</style>