define(function(require) {
  'use strict';

  var _ = require('underscore');
  var $ = require('jquery');
  var { Logger } = require('vue-infra');
  var mappedDefaults = require('assets/config/le-default-conf');
  // todo - make sure le-conf is external
  var mappedPhaseConf = require('assets/config/le-conf');
  var zone;
  var dc;
  var grid;
  var resolvedDefaults;
  var resolvedPhaseConf;
  var config;

  var logger = Logger.getLogger('LEFramework');

  if (_.isString(mappedPhaseConf)) {
    try {
      logger.info('Trying to parse the loaded environments properties');
      mappedPhaseConf = JSON.parse(mappedPhaseConf);
    } catch (e) {
      logger.error(
        'Error loading the environments properties - Configuration from puppet may have invalid format!!!'
      );
    }
  }

  function resolveObj(mappedObj) {
    var resolved = {};
    _.each(mappedObj, function(val, key) {
      if (key.substr(0, 2) === '__') {
        var resolvedValue;
        if (val.hasOwnProperty(dc)) {
          resolvedValue = val[dc];
        } else if (val.hasOwnProperty(zone)) {
          resolvedValue = val[zone];
        } else if (val.hasOwnProperty(grid)) {
          resolvedValue = val[grid];
        } else {
          resolvedValue = val['default'];
        }
        if (_.isString(resolvedValue)) {
          resolvedValue = resolvedValue
            .replace('{{{dc}}}', dc)
            .replace('{{{zone}}}', zone);
        }
        resolved[key.substr(2)] = resolvedValue;
      }
    });

    return $.extend(true, {}, mappedObj, resolved);
  }

  zone = mappedPhaseConf.lp_zone || mappedDefaults.lp_zone || 'z2';
  dc = mappedPhaseConf.lp_dc || mappedDefaults.lp_dc || 'uk';
  grid = mappedPhaseConf.lp_grid || mappedDefaults.lp_grid || 'ga';
  if (zone.indexOf('-a') > -1) {
    dc = dc + '-a';
  }
  resolvedDefaults = resolveObj(mappedDefaults);
  resolvedPhaseConf = mappedPhaseConf ? resolveObj(mappedPhaseConf) : {};
  config = $.extend(true, {}, resolvedDefaults, resolvedPhaseConf);

  return config;
});
