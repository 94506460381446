/**
 * Created with IntelliJ IDEA.
 * User: tomers
 * Date: 6/5/14
 * Time: 11:13 AM
 * Some documentation:  ( Tomer to write more later)
 * Page component is designed top be General page in the system - this way tommorow when PM/UIX/UX decide on different design we change it here
 * or just add another permutation on it.
 *
 * The page component started by wanting to have one css/template for all pages that look like dialog overlay but are not
 * All these pages has got the same design title, description, actions strip, x close button,
 * middle view with all its forms and then a footer area.
 *
 * To use:
 *
 * Create the page in your controller and pass into it your inner view.
 *this.contentGalleryLayout = new ContentGalleryLayout({
                    vent: this.vent,
                    model: this.model
  });

 this.mainView = new Page({
                    isPermanent: true, An option override for marrionete regions if the page is inside a region using show with another view will not close it
                    view:        this.contentGalleryLayout,
                    title:       this.model.getHeaderTitle(),
                    description: this.model.getHeaderDescription(),
                    hasActionStrip: true | false,
                    moduleName: LECampaigns.moduleName // REQUIRED for Dialog permutation
                    context: LE.context // REQUIRED for Dialog permutation
  });

 CSS and templates:
 The dialog page template contains header button and inside view container.
 The dialog page CSS calculates the size remaining for the inner view if it with/without description
 The dialog page CSS calculates the size remaining for the inner view if it with/without action strip
 and also position the footer element.

 So what should the inside view template use:
 .page-action-strip{

 }
 .view-container{

 }
 .page-footer {
       .buttons-container {
       }
 }

 Functionalities
 *
 * The inner view can trigger  showLoader,closeLoader,close events and the page will respond
 * The inner view  should listen to "page:closeButtonClick" event and decide what to do with it.
 *
 * API
 * setTitle
 * setDescription
 * setActionStrip
 * setInsideView
 * showLoader
 * closeLoader
 *
 * Inner view can trigger
 */
define(function (require) {
    "use strict";

    var BasicPage = require("ui/page/Page");
    var PageHeaderView = require("ui.components/page/js/views/PageHeaderView");
    var Marionette = require("marionette");
  var _ = require('underscore');

    var Page = BasicPage.extend({
        initialize: function(options) {
            options.header = (options.header instanceof Marionette.View) ? options.header : new PageHeaderView(this._getHeaderViewOptions(options));
            BasicPage.prototype.initialize.call(this, options);
        },
        _getHeaderViewOptions: function(options) {
            var title = _.has(options.header, "title") ? options.header.title : "";
            var description = _.has(options.header, "description") ? options.header.description : "";
            var knowledgePack = _.has(options.header, "knowledgePack") ? options.header.knowledgePack : {}; // should be an object {text: "text to show", eventId: "slug:blabla"}
            var beta = _.has(options.header, "beta") ? options.header.beta : false; // should be TRUE/FALSE
            var feedback = _.has(options.header, "feedback") ? options.header.feedback : null; // should be link to open or null
            var dependency = _.has(options.header, "dependency") ? options.header.dependency : {}; // should be an object {model:model, itemClick: function() {}, success: function() { return {dependencyArr:Array ,title:String ,footer:String }; }}
            var badge = _.has(options.header, "badge") ? options.header.badge : "";

            return {
                moduleName: options.moduleName,
                context: options.context,
                title: title,
                description: description,
                knowledgePack: knowledgePack,
                beta: beta,
                feedback: feedback,
                dependency: dependency,
                badge: badge
            };
        },
        setKnowledgePack: function(knowledgePack) {
            if (this.headerView && _.isFunction(this.headerView.setKnowledgePack)) {
                this.headerView.setKnowledgePack(knowledgePack);
            }
        },
        setDependency: function(dependency) {
            if (this.headerView && _.isFunction(this.headerView.setDependency)) {
                this.headerView.setDependency(dependency);
            }
        },
        setBadge: function(badge) {
            if (this.headerView && _.isFunction(this.headerView.setBadge)) {
                this.headerView.setBadge(badge);
            }
        }
    });

    return Page;
});
