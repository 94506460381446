/**
 * Created with IntelliJ IDEA.
 * User: shlomif
 * Date: 1/14/14
 * Time: 3:08 PM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var Backbone = require("backbone");
    var Marionette = require("marionette");
    var template = require("ui.components/dependency-indicator/templates/dependencyTypeList.tmpl");
    var DependencyItemView = require("ui.components/dependency-indicator/js/views/DependencyItemView");


    var DependencyTypeListView = Marionette.CompositeView.extend({
        template: template,
        className: "dependency-type-container",
        itemView: DependencyItemView,
        itemViewContainer: ".dependency-list",
        itemViewOptions: function(model, index) {
            return {
                itemIndex: index,
                maxItemsToDisplay: this.maxItemsToDisplay
            };
        },

        ui: {
            list: ".dependency-list"
        },

        customTemplateHelpers: function() {
            return {
                // Show 'and more' text in case there are more than %maxItemsToDisplay% items
                showMoreText: this.originalCollectionLength > this.maxItemsToDisplay
            };
        },

        initialize: function(options) {
            this.originalCollectionLength = this.model.get("dependencies").models.length;
            this.maxItemsToDisplay = options.maxItemsToDisplay;

            var slicedModels = this.model.get("dependencies").models.slice(0, this.maxItemsToDisplay);
            this.collection = new Backbone.Collection(slicedModels);
            this.listenTo(this, "itemview:clicked", this._onItemViewClicked);
        },

        onRender: function() {
            if(this.collection.length === 0) {
                this.ui.list.addClass("empty-list");
            }
        },

        _onItemViewClicked: function(itemView, model) {
            this.trigger("clicked", model);
        }
    });

    return DependencyTypeListView;
});
