/**
 * Created with IntelliJ IDEA.
 * User: ahmadao
 * Date: 3/21/13
 * Time: 10:12 PM
 * To change this template use File | Settings | File Templates.
 */

define(function (require) {
    "use strict";
    var _ = require("underscore");

    var SECONDS_TO_TIME_UNIT_CONVERSION = {
        TIME_UNIT_MINUTES: 60,
        TIME_UNIT_HOURS: 3600,
        TIME_UNIT_DAYS: 3600*24
    };

    var TimeUtils = (function () {

        return {

            millisToMinutesAndSecondsObj:function (millis) {

                var timeInSeconds = Math.round(millis / 1000);

                var minutes = Math.floor(timeInSeconds / 60);
                var seconds = (timeInSeconds % 60);

                return {
                    minutes:minutes,
                    seconds:seconds
                };
            },

            /**
             * returns a time string of structure MM:SS. if minutes > 99 then structure will be M...M:SS for example: 1440:03
             * @param seconds
             * @returns {string}
             */
            secondsToMinutesAndSecondsString:function (seconds) {
                return this.millisToMinutesAndSecondsString(seconds*1000);
            },
            /**
             * returns a time string of structure HH:MM:SS. if hours > 99 then structure will be H...H/MM:SS for example: 1151:52:03
             * @param seconds
             * @returns {string}
             */
            secondsToHoursAndMinutesAndSecondsString: function (seconds) {
                return this.millisToHoursAndMinutesAndSecondsString(seconds*1000);
            },
            /**
             * returns a time string of structure HH hr MM min SS sec.
             * @param seconds
             * @returns {string}
             */
            secondsToHoursAndMinutesAndSecondsLongString: function (seconds) {
                return this.millisToHoursAndMinutesAndSecondsLongString (seconds*1000);
            },
            /**
             * returns a time string of structure HH hr MM min SS sec.
             * @param millis
             * @returns {string}
             */
            millisToHoursAndMinutesAndSecondsLongString: function (millis) {
                var timeObj = this.millisToHoursAndMinutesAndSecondsObj(millis);

                var hours = "";
                var minutes = "";
                var seconds = "";

                // Check if the time is set to 00:00:00 and display it in format "0 sec"
                if (timeObj.hours === 0 && timeObj.minutes === 0 && timeObj.seconds === 0) {
                    seconds = timeObj.seconds + " " + window.LE.translator.translate("LEReporting.second_short");
                }
                else {
                    // We'll show only the smaller units with leading zeros.
                    // Hours is the largest unit, if hours > 0 it will be shown as is.
                    if (timeObj.hours > 0) {
                        hours = timeObj.hours + " " + window.LE.translator.translate("LEReporting.hour_short") + " ";
                    }

                    // Check if minutes is a smaller unit, or it 0 but hours and seconds needs to be shown and therefore also the minutes unit with 0 value.
                    if (timeObj.hours > 0 && (timeObj.minutes > 0 || timeObj.seconds > 0)) {
                        minutes = String("00" + timeObj.minutes).slice(-2) + " " + window.LE.translator.translate("LEReporting.minute_short");
                    }
                    // Check if minutes is the bigger unit
                    else if (timeObj.hours === 0 && timeObj.minutes > 0) {
                        minutes = timeObj.minutes + " " + window.LE.translator.translate("LEReporting.minute_short");
                    }

                    // Check if seconds needs to be shown
                    if (timeObj.seconds > 0) {
                        // Check if seconds is a smaller unit
                        seconds = (timeObj.minutes > 0 || timeObj.hours > 0) ? " " + String("00" + timeObj.seconds).slice(-2) : timeObj.seconds;
                        seconds += " " + window.LE.translator.translate("LEReporting.second_short");
                    }
                }

                var timeString = hours + minutes + seconds;
                return timeString.trim();
            },
            /**
             * returns a time string of structure HH hr MM min.
             * @param millis
             * @returns {string}
             */
            millisToHoursAndMinutesLongString: function (millis) {
                var timeObj = this.millisToHoursAndMinutesAndSecondsObj(millis);
                var hours = (timeObj.hours > 0) ? timeObj.hours + " " + window.LE.translator.translate("LEReporting.hour_short") + " " : "";
                var minutes = "";
                if (timeObj.minutes > 0 || timeObj.hours === 0) {
                    minutes = (timeObj.hours > 0) ? String("00" + timeObj.minutes).slice(-2) : timeObj.minutes;
                    minutes += " " + window.LE.translator.translate("LEReporting.minute_short");
                }

                var timeString = hours + minutes;
                return timeString.trim();
            },
            /**
             * returns a time string of structure MM:SS. if minutes > 99 then structure will be M...M:SS for example: 1440:03
             * @param millis
             * @returns {string}
             */
            millisToMinutesAndSecondsString:function (millis) {

                var minutesAndSecondsObj = TimeUtils.millisToMinutesAndSecondsObj(millis);
                var minutes = minutesAndSecondsObj.minutes > 99 ? minutesAndSecondsObj.minutes : String("00" + minutesAndSecondsObj.minutes).slice(-2);
                var minutesAndSecondsStr = minutes + ":" + String("00" + minutesAndSecondsObj.seconds).slice(-2);

                return minutesAndSecondsStr;
            },

            millisToHoursAndMinutesAndSecondsObj:function (millis) {

                var timeInSeconds = Math.round(millis / 1000);

                var hours = Math.floor(timeInSeconds / (60 * 60));

                var divisor_for_minutes = timeInSeconds % (60 * 60);

                var minutes = Math.floor(divisor_for_minutes / 60);

                var seconds = (timeInSeconds % 60);

                return {
                    minutes:minutes,
                    hours:hours,
                    seconds:seconds
                };
            },

            /**
             * returns a time string of structure HH:MM. if minutes > 99 then structure will be H...H:MM for example: 1440:03
             * @param millis
             * @returns {string}
             */
            millisToHoursAndMinutesString:function (millis) {

                var hoursAndMinutesObj = TimeUtils.millisToHoursAndMinutesAndSecondsObj(millis);
                var hours = hoursAndMinutesObj.hours > 99 ? hoursAndMinutesObj.hours : String("00" + hoursAndMinutesObj.hours).slice(-2);
                var hoursAndMinutesStr = hours + ":" + String("00" + hoursAndMinutesObj.minutes).slice(-2);

                return hoursAndMinutesStr;
            },
            /**
             * returns a time string of structure HH. if minutes > 99 then structure will be H...H:MM for example: 1440:03
             * @param millis
             * @returns {string}
             */
            millisToHoursString:function (millis) {
                var hoursAndMinutesObj = TimeUtils.millisToHoursAndMinutesAndSecondsObj(millis);
                return hoursAndMinutesObj.hours;
            },
            /**
             * returns a time string of structure MM.
             * @param millis
             * @returns {string}
             */
            millisToMinutesString:function (millis) {
                var hoursAndMinutesObj = TimeUtils.millisToHoursAndMinutesAndSecondsObj(millis);
                var minutes = String("00" + hoursAndMinutesObj.minutes).slice(-2);

                return minutes;
            },
            /**
             * returns a time string of structure HH:MM:SS. if hours > 99 then structure will be H...H/MM:SS for example: 1151:52:03
             * @param seconds
             * @returns {string}
             */
            millisToHoursAndMinutesAndSecondsString: function (millis) {
                var hoursAndMinutesAndSecondsObj = TimeUtils.millisToHoursAndMinutesAndSecondsObj(millis);
                var hours = hoursAndMinutesAndSecondsObj.hours > 99 ? hoursAndMinutesAndSecondsObj.hours : String("00" + hoursAndMinutesAndSecondsObj.hours).slice(-2);
                var minutes = String("00" + hoursAndMinutesAndSecondsObj.minutes).slice(-2);
                var seconds = String("00" + hoursAndMinutesAndSecondsObj.seconds).slice(-2);
                var hoursAndMinutesAndSecondsStr = hours + ":" + minutes + ":" + seconds;

                return hoursAndMinutesAndSecondsStr;
            },
            /**
            * returns a UTC time from string format "YYYY-MM-DD"
            * @param string
            * @returns {millis}
            */
            dateStringToDateUtc: function (dateString) {

                var dateUtc = null;

                if (typeof(dateString) === 'string') {

                    var dateArray = dateString.split("-");

                    var year = parseInt(dateArray[0], 10);

                    //january equal to zero need to reduce by one
                    var month = parseInt(dateArray[1], 10) - 1;
                    var day = parseInt(dateArray[2], 10);

                    dateUtc = Date.UTC(year, month, day);
                }

                return dateUtc;
            },
            /*
            * Return time in string with seconds or minuets indicator
            * @param int
            * @returns {string}
            */
            timeToStringSecondsOrMinuets: function (timeInt) {

                //i18n translator.
                var translator = window.LE.translator;

                var stringReturn = "";

                if(timeInt <= 60) {
                    stringReturn = timeInt + " " + translator.translate("LEReporting.second_short");
                } else {
                    // according to PM when show minutes we don't need to show the seconds
                    stringReturn = Math.floor(timeInt / 60) + " " + translator.translate("LEReporting.minute_short");
                }

                return stringReturn;
            },


            /**
             * outputs a string of structure ddmmyyyy out of a date object.
             * returns an empty string if dataObj is not an object.
             * @param dateObj
             * @returns {string}
             */
            getDateStrFromDateObjForUrl: function (dateObj) {
                var str = "";
                var day;
                var month;

                if(_.isObject(dateObj)){
                    day = "0" + dateObj.getDate();  //output is one of: 01, 02, ... , 010, 011, 012, ... , 030, 031
                    str += day.substr(day.length-2);
                    month = "0" + (dateObj.getMonth() + 1);    //output is one of: 01, 02, ... , 010, 011, 012
                    str += month.substr(month.length-2);
                    str += dateObj.getFullYear();
                }

                return str;
            },

            convertDateObjToStringFormat: function(dateObj, timeFormat) {
                var str = timeFormat;

                // parse day
                var day = "0" + dateObj.getDate();
                day = day.substr(day.length - 2);
                str = str.replace("dd", day);

                // parse month
                var month = "0" + (dateObj.getMonth() + 1);
                month = month.substr(month.length - 2);
                str = str.replace("mm", month);

                // parse year
                var year = dateObj.getFullYear();
                str = str.replace("yyyy", year);

                return str;
            },

            convertSecondsToTimeUnit: function(seconds, secondsPerTimeUnit) {
                var timeInUnit = 0;
                if (seconds && seconds % secondsPerTimeUnit === 0) {
                    timeInUnit = seconds / secondsPerTimeUnit;
                }
                return timeInUnit;
            },

            convertSecondsToDaysHoursAndMinutes: function(seconds) {
                var slaTime = {
                    days: this.convertSecondsToTimeUnit(seconds, SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_DAYS),
                    hours: this.convertSecondsToTimeUnit(seconds, SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_HOURS),
                    minutes: this.convertSecondsToTimeUnit(seconds, SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_MINUTES)
                };
                return slaTime;
            },

            convertMinutesOrHoursOrDaysToSeconds: function(timeValue, timeUnit) {
                var SLA_TIME_UNITS = window.LE.siteSettings.SlaUnitsSettings.getSlaUnits();
                var unitMapping = {};
                unitMapping[SLA_TIME_UNITS.MINUTES] = SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_MINUTES;
                unitMapping[SLA_TIME_UNITS.HOURS] = SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_HOURS;
                unitMapping[SLA_TIME_UNITS.DAYS] = SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_DAYS;

                return this.convertTimeUnitToSeconds(timeValue, unitMapping[timeUnit]);
            },

            convertTimeUnitToSeconds: function(timeValue, secondsPerTimeUnit) {
                return secondsPerTimeUnit * timeValue;
            },

            getSlaTimeUnitFromSeconds: function(timeInSeconds) {
                var SLA_TIME_UNITS = window.LE.siteSettings.SlaUnitsSettings.getSlaUnits();

                var timeUnit = SLA_TIME_UNITS.MINUTES;
                if (this.convertSecondsToTimeUnit(timeInSeconds, SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_HOURS)) {
                    timeUnit = SLA_TIME_UNITS.HOURS;
                    if (this.convertSecondsToTimeUnit(timeInSeconds, SECONDS_TO_TIME_UNIT_CONVERSION.TIME_UNIT_DAYS)) {
                        timeUnit = SLA_TIME_UNITS.DAYS;
                    }
                }
                return timeUnit;
            }
        };
    })();

    return TimeUtils;
});
