define(function (require) {
    "use strict";

    var Settings = require("codeTables/settings.json");
  var _ = require('underscore');

    var ENABLED_VALUE = "true";
    var { sessionModel } = require('vue-infra');

    var cobrowseSettings = (function () {

        var currentProfile;
        var currentAgentCanChooseProfile;
        var currentCoBrowseDisabled;

        var getAgentCanChooseProfile = function () {
            if (_.isUndefined(currentAgentCanChooseProfile)) {
                setAgentCanChooseProfile(_getSetting(Settings.SETTING_ID.COBROWSE_CHOOSEAGENTPROFILE, true) === ENABLED_VALUE);
            }
            return currentAgentCanChooseProfile;
        };

        var getProfile = function () {
            if (_.isUndefined(currentProfile)) {
                setProfile(_getSetting(Settings.SETTING_ID.COBROWSE_AGENTPROFILE, true));
            }
            return currentProfile;
        };

        var isCoBrowseDisabled = function () {
            if (_.isUndefined(currentCoBrowseDisabled)) {
                setCoBrowseDisabled(_getSetting(Settings.SETTING_ID.COBROWSE_DISABLED, false) === ENABLED_VALUE);
            }
            return currentCoBrowseDisabled;
        };

        var isVoicecallEnabled = function () {
            return _getSetting(Settings.SETTING_ID.VOICECALL_ENABLED, false) === ENABLED_VALUE;
        };

        var isVideocallEnabled = function () {
            return _getSetting(Settings.SETTING_ID.VIDEOCALL_ENABLED, false) === ENABLED_VALUE;
        };

        var isControllerBotPreferredForCoBrowseSystemMessages = function () {
            return _getSetting(Settings.SETTING_ID.COBROWSE_CBOT_PREFERRED_FOR_SYSTEM_MESSAGES, false) === ENABLED_VALUE;
        };

        var setAgentCanChooseProfile = function (agentCanChoose) {
            currentAgentCanChooseProfile = agentCanChoose;
        };

        var setProfile = function (profile) {
            currentProfile = profile;
        };

        var setCoBrowseDisabled = function (disabled) {
            currentCoBrowseDisabled = disabled;
        };

        var setValuesFromModel = function (model) {
            _.each(model, function (setting) {
                switch (setting.id) {
                    case Settings.SETTING_ID.COBROWSE_AGENTPROFILE:
                        setProfile(atob(setting.propertyValue.value));
                        break;
                    case Settings.SETTING_ID.COBROWSE_CHOOSEAGENTPROFILE:
                        setAgentCanChooseProfile(atob(setting.propertyValue.value) === ENABLED_VALUE);
                        break;
                    case Settings.SETTING_ID.COBROWSE_DISABLED:
                        setCoBrowseDisabled(setting.propertyValue.value === ENABLED_VALUE);
                        break;
                }
            });
        };

        var _getSetting = function (id, isBase64) {
            var property =  LE.sessionManager.getAccountSettingByID(id);
            if (property && !property.deleted && property.propertyValue && property.propertyValue.value) {
                if (isBase64) {
                    return atob(property.propertyValue.value);
                } else {
                    return property.propertyValue.value;
                }
            }
        };

        return {
            getProfile: getProfile,
            getAgentCanChooseProfile: getAgentCanChooseProfile,
            isCoBrowseDisabled: isCoBrowseDisabled,
            setProfile: setProfile,
            setAgentCanChooseProfile: setAgentCanChooseProfile,
            setCoBrowseDisabled: setCoBrowseDisabled,
            setValuesFromModel: setValuesFromModel,
            isVoicecallEnabled: isVoicecallEnabled,
            isVideocallEnabled: isVideocallEnabled,
            isControllerBotPreferredForCoBrowseSystemMessages: isControllerBotPreferredForCoBrowseSystemMessages,
        };
    })();

    return cobrowseSettings;
});
