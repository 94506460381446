 import { isArray } from "lodash";
 import { sessionManager } from 'vue-infra';

const USERS_WITH_FOCUS_OUTLINE_SETTING_ID = 'le.ui.a11y.usersWithFocusOutline';

export function userHasAccessibilityEnabled() {
  const loggedInUsersId = sessionManager.getUserId();
  const usersThatNeedsAccessibility = sessionManager.getAccountSettingValueByID(
    USERS_WITH_FOCUS_OUTLINE_SETTING_ID,
    ['*']
  );
  if (
    isArray(usersThatNeedsAccessibility) &&
    usersThatNeedsAccessibility.length > 0
  ) {
    return (
      usersThatNeedsAccessibility.indexOf('*') > -1 ||
      usersThatNeedsAccessibility.indexOf(loggedInUsersId) > -1
    );
  }
}

export function validateACFeatures(acFeatures) {
  let hasFeaturesEnabled = true;
  if (acFeatures && acFeatures.length > 0) {
    hasFeaturesEnabled = acFeatures.every(feature =>
      sessionManager.getFeaturePropertyState(feature)
    );
  }
  return hasFeaturesEnabled;
}

export function validatePrivileges(privileges) {
  let hasPrivileges = true;
  if (privileges) {
    const { AND, OR } = privileges;
    let hasAndPrivileges = true;
    let hasOrPrivileges = true;
    if (AND && AND.length > 0) {
      hasAndPrivileges = AND.every(privilege =>
        sessionManager.hasPrivilege(privilege)
      );
    }
    if (OR && OR.length > 0) {
      hasOrPrivileges = OR.some(privilege =>
        sessionManager.hasPrivilege(privilege)
      );
    }
    hasPrivileges = hasAndPrivileges && hasOrPrivileges;
  }
  return hasPrivileges;
}

export function validateRoles(allowedRoles) {
  let hasRoles = true;
  if (allowedRoles && allowedRoles.length > 0) {
    const normalizedAllowedRoles = allowedRoles.map(role =>
      role.trim().toLowerCase()
    );
    const userRoles = LE.sessionManager.getRolesLost();
    hasRoles = userRoles.some(userRole =>
      normalizedAllowedRoles.includes(userRole.trim().toLowerCase())
    );
  }
  return hasRoles;
}
