/**
 * Created with IntelliJ IDEA.
 * User: noam
 * Date: 3/3/13
 * Time: 10:21 AM
 */
/*
 You can pass an options parameter: getCreateNewModelOnEmptyId that makes get create a new model when getting an empty id from the collection
 todo implement attributes on collection like:
 http://stackoverflow.com/questions/5930656/setting-attributes-on-a-collection-backbone-js
 */
define(function (require) {
    "use strict";
    var _ = require("underscore");
    var Backbone = require("backbone");
    var LEResourceResolver = require("leResourceResolver");
    var CONST = require("const");
    var { Logger } = require('vue-infra');
    var es6MiddleUtils = require('src/es6MiddleUtils');
    var SourceQueryParamDecorator = es6MiddleUtils.removeEsModuleDefault(require('../decorators/SourceQueryParamDecorator'));


    var FetchState = {
        NONE:     "none",
        ERROR:    "error",
        SUCCESS:  "success",
        LOADING:  "loading"
    };

    var BaseCollection = Backbone.Collection.extend({
        getCreateNewModelOnEmptyId:false,
        FetchStateEnum: FetchState,
        fetchState : FetchState.NONE,
        logger: Logger.getLogger("LEFramework"),
        // Initialize is an empty function by default. I am override it with
        // initialization logic which allow assignment of URL to collections during initialization.
        initialize:function (models, options) {
            options = options || {};
            this._events = (this._events) ? this._events : {};
            this.getCreateNewModelOnEmptyId = options.getCreateNewModelOnEmptyId || this.getCreateNewModelOnEmptyId;
            this.change404To200OK = _.has(options,"change404To200OK")? options.change404To200OK: true;
        },

        /**
         * We support resource as an object with url (and version optionally) and resource as a string
         * @return {String} - the api resource url of the collection.
         */
        url:function () {
            var result = LEResourceResolver.getUrl.call(this, this.resource);

            var fieldset = this._fieldset || this.fieldset;
            if (_.isArray(fieldset) && 0 < fieldset.length) {
                var prefix = "&fields=";
                result += ((-1 !== result.indexOf("?")) ? prefix : "?" + prefix.substring(1)) + fieldset.join(prefix);
            }

            // If we define a source object on the collection it will add the source query parameter to the request URL,
            // and replace it if it already exists in the URL.
            // for example, { appName: 'camp', contextName: 'winStudio' } will become source=ccuiCampWinStudio
            // Note: if this method is overridden then this implementation should be added if you wish to use it.
            if (this.source) {
              result = SourceQueryParamDecorator.addSourceQueryParam.call(this, result, this.source, true);
            }

            return result;
        },

        // Override 'Backbone.sync' to extend parameters from urlResolver
        sync:function (method, model, options) {
            options = LEResourceResolver.extendOptionsWithResourceOptions.call(this, this.resource, options);
            // If we define a source object on the collection it will add the source query parameter to the request URL,
            // and replace it if it already exists in the URL.
            // for example, { appName: 'camp', contextName: 'winStudio' } will become source=ccuiCampWinStudio
            // Note: if this method is overridden then this implementation should be added if you wish to use it.
            if (options.url && this.source) {
                options.url = SourceQueryParamDecorator.addSourceQueryParam.call(this, options.url, this.source, true);
            }

            return Backbone.sync.apply(this, [].slice.apply(arguments));
        },

        get:function (id) {
            if (this.getCreateNewModelOnEmptyId && ("undefined" == typeof id || "" === id || null === id)) { //id is empty create new model
                return new this.model();
            } else {
                return Backbone.Collection.prototype.get.call(this, id);
            }
        },

        extendError:function (options) {
            if (_.isObject(options)) {
                var error = options.error;

                options.error = _.bind(function (collection, resp, options) {
                    if (this.change404To200OK && 404 === resp.status && options.success ) {
                        //All AC services implemented empty array with 200 instead of 404, we still need to support appserver APIs etc so this is passed as parameter

                        //at this stage options.success is defined by Backbone.Collection.fetch.
                        //that function expects just the response object as its argument.
                        //so pass it an empty list. that function will call the original success passed by the user
                        //with the correct arguments
                        options.ignoreError = true;
                        options.success.apply(this, []);
                    } else if (error) {
                        error.apply(this, [].slice.apply(arguments));
                    }
                },this);
            }
        },

        fetch: function(options) {
            options = options || {};

            // First Check if we need to validate the freshness of the data.
            // * If not fresh enough, it continues processing the fetch
            // * If data is fresh, it will call the success callback immediately
            // * If no need for freshness, it continues processing the fetch
            var freshnessTime = _.has(options, "freshnessTime") ? options.freshnessTime : this.freshnessTime;
            if (freshnessTime && freshnessTime > (new Date().getTime()) - this.getLastFetchSuccess()) {
                this.logger.debug("framework BaseCollection collection is fresh enough returning success" + this._getCollectionName(),"BaseCollection:fetch");
                options.success(this, {}, options);
                return;
            }



            // Add handler for 404 errors
            var successFunc = options.success;
            var errorFunc = options.error;
            options.success = function(collection, response, options) {
                collection.lastFetchSuccess = new Date().getTime();  //todo waiting when we have updated underscore with _.now()
                if (_.isFunction(collection.fetchSuccess)) {
                    collection.fetchSuccess(collection, response, options);
                }
                collection.setFetchState(FetchState.SUCCESS);
                collection.logger.debug("framework:BaseCollection:fetchSucces:" + collection._getCollectionName(),"BaseCollection:fetch");
                if (_.isFunction(successFunc)) {
                    successFunc(collection, response, options);
                }
            };
            options.error = function(collection, response, options) {
                if (!options.type || CONST.HTTP_METHOD.GET === options.type) {
                    collection.setFetchState(FetchState.ERROR);
                    collection.logger.graph({name:"framework:BaseCollection:fetchError"});
                    collection.logger.error("BaseCollection:fetchError:" + collection._getCollectionName(),"BaseCollection::fetch",{response: response });
                }
                if (_.isFunction(collection.fetchError)) {
                    collection.fetchError(collection, response, options);
                }
                if (_.isFunction(errorFunc)) {
                    errorFunc(collection, response, options);
                }
            };
            this.extendError(options);

            if (!options.type || CONST.HTTP_METHOD.GET === options.type || options.isLoading) {
                this.setFetchState(FetchState.LOADING);
            }

            // Ugly but I need it for further use and must sve it on the object
            this._fieldset = options.fieldset;

            // Call Backbone's fetch
            return this._backboneFetchWith304Implemented(options);
        },
        /**
         * Important we are overriding backbone collection fetch here to support 304 unmodified
         * This is the exact function used from BB version 1.1.2  ,
         * Any change in backbone should validate this function is still doing the same work needed
         * @param options
         * @returns {*}
         * @private
         */
        _backboneFetchWith304Implemented: function(options){
            options = options ? _.clone(options) : {};
            if (_.isUndefined(options.parse) ){
                options.parse = true;
            }

            var success = options.success;
            var collection = this;
            options.success = function(resp,statusText, xhr) {
                if (xhr && 304 === xhr.status){
                    //if we received 304 and the implementation requested reset - we just trigger the event and keep the collection as is.
                    //Most implementation using reset today are bugs ( should use sync event )
                    if (!options.silent && options.reset) {
                        collection.trigger('reset', this, options);
                    }
                }else {
                    var method = options.reset ? 'reset' : 'set';
                    collection[method](resp, options);
                }

                if (success) {
                    success(collection, resp, options);
                }
                collection.trigger('sync', collection, resp, options);
            };

            var error = options.error;
            options.error = function(resp) {
                if (error) {
                    error(collection, resp, options);
                }
                collection.trigger('error', collection, resp, options);
            };

            return this.sync('read', this, options);
        },

        setFetchState: function(state) {
            this.fetchState = state;
            this.trigger("fetch:" + state);
        },

        getFetchState: function(){
            return this.fetchState;
        },
        getLastFetchSuccess: function(){
            return this.lastFetchSuccess;
        },
        isLastFetchReturnedError: function() {
            return  this.getFetchState() === FetchState.ERROR;
        },
        isLastFetchReturnedSuccess: function() {
            return  this.getFetchState() === FetchState.SUCCESS;
        },
        hasAtLeastOneSuccessfulFetch: function() {
            return (!_.isUndefined(this.getLastFetchSuccess()));
        },

        nextElement: function(id){
            var currModel = this.get(id);
            if (currModel && currModel.id){
                var nextElementIndex = this.indexOf(currModel) + 1;
                if (nextElementIndex === this.length) {
                    //It's the last model in the collection so return null
                    return null;
                }else{
                    return this.at(nextElementIndex);
                }
            }
            return null;
        },

        previousElement: function(id){
            var currModel = this.get(id);
            if (currModel && currModel.id){
                var prevElementIndex = this.indexOf(currModel) -1;
                if (prevElementIndex < 0) {
                    //It's the last model in the collection so return null
                    return null;
                }else{
                    return this.at(prevElementIndex);
                }
            }
            return null;
        },

        // Update an element collection to reflect the model changes and re-render the view
        mergeCollectionChanges:function (changedCollectionArray) {
            /*
             this list holds the list of the changed elements (added, removed and updated)
             and also elements that haven't changed.
             changedCollectionArray is NOT a Collection of element Models, it is a plain array of objects
             */
            if (changedCollectionArray) {
                /*
                 in order to update the current collection, we need to loop the changed elements array and
                 for each element there, update the collection relatively (add, remove, update)
                 */
                var currentCollection = this;
                /*
                 go over the change array backwards because if we remove a model from the collection
                 it will shorten the collection length. So in order to keep the pointer to the changedCollectionArray
                 and the pointer to the currentCollection in sync, we loop from the end
                 */
                for (var i = changedCollectionArray.length - 1; i >= 0 ; i--){
                    var elementObj = changedCollectionArray[i];
                    if (i > currentCollection.length - 1){
                        /*
                         if the index of the element in the changed array is larger than the current length of the collection
                         this is a new element and we add it to the collection
                         */
                        currentCollection.push(elementObj);
                    } else {
                        var model = currentCollection.at(i);
                        if (_.isUndefined(elementObj)) {
                            //if the element was removed, than the changed array should hold undefined for that index
                            currentCollection.remove(model);
                        } else {
                            //otherwise, the element still exists, and we'll update the model at that index
                            model.set(elementObj);
                        }
                    }
                }
            }
        },
        onInitialFetched: function(callback) {
            if (this.fetchState === FetchState.SUCCESS) {  //todo not good if we are in refresh mode this status is not the correct one to look at - need to look at last fetch time
                callback();
            }
            else {
                this.listenToOnce(this, "fetch:success", callback);
            }
        },
        onInitialError: function(callback) {
            if (this.fetchState === FetchState.ERROR) {
                callback();
            }
            else {
                this.listenToOnce(this, "fetch:error", callback);
            }
        },

        /**
         * returns a new instance of this collection, filtered by the passed key and value.
         *
         * @param key the model attribute key to match against.
         * @param value the model attribute value to match against.
         * @param options a hash of optional settings for this function:
         *      * ignoreCase {Boolean}: compare values in the filter predicate without considering letter case.
         * @returns {BaseCollection} a new instance of this collection, filtered by the passed key and value.
         */
        filterBy: function (key, value, options) {
            options = options || {};
            var modelValue, filtered;
            filtered = this.filter(function (model) {
                modelValue = model.get(key);
                if (options.ignoreCase) {
                    modelValue = modelValue.toLowerCase();
                    value = value.toLowerCase();
                }
                return modelValue === value;
            });
            return new this.constructor(filtered);
        },
        _getCollectionName: function(){
            var collectionName = "";
            if (this.name) {
                collectionName = this.name;
            } else {
                var resource = _.result(this, "resource");
                if (_.isString(resource)) {
                    collectionName = resource;
                }
                else if (_.isObject(resource) && resource.url) {
                    collectionName = resource.url;
                }

                collectionName = collectionName.replace(/\//g,"-");
            }
            return  collectionName;
        }


    });

    BaseCollection.FetchState =  FetchState;

    return  BaseCollection;
});
