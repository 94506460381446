<template>
  <div
    :class="['le-toggle', { 'disabled': disabled }]"
    :aria-disabled="isDisabled"
    :aria-label="`sound ${isToggled === 'true' ? 'on': 'off'}`"
    :aria-checked="isToggled"
    role="switch"
  >
    <div
      :class="['toggle-frame', toggleFrameStateClass]"
      :aria-labelledby="ariaLabelledBy"
      :disabled="isDisabled"
      :aria-pressed="isToggled"
      role="button"
      :tabindex="tabIndex"
      data-test="toggle_btn"
      @keydown.enter.space.prevent="onToggleClick"
      @click="onToggleClick"
    >
      <div class="toggle-button"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ToggleSwitch',
  model: {
    prop: 'state',
    event: 'change',
  },
  props: {
    state: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaLabelledBy: {
      type: String,
      default: '',
    },
  },
  emits: ['on-switch', 'change'],
  computed: {
    toggleFrameStateClass() {
      return this.state ? 'toggle-active' : 'toggle-inactive';
    },
    isToggled() {
      return this.state ? 'true' : null;
    },
    isDisabled() {
      return this.disabled || null;
    },
    tabIndex() {
      return !this.disabled ? '0' : '-1';
    },
  },
  methods: {
    onToggleClick() {
      this.$emit('change', !this.state);
      this.$emit('on-switch');
    },
  },
};
</script>

<style lang="scss" scoped>

@import "../../../assets/scss/global";
@import "../../../assets/scss/mixins";

.le-toggle {
  .toggle-frame {
    position: relative;
    display: flex;
    cursor: pointer;
    width: 36px;
    height: 20px;
    border-radius: 12px;
    transition: border-color .3s, background-color .3s;
    @include themify {
      background-color: getColor('s-toggleBackground', 'framework');
    }

    .toggle-button {
      width: 16px;
      height: 16px;
      position: absolute;
      border-radius: 50%;
      margin-top: 2px;
      transition: border-color .1s ease, background-color .1s ease, right .1s linear;
    }

    &.toggle-active {
      //border: solid 1px;
      @include themify {
        //border-color: getColor('s-toggleBorderSelected');, 'framework'
      }

      .toggle-button {
        right: 2px;
        @include themify {
          background-color: getColor('s-toggleIndicatorSelected', 'framework');
        }
      }

      &:hover, &:focus {
        @include themify {
          //border-color: getColor('s-toggleBorderSelectedHover');, 'framework'
        }

        .toggle-button {
          @include themify {
            background-color: getColor('s-toggleIndicatorSelectedHover', 'framework');
          }
        }
      }
    }

    &.toggle-inactive {
      //border: solid 1px;
      @include themify {
        //border-color: getColor('s-toggleBorder');, 'framework'
        background-color: getColor('s-toggleBackgroundInactive', 'framework');
      }

      .toggle-button {
        right: calc(100% - 18px);
        @include themify {
          background-color: getColor('s-toggleIndicator', 'framework');
        }
      }

      &:hover, &:focus {
        @include themify {
          //border-color: getColor('s-toggleBorderHover');, 'framework'
        }

        .toggle-button {
          @include themify {
            background-color: getColor('s-toggleIndicatorHover', 'framework');
          }
        }
      }
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;

    .toggle-frame {
      //border: solid 1px;
      @include themify {
        background-color: getColor('s-toggleBackgroundSelectedDisabled', 'framework');
        //border-color: getColor('s-toggleBorderDisabled');, 'framework'
      }

      .toggle-button {
        @include themify {
          background-color: getColor('s-toggleIndicatorDisabled', 'framework');
        }
      }

      &.toggle-active {
        @include themify {
          //border-color: getColor('s-toggleBorderSelectedDisabled');, 'framework'
        }

        .toggle-button {
          @include themify {
            background-color: getColor('s-toggleIndicatorSelectedDisabled', 'framework');
          }
        }
      }

      &.toggle-inactive {
        @include themify {
          background-color: getColor('s-toggleBackgroundDisabled', 'framework');
        }
      }
    }
  }
}
</style>
