/**
 * Created with IntelliJ IDEA.
 * User: noam
 * Date: 2/13/13
 * Time: 10:57 AM
 * To change this template use File | Settings | File Templates.
 */
define(function (require) {
    "use strict";

    var BaseCollection = require("collections/BaseCollection");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var SkillModel = require("models/entities/SkillModel");
    var ApiResources = require("assets/data/apiResources.json");
    var CONST = require("const");
    var LEResourceResolver = require("leResourceResolver");
     var _ = require('underscore');

    var FETCH_METHOD = "GET";
    var NAME = "SkillsCollection";

    var SkillsCollection = RevisionedCollection.extend({
        model : SkillModel,
        defaultSkill : null,
        name: NAME,

        initialize: function(models,options) {
            options = options ||{};
            this.source = options.source;

            if(CONST.SOURCE_ENUM.LEGACY === LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.USERS_SOURCE)) {
                //this.resource = ApiResources.AccountConfig.Skills;
                this.resource = ApiResources.AppServer.Skills;
                options.change404To200OK = true;
            } else {
                this.resource = ApiResources.AccountConfig.Skills;
            }
            RevisionedCollection.prototype.initialize.call(this,models,options);
        },

        url: function() {
          const ACWithSource = { ...ApiResources.AccountConfig.Skills, source: this.source };

          if(CONST.SOURCE_ENUM.LEGACY === LE.sessionManager.getAccountSettingValueByID(LE.sessionManager.ACCOUNT_SETTINGS_ID.USERS_SOURCE)) {
              return LEResourceResolver.getUrl.call(this, ApiResources.AppServer.Skills);
            } else {
                return LEResourceResolver.getUrl.call(this, ACWithSource) + "&select=$all";
            }
        },

        parse : function (response, options) {
            //we are doing parse here to put id of skill as an integer on the model attributes
            //The skills api returns it as string but
            //this is more correct as the engagement model and the UI uses it as a number.

            response = _.map(response, function (skillAttributes) {
                skillAttributes.id = parseInt(skillAttributes.id, 10);
                return skillAttributes; //basically I should create a new object but performance wize ...
            });
            return RevisionedCollection.prototype.parse.call(this, response, options);
        },

        fetch : function (options) {
            var successFunc = options.success;
            options.success = function (collection, response, options) {
                collection.setDefault();
                if (_.isFunction(successFunc)) {
                    successFunc(collection, response, options);
                }
            };
            // Call BaseCollection's fetch
            return BaseCollection.prototype.fetch.call(this, options);
        },

        getDefault : function () {
            return this.defaultSkill;
        },
        setDefault : function () {
            var defaultSkill = this.find(function (skill) {
                return skill.get("isDefault");
            });
            this.defaultSkill = defaultSkill || null;
        },

        getFetchMethod: function(){ //used by fetcher
            return FETCH_METHOD;
        },

        getFetchHeaders: function(){  //used by fetcher
            return {"accept": "application/json"};
        },

        getSkillName: function (skillId) {
          var skill = this.get(skillId);
          return skill ? skill.getName() : '';
        }
    });

    SkillsCollection.NAME = NAME;

    return SkillsCollection;
});
