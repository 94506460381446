import LEResourceResolver from 'leResourceResolver';
import Navigator from 'routers/navigator';
import RouteNameBuilder from './RouteNameBuilder';
import { Logger, createBaseRouter, gChannel, store, storeModuleTypes, sessionStateKeys, sessionGetterTypes, sessionActionTypes, environmentStateKeys } from 'vue-infra';

// const name = 'LEApp';

const EmptyVueComponent = {
  template: '',
};

const LiveEngageBaseRouteHandler = {
  logger: Logger.getLogger('LEFramework'),
  navigation: {
    routes: [
      {
        path: '/*notFound',
        component: EmptyVueComponent,
        meta: {
          LEDefaultRoute: true,
          handlerName: 'notFound',
        },
      },
      {
        path: '/404',
        component: EmptyVueComponent,
        meta: {
          LEDefaultRoute: true,
          handlerName: 'notFound',
        },
      },
      {
        path: '/login/:errorCode?',
        component: EmptyVueComponent,
        meta: {
          LEDefaultRoute: true,
          handlerName: 'login',
        },
      },
      {
        path: '/denied/:errorCode?',
        component: EmptyVueComponent,
        meta: {
          LEDefaultRoute: true,
          handlerName: 'denied',
        },
      },
    ],
  },

  initialize(options) {
    /* eslint-disable no-param-reassign */
    options = options || {};
    this.loginInfo = options.loginInfo;
    this.AppServeresources = options.AppServeresources;
    this.LogoutUrl = options.LogoutUrl;
    this.calculateUrlFn = options.calculateUrlFn;

    const routerOptions = { routes: this.navigation.routes };
    const routerHooks = {
      afterEach: (to) => {
        if (to.meta.LEDefaultRoute && to.meta.handlerName) {
          const { error } = Navigator.parseQueryString(false);
          LiveEngageBaseRouteHandler[to.meta.handlerName](error || to.params.errorCode);
        }
      },
    };

    this.Router = createBaseRouter(routerOptions, routerHooks);
  },

  login(errorCode) {
    this.doLogin('RealTime', errorCode);
  },

  denied(errorCode) {
    this.doDenied('RealTime', errorCode);
  },

  doLogin(loginType, errorCode) {
    this.doAppServerRedirect('Login', loginType, errorCode);
  },

  doDenied(deniedType, errorCode) {
    this.doAppServerRedirect('AccessDenied', deniedType, errorCode);
  },

  doAppServerRedirect(redirectType, internalType, errorCode) {
    const absolute = LEResourceResolver.getWindowLocation({ removeTrailing: true });
    const parts = LEResourceResolver.getUrlParts(absolute);
    let local = parts.pathname.replace(new RegExp('^/'), '');
    let hash = '#,~~ssokey~~';
    let accountId;

    // if account id in the store the logout is part of session process.
    // else
    // it is part of login / logout process.
    if(errorCode){
      const queryString = Navigator.parseQueryString(false);
      accountId = queryString.accountid ||
        queryString.accountId ||
        store.state[storeModuleTypes.SESSION][sessionStateKeys.ACCOUNT_ID] ||
        LEResourceResolver.getCurrentAccountId();
      local = 'a/' + accountId;
    } else if(store.state[storeModuleTypes.SESSION][sessionStateKeys.ACCOUNT_ID]) {
      accountId = store.state[storeModuleTypes.SESSION][sessionStateKeys.ACCOUNT_ID];
    } else {
      accountId = LEResourceResolver.getCurrentAccountId();
    }

    const outerRoute = store.state[storeModuleTypes.ENVIRONMENT][environmentStateKeys.OUTER_ROUTE];

    if (outerRoute || accountId) {
      if (outerRoute && outerRoute.length > 0 &&
        this.navigation.routes[outerRoute] === undefined) {
        hash += `;${outerRoute}`;
      }

      if (accountId) {
        local = local.replace(accountId, '~~accountid~~');
      }
    }

    if ((local.length - 1) !== local.lastIndexOf('/') || hash.indexOf('/') !== 0) {
      local += '/';
    }

    const params = { ServicePath: encodeURIComponent(local + hash), accountId };

    if (!Number.isNaN(errorCode) && errorCode > 0) {
      params.errorCode = `&error=${errorCode.toString()}`;
    } else {
      params.errorCode = '';
    }

    const ssoLogoutUrl = store.getters[`${storeModuleTypes.SESSION}/${sessionGetterTypes.GET_VALUE}`](sessionStateKeys.SSO_LOGOUT_URL) ?
      { url: store.getters[`${storeModuleTypes.SESSION}/${sessionGetterTypes.GET_VALUE}`](sessionStateKeys.SSO_LOGOUT_URL) } : undefined;

    const res = ssoLogoutUrl ||
      this.AppServeresources[redirectType][internalType];
    const url = this.calculateUrlFn(Object.assign({}, res, params));
    store.dispatch(`${storeModuleTypes.SESSION}/${sessionActionTypes.RESET_STATE}`);
    store.dispatch(`${storeModuleTypes.ENVIRONMENT}/${sessionActionTypes.RESET_STATE}`);
    gChannel.publish('LiveEngageCommon', 'infra_router:doLogin', url);
  },

  notFound(path) {
    /* eslint-disable no-param-reassign */
    const currentRoute = this.Router.currentRoute.value;
    path = path || (currentRoute && currentRoute.path);
    this.logger.info(`Path: ${path} not found! I'll route to default.`);
    if (path) {
      this.logger.info('First try to parse the path and check for valid prefix.');
      const prefix = RouteNameBuilder.getRoutePrefix(path);
      this.logger.info(`Found prefix - ${prefix}.`);
      this.logger.info('Try to resolve the module name for the prefix found.');
      const moduleName = store.getters[`${storeModuleTypes.ENVIRONMENT}/resolveModuleNameByPrefix`](prefix);

      if (moduleName && moduleName !== '') {
        this.logger.info(`Found module name - ${moduleName}.`);
        this.logger.info('Try to get the router for the module found.');
      }

      if (this.Router) {
        // Navigate to the default route of the active router
        this.logger.info('I will navigate to the router default route.');
        // this.navigator.navigate('');
        // this.Router.push('/');
        gChannel.publish('LiveEngageCommon', 'infra_router:doNavigateToDefaultRoute');
      }
    }
  },
};

export default LiveEngageBaseRouteHandler;
