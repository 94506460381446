<template>
  <div class="personalization-widget">
    <div
      v-show="hasAnnouncements"
      :class="{ 'announcement-active': hasPendoTargetId }"
    >
      <div
        v-show="hasPendoTargetId"
        ref="announcementElement"
        :title="tooltipMessage"
        :tooltip-text="tooltipMessage"
        tooltip-position="bottom"
        class="announcement s-announcement"
        role="button"
        tabindex="0"
        aria-haspopup="true"
      ></div>
    </div>
  </div>
</template>
<script>
import CONSTANTS from '../../../assets/js/const';
import { Logger } from 'vue-infra';

const logger = Logger.getLogger("LEFramework:AnnouncementPendoIcon");

export default {
  name: 'AnnouncementPendoIcon',
  data() {
    return {
      checkAttempts: 0,
      hasPendoTargetId: false,
      hasAnnouncements: false,
      checkForPendoTargetIdInterval: null,
      tooltipMessage: this.$translator.translate(
        'LEFramework.announcement.tooltip.title'
      )
    };
  },
  watch: {
    $route() {
      this.showAnnouncements();
    }
  },

  created() {
    this.checkForPendoTargetIdInterval = setInterval(() => {
      const announcementElement = this.$refs.announcementElement;
      this.checkAttempts++;
      if (announcementElement && announcementElement.pendoTargetId) {
        this.hasPendoTargetId = !!announcementElement.pendoTargetId;
        clearInterval(this.checkForPendoTargetIdInterval);
      } else if(this.checkAttempts >= 10) {
        logger.info("pendoTargetId was not found!")
        clearInterval(this.checkForPendoTargetIdInterval)
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.checkForPendoTargetIdInterval);
  },

  methods: {
    showAnnouncements() {
      const isPendoEnabledACFeature =
        LE.sessionManager &&
        LE.sessionManager.getFeaturePropertyState(
          CONSTANTS.FEATURES.IS_PENDO_ENABED
        );
      const isPendoEnabledOnPhase =
        LE.getConfig() && LE.getConfig().__isPendoAnnoucmentsEnabled;
      const onAgentWorkspace =
        window.location.hash &&
        window.location.hash.includes(CONSTANTS.ROUTE_PREFIX.AW);
      const onWebAgent =
        window.location.hash &&
        window.location.hash.includes(CONSTANTS.ROUTE_PREFIX.WA);
      this.hasAnnouncements =
        isPendoEnabledACFeature &&
        isPendoEnabledOnPhase &&
        !onAgentWorkspace &&
        !onWebAgent;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/mixins';

$icons: s-announcement;
@include add-icons-from-list($icons, 50%, 50%);

.announcement-active {
  display: flex;
  justify-content: center;
  align-items: center;
  @include themify {
    color: getColor('text', 'framework');
    &.clicked,
    &:hover {
      background: getColor('data-bar-icon-hover-background', 'framework');
      border-radius: 8px;
    }
  }
  height: 32px;
  width: 32px;
  font-size: 12px;
  cursor: pointer;
}

.announcement {
  width: 20px;
  height: 20px;
  font-size: 17px;
  object-fit: contain;
  @include themify {
    color: getColor('data-bar-icons-active', 'framework');
    &.clicked,
    &:hover {
      color: getColor('data-bar-icons-hover', 'framework');
    }
  }
}
</style>
