define(function (require) {
    "use strict";

    var BaseCollection = require("collections/BaseCollection");
    var RevisionedCollection = require("collections/RevisionedCollection");
    var PostSurveyModel = require("models/entities/PostSurveyModel");
    var ApiResources = require("assets/data/apiResources.json");
    var LEResourceResolver = require("leResourceResolver");
  var _ = require('underscore');

    var URL_SELECT_PARAMETER = {
        ALL: "$all",
        PARTIAL: [PostSurveyModel.KEYS.ID, PostSurveyModel.KEYS.FLOW + '(name)']
    };

    var FETCH_METHOD = "GET";
    var NAME = "PostSurveyCollection";

    var PostSurveyCollection = RevisionedCollection.extend({
        model : PostSurveyModel,
        defaultPostSurvey: null,
        name: NAME,
        URL_SELECT_PARAMETER: URL_SELECT_PARAMETER,
        initialize: function(models,options) {
            options = options ||{};
            this.urlSelectParameter = options.urlSelectParameter || URL_SELECT_PARAMETER.PARTIAL;
            this.resource = ApiResources.AccountConfig.PostSurvey;
            this.model = PostSurveyModel;
            RevisionedCollection.prototype.initialize.call(this,models,options);
        },
        url: function() {
           return LEResourceResolver.getUrl(ApiResources.AccountConfig.PostSurvey) + "&select=" + this.urlSelectParameter;
        },

        parse : function (response, options) {

            response = _.map(response, function (postSurveyAttributes) {
                postSurveyAttributes.id = parseInt(postSurveyAttributes.id, 10);
                postSurveyAttributes.name = postSurveyAttributes.flow.name;

                return postSurveyAttributes; //basically I should create a new object but performance wize ...
            });
            return RevisionedCollection.prototype.parse.call(this, response, options);
        },

        fetch : function (options) {
            var successFunc = options.success;
            options.success = function (collection, response, options) {
                collection.setDefault();
                if (_.isFunction(successFunc)) {
                    successFunc(collection, response, options);
                }
            };
            // Call BaseCollection's fetch
            return BaseCollection.prototype.fetch.call(this, options);
        },

        getDefault : function () {
            return this.defaultPostSurvey;
        },

        setDefault : function () {
            var defaultPostSurvey = this.find(function (postSurvey) {
                return postSurvey.get("isDefault");
            });
            this.defaultPostSurvey = defaultPostSurvey || null;
        },

        getFetchMethod: function(){ //used by fetcher
            return FETCH_METHOD;
        },

        getFetchHeaders: function(){  //used by fetcher
            return {"accept": "application/json"};
        }
    });

    PostSurveyCollection.NAME = NAME;
    PostSurveyCollection.URL_SELECT_PARAMETER = URL_SELECT_PARAMETER;

    return PostSurveyCollection;
});
