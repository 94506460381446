define(function (require) {
    'use strict';
    const { Logger } = require("vue-infra");
    const logger = Logger.getLogger('LEFramework');
    const _ = require('underscore');
    const CONST = require('const');
    const Navigator = require("routers/navigator");
    const LocaleResolver = (function () {
        const defaultLocale = CONST.LANGUAGE['en-US'];
        let currentLocale = defaultLocale;
        const validValues = _.invert(CONST.LANGUAGE);

        function getLocale() {
            return currentLocale;
        }

        function setLocale(locale) {
          logger.debug('localeResolver: setLocale >>>>>>>>>>>>> set locale');
            const search = Navigator.parseQueryString();
            if(search && search.leui_ovr_lang && validValues[search.leui_ovr_lang]){
              currentLocale = search.leui_ovr_lang;
            } else if (!_.isUndefined(locale) && validValues[locale]) {
                currentLocale = locale;
                return true;
            }
            return false;
        }

        function getLang() {
            return currentLocale.split('-')[0];
        }

        function getDefaultLocale() {
            return defaultLocale;
        }

        return {
            getLocale: getLocale,
            setLocale: setLocale,
            getLang: getLang,
            getDefaultLocale: getDefaultLocale
        };

    }());

    return LocaleResolver;
});
