<template>
  <div
    :class="['agent-avatar', 'tray-menu-container', buttonClass]"
    :role="role"
    :tabindex="tabIndex"
    :aria-haspopup="isButton || null"
    @click="avatarClicked"
    @keydown.enter.space.prevent="avatarClicked"
  >
    <div
      v-if="displayLoader"
      data-lp-at="avatar-loader-display"
      class="loader-wrapper"
      :class="size"
    >
      <le-loader
        class="avatar-loader"
        :size="size === 'big' ? 40 : 28"
      />
    </div>
    <div
      v-else-if="imageUrl === undefined || fallbackImage === true"
      data-lp-at="agent-status-image"
      class="agent-status-image img"
      :class="size"
    >
    </div>
    <img
      v-else
      data-lp-at="agent-status-image"
      class="agent-status-image"
      :class="size"
      :src="imageUrl"
      alt=""
      @error="doFallback"
    />
    <div class="tray-menu-container-left">
      <div class="tray-menu-item-container">
        <div
          v-tooltip.right="tooltip"
          :class="['agent-status-indicator',iconClasses, { 'loading': displayLoader }, agentStatusClass]"
        >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { leLoader } from '@liveperson/lp-vue-components';
  export default {
    name: "AgentAvatar",
    components: {
      leLoader,
    },
    props: {
      size: {
        type: String,
        default: 'small'
      },
      agentStatusClass: {
        type: String,
        default: ''
      },
      tooltip: {
        type: String,
        default: '',
      },
      imageUrl: {
        type: String,
        default: undefined
      },
      displayLoader: {
        type: Boolean,
        default: false
      },
      alt: {
        type: String,
        default: '',
      },
      isButton: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['clicked'],
    data() {
      return {
      fallbackImage: false,
      status: this.$translator.translate('LEFramework.permission.value.agent') + ' - ' + this.tooltip,
    }
  },
    computed:{
      iconClasses() {
        let imageClass;
        if(this.agentStatusClass.indexOf('online') !== -1){
          imageClass = 'ico-agent-status-online online'
        }
        else if(this.agentStatusClass.indexOf('brb') !== -1){
          imageClass = 'ico-agent-status-back-soon brb'
        }
        else if(this.agentStatusClass.indexOf('away') !== -1){
          imageClass = 'ico-agent-status-away away'
        }

        return {
          [imageClass]: true
        };
      },
      role() {
        if (this.isButton) {
          return 'button';
        }
        return null;
      },
      tabIndex() {
        if (this.isButton) {
          return '0';
        }
        return '-1';
      },
      buttonClass() {
        if (this.isButton) {
          return 'pointer';
        }
        return '';
      },
    },
    methods: {
      avatarClicked() {
        if (this.isButton) {
          this.$emit('clicked');
        }
      },
      doFallback() {
        this.fallbackImage = true;
        // this.imageUrl = '';
      }
    },
  }

</script>

<style lang='scss' scoped>
  @import "../../../assets/scss/global";
  @import "../../../assets/scss/mixins";

  .tray-menu-container-left{
    position: absolute;
    top: 10px;
    right: 2px;
  }

  .agent-avatar {
    position: relative;
    object-fit: contain;
    &.pointer {
      cursor: pointer;
    }
    .agent-status-image {
      border-radius: 50%;
      &.img {
        @include themify {
          $default-agent-avatar: getAttr('default-agent-avatar', 'framework');
          @include lp-bg-image('assets/img/' + $default-agent-avatar);
        }
      }
      &.small{
        background-size: 28px;
        width: 28px;
        height: 28px;
      }
      &.big{
        background-size: 40px 40px !important;
        width: 40px;
        height: 40px;
      }
    }
    .agent-status-indicator {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      right: 0;
      &.online {
        @include themify {
          background-color: getColor('agent-status-online', 'framework');
        }
      }
      &.brb {
        @include themify {
          background-color: getColor('agent-status-brb', 'framework');
        }
      }
      &.away {
        @include themify {
          background-color: getColor('agent-status-away', 'framework');
        }
      }
      &.unknown, &.loading {
        @include themify {
          background-color: getColor('agent-status-unknown', 'framework');
        }
      }
    }
    .loader-wrapper {
      display: block;
      position: relative;
      background-size: 28px;
      width: 28px;
      height: 28px;
      @include themify {
        background-color: getColor('agent-avatar-background', 'framework');
      }
      &.small{
        background-size: 28px;
        width: 28px;
        height: 28px;
      }
      &.big{
        background-size: 40px 40px !important;
        width: 40px;
        height: 40px;
      }
      border-radius: 50%;
      .avatar-loader {
        position: absolute;
        left: -5px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

</style>
