define(function (require) {
    "use strict";

    var _ = require("underscore");
    var { Logger } = require('vue-infra');
    var BaseModel = require("models/BaseModel");
    var ApiResources = require("assets/data/apiResources.json");
    var Backbone = require("backbone");

    var PersonModel = {};

    var CONST = {
        OPERATOR: "Operator",
        EMAIL_ADDRESS: "emailAddress"
    };


    PersonModel = BaseModel.extend({
        resource:ApiResources.UserManagement.Operators,
        name: "PersonModel",
        initialize: function(attributes, options) {
            this.logger = Logger.getLogger("LEFramework");
            if(!_.isUndefined(options)){
                if (options.userId){
                    this.resource = ApiResources.UserManagement.Operator.url.replace("{operatorId}", options.userId);
                }
            }
        },
        save: function(attributes, options) {
            var originalError = options.error;
            var logger = this.logger;
            options.error = function(model, errorsData){
                logger.error("PersonModel >> save >> error >> START");
                var errorMsg = getErrorMsg(errorsData);
                var errors = prepareErrorsFromMessage(errorMsg, model);

                originalError(model, errors);
                logger.error("PersonModel >> save >> error >> END");
            };
            Backbone.Model.prototype.save.call(this, attributes, options);
        },

        fetch: function(options) {
            var originalError = options.error;
            var logger = this.logger;
            options.error = function(model, errorsData){
                logger.error("PersonModel >> fetch >> error >> START");
                var errorMsg = getErrorMsg(errorsData);
                var errors = prepareErrorsFromMessage(errorMsg, model);

                originalError(model, errors);
                logger.error("PersonModel >> fetch >> error >> END");
            };
            Backbone.Model.prototype.fetch.call(this, options);
        }
    });

    function getErrorMsg(errors){
        if (errors && errors.responseError && errors.responseError.error){
            return errors.responseError.error.message;
        }
        else{
            return "";
        }
    }

    var CONSTANT = {
        ERROR_EMAIL_EXIST_START: "[Login name ",
        ERROR_NAME_EXIST_START: "[Dispaly name ",
        ERROR_EXIST_END: " is not unique.]",
        ERROR_CURR_PASSWORD: "[Wrong credentials]",
        ERROR_USER_NOT_FOUND: "Unable to get oper", //there are 2 different errors for this case (operator not found, operator was deleted) but there is a typo in the word operator in one of them
        ERROR_USER_IS_ACTIVE: "Unable delete a non-offline operator"
    };


    function prepareErrorsFromMessage(errorsText, model){
        var errorsServer = {};
        var expectedEmailErrorText = CONSTANT.ERROR_EMAIL_EXIST_START + model.get(PersonModel.FIELDS.LOGIN_NAME) + CONSTANT.ERROR_EXIST_END;
        var expectedNameErrorText = CONSTANT.ERROR_NAME_EXIST_START + model.get(PersonModel.FIELDS.DISPLAY_NAME) + CONSTANT.ERROR_EXIST_END;

        _.extend(errorsServer, compareErrorText(errorsText, expectedEmailErrorText, PersonModel.ERRORS.EMAIL_NOT_UNIQUE));
        _.extend(errorsServer, compareErrorText(errorsText, expectedNameErrorText, PersonModel.ERRORS.NAME_NOT_UNIQUE));
        _.extend(errorsServer, compareErrorText(errorsText, CONSTANT.ERROR_CURR_PASSWORD, PersonModel.ERRORS.WRONG_CREDENTIALS));
        _.extend(errorsServer, compareErrorText(errorsText, CONSTANT.ERROR_USER_NOT_FOUND, PersonModel.ERRORS.USER_NOT_FOUND));
        _.extend(errorsServer, compareErrorText(errorsText, CONSTANT.ERROR_USER_IS_ACTIVE, PersonModel.ERRORS.USER_IS_ACTIVE));

        return errorsServer;
    }

    //this function compares error texts and insert suitable error syntax into array
    function compareErrorText(originalErrorText, expectedErrorText, error){
        var errors={};
        if (originalErrorText.indexOf(expectedErrorText) >= 0) {
            errors[error] = true;
        }
        return errors;
    }


    PersonModel.ERRORS = {
        EMAIL_NOT_UNIQUE: "emailNotUnique",
        NAME_NOT_UNIQUE: "nameNotUnique",
        EMAIL_EMPTY: "emailEmpty",
        WRONG_CREDENTIALS: "wrongCredentials",
        USER_NOT_FOUND: "userNotFound",
        USER_IS_ACTIVE: "userIsActive"
    };

    PersonModel.FIELDS = {
        DISPLAY_NAME: "displayName",
        NICK_NAME: "nickName",
        LOGIN_NAME: "loginName",
        MAX_NUMBER_OF_CHATS: "maxNumberOfChats",
        SKILLS: "skills",
        OLD_PASSWORD: "oldPassword",
        PASSWORD: "password"
    };

    return PersonModel;
});
