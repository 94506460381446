define(function (require) {
    "use strict";

    var _ = require("underscore");
    var Marionette = require("marionette");
    var template = require("ui.components/schedule-picker/templates/schedulePicker.tmpl");
    var SchedulePickerItemDataView = require("ui.components/schedule-picker/js/SchedulePickerItemDataView");
    var Button = require("ui/button/Button");
    var EditDecorator = require("ui.plugins/edit-decorator/js/EditDecorator");
    var Translator = require("i18n/translator");

    var SchedulePicker = Marionette.ItemView.extend({
        className: "schedule-picker-container",
        template: template,
        withUIComponents: true,
        ui: {
            "setScheduleButton": ".set-schedule-button",
            "selectedScheduleNameContainer": ".selected-schedule-name-container",
            "selectedScheduleName": ".selected-schedule-name-container .schedule-name",
            "selectedScheduleDataContainer": ".selected-schedule-data-container"
        },
        uiComponents: {
            setScheduleButton: {
                type: Button,
                options: {
                    el: "view.ui.setScheduleButton",
                    permutation: Button.PERMUTATION.BTN_SPECIAL_FULL,
                    initState: Button.STATES.ENABLED
                },
                events: {
                    click: "_onEditScheduleClick"
                }
            },
            selectedScheduleEditDecorator: {
                type: EditDecorator,
                options: {
                    el: "view.ui.selectedScheduleNameContainer",
                    permutation: EditDecorator.PERMUTATION.B,
                    enabled: "view.hasEditPrivileges"
                },
                events: {
                    click: "_onEditScheduleClick"
                }
            },
        },

        customTemplateHelpers: function() {
            return {
                addText: this.addText
            };
        },

        initialize: function(options) {
            this._initialize(options);
        },
        onRender: function() {
            this._onRender();
        },
        getSelection: function() {
            return this._getSelection();
        },
        setSelection: function(model) {
            this._setSelection(model);
        },
        setError: function(isError, errorMsg) {
            this._setError(isError, errorMsg);
        },



        _initialize: function(options) {
            options = options || {};

            this.container = options.container;
            this.addText = _.has(options, "addText") ? options.addText : Translator.translate("LEUIComponents.schedulePicker.add.text");
            this.model = _.has(options, "model") ? options.model : null;
            this.hasEditPrivileges = _.has(options, "hasEditPrivileges") ? options.hasEditPrivileges : true;
        },
        _onRender: function() {
            if (!_.isEmpty(this.container)) {
                this.container.append(this.$el);
            }

            this.uiComponents.setScheduleButton.setEnabled(this.hasEditPrivileges);
            this._renderSelectedModel();
        },
        _renderSelectedModel: function() {
            var isModelExists = !_.isEmpty(this.model);
            this.ui.setScheduleButton.toggleClass("hide", isModelExists);
            this.ui.selectedScheduleNameContainer.toggleClass("hide", !isModelExists);
            this.ui.selectedScheduleDataContainer.toggleClass("hide", !isModelExists);

            var modelName = (isModelExists) ? this.model.getName() : "";
            this.ui.selectedScheduleName.text(modelName);

            this._renderSelectedModelData();
        },
        _renderSelectedModelData: function() {
            if (this.uiComponents.pickerItemData) {
                this.uiComponents.pickerItemData.close();
            }

            if (this.model) {
                this.uiComponents.host$.createAndRender("pickerItemData", {
                    type: SchedulePickerItemDataView,
                    options: {
                        model: this.model,
                        container: this.ui.selectedScheduleDataContainer
                    }
                });
            }
        },
        _onEditScheduleClick: function() {
            this.trigger("edit");
        },
        _getSelection: function() {
            var selection = (this.model) ? this.model.id : null;
            return selection;
        },
        _setSelection: function(model) {
            this.model = model;
            this._renderSelectedModel();
        },
        _setError: function(isError, errorMsg) {
            this.uiComponents.setScheduleButton.setError(isError, errorMsg);
        }
    });

    return SchedulePicker;

});
